// tslint:disable: jsdoc-format
// tslint:disable: quotemark

import { Injectable } from "@angular/core";
import { IChannelItem } from "../models/channel.model";
import { ApiService } from "../../core/services/api.service";
import { forkJoin, Observable, of } from "rxjs";
import { map } from 'rxjs/operators';
import { ENUM_DIAGNOSTIC_DEFAULTS, ENUM_LOS_DEFAULTS } from "../helpers/app.constants";
import { IDiagnosticParam } from "../models/diagnostic-points.model";
import { Helper } from "../helpers/helper";


/**
1. ISIP: Treatment\ initialShutinPresTime. When user updates this point, AI needs to update following dependent values as well
    a. initialShutinPres: This is value of real time channel “Treating Pressure” at time initialShutinPresTime. If this channel is not present use Fracpro channel “Surf Pressure”. If none is present , set the value to 0.
    b. initialShutinPres5: This is value of real time channel “Treating Pressure” at time initialShutinPresTime + 300. If this channel is not present use Fracpro channel “Surf Pressure”. If none is present , set the value to 0.
    c. initialShutinPres10: This is value of real time channel “Treating Pressure” at time initialShutinPresTime + 600 . If this channel is not present use Fracpro channel “Surf Pressure”. If none is present , set the value to 0.
    d. initialShutinPres15: This is value of real time channel “Treating Pressure” at time initialShutinPresTime + 900 . If this channel is not present use Fracpro channel “Surf Pressure”. If none is present , set the value to 0.

2. Ball Seat: Treatment\ BallseatTime. When user updates this point, AI needs to update the following dependent values as well
    a. BallseatPressure: This is value of real time channel “Treating Pressure” at time BallseatTime. If this channel is not present use Fracpro channel “Surf Pressure”. If none is present , set the value to 0.
    b. BallseatRate: This is value of real time channel “Slurry Flow Rate” at time BallseatTime. If this channel is not present use Fracpro channel “Slurry Rate”. If none is present , set the value to 0.
    c. BreakdownVolume: This is value of Fracpro channel “Clean Total” at time BallseatTime. If not present , set the value to 0.

  3. Breakdown: treatment\ BreakdownTime. When user updates this point, AI needs to update the following dependent values as well
    a. BreakdownPres: This is value of real time channel “Treating Pressure” at time BreakdownTime. If this channel is not present use Fracpro channel “Surf Pressure”. If none is present , set the value to 0.
    b. BreakdownRate: This is value of real time channel “Slurry Flow Rate” at time BreakdownTime. If this channel is not present use Fracpro channel “Slurry Rate”. If none is present , set the value to 0.
    c. BreakdownVolume: This is value of Fracpro channel “Clean Total” at time BreakdownTime. If not present , set the value to 0.

  4. WellOpen: treatment\ WellOpenTime. When user updates this point, AI needs to update the following dependent values as well
    a. WellheadOpeningPressure: This is value of real time channel “Treating Pressure” at time WellOpenTime. If this channel is not present use Fracpro channel “Surf Pressure”. If none is present , set the value to 0.
**/

export interface IDefaultDiagnosticPointRequestBody {
  treatmentColumnName: string;
  timeOffSet: number;
  realtimeCName?: string;
  fracproCName?: string;
  baseTreatmentDataTime?: string;
}

export interface IDefaultFpDiagnosticPointRequestBody {
  name: string;
  timeOffSet: number;
}

export interface IDefaultDiagnosticPointResponse {
  treatmentColumnName: string;
  timeOffset: number;
  value: number;
}

export interface IDefaultFpDiagnosticPointResponse {
  name: string;
  timeOffset: number;
}

export interface IDefaultDiagnosticPointResponseResult {
  result: IDefaultDiagnosticPointResponse[];
}

export interface IDefaultFpDiagnosticPointResponseResult {
  result: IDefaultFpDiagnosticPointResponse[];
}

export enum ENUM_REALTIME_CHANNELS {
  treatingPressure = "Treating Pressure",
  slurryFlowRate = "Slurry Flow Rate",
}

export enum ENUM_FRACPRO_CHANNELS {
  surfPressure = "Surf Pressure",
  slurryRate = "Slurry Rate",
  cleanTotal = "Clean Total"
}

export enum ENUM_TREATMENT_PROPS {
  initialShutinPres = "initialShutinPres",
  initialShutinPres5 = "initialShutinPres5",
  initialShutinPres10 = "initialShutinPres10",
  initialShutinPres15 = "initialShutinPres15",
  ballseatPressure = "BallseatPressure",
  ballseatRate = "BallseatRate",
  ballseatVolume = "BallseatVolume",
  breakdownPres = "BreakdownPres",
  breakdownRate = "BreakdownRate",
  breakdownVolume = "BreakdownVolume",
  wellheadOpeningPressure = "WellheadOpeningPressure",
  wellClose = "WellClose",

  Equalize = 'Equalize',
  StartStage = 'StartStage',
  AcidStart = 'AcidStart',
  AcidEnd = 'AcidEnd',
  DesignRate = 'DesignRate',
  DiverterStart = 'DiverterStart',
  DiverterEnd = 'DiverterEnd',
  EndStage = 'EndStage',

  MinMaxStart = 'MinMaxStart',
  MinMaxEnd = 'MinMaxEnd'
}

export const DEFAULTS_LOS_GROUP_ID = ['MinMaxStart', 'MinMaxEnd']
export const DEFAULT_DIAGNOSTIC_GROUP_ID = ['BallSeatTime', 'BreakDownTime', 'InitialShutinPresTime', 'WellOpenTime', 'WellClose', 'Equalize', 'StartStage', 'AcidStart', 'AcidEnd', 'DesignRate', 'DiverterStart', 'DiverterEnd', 'EndStage'];
export const DEFAULT_FP_DIAGNOSTIC_GROUP_ID = ['WellClose', 'Equalize', 'StartStage', 'AcidStart', 'AcidEnd', 'DesignRate', 'DiverterStart', 'DiverterEnd', 'EndStage'];

export const TREATMENT_DEPENTDENT_VALUES: { [key: string]: { treatmentProp: string; realtimeChannel?: string; fracproChannel?: string }[] } = {
  [ENUM_DIAGNOSTIC_DEFAULTS.InitialShutinPresTime]: [
    { treatmentProp: ENUM_TREATMENT_PROPS.initialShutinPres, realtimeChannel: ENUM_REALTIME_CHANNELS.treatingPressure, fracproChannel: ENUM_FRACPRO_CHANNELS.surfPressure },
    { treatmentProp: ENUM_TREATMENT_PROPS.initialShutinPres5, realtimeChannel: ENUM_REALTIME_CHANNELS.treatingPressure, fracproChannel: ENUM_FRACPRO_CHANNELS.surfPressure },
    { treatmentProp: ENUM_TREATMENT_PROPS.initialShutinPres10, realtimeChannel: ENUM_REALTIME_CHANNELS.treatingPressure, fracproChannel: ENUM_FRACPRO_CHANNELS.surfPressure },
    { treatmentProp: ENUM_TREATMENT_PROPS.initialShutinPres15, realtimeChannel: ENUM_REALTIME_CHANNELS.treatingPressure, fracproChannel: ENUM_FRACPRO_CHANNELS.surfPressure },
  ],
  [ENUM_DIAGNOSTIC_DEFAULTS.BallSeatTime]: [
    { treatmentProp: ENUM_TREATMENT_PROPS.ballseatPressure, realtimeChannel: ENUM_REALTIME_CHANNELS.treatingPressure, fracproChannel: ENUM_FRACPRO_CHANNELS.surfPressure },
    { treatmentProp: ENUM_TREATMENT_PROPS.ballseatRate, realtimeChannel: ENUM_REALTIME_CHANNELS.slurryFlowRate, fracproChannel: ENUM_FRACPRO_CHANNELS.slurryRate },
    { treatmentProp: ENUM_TREATMENT_PROPS.ballseatVolume, fracproChannel: ENUM_FRACPRO_CHANNELS.cleanTotal },
  ],
  [ENUM_DIAGNOSTIC_DEFAULTS.BreakDownTime]: [
    { treatmentProp: ENUM_TREATMENT_PROPS.breakdownPres, realtimeChannel: ENUM_REALTIME_CHANNELS.treatingPressure, fracproChannel: ENUM_FRACPRO_CHANNELS.surfPressure },
    { treatmentProp: ENUM_TREATMENT_PROPS.breakdownRate, realtimeChannel: ENUM_REALTIME_CHANNELS.slurryFlowRate, fracproChannel: ENUM_FRACPRO_CHANNELS.slurryRate },
    { treatmentProp: ENUM_TREATMENT_PROPS.breakdownVolume, fracproChannel: ENUM_FRACPRO_CHANNELS.cleanTotal },
  ],
  [ENUM_DIAGNOSTIC_DEFAULTS.WellOpenTime]: [
    { treatmentProp: ENUM_TREATMENT_PROPS.wellheadOpeningPressure, realtimeChannel: ENUM_REALTIME_CHANNELS.treatingPressure, fracproChannel: ENUM_FRACPRO_CHANNELS.surfPressure },
  ],
  [ENUM_DIAGNOSTIC_DEFAULTS.WellClose]: [
    { treatmentProp: ENUM_TREATMENT_PROPS.wellClose, realtimeChannel: ENUM_REALTIME_CHANNELS.treatingPressure, fracproChannel: ENUM_FRACPRO_CHANNELS.surfPressure },
  ],
  [ENUM_DIAGNOSTIC_DEFAULTS.Equalize]: [{ treatmentProp: ENUM_TREATMENT_PROPS.Equalize }],
  [ENUM_DIAGNOSTIC_DEFAULTS.StartStage]: [{ treatmentProp: ENUM_TREATMENT_PROPS.StartStage }],
  [ENUM_DIAGNOSTIC_DEFAULTS.AcidStart]: [{ treatmentProp: ENUM_TREATMENT_PROPS.AcidStart }],
  [ENUM_DIAGNOSTIC_DEFAULTS.AcidEnd]: [{ treatmentProp: ENUM_TREATMENT_PROPS.AcidEnd }],
  [ENUM_DIAGNOSTIC_DEFAULTS.DesignRate]: [{ treatmentProp: ENUM_TREATMENT_PROPS.DesignRate }],
  [ENUM_DIAGNOSTIC_DEFAULTS.DiverterStart]: [{ treatmentProp: ENUM_TREATMENT_PROPS.DiverterStart }],
  [ENUM_DIAGNOSTIC_DEFAULTS.DiverterEnd]: [{ treatmentProp: ENUM_TREATMENT_PROPS.DiverterEnd }],
  [ENUM_DIAGNOSTIC_DEFAULTS.EndStage]: [{ treatmentProp: ENUM_TREATMENT_PROPS.EndStage }],

  [ENUM_LOS_DEFAULTS.MinMaxStart]: [{ treatmentProp: ENUM_TREATMENT_PROPS.MinMaxStart }],
  [ENUM_LOS_DEFAULTS.MinMaxEnd]: [{ treatmentProp: ENUM_TREATMENT_PROPS.MinMaxEnd }],
};

@Injectable()
export class DefaultDiagnosticPointsService {
  prefix: string = '/wells';

  constructor(
    private apiService: ApiService,
  ) { }

  parseRequestToId(requestId: string): string {
    switch (requestId) {
      case ENUM_TREATMENT_PROPS.initialShutinPres: return ENUM_DIAGNOSTIC_DEFAULTS.InitialShutinPresTime;
      case ENUM_TREATMENT_PROPS.ballseatPressure: return ENUM_DIAGNOSTIC_DEFAULTS.BallSeatTime;
      case ENUM_TREATMENT_PROPS.breakdownPres: return ENUM_DIAGNOSTIC_DEFAULTS.BreakDownTime;
      case ENUM_TREATMENT_PROPS.wellheadOpeningPressure: return ENUM_DIAGNOSTIC_DEFAULTS.WellOpenTime;
      case ENUM_TREATMENT_PROPS.wellClose: return ENUM_DIAGNOSTIC_DEFAULTS.WellClose;

      case ENUM_TREATMENT_PROPS.Equalize: return ENUM_DIAGNOSTIC_DEFAULTS.Equalize;
      case ENUM_TREATMENT_PROPS.StartStage: return ENUM_DIAGNOSTIC_DEFAULTS.StartStage;
      case ENUM_TREATMENT_PROPS.AcidStart: return ENUM_DIAGNOSTIC_DEFAULTS.AcidStart;
      case ENUM_TREATMENT_PROPS.AcidEnd: return ENUM_DIAGNOSTIC_DEFAULTS.AcidEnd;
      case ENUM_TREATMENT_PROPS.DesignRate: return ENUM_DIAGNOSTIC_DEFAULTS.DesignRate;
      case ENUM_TREATMENT_PROPS.DiverterStart: return ENUM_DIAGNOSTIC_DEFAULTS.DiverterStart;
      case ENUM_TREATMENT_PROPS.DiverterEnd: return ENUM_DIAGNOSTIC_DEFAULTS.DiverterEnd;
      case ENUM_TREATMENT_PROPS.EndStage: return ENUM_DIAGNOSTIC_DEFAULTS.EndStage;

      case ENUM_TREATMENT_PROPS.MinMaxStart: return ENUM_LOS_DEFAULTS.MinMaxStart;
      case ENUM_TREATMENT_PROPS.MinMaxEnd: return ENUM_LOS_DEFAULTS.MinMaxEnd;
      default: return null;
    }
  }

  update(wellId, treatmentId, flowPathType: number = 0, body: IDefaultDiagnosticPointRequestBody[]): Observable<any> {
    return this.apiService.put(`${this.prefix}/${wellId}/treatments/${treatmentId}/flowPaths/${flowPathType}/defaultDiagnostic`, body);
  }

  updateDefaultfpDiagnostic(wellId, treatmentId, flowPathType: number = 0, body: { name: string, timeOffSet: number }[]): Observable<any> {
    return this.apiService.put(`${this.prefix}/${wellId}/treatments/${treatmentId}/flowPaths/${flowPathType}/defaultfpDiagnostic`, body);
  }

  private setDiagnosPayload = (diagName: string, timeOffsetParam: number, realtimeChannels: IChannelItem[], fracproChannels: IChannelItem[], baseTreatmentDataTime?: string) => {
    const dependentValues = TREATMENT_DEPENTDENT_VALUES[diagName] || [];
    const isFpDiagnostic = DEFAULT_FP_DIAGNOSTIC_GROUP_ID.concat(DEFAULTS_LOS_GROUP_ID).includes(diagName);
    const result: {
      fpDiagnostic: IDefaultFpDiagnosticPointRequestBody[], diagnostic: IDefaultDiagnosticPointRequestBody[]
    } = { fpDiagnostic: [], diagnostic: [] };

    dependentValues.forEach((dpValue, index) => {
      let timeOffSet = timeOffsetParam;
      if (diagName === ENUM_DIAGNOSTIC_DEFAULTS.InitialShutinPresTime) {
        timeOffSet = timeOffSet + (300 * index);
      }

      if (!!isFpDiagnostic) {
        result.fpDiagnostic.push({ timeOffSet: Math.round(timeOffSet), name: dpValue.treatmentProp });
      } else {
        const payload: IDefaultDiagnosticPointRequestBody = {
          treatmentColumnName: dpValue.treatmentProp,
          timeOffSet: Math.round(timeOffSet),
          baseTreatmentDataTime: baseTreatmentDataTime,
          realtimeCName:  null,
          fracproCName: null
        };

        let channel = realtimeChannels.find(item => item.name === dpValue.realtimeChannel);
        if (channel) {
          payload.realtimeCName = channel.cName;
        } else {
          channel = fracproChannels.find(item => item.name === dpValue.fracproChannel);
          if (channel) payload.fracproCName = channel.cName;
        }
        if (!channel) payload.timeOffSet = 0;
        result.diagnostic.push(payload);
      }

    });
    return result;
  };

  handleUpdate(
    wellId, treatmentId, flowPathType: number = 0, params: IDiagnosticParam[],
    realtimeChannels: IChannelItem[], fracproChannels: IChannelItem[], baseTreatmentDataTime?: string
  ): Observable<{ fpDiagnostic: IDefaultDiagnosticPointResponse[], diagnostic: IDefaultDiagnosticPointResponse[] }> {
    let reqBody: {
      fpDiagnostic: IDefaultFpDiagnosticPointRequestBody[], diagnostic: IDefaultDiagnosticPointRequestBody[]
    } = { fpDiagnostic: [], diagnostic: [] };

    params.forEach(param => {
      const payload = this.setDiagnosPayload(param.name, param.timeOffSet, realtimeChannels, fracproChannels, baseTreatmentDataTime);
      reqBody.fpDiagnostic = reqBody.fpDiagnostic.concat(payload.fpDiagnostic);
      reqBody.diagnostic = reqBody.diagnostic.concat(payload.diagnostic);
    });

    const fpDiagnostic$ = !reqBody.fpDiagnostic.length ? of([]) : this.updateDefaultfpDiagnostic(wellId, treatmentId, flowPathType, reqBody.fpDiagnostic);
    const diagnostic$ = !reqBody.diagnostic.length ? of([]) : this.update(wellId, treatmentId, flowPathType, reqBody.diagnostic);
    return forkJoin( fpDiagnostic$, diagnostic$ ).pipe(map(response => {
      const isNull = !response || !response.length;
      return {
        fpDiagnostic: isNull ? [] : Helper.getList(response[0], 'result'),
        diagnostic: isNull ? [] : Helper.getList(response[1], 'result')
      };
    }));
  }

}

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, OnInit } from '@angular/core';
import { compact, orderBy, cloneDeep, isEmpty, remove } from 'lodash';
import { ToasterService } from '../../services/toaster.service';
import { SelectItem } from '../select-items/select-item';

interface IDesignActionItem {
  id: string;
  text: string;
  designValue: string;
  actualValue: string;
}

interface IModelValue {
  groupOption: string;
  selected: IDesignActionItem[]
}

@Component({
  selector: 'app-select-design-actual',
  templateUrl: './select-design-actual.component.html',
  styleUrls: ['./select-design-actual.component.scss']
})
export class SelectDesignActualComponent implements OnDestroy {

  optionsOpened: boolean = false;

  private _disabled: boolean = false;
  public activeOption: IDesignActionItem[] = [];

  searchVal: string = '';

  public options: IDesignActionItem[] = [
    { designValue: 'desPumpTime', actualValue: 'totalPumpTime', text: 'Pump Time', id: 'Pump Time' },
    { designValue: 'desCleanVol', actualValue: 'baseFluidVol', text: 'Clean Vol', id: 'Clean Vol' },
    { designValue: 'desSlurryVol', actualValue: 'totalVolume', text: 'Slurry Vol', id: 'Slurry Vol' },
  ];

  modelValue: IModelValue = {
    groupOption: 'well',
    selected: []
  };

  @Input() public maxShowedItem: boolean = false;
  @Input() public placeholder: string = '';
  @Input() public multiple: boolean = false;
  @Input() public maxItems: number;
  @Input() public isFullWidth: boolean = false;
  @Input() public sortSelect: boolean = true;

  @Input()
  public set active(selectedItems: IDesignActionItem[]) {
    if (isEmpty(this.activeOption) && !isEmpty(selectedItems)) {
      selectedItems = compact(selectedItems);
      const areItemsStrings = typeof selectedItems[0] === 'string';
      this.activeOption = selectedItems;
    }
  }

  public get active(): IDesignActionItem[] {
    return this.activeOption;
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
    if (this._disabled === true) {
      this.clickOutside();
    }
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Output() public selected: EventEmitter<any> = new EventEmitter();

  constructor(
    private toasterService: ToasterService,
    private ref: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.ref.detach();
  }

  isActive(value: SelectItem): boolean {
    const item = this.activeOption.find(i => i.id === value.id);
    return !!item;
  }

  matchClick(e: any) {
    if (this._disabled === true) {
      return;
    }
    this.open();
  }

  open() {
    this.optionsOpened = true;
  }

  public clickOutside(): void {
    this.optionsOpened = false;
  }

  selectMatch(value, e: Event = void 0): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const isSelectedItem = this.activeOption.find(item => item.id === value.id);
    if (this.multiple) {
      if (isSelectedItem) {
        remove(this.activeOption, (item => item.id === value.id));
      } else {
        if (this.maxItems) {
          if (this.activeOption.length < this.maxItems) {
            this.activeOption.push(value);
          } else {
            this.toasterService.showError(`The Items must be at less than or equal to ${this.maxItems}`);
          }
        } else {
          this.activeOption.push(value);
        }
      }
    } else {
      if (isSelectedItem) {
        this.activeOption = [];
      } else {
        this.activeOption = [value];
      }
    }
    // emit selected items
    this.modelValue.selected = this.activeOption;
    this.selected.emit(this.modelValue);
  }

}


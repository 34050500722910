import { WellsCompareLogDataService } from './../pages/wells/wells-compare-log-data.service';
import { WellsCompareResolver } from './../pages/wells/wells-compare.resolver';
import { TreatmentSettingsService } from './services/treatment-settings.service';
import { WellsCompareDataService } from './../pages/wells/wells-compare-data.service';
import { CustomRelatedColumnComponent } from './components/custom-smart-table/custom-related-column.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgSelectModule } from '@ng-select/ng-select';
import { HighchartsChartModule } from 'highcharts-angular';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { Ng5SliderModule } from 'ng5-slider';

import { HeaderComponent } from './components/header/header.component';

import { SearchWellApiComponent } from './components/search-well-api/search-well-api.component';
import { DefaultLayoutComponent } from './layouts';
import { AuthGuardService, NoAuthGuardService, AdminGuardService, DataEngineerGuardService, ServiceCompanyGuardService } from './guards';
import { UserAuthService, UserService, CompaniesService, UserRolesService, PivotPointService, DiagnosticPointsService,
  ChannelMappingService, ToasterService, ChartsService, ChannelSelectionService, LoadingOverlayService, ChannelSettingsStorageService,
  NavbarScaleChartService, RealtimeTreatmentStorageService, LocalDataStorageService, FracproChannelsService, PlotTemplateService, VersionBuildCheckService,
  SearchWellOptionsStorageService, DiagnosticCompanyStorageService, ChannelMappingStorageService, FracproChannelStorageService
} from './services';
import { ErrorMessageComponent, CompanySelectorComponent, InputTagsComponent, SelectChannelsComponent, DiagnosticPointsSelectComponent } from './components';
import { CompareWellsTimeControlComponent } from './components/compare-wells-time-control/compare-wells-time-control.component';
import { NoWhiteSpaceDirective } from './directives/no-white-space.directive';
import { DecimalFormatDirective } from './directives/decimal-format.directive';
import { SortableTableHeaderDirective } from './directives/sortable-table-header.directive';
import { ModalConfirmComponent } from './components/modals/confirm/modal-confirm.component';
import { SelectComponent } from './components/select/select.component';
import { CustomColorComponent } from './components/custom-smart-table/custom-color.component';
import { EditCustomColorComponent } from './components/custom-smart-table/edit-custom-color.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { EditCustomInputNumberComponent } from './components/custom-smart-table/edit-custom-input-number';
import { EditCustomChannelsSelectComponent } from './components/custom-smart-table/edit-custom-channels-select.component';
import { CustomChannelsSelectComponent } from './components/custom-smart-table/custom-channels-select.component';
import { CustomDateComponent } from './components/custom-smart-table/custom-date.component';
import { CustomSwitchColumnComponent } from './components/custom-smart-table/custome-switch-column/custome-switch-column.component';
import { TreatmentService } from './services/treatment.service';
import { BaseChartService, RealtimeChartService, ScatterPlotParameterChartService, LogDataCurveParameterChartService, SingleWellMultiTreatmentChartService, MultiWellChartService } from './services/charts';
import { EllipsisLinesDirective } from './directives/ellipsis-lines.directive';
import { CompareWellsService } from './services/compare-wells.service';
import { PadService } from './services/pad.service';
import { CompareWellsTimeControlService } from './components/compare-wells-time-control/compare-wells-time-control.service';
import { ScrollbarChartSettingsService } from './services/scrollbar-chart-settings.service';
import { ThemesSettingsService } from './services/themes-settings.service';
import {
  MultiFlowDataChartComponent,
  SingleWellMultiTreatmentChartComponent, MultiWellChartComponent,
  ScatterPlotParameterChartComponent
} from './components/charts';
import { TimeSettingsComponent } from './components/modals/time-settings/time-settings.component';
import { TextMaskModule } from 'angular2-text-mask';
import { InputNoUnsetDirective } from './directives/input-no-unset.directive';
import { InputNumberInRangeDirective } from './directives/input-number-in-range.directive';
import { RenameChannelComponent } from './components/rename-channel/rename-channel.component';
import { NumberValidator } from './validators/number.validator';
import { MultipleInputComponent } from './components/multiple-input/multiple-input.component';
import { CustomNumberComponent } from './components/custom-smart-table/custom-number.component';
import { CustomNumberDecimal1Component } from './components/custom-smart-table/custom-number-decimal-1.component';
import { CustomNumberDecimal3Component } from './components/custom-smart-table/custom-number-decimal-3.component';
import { ChannelSelectionComponent } from './components/modals/channel-selection/channel-selection.component';
import { ChannelSettingYaxisComponent } from './components/channel-setting-yaxis/channel-setting-yaxis.component';
import { ScatterPlotParameterSettingsComponent } from './components/modals/scatter-plot-parameter-settings/scatter-plot-parameter-settings.component';
import { PivotPointComponent } from './components/modals/pivot-point/pivot-point.component';
import { OffsetWellCompareComponent } from './components/modals/offset-well-compare/offset-well-compare.component';
import { ExportChartDropdownComponent } from './components/export-chart-dropdown/export-chart-dropdown.component';
import { PlotTemplateDropdownComponent } from './components/plot-template-dropdown/plot-template-dropdown.component';
import { PivotPointSelectComponent } from './components/pivot-point-select/pivot-point-select.component';
import { TreatmentPlotComponent } from './components/treatment-plot/treatment-plot.component';
import { TreatmentLogChartSettingsComponent } from './components/modals/treatment-log-chart-settings/treatment-log-chart-settings.component';
import { SelectParametersComponent } from './components/select-parameters/select-parameters.component';
import { TreatmentPlotService } from './components/treatment-plot/treatment-plot.service';
import { DataFilesService } from './services/data-files.service';
import { SessionService } from './services/session/session.service';
import { CompareOffsetChannelsService } from './services/compare-offset-channels.service';
import { DefaultDiagnosticPointsService } from './services/default-diagnostic-points.service';
import { TreatmentLogParserService } from './services/treatment-log-parser.service';
import { TreatmentLogTemplateService } from './services/treatment-log-template.service';
import { SelectMultiTreatmentsComponent } from './components/select-multi-treatments/select-multi-treatments.component';
import { TableWellsSelectionComponent } from './components/table-wells-selection/table-wells-selection.component';
import { WellsSelectionModalComponent } from './components/modals/wells-selection-modal/wells-selection-modal.component';
import { SelectItemsComponent } from './components/select-items/select-items.component';
import { SelectDesignActualComponent } from './components/select-design-actual/select-design-actual.component';
import { SearchWellListComponent } from './components/search-well-list/search-well-list.component';
import { WellListTableComponent } from '../pages/wells/well-list-table/well-list-table.component';
import { TreatmentLogTemplateDropdownComponent } from './components/treatment-log-template-dropdown/treatment-log-template-dropdown.component';
import { WellGridViewComponent } from './components/well-grid-view/well-grid-view.component';
import { SearchPadListComponent } from './components/search-pad-list/search-pad-list.component';
import { PadListTableComponent } from './../pages/pad/pad-list-table/pad-list-table.component';

import { SearchPadListService } from './components/search-pad-list/shared/services/search-pad-list.service';
import { SearchPadListControlService } from './components/search-pad-list/shared/services/search-pad-list-control.service';
import { HistoricalWellsService } from '../pages/manage/historical-wells/historical-wells.service';
import { SearchWellListService } from './components/search-well-list/search-well-list.service';
import { SearchWellListControlService } from './components/search-well-list/search-well-list-control.service';
import { WellSearchSettingsService } from './services/well-search-settings.service';
import { TreatmentPlotDataStoreService } from './components/treatment-plot/treatment-plot-data-store.service';
import { TreatmentPlotFdiService } from './components/treatment-plot/treatment-plot-fdi.service';
import { TreatmentPlotFdiApiService } from './components/treatment-plot/treatment-plot-fdi-api.service';
import { TreatmentPlotTimeConverterService } from './components/treatment-plot/treatment-plot-time-converter.service';
import { TreatmentPlotFdiStoreService } from './components/treatment-plot/treatment-plot-fdi-store.service';
import { TreatmentDetailsStorageService } from './services/storage/treatment-details-storage.service';
import { SearchPadPadOptionsStorageService } from './services/storage/search-pad-options-storage.service';

import { CountriesAndTimezonesService } from './services/countries-and-timezones/countries-and-timezones.service';

import { WellCompletionDatePipe } from './pipes/well-completion-date.pipe';
import { ConfirmTemplateModalComponent } from './components/modals/confirm-template-modal/confirm-template-modal.component';
import { TreatmentPlotFdiDropdownComponent } from './components/treatment-plot-fdi-dropdown/treatment-plot-fdi-dropdown.component';
import { FdiWellSelectionModalComponent } from './components/modals/fdi-well-selection-modal/fdi-well-selection-modal.component';
import { TreatmentPlotFdiInfoComponent } from './components/treatment-plot-fdi-info/treatment-plot-fdi-info.component';
import { BourdetDerivativeComponent } from './components/modals/bourdet-derivative/bourdet-derivative.component';
import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbInputModule,
  NbAccordionModule,
  NbDialogModule,
  NbWindowModule,
  NbListModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbTooltipModule,
} from '@nebular/theme';

import {
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbPaginationModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { FDIParameterSelectComponent } from './components/modals/fdi-parameter-select/fdi-parameter-select.component';
import { CompareWellStorageService } from './services/storage/compare-wells-storage.service';
import { RealtimeTreatmentService } from '../pages/treatments/realtime/realtime-treatment/realtime-treatment.service';
import { PadRealtimeDataService } from '../pages/pad/pad-realtime-data.service';
import { PadRealtimeResolver } from '../pages/pad/pad-realtime.resolver';
import { UnitSystemService } from './services/unit-system.service';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, ColorPickerModule, Ng2SmartTableModule, TextMaskModule, NgbModule];

const LIB_MODULES = [
  ClickOutsideModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbPaginationModule,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OwlMomentDateTimeModule,
  NgSelectModule,
  HighchartsChartModule,
  NgxCleaveDirectiveModule,
  UiSwitchModule,
  Ng5SliderModule
];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  // NgbModule,
  // NbSecurityModule, // *nbIsGranted directive,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbListModule,
  NbInputModule,
  NbAccordionModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbTooltipModule,
];

const FOR_ROOT_MODULES = [
  NbDialogModule.forRoot(),
  ToastrModule.forRoot({
    preventDuplicates: true
  }),
  NgxLoadingModule.forRoot({
    animationType: ngxLoadingAnimationTypes.circle,
    primaryColour: '#007ea3',
    secondaryColour: '#ffffff',
  })
];

const EXPORT_MODULES = [
  NgxLoadingModule
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'default',
    },
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
];

const COMPONENTS = [
  HeaderComponent,
  DefaultLayoutComponent,
  ErrorMessageComponent,
  CompanySelectorComponent,
  SelectComponent,
  SearchWellApiComponent,
  MultiFlowDataChartComponent,
  SingleWellMultiTreatmentChartComponent,
  MultiWellChartComponent,
  ScatterPlotParameterChartComponent,
  ExportChartDropdownComponent,
  DiagnosticPointsSelectComponent,
  PlotTemplateDropdownComponent,
  PivotPointSelectComponent,
  TreatmentPlotComponent,
  TreatmentPlotFdiDropdownComponent,
  TreatmentPlotFdiInfoComponent,
  CompareWellsTimeControlComponent,
  TreatmentLogTemplateDropdownComponent,

  NoWhiteSpaceDirective,
  DecimalFormatDirective,
  SortableTableHeaderDirective,
  EllipsisLinesDirective,
  InputNoUnsetDirective,
  InputNumberInRangeDirective,
  RenameChannelComponent,
  InputTagsComponent,
  MultipleInputComponent,
  SelectChannelsComponent,
  ChannelSettingYaxisComponent,
  SelectParametersComponent,
  SelectMultiTreatmentsComponent,
  TableWellsSelectionComponent,
  SelectItemsComponent,
  SelectDesignActualComponent,
  SearchWellListComponent,
  WellListTableComponent,
  WellGridViewComponent,
  SearchPadListComponent,
  PadListTableComponent
];

const PIPES = [
  WellCompletionDatePipe
]

const SHARED_PROVIDERS = [
  AuthGuardService,
  NoAuthGuardService,
  AdminGuardService,
  DataEngineerGuardService,
  ServiceCompanyGuardService,
  VersionBuildCheckService,

  UserAuthService,
  UserService,
  CompaniesService,
  UserRolesService,
  ChannelMappingService,
  ToasterService,
  TreatmentService,
  ChartsService,
  ChannelSelectionService,
  CompareWellsService,
  PadService,
  LoadingOverlayService,
  NavbarScaleChartService,
  SearchWellOptionsStorageService,
  ChannelSettingsStorageService,
  RealtimeTreatmentStorageService,
  DiagnosticCompanyStorageService,
  ChannelMappingStorageService,
  FracproChannelStorageService,
  CompareWellStorageService,
  LocalDataStorageService,
  PivotPointService,
  DiagnosticPointsService,
  FracproChannelsService,
  PlotTemplateService,
  TreatmentPlotService,
  TreatmentPlotDataStoreService,
  DataFilesService,
  SessionService,
  CompareOffsetChannelsService,
  DefaultDiagnosticPointsService,
  TreatmentLogParserService,
  TreatmentLogTemplateService,
  TreatmentPlotFdiService,
  TreatmentPlotFdiApiService,
  TreatmentPlotTimeConverterService,
  TreatmentPlotFdiStoreService,
  TreatmentDetailsStorageService,
  SearchPadPadOptionsStorageService,

  BaseChartService,
  RealtimeChartService,
  ScatterPlotParameterChartService,
  LogDataCurveParameterChartService,
  SingleWellMultiTreatmentChartService,
  MultiWellChartService,
  CompareWellsTimeControlService,
  ScrollbarChartSettingsService,
  SearchWellListService,
  SearchWellListControlService,
  HistoricalWellsService,
  WellSearchSettingsService,
  SearchPadListControlService,
  SearchPadListService,

  CountriesAndTimezonesService,

  NumberValidator,

  WellsCompareResolver,
  WellsCompareLogDataService,
  WellsCompareDataService,
  TreatmentSettingsService,
  PadRealtimeDataService,
  PadRealtimeResolver,

  RealtimeTreatmentService,
  ThemesSettingsService,
  UnitSystemService
];

const MODALS = [
  ModalConfirmComponent,
  CustomRelatedColumnComponent,
  CustomColorComponent,
  EditCustomColorComponent,
  EditCustomInputNumberComponent,
  EditCustomChannelsSelectComponent,
  CustomChannelsSelectComponent,
  CustomDateComponent,
  CustomNumberComponent,
  CustomNumberDecimal1Component,
  CustomNumberDecimal3Component,
  TimeSettingsComponent,
  FDIParameterSelectComponent,
  ChannelSelectionComponent,
  ScatterPlotParameterSettingsComponent,
  PivotPointComponent,
  OffsetWellCompareComponent,
  TreatmentLogChartSettingsComponent,
  WellsSelectionModalComponent,
  ConfirmTemplateModalComponent,
  FdiWellSelectionModalComponent,
  BourdetDerivativeComponent,
  CustomSwitchColumnComponent
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, ...LIB_MODULES, ...FOR_ROOT_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...MODALS, ...LIB_MODULES, ...EXPORT_MODULES, ...PIPES],
  declarations: [...COMPONENTS, ...MODALS, ...PIPES],
  entryComponents: [...MODALS]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    return <ModuleWithProviders> {
      ngModule: SharedModule,
      providers: [...NB_THEME_PROVIDERS, ...SHARED_PROVIDERS, { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: true } }],
    };
  }
}

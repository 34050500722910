import { Injectable } from '@angular/core';
import { isNil } from 'lodash';

@Injectable()
export class ChannelSettingsStorageService {

  constructor() { }

  saveByPad(padName, settings, excludedKeys?: string[]) {
    if (padName !== undefined) {
      const storageData = this.getByWell(padName);
      const data = { ...settings };
      if (excludedKeys && excludedKeys.length) {
        excludedKeys.forEach(excludedKey => {
          if (storageData && !isNil(storageData[excludedKey])) { data[excludedKey] = storageData[excludedKey]; }
          else { delete data[excludedKey]; }
        });
      }

      localStorage.setItem(`padChannelSettings_${padName}`, JSON.stringify(data));
    }
  }

  getByPad(padName) {
    if (padName !== undefined) {
      try {
        const stringData = localStorage.getItem(`padChannelSettings_${padName}`);
        return JSON.parse(stringData);
      } catch (error) {
        console.log(error);
      }
    }
    return;
  }

  removeByPad(padName) {
    localStorage.removeItem(`padChannelSettings_${padName}`);
  }

  saveByWell(wellId, settings, excludedKeys?: string[], optionKey?: string) {
    if (wellId) {
      const storageData = this.getByWell(wellId);
      const data = { ...settings };
      if (excludedKeys && excludedKeys.length) {
        excludedKeys.forEach(excludedKey => {
          if (storageData && !isNil(storageData[excludedKey])) { data[excludedKey] = storageData[excludedKey]; }
          else { delete data[excludedKey]; }
        });
      }
      const key = optionKey ? `well_${optionKey}_${wellId}` : `well_${wellId}`;
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  getByWell(wellId, optionKey?: string) {
    if (wellId) {
      try {
        const key = optionKey ? `well_${optionKey}_${wellId}` : `well_${wellId}`;
        const stringData = localStorage.getItem(key);
        return JSON.parse(stringData);
      } catch (error) {
        console.log(error)
        // return null;
      }
    }
    return;
  }

  removeByWell(wellId, optionKey?: string) {
    if (wellId) {
      const key = optionKey ? `well_${optionKey}_${wellId}` : `well_${wellId}`;
      localStorage.removeItem(key);
    }
  }

  saveByEquipment(equipmentId, settings) {
    if (equipmentId) {
      localStorage.setItem(`equipment_${equipmentId}`, JSON.stringify(settings));
    }
  }

  getByEquipment(equipmentId) {
    if (equipmentId) {
      try {
        const stringData = localStorage.getItem(`equipment_${equipmentId}`);
        return JSON.parse(stringData);
      } catch (error) {
        return null;
      }
    }
    return;
  }

  clearAll() {
    localStorage.clear();
  }
}

import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { TreatmentPlotFDI, IFDIPayload, TreatmentPlotFDIInfo, TreatmentPlotFDIParam } from '../treatment-plot/treatment-plot-fdi.model';
import { isEmpty, forEach, isNil } from 'lodash';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { ITimeSetting } from '../../models';
import { ChartTimeFormat, TIME_SETTING_TYPE } from '../../helpers/app.constants';
(window as any).moment = moment;
(window as any).momentTz = momentTz;

@Component({
  selector: 'app-treatment-plot-fdi-info',
  templateUrl: './treatment-plot-fdi-info.component.html',
  styleUrls: ['./treatment-plot-fdi-info.component.scss']
})
export class TreatmentPlotFdiInfoComponent implements OnInit, OnChanges, OnDestroy {
  listFDIs: TreatmentPlotFDI[] = [];
  fdiInfo: TreatmentPlotFDIInfo = {
    fdiEnd: undefined,
    fdiStart: undefined,
    netGainPress: undefined,
    grossGainPress: undefined,
    volToFrstResp: undefined,
    volToFrstRespPercent: undefined,
    avgSlope: undefined,
    maxSlope: undefined,
    avgFDIPressure: undefined,
    maxFDIPressure: undefined,
    preFDIPressure: undefined
  };

  @Input() selectedParam: TreatmentPlotFDIParam;
  @Input() timeFormat: ChartTimeFormat;
  @Input() isShow = true;

  @Input()
  set currentId(id: string) {
    if (id && !isEmpty(this.listFDIs)) {
      const curFDI = this.listFDIs.find(item => item.id === id);
      if (curFDI) {
        this.setFDIInfo(Object.assign({}, curFDI));
      }
    }
  }
  @Input() fdiPayload: IFDIPayload;
  @Input() wellInfo: any;
  @Input() treatmentInfo: any;

  constructor(
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fdiPayload && !changes.fdiPayload.isFirstChange()) {
      this.fdiPayload = changes.fdiPayload.currentValue;
      if (this.fdiPayload && this.fdiPayload.fdi) {
        this.listFDIs = this.fdiPayload.fdi;
        if (!isEmpty(this.listFDIs)) {
          const curFDI = Object.assign({}, this.listFDIs[0]);
          this.setFDIInfo(curFDI);
        }
      }
    }
  }

  ngOnDestroy() {
  }

  setFDIInfo(curFDI: TreatmentPlotFDI) {
    if (curFDI) {
      forEach(this.fdiInfo, (value, key) => {
        if (['fdiStart', 'fdiEnd'].indexOf(key) > -1) {
          curFDI[key] = curFDI[key] * 1000;
        }
        this.fdiInfo[key] = curFDI[key];
      });
      this.fdiInfo = Object.assign({}, this.fdiInfo);
    }
  }

  private getFDIStartEndTime(prop: string): string | number {
    if (!this.fdiInfo || isNil(this.fdiInfo[prop])) return 'N/A';
    // float time settting
    if (this.timeFormat === TIME_SETTING_TYPE.OffsetTime) {
      const dateTime = this.treatmentInfo && this.treatmentInfo.baseTreatmentDateTime ? this.fdiInfo[prop] - this.treatmentInfo.baseTreatmentDateTime * 1000 : this.fdiInfo[prop]
      return (dateTime / 60_000).toFixed(3) + ' min';
    }
    // hh:mm time setting
    else {
      const dateTime = Math.round(this.fdiInfo[prop] / 1000);
      if (!this.wellInfo) moment.unix(dateTime).utc().format('MMM DD, YYYY HH:mm:ss');
      return momentTz.tz(moment.unix(dateTime).utc(), this.wellInfo.tzTimeZone).format('MMM DD, YYYY HH:mm:ss');
    }
    return 'N/A';
  }

  get fdiStart() {
    return this.getFDIStartEndTime('fdiStart')
  }

  get fdiEnd() {
    return this.getFDIStartEndTime('fdiEnd')
  }

  get hasSelectedParam(): boolean {
    if (!this.selectedParam) return true;
    for (const prop in this.selectedParam) {
      if (this.selectedParam[prop]) return true;
    }
    return false;
  }

  isNumber(val: any): boolean {
    if (!isNaN(val) && typeof val === 'number') return true;
    return false;
  }

}

import { Directive, Input, Output, EventEmitter } from '@angular/core';

export type SortDirection = 'asc' | 'desc' | '';
const ROTATE_STATE: {[key: string]: SortDirection} = {
  asc: 'desc',
  desc: '',
  '': 'asc'
};

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface PagingSettings {
  page: number;
  pageSize: number;
  collectionSize: number;
}

export const pagingDefaultSettings: PagingSettings = {
  page: 1, // current page
  pageSize: 24, // number of items per page
  collectionSize: 0 // total number of items
};

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'th[sortable]',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class SortableTableHeaderDirective {

  constructor() { }

  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = ROTATE_STATE[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }

}

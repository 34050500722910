import { Injectable } from '@angular/core';
import { isBoolean, isNumber } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { ENUM_THEMES } from '../helpers/app.constants';

export const KEY_SCROLLBAR_SETTINGS = 'scrollbarSettings';
export const DEFAULT_REALTIME_BOURDET = false;
export const OFFSET_DATA_BEFORE_TREATMENT_START = '30'; // min
export const OFFSET_DATA_AFTER_TREATMENT_END = '60'; // min
export const SCROLLBAR_SAMPLING_RATE = '20'; // min
export const HIGHT_VOLUME_SAMPLING_RATE = '10'; // sec
export const HIGH_RES_TIME_RATE = '120'; // min
export const REALTIME_REQUEST_RATE = '5'; // sec

export interface IScrollbarChartSettings {
  themes: string;
  scrollbarSamplingRate: string;
  highVolumeSamplingRate: string;
  highResTimeRate: string;
  // offsetDataBeforeTreatmentStart: string;
  // offsetDataAfterTreatmentEnd: string;
  showWellAliasInTag: boolean;
  multiWellScrollbar: boolean;
  realtimeRequestRate: string;
  realtimeBourdet: boolean;
}

@Injectable()
export class ScrollbarChartSettingsService {
  private dataState: BehaviorSubject<IScrollbarChartSettings>;

  constructor() {
    this.dataState = new BehaviorSubject({
      themes: ENUM_THEMES.Light,
      scrollbarSamplingRate: SCROLLBAR_SAMPLING_RATE,
      highVolumeSamplingRate: HIGHT_VOLUME_SAMPLING_RATE,
      highResTimeRate: HIGH_RES_TIME_RATE,
      realtimeRequestRate: REALTIME_REQUEST_RATE,
      // offsetDataBeforeTreatmentStart: OFFSET_DATA_BEFORE_TREATMENT_START,
      // offsetDataAfterTreatmentEnd: OFFSET_DATA_AFTER_TREATMENT_END,
      showWellAliasInTag: true,
      multiWellScrollbar: true,
      realtimeBourdet: DEFAULT_REALTIME_BOURDET
    });
  }

  setDataState(data: IScrollbarChartSettings) {
    this.dataState.next(data);
  }

  getDataState(): Observable<IScrollbarChartSettings> {
    return this.dataState.asObservable();
  }

  setLocalStorage(id, optionKey, value) {
    if (optionKey) {
      localStorage.setItem(`company_${id}_${optionKey}`, JSON.stringify(value));
    }
  }

  getLocalStorage(id: number, optionKey: string) {
    if (optionKey) {
      try {
        const stringData = localStorage.getItem(`company_${id}_${optionKey}`);
        return JSON.parse(stringData);
      } catch (error) {
        return null;
      }
    }
    return;
  }

  getRealtimeBourdetStorage(id: number) {
    const dataState = this.getLocalStorage(id, KEY_SCROLLBAR_SETTINGS);
    if (!dataState || dataState.realtimeBourdet === null || dataState.realtimeBourdet === undefined) return DEFAULT_REALTIME_BOURDET;
    if (!isBoolean(dataState.realtimeBourdet)) return DEFAULT_REALTIME_BOURDET;
    return dataState.realtimeBourdet;
  }

  getRealtimeRequestRate(id: number) {
    const dataState = this.getLocalStorage(id, KEY_SCROLLBAR_SETTINGS);
    const defaultRequestRate = parseInt(REALTIME_REQUEST_RATE, 10) * 1000;
    if (!dataState || !dataState.realtimeRequestRate === null) return defaultRequestRate;
    const realtimeRequestRate = parseInt(dataState.realtimeRequestRate, 10);
    if (!isNumber(realtimeRequestRate)) return defaultRequestRate;
    if (realtimeRequestRate <= 0) return defaultRequestRate;
    return realtimeRequestRate * 1000;
  }
}

import { DiagnosticCompanyStorageService } from './../../services/storage/diagnostic-company-storage.service';
import { TreatmentDetailsStorageService } from './../../services/storage/treatment-details-storage.service';
import { ToasterService } from './../../services/toaster.service';
import { Observable } from 'rxjs';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { saveAs } from 'file-saver';
import { cloneDeep, forEach, isEmpty, isEqual, isNil, isNumber, includes, capitalize, uniq, uniqBy } from 'lodash';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MultiFlowDataChartComponent } from '../../../shared/components/charts';
import { ChannelSelectionComponent } from '../../../shared/components/modals/channel-selection/channel-selection.component';
import { TimeSettingsComponent } from '../../../shared/components/modals/time-settings/time-settings.component';
import {
  ChartTimeFormat, CHART_MESSAGES, DEFAULT_INIT_TEMPLATE_NAME, DEFAULT_LAST_MIN_FILTER, DIAGNOSTIC_DEFAULTS, ENUM_DIAGNOSTIC_DEFAULTS, ENUM_TREATMENT_TYPE,
  INTERVAL_CHART_REALTIME, TIME_SETTING_TYPE, TREATMENT_TYPE, YAXIS_KEYS, INTERVAL_CHART_COMMENT_REALTIME, USER_STORAGE, FDI_PARAM,
  TIME_SETTINGS_TIME_OPTIONS, DEFAULT_MONITOR_LAST_MIN_FILTER, TREATMENT_MONITOR_NUMBER, PREFIX_PREDICTIVE_CHANNEL, CHART_SERIES_NAME, LOS_DEFAULTS, ENUM_LOS_DEFAULTS, WELL_TOKEN_EXPIRED
} from '../../../shared/helpers/app.constants';
import { Helper } from '../../../shared/helpers/helper';
import { IChartDataState, IInputDataMultiFlow, IOffsetDataState, ITimeSetting, ITreatmentComment } from '../../../shared/models';
import { IChannelItem, IChannelMappingResponse, IChannelSettingItemModel, IChannelSettingModel, isChannelSettingModel } from '../../../shared/models/channel.model';
import { IDiagnosticParam, IDiagnosticPoint, IDiagnosticPointDataChange } from '../../../shared/models/diagnostic-points.model';
import { IPlotTemplate, IPlotTemplateResponse } from '../../../shared/models/plot-template.model';
import {
  ChannelMappingService, ChannelSelectionService, ChannelSettingsStorageService, DiagnosticPointsService,
  FracproChannelsService,
  FracproChannelStorageService,
  KEY_LAST_MIN_FILTER, KEY_OFFSET_CHANNEL_SETTINGS, KEY_TEMPLATE_ID, KEY_TIME_FORMAT,
  LocalDataStorageService, PlotTemplateService, RealtimeTreatmentStorageService
} from '../../../shared/services';
import { BaseChartService, ITimeRangeFilter, CONST_CURSOR_OPTIONS, ENUM_CURSOR_OPTS } from '../../../shared/services/charts/base-chart.service';
import { RealtimeChartService } from '../../../shared/services/charts/realtime-chart.service';
import { NavbarScaleChartService } from '../../../shared/services/navbar-scale-chart.service';
import { TreatmentService } from '../../../shared/services/treatment.service';
import { UserAuthService } from '../../../shared/services/user-auth.service';
import { IntervalTimer, TimerState } from '../../helpers/intervalTimer';
import { ITreatmentPlotDataState } from '../../models/treatment-plot.model';
import { ITreatmentItem } from '../../models/treatment.model';
import { CompareOffsetChannelsService } from '../../services/compare-offset-channels.service';
import { CompareWellsService } from '../../services/compare-wells.service';
import { DefaultDiagnosticPointsService, DEFAULT_DIAGNOSTIC_GROUP_ID, DEFAULT_FP_DIAGNOSTIC_GROUP_ID, IDefaultDiagnosticPointResponse, IDefaultDiagnosticPointResponseResult, IDefaultFpDiagnosticPointResponse, IDefaultFpDiagnosticPointResponseResult, DEFAULTS_LOS_GROUP_ID, TREATMENT_DEPENTDENT_VALUES } from '../../services/default-diagnostic-points.service';
import { DEFAULT_REALTIME_BOURDET, ScrollbarChartSettingsService } from '../../services/scrollbar-chart-settings.service';
import { FdiWellSelectionModalComponent } from '../modals/fdi-well-selection-modal/fdi-well-selection-modal.component';
import { TreatmentPlotDataStoreService } from './treatment-plot-data-store.service';
import { TreatmentPlotFdiApiService } from './treatment-plot-fdi-api.service';
import { TreatmentPlotFdiStoreService } from './treatment-plot-fdi-store.service';
import { IFdiDropEventData, IFDIPayload, TreatmentPlotFDI, TreatmentPlotFDIParam } from './treatment-plot-fdi.model';
import { TreatmentPlotFdiService } from './treatment-plot-fdi.service';
import { TreatmentPlotTimeConverterService } from './treatment-plot-time-converter.service';
import { TreatmentPlotService } from './treatment-plot.service';
import { ChannelMappingStorageService } from '../../services/storage/channel-mapping-storage.service';
import { IRealtimeDataStateSetting } from '../../../pages/treatments/realtime/realtime-treatment/realtime-treatment.component';
import { UnitSystemService } from '../../services/unit-system.service';
import { SessionService } from '../../services/session/session.service';
import { IBourdetOption } from '../../models/offset-well-compare.model';

const REALTIME_RENDER_DELAY = 100;


@Component({
  selector: 'app-treatment-plot',
  templateUrl: './treatment-plot.component.html',
  styleUrls: ['./treatment-plot.component.scss']
})
export class TreatmentPlotComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<any> = new Subject();
  subscriptions: Subscription[] = [];
  viewChangeSubscription: Subscription;
  CHART_SERIES_NAME = CHART_SERIES_NAME;
  cursorOpt: string;
  cursorSelected: any = { id: ENUM_CURSOR_OPTS.Default, text: 'Default', typeCursor: ENUM_CURSOR_OPTS.VertLine, icon: 'icon-mouse-pointer' };
  cursorOpts = [this.cursorSelected].concat(CONST_CURSOR_OPTIONS);

  @Input() disabledBtn: boolean = false;

  _dialogOpened: boolean = false;
  @Input('dialogOpened')
  set dialogOpened(value: boolean) {
    if (this._dialogOpened !== value) {
      this._dialogOpened = !!value;
      this.dialogOpenedChange.emit(this.dialogOpened);
    }
  }
  get dialogOpened(): boolean {
    return this._dialogOpened;
  }
  @Output() dialogOpenedChange: EventEmitter<boolean> = new EventEmitter();

  // Button dropdown
  optionsOpened: boolean = false;
  exportList: string[] = ['Save PNG', 'Save JPEG', 'Print Chart', 'Raw Data'];

  @ViewChild(MultiFlowDataChartComponent) highChart: MultiFlowDataChartComponent;
  chartInputState: IChartDataState = null;
  originalChartInputData: IInputDataMultiFlow;
  chartInputData: IInputDataMultiFlow;

  offsetChartInputData: IInputDataMultiFlow[] = [];
  isLoadingChartData: boolean = false;
  @Input() isDisplayTooltip: boolean = true;
  @Input() isDisplayComment: boolean;
  isDisplayDiagnostics: boolean = false;
  isDisplayLOSPoints: boolean = false;
  selectedCommentType: string = 'Annotation';
  timeFormat: ChartTimeFormat = TIME_SETTING_TYPE.OffsetTime;
  listTreatmentComments: ITreatmentComment[] = [];
  @Input() listDiagnosticPoints: IDiagnosticPoint[] = [];
  @Input() losPoints: IDiagnosticPoint[] = [];
  loadingPlotTemplates: boolean;

  @Input() chartWidth = '100%';
  @Input() chartHeight = '540px';
  @Input() viewChangeEvent: Observable<void>;

  @Input() isFullChart: boolean = false;
  @Input() dataPanelBtnDisabled: boolean = false;

  @Input() wellId: number;
  @Input() treatmentId: number;
  @Input() chartId: string;
  @Input() chartIndex: number = 0;
  @Input()
  set realtime(value: boolean) {
    this.isRealtime = value;
    this.autoScaleTime = this.isRealtime;
  }
  @Input()
  set listWellsData(listWells) {
    this.listWells = listWells;
  }

  @Input()
  set isShowFullChart(value: boolean) {
    if (value && this.highChart) {
      this.isFullChart = value;
      this.highChart.reflow();
    }
  }

  @Input()
  set originalListTreatmentComments(items: ITreatmentComment[]) {
    if (items) this.listTreatmentComments = items;
  };
  @Input() timeFormatDefault: ChartTimeFormat;
  @Input() dataPlotTemplates: IPlotTemplateResponse[] = [];
  @Input() listRealtimeChannels: IChannelItem[] = [];
  @Input() listFracProChannels: IChannelItem[] = [];
  @Input() listPredictedChannels: IChannelItem[] = [];
  @Input() fracProChannelsLatestVer: any[] = [];

  @Input() channelSetting: IChannelSettingModel;
  isReloadOffsetChannels: boolean = false;

  wellTokenIntervalTimer: IntervalTimer;
  wellTokenStartTime: number;
  isResetTokenInterval: boolean;
  loadingTreatmentsToken: boolean;
  treatmentsToken: { wellId: number, treatmentId: number, authToken: string, startTime: number }[] = [];

  @Input() set setDataStateSetting(setting: IRealtimeDataStateSetting) {
    if (!setting) return;
    let isChangeChannels: boolean = false;
    let isChangeOffsetChannels: boolean = false;

    if (setting.offsetChannelSetting) {
      isChangeOffsetChannels = this.channelSelectionService.checkIsChangedChannels(setting.offsetChannelSetting, this.offsetChannelSetting);
      if (isChangeOffsetChannels) {
        this.offsetChannelSetting = setting.offsetChannelSetting;
      }
    }
    if (setting.channelSetting) {
      if (!this.channelSetting) {
        this.selectedTemplate = this.channelSelectionService.findPlotTemplate(setting.channelSetting, this.dataPlotTemplates);
      }
      isChangeChannels = this.channelSelectionService.checkIsChangedChannels(setting.channelSetting, this.channelSetting);
      if (isChangeChannels) this.channelSetting = setting.channelSetting;
    }

    let refresh: boolean = !!isChangeChannels && !!this.chartInputData;
    let typeSettingChanged: boolean;
    let lastMinChanged: boolean;
    if (setting.filterSetting) {
      lastMinChanged = this.lastMinFilter !== setting.filterSetting.lastMin;
      typeSettingChanged = (this.filterSetting && this.filterSetting.type && this.filterSetting.type !== setting.filterSetting.type);
      this.filterSetting = setting.filterSetting;
      this.lastMinFilter = setting.filterSetting.lastMin;
      this.timeFormat = setting.filterSetting.type || TIME_SETTING_TYPE.OffsetTime;
      this.autoScaleTime = setting.filterSetting.autoScale;
      if (!!lastMinChanged) this.chartInputData = this.handleChartInputData();
    }

    if (isChangeOffsetChannels && !!this.treatmentInfo) {
      this.isReloadOffsetChannels = false;
      this.reloadOffsetDataChart(true);
    } else {
      if (isChangeOffsetChannels) this.isReloadOffsetChannels = true;
      if (!!refresh || lastMinChanged || typeSettingChanged) {
        this.handleDataStateChange();
        this.isLoadingChartData = false;
      }
    }

    if (setting.fullChartSetting) {
      this.isFullChart = !setting.fullChartSetting ? false : true;
    }
  }

  handleChartInputData(): IInputDataMultiFlow {
    if (this.lastMinFilter) return this.treatmentPlotDataStoreService.getChartInputDataByLastMin(this.originalChartInputData, this.lastMinFilter);
    return cloneDeep(this.originalChartInputData);
  }

  @Input() isReloadDataChart: boolean;

  @Input()
  set setChartInputData(data: IInputDataMultiFlow) {
    // the data chart is rendered with this.handleDataStateChange and  this.updateSeriesData
    // both are called under setTimeout(100) to ensure this.chartInputData = data is set
    // this also help feature in chart like diagnostic and FDI handle issues
    let lastMin = (this.filterSetting && this.filterSetting.lastMin !== null && this.filterSetting.lastMin !== undefined) ? this.filterSetting.lastMin : null;
    let lastMinChanged: boolean;
    if (this.lastMinFilter !== lastMin) {
      lastMinChanged = true;
      this.lastMinFilter = lastMin;
    }
    this.originalChartInputData = data;
    const chartData: IInputDataMultiFlow = this.handleChartInputData();
    if (!this.chartInputData) {
      this.chartInputData = chartData;
      this.highChart.setupChart();
      const hasChannel = this.channelSelectionService.hasChannelItems(this.channelSetting);
      if (hasChannel) this.isLoadingChartData = true;
      setTimeout(() => {
        this.handleDataStateChange();
        if (data) this.isLoadingChartData = false;
      }, REALTIME_RENDER_DELAY);
    } else {
      const isReloadDataChart = (curData, newData) => {
        const isNullRealtimeData = !curData.realtimeData || !curData.realtimeData.length;
        const isNullFracproData = !curData.fracproData || !curData.fracproData.length;
        let curValues: any;
        let newValues: any;
        if (isNullRealtimeData && isNullFracproData) return true;
        if (!newData) return false;
        if ((!newData.realtimeData || !newData.realtimeData.length) && (!newData.fracproData || !newData.fracproData.length)) return false;
        
        if (isNullRealtimeData) curValues = curData.fracproData[0].values;
        else curValues = curData.realtimeData[0].values;
        if (!newData.realtimeData || !newData.realtimeData.length) newValues = newData.fracproData[0].values;
        else newValues = newData.realtimeData[0].values;

        if (!curValues || !curValues.length) return true;
        if (!newValues || !newValues.length) return false;

        if (newValues[0][0] < curValues[0][0]) return true;

        return false;
      }
      if (lastMinChanged || isReloadDataChart(this.chartInputData, chartData)) {
        this.highChart.setLoadingChart(true);
        this.chartInputData = chartData;
        setTimeout(() => {
          this.handleDataStateChange();
          this.isLoadingChartData = false;
        }, REALTIME_RENDER_DELAY);
      } else {
        this.chartInputData = chartData;
        setTimeout(() => {
          this.updateSeriesData(true);
          this.isLoadingChartData = false;
        }, REALTIME_RENDER_DELAY);
      }
    }

    if (!this.timeRangeFilterValues && this.originalChartInputData && this.filterSetting) {
      const isNullRealtimeData = !this.originalChartInputData || !this.originalChartInputData.realtimeData || !this.originalChartInputData.realtimeData.length;
      const isNullFracproData = !this.originalChartInputData || !this.originalChartInputData.fracproData || !this.originalChartInputData.fracproData.length;
      const timeRangeRealtimeData = this.baseChartService.getTimeRangeFilter(isNullRealtimeData ? null : this.originalChartInputData.realtimeData[0]);
      const timeRangeFracproData = this.baseChartService.getTimeRangeFilter(isNullFracproData ? null : this.originalChartInputData.fracproData[0]);
      this.timeRangeFilterValues = this.baseChartService.mergeTimeRangeFilter(timeRangeRealtimeData, timeRangeFracproData);
      if(this.timeRangeFilterValues && this.treatmentInfo) {
        this.timeRangeFilterValues.timefloat.start = 0;
        this.timeRangeFilterValues.timestamp.start = this.treatmentInfo.timeStart * 1000;
      }
      this.filterSetting = this.baseChartService.toFilterSettings(this.filterSetting.type, this.timeRangeFilterValues, this.wellInfo.tzTimezone, undefined, undefined, this.autoScaleTime);
      if (this.filterSetting.type) this.timeFormat = this.filterSetting.type;
      this.filterSetting.lastMin = this.lastMinFilter;
      this.filterSettingChange.emit(this.filterSetting);
    }
  }

  offsetChannelSetting: IChannelSettingModel;
  channelSettings: IChannelSettingModel;
  autoScaleTime: boolean = true;
  @Input() filterSetting: ITimeSetting = {
    type: this.timeFormat,
    startIndex: 0,
    endIndex: 0,
    lastMin: 0,
    autoScale: true
  };
  lastMinFilter: number;
  timeRangeFilterValues: ITimeRangeFilter;
  wellInfo: any = {};
  treatmentInfo: ITreatmentItem;

  @Input()
  set setTreatmentInfo(item: ITreatmentItem) {
    this.initTreatmentInfo(item, this.isReloadOffsetChannels);
  }

  @Input()
  set setWellInfo(item: any) {
    this.initWellInfo(item);
  }

  minTime: any;
  maxTime: any;

  fracproGridDataCName = [];
  listFlowpaths: any[] = [];
  selectedFlowpath: any[];
  wellTimeZoneOffset: number = null;
  flagKeys = ['isDisplayTooltip', 'isDisplayComment', 'showFullChart', 'timeFormat'];
  userCompanyId: number = null;
  treatmentNumber: number;
  isRealtime: boolean = false;
  selectedTemplate: IPlotTemplateResponse;
  @Input()
  channelMappingData: IChannelMappingResponse[] = [];
  baseTreatmentDataTime: string;
  monitorOptions: IBourdetOption;

  timer: IntervalTimer;
  // commentTimer: IntervalTimer;
  isRefreshBourdet = DEFAULT_REALTIME_BOURDET;
  isShowFDIInfo: boolean = false;
  selectedFDIParam: TreatmentPlotFDIParam;
  countFDI = 1;
  listWells: any[] = [];
  originListFDIs: TreatmentPlotFDI[] = [];
  listFDIs: TreatmentPlotFDI[] = [];
  fdiDataPayload: IFDIPayload = this.treatmentPlotFdiStoreService.getInitialDataPayload();
  activeFDIId: string;
  realtimeYAxisMinMax: any[];

  // Output
  @Output()
  initDone: EventEmitter<any> = new EventEmitter();
  @Output()
  dataChange: EventEmitter<any> = new EventEmitter();
  @Output()
  toggleFullChart: EventEmitter<any> = new EventEmitter();
  @Output()
  commentTypeChange: EventEmitter<{ isDisplayComment: boolean, commentType: string }> = new EventEmitter();
  @Output()
  defaultDiagnosticPointChange: EventEmitter<any> = new EventEmitter();
  @Output()
  channelSettingChange: EventEmitter<IChannelSettingModel> = new EventEmitter();
  @Output() offsetChannelSettingChange: EventEmitter<IChannelSettingModel> = new EventEmitter();
  @Output()
  filterSettingChange: EventEmitter<ITimeSetting> = new EventEmitter();
  @Output()
  componentClose: EventEmitter<boolean> = new EventEmitter();
  @Output()
  componentTransform: EventEmitter<boolean> = new EventEmitter();
  @Output() timerStateChange: EventEmitter<TimerState> = new EventEmitter();

  companiesDiagnostics: any[] = [];

  unitSetting: number;

  constructor(
    private ngZone: NgZone,
    private treatmentService: TreatmentService,
    private localDataStorageService: LocalDataStorageService,
    private treatmentPlotService: TreatmentPlotService,
    private compareWellsService: CompareWellsService,
    private channelMappingService: ChannelMappingService,
    private channelMappingStorageService: ChannelMappingStorageService,
    private compareOffsetChannelsService: CompareOffsetChannelsService,
    private defaultDiagnosticPointsService: DefaultDiagnosticPointsService,
    private dialogService: NbDialogService,
    private baseChartService: BaseChartService,
    private realtimeChartService: RealtimeChartService,
    private channelSelectionService: ChannelSelectionService,
    private diagnosticPointsService: DiagnosticPointsService,
    private plotTemplateService: PlotTemplateService,
    private userAuthService: UserAuthService,
    private treatmentDetailsStorageService: TreatmentDetailsStorageService,
    private channelSettingsStorageService: ChannelSettingsStorageService,
    private realtimeTreatmentStorageService: RealtimeTreatmentStorageService,
    private navbarScaleChartService: NavbarScaleChartService,
    private treatmentPlotDataStoreService: TreatmentPlotDataStoreService,
    private treatmentPlotFdiApiService: TreatmentPlotFdiApiService,
    private treatmentPlotTimeConverterService: TreatmentPlotTimeConverterService,
    private treatmentPlotFdiStoreService: TreatmentPlotFdiStoreService,
    private treatmentPlotFdiService: TreatmentPlotFdiService,
    private scrollbarChartSettingsService: ScrollbarChartSettingsService,
    private diagnosticCompanyStorageService: DiagnosticCompanyStorageService,
    private toasterService: ToasterService,
    private fracproChannelStorageService: FracproChannelStorageService,
    private fracproChannelsService: FracproChannelsService,
    private unitSystemService: UnitSystemService,
    private sessionService: SessionService
  ) {
    const userInfo = this.userAuthService.getUserInfo();
    if (userInfo.companyId) {
      this.userCompanyId = userInfo.companyId;
    }
    const companyStorage = this.diagnosticCompanyStorageService.getCompanyStorage(this.userCompanyId);
    if (companyStorage) {
      this.companiesDiagnostics = companyStorage;
    }

    this.unitSetting = this.unitSystemService.loadUnitSettings();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.listRealtimeChannels && !changes.listRealtimeChannels.firstChange) ||
      (changes.listFracProChannels && !changes.listFracProChannels.firstChange)
    ) {
      if (changes.listRealtimeChannels) this.listRealtimeChannels = changes.listRealtimeChannels.currentValue;
      if (changes.listFracProChannels) {
        this.listFracProChannels = this.channelSelectionService.handleChannelsColor(changes.listFracProChannels.currentValue, this.fracProChannelsLatestVer);
      }
      this.onSelectPlotTemplate(this.selectedTemplate);
    }
  }

  public ngOnInit(): void {
    if (!this.wellId || !this.treatmentId) return;
    if (this.isRealtime) {
      // this.filterSetting = {
      //   type: this.timeFormat,
      //   startIndex: 0,
      //   endIndex: 0,
      //   lastMin: 0,
      //   autoScale: true
      // };
      this.initHighChartReflow();
      this.viewChangeSubscription = this.viewChangeEvent.subscribe(() => {
        if (this.highChart) this.highChart.reflow();
      });
      // can be performed only on the first Plot panel
      if (!this.chartIndex) this.runIntervalLoadData();
    } else {
      this.reloadChannelMappingStorage();
      if (this.timeFormatDefault) this.timeFormat = this.timeFormatDefault;
      const filterSettings = this.treatmentDetailsStorageService.getFilterSettings(this.treatmentId);
      if (filterSettings) {
        this.filterSetting = filterSettings;
        if (!this.filterSetting.type) this.filterSetting.type = this.timeFormat;
        if (!this.timeFormatDefault) this.timeFormat = this.filterSetting.type;
      }
      else this.filterSetting = {
        type: this.timeFormat,
        startIndex: 0,
        endIndex: 0,
        lastMin: 0,
        autoScale: true
      };
      this.compareWellsService.getListWellName().subscribe(res => this.listWells = res.result);
      this.initialize();
    }
  }

  private onChangeTimerState(state: number): void {
    if (!state) this.timerStateChange.emit(TimerState.idle);
    else {
      if (!!this.dialogOpened) {
        if (this.timer) this.timer.pause();
        if (this.wellTokenIntervalTimer) this.wellTokenIntervalTimer.pause();
        this.timerStateChange.emit(state);
      } else {
        if (state == TimerState.paused) {
          if (this.timer) this.timer.pause();
          if (this.wellTokenIntervalTimer) this.wellTokenIntervalTimer.pause();
        } else if (state == TimerState.resumed) {
          if (this.timer) this.timer.resume();
          if (this.wellTokenIntervalTimer) this.wellTokenIntervalTimer.resume();
        }
        this.timerStateChange.emit(state);
      }
    }
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.loadPageOptions(this.wellId);
    //   this.filterSetting.type = this.timeFormat;
    // });
  }

  ngOnDestroy() {
    Helper.unsubscribe(this.subscriptions);
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    this.treatmentPlotFdiStoreService.unsetDataPayload();
    this.onDestroyInterval();

    if (!this.isRealtime) return;
    if (this.viewChangeSubscription) this.viewChangeSubscription.unsubscribe();
  }

  onDestroyInterval(): void {
    if (this.timer) this.timer.stop();
    this.onDestroyTokenInterval();
  }

  private onDestroyTokenInterval(): void {
    if (this.wellTokenIntervalTimer) this.wellTokenIntervalTimer.stop();
  }

  private runIntervalLoadTreatmentsToken(): void {
    const currentTime = new Date().getTime();
    const wellTokenInterval = WELL_TOKEN_EXPIRED - (currentTime - this.wellTokenStartTime);

    this.ngZone.runOutsideAngular(() => {
      this.wellTokenIntervalTimer = new IntervalTimer(() => { this.getWellTreatmentsTokens(); }, wellTokenInterval);
    });
  }

  private getWellTreatmentsTokens(): void {
    if (!this.treatmentsToken || !this.treatmentsToken.length) return;
    if (!!this.loadingTreatmentsToken) return;
    const cloneData = cloneDeep(this.treatmentsToken);
    this.loadingTreatmentsToken = true;

    const requests$ = cloneData.map((item: any) => {
      const wellId = item.wellId;
      const treatmentId = item.treatmentId;
      const currentTime = new Date().getTime();
      if (!!this.isResetTokenInterval) return this.sessionService.getWellTreatmentsToken(wellId, treatmentId).pipe(map(res => res.result));
      else {
        if (item.authToken && item.startTime && currentTime - item.startTime < WELL_TOKEN_EXPIRED - 100) return of(null);
        else return this.sessionService.getWellTreatmentsToken(wellId, treatmentId).pipe(map(res => res.result));
      }
    });
    this.isResetTokenInterval = false;

    forkJoin(requests$)
      .pipe(finalize(() => this.loadingTreatmentsToken = false))
      .subscribe(authTokens => {
        if (!this.wellTokenStartTime) this.wellTokenStartTime = new Date().getTime();
        authTokens.forEach((value: string, index: number) => {
          if (value) {
            this.treatmentsToken[index].authToken = value;
            this.treatmentsToken[index].startTime = new Date().getTime();
          }
        });
      });
  }

  private resetTreatmentTokens(): void {
    this.treatmentsToken = [];
    this.isResetTokenInterval = true;
    this.wellTokenStartTime = undefined;
  }

  private initializeTreatmentTokens(): any {
    if (!this.getOffsetChannelList || !this.getOffsetChannelList.length) {
      this.resetTreatmentTokens();
      return;
    }
    const settings = uniqBy(this.getOffsetChannelList, ['wellId', 'treatmentId'])
      .filter(setting => setting.wellId !== this.wellId && setting.treatmentId !== this.treatmentId);
    if (!settings.length) this.resetTreatmentTokens();
    else {
      if (!this.treatmentsToken || !this.treatmentsToken.length) {
        this.isResetTokenInterval = true;
        this.wellTokenStartTime = undefined;
      } else {
        const tokensData = this.treatmentsToken;
        this.isResetTokenInterval = false;
        for (let i = 0; i < tokensData.length; i++) {
          const itemExist = settings.find(setting => setting.wellId === tokensData[i].wellId && setting.treatmentId === tokensData[i].treatmentId);
          if (!!itemExist) continue;
          this.isResetTokenInterval = true;
          this.wellTokenStartTime = undefined;
          break;
        }
      }
      if (!!this.isResetTokenInterval) {
        this.treatmentsToken = settings.map(item => {
          return { wellId: item.wellId, treatmentId: item.treatmentId, authToken: undefined, startTime: undefined };
        });
      }
      this.getWellTreatmentsTokens();
    }
  }
  
  private getMonitorOptionsDefault = (timeEnd: number, timeStart: number, baseTime: number, lastMin: number): IBourdetOption => {
    if (!lastMin) return undefined;
    if (!timeEnd) return undefined;

    const tmpTime = timeEnd - lastMin * 60;
    if (tmpTime >= timeStart) return { startDate: Helper.toDateTimeString(tmpTime * 1000) };
    else if (timeStart > baseTime) return { startDate: Helper.toDateTimeString(timeStart * 1000) };
    else return undefined;
  }
  private getMonitorOptions = (treatment: ITreatmentItem, filterSetting: ITimeSetting, lastMin: number): IBourdetOption => {
    if (!treatment) return undefined;
    if (treatment.number !== TREATMENT_MONITOR_NUMBER) return undefined;
    const baseTime = treatment.baseTreatmentDateTime;
    if (!filterSetting || !filterSetting.endIndex) {
      const timeEnd = treatment.timeEnd || 0;
      const timeStart = treatment.timeStart || 0;
      return this.getMonitorOptionsDefault(timeEnd, timeStart, baseTime, lastMin);
    } else {
      let timeEnd = filterSetting.endIndex || 0;
      let timeStart = 0;
      const timeTypeLowerCase = filterSetting.type ? filterSetting.type.toLowerCase() : '';
      if (timeTypeLowerCase === TIME_SETTING_TYPE.EpochTimeLowerCase) {
        if (!lastMin) timeStart = filterSetting.startIndex;
        else {
          timeStart = timeEnd - lastMin * 60000;
          if (timeStart < filterSetting.startIndex) timeStart = filterSetting.startIndex
        }

        return {
          startDate: Helper.toDateTimeString(timeStart),
          endDate: Helper.toDateTimeString(timeEnd)
        };
      } else {
        if (!lastMin) timeStart = filterSetting.startIndex;
        else {
          timeStart = timeEnd - lastMin * 60;
          if (timeStart < filterSetting.startIndex) timeStart = filterSetting.startIndex
        }

        return {
          startDate: Helper.toDateTimeString((baseTime + timeStart) * 1000),
          endDate: Helper.toDateTimeString((baseTime + timeEnd) * 1000)
        };
      }
    }
  }

  private initTreatmentInfo(treatment: ITreatmentItem, isReloadOffsetDataChart?: boolean): void {
    if (!treatment) return;
    this.treatmentInfo = treatment;
    this.treatmentNumber = this.treatmentInfo.number;
    // setup filter time setting
    if (this.lastMinFilter === undefined) {
      this.lastMinFilter = this.realtimeTreatmentStorageService.getOption(this.wellId, KEY_LAST_MIN_FILTER);
    }
    // token
    if (this.isRealtime) {
      this.initializeTreatmentTokens();
      if (!!this.isResetTokenInterval) {
        this.onDestroyTokenInterval();
        setTimeout(() => { this.runIntervalLoadTreatmentsToken(); }, 100);
      }
    }
    this.setLastMinFilter(this.lastMinFilter);
    // setup base time
    if (treatment.baseTreatmentDateTime) {
      this.wellTimeZoneOffset = Helper.getTimezoneOffset(treatment.baseTreatmentDateTime, this.wellInfo.tzTimeZone);
      this.baseTreatmentDataTime = Helper.toDateTimeString(treatment.baseTreatmentDateTime * 1000);
    }    
    if(this.timeRangeFilterValues) {
      this.timeRangeFilterValues.timefloat.start = 0;
      this.timeRangeFilterValues.timestamp.start = treatment.timeStart * 1000;
    }

    this.monitorOptions = this.getMonitorOptions(treatment, this.filterSetting, this.lastMinFilter);
    if (!!isReloadOffsetDataChart) this.reloadOffsetDataChart(true);
  }

  private initWellInfo(well: any) {
    if (!well) return;
    this.wellInfo = well;
  }

  private initSideData() {
    this.treatmentPlotFdiApiService.getByTreatment(this.treatmentId)
      .subscribe(res => {
        if (res) {
          this.originListFDIs = cloneDeep(res.fdi);
          const fdiData = Object.assign({}, res);
          // filter list fdi by current offset channels
          fdiData.fdi = this.treatmentPlotFdiService.filterListFDIsByOffsetChannels(this.offsetChannelSetting, this.originListFDIs);
          // if there is fdiData.fdi data countFDI++ to init the right color on the next onAddFDI
          if (fdiData.fdi.length) this.setCountFDI(this.countFDI + fdiData.fdi.length);
          this.setFDIDataState(fdiData);
        }
      });
  }

  private reloadChannelMappingStorage() {
    const channelMapping = this.channelMappingStorageService.getChannelMapped(this.userCompanyId);
    if (!channelMapping || !channelMapping.length) return;
    this.channelMappingData = channelMapping;
  }

  private reloadFracProChannelsStorage(): void {
    const fracproChannels = this.fracproChannelStorageService.getFracproChannels();
    if (fracproChannels && fracproChannels.length) {
      this.fracProChannelsLatestVer = fracproChannels;
    }
  }

  private loadDataForPivotPoints(): void {
    const companyDiagnostics = this.getCompanyDiagnostics();
    const paramNames = companyDiagnostics ? companyDiagnostics.map(diagnostics => diagnostics.name) : [];
    this.diagnosticPointsService.getDataForPivotPoints(this.treatmentId, paramNames, this.userCompanyId)
      .pipe(switchMap(pivotPoints => {
        const result = cloneDeep(pivotPoints);
        if (companyDiagnostics) {
          forEach(companyDiagnostics, (point) => {
            if (pivotPoints.findIndex(x => x.id === point.id) === -1) result.push(point);
          });
        }
        return of(result);
      }))
      .subscribe(data => {
        const pivotPoints = !data ? [] : data;
        this.listDiagnosticPoints = pivotPoints.filter(point => point.name !== 'MinMaxStart' && point.name !== 'MinMaxEnd');
        this.losPoints = pivotPoints.filter(point => point.name === 'MinMaxStart' || point.name === 'MinMaxEnd');
      });
  }

  private initializeData() {
    this.isLoadingChartData = true;
    this.loadingPlotTemplates = true;

    // load data from storage first if there is
    this.reloadChannelMappingStorage();
    const loadChannelMapping = this.channelMappingData && this.channelMappingData.length ? of(true) : this.channelMappingService.getAll(this.userCompanyId);

    this.reloadFracProChannelsStorage();
    const fracProChannelsLatestVer$ = this.fracProChannelsLatestVer && this.fracProChannelsLatestVer.length ? of(true) : this.fracproChannelsService.getLatestVersion();

    const subs = forkJoin(
      this.treatmentService.getWellDetail(this.wellId),
      this.plotTemplateService.getByWell(this.wellId),
      loadChannelMapping,
      fracProChannelsLatestVer$
    ).pipe(switchMap(res => {
      // set well info
      this.wellInfo = res[0].result;
      this.wellInfo.diagnostics = !this.wellInfo.diagnostics ? [] : this.wellInfo.diagnostics;

      this.loadDataForPivotPoints();

      if (!this.wellInfo.tzTimeZone) this.wellInfo.tzTimeZone = Helper.lookupTimeZone(this.wellInfo.country, this.wellInfo.timeZone);

      this.dataPlotTemplates = res[1].result;

      // result from channelMappingService.getAll(this.userCompanyId)
      // unless the channelMappingData loaded from channelMappingStorageService
      if (res[2] && res[2].result) {
        this.channelMappingData = res[2].result.sort((a, b) => a > b);
        this.channelMappingStorageService.setChannelMapped(this.userCompanyId, this.channelMappingData);
      }

      if (res[3] && res[3].result) {
        this.fracProChannelsLatestVer = res[3].result;
        this.fracproChannelStorageService.setFracproChannels(this.fracProChannelsLatestVer);
      }

      // Get list flow paths
      // this.initDone.emit(this.getDataState);
      return this.treatmentService.getListFlowPaths(this.wellId, this.treatmentId);
    }))
      .pipe(switchMap(res => {
        if (res && !isEmpty(res.result)) {
          this.listFlowpaths = res.result.map(item => {
            return { type: item.type, name: item.name };
          });
          this.selectedFlowpath = [this.listFlowpaths[0]];
        }
        return of(
          this.getFlowPathType(this.selectedFlowpath)
        );
      }))
      .pipe(switchMap(flowPathType => {
        const isMonitor = (this.treatmentInfo && this.treatmentInfo.number === TREATMENT_MONITOR_NUMBER);
        // Get list channels
        return this.treatmentService.getChannelsForSettings(this.wellId, this.treatmentId, flowPathType, isMonitor);
      }))
      .pipe(switchMap(res => {
        // Set realtime channels, fracpro channels
        this.listRealtimeChannels = res.realtimeChannels;
        this.listFracProChannels = this.channelSelectionService.handleChannelsColor(res.fracProChannels, this.fracProChannelsLatestVer);
        this.listPredictedChannels = res.realtimeChannels.filter(x => x.originalName.toLowerCase().substring(0, PREFIX_PREDICTIVE_CHANNEL.length) === PREFIX_PREDICTIVE_CHANNEL);

        const flowPathType = this.getFlowPathType(this.selectedFlowpath);
        // call api get chart data, init interval api call
        if (!this.isEmptyAllChannels()) {
          return this.initChannelSettings(flowPathType);
        } else {
          // do nothing if data of channels are empty
          // set message as no data
          this.highChart.resetChart(true);
          this.highChart.setChartMessage(CHART_MESSAGES.en.noData);
          this.isLoadingChartData = false;
          this.channelSetting = this.channelSelectionService.getDefaultSettings();
          return of(null);
        }
      }))
      .pipe(finalize(() => {
        // emit data to parent
        this.initDone.emit(this.getDataState);
        this.isLoadingChartData = false;
        this.isShowFDIInfo = true;
        this.loadingPlotTemplates = false;
        setTimeout(() => {
          this.toggleAnnotations();
        }, 200);
      }))
      .subscribe(() => {
        // realtime handler
        // if (this.isRealtime) {
        //   this.runIntervalLoadData();
        // }
      });

    this.subscriptions.push(subs);
  }

  private handleNewTimeSettings(res): ITimeSetting {
    let newTimeSettings: ITimeSetting;
    if (this.autoScaleTime) {
      newTimeSettings = this.baseChartService.toFilterSettings(res.type, this.timeRangeFilterValues, this.wellInfo.tzTimeZone);
      this.minTime = this.maxTime = null;
    } else {
      const startTimeValue = res.type === TIME_SETTING_TYPE.EpochTime ? res.startTime : res.startTimeFloat;
      const endTimeValue = res.type === TIME_SETTING_TYPE.EpochTime ? res.endTime : res.endTimeFloat;
      newTimeSettings = this.baseChartService.toFilterSettings(res.type, this.timeRangeFilterValues, this.wellInfo.tzTimeZone, startTimeValue, endTimeValue, false);
      // update min max zoom range
      this.minTime = newTimeSettings.startIndex;
      this.maxTime = newTimeSettings.endIndex;
    }

    newTimeSettings.lastMin = res.lastMin;
    newTimeSettings.autoScale = res.autoScaleTime;
    return newTimeSettings;
  }

  private reloadOffsetDataChart(isFetchNewData?: boolean): void {
    if (!this.offsetChannelSetting) return;

    const offsetChannels = this.getOffsetChannelList;
    const flowPathType = this.getFlowPathType(this.selectedFlowpath);

    // Handle realtime interval offset well
    if (!!isFetchNewData) {
      if (!isEmpty(offsetChannels)) {
        this.isLoadingChartData = !!isFetchNewData;
        const reloadChartData = this.getOffsetChartData(offsetChannels, flowPathType, true);
        const reloadFDIData = !this.chartIndex
          ? (!this.fdiDataPayload || !this.fdiDataPayload.fdi || !this.fdiDataPayload.fdi.length
            ? this.treatmentPlotFdiApiService.getByTreatment(this.treatmentId)
            : of(this.fdiDataPayload))
          : of(null);

        // reload data and FDI also
        forkJoin([reloadChartData, reloadFDIData])
          .pipe(
            finalize(() => {
              this.treatmentDetailsStorageService.removeTreatmentData();
              this.isLoadingChartData = false;
            })
          )
          .subscribe(data => {
            if (!this.chartIndex) { // FDI can be performed only on the first Plot panel
              const newListFDIs = this.treatmentPlotFdiService.filterListFDIsByOffsetChannels(this.offsetChannelSetting, data[1].fdi);
              this.originListFDIs = cloneDeep(newListFDIs);
              const fdiData = Object.assign({}, data[1]);
              // filter list fdi by current offset channels
              fdiData.fdi = this.treatmentPlotFdiService.filterListFDIsByOffsetChannels(this.offsetChannelSetting, this.originListFDIs);
              // if there is fdiData.fdi data countFDI++ to init the right color on the next onAddFDI
              if (fdiData.fdi.length) this.setCountFDI(this.countFDI + fdiData.fdi.length);
              this.setFDIDataState(fdiData);
              this.onToggleShowFDI(true);
            } else {
              this.highChart.removeAllFDIHandler();
              this.setFDIDataState(this.treatmentPlotFdiStoreService.getInitialDataPayload());
            }
            this.handleDataStateChange();
            this.isLoadingChartData = false;
          });
      } else {
        this.offsetChartInputData = [];
        this.setCountFDI();
        this.highChart.removeAllFDIHandler();
        this.setFDIDataState(this.treatmentPlotFdiStoreService.getInitialDataPayload());
        this.handleDataStateChange();
      }
    } else {
      if (!isEmpty(offsetChannels)) {
        if (this.channelSettings && this.channelSettings.autoScaleYAxis) {
          // set null for auto scale y axis
          this.highChart.setAxisMinMax(null, null, true, true);
        }
        // if (this.autoScaleTime) {
        // set null for auto scale x axis
        // this.highChart.setAxisMinMax(null, null, false, true);
        // }
        this.subscriptions.push(
          this.getOffsetChartData(offsetChannels, flowPathType, false)
            .pipe(
              finalize(() => {
                this.treatmentDetailsStorageService.removeTreatmentData();
              })
            )
            .subscribe(
              response => {
                // update chart data without reload chart
                // this.updateSeriesData();
                if (!this.chartIndex) { // FDI can be performed only on the first Plot panel
                  // // update fdi point data
                  this.updateFDIDataPointsRealtime(this.listFDIs);
                }
              }
            )
        );
      }
    }
  }

  private runIntervalLoadData(): void {
    const realtimeRequestRate = this.scrollbarChartSettingsService.getRealtimeRequestRate(this.userCompanyId) || INTERVAL_CHART_REALTIME;

    this.ngZone.runOutsideAngular(() => {
      this.timer = new IntervalTimer(() => {
        this.reloadOffsetDataChart();
      }, realtimeRequestRate);
    });
  }

  private loadTreatmentComments() {
    this.subscriptions.push(
      this.getTreatmentComments(this.wellId, this.treatmentId)
        .subscribe(
          treatmentComments => {
            if (!isEmpty(treatmentComments)) {
              this.listTreatmentComments = treatmentComments;
              this.toggleAnnotations();
            }
          },
          err => err
        )
    );
  }

  private getOffsetChartData(offsetChannels: IChannelSettingItemModel[], flowPathType?: number, isFetchNewData?: boolean): Observable<any> {
    let isRealtimeBourdet: boolean;
    let offsetChartInputData: IInputDataMultiFlow[];
    if (!!isFetchNewData) {
      isRealtimeBourdet = true;
    } else {
      offsetChartInputData = this.offsetChartInputData;
      if (this.isRefreshBourdet) {
        isRealtimeBourdet = true;
        this.isRefreshBourdet = false;
      } else {
        isRealtimeBourdet = this.scrollbarChartSettingsService.getRealtimeBourdetStorage(0);
      }
    }

    const baseTreatmentDateTime = this.treatmentInfo ? this.treatmentInfo.baseTreatmentDateTime : undefined;
    return this.treatmentPlotService.getWellTreatmentsData(offsetChannels)
      .pipe(
        switchMap(treatmentItems => {
          return this.treatmentPlotService.getOffsetChartInputData(
            offsetChannels,
            this.treatmentInfo,
            flowPathType,
            offsetChartInputData,
            this.filterSetting,
            baseTreatmentDateTime,
            this.isRealtime,
            isRealtimeBourdet,
            this.treatmentsToken
          );
        })
      )
      .pipe(
        map(data => {
          if (data && data.length) {
            this.offsetChartInputData = data.filter(x => x);
          } else {
            this.offsetChartInputData = [];
          }
        })
      );
  }

  private updateSeriesData(isRedraw: boolean = true): void {
    let offsetDataState: IOffsetDataState;
    if (this.offsetChannelSetting && this.offsetChartInputData && this.offsetChartInputData.length) {
      offsetDataState = { channelSettings: this.offsetChannelSetting, channelData: this.offsetChartInputData }
    }
    // update chart data without reload chart
    this.highChart.updateSeriesData(this.chartInputData, this.filterSetting, isRedraw, offsetDataState);
  }

  private setLastMinFilter(lastMinFilter): void {
    const lastMin = (value: number, values: number[], defaultVal: number, isRealtime: boolean) => {
      let index = values.findIndex(x => x === value);
      if (index > -1) return values[index];
      if (!!isRealtime) {
        index = TIME_SETTINGS_TIME_OPTIONS.realtimeExtends.findIndex(x => x === value);
        if (index > -1) return TIME_SETTINGS_TIME_OPTIONS.realtimeExtends[index];
      }
      return defaultVal;
    }

    if (this.treatmentInfo && this.treatmentInfo.number === TREATMENT_MONITOR_NUMBER) {
      this.lastMinFilter = lastMin(lastMinFilter, TIME_SETTINGS_TIME_OPTIONS.monitor, DEFAULT_MONITOR_LAST_MIN_FILTER, this.isRealtime);
    } else {
      this.lastMinFilter = lastMin(lastMinFilter, TIME_SETTINGS_TIME_OPTIONS.treatment, DEFAULT_LAST_MIN_FILTER, this.isRealtime);
    }
    this.filterSetting.lastMin = this.lastMinFilter;
  }

  private initHighChartReflow = () => {
    if (!this.highChart) return;
    this.navbarScaleChartService.isLoading.subscribe(() => {
      setTimeout(() => {
        this.highChart.reflow();
      });
    });
  }

  initialize() { // only with treatment detail page
    // set selected comment type
    const selectedCommentType = this.treatmentDetailsStorageService.getCommentType(this.treatmentId);
    if (selectedCommentType && typeof selectedCommentType === 'string') {
      this.selectedCommentType = selectedCommentType;
    }
    // get FDI Parameter
    const selectedFDIParam = this.localDataStorageService.getOption(USER_STORAGE.fdiParam);
    this.selectedFDIParam = selectedFDIParam;

    this.initHighChartReflow();
    // init default values
    this.channelSetting = this.channelSelectionService.getDefaultSettings();
    // load offfset channel settings
    const localOffsetChannelSettings = this.realtimeTreatmentStorageService.getOption(this.wellId, KEY_OFFSET_CHANNEL_SETTINGS);
    this.offsetChannelSetting = localOffsetChannelSettings;
    // init side data
    this.initSideData();
    // Get well info
    this.initializeData();
  }

  onRefresh() {
    this.highChart.zoomOut();
    this.updateFDIDataPoints(this.fdiDataPayload.fdi);
    if (!this.isRealtime) return;
    this.isRefreshBourdet = true;
  }

  setChartInputState(channelSettings, filterSettings, inputData, offsetDataState?: IOffsetDataState) {
    // set los points unixTime
    if (!this.losPoints || this.losPoints.length === 0) {
      if (this.treatmentInfo && !isNil(this.treatmentInfo.timeEnd) && this.treatmentInfo.timeStart <= this.treatmentInfo.timeEnd) {
        this.losPoints = [];
        const minStart = !this.treatmentInfo.timeStart ? 0 : this.treatmentPlotTimeConverterService.convertXValueByTimeFormat({
          xValue: this.treatmentInfo.timeStart * 1000, timeFormat: this.timeFormat, baseTreatmentDataTime: this.baseTreatmentDataTime, wellTimeZoneOffset: this.wellTimeZoneOffset
        });
        const maxEnd = !this.treatmentInfo.timeEnd ? 0 : this.treatmentPlotTimeConverterService.convertXValueByTimeFormat({
          xValue: this.treatmentInfo.timeEnd * 1000, timeFormat: this.timeFormat, baseTreatmentDataTime: this.baseTreatmentDataTime, wellTimeZoneOffset: this.wellTimeZoneOffset
        });
        LOS_DEFAULTS.forEach(item => {
          const point = Object.assign({
            id: item.id,
            name: item.text,
            timeOffSet: item.id === ENUM_LOS_DEFAULTS.MinMaxStart ? minStart : maxEnd,
            color: item.color || Helper.randomHexColor(),
            isDefault: true
          }) as IDiagnosticPoint;
          this.losPoints.push(point);
        })
      }
    }
    // set up chartInputState
    this.chartInputState = {
      ...this.chartInputState,
      channelSettings: { ...channelSettings },
      filterSettings: { ...filterSettings },
      inputData: { ...inputData },
      offsetDataState,
      timezoneOffset: this.wellTimeZoneOffset,
      listDiagnosticPoints: this.listDiagnosticPoints,
      losPoints: this.losPoints,
      baseTreatmentDataTime: this.baseTreatmentDataTime,
      treatmentInfo: this.treatmentInfo,
      wellInfo: this.wellInfo,
      realtimeChannels: this.listRealtimeChannels,
      fracProChannels: this.listFracProChannels,
    };
  }

  private get getDataState(): ITreatmentPlotDataState {
    return {
      wellInfo: this.wellInfo,
      treatmentInfo: this.treatmentInfo,
      realtimeChannels: this.listRealtimeChannels,
      fracProChannels: this.listFracProChannels,
      listFlowpaths: this.listFlowpaths,
      listTreatmentComments: this.listTreatmentComments,
      channelSettings: this.channelSetting,
      filterSettings: this.filterSetting,
      chartInputData: this.chartInputData,
      offsetDataState: {
        channelSettings: this.offsetChannelSetting,
        channelData: this.offsetChartInputData,
        listFDIs: this.listFDIs
      },
      predictedCNames: this.listPredictedChannels.map(x => x.cName),
      timeRangeFilterValues: this.timeRangeFilterValues
    };
  }

  handleDataStateChange() {
    const offsetDataState: IOffsetDataState = {
      channelSettings: this.offsetChannelSetting,
      channelData: this.offsetChartInputData,
      listFDIs: this.listFDIs,
      fdiPayload: this.fdiDataPayload
    };

    this.setChartInputState(this.channelSetting, this.filterSetting, this.chartInputData, offsetDataState);
  }

  setFDIDataState(dataPayload: IFDIPayload) {
    if (dataPayload) {
      this.listFDIs = dataPayload.fdi || [];
      this.fdiDataPayload = dataPayload;
      this.treatmentPlotFdiStoreService.setDataPayload(dataPayload);
    }
  }

  initChannelSettings(flowPathType: number) {
    // get settings from local storage
    const settingsStored = this.channelSettingsStorageService.getByWell(this.wellId);
    const settings = this.channelSelectionService.updateChannels(settingsStored, this.listRealtimeChannels, this.listFracProChannels);

    let settingObservable$: Observable<any>;
    if (isChannelSettingModel(settings)) {
      // this.treatmentPlotService.checkChannelAppear(settings, this.listRealtimeChannels, this.listFracProChannels);
      // default auto scale is always false in realtime mode
      // use settings.autoScaleYAxis if not
      settings.autoScaleYAxis = this.isRealtime ? (isNil(settings.autoScaleYAxis) ? false : settings.autoScaleYAxis) : false;
      settingObservable$ = of(settings);
    } else {
      settingObservable$ = this.channelSelectionService.getDefaultSettingsObservable(
        this.wellId,
        this.treatmentId,
        flowPathType,
        this.listRealtimeChannels,
        true,
        true,
      );
    }

    return settingObservable$
      .pipe(switchMap(channelSettings => {
        // load selected template
        if (!isEmpty(this.dataPlotTemplates)) {
          const curPlotTemplateId = this.realtimeTreatmentStorageService.getOption(this.wellId, KEY_TEMPLATE_ID);
          let dataPlotTemplate;
          if (curPlotTemplateId) {
            dataPlotTemplate = this.dataPlotTemplates.find(item => item.id === curPlotTemplateId);
          } else if (!this.channelSettingsStorageService.getByWell(this.wellId)) { // in case unset local storage
            dataPlotTemplate = this.dataPlotTemplates.find(item => item.name === DEFAULT_INIT_TEMPLATE_NAME);
          }
          if (dataPlotTemplate && dataPlotTemplate.content) {
            this.loadTemplateSettings(dataPlotTemplate.content);
            this.selectedTemplate = dataPlotTemplate;
            let templateId: number;
            if (this.selectedTemplate) templateId = this.selectedTemplate.id;

            const isAutoScaleYAxis = !!this.isRealtime;
            this.channelSetting = this.channelSelectionService.plotTemplateToChannelSettings(dataPlotTemplate.content, this.listRealtimeChannels, this.listFracProChannels, this.channelMappingData, isAutoScaleYAxis, undefined, this.fracProChannelsLatestVer);
          } else {
            this.channelSetting = channelSettings;
          }
        } else {
          this.channelSetting = channelSettings;
        }
        // in realtime mode, need to load fracpro channels in grid data
        if (this.isRealtime) {
          const gridData = this.realtimeChartService.getFracproGridData(this.listFracProChannels);
          this.fracproGridDataCName = this.realtimeChartService.getCNameGridData(gridData);
        }
        return this.reloadChartData(this.channelSetting, false, this.fracproGridDataCName, flowPathType, this.offsetChannelSetting);
      }))
      .pipe(switchMap(data => {
        this.handleDataStateChange();
        return of(data);
      }));
  }

  onToggleFullChart() {
    this.isFullChart = !this.isFullChart;
    this.toggleFullChart.emit(this.isFullChart ? 1 : 0);
    setTimeout(() => this.highChart.reflow());
  }

  showChannelSettings() {
    this.dialogOpened = true;
    this.onChangeTimerState(TimerState.paused);
    let paramSettings = cloneDeep(this.channelSetting);
    let realtimeChannels = [...this.listRealtimeChannels];
    const mergedOffsetData = this.mergeOffsetChannelSettings(paramSettings, this.offsetChannelSetting, this.listRealtimeChannels);
    if (mergedOffsetData.channelSettings && mergedOffsetData.realtimeChannels) {
      paramSettings = mergedOffsetData.channelSettings;
      realtimeChannels = mergedOffsetData.realtimeChannels;
    }
    this.dialogService.open(ChannelSelectionComponent, {
      context: {
        title: 'Channel Selection',
        realtimeChannels,
        fracProChannels: this.treatmentNumber < 0 ? [] : this.listFracProChannels,
        settings: paramSettings,
        wellId: this.wellId,
        treatmentId: this.treatmentId,
        flowPathType: this.getFlowPathType(this.selectedFlowpath),
        showAutoScale: this.isRealtime,
        autoScaleYAxis: this.isRealtime ? this.channelSetting.autoScaleYAxis : false,
        listWells: this.listWells,
        hideCompareBtn: this.chartIndex ? true : false
      }
    }).onClose.subscribe(res => {
      this.dialogOpened = false;
      this.onChangeTimerState(TimerState.resumed);
      if (!res) return;
      const assignChannelSetting = (curSettings, newSettings) => {
        const result = cloneDeep(newSettings);
        if (!curSettings) return result;

        const curOffsetChannels = this.channelSelectionService.getListChannels(curSettings, true);
        for (const key in newSettings) {
          const newSetting: any = cloneDeep(newSettings[key]);
          if (newSetting && !isEmpty(newSetting.channels) && curOffsetChannels) {
            result[key].channels = newSetting.channels.map(item => {
              const channelExist = curOffsetChannels.find(x => x.wellId === item.wellId && x.treatmentId === item.treatmentId && x.name === item.name);
              if (channelExist) {
                const fillData = (keys: string[], n, o) => {
                  let data: any = {};
                  forEach(keys, key => {
                    if (item.hasOwnProperty(key)) data[key] = item[key];
                    else data[key] = channelExist[key];
                  });
                  return data;
                };
                const keys = uniq(Object.keys(item).concat(Object.keys(channelExist)));
                let result = fillData(keys, item, channelExist);
                const channelKeys = uniq(Object.keys(item.channel).concat(Object.keys(channelExist.channel)));
                result.channel = fillData(channelKeys, item.channel, channelExist.channel);

                return result;
              }
              else return item;
            });
          }
        }
        return result;
      };
      const settings = assignChannelSetting(this.offsetChannelSetting, res);

      const offsetChannelSetting = this.channelSelectionService.formatChannelSettingModel(settings, true);
      const channelSetting = this.channelSelectionService.formatChannelSettingModel(res);
      if (!this.isRealtime) {
        channelSetting.autoScaleYAxis = false;
      }

      // compare if there is a change in either this.channelSettings or this.offsetChannelSettings
      const isSameChannelSettings = isEqual(this.channelSetting, channelSetting);
      const isSameOffsetChannelSettings = isEqual(this.offsetChannelSetting, offsetChannelSetting);
      if (isSameChannelSettings && isSameOffsetChannelSettings) return;

      // prepare param to reloadChartData
      const flowPathType = this.getFlowPathType(this.selectedFlowpath);
      const isChangeChannels = this.channelSelectionService.checkIsChangedChannels(channelSetting, this.channelSetting);
      const isChangeOffsetChannels = this.channelSelectionService.checkIsChangedChannels(offsetChannelSetting, this.offsetChannelSetting, true);
      this.channelSetting = cloneDeep(channelSetting);
      this.offsetChannelSetting = cloneDeep(offsetChannelSetting);

      // remove selected template id
      this.realtimeTreatmentStorageService.removeOption(this.wellId, KEY_TEMPLATE_ID);
      this.selectedTemplate = null;
      // In realtime-mode: get auth token for offset channels
      if (this.isRealtime) {
        this.initializeTreatmentTokens();
        if (!!this.isResetTokenInterval) {
          this.onDestroyTokenInterval();
          setTimeout(() => { this.runIntervalLoadTreatmentsToken(); }, 100);
        }

        this.channelSettingChange.emit(this.channelSetting);
        this.offsetChannelSettingChange.emit(this.offsetChannelSetting);
        if (isChangeOffsetChannels) {
          this.isRefreshBourdet = true;
          this.reloadOffsetDataChart();
        }
        this.handleDataStateChange();
        return;
        // if (!this.offsetChannelSettings) return;
      } else {
        // if (!this.isRealtime || this.offsetChannelSettings) {}
        const reloadChartData = this.reloadChartData(channelSetting, !isChangeChannels, this.fracproGridDataCName, flowPathType, this.offsetChannelSetting);
        const reloadFDIData = this.treatmentPlotFdiApiService.getByTreatment(this.treatmentId);

        // save new channel settings for current well in local storage if isRealtime
        this.channelSettingsStorageService.saveByWell(this.wellId, this.channelSetting);

        const offsetChanelItems = this.channelSelectionService.toChannelItems(this.offsetChannelSetting, true);
        if (offsetChanelItems.length) this.realtimeTreatmentStorageService.setOption(this.wellId, KEY_OFFSET_CHANNEL_SETTINGS, this.offsetChannelSetting);
        else this.realtimeTreatmentStorageService.removeOption(this.wellId, KEY_OFFSET_CHANNEL_SETTINGS);

        // reload data and FDI also
        forkJoin([reloadChartData, reloadFDIData]).subscribe(data => {
          const newListFDIs = this.treatmentPlotFdiService.filterListFDIsByOffsetChannels(this.offsetChannelSetting, data[1].fdi);
          this.originListFDIs = cloneDeep(newListFDIs);
          const fdiData = Object.assign({}, data[1]);
          // filter list fdi by current offset channels
          fdiData.fdi = this.treatmentPlotFdiService.filterListFDIsByOffsetChannels(this.offsetChannelSetting, this.originListFDIs);
          // if there is fdiData.fdi data countFDI++ to init the right color on the next onAddFDI
          if (fdiData.fdi.length) this.setCountFDI(this.countFDI + fdiData.fdi.length);
          this.setFDIDataState(fdiData);
          this.handleDataStateChange();
          setTimeout(() => {
            this.toggleAnnotations();
          }, 200);
        });
      }

    });
  }

  reloadChartData(
    channelSettings: IChannelSettingModel,
    isNoFetchNewData?: boolean,
    fracproGridCName?: string[],
    flowPathType?: number,
    offsetChannelSettings?: IChannelSettingModel,
    timeSetting?: ITimeSetting
  ) {
    let offsetChannels: IChannelSettingItemModel[];
    if (offsetChannelSettings) {
      offsetChannels = this.channelSelectionService.getListChannels(offsetChannelSettings, true);
    }

    // redraw chart without call api to get data
    if (isNoFetchNewData) {
      if (!isEmpty(offsetChannels)) {
        this.isLoadingChartData = true;
        const requestTimeSetting = timeSetting ? timeSetting : this.filterSetting;
        return this.treatmentPlotService.getWellTreatmentsData(offsetChannels)
          .pipe(
            switchMap(treatmentItems => {
              return this.treatmentPlotService.getOffsetChartInputData(offsetChannels, this.treatmentInfo, flowPathType, null, requestTimeSetting, this.treatmentInfo.baseTreatmentDateTime, this.isRealtime)
                .pipe(switchMap(response => {
                  if (response && response.length) {
                    this.offsetChartInputData = response.filter(x => x);
                  }
                  if (this.isRealtime) {
                    const offsetTimeRange = this.baseChartService.mergeTimeRangeOffsetChannels(this.offsetChartInputData, this.timeRangeFilterValues);
                    if (offsetTimeRange) {
                      this.timeRangeFilterValues = offsetTimeRange;
                      this.timeRangeFilterValues.timefloat.start = 0;
                      this.timeRangeFilterValues.timestamp.start = this.treatmentInfo.timeStart * 1000;
                    }
                  }
                  if (this.filterSetting.startIndex === 0 && this.filterSetting.endIndex === 0) {
                    this.filterSetting = this.baseChartService.toFilterSettings(this.filterSetting.type, this.timeRangeFilterValues, this.wellInfo.tzTimezone, undefined, undefined, this.autoScaleTime);
                    this.filterSetting.lastMin = this.lastMinFilter;
                    this.treatmentDetailsStorageService.setFilterSettings(this.treatmentId, this.filterSetting);
                  }
                  return of(null);
                }));
            })
          )
          .pipe(
            finalize(() => {
              this.isLoadingChartData = false;
              this.treatmentDetailsStorageService.removeTreatmentData();
            })
          );
      } else {
        return of(null);
      }
    } else {
      this.isLoadingChartData = true;
      return this.treatmentPlotService.getWellTreatmentsData(offsetChannels)
        .pipe(
          switchMap(treatmentItems => {
            return this.treatmentPlotService.getChartDataHandler(
              this.wellId,
              this.treatmentId,
              this.treatmentInfo,
              channelSettings,
              offsetChannels,
              fracproGridCName,
              flowPathType,
              this.baseTreatmentDataTime,
              timeSetting,
              this.isRealtime,
              this.monitorOptions
            ).pipe(
              switchMap(res => {
                this.treatmentPlotDataStoreService.setChartInputData(res.chartInputData);
                this.timeRangeFilterValues = res.timeRangeFilter;
                if (this.timeRangeFilterValues) {
                  this.timeRangeFilterValues.timefloat.start = 0;
                  this.timeRangeFilterValues.timestamp.start = this.treatmentInfo.timeStart * 1000;
                }
                if (!this.filterSetting.endIndex || !timeSetting) {
                  this.filterSetting = this.baseChartService.toFilterSettings(this.filterSetting.type, this.timeRangeFilterValues, this.wellInfo.tzTimeZone, undefined, undefined, this.autoScaleTime);
                  this.filterSetting.lastMin = this.lastMinFilter;
                  this.treatmentDetailsStorageService.setFilterSettings(this.treatmentId, this.filterSetting);
                }
                this.chartInputData = this.treatmentPlotDataStoreService.getChartInputDataByLastMin(res.chartInputData, this.lastMinFilter);
                if (res.offsetChartInputData && res.offsetChartInputData.length) {
                  this.offsetChartInputData = res.offsetChartInputData.filter(x => x);
                }
                return of(res);
              })
            );
          })
        )
        .pipe(
          finalize(() => {
            this.isLoadingChartData = false;
            this.treatmentDetailsStorageService.removeTreatmentData();
          })
        );
    }
  }

  showTimeSettings() {
    this.dialogOpened = true;
    this.onChangeTimerState(TimerState.paused);
    this.dialogService.open(TimeSettingsComponent, {
      context: {
        wellItem: this.wellInfo,
        treatmentItem: this.treatmentInfo,
        model: this.filterSetting,
        realTime: this.isRealtime,
        autoScale: this.autoScaleTime,
        lastMinFilter: this.lastMinFilter,
        timeRangeFilter: cloneDeep(this.timeRangeFilterValues),
        // comment input
        showCommentOption: this.isDisplayComment,
        listCommentsOptions: !this.isRealtime,
        selectedCommentType: this.selectedCommentType
      }
    }).onClose.subscribe(res => {
      this.dialogOpened = false;
      this.onChangeTimerState(TimerState.resumed);
      if (!res) return;
      this.autoScaleTime = this.isRealtime ? res.autoScaleTime : false;
      const newTimeSettings = this.handleNewTimeSettings(res);
      // set time format settings in local storage
      this.timeFormat = newTimeSettings.type;
      this.realtimeTreatmentStorageService.setOption(this.wellId, 'timeFormat', this.timeFormat);

      if (this.isRealtime) {
        this.filterSettingChange.emit(newTimeSettings);
        return;
      }

      if (!isEqual(this.filterSetting, newTimeSettings) || this.lastMinFilter !== newTimeSettings.lastMin) {
        const flowPathType = this.getFlowPathType(this.selectedFlowpath);
        // set time format settings in local storage
        this.lastMinFilter = newTimeSettings.lastMin;
        this.realtimeTreatmentStorageService.setOption(this.wellId, KEY_LAST_MIN_FILTER, this.lastMinFilter);
        // check re-load data chart
        const isReloadChart = (treatmennt: any, newOpts: IBourdetOption, curOpts: IBourdetOption): boolean => {
          if (!treatmennt || treatmennt.number !== TREATMENT_MONITOR_NUMBER) return false;
          if (!curOpts) return false;
          if (!newOpts) return true;

          if (!curOpts.endDate || !newOpts.endDate) {
            if (newOpts.startDate < curOpts.startDate) return true;
            return false;
          }
          if (newOpts.startDate < curOpts.startDate) return true;
          if (!newOpts.endDate || !curOpts.endDate) return false;
          if (newOpts.endDate > curOpts.endDate) return true;

          return false;
        }
        const newMonitorOptions = this.getMonitorOptions(this.treatmentInfo, newTimeSettings, newTimeSettings.lastMin);
        if (isReloadChart(this.treatmentInfo, newMonitorOptions, this.monitorOptions) === true) {
          // set data base on this.filterSetting
          this.filterSetting = newTimeSettings;
          this.treatmentDetailsStorageService.setFilterSettings(this.treatmentId, this.filterSetting);
          this.monitorOptions = newMonitorOptions;
          // reload data by flowPathType
          this.reloadChartData(this.channelSetting, false, this.fracproGridDataCName, flowPathType, this.offsetChannelSetting, newTimeSettings)
            .subscribe((response) => { this.handleDataStateChange(); });
        } else {
          this.isLoadingChartData = true;
          // set data base on this.filterSetting
          this.filterSetting = newTimeSettings;
          this.treatmentDetailsStorageService.setFilterSettings(this.treatmentId, this.filterSetting);
          let chartData = this.treatmentPlotDataStoreService.getChartInputData();
          this.chartInputData = this.treatmentPlotDataStoreService.getChartInputDataByLastMin(chartData, this.lastMinFilter);
          this.treatmentPlotService.getWellTreatmentsData(this.getOffsetChannelList)
            .pipe(
              switchMap(treatmentItems => {
                return this.treatmentPlotService.getOffsetChartInputData(
                  this.getOffsetChannelList, this.treatmentInfo,
                  flowPathType, null, this.filterSetting,
                  this.treatmentInfo.baseTreatmentDateTime,
                  this.isRealtime
                )
                  .pipe(
                    map(data => {
                      if (data && data.length) {
                        this.offsetChartInputData = data.filter(x => x);
                      }
                      this.handleDataStateChange();
                      this.isLoadingChartData = false;
                    })
                  );
              })
            )
            .pipe(finalize(() => this.treatmentDetailsStorageService.removeTreatmentData()))
            .subscribe();
        }
      }

      if (this.selectedCommentType !== res.commentType) {
        this.selectedCommentType = res.commentType;
        this.treatmentDetailsStorageService.setCommentType(this.treatmentId, this.selectedCommentType);
        const annotationsOption = this.baseChartService.parseAnnotationsOption(this.listTreatmentComments, this.filterSetting.type, this.timeRangeFilterValues, this.highChart.getChartInstance());
        this.highChart.toggleAnnotations(annotationsOption, this.isShowAnnotation());
        // emit to parent comment type
        this.commentTypeChange.emit({ isDisplayComment: this.isDisplayComment, commentType: this.selectedCommentType });
      }
    });
  }

  isShowAnnotation() {
    return this.isDisplayComment && this.selectedCommentType === 'Annotation';
  }

  selectFlowpathHandler(data) {
    if (data) {
      const selectedItem = this.listFlowpaths.find(item => item.type === data.id);
      this.selectedFlowpath = [selectedItem];
      const flowPathType = this.getFlowPathType(this.selectedFlowpath);
      const isMonitor = (this.treatmentInfo && this.treatmentInfo.number === TREATMENT_MONITOR_NUMBER);

      // reload list channel by wellId, treatmentId, flowPathType
      this.subscriptions.push(
        this.treatmentService.getChannelsForSettings(this.wellId, this.treatmentId, flowPathType, isMonitor)
          .subscribe(
            res => {
              this.listRealtimeChannels = res.realtimeChannels;
              this.listFracProChannels = this.channelSelectionService.handleChannelsColor(res.fracProChannels, this.fracProChannelsLatestVer);
              // update fracpro channels on grid data
              this.fracproGridDataCName = this.realtimeChartService.getFracproCNameGridData(this.listFracProChannels);
              // reload data by flowPathType
              this.reloadChartData(this.channelSetting, false, this.fracproGridDataCName, flowPathType, this.offsetChannelSetting)
                .toPromise()
                .then(() => {
                  this.handleDataStateChange();
                  setTimeout(() => {
                    this.toggleAnnotations();
                  }, 200);
                });
            },
            err => err
          )
      );
    }
  }

  exportChart(type: string) {
    this.clickOutside();
    if (type === 'Raw Data') {
      let uniThreeOffsetChannel = [];
      if (this.offsetChannelSetting) {
        uniThreeOffsetChannel = this.offsetChannelSetting.firstLeft.channels
          .concat(this.offsetChannelSetting.secondLeft.channels)
          .concat(this.offsetChannelSetting.firstRight.channels)
          .concat(this.offsetChannelSetting.secondRight.channels);
      }
      const uniThreeAxisChannel = this.channelSetting.firstLeft.channels
        .concat(this.channelSetting.firstRight.channels)
        .concat(this.channelSetting.secondLeft.channels)
        .concat(this.channelSetting.secondRight.channels);
      const textContent = this.treatmentPlotService.getStringRawData(
        uniThreeAxisChannel, this.chartInputData.realtimeData[0], this.chartInputData.fracproData[0],
        this.minTime, this.maxTime, this.timeFormat, uniThreeOffsetChannel, this.offsetChartInputData
      );
      const file = new Blob([textContent], { type: 'text/plain;charset=utf-8' });
      let fileName = `${this.wellInfo.wellName}_${this.treatmentNumber}.txt`;
      if (TREATMENT_TYPE[this.treatmentInfo.dataType] === ENUM_TREATMENT_TYPE.Monitoring) {
        fileName = `${this.wellInfo.wellName}_${ENUM_TREATMENT_TYPE.Monitoring}.txt`;
      }

      saveAs(file, fileName);
    } else {
      const treatmentType = TREATMENT_TYPE[this.treatmentInfo.dataType];
      let fileName = `${this.wellInfo.wellName}_${treatmentType} ${this.treatmentNumber}`;
      if (treatmentType === ENUM_TREATMENT_TYPE.Monitoring) {
        fileName = `${this.wellInfo.wellName}_${ENUM_TREATMENT_TYPE.Monitoring}`;
      }
      this.highChart.exportChart(fileName.toUpperCase(), type);
    }
  }

  toggleDisplayTooltip(event) {
    this.isDisplayTooltip = !this.isDisplayTooltip;
    this.highChart.toggleTooltip(this.isDisplayTooltip);
    this.realtimeTreatmentStorageService.setOption(this.wellId, 'isDisplayTooltip', this.isDisplayTooltip);
  }

  getFlowPathType(selectedFlowpath) {
    let flowPathType = 0;
    if (!isEmpty(selectedFlowpath) && selectedFlowpath[0]) {
      flowPathType = selectedFlowpath[0].type;
    }
    return flowPathType;
  }

  openButtonDropdown() {
    this.optionsOpened = !this.optionsOpened;
  }

  clickOutside() {
    this.optionsOpened = false;
  }

  isEmptyAllChannels() {
    return isEmpty(this.listRealtimeChannels) && isEmpty(this.listFracProChannels);
  }

  private getTreatmentComments(wellId, treatmentId): Observable<any> {
    return this.treatmentService.getListComments(wellId, treatmentId)
      .pipe(map(response => {
        if (response && response.result && !isEmpty(response.result)) {
          return response.result;
        }
        return [];
      }));
  }

  toggleDisplayComment(event): void {
    this.isDisplayComment = !this.isDisplayComment;
    if (this.isDisplayComment) {
      // get list comments
      if (!this.listTreatmentComments) {
        this.loadTreatmentComments();
      } else {
        this.toggleAnnotations();
      }
    } else {
      this.toggleAnnotations();
    }
    if (!this.isRealtime) this.realtimeTreatmentStorageService.setOption(this.wellId, 'isDisplayComment', this.isDisplayComment);
    this.commentTypeChange.emit({ isDisplayComment: this.isDisplayComment, commentType: this.selectedCommentType });
  }

  private toggleAnnotations(): void {
    const annotationsOption = this.baseChartService.parseAnnotationsOption(this.listTreatmentComments, this.filterSetting.type, this.timeRangeFilterValues, this.highChart.getChartInstance());
    this.highChart.toggleAnnotations(annotationsOption, this.isDisplayComment);
  }

  onReloadChartData(eventData) {
    if (this.highChart) {
      const annotationsOption = this.baseChartService.parseAnnotationsOption(this.listTreatmentComments, this.filterSetting.type, this.timeRangeFilterValues, this.highChart.getChartInstance());
      this.highChart.toggleAnnotations(annotationsOption, this.isDisplayComment);
      this.highChart.toggleTooltip(this.isDisplayTooltip);
      this.updateFDIDataPoints(this.fdiDataPayload.fdi, true);

    }
  }

  loadPageOptions(wellId) {
    forEach(this.flagKeys, (key) => {
      if (!this.isRealtime || key !== 'isDisplayComment') {
        const value = this.realtimeTreatmentStorageService.getOption(wellId, key);
        if (value !== null) {
          this[key] = value;
        }
      }
    });
  }

  toggleDisplayDiagnostics(e) {
    this.isDisplayDiagnostics = !this.isDisplayDiagnostics;
    // reset selected state of list diagnostic points
    if (this.isDisplayDiagnostics === false) {
      this.listDiagnosticPoints.forEach(point => {
        point.selected = false;
        this.highChart.toggleDiagnosticPoints(point);
      });
    }
  }

  getMinMaxTime(eventData) {
    this.onChangeTimerState(TimerState.paused);
    this.minTime = eventData.minX;
    this.maxTime = eventData.maxX;
    setTimeout(() => {
      this.updateFDIDataPoints(this.fdiDataPayload.fdi);
      if (!this.dialogOpened) this.onChangeTimerState(TimerState.resumed)
    });
  }

  onDiagnosticPointDrag(event): void {
    this.onChangeTimerState(TimerState.paused);
  }

  onDiagnosticPointDrop(eventData) {
    const isDefault = DEFAULT_DIAGNOSTIC_GROUP_ID.concat(DEFAULTS_LOS_GROUP_ID).includes(eventData.groupId);
    if (!isDefault) {
      this.subscriptions.push(
        this.updateDiagnosticPointHandler(eventData)
          .pipe(
            finalize(() => {
              if (!this.dialogOpened) this.onChangeTimerState(TimerState.resumed);
            })
          )
          .subscribe(timeOffSet => {
            // update list diagnostic points
            this.updateTreatmentDiagnosticData(eventData.groupId, timeOffSet);
            this.defaultDiagnosticPointChange.emit();
            this.updateTreatmentBallSeatData(eventData);
            this.updateTreatmentISIPData(eventData);
          })
      );
    } else {
      // check channel exist
      const treatmentDepentdentValue = TREATMENT_DEPENTDENT_VALUES[eventData.groupId];
      const channelName = treatmentDepentdentValue.map(x => x.realtimeChannel).join(',');
      let flag: number = 0;
      for (let i = 0; i < treatmentDepentdentValue.length; i++) {
        if (treatmentDepentdentValue[i].realtimeChannel === undefined) {
          flag++
          break;
        }
        if (!this.listRealtimeChannels) break;
        if (
          this.listRealtimeChannels.findIndex(item => (
            item.originalName === treatmentDepentdentValue[i].realtimeChannel ||
            item.name === treatmentDepentdentValue[i].realtimeChannel
          )) === -1
        ) continue;
        flag++
        break;
      }

      if (flag > 0) {
        this.subscriptions.push(
          this.updateDefaultDiagnosticPointsHandler(eventData)
            .pipe(
              finalize(() => { if (!this.dialogOpened) this.onChangeTimerState(TimerState.resumed); })
            )
            .subscribe(res => {
              // fpDiagnostic: [{name: "MinMaxStart", timeOffset: 507}];
              const isLOS = DEFAULTS_LOS_GROUP_ID.includes(eventData.groupId);
              if (res !== null) {
                if (res.fpDiagnostic && res.fpDiagnostic.length) {
                  this.updateTreatmentDefaultDiagnosticData(res.fpDiagnostic, true);
                  if (!!isLOS && (!res.diagnostic || !res.diagnostic.length)) this.defaultDiagnosticPointChange.emit();
                }
                if (res.diagnostic && res.diagnostic.length) {
                  this.updateTreatmentDefaultDiagnosticData(res.diagnostic, false);
                  this.defaultDiagnosticPointChange.emit();
                  this.updateTreatmentBallSeatData(eventData);
                  this.updateTreatmentISIPData(eventData);
                }
              } else if (isLOS) {
                const point = this.losPoints.find(p => p.id === eventData.groupId);
                this.highChart.updateLOSPoint(point.timeOffSet, eventData.groupId);
              }
            })
        );
      } else {
        // Treating Pressure channel does not exist
        this.toasterService.showError(`${channelName} channel does not exist`);
        setTimeout(() => {
          const point = this.listDiagnosticPoints.find(item => item.id === eventData.groupId)
          if (point) this.highChart.updateDiagnosticPoint(point);
        }, 100);
      }
    }
  }

  private updateTreatmentDiagnosticData(groupId: string, res: number) {
    const index = this.listDiagnosticPoints.findIndex(x => x.id === groupId);
    if (index < 0) return;
    this.listDiagnosticPoints[index].timeOffSet = res;
  }

  private updateTreatmentDefaultDiagnosticData(response: any[] | IDefaultDiagnosticPointResponse[] | IDefaultFpDiagnosticPointResponse[], isDefaultV2?: boolean) { // isDefaultV2: fpDiagnostics & LOS point
    if (!response || !response.length) return;
    for (const res of response) {
      const requestId = !isDefaultV2 ? res.treatmentColumnName : res.name;
      const diagnosticId = this.defaultDiagnosticPointsService.parseRequestToId(requestId);
      if (!diagnosticId) continue;
      const diagIndex = this.listDiagnosticPoints.findIndex(x => x.id === diagnosticId);
      if (diagIndex >= 0) this.listDiagnosticPoints[diagIndex].timeOffSet = res.timeOffset;

      const losIndex = this.losPoints.findIndex(x => x.id === diagnosticId);
      if (losIndex >= 0) this.losPoints[losIndex].timeOffSet = res.timeOffset;
    }
  }

  private updateTreatmentBallSeatData(eventData) {
    if (eventData.groupId !== ENUM_DIAGNOSTIC_DEFAULTS.BallSeatTime || !this.fdiDataPayload || !this.fdiDataPayload.fdi.length) return;
    const timeOffset = this.treatmentPlotTimeConverterService.convertXValueByTimeFormat({ xValue: eventData.x, timeFormat: this.timeFormat, baseTreatmentDataTime: this.baseTreatmentDataTime, wellTimeZoneOffset: this.wellTimeZoneOffset });
    const listFDIs = this.fdiDataPayload.fdi.map(item => ({ id: item.id, ballSeatTime: timeOffset }));
    const payload = { fdi: listFDIs };
    this.treatmentPlotFdiApiService.updateByTreatment(this.treatmentId, payload, this.isRealtime)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => this.updateFDIDataState(res));
  }

  private updateTreatmentISIPData(eventData) {
    if (eventData.groupId !== ENUM_DIAGNOSTIC_DEFAULTS.InitialShutinPresTime || !this.fdiDataPayload || !this.fdiDataPayload.fdi.length) return;
    const timeOffset = this.treatmentPlotTimeConverterService.convertXValueByTimeFormat({ xValue: eventData.x, timeFormat: this.timeFormat, baseTreatmentDataTime: this.baseTreatmentDataTime, wellTimeZoneOffset: this.wellTimeZoneOffset });
    const listFDIs = this.fdiDataPayload.fdi.map(item => ({ id: item.id, ISIPTime: timeOffset }));
    const payload = { fdi: listFDIs };
    this.treatmentPlotFdiApiService.updateByTreatment(this.treatmentId, payload, this.isRealtime)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => this.updateFDIDataState(res));
  }

  onDisplayDiagnosticPoints(eventData: IDiagnosticPointDataChange | IDiagnosticPointDataChange[]) {
    if (!eventData) return;
    const isDefault = (groupId) => {
      return DEFAULT_DIAGNOSTIC_GROUP_ID.concat(DEFAULTS_LOS_GROUP_ID).includes(groupId);
    }

    if (eventData instanceof Array) {
      let points: IDiagnosticPointDataChange[] = eventData.filter(point => isDefault(point.groupId) === false);
      let pointsDefault: IDiagnosticPointDataChange[] = eventData.filter(point => isDefault(point.groupId) === true);
      this.subscriptions.push(
        forkJoin(
          points.length ? this.updateDiagnosticPointHandler(points) : of(null),
          pointsDefault.length ? this.updateDefaultDiagnosticPointsHandler(pointsDefault) : of(null)
        ).subscribe(res => {
          if (res[0] && res[0].length) {
            // update list diagnostic points
            this.listDiagnosticPoints.forEach(item => {
              const index = points.findIndex(point => item.id === point.groupId);
              if (index > -1) {
                item.timeOffSet = res[0][index].timeOffset;
                this.highChart.updateDiagnosticPoint(item);
              }
            });
          }
        })
      );
    } else {
      if (isDefault(eventData.groupId) === false) {
        this.subscriptions.push(
          this.updateDiagnosticPointHandler(eventData)
            .subscribe((timeOffset) => {
              // update list diagnostic points
              this.listDiagnosticPoints.forEach(item => {
                if (item.id === eventData.groupId) {
                  item.timeOffSet = timeOffset;
                  this.highChart.updateDiagnosticPoint(item);
                }
              });
            })
        );
      } else {
        this.subscriptions.push(
          this.updateDefaultDiagnosticPointsHandler(eventData).subscribe()
        );
      }
    }
  }

  toggleDisplayLosPoints(): void {
    if (!this.losPoints) return;
    this.isDisplayLOSPoints = !this.isDisplayLOSPoints;
    this.losPoints = this.losPoints.map(point => {
      point.selected = !!this.isDisplayLOSPoints;
      return point;
    })
    this.highChart.toggleDiagnosticPoints(this.losPoints, CHART_SERIES_NAME.los)
  }

  onToggleDiagnosticPoint(point: IDiagnosticPoint, keyOpt?) {
    if (point) {
      this.highChart.toggleDiagnosticPoints(point, keyOpt);
    }
  }

  updateDiagnosticPointHandler(eventData: IDiagnosticPointDataChange | IDiagnosticPointDataChange[]): Observable<any> {
    if (!eventData) return of(null);
    if (eventData instanceof Array) {
      // do something
    } else {
      if (!eventData.groupId) return of(null);

      const timeOffset = this.treatmentPlotTimeConverterService.convertXValueByTimeFormat({
        xValue: eventData.x, timeFormat: this.timeFormat, baseTreatmentDataTime: this.baseTreatmentDataTime, wellTimeZoneOffset: this.wellTimeZoneOffset
      });
      const payload = {
        id: eventData.groupId,
        nameId: null,
        timeOffSet: timeOffset
      };
      if (isNaN(parseInt(eventData.groupId, 10))) {
        payload.id = null;
        payload.nameId = eventData.groupId;
      }
      return this.diagnosticPointsService.updateByWellTreatment(this.wellId, this.treatmentId, payload).pipe(map(data => timeOffset));
    }
  }

  updateDefaultDiagnosticPointsHandler(eventData: IDiagnosticPointDataChange | IDiagnosticPointDataChange[]): Observable<{
    fpDiagnostic: IDefaultDiagnosticPointResponse[], diagnostic: IDefaultDiagnosticPointResponse[]
  }> {
    if (!eventData) return of(null);
    // set request payload for API
    const setDiagnosticParam = (point: IDiagnosticPointDataChange): IDiagnosticParam => {
      const isLOS = DEFAULTS_LOS_GROUP_ID.includes(point.groupId);
      const isDefaultPoint = DEFAULT_DIAGNOSTIC_GROUP_ID.includes(point.groupId);
      if (!isDefaultPoint && !isLOS) return null;

      const timeOffset = this.treatmentPlotTimeConverterService.convertXValueByTimeFormat({
        xValue: point.x, timeFormat: this.timeFormat, baseTreatmentDataTime: this.baseTreatmentDataTime, wellTimeZoneOffset: this.wellTimeZoneOffset
      });
      if (!!isLOS) {
        if (point.groupId === ENUM_LOS_DEFAULTS.MinMaxStart) {
          const maxPoint = this.losPoints.find(p => p.id !== point.groupId);
          if (maxPoint.timeOffSet < timeOffset) return null;
        }
        if (point.groupId === ENUM_LOS_DEFAULTS.MinMaxEnd) {
          const minPoint = this.losPoints.find(p => p.id !== point.groupId);
          if (minPoint.timeOffSet > timeOffset) return null;
        }
      }
      return { timeOffSet: timeOffset, name: point.groupId };
    };
    // Calling api update
    let diagnosticParams: IDiagnosticParam[];
    if (eventData instanceof Array) {
      diagnosticParams = eventData.map(point => setDiagnosticParam(point)).filter(point => point !== null);
      if (!diagnosticParams || !diagnosticParams.length) return of(null);
    } else {
      const param = setDiagnosticParam(eventData);
      if (!param) return of(null);
      diagnosticParams = [param];
    }
    return this.defaultDiagnosticPointsService.handleUpdate(
      this.wellId,
      this.treatmentId,
      this.getFlowPathType(this.selectedFlowpath),
      diagnosticParams,
      this.listRealtimeChannels,
      this.listFracProChannels,
      this.baseTreatmentDataTime
    );
  }

  validTimeOffsetByTimeFormat(xValue) {
    let timeOffset;
    if (xValue < 0) {
      xValue = 10 * 60;
    }
    if (this.timeFormat === TIME_SETTING_TYPE.EpochTime) {
      timeOffset = xValue - new Date(this.baseTreatmentDataTime).getTime() - this.wellTimeZoneOffset * 60 * 1000; // milliseconds
      timeOffset = timeOffset / 1000; // in seconds
    } else {
      if (xValue > 60000) {
        xValue = 200 * 60;
      }
      timeOffset = xValue; // in seconds
    }
    return timeOffset;
  }

  onSelectPlotTemplate(plotTemplate: IPlotTemplateResponse) {
    if (!plotTemplate || !plotTemplate.content) return;

    const template = plotTemplate.content;
    const autoScaleYAxis = this.isRealtime ? this.channelSetting.autoScaleYAxis : false;
    const channelSetting = this.channelSelectionService.plotTemplateToChannelSettings(template, this.listRealtimeChannels, this.listFracProChannels, this.channelMappingData, autoScaleYAxis, undefined, this.fracProChannelsLatestVer);
    const targetTimeFormat = template.timeFormat || TIME_SETTING_TYPE.OffsetTime;
    const isChangeTime = targetTimeFormat === this.timeFormat;
    this.selectedTemplate = plotTemplate;

    // case 1: real time page
    if (this.isRealtime) {
      const isChangeChannels = this.channelSelectionService.checkIsChangedChannels(channelSetting, this.channelSetting, true);
      // set and callback filterSettingChange
      if (isChangeTime) {
        this.timeFormat = targetTimeFormat;
        this.filterSetting.type = targetTimeFormat;
        this.filterSettingChange.emit(this.filterSetting);
      }
      if (isChangeChannels) {
        this.channelSetting = channelSetting;
        this.channelSettingChange.emit(this.channelSetting);
      }
      this.handleDataStateChange();
      setTimeout(() => {
        this.toggleAnnotations();
      }, 200);
    }
    // case 2: detail page
    else {
      const isChangeChannels = this.channelSelectionService.checkIsChangedChannels(channelSetting, this.channelSetting);
      this.loadTemplateSettings(template);
      this.channelSetting = channelSetting;
      const flowPathType = this.getFlowPathType(this.selectedFlowpath);
      // reload chart data
      this.reloadChartData(this.channelSetting, !isChangeChannels, this.fracproGridDataCName, flowPathType, this.offsetChannelSetting)
        .toPromise().then(() => {
          this.handleDataStateChange();
          setTimeout(() => {
            this.toggleAnnotations();
          }, 200);
        });

      // save newSettings to storageService
      // if there is a min/max set autoScaleYAxis to false
      const excludedKeys = this.isRealtime ? [] : ['autoScaleYAxis'];
      const isScaleChannel = channel => isNumber(channel.min) && isNumber(channel.max);

      const isScaleSettings = channelSettings => {
        for (const i of YAXIS_KEYS) {
          if (isScaleChannel(channelSettings[i])) return false;
        }
        return true;
      };

      this.channelSetting.autoScaleYAxis = isScaleSettings(this.channelSetting);
      this.channelSettingsStorageService.saveByWell(this.wellId, this.channelSetting, excludedKeys);
      // save template id in local
      if (plotTemplate.id) {
        this.realtimeTreatmentStorageService.setOption(this.wellId, KEY_TEMPLATE_ID, plotTemplate.id);
      }
    }
  }

  loadTemplateSettings(template: IPlotTemplate) {
    this.timeFormat = template.timeFormat || TIME_SETTING_TYPE.OffsetTime;
    const timeFormatStorage = this.realtimeTreatmentStorageService.getOption(this.wellId, KEY_TIME_FORMAT);
    if (timeFormatStorage) {
      if (timeFormatStorage === TIME_SETTING_TYPE.OffsetTime) this.timeFormat = timeFormatStorage;
      if (timeFormatStorage === TIME_SETTING_TYPE.EpochTime) this.timeFormat = timeFormatStorage;
    }
    this.filterSetting.type = this.timeFormat;

    if (this.timeRangeFilterValues) {
      this.filterSetting = this.handleNewTimeSettings(this.filterSetting);
    } else {
      this.filterSetting.type = this.timeFormat;
    }
    this.treatmentDetailsStorageService.setFilterSettings(this.treatmentId, this.filterSetting);

    this.isDisplayTooltip = !!template.isShowTooltip;
    if (this.isDisplayComment === undefined || this.isDisplayComment === null) {
      this.isDisplayComment = !!template.isShowComments;
    }
    if (!this.selectedCommentType) {
      this.selectedCommentType = template.commentType || 'Annotation';
      this.treatmentDetailsStorageService.setCommentType(this.treatmentId, this.selectedCommentType);
    }
    this.commentTypeChange.emit({ isDisplayComment: this.isDisplayComment, commentType: this.selectedCommentType });
  }

  onTemplatesChange(eventData) {
    this.loadingPlotTemplates = true;
    this.subscriptions.push(
      this.plotTemplateService.getByWell(this.wellId)
        .pipe(
          finalize(() => this.loadingPlotTemplates = false)
        )
        .subscribe(
          res => {
            this.dataPlotTemplates = res.result;
          }
        )
    );
  }

  mergeOffsetChannelSettings(
    channelSettings: IChannelSettingModel, offsetChannelSettings: IChannelSettingModel, listRealtimeChannels: IChannelItem[]
  ): { channelSettings: IChannelSettingModel, realtimeChannels: IChannelItem[] } {
    let paramSettings;
    let realtimeChannels;
    if (channelSettings && offsetChannelSettings && listRealtimeChannels) {
      // merge offset channel settings
      paramSettings = this.compareOffsetChannelsService.mergeChannelSettings(channelSettings, offsetChannelSettings);
      // merge offset channels with realtime channels
      const offsetChanelItems = this.channelSelectionService.toChannelItems(offsetChannelSettings, true);
      realtimeChannels = [...listRealtimeChannels, ...offsetChanelItems];
    }

    return { channelSettings: paramSettings, realtimeChannels };
  }

  private getCompanyDiagnostics() {
    const filterDiagnostic = diagnostic => {
      if (!diagnostic || !this.wellInfo || !this.wellInfo.diagnostics) return false;
      for (const wellDiagnostic of this.wellInfo.diagnostics) {
        if (wellDiagnostic.toString() === diagnostic.id.toString()) return true;
      }
      return false;
    };

    const companyDiagnostics = this.companiesDiagnostics
      .filter(x => filterDiagnostic(x))
      .map(x => {
        x.id = `${x.id}`;
        x.isDefault = false;
        return x;
      });
    return companyDiagnostics;
  }

  private setUpChannelSettings = () => {
    if (this.isEmptyAllChannels() === true) this.channelSetting = this.channelSelectionService.getDefaultSettings();
    else {
      const storage = this.channelSettingsStorageService.getByWell(this.wellId);
      if (!storage) {
        if (this.selectedTemplate) {
          const autoScaleYAxis = this.isRealtime && this.channelSetting ? this.channelSetting.autoScaleYAxis : false;
          this.channelSetting = this.channelSelectionService.plotTemplateToChannelSettings(this.selectedTemplate.content, this.listRealtimeChannels, this.listFracProChannels, this.channelMappingData, autoScaleYAxis, undefined, this.fracProChannelsLatestVer);
        } else this.channelSetting = this.channelSelectionService.getDefaultSettings();
      }
      else {
        const settings = cloneDeep(storage);
        YAXIS_KEYS.forEach(key => {
          if (settings[key] && !isEmpty(settings[key].channels)) {
            settings[key].channels.forEach(channelItem => {
              let channelName: string = channelItem.name;
              let cName: string = channelItem.cName;
              if (!channelName && channelItem.channel) channelName = channelItem.channel.name;
              if (!cName && channelItem.channel) cName = channelItem.channel.cName;

              let curChannel = this.listRealtimeChannels.find(cnel => cnel.name === channelName && cName.indexOf('C') === 0);
              if (!curChannel) curChannel = this.listFracProChannels.find(cnel => cnel.name === channelName && cName.indexOf('F') === 0);
              if (curChannel) {
                channelItem.name = curChannel.name;
                channelItem.cName = curChannel.cName;
                channelItem.originalName = curChannel.originalName;
                channelItem.unit = curChannel.unit;
                channelItem.measureType = curChannel.measureType;
                if (channelItem.channel) {
                  channelItem.channel.name = curChannel.name;
                  channelItem.channel.cName = curChannel.cName;
                  channelItem.channel.originalName = curChannel.originalName;
                  channelItem.channel.unit = curChannel.unit;
                  channelItem.channel.measureType = curChannel.measureType;
                }
              } else {
                channelItem.cName = '';
              }
            });
          }
        });
        this.channelSetting = settings;
      }
    }
  }

  reloadDataByTreatment(treatmentId: number): void {
    this.treatmentId = treatmentId;
    this.isLoadingChartData = true;
    const companyDiagnostics = this.getCompanyDiagnostics();
    const paramNames = companyDiagnostics ? companyDiagnostics.map(diagnostics => diagnostics.name) : [];

    this.reloadFracProChannelsStorage();
    const fracProChannelsLatestVer$ = this.fracProChannelsLatestVer && this.fracProChannelsLatestVer.length ? of(true) : this.fracproChannelsService.getLatestVersion();

    // update side data
    this.subscriptions.push(
      forkJoin(
        this.diagnosticPointsService.getDataForPivotPoints(this.treatmentId, paramNames, this.userCompanyId)
          .pipe(switchMap(pivotPoints => {
            const result = cloneDeep(pivotPoints);
            if (companyDiagnostics) {
              forEach(companyDiagnostics, (point) => {
                if (pivotPoints.findIndex(x => x.id === point.id) === -1) result.push(point);
              });
            }
            return of(result);
          })),
        this.treatmentPlotFdiApiService.getByTreatment(this.treatmentId),
        fracProChannelsLatestVer$
      ).subscribe(res => {
        // set list comments and list diagnostic points
        const pivotPoints = res[0] ? (Array.isArray(res[0]) ? res[0] : [res[0]]) : [];
        const resListDiagnosticPoints = pivotPoints.filter(point => point.name !== 'MinMaxStart' && point.name !== 'MinMaxEnd');
        const resListLosPoints = pivotPoints.filter(point => point.name === 'MinMaxStart' || point.name === 'MinMaxEnd');
        let handlePivotPoints = (curList: IDiagnosticPoint[], newList: any[]) => {
          let results = cloneDeep(newList);
          for (const pt of curList) {
            if (!pt.selected) continue;
            const findPt = results.find(x => x.id === pt.id);
            if (!findPt) continue;
            findPt.selected = pt.selected;
          }
          return results;
        }
        this.losPoints = handlePivotPoints(this.losPoints, resListLosPoints);
        this.listDiagnosticPoints = handlePivotPoints(this.listDiagnosticPoints, resListDiagnosticPoints);
        // set treatment FDI
        if (res[1]) {
          this.originListFDIs = cloneDeep(res[1].fdi);
          // filter list fdi by current offset channels
          res[1].fdi = this.treatmentPlotFdiService.filterListFDIsByOffsetChannels(this.offsetChannelSetting, this.originListFDIs);
        }
        this.setFDIDataState(res[1]);
        this.updateFDIDataPoints(this.fdiDataPayload.fdi, true);
        // save fracpro channel latest verion to storage
        if (res[2] && res[2].result) {
          this.fracProChannelsLatestVer = res[2].result;
          this.fracproChannelStorageService.setFracproChannels(this.fracProChannelsLatestVer);
        }
      })
    );

    // Get well info
    const subs = this.treatmentService.getTreatmentDetailWebApi(this.wellId, this.treatmentId)
      .pipe(switchMap(res => {
        this.initTreatmentInfo(res.data);
        // Get list flow paths
        return this.treatmentService.getListFlowPaths(this.wellId, this.treatmentId);
      }))
      .pipe(switchMap(res => {
        if (res && !isEmpty(res.result)) {
          this.listFlowpaths = res.result.map(item => {
            return { type: item.type, name: item.name };
          });
          this.selectedFlowpath = [this.listFlowpaths[0]];
        }
        const flowPathType = this.getFlowPathType(this.selectedFlowpath);

        return of(flowPathType);
      }))
      .pipe(switchMap(flowPathType => {
        const isMonitor = (this.treatmentInfo && this.treatmentInfo.number === TREATMENT_MONITOR_NUMBER);
        // Get list channels
        return this.treatmentService.getChannelsForSettings(this.wellId, this.treatmentId, flowPathType, isMonitor);
      }))
      .pipe(switchMap(res => {
        // Set realtime channels, fracpro channels
        this.listRealtimeChannels = res.realtimeChannels;
        this.listFracProChannels = this.channelSelectionService.handleChannelsColor(res.fracProChannels, this.fracProChannelsLatestVer);
        const flowPathType = this.getFlowPathType(this.selectedFlowpath);

        // update channel settings by realtime channels
        this.setUpChannelSettings();
        // call api get chart data, init interval api call
        if (!this.isEmptyAllChannels()) {
          // reload chart data
          return this.reloadChartData(this.channelSetting, false, this.fracproGridDataCName, flowPathType, this.offsetChannelSetting)
            .pipe(switchMap(data => {
              this.handleDataStateChange();
              return of(data);
            }));
        } else {
          // do nothing if data of channels are empty
          // set message as no data
          this.highChart.resetChart(true);
          this.highChart.setChartMessage(CHART_MESSAGES.en.noData);
          this.isLoadingChartData = false;

          return of(null);
        }
      }))
      .pipe(finalize(() => {
        // emit data to parent
        this.initDone.emit(this.getDataState);
        this.isLoadingChartData = false;
        setTimeout(() => {
          this.toggleAnnotations();
        }, 200);
      }))
      .subscribe(() => {
      });

    this.subscriptions.push(subs);
  }


  ////////////////////////////////////////
  // these function related to CRUD FDI //
  ////////////////////////////////////////

  onAddFDI() {
    if (!this.offsetChartInputData.length) return;
    // filter offset unique well
    const activeWellIds = this.offsetChartInputData.map(x => x.wellId).filter((x, i, a) => a.indexOf(x) === i);

    // function to handleAddFDI
    const handleAddFDI = (selectedWell, offsetWells) => {
      if (!selectedWell) return;
      const offsetData = offsetWells.find(item => item.wellId === selectedWell.wellId);
      this.handleAddFDI(offsetData);
      this.countFDI++;
    };

    // if there are more than 1 offset well
    if (activeWellIds.length > 1) {
      const context = { listWells: this.listWells, activeWellIds };
      this.dialogService.open(FdiWellSelectionModalComponent, { context }).onClose.subscribe(selectedWell => handleAddFDI(selectedWell, this.offsetChartInputData));
    }
    // if there is only 1 offset well
    // that including normal and bourdet der from 1 offset well
    else {
      const selectedWell = { wellId: this.offsetChartInputData[0].wellId };
      handleAddFDI(selectedWell, this.offsetChartInputData);
    }
  }

  handleAddFDI(offsetData: IInputDataMultiFlow) {
    if (offsetData) {
      let treatmentStart = this.treatmentInfo.timeStart;
      if (treatmentStart <= this.treatmentInfo.baseTreatmentDateTime) {
        treatmentStart = this.treatmentInfo.baseTreatmentDateTime + 1;
      }

      if (this.filterSetting.type === TIME_SETTING_TYPE.EpochTime) {
        const startIndex = Math.round(this.filterSetting.startIndex / 1000);
        if (treatmentStart <= startIndex) treatmentStart = startIndex + 1;
      }

      const paddingSec = 3 * 60;
      let treatmentEnd = this.treatmentInfo.timeEnd;
      if (!treatmentEnd || isNaN(treatmentEnd)) {
        const lastData = this.lastRealtimeData();
        if (!lastData) treatmentEnd = treatmentStart + (this.countFDI + 3) * paddingSec;
        else {
          treatmentEnd = Math.floor(new Date(lastData[0]).getTime() / 1000);
        }
      }

      if (this.filterSetting.type === TIME_SETTING_TYPE.EpochTime) {
        const endIndex = Math.round(this.filterSetting.endIndex / 1000);
        if (treatmentEnd >= endIndex) treatmentEnd = endIndex - 1;
      }

      const fdiStart = treatmentStart + this.countFDI * paddingSec;
      let fdiEnd = treatmentEnd - this.countFDI * paddingSec;
      if (fdiEnd < fdiStart) fdiEnd = fdiStart;

      
      const peakFDIStart = treatmentStart + 2 * this.countFDI * paddingSec;
      const leakOffStart = treatmentStart + 3 * this.countFDI * paddingSec;
      const peakFDIEnd = treatmentStart + 4 * this.countFDI * paddingSec;
      const leakOffEnd = treatmentStart + 5 * this.countFDI * paddingSec;

      // for some reason countFDI starting at 2
      const fdiInfo = new TreatmentPlotFDI({
        fdiStart, fdiEnd, peakFDIStart, peakFDIEnd, leakOffStart, leakOffEnd, wellId: offsetData.wellId, treatmentId: offsetData.treatmentId,
        color: this.treatmentPlotFdiService.getFDIMarkerColor(this.countFDI, 1),
        cName: offsetData.cName
      });
      const payload: IFDIPayload = {
        fdi: [fdiInfo]
      };
      const isStaticFDI = !this.listFDIs || isEmpty(this.listFDIs);
      if (isStaticFDI) {
        payload.treatmentStart = treatmentStart;
        payload.treatmentEnd = treatmentEnd;
      }

      // do NOT forget to pass filterSettings to add minDrag and maxDrag for FDI
      this.treatmentPlotFdiApiService.createByTreatment(this.treatmentId, payload, this.isRealtime)
        .pipe(takeUntil(this.destroy$))
        .subscribe(newFDI => {
          newFDI.countAddFDI = this.countFDI;
          this.highChart.addFDIHandler({
            baseTreatmentDataTime: this.baseTreatmentDataTime,
            timeFormat: this.timeFormat,
            timezoneOffset: this.wellTimeZoneOffset,
            isStaticFDI,
            fdiInfo: newFDI,
            treatmentInfo: this.treatmentInfo,
            startIndex: this.filterSetting.startIndex,
            endIndex: this.filterSetting.endIndex,
            treatmentStart,
            treatmentEnd
          });
          const newFDIPayload = { ...this.fdiDataPayload };
          newFDIPayload.fdi = [...newFDIPayload.fdi, newFDI];
          this.setFDIDataState(newFDIPayload);
          this.updateFDIDataPoints(newFDIPayload.fdi, true);
        });
    }
  }

  onAutoFDI() {
    if (!this.offsetChartInputData.length) return;
    if (!this.listFDIs.length) return;
    // filter offset unique well
    const fdiWellIds = this.listFDIs.map(x => x.wellId);
    const bourdetOffsetWells = this.offsetChartInputData.filter(x => x.isBourdetDer && fdiWellIds.includes(x.wellId));
    const offsetWellIds = bourdetOffsetWells.map(x => x.wellId);
    const activeWellIds = offsetWellIds.filter((x, i, a) => a.indexOf(x) === i);
    if (!activeWellIds || !activeWellIds.length) return;

    // function to handleAutoFDI
    const handleAutoFDI = (selectedWell, offsetWells) => {
      if (!selectedWell) return;
      const offsetWell = offsetWells.find(x => x.wellId === selectedWell.wellId);
      if (!offsetWell) return;
      this.handleAutoFDI(offsetWell);
    };

    // if there are more than 1 offset well
    if (activeWellIds.length > 1) {
      const context = { listWells: this.listWells, activeWellIds };
      this.dialogService.open(FdiWellSelectionModalComponent, { context }).onClose.subscribe(selectedWell => handleAutoFDI(selectedWell, bourdetOffsetWells));
    }
    // if there are only 1 offset well: there is no need to open modal
    if (activeWellIds.length === 1) {
      const selectedWell = { wellId: activeWellIds[0] };
      handleAutoFDI(selectedWell, bourdetOffsetWells);
    }
  }

  handleAutoFDI(offsetWell: IInputDataMultiFlow) {
    // get column index from offsetData.cName: ie C3 or C4
    // that ensure the data being used is the actual data being render
    if (!offsetWell.realtimeData.length) return;
    let offsetData;
    if (offsetWell.realtimeData.length) offsetData = offsetWell.realtimeData[0];
    else if (offsetWell.fracproData.length) offsetData = offsetWell.fracproData[0];
    else return;

    const dataColumnIndex = offsetData.columns.findIndex(x => x === offsetWell.cName);
    if (dataColumnIndex === -1) return;
    const fdi = this.listFDIs.find(x => x.wellId === offsetWell.wellId);
    if (!fdi) return;

    // map cName data to list and calculate positive, negative
    // positiveIndex take the first index when value go from negative to positive
    // negativeIndex take the first index when value go from positive to negative
    // note that both positiveIndex and negativeIndex must be in range of offset data
    let negativeIndex = -1;
    let positiveIndex = -1;
    const n = offsetData.values.length;
    for (let i = 0; i < n - 1; i++) {
      if (negativeIndex !== -1 && positiveIndex !== -1) break;
      if (positiveIndex === -1) {
        if (offsetData.values[i][dataColumnIndex] < 0 && offsetData.values[i + 1][dataColumnIndex] >= 0) positiveIndex = i + 1;
      }
      if (negativeIndex === -1) {
        if (offsetData.values[i][dataColumnIndex] >= 0 && offsetData.values[i + 1][dataColumnIndex] < 0) negativeIndex = i + 1;
      }
    }

    // calc positiveTimestamp and negativeTimestamp
    if (positiveIndex === -1 && negativeIndex === -1) return;
    let positiveTimestamp = positiveIndex >= 0 ? offsetData.values[positiveIndex][0] : null;
    let negativeTimestamp = negativeIndex >= 0 ? offsetData.values[negativeIndex][0] : null;
    // update fdi fdiStart and fdiEnd if there is change in positiveTimestamp and negativeTimestamp
    // if there no update of fdiStart or fdiEnd: set them to NULL
    if (positiveTimestamp) {
      const fdiStartTime = this.treatmentPlotTimeConverterService.convertMsToSec(positiveTimestamp);
      fdi.fdiStart === fdiStartTime ? positiveTimestamp = null : fdi.fdiStart = fdiStartTime;
    }
    if (negativeTimestamp) {
      const fdiEndTime = this.treatmentPlotTimeConverterService.convertMsToSec(negativeTimestamp);
      fdi.fdiEnd === fdiEndTime ? negativeTimestamp = null : fdi.fdiEnd = fdiEndTime;
    }
    // if this.filterSettings.type is NOT hh:mm: use time float
    // the fdiStart and fdiEnd must be calc and pass based on this.filterSettings.type
    const mapTimeFloat = (value, data, baseData) => {
      if (this.filterSetting.type === TIME_SETTING_TYPE.EpochTime) return value;
      return data[1] - baseData[1];
    };

    let fdiStart = positiveTimestamp ? mapTimeFloat(positiveTimestamp, offsetData.values[positiveIndex], offsetData.values[0]) : null;
    let fdiEnd = negativeTimestamp ? mapTimeFloat(negativeTimestamp, offsetData.values[negativeIndex], offsetData.values[0]) : null;
    if (typeof fdiStart === 'string') fdiStart = new Date(fdiStart).getTime();
    if (typeof fdiEnd === 'string') fdiEnd = new Date(fdiEnd).getTime();

    // positiveTimestamp and negativeTimestamp are used for render
    // fdi.fdiStart and fdi.fdiEnd and this.treatmentId are used for saving current FDI location in DB
    // the fdiStart and fdiEnd can be in either format fdi.fdiStart and fdi.fdiEnd must be in hh:mm up to second format
    this.highChart.updateFDIHandler(fdi, fdiStart, fdiEnd);

    // update FDI summary (top right of the chart)
    this.treatmentPlotFdiApiService.updateMutiFDIByTreatment(this.treatmentId, fdi.id, ['fdiStart', 'fdiEnd'], [fdi.fdiStart, fdi.fdiEnd], this.isRealtime)
      .pipe(takeUntil(this.destroy$))
      // .pipe(finalize(() => { if (!this.dialogOpened) this.onChangeTimerState(TimerState.resumed) }))
      .subscribe(res => {
        this.updateFDIDataState(res);
        this.updateFDIDataPoint(res.fdi, 'fdiStart');
        this.updateFDIDataPoint(res.fdi, 'fdiEnd');
      });
  }

  onRemoveAllFDI() {
    if (!this.treatmentId) return;
    this.treatmentPlotFdiApiService.deleteByTreatment(this.treatmentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setCountFDI();
        this.highChart.removeAllFDIHandler();
        this.setFDIDataState(this.treatmentPlotFdiStoreService.getInitialDataPayload());
      });
  }

  onToggleShowFDI(isShow) {
    this.isShowFDIInfo = isShow;
    this.highChart.toggleShowFDIHandler(isShow);
  }

  onSelectFDIParam(selectedParam) {
    this.selectedFDIParam = selectedParam;
  }

  onFDIDrag(eventData: IFdiDropEventData) {
    // this.updateTimer(false);
    this.onChangeTimerState(TimerState.paused);
  }

  onFDIMouseOver(eventData: IFdiDropEventData) {
    this.activeFDIId = eventData.fdiId;
  }

  onFDIDrop(eventData: IFdiDropEventData) {
    let fdiValue: number;
    fdiValue = this.treatmentPlotFdiService.parseFDIValueToTreatment(this.timeFormat, this.baseTreatmentDataTime, Math.round(eventData.x));

    const matchInRangeOffset = (): number => {
      const findFdi = this.listFDIs.find(x => x.id === eventData.fdiId);
      if (!findFdi) return fdiValue;

      const offsetData = this.offsetChartInputData.find(x => x.treatmentId === findFdi.treatmentId && !x.isBourdetDer);
      if (!offsetData || !offsetData.realtimeData || !offsetData.realtimeData.length) return fdiValue;

      const offsetRealtimeData = offsetData.realtimeData[0];
      if (!offsetRealtimeData.values || !offsetRealtimeData.values.length) return fdiValue;

      const offsetRealtimeMinValues = new Date(offsetRealtimeData.values[0][0]).getTime();
      const offsetRealtimeMaxValues = new Date(offsetRealtimeData.values[offsetRealtimeData.values.length - 1][0]).getTime();
      if (fdiValue * 1000 < offsetRealtimeMinValues) return Math.round(offsetRealtimeMinValues / 1000);
      if (fdiValue * 1000 > offsetRealtimeMaxValues) return Math.round(offsetRealtimeMaxValues / 1000);
      return fdiValue;
    };
    fdiValue = matchInRangeOffset();

    this.treatmentPlotFdiApiService.updateFDIByTreatment(this.treatmentId, eventData.fdiId, eventData.fdiKeyValue, fdiValue, this.isRealtime)
      .pipe(takeUntil(this.destroy$))
      .pipe(finalize(() => { if (!this.dialogOpened) this.onChangeTimerState(TimerState.resumed) }))
      .subscribe(res => {
        this.updateFDIDataState(res, eventData.fdiId);
        this.updateFDIDataPoint(res.fdi, eventData.fdiKeyValue);
      });
  }

  private handleFDIValue(offsetData: any[], targetFDIValue: number): { fdiValue: number, fdiDateTime: string } {
    if (offsetData && offsetData.length > 0) {
      const xAxisMin = new Date(offsetData[0][0]).getTime();
      const xAxisMax = new Date(offsetData[offsetData.length - 1][0]).getTime();
      if (targetFDIValue * 1000 > xAxisMax) {
        return { fdiValue: xAxisMax, fdiDateTime: offsetData[offsetData.length - 1][0] };
      } else if (targetFDIValue * 1000 < xAxisMin) {
        return { fdiValue: xAxisMin, fdiDateTime: offsetData[0][0] };
      }
    }

    const fdiDateTime = new Date(targetFDIValue * 1000).toISOString().substring(0, 19) + 'Z';
    return { fdiValue: targetFDIValue * 1000, fdiDateTime };
  }

  updateFDIDataPoints(inputFDI, firstInit?: boolean) {
    for (const param of FDI_PARAM) {
      this.updateFDIDataPoint(inputFDI, param);
    }
    if (!firstInit) return;
    this.updateFDIDataPoint(inputFDI, 'leakOffStart');
    this.updateFDIDataPoint(inputFDI, 'peakFDIStart');
  }

  updateFDIDataPointsRealtime(inputFDI) {
    const offsetChartInput = this.offsetChartInputData.filter(x => !x.isBourdetDer);
    const newRealtimeYAxisMinMax = [];
    for (const offsetInput of offsetChartInput) {
      let offsetChannelName;
      for (const axis of YAXIS_KEYS) {
        const axixChannels = this.offsetChannelSetting[axis];
        if (!axixChannels || !axixChannels.channels || !axixChannels.channels.length) continue;
        const offsetChannel = axixChannels.channels.find(x => x.treatmentId === offsetInput.treatmentId && !x.isBourdetDer);
        if (!offsetChannel) continue;

        const measureType = offsetChannel.measureType;
        let unit: string;
        if (measureType && measureType.trim()) unit = this.unitSystemService.getUnit(measureType, this.unitSetting);
        else {
          unit = offsetChannel.unit ? offsetChannel.unit : offsetChannel.channel.unit;
        }

        const pre = unit && unit.length ? (' (' + unit + ')') : '';
        offsetChannelName = (offsetChannel.channelName || offsetChannel.channel.name) + pre;
        break;
      }
      if (!offsetChannelName) continue;
      const yAxix = this.highChart.highChart.yAxis.find(x => x['userOptions'].title.text.includes(offsetChannelName));
      if (!yAxix) continue;
      newRealtimeYAxisMinMax.push({
        treatmentId: offsetInput.treatmentId,
        min: yAxix.min,
        max: yAxix.max
      });
    }

    const compareYAxisMinMax = (arr: any[], brr: any[]): boolean => {
      for (const a of arr) {
        const findB = brr.find(b => b.treatmentId === a.treatmentId);
        if (!findB) return false;
        if (a.min !== findB.min) return false;
        if (a.max !== findB.max) return false;
      }
      for (const b of brr) {
        const findA = arr.find(a => b.treatmentId === a.treatmentId);
        if (!findA) return false;
        if (findA.min !== b.min) return false;
        if (findA.max !== b.max) return false;
      }
      return true;
    };

    if (!newRealtimeYAxisMinMax.length) return;
    if (!this.realtimeYAxisMinMax) {
      this.realtimeYAxisMinMax = newRealtimeYAxisMinMax;
      this.updateFDIDataPoints(inputFDI);
    } else {
      if (!compareYAxisMinMax(this.realtimeYAxisMinMax, newRealtimeYAxisMinMax)) {
        this.realtimeYAxisMinMax = newRealtimeYAxisMinMax;
        this.updateFDIDataPoints(inputFDI);
      }
    }
  }

  updateFDIDataPoint(inputFDI, fdiKeyValue: string) {
    for (const fdi of inputFDI) {
      const offsetData = this.offsetChartInputData.find(x => x.treatmentId === fdi.treatmentId && !x.isBourdetDer);
      if (!offsetData) continue;
      if (!offsetData.realtimeData || !offsetData.realtimeData.length) continue;
      if (!offsetData.realtimeData[0].values || !offsetData.realtimeData[0].values.length) continue;

      const { fdiValue, fdiDateTime } = this.handleFDIValue(offsetData.realtimeData[0].values, fdi[fdiKeyValue]);
      const value = Helper.findOffsetData(offsetData.realtimeData[0].values, fdiDateTime);

      let offsetChannelName;
      let measureType: string;
      for (const axis of YAXIS_KEYS) {
        const axixChannels = this.offsetChannelSetting[axis];
        if (!axixChannels || !axixChannels.channels || !axixChannels.channels.length) continue;
        const offsetChannel = axixChannels.channels.find(x => x.treatmentId === fdi.treatmentId && !x.isBourdetDer);
        if (!offsetChannel) continue;

        measureType = offsetChannel.measureType;
        let unit: string;
        if (measureType && measureType.trim()) unit = this.unitSystemService.getUnit(measureType, this.unitSetting);
        else {
          unit = offsetChannel.unit ? offsetChannel.unit : offsetChannel.channel.unit;
        }
        const pre = unit && unit.length ? (' (' + unit + ')') : '';
        offsetChannelName = (offsetChannel.channelName || offsetChannel.channel.name) + pre;
        break;
      }

      if (!offsetChannelName) continue;
      const yAxix = this.highChart.highChart.yAxis.find(x => x['userOptions'].title.text.includes(offsetChannelName));
      if (!yAxix) continue;
      // hide fdi series if FDI series is outside the yAxis range
      if (value > yAxix.max) {
        const highChartInstance: any = this.highChart.highChart;
        if (!highChartInstance.series) continue;
        const series = highChartInstance.series.find(x => x['userOptions'].id.includes(fdi.id + '_' + fdiKeyValue));
        if (series) {
          if (series.graph) series.graph.hide();
          series.hide();
        }
        continue;
      }
      let yValue = (value - yAxix.min) / (yAxix.max - yAxix.min) * 100;
      if (measureType && measureType.trim()) yValue = this.unitSystemService.convert(yValue, measureType);

      const xValue = this.treatmentPlotFdiService.parseFDIValueToChartSeries(this.timeFormat, this.baseTreatmentDataTime, fdiValue);
      this.highChart.updateFDIPoint(fdi.id + '_' + fdiKeyValue, fdiKeyValue, xValue, yValue);
    }
  }

  onTreatmentStartEndDrop(eventData: IFdiDropEventData) {
    // this.onFDIDrop(eventData);
    this.onChangeTimerState(TimerState.paused);
    let fdiValue = Math.round(eventData.x);
    if (this.timeFormat === TIME_SETTING_TYPE.OffsetTime) {
      fdiValue = this.treatmentPlotTimeConverterService.convertOffsetToEpochSec({ xValue: Math.round(eventData.x), baseTreatmentDataTime: this.baseTreatmentDataTime });
    } else {
      // have to remove the timezone before update it to the database
      fdiValue = this.treatmentPlotTimeConverterService.toEpochSecWithTimezone({ xValue: fdiValue });
    }
    const payload = {
      [`${eventData.fdiKeyValue}`]: fdiValue
    };
    this.treatmentPlotFdiApiService.updateByTreatment(this.treatmentId, payload, this.isRealtime)
      .pipe(takeUntil(this.destroy$))
      .pipe(finalize(() => { if (!this.dialogOpened) this.onChangeTimerState(TimerState.resumed) }))
      .subscribe(res => this.updateFDIDataState(res));
  }

  //////////////////////////////////////////////////////////////////////////
  // these function provide shortcut for various function across the file //
  //////////////////////////////////////////////////////////////////////////

  get getOffsetChannelList(): IChannelSettingItemModel[] {
    if (!this.offsetChannelSetting) return [];
    return this.channelSelectionService.getListChannels(this.offsetChannelSetting, true);
  }

  get hasOffsetChannel() {
    return this.compareOffsetChannelsService.checkIsExistOffsetChannel(this.offsetChannelSetting);
  }

  private updateFDIDataState(res, fdiId?: string) {
    const resortFDI = (resFdi) => {
      if (!fdiId) return resFdi;
      const findFdi = resFdi.find(x => x.id === fdiId);
      if (!findFdi) return resFdi;
      const filterFdi = resFdi.filter(x => x.id !== fdiId);
      filterFdi.unshift(findFdi);
      return filterFdi;
    };

    res.fdi = resortFDI(res.fdi);
    this.originListFDIs = res.fdi;
    this.setFDIDataState(res);
  }

  private setCountFDI(value = 1) {
    this.countFDI = value;
  }

  private baseRealtimeData() {
    const realtimeData = this.chartInputData.realtimeData;
    if (!realtimeData || !realtimeData.length) return null;
    const data = realtimeData[0];
    if (!data.values || !data.values.length) return null;
    return data.values[0];
  }

  private lastRealtimeData() {
    const realtimeData = this.chartInputData.realtimeData;
    if (!realtimeData || !realtimeData.length) return null;
    const data = realtimeData[0];
    if (!data.values || !data.values.length) return null;
    return data.values[data.values.length - 1];
  }

  private lastRealtimeOffsetData(treatmentId: number) {
    const offsetChannel = this.offsetChartInputData.find(x => x.treatmentId === treatmentId);
    if (!offsetChannel) return;
    const realtimeData = offsetChannel.realtimeData;
    if (!realtimeData || !realtimeData.length) return null;
    const data = realtimeData[0];
    if (!data.values || !data.values.length) return null;
    return data.values[data.values.length - 1];
  }

  onClose() {
    this.componentClose.emit(true);
  }

  onTransform() {
    this.componentTransform.emit(true);
  }

  onChangeOption(eventData) {
    if (!eventData || isNil(eventData.id)) return;
    this.cursorSelected = this.cursorOpts.find(item => item.id === eventData.id);
    // handle tooltip.shared
    this.highChart.updateChartCursorOptions(this.cursorSelected);
  }

  @HostListener('window:visibilitychange', ['$event'])
  onVisibilitychange(event) {
    if (document.hidden === true && document.visibilityState === 'hidden') {
      this.onChangeTimerState(TimerState.paused);
    } else if (document.hidden === false && document.visibilityState === 'visible') {
      this.onChangeTimerState(TimerState.resumed);
    }
  }

}

import { Component, OnInit, Input} from '@angular/core';
import {DefaultEditor} from 'ng2-smart-table';

@Component({
  template: `
    <input type="number" decimalFormat="0"
           class="form-control"
           [(ngModel)]="data"
           placeholder="{{placeHolder}}"
           (keyup)="updateValue($event)"/>`,
})

export class EditCustomInputNumberComponent extends DefaultEditor implements OnInit {
  @Input() value: string | number;
  @Input() rowData: any;

  data: number = null;
  placeHolder: string = '';

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.cell.newValue !== '') {
      this.data = this.cell.getValue();
    }
    this.placeHolder = this.cell.getColumn().class;
  }

  updateValue(e: any) {
    this.cell.newValue = e.target.value;
  }
}

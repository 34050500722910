import { Component, OnInit, Input } from '@angular/core';

@Component({
  template: `
    <span>{{value ? (value | number) : 0}}</span>
  `,
})
export class CustomNumberComponent implements OnInit {
  @Input() value: string | number;
  @Input() rowData: any;

  constructor() { }

  ngOnInit() {
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'app-custom-channels-select',
  template: `
    {{renderValue}}
  `,
})
export class CustomChannelsSelectComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: any;
  @Input() rowData: any;

  ngOnInit() {
    if (this.value && this.value.name) {
      this.renderValue = this.value.name;
    }
  }

}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forEach, isNil } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import 'rxjs/add/operator/map';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../core/services/api.service';
import { DefaultSize } from '../helpers/app.constants';
import { ChannelMappingService } from './channel-mapping.service';

@Injectable()
export class CompareWellsService {
  prefixPath = '/wells';

  constructor(private apiService: ApiService, private channelMappingService: ChannelMappingService) {

  }

  updateWellColor(wellId: number, color: string, finalize?: VoidFunction) {
    return this.apiService.put(`${this.prefixPath}/${wellId}`, {wellColor: color}, finalize);
  }

  getChannelSingleCompare(wellId: number): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/${wellId}/getListChannelForSingleCompare?wellId=${wellId}`);
  }
  getChannelSingleCompareByTreatment(wellId: number, treatmentId: number): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/${wellId}/getListChannelForSingleCompareFirstTreatment?treatmentId=${treatmentId}`);
  }
  getFracproChannelSingleCompare(wellId: number): Observable<any> {
    let param = new HttpParams();
    param = param.set('wellId', wellId.toString());
    param = param.set('flowPathType', '0');
    return this.apiService.get(`${this.prefixPath}/${wellId}/getListFracProChannelForSingleCompare`, param);
  }
  getChannelsCompareSingle(wellId: number, treatmentId?: number) {
    let getReatlimeChannels = this.getChannelSingleCompare(wellId).pipe(catchError(err => of({})));
    if (treatmentId) {
      getReatlimeChannels = this.getChannelSingleCompareByTreatment(wellId, treatmentId).pipe(catchError(err => of({})));
    }
    return forkJoin(
      getReatlimeChannels,
      this.getFracproChannelSingleCompare(wellId).pipe(catchError(err => of({})))
    ).pipe(map(response => {
      const result = {
        realtimeChannels: [],
        fracproChannels: []
      };
      if (response && response.length > 0) {
        response.forEach((res, index) => {
          if (res.result) {
            if (index === 0) {
              // realtime channels result
              result.realtimeChannels = res.result;
            } else {
              result.fracproChannels = res.result;
            }
          }
        });
      }
      return result;
    }));
  }
  getChannelsCompareMultiple(wellIds: number[]) {
    const request$ = wellIds.map(wellId => {
      return forkJoin(
        this.getChannelMultiCompare(wellId).pipe(catchError(err => of({}))),
        this.getFracproChannelMultiCompare(wellId).pipe(catchError(err => of({})))
      )
    })
    return forkJoin(request$).pipe(map(response => {
      const result = {
        realtimeChannels: [],
        fracproChannels: []
      };
      if (response && response.length > 0) {
        const hadnelChannels = (list: any[]) => {
          if (!list || !list.length) return [];
          return list.reduce((ac, a) => {
            let temp = ac.find(x => x.name === a.name);
            if (!temp) ac.push({ ...a,
              channelMappedGroup: [a]
            })
            else temp.channelMappedGroup.push(a)
            return ac;
            }, []);
        };
        let realtimeChannels: any[] = [];
        let fracproChannels: any[] = [];

        response.forEach((res, index) => {
          if (res && res.length) {
            if (res[0] && res[0].result) realtimeChannels = realtimeChannels.concat(res[0].result);
            if (res[1] && res[1].result) fracproChannels = fracproChannels.concat(res[1].result);
          }
        });

        result.realtimeChannels = hadnelChannels(realtimeChannels);
        result.fracproChannels = hadnelChannels(fracproChannels);
      }
      return result;
    }));
  }
  getChannelMultiCompare(wellId: number, param?: any): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/${wellId}/getListChannelForSingleCompare`, param);
  }
  getFracproChannelMultiCompare(wellId: number, param?: any): Observable<any> {
    // there is no need to fetch data if treatment is kind of monitor
    // since monitor treatments do NOT have fracpro data to begin
    if (param && param[0] && param[0].treatmentNumber < 0) return of({ result: [] });
    return this.apiService.get(`${this.prefixPath}/${wellId}/getListFracProChannelForSingleCompare`, param);
  }
  getParamSingleCompare(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/getListParamForSingleCompare`);
  }
  getParamMultiCompare(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/getListParamForMultiCompare`);
  }
  getTreatmentMultiCompare(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/getListTreatmentForMultiCompare`);
  }
  compareSingleWell(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/compareSingleWell`, param, finalize);
  }
  compareSingleWellChart(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/compareSingleWellChart`, param, finalize);
  }
  compareMultiWell(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/compareMultiWell`, param, finalize);
  }
  compareMultiWellChart(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/compareMultiWellChart`, param, finalize);
  }
  getPivotPointData(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/treatments/getDataForPivotPoint`, param, finalize);
  }
  getDataForPivotPoints(param: { treatmentIds: number[], paramNames: string[] }, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/treatments/getDataForPivotPoints`, param, finalize);
  }
  getWellSummary(id: number) {
    return this.apiService.get(`${this.prefixPath}/${id}/getWellSummary`);
  }
  getListWellTreatment(wellId: number) {
    let param = new HttpParams();
    param = param.set('wellId', wellId.toString());
    return this.apiService.get(`${this.prefixPath}/getListWellTreatment`, param);
  }
  getListWellData() {
    return this.apiService.get(`${this.prefixPath}/getListWell`);
  }
  getListWellName() {
    return this.apiService.get(`${this.prefixPath}/getListWellName`);
  }
  getListWell(paramObject: any, page: number, pageSize: number, sortField?: string, sortType: boolean = true) {
    let param = new HttpParams();
    if (paramObject) {
      forEach(paramObject, (item, key) => {
        if (item) {
          param = param.set(key.toString(), item);
        }
      });
    }
    if (page) {
      param = param.set('page', page.toString());
    }
    if (pageSize) {
      param = param.set('pageSize', pageSize.toString());
    }
    if (sortField) {
      param = param.set('sortField', sortField);
      param = param.set('sortType', sortType.toString());
    }
    return this.apiService.get(`${this.prefixPath}/GetListWellPaging`, param);
  }

  getListWellsForComparing(pageSize?: number) {
    if (isNil(pageSize)) {
      pageSize = DefaultSize.wellPage;
    }
    return this.getListWell({}, null, pageSize).pipe(map(res => {
      return res.result ? res.result.items : [];
    }));
  }

  deleteWells(paramObject: number[], finalize?: VoidFunction): Observable<any> {
    let param = new HttpParams();
    forEach(paramObject, (id, key) => {
      if (id) {
        param = param.append('wellIds', id.toString());
      }
    });
    return this.apiService.deleteParam(`${this.prefixPath}`, param, finalize);
  }
  deletePostWells(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/delete`, param, finalize, { requestTimeout: 30 * 60 * 1000 }); // 30 min request timeout
  }
}

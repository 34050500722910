import { Injectable } from '@angular/core';
import { IChannelSettingModel, isChannelSettingModel, IChannelItem, IChannelMappingResponse, IChannelSettingItemModel, IChannelSettingYAxisModel } from '../models/channel.model';
import { isEmpty, isString, cloneDeep, isNumber, maxBy, minBy, findIndex, uniqBy, isNil, each } from 'lodash';
import { Helper } from '../helpers/helper';
import { TreatmentService } from './treatment.service';
import { map } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { IPlotTemplate, IPlotTemplateResponse } from '../models/plot-template.model';
import { AxisType } from '../models/offset-well-compare.model';
import { ENUM_COLOR_MODE, TEMPLATE_YAXIS_KEYS, PREFIX_PREDICTIVE_CHANNEL, YAxisMap, YAXIS_KEYS } from '../helpers/app.constants';

const YAXIS_MAP = {
  yAxisLeft: YAxisMap.firstLeft,
  yAxisLeftMost: YAxisMap.secondLeft,
  yAxisRight: YAxisMap.firstRight,
  yAxisRightMost: YAxisMap.secondRight,
};
export const DIGITS_AFTER_DECIMAL_DEFAULT = '2';

@Injectable()
export class ChannelSelectionService {
  private defaultChannelsFirstLeft = [];
  private defaultChannelsSecondLeft = [];
  private defaultChannelsFirstRight = [];
  private defaultChannelsSecondRight = [];

  constructor(
    private treatmentService: TreatmentService,
  ) { }

  formatChannelModel(channelItem, isRandomColor?: boolean, isOffsetChannel?: boolean): IChannelSettingItemModel {
    let channelColor = channelItem.color;
    let channelColorMode = '';
    if (isRandomColor && !channelColor) {
      channelColor = Helper.randomHexColor();
    }
    if (channelItem.colorMode) {
      channelColorMode = channelItem.colorMode;
    }

    const minValue = isNumber(channelItem.min) && !isNaN(channelItem.min) ? channelItem.min : null;
    const maxValue = isNumber(channelItem.max) && !isNaN(channelItem.max) ? channelItem.max : null;
    const digits = channelItem.digits !== undefined && channelItem.digits !== null ? channelItem.digits : DIGITS_AFTER_DECIMAL_DEFAULT;
    let result: IChannelSettingItemModel = {
      channelName: channelItem.name || channelItem.channelName,
      name: channelItem.name || channelItem.channelName,
      channel: {
        name: channelItem.name || channelItem.channelName,
        cName: channelItem.cName,
        originalName: channelItem.originalName,
        unit: channelItem.unit,
        color: channelColor,
        colorMode: channelColorMode,
        min: minValue,
        max: maxValue,
        isCompare: channelItem.isCompare,
        digits: digits,
        measureType: channelItem.measureType,
        channelMappedGroup: channelItem.channelMappedGroup
      },
      cName: channelItem.cName,
      originalName: channelItem.originalName,
      unit: channelItem.unit,
      color: channelColor,
      colorMode: channelColorMode,
      min: minValue,
      max: maxValue,
      isCompare: channelItem.isCompare,
      digits: digits,
      measureType: channelItem.measureType,
      channelMappedGroup: channelItem.channelMappedGroup
    };
    if (isOffsetChannel) {
      const offsetProps = {
        isOffset: true,
        isRealtime: channelItem.isRealtime,
        wellId: channelItem.wellId,
        treatmentId: channelItem.treatmentId,
        originName: channelItem.originName,
        isSmoothData: channelItem.isSmoothData,
        smoothRange: channelItem.smoothRange,
        isCompare: channelItem.isCompare,
        digits: digits,
        measureType: channelItem.measureType,
        channelMappedGroup: channelItem.channelMappedGroup
      };
      result = Object.assign(result, offsetProps);
      result.channel = Object.assign(result.channel, offsetProps);
    }
    if (channelItem.isBourdetDer) {
      const bourdetProps = {
        isBourdetDer: channelItem.isBourdetDer,
        bourdetDerRange: channelItem.bourdetDerRange
      };
      result = Object.assign(result, bourdetProps);
      result.channel = Object.assign(result.channel, bourdetProps);
    }

    return result;
  }

  formatChannelSettingModel(paramChannelSettings: IChannelSettingModel, isOffsetOnly?: boolean) {
    const channelSettings = cloneDeep(paramChannelSettings);
    let allChannels = [];
    for (const key in channelSettings) {
      if (channelSettings.hasOwnProperty(key)) {
        const setting = channelSettings[key];
        if (setting && !isEmpty(setting.channels)) {
          // filter unselected channel
          setting.channels = setting.channels.filter(item => item.channel && item.channel.name);
          // filter offset channels
          if (isOffsetOnly) {
            setting.channels = setting.channels.filter(item => item.channel.isOffset);
          } else {
            setting.channels = setting.channels.filter(item => !item.channel.isOffset);
          }
          // set axis key in channel
          setting.channels = setting.channels.map(item => {
            item.axisKey = key;
            item.digits = item.digits !== undefined && item.digits !== null ? item.digits : DIGITS_AFTER_DECIMAL_DEFAULT;
            return item;
          });
          // to all channels
          allChannels = allChannels.concat(setting.channels);
        }
      }
    }
    // filter duplicate channels
    allChannels = uniqBy(allChannels, 'channel.name');
    for (const key in channelSettings) {
      if (channelSettings.hasOwnProperty(key)) {
        const setting = channelSettings[key];
        if (setting && !isEmpty(setting.channels)) {
          setting.channels = allChannels.filter(channel => channel.axisKey === key);
          setting.channels = setting.channels.map(item => {
            item.channel.color = item.color;
            item.channel.colorMode = item.colorMode ? item.colorMode : '';
            item.channel.min = item.min;
            item.channel.max = item.max;
            item.channel.isCompare = item.isCompare;
            item.channel.digits = item.digits !== undefined && item.digits !== null ? item.digits : DIGITS_AFTER_DECIMAL_DEFAULT;
            item.channel.measureType = item.measureType;
            item.channelchannelMappedGroup = item.channelMappedGroup;
            return this.formatChannelModel(item.channel, false, isOffsetOnly);
          });
        }
      }
    }

    return channelSettings;
  }

  setChannelValueByPropKey(channelSettings: IChannelSettingModel, propKey: string, propValue: string | number) {
    for (const key in channelSettings) {
      if (channelSettings.hasOwnProperty(key)) {
        const setting = channelSettings[key];
        if (setting && !isEmpty(setting.channels)) {
          setting.channels.forEach(item => {
            if (item) {
              item[propKey] = propValue;
            }
          });
        }
      }
    }
  }

  filterChannelsByPrefixStr(channelSettings: IChannelSettingModel, prefix: string) {
    const settings = cloneDeep(channelSettings);
    for (const key in settings) {
      if (settings.hasOwnProperty(key)) {
        const setting = settings[key];
        if (setting && !isEmpty(setting.channels)) {
          setting.channels = setting.channels.filter(item => {
            const cName = item.cName && item.cName.trim()
              ? item.cName.trim()
              : (item.channel && item.channel.cName && item.channel.cName.trim() ? item.channel.cName.trim() : '');
            const arrayName = cName.split('');
            return arrayName[0] === prefix;
          });
        }
      }
    }
    return settings;
  }

  filterChannelsByAxisType(channelSettings: IChannelSettingModel, axisType: AxisType) {
    const settings = cloneDeep(channelSettings);
    const yAxisMap = {
      Left: YAxisMap.firstLeft,
      Leftmost: YAxisMap.secondLeft,
      Right: YAxisMap.firstRight,
      Rightmost: YAxisMap.secondRight,
    };
    each(yAxisMap, (value, key) => {
      if (settings[value] && !isEmpty(settings[value].channels) && axisType !== key) {
        settings[value].channels = [];
      }
    });
    return settings;
  }

  getListChannels(channelSettings: IChannelSettingModel, isOffsetOnly?: boolean): IChannelSettingItemModel[] {
    let result = [];
    YAXIS_KEYS.forEach(key => {
      if (channelSettings.hasOwnProperty(key)) {
        const setting = channelSettings[key];
        if (setting && !isEmpty(setting.channels)) {
          // filter offset channels
          const channels = setting.channels.filter(item => {
            let rlt = isOffsetOnly ? item.isOffset : !item.isOffset;
            rlt = rlt && (!item.isCompare || (!item.insPressure && !item.isPressure));
            return rlt;
          });
          result = result.concat(channels);
        }
      }
    });
    return result;
  }

  hasChannelItems(channelSettings: IChannelSettingModel): boolean {
    const settings = ['firstLeft', 'secondLeft', 'firstRight', 'secondRight']
    for (const s of settings) {
      if (channelSettings[s] && channelSettings[s].channels) {
        if (channelSettings[s].channels.length) return true;
      }
    }
    return false;
  }

  toChannelItems(channelSettings: IChannelSettingModel, isOffsetOnly?: boolean): IChannelItem[] {
    const channelSettingItems = this.getListChannels(channelSettings, isOffsetOnly);
    const result = channelSettingItems.map(item => {
      const channelItem = {
        cName: item.cName,
        name: item.name,
        unit: item.unit,
        color: item.color,
        originalName: item.originalName
      };
      if (isOffsetOnly) {
        return Object.assign(channelItem, {
          // offset channel properties
          isOffset: isOffsetOnly,
          wellId: item.wellId,
          treatmentId: item.treatmentId,
          originName: item.originName,
        });
      } else {
        return channelItem;
      }
    });
    return result;
  }

  updateMinMaxChannels(channelSettings, listChannels: any[], isOffsetChannel?: boolean) {
    const settings = cloneDeep(channelSettings);
    if (!isEmpty(listChannels)) {
      YAXIS_KEYS.forEach(key => {
        if (settings.hasOwnProperty(key)) {
          const setting = settings[key];
          if (setting && !isEmpty(setting.channels)) {
            // update channels
            setting.channels = setting.channels.map(curChannel => {
              if (!curChannel.isBourdetDer) {
                let minMaxChannel;
                if (isOffsetChannel) {
                  minMaxChannel = listChannels.find(item => item.cName === curChannel.cName && item.wellId === curChannel.wellId && item.treatmentId === curChannel.treatmentId);
                } else {
                  minMaxChannel = listChannels.find(item => item.cName === curChannel.cName);
                }
                if (minMaxChannel) {
                  curChannel.min = curChannel.channel.min = minMaxChannel.min;
                  curChannel.max = curChannel.channel.max = minMaxChannel.max;
                }
              }
              return curChannel;
            });
          }
        }
      });
    }
    return settings;
  }

  updateMinMaxChannelsByName(channelSettings, listChannels: any[]) {
    const settings = cloneDeep(channelSettings);
    if (!isEmpty(listChannels)) {
      YAXIS_KEYS.forEach(key => {
        if (settings.hasOwnProperty(key)) {
          const setting = settings[key];
          if (setting && !isEmpty(setting.channels)) {
            // update min max channels
            setting.channels = this.setMinMaxChannels(setting.channels, listChannels);
          }
        }
      });
    }
    return settings;
  }

  setMinMaxChannels(originChannels, channelsWithMinMax: {cName: string; channelName: string; min: number; max: number}[], isClone?: boolean) {
    let resultChannels = isClone ? cloneDeep(originChannels) : originChannels;
    resultChannels = resultChannels.map(curChannel => {
      const minMaxChannels = channelsWithMinMax.filter(item => item.channelName === curChannel.name);
      if (!isEmpty(minMaxChannels)) {
        const minItem = minBy(minMaxChannels, 'min');
        const maxItem = maxBy(minMaxChannels, 'max');
        if (minItem && maxItem) {
          curChannel.min = minItem.min;
          curChannel.max = maxItem.max;
        }
      }
      return curChannel;
    });
    return resultChannels;
  }

  updateAxisScaleByMinMaxChannels(channelSettings: IChannelSettingModel, isRespectOldValue?: boolean) {
    try {
      const settings = cloneDeep(channelSettings);
      for (const key in settings) {
        if (settings.hasOwnProperty(key) && key !== 'autoScaleYAxis') {
          const setting = settings[key];
          if (setting && !isEmpty(setting.channels)) {
            const maxItem = maxBy(setting.channels, (item: any) => {
              return item.max;
            });
            const minItem = minBy(setting.channels, (item: any) => {
              return item.min;
            });
            if (minItem && maxItem) {
              if (!isRespectOldValue) {
                setting.min = minItem.min;
                setting.max = this.roundUpMaxValue(maxItem.max);
              } else {
                if (!isNumber(setting.min)) {
                  setting.min = minItem.min;
                }
                if (!isNumber(setting.max)) {
                  setting.max = this.roundUpMaxValue(maxItem.max);
                }
              }
            }
          }
        }
      }
      return settings;
    } catch (err) {
      return channelSettings;
    }
  }

  initSettingsModel(isUnsetRange?: boolean, isSetAutoScale?: boolean): IChannelSettingModel {
    const result: IChannelSettingModel = {
      firstLeft: {
        min: 0,
        max: 150,
        channels: []
      },
      secondLeft: {
        min: 0,
        max: 150,
        channels: []
      },
      firstRight: {
        min: 0,
        max: 150,
        channels: [],
        opposite: true
      },
      secondRight: {
        min: 0,
        max: 150,
        channels: [],
        opposite: true
      }
    };
    if (isUnsetRange) {
      for (const key in result) {
        if (result.hasOwnProperty(key) && key !== 'autoScaleYAxis') {
          result[key].min = null;
          result[key].max = null;
        }
      }
    }
    if (isSetAutoScale) {
      result.autoScaleYAxis = true;
    }

    return result;
  }

  getDefaultSettings(listChannels?, isUnsetRange?: boolean, isSetAutoScale?: boolean): IChannelSettingModel {
    const result = this.initSettingsModel(isUnsetRange, isSetAutoScale);
    if (listChannels && listChannels.length > 0) {
      result.firstLeft.channels = listChannels.filter(item => this.defaultChannelsFirstLeft.includes(item.cName)).map((item, index) => {
        return this.formatChannelModel(item, true);
      });
      result.secondLeft.channels = listChannels.filter(item => this.defaultChannelsSecondLeft.includes(item.cName)).map((item, index) => {
        return this.formatChannelModel(item, true);
      });
      result.firstRight.channels = listChannels.filter(item => this.defaultChannelsFirstRight.includes(item.cName)).map((item, index) => {
        return this.formatChannelModel(item, true);
      });
      result.secondRight.channels = listChannels.filter(item => this.defaultChannelsSecondRight.includes(item.cName)).map((item, index) => {
        return this.formatChannelModel(item, true);
      });
    }
    return result;
  }

  getDefaultSettingsObservable(wellId, treatmentId, flowPathType, listChannels?, isUnsetRange?: boolean, isSetAutoScale?: boolean) {
    try {
      let settings = this.getDefaultSettings(listChannels, isUnsetRange, isSetAutoScale);
      const cNames = [...this.defaultChannelsFirstLeft, ...this.defaultChannelsSecondLeft, ...this.defaultChannelsFirstRight, ...this.defaultChannelsSecondRight];
      if (!cNames.length) return of(settings);

      return this.treatmentService.getChannelsMinMax(wellId, treatmentId, flowPathType, cNames).pipe(map(response => {
        if (response && !isEmpty(response.result)) {
          settings = this.updateMinMaxChannels(settings, response.result);
          settings = this.updateAxisScaleByMinMaxChannels(settings);
        }
        return settings;
      }));

    } catch (err) {
      return throwError(err);
    }
  }

  // updateChannels(channelSettings, realtimeChannels?, fracproChannels?): IChannelSettingModel {
  //   const settings = cloneDeep(channelSettings);
  //   if (!isEmpty(realtimeChannels) || !isEmpty(fracproChannels)) {
  //     for (const key in settings) {
  //       if (settings.hasOwnProperty(key)) {
  //         const setting = settings[key];
  //         if (setting && !isEmpty(setting.channels)) {
  //           // update channels
  //           setting.channels = setting.channels.map(curChannel => {
  //             const prefixCName = this.getPrefixChannelByCName(curChannel.cName);
  //             let matchedChannel;
  //             if (prefixCName.toLowerCase() === 'f') {
  //               matchedChannel = fracproChannels.find(item => {
  //                 return item.cName === curChannel.cName;
  //               });
  //             } else if (prefixCName.toLowerCase() === 'c') {
  //               matchedChannel = realtimeChannels.find(item => {
  //                 return item.cName === curChannel.cName;
  //               });
  //             }
  //             if (matchedChannel) {
  //               if (curChannel.channelName !== matchedChannel.name) {
  //                 curChannel.color = matchedChannel.color ? matchedChannel.color : curChannel.color;
  //                 curChannel.channel.color = curChannel.color;
  //               }
  //               // update name
  //               curChannel.channelName = curChannel.name = matchedChannel.name;
  //               if (curChannel.channel) {
  //                 curChannel.channel.name = matchedChannel.name;
  //               }
  //             }
  //             return curChannel;
  //           });
  //         }
  //       }
  //     }
  //   }
  //   return settings;
  // }
  updateChannels(channelSettings, realtimeList: any[], fracproList: any[]): IChannelSettingModel {
    if (isEmpty(realtimeList) && isEmpty(fracproList)) return this.getDefaultSettings(undefined, undefined, true);
    if (!channelSettings) return this.getDefaultSettings(undefined, undefined, true);

    const settings = cloneDeep(channelSettings);
    YAXIS_KEYS.forEach(key => {
      if (settings.hasOwnProperty(key)) {
        if (settings[key] && !isEmpty(settings[key].channels)) {
          settings[key].channels = settings[key].channels.map(channel => {
            let channelName: string = channel.channelName || channel.name;
            let cName: string = channel.cName;
            if (!channelName) {
              if (!channel.channel || !channel.channel.name) return null;
              channelName = channel.channel.name;
            }
            if (!cName && channel.channel) cName = channel.channel.cName;

            let channelExist = realtimeList.find(item => item.name === channelName && cName.indexOf('C') === 0);
            if (!channelExist) channelExist = fracproList.find(item => item.name === channelName && cName.indexOf('F') === 0);
            if (!channelExist) {
              channel.cName = '';
            } else {
              channel.channelName = channelExist.name;
              channel.name = channelExist.name;
              channel.cName = channelExist.cName;
              channel.originalName = channelExist.originalName;
              channel.unit = channelExist.unit;
              channel.measureType = channelExist.measureType;
              
              channel.color = channelExist.color ? channelExist.color : channel.color;
              if (channel.channel) {
                channel.channel.name = channelExist.name;
                channel.channel.cName = channelExist.cName;
                channel.channel.originalName = channelExist.originalName;
                channel.channel.unit = channelExist.unit;
                channel.channel.measureType = channelExist.measureType;
                channel.channel.color = channel.color;
              }
            }

            return channel;
          }).filter(channel => channel !== null);
        } else {
          settings[key] = this.getDefaultSettings(undefined, undefined, true)[key];
        }
      }
    });
    return settings;
  }

  toRequestChannelMap(channelSetting: IChannelSettingModel, fieldName?: string) {
    let result = [];
    fieldName = fieldName ? fieldName : 'cName';
    if (channelSetting) {
      const channels = [
        ...channelSetting.firstLeft.channels,
        ...channelSetting.secondLeft.channels,
        ...channelSetting.firstRight.channels,
        ...channelSetting.secondRight.channels,
      ];
      // if (!!isPredictChannel) {
      //   channels = channels.filter(item => {
      //     if (item.originalName) {
      //       const originalNameLoweCase = item.originalName.toLowerCase();
      //       return originalNameLoweCase.indexOf(PREFIX_PREDICTIVE_CHANNEL) === 0;
      //     } else {
      //       return false;
      //     }
      //   });
      // }
      result = channels.map(item => {
        if (item.channel && item.channel[fieldName]) {
          return item.channel[fieldName];
        }
        return item[fieldName];
      });
    }
    return result;
  }

  toRequestChannelMap2(channelSetting: IChannelSettingModel, fieldName?: string): { realtimeItems: any[], fracproItems: any[] } {
    let result: { realtimeItems: any[], fracproItems: any[] } = { realtimeItems: [], fracproItems: [] };
    fieldName = fieldName ? fieldName : 'cName';
    if (channelSetting) {
      const channels = [
        ...channelSetting.firstLeft.channels,
        ...channelSetting.secondLeft.channels,
        ...channelSetting.firstRight.channels,
        ...channelSetting.secondRight.channels,
      ];
      channels.forEach(item => {
        let cName: string;
        let value: any;
        if (item.channel && item.channel[fieldName]) {
          cName = item.cName;
          value = item.channel[fieldName];
        } else value = item[fieldName];
        if (!cName || !cName.trim()) cName = item.cName;
        if (cName && cName.trim()) {
          cName = cName.trim();
          if (cName.indexOf('F') === 0) result.fracproItems.push(value);
          else result.realtimeItems.push(value);
        } else result.realtimeItems.push(value);
      });
    }
    return result;
  }

  isEmptyChannels(channelSettings: IChannelSettingModel) {
    let result = false;
    try {
      result = isEmpty(channelSettings.firstLeft.channels)
        && isEmpty(channelSettings.secondLeft.channels)
        && isEmpty(channelSettings.firstRight.channels)
        && isEmpty(channelSettings.secondRight.channels);
    } catch (err) {
      result = true;
    }
    return result;
  }

  checkIsChangedChannels(newSettings: IChannelSettingModel, currentSettings: IChannelSettingModel, strictCheck?: boolean) {
    if (!newSettings || !currentSettings) return true;

    const newCNames = this.toRequestChannelMap(newSettings);
    const curCNames = this.toRequestChannelMap(currentSettings);

    if (!strictCheck) {
      for (const cName of newCNames) {
        if (!curCNames.includes(cName)) {
          return true;
        }
      }
    } else {
      if (newCNames.length !== curCNames.length) return true;
      const n = newCNames.length || curCNames.length
      for (let i = 0; i < n; i++) {
        if (newCNames[i] !== curCNames[i]) return true;
      }
    }
    
    // do NOT need to change if there is change in predicted channel since the above code has done that
    // just need to check if there is add/remove predicted channels
    // if (!predictedCNames || !predictedCNames.length) return false;
    // const curPredictedCNames = newCNames.filter(x => predictedCNames.includes(curPredictedCNames));
    // const newPredictedCNames = newCNames.filter(x => predictedCNames.includes(newPredictedCNames));
    // if (curPredictedCNames.length !== newPredictedCNames.length) return true;

    return false;
  }

  getPrefixChannelByDataType(dataKey) {
    if (dataKey === 'fracproData') {
      return 'F';
    } else if (dataKey === 'equipmentData') {
      return 'E';
    }
    return 'C';
  }

  getPrefixChannelByCName(cName: string) {
    let result = '';
    if (typeof cName === 'string') {
      const arrStr = cName.split('');
      result = arrStr[0];
    }
    return result;
  }

  toChannelsByDataField(selectedItems: string[], allChannels: any[], dataField?: string) {
    let result = [];
    if (isString(dataField) && !isEmpty(selectedItems) && !isEmpty(allChannels)) {
      result = allChannels.filter(item => {
        return selectedItems.includes(item[dataField]);
      });
    }
    return result;
  }

  toChannelByDataField(selectedItem, realtimeChannels, fracproChannels, dataField?: string, prefix?: string) {
    let result;
    if (selectedItem) {
      if (prefix) {
        // find by prefix channel type (realtime channel or fracpro channel)
        if (prefix.toLowerCase() === 'f') {
          result = fracproChannels.find(item => {
            return item[dataField] === selectedItem;
          });
        } else if (prefix.toLowerCase() === 'c') {
          result = realtimeChannels.find(item => {
            return item[dataField] === selectedItem;
          });
        }
      } else {
        // otherwise find by top down
        result = realtimeChannels.find(item => {
          return item[dataField] === selectedItem;
        });
        if (!result) {
          result = fracproChannels.find(item => {
            return item[dataField] === selectedItem;
          });
        }
      }
    }
    return result;
  }

  toChannelByCName(selectedCName, realtimeChannels, fracproChannels) {
    let result;
    if (selectedCName) {
      const prefixCName = this.getPrefixChannelByCName(selectedCName);
      if (prefixCName) {
        if (prefixCName.toLowerCase() === 'f') {
          result = fracproChannels.find(item => {
            return item.cName === selectedCName;
          });
        } else if (prefixCName.toLowerCase() === 'c') {
          result = realtimeChannels.find(item => {
            return item.cName === selectedCName;
          });
        }
      }
    }
    return result;
  }

  findYAxisIdByChannelId(curChannels, cId) {
    // round robin way
    let channelIndex = findIndex(curChannels, { id: cId });
    const yAxisIndexs = YAXIS_KEYS;
    let result = yAxisIndexs[0];
    if (channelIndex > -1) {
      if (channelIndex > 2 && channelIndex < 5) { // 5 is limit number of channels can select
        channelIndex = channelIndex - yAxisIndexs.length;
      } else if (channelIndex > 5) {
        channelIndex = 0;
      }
      for (let index = 0; index < yAxisIndexs.length; index++) {
        if (channelIndex === index) {
          result = yAxisIndexs[index];
          break;
        }
      }
    }
    return result;
  }

  roundUpMaxValue(maxValue) {
    if (isNil(maxValue)) return null;
    // if (maxValue < 0) {
    //   maxValue = 0; // round up to 0
    // }
    else if (maxValue > 0 && maxValue < 1) {
      maxValue = 1; // round up to 1
    } else if (maxValue > 1 && maxValue < 99) {
      maxValue = Math.ceil(maxValue / 10) * 10; // round up max nearest 10
    } else {
      maxValue = Math.ceil(maxValue / 100) * 100; // round up max nearest 10
    }
    return maxValue;
  }

  findPlotTemplate(channelSetting: IChannelSettingModel, plotTemplates: IPlotTemplateResponse[]): IPlotTemplateResponse | null {
    const compare = (ch1: IChannelSettingItemModel, ch2: IChannelSettingItemModel): boolean => {
      if (ch1.name !== ch2.name) return false;
      if (ch2.color !== ch2.color) return false;
      return true;
    };

    const compareAxisChannel = (model1: IChannelSettingYAxisModel, model2: IChannelSettingYAxisModel): boolean => {
      if (!model1 || !model2) return true;
      if (!Helper.isSameSetData(model1.min, model2.min)) return false;
      if (!Helper.isSameSetData(model1.max, model2.max)) return false;
      if (model1.channels.length !== model2.channels.length) return false;
      if (!model1.channels.length && !model2.channels.length) return true;
      const n = model1.channels.length || model2.channels.length;
      for (let i = 0; i < n; i++) {
        const matchedChannel = compare(model1.channels[i], model2.channels[i]);
        if (!matchedChannel) return false;
      }
      return true;
    };

    const find = (plotTemplate: IPlotTemplateResponse) => {
      for (let i = 0; i < YAXIS_KEYS.length; i++) {
        const chSetting = channelSetting[YAXIS_KEYS[i]];
        const tmpSetting = plotTemplate.content[TEMPLATE_YAXIS_KEYS[i]];
        const nilChSetting = !chSetting || !chSetting.channels || !chSetting.channels[0];
        const nilTmpSetting = !tmpSetting || !tmpSetting.channels || !tmpSetting.channels[0];
        if (nilChSetting && nilTmpSetting) continue;
        if (nilChSetting && !nilTmpSetting) return false;
        if (!nilChSetting && nilTmpSetting) return false;
        if (!compareAxisChannel(chSetting, tmpSetting)) return false;
      }
      return true;
    };

    if (!plotTemplates || !plotTemplates[0] || !channelSetting) return null;
    return plotTemplates.find(x => find(x));
  }

  plotTemplateToChannelSettings(
    plotTemplate: IPlotTemplate,
    realtimeChannels: IChannelItem[],
    fracproChannels: IChannelItem[],
    mappingChannelData?: IChannelMappingResponse[],
    isAutoScaleYAxis?: boolean,
    colorMode?: string,
    fracProChannelsLatestVer?: any[]
  ): IChannelSettingModel {
    const channelSettings = this.initSettingsModel(true, isAutoScaleYAxis);
    if (plotTemplate) {
      const yAxisMap = {
        yAxisLeft: YAxisMap.firstLeft,
        yAxisLeftMost: YAxisMap.secondLeft,
        yAxisRight: YAxisMap.firstRight,
        yAxisRightMost: YAxisMap.secondRight,
      };

      // tslint:disable-next-line: forin
      for (const key in yAxisMap) {
        const yAxisChannelSettingKey = yAxisMap[key];
        const yAxisPlotTemplateKey = key;
        if (plotTemplate[yAxisPlotTemplateKey]) {
          channelSettings[yAxisChannelSettingKey].min = plotTemplate[yAxisPlotTemplateKey].min;
          channelSettings[yAxisChannelSettingKey].max = plotTemplate[yAxisPlotTemplateKey].max;
          if (!isEmpty(plotTemplate[yAxisPlotTemplateKey].channels)) {
            plotTemplate[yAxisPlotTemplateKey].channels.forEach(channelItem => {
              let isMappedChannel = false;
              let channelObj;
              let mappedChannelItem: IChannelMappingResponse;

              // first find by mapping channel data
              if (!isEmpty(mappingChannelData) && !isEmpty(realtimeChannels)) {
                const dataExist = mappingChannelData.find(item => {
                  return item.name === channelItem.name;
                });
                if (dataExist) {
                  mappedChannelItem = dataExist;
                  isMappedChannel = true;
                }
                if (mappedChannelItem && mappedChannelItem.name) {
                  channelObj = this.toChannelByDataField(mappedChannelItem.name, realtimeChannels, fracproChannels, 'name');
                  // use color from mapping channel
                  if (channelObj && !channelObj.color && mappedChannelItem.color) {
                    channelObj.color = mappedChannelItem.color;
                  }
                }
              }
              // if not found then find by realtime channel and fracpro channels
              if (!channelObj) {
                channelObj = this.toChannelByDataField(channelItem.name, realtimeChannels, fracproChannels, 'name');
              }
              // if found out then set this in setting model
              if (channelObj) {
                if (!channelObj.color) {
                  if (channelItem && channelItem.color) {
                    channelObj.color = channelItem.color;
                  } else if (mappedChannelItem && mappedChannelItem.color) {
                    channelObj.color = mappedChannelItem.color;
                  } else {
                    if (fracProChannelsLatestVer && fracProChannelsLatestVer.length) {
                      const dataExist = fracProChannelsLatestVer.find(item => item.name === channelItem.name);
                      if (dataExist && (dataExist.color || dataExist.defaultColor)) {
                        channelObj.color = dataExist.color ? dataExist.color : dataExist.defaultColor;
                      } else {
                        channelObj.color = Helper.randomHexColor();
                      }
                    } else {
                      channelObj.color = Helper.randomHexColor();
                    }
                  }
                }
                channelObj.colorMode = colorMode || ENUM_COLOR_MODE.Gradient;

                // ignore set color from template if this is mapped channel, use color from mapped channel instead
                // if (!isMappedChannel) {
                //   // use color from template first, secondary default color, lastly random color
                //   if (channelItem.color) {
                //     channelObj.color = channelItem.color;
                //   }
                //   if (!channelObj.color) {
                //     channelObj.color = Helper.randomHexColor();
                //   }
                // } else if (!channelObj.color) { // get random color if template channel doesn't have color
                //   channelObj.color = Helper.randomHexColor();
                // }

                const channelSettingModel = this.formatChannelModel(channelObj);
                channelSettings[yAxisChannelSettingKey].channels.push(channelSettingModel);
              }
            });
          }
        }
      }
    }
    return channelSettings;
  }

  handleChannelsColor(channels: any[], channelsMapping: any[]): any[] {
    if (channels && channels.length && channelsMapping && channelsMapping.length) {
      return channels.map(channel => {
        if (!channel.color && !channel.defaultColor) {
          const dataExist = channelsMapping.find(item => item.name === channel.name);
          if (dataExist && (dataExist.color || dataExist.defaultColor)) {
            channel.color = dataExist.color ? dataExist.color : dataExist.defaultColor;
          }
        } else if (!channel.color) {
          channel.color = channel.defaultColor;
        }
        return channel;
      });
    } else {
      return channels;
    }
  }

}

import { ITimeRangeFilter } from "../services/charts";
import { IChannelItem } from "./channel.model";

export interface IBourdetOption {
  isSmoothData?: boolean;
  smoothRange?: number;
  isBourdetDer?: boolean;
  bourdetDerRange?: number;
  startDate?: string;
  endDate?: string;
}

export interface IOffsetWellCompare {
  wellId: number;
  wellName: string;
  treatmentId: number;
  treatmentNumber?: number;
  treatmentType?: number;
  treatmentName?: string;
  compStartTime: number;
  compEndTime: number;
  axisType: AxisType;
  realtimeChannels: IChannelItem[];
  isRealtime?: boolean;
  isProducingWell?: boolean;
  isSmoothData?: boolean;
  smoothRange?: number;
  hasBourdetDer?: boolean;
  bourdetDerRange?: number;
  isCompare?: boolean;
}

export class OffsetWellCompare implements IOffsetWellCompare {
  wellId: number;
  wellName: string;
  treatmentId: number;
  treatmentNumber: number;
  treatmentType: number;
  treatmentName: string;
  compStartTime: number;
  compEndTime: number;
  axisType: AxisType;
  realtimeChannels: IChannelItem[];
  isRealtime: boolean;
  isProducingWell: boolean;
  isSmoothData: boolean;
  smoothRange: number;
  hasBourdetDer: boolean;
  bourdetDerRange: number;
  isCompare: boolean;

  constructor(
    wellId, wellName, treatmentId, compStartTime, compEndTime, axisType, realtimeChannels, isRealtime?,
    treatmentNumber?: number,
    treatmentType?: number,
    isProducingWell?,
    treatmentName?: string,
    isSmoothData?: boolean,
    smoothRange?: number,
    hasBourdetDer?: boolean,
    bourdetDerRange?: number,
    isCompare?: boolean
  ) {
    this.wellId = wellId;
    this.wellName = wellName;
    this.treatmentId = treatmentId;
    this.treatmentNumber = treatmentNumber;
    this.treatmentType = treatmentType;
    this.treatmentName = treatmentName;
    this.compStartTime = compStartTime;
    this.compEndTime = compEndTime;
    this.axisType = axisType;
    this.realtimeChannels = realtimeChannels;
    this.isRealtime = isRealtime;
    this.isProducingWell = isProducingWell;
    this.isSmoothData = isSmoothData;
    this.smoothRange = smoothRange;
    this.hasBourdetDer = hasBourdetDer;
    this.bourdetDerRange = bourdetDerRange;
    this.isCompare = isCompare;
  }
}

export type AxisType = 'Left' | 'Leftmost' | 'Right';

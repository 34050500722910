import { Injectable } from '@angular/core';

export const SEARCH_WELL_LIST_OPT = 'search_well_list_opt';
export const SEARCH_WELL_SELECTED = 'search_well_selected';

@Injectable()
export class SearchWellOptionsStorageService  {
  constructor() { }

  setListOptions(data: any, userId: number) {
    if (userId) data['userId'] = userId;
    const value = data ? JSON.stringify(data) : undefined;
    localStorage.setItem(SEARCH_WELL_LIST_OPT, value);
  }

  getListOptions(userId: number) {
    try {
      const stringData = localStorage.getItem(SEARCH_WELL_LIST_OPT);
      const data = JSON.parse(stringData);
      if (userId) {
        if (data['userId'] === userId) return data;
        else null;
      } else return data;
    } catch (error) {
      return null;
    }
  }

  removeListOptions() {
    localStorage.removeItem(SEARCH_WELL_LIST_OPT);
  }

  clearAll() {
    localStorage.clear();
  }
}

import { KEY_SCROLLBAR_SETTINGS, ScrollbarChartSettingsService, OFFSET_DATA_BEFORE_TREATMENT_START, OFFSET_DATA_AFTER_TREATMENT_END } from './../../../services/scrollbar-chart-settings.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { isEmpty, isString, isNumber, forEach, isNil, capitalize } from 'lodash';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
import HC_noData from 'highcharts/modules/no-data-to-display';
import HC_annotations from 'highcharts/modules/annotations';
import HC_draggablePoints from 'highcharts/modules/draggable-points';
HC_annotations(Highcharts);
HC_noData(Highcharts);
HC_exporting(Highcharts);
HC_offline_exporting(Highcharts);
HC_draggablePoints(Highcharts);

import { IChannelSettingModel, ITimeSetting, IInputDataMultiFlow, IChartDataState, IOffsetDataState, IInputDataChart, IChannelItem } from '../../../../shared/models';
import { BaseChartService, TIMEFLOAT_CHANNEL_NAME, FRACPRO_TIMEFLOAT_CNAME, DATETIME_CHANNEL_NAME, EQUIPMENT_TIMEFLOAT_CNAME, ENUM_CURSOR_OPTS, CURSOR_OPT_DEFAULT } from '../../../../shared/services/charts/base-chart.service';
import { ChannelSelectionService } from '../../../services';
import { of, forkJoin, from, Subscription } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import { CHART_MESSAGES, CHART_SERIES_NAME, ENUM_THEMES, ENUM_LOS_DEFAULTS, FDI_PARAM, FDI_PARAM_TENANT, INPUT_DATA_KEY, LOS_DEFAULTS, TIME_SETTING_TYPE, YAXIS_KEYS } from '../../../helpers/app.constants';
import { CustomDataPoint, IDiagnosticPoint, IDiagnosticPointDataChange } from '../../../models/diagnostic-points.model';
import { IOffsetWellCompare } from '../../../models/offset-well-compare.model';
import { CompareOffsetChannelsService } from '../../../services/compare-offset-channels.service';
import { ID_YAXIS_FDI, TreatmentPlotFdiService } from '../../treatment-plot/treatment-plot-fdi.service';
import { IFdiDropEventData, TreatmentPlotFDI } from '../../treatment-plot/treatment-plot-fdi.model';
import { ITreatmentItem } from '../../../models/treatment.model';
import { Helper } from '../../../helpers/helper';

import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { ThemesSettingsService } from '../../../services/themes-settings.service';
import { UnitSystemService } from '../../../services/unit-system.service';
(window as any).moment = moment;
(window as any).momentTz = momentTz;

export const INPUT_DATA_FLOWS_KEYS = [INPUT_DATA_KEY.fracproData, INPUT_DATA_KEY.realtimeData, INPUT_DATA_KEY.equipmentData];

export const CHART_ID_DEFAULT = 'multi-flow-chart';

@Component({
  selector: 'app-multi-flow-data-chart',
  templateUrl: './multi-flow-data-chart.component.html',
  styleUrls: ['./multi-flow-data-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiFlowDataChartComponent implements OnInit, OnChanges, OnDestroy {
  subscriptions: Subscription[] = [];

  @Input() companyId: number;
  @Input() chartId: string = CHART_ID_DEFAULT;
  @Input() chartHeight: string = '620px';
  @Input() chartWidth: string = '100%';

  @Input()
  set inputState(state: IChartDataState) {
    if (state) {
      this.channelSettings = state.channelSettings;
      this.filterSettings = state.filterSettings;
      this.inputChartData = state.inputData;
      this.offsetDataState = state.offsetDataState;
      this.timezoneOffset = state.timezoneOffset;
      this.listDiagnosticPoints = state.listDiagnosticPoints;
      this.losPoints = state.losPoints;
      this.baseTreatmentDataTime = state.baseTreatmentDataTime;
      this.treatmentInfo = state.treatmentInfo;
      this.wellInfo = state.wellInfo;
      this.realtimeChannels = state.realtimeChannels;
      this.fracProChannels = state.fracProChannels;
    }
  }

  @Input()
  set inputReflow(isReflow: any) {
    if (this.highChart) {
      this.highChart.reflow();
    }
  }

  @Input() defaultTextData: string = '';
  @Input() isFilterData: boolean = true;
  cursor: { id: string, text: string, typeCursor: string };

  @Output() chartDataLoad: EventEmitter<any> = new EventEmitter();
  @Output() diagnosticPointsDisplay: EventEmitter<IDiagnosticPointDataChange | IDiagnosticPointDataChange[]> = new EventEmitter();
  @Output() diagnosticPointDrag: EventEmitter<any> = new EventEmitter();
  @Output() diagnosticPointDrop: EventEmitter<IDiagnosticPointDataChange> = new EventEmitter();
  @Output() fdiDrag: EventEmitter<IFdiDropEventData> = new EventEmitter();
  @Output() fdiDrop: EventEmitter<IFdiDropEventData> = new EventEmitter();
  @Output() fdiMouseOver: EventEmitter<IFdiDropEventData> = new EventEmitter();
  @Output() treatmentStartEndDrop: EventEmitter<IFdiDropEventData> = new EventEmitter();
  @Output() returnMinMaxTime: EventEmitter<any> = new EventEmitter();
  @Output() losPointsChange: EventEmitter<any> = new EventEmitter();

  realtimeChannels: IChannelItem[] = [];
  fracProChannels: IChannelItem[] = [];
  inputChartData: IInputDataMultiFlow;
  offsetDataState: IOffsetDataState;
  treatmentInfo: ITreatmentItem;
  wellInfo: any;
  channelSettings: IChannelSettingModel;
  filterSettings: ITimeSetting = {
    type: TIME_SETTING_TYPE.OffsetTime,
    startIndex: 0,
    endIndex: 0
  };
  listDiagnosticPoints: IDiagnosticPoint[] = [];
  losPoints: IDiagnosticPoint[] = [];
  highChart: Highcharts.Chart;
  Highcharts = Highcharts;
  chartOptions: Highcharts.Options | any;
  updateFlagChart: boolean = false;
  isLoadingChart = false;
  messageNoData: string = '';
  timezoneOffset: number;
  minTime: any;
  baseTreatmentDataTime: string;
  isDarkTheme: boolean;

  unitSetting: number;
  @Input() isReloadDataChart: boolean;

  constructor(
    private ref: ChangeDetectorRef,
    private unitSystemService: UnitSystemService,
    private baseChartService: BaseChartService,
    private channelSelectionService: ChannelSelectionService,
    private compareOffsetChannelsService: CompareOffsetChannelsService,
    private treatmentPlotFdiService: TreatmentPlotFdiService,
    private themesSettingsService: ThemesSettingsService
  ) {
    const localData = this.themesSettingsService.getLocalStorage();
    if (localData && localData.themes === ENUM_THEMES.Dark) {
      this.isDarkTheme = true;
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    this.unitSetting = this.unitSystemService.loadUnitSettings();
  }

  ngOnInit() {
    this.setupChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.inputState) return;
    // if this is the first time loaded: use setTimeout to wait for the chart to setup first
    if (changes.inputState.isFirstChange()) {
      setTimeout(() => this.updateChart(this.channelSettings, this.filterSettings, this.inputChartData, this.offsetDataState, this.listDiagnosticPoints, this.losPoints), 100);
    } else {
      this.updateChart(this.channelSettings, this.filterSettings, this.inputChartData, this.offsetDataState, this.listDiagnosticPoints, this.losPoints);
    }
  }

  ngOnDestroy() {
    Helper.unsubscribe(this.subscriptions);
  }

  chartInstanceHandler(chart) {
    this.highChart = chart;
  }

  getChartInstance() {
    return this.highChart;
  }

  public setLoadingChart(value: boolean) {
    this.isLoadingChart = value;
  }

  setupChart(timeOut = true) {
    const self = this;
    // also the line rendered in boost enabled is not meet with customer requirement
    const chartDefaultOptions = this.baseChartService.getDefaultOptions(false, undefined, this.isDarkTheme, this.cursor);
    this.chartOptions = chartDefaultOptions;

    this.chartOptions.chart.events = {
      selection(event) {
        const result = { minX: null, maxX: null };
        if (event.xAxis) {
          result.minX = event.xAxis[0].min;
          result.maxX = event.xAxis[0].max;
        } else if (this.xAxis && this.xAxis[0]) {
          const xAxisData = this.xAxis[0].getExtremes();
          if (xAxisData) {
            result.minX = xAxisData.dataMin;
            result.maxX = xAxisData.dataMax;
          }
        }
        self.returnMinMaxTime.emit(result);
      }
    };

    this.chartOptions.yAxis = [];
    // set timezone offset
    // if (this.timezoneOffset) {
    //   this.chartOptions.time.timezoneOffset = this.timezoneOffset;
    // }
    if (this.defaultTextData) {
      this.chartOptions.lang.noData = this.defaultTextData;
    }
    if (!this.chartId || !this.chartId.trim()) this.chartId = CHART_ID_DEFAULT;
    if (timeOut) {
      setTimeout(() => {
        this.highChart = Highcharts.chart(this.chartId, this.chartOptions);
      }, 100);
    } else {
      this.highChart = Highcharts.chart(this.chartId, this.chartOptions);
    }
  }

  parseInputDataToSeriesData(channelSettings: IChannelSettingModel, inputData: IInputDataMultiFlow, timeFormat: string, offsetWellCompare?: IOffsetWellCompare) {
    let seriesData = [];
    INPUT_DATA_FLOWS_KEYS.forEach(dataType => {
      if (inputData.hasOwnProperty(dataType)) {
        if (!isEmpty(inputData[dataType])) {
          const dataFlow = inputData[dataType][0];
          const prefixChannel = this.channelSelectionService.getPrefixChannelByDataType(dataType);
          const channelSettingsByDataType = this.channelSelectionService.filterChannelsByPrefixStr(channelSettings, prefixChannel);
          const extraInfo = { isOffsetSeries: !!offsetWellCompare };
          const parsedData = this.baseChartService.parseSeriesData(channelSettingsByDataType, [], dataFlow, this.realtimeChannels, timeFormat, dataType, extraInfo, this.unitSetting);
          seriesData = seriesData.concat(parsedData);
        }
      }
    });
    return seriesData;
  }

  private mapInputData(channelSettings: IChannelSettingModel, inputRealtimeData: IInputDataChart[], inputFracproData: IInputDataChart[]): IInputDataChart[] {
    const hasChannel = (inputDataChart: IInputDataChart[]) => {
      for (const key of YAXIS_KEYS) {
        if (channelSettings[key] && channelSettings[key].channels && channelSettings[key].channels.length) {
          for (const channel of channelSettings[key].channels) {
            if (inputDataChart[0].columns.includes(channel.cName)) return true;
          }
        }
      }
      return false;
    };

    const hasRealtimeData = inputRealtimeData && inputRealtimeData.length && hasChannel(inputRealtimeData);
    const hasFracproData = inputFracproData && inputFracproData.length && hasChannel(inputFracproData);
    if (!hasRealtimeData && !hasFracproData) return [];
    if (!hasRealtimeData || !inputRealtimeData[0].values.length) return inputFracproData;
    if (!hasFracproData || !inputFracproData[0].values.length) return inputRealtimeData;

    if (inputRealtimeData[0].values[0][0] > inputFracproData[0].values[0][0]) return inputFracproData;
    return inputRealtimeData;
  }

  private getFirstLastData(channelSettings: IChannelSettingModel, inputRealtimeData: IInputDataChart[], inputFracproData: IInputDataChart[]): { firstData: any; lastData: any; } {
    const hasChannel = (inputDataChart: IInputDataChart[]) => {
      if (!inputDataChart || !inputDataChart.length) return false;
      for (const key of YAXIS_KEYS) {
        if (channelSettings[key] && channelSettings[key].channels && channelSettings[key].channels.length) {
          for (const channel of channelSettings[key].channels) {
            if (inputDataChart[0].columns.includes(channel.cName) && inputDataChart[0].values.length) return true;
          }
        }
      }
      return false;
    };

    const hasRealtimeData = inputRealtimeData && inputRealtimeData.length && hasChannel(inputRealtimeData);
    const hasFracproData = inputFracproData && inputFracproData.length && hasChannel(inputFracproData);
    if (!hasRealtimeData && !hasFracproData) return { firstData: null, lastData: null };
    if (!hasRealtimeData || !inputRealtimeData[0].values.length) return this.baseChartService.getFirstLastData(inputFracproData[0]);
    if (!hasFracproData || !inputFracproData[0].values.length) return this.baseChartService.getFirstLastData(inputRealtimeData[0]);


    const firstFracproData = inputFracproData[0].values[0][0];
    const lastFracproData = inputFracproData[0].values[inputFracproData[0].values.length - 1][0];

    const firstRealtimeData = inputRealtimeData[0].values[0][0];
    const lastRealtimeData = inputRealtimeData[0].values[inputRealtimeData[0].values.length - 1][0];

    const firstData = firstRealtimeData > firstFracproData ? inputFracproData[0].values[0] : inputRealtimeData[0].values[0];
    const lastData = lastFracproData > lastRealtimeData ? inputFracproData[0].values[inputRealtimeData[0].values.length - 1] : inputRealtimeData[0].values[inputRealtimeData[0].values.length - 1];

    return { firstData: firstData, lastData: lastData };
  }

  private calcStartEndIndex(channelSettings: IChannelSettingModel, timeSetting: ITimeSetting, inputRealtimeData: IInputDataChart[], inputFracproData: IInputDataChart[]) {
    const res = { startIndex: timeSetting.startIndex, endIndex: timeSetting.endIndex, firstData: null, lastData: null, inputData: null };
    // const iData = this.mapInputData(channelSettings, inputRealtimeData, inputFracproData);

    const { firstData, lastData } = this.getFirstLastData(channelSettings, inputRealtimeData, inputFracproData);
    res.firstData = firstData;
    res.lastData = firstData;
    // res.inputData = iData[0];

    if (!!timeSetting.autoScale && (!lastData || !timeSetting.lastMin)) {
      res.startIndex = res.endIndex = null;
      return res;
    }

    const timeTypeLowerCase = timeSetting.type ? timeSetting.type.toLowerCase() : '';
    if (timeSetting.type && timeTypeLowerCase === TIME_SETTING_TYPE.EpochTimeLowerCase) {
      if (lastData && timeSetting.lastMin) {
        const endIndex = new Date(lastData[0]).getTime();
        res.startIndex = endIndex - timeSetting.lastMin * 60_000;
      }
      if (firstData) {
        const startIndex = new Date(firstData[0]).getTime();
        if (res.startIndex < startIndex) res.startIndex = startIndex;
      }
      if (timeSetting.lastMin && res.endIndex - res.startIndex > timeSetting.lastMin * 60000) {
        res.startIndex = res.endIndex - timeSetting.lastMin * 60_000;
      }
      if (!!timeSetting.autoScale) res.endIndex = null;
    } else {
      if (lastData && timeSetting.lastMin) {
        const endIndex = lastData[1];
        res.startIndex = endIndex - timeSetting.lastMin * 60;
      }
      if (firstData) {
        const startIndex = firstData[1];
        if (res.startIndex < startIndex) res.startIndex = startIndex;
      }
      if (timeSetting.lastMin && res.endIndex - res.startIndex > timeSetting.lastMin * 60) {
        res.startIndex = res.endIndex - timeSetting.lastMin * 60;
      }
      if (!!timeSetting.autoScale) res.endIndex = null;
    }
    return res;
  }

  loadChartData(channelSettings: IChannelSettingModel, timeSetting: ITimeSetting, inputData: IInputDataMultiFlow, offsetDataState?: IOffsetDataState, listDiagnosticPoints?: IDiagnosticPoint[], losPoints?: IDiagnosticPoint[]) {
    const observables = [of(null).pipe(delay(100))];
    const self = this;

    if (!this.highChart || !channelSettings || !timeSetting) return forkJoin(observables);
    const timeFormat = timeSetting.type;
    // re-setup chart
    this.setupChart(false);
    let xAxisOpt;
    const cursorOpt = this.cursor ? this.cursor.id : ENUM_CURSOR_OPTS.Default;
    const crosshair = this.baseChartService.crosshair(cursorOpt);
    const isXAxisCrosshair = !!crosshair.x;
    const isYAxisCrosshair = !!crosshair.y;

    const mapDataIndex = this.calcStartEndIndex(channelSettings, timeSetting, inputData.realtimeData, inputData.fracproData);
    xAxisOpt = this.baseChartService.getXAxisOption(timeFormat, mapDataIndex.startIndex, mapDataIndex.endIndex, false, isXAxisCrosshair);
    this.highChart.addAxis(xAxisOpt, true);
    // get y axis options
    let yAxisData = this.baseChartService.getYAxisOption(channelSettings, undefined, this.unitSetting, isYAxisCrosshair);
    const timezone = this.wellInfo.tzTimeZone;

    Highcharts.setOptions({
      time: {
        getTimezoneOffset(timestamp) {
          return -momentTz.tz(timestamp, timezone).utcOffset();
        },
      }
    });

    // update tooltip header
    this.highChart.update({
      tooltip: {
        headerFormat: this.baseChartService.getTooltipHeader(timeFormat)
      },
    }, false);
    // parse all flow input data to chart's series data
    let seriesData = this.parseInputDataToSeriesData(channelSettings, inputData, timeFormat);
    // set startIndex and endIndex to detect zoomIn
    for (const series of seriesData) {
      if (!series.data || !series.data.length) continue;
    }

    // parse data for offset data
    if (offsetDataState && offsetDataState.channelSettings && offsetDataState.channelData) {
      yAxisData = this.baseChartService.getYAxisOption(channelSettings, offsetDataState.channelSettings, this.unitSetting, isYAxisCrosshair);
      offsetDataState.channelData.forEach(offsetInputData => {
        const offsetChannel = this.compareOffsetChannelsService.getOffsetChannelInfo(offsetDataState.channelSettings, offsetInputData);
        if (offsetChannel.channel) {
          if (!offsetChannel.channel.isRealtime) {
            let primaryInputData;
            if (offsetInputData.realtimeData && offsetInputData.realtimeData.length) {
              primaryInputData = offsetInputData.realtimeData[0];
            } else if (offsetInputData.fracproData && offsetInputData.fracproData.length) {
              primaryInputData = offsetInputData.fracproData[0];
            }
            const offsetSeriesData = this.baseChartService.getDataSeriesFromChannels(offsetChannel.index, [offsetChannel.channel], [], primaryInputData, timeFormat, null, { isOffsetSeries: true }, this.unitSetting);
            seriesData.push(...offsetSeriesData);
          } else {
            let primaryInputData;
            if (inputData.realtimeData && inputData.realtimeData[0]) {
              primaryInputData = inputData.realtimeData[0];
            } else if (inputData.fracproData && inputData.fracproData[0]) {
              primaryInputData = inputData.fracproData[0];
            }
            if (primaryInputData && !isEmpty(offsetInputData.realtimeData) && offsetInputData.realtimeData[0]) {
              const offsetSeriesData = this.compareOffsetChannelsService.getOffsetChannelDataSeries(
                offsetChannel.index,
                offsetChannel.channel,
                primaryInputData,
                offsetInputData.realtimeData[0],
                [],
                timeFormat,
                this.unitSetting
              );
              seriesData.push(...offsetSeriesData);
            }
          }
        }
      });

      // add FDI y-axis but invisible
      const FDIAxisOpt: Highcharts.YAxisOptions = {
        id: ID_YAXIS_FDI,
        title: {
          text: 'FDI',
          useHTML: false,
        },
        opposite: false,
        showEmpty: false,
        min: 0,
        max: 100,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: false,
        visible: false
      };
      yAxisData.push(FDIAxisOpt);

      // load fdis
      if (!isEmpty(offsetDataState.listFDIs) && offsetDataState.fdiPayload) {
        const onDragHandler = (event) => {
          this.fdiDrag.emit(event.target);
        };
        // load treatment start/end fdis
        const onTreatmentStartEndDropHandler = (event) => {
          this.treatmentStartEndDrop.emit(event.target);
        };

        const staticFDISeries = this.treatmentPlotFdiService.parseTreatmentChartSeries({
          baseTreatmentDataTime: this.baseTreatmentDataTime,
          timeFormat,
          yAxisIndex: yAxisData.length - 1,
          treatmentStart: offsetDataState.fdiPayload.treatmentStart,
          treatmentEnd: offsetDataState.fdiPayload.treatmentEnd,
          treatmentInfo: this.treatmentInfo,
          startIndex: timeSetting.startIndex,
          endIndex: timeSetting.endIndex,
          onDropHandler: onTreatmentStartEndDropHandler,
          onDragHandler
        });
        seriesData = seriesData.concat(staticFDISeries);

        const onDropHandler = (event) => {
          this.fdiDrop.emit(event.target);
        };
        const onMouseOverHandler = (event) => {
          this.fdiMouseOver.emit(event.target);
        };
        // load dynamic fdis
        offsetDataState.listFDIs.forEach(fdiInfo => {
          const dynamicFDISeries = this.treatmentPlotFdiService.parseFDIChartSeries({
            baseTreatmentDataTime: this.baseTreatmentDataTime,
            timeFormat,
            yAxisIndex: yAxisData.length - 1,
            fdiInfo,
            onDropHandler, onMouseOverHandler, onDragHandler
          });
          seriesData = seriesData.concat(dynamicFDISeries);
        });

        // load tangent FDI dot lines
        const tangentSeries = this.treatmentPlotFdiService.parseFDITangentToChartSeries(seriesData, FDI_PARAM_TENANT);
        seriesData = seriesData.concat(tangentSeries);
      }
    }

    // load diagnostic point (vertical line)
    let loadPivotPoints = (dataList: any, keyOpt?: string, name?: any) => {
      if (!keyOpt) keyOpt = 'diag';
      if (!name) name = CHART_SERIES_NAME.diagnostic;
      let dragPrecisionX = 1;
      if (timeFormat === TIME_SETTING_TYPE.EpochTime) {
        dragPrecisionX = 1000;
      }
      // add a virsual yAxis for vertical line series
      const diagnoctisAxisOpt: Highcharts.YAxisOptions = {
        title: {
          text: capitalize(name),
          useHTML: false,
        },
        opposite: false,
        showEmpty: false,
        min: 0,
        max: 100,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: false,
        visible: false
      };
      yAxisData.push(diagnoctisAxisOpt);

      dataList.forEach((diagPoint, index) => {
        let offsetTimeSec = (index + 1) * 2; // default when unset
        let timefloat = { start: null, end: null };
        let timestamp = { start: null, end: null };

        if (inputData.realtimeData && inputData.realtimeData.length) {
          const timeRange = this.baseChartService.getTimeRangeFilter(inputData.realtimeData[0]);
          timefloat = timeRange.timefloat;
          timestamp = timeRange.timestamp;
        } else if (inputData.fracproData && inputData.fracproData.length) {
          const timeRange = this.baseChartService.getTimeRangeFilter(inputData.fracproData[0]);
          timefloat = timeRange.timefloat;
          timestamp = timeRange.timestamp;
        }

        if (isNumber(diagPoint.timeOffSet)) {
          if (timeFormat !== TIME_SETTING_TYPE.EpochTime) {
            offsetTimeSec = diagPoint.timeOffSet; // in sec
            if (timefloat.start && diagPoint.timeOffSet < timefloat.start) offsetTimeSec = timefloat.start;
            if (timefloat.end && diagPoint.timeOffSet > timefloat.end) offsetTimeSec = timefloat.end;
          } else {
            if (this.baseTreatmentDataTime) {
              // convert offset value to epoch value
              offsetTimeSec = diagPoint.timeOffSet;
              if (timefloat.start && diagPoint.timeOffSet < timefloat.start) offsetTimeSec = timefloat.start;
              if (timefloat.end && diagPoint.timeOffSet > timefloat.end) offsetTimeSec = timefloat.end;
              offsetTimeSec = new Date(this.baseTreatmentDataTime).getTime() + offsetTimeSec * 1000;
              // offsetTimeSec = offsetTimeSec + this.timezoneOffset * 60 * 1000;
            }
          }
        }
        seriesData.push({
          id: `${keyOpt}_${diagPoint.id}`,
          name: name,
          type: 'line',
          tooltip: false,
          data: [
            { x: offsetTimeSec, y: 0, groupId: `${diagPoint.id}`, name: `${diagPoint.name}` },
            { x: offsetTimeSec, y: 100, groupId: `${diagPoint.id}`, name: `${diagPoint.name}` }
          ],
          showInLegend: false,
          yAxis: yAxisData.length - 1,
          color: diagPoint.color,
          lineWidth: 3,
          dragDrop: {
            draggableX: true,
            dragMinX: 0,
            // dragMaxX: 1000,
            groupBy: 'groupId',
            dragSensitivity: 5,
            dragPrecisionX
          },
          stickyTracking: false,
          visible: !!diagPoint.selected,
          point: {
            events: {
              drop(e) {
                self.diagnosticPointDrop.emit(e.target);
                this.series.update({
                  cursor: 'grab'
                });
              },
              dragStart(e) {
                self.diagnosticPointDrag.emit(e.target);
                this.series.update({
                  cursor: 'grabbing'
                });
              }
            }
          },
          cursor: 'grab',
          events: {
            click(e) {
            }
          }
        });
      });
    }

    if (listDiagnosticPoints && !isEmpty(listDiagnosticPoints)) {
      loadPivotPoints(listDiagnosticPoints, 'diag', CHART_SERIES_NAME.diagnostic);
    }
    // handle LOS feature
    if (!losPoints || losPoints.length === 0) {
      let firstPos = null;
      let lastPos = null;
      seriesData
        .filter(series => series.name !== 'diagnostic' && series.name !== 'Diagnostic')
        .forEach(series => {
          if (series.data && series.data.length > 0) {
            if (firstPos === null || firstPos > series.data[0].x) firstPos = series.data[0].x;
            if (lastPos === null || lastPos > series.data[series.data.length - 1].x) lastPos = series.data[series.data.length - 1].x;
          }
        });

      if (firstPos !== null && lastPos !== null && lastPos >= firstPos) {
        let convertMinMaxTime = ({ xValue, timeFormat, baseTreatmentDataTime }) => {
          let timeOffset;
          if (timeFormat === TIME_SETTING_TYPE.EpochTime) {
            timeOffset = xValue - new Date(baseTreatmentDataTime).getTime();
            timeOffset = timeOffset / 1000; // in seconds
          } else { timeOffset = xValue; }
          return timeOffset;
        }
        losPoints = [];
        LOS_DEFAULTS.forEach(item => {
          const point = Object.assign({
            id: item.id,
            name: item.text,
            timeOffSet: item.id === ENUM_LOS_DEFAULTS.MinMaxStart ? convertMinMaxTime({
              xValue: firstPos * 1000, timeFormat: timeFormat, baseTreatmentDataTime: this.baseTreatmentDataTime
            }) : convertMinMaxTime({
              xValue: lastPos * 1000, timeFormat: timeFormat, baseTreatmentDataTime: this.baseTreatmentDataTime
            }),
            color: item.color || Helper.randomHexColor(),
            isDefault: true
          }) as IDiagnosticPoint;
          losPoints.push(point);
        })
        this.losPoints = losPoints;
        this.losPointsChange.emit(losPoints);
      }
    }
    if (losPoints && !isEmpty(losPoints)) {
      loadPivotPoints(losPoints, CHART_SERIES_NAME.los, CHART_SERIES_NAME.los);
    }

    // add y axis in highchart
    yAxisData.forEach(yAxis => {
      this.highChart.addAxis(yAxis, false);
    });

    // check if all series is empty data then return empty array (prevent show series legend on chart
    if (this.channelSelectionService.isEmptyChannels(channelSettings)) {
      // this.setChartMessage(CHART_MESSAGES.en.pleaseSelectChannel);
    } else {
      const promiesHandleSeries = new Promise<void>((resolve) => {
        if (this.baseChartService.isAllSeriesDataEmpty(seriesData)) {
          seriesData = [];
        }
        if (isEmpty(seriesData)) {
          this.setChartMessage(CHART_MESSAGES.en.noData);
        }
        seriesData.forEach(series => {
          this.highChart.addSeries(series, false, false);
        });
        setTimeout(() => {
          this.highChart.redraw();
          this.highChart.setSize(this.highChart.chartWidth, this.highChart.chartHeight);
          // this.highChart.options.time.timezoneOffset = this.timezoneOffset;
          this.highChart.options.chart.width = null;
          this.highChart.options.chart.height = null;
          resolve();
        }, 100);
      });

      const handleSeries$ = from(promiesHandleSeries);
      observables.push(handleSeries$);
    }

    return forkJoin(observables);
  }

  private updateChart(channelSettings: IChannelSettingModel, filterSettings: ITimeSetting, inputChartData: IInputDataMultiFlow, offsetDataState, listDiagnosticPoints, losPoints): void {
    if (inputChartData && channelSettings && filterSettings) {
      this.isLoadingChart = true;
      let inputData = inputChartData;
      if (this.isFilterData) inputData = this.handleFilterInputData(inputData, filterSettings, channelSettings);

      this.subscriptions.push(
        this.loadChartData(channelSettings, filterSettings, inputData, offsetDataState, listDiagnosticPoints, losPoints)
        .pipe(finalize(() => {
          this.isLoadingChart = false;
          this.ref.detectChanges();
          this.chartDataLoad.emit();
        }))
        .subscribe()
      );
    }
  }

  private handleFilterInputData(inputData: IInputDataMultiFlow, filterSetting: ITimeSetting, channelSettings?: IChannelSettingModel): IInputDataMultiFlow {
    const res: any = {};
    if (!inputData || !filterSetting) return res;
    if (filterSetting.autoScale) {
      res.fracproData = inputData.fracproData;
      res.realtimeData = inputData.realtimeData;
      return res;
    }

    let channelFiltering;
    const startTime = filterSetting.startIndex;
    const endTime = filterSetting.endIndex;
    const filterType = isString(filterSetting.type) ? filterSetting.type.toLowerCase() : '';
    // filter by datetime column
    if (filterType === TIME_SETTING_TYPE.EpochTimeLowerCase) {
      channelFiltering = DATETIME_CHANNEL_NAME;
      //   if (filterSetting.lastMin && endTime - startTime > filterSetting.lastMin * 60000) {
      //     startTime = endTime - filterSetting.lastMin * 60_000;
      //   }
      // } else {
      //   if (filterSetting.lastMin && endTime - startTime > filterSetting.lastMin * 60) {
      //     startTime = endTime - filterSetting.lastMin * 60;
      //   }
    }

    // filter all flow data
    INPUT_DATA_FLOWS_KEYS.forEach(key => {
      if (inputData.hasOwnProperty(key)) {
        if (!isEmpty(inputData[key])) {
          const dataFlowObj = inputData[key][0];
          // filter by float column
          if (filterType === TIME_SETTING_TYPE.OffsetTimeLower) {
            if (key === 'fracproData') {
              channelFiltering = FRACPRO_TIMEFLOAT_CNAME;
            } else if (key === 'realtimeData') {
              channelFiltering = TIMEFLOAT_CHANNEL_NAME;
            } else if (key === 'equipmentData') {
              channelFiltering = EQUIPMENT_TIMEFLOAT_CNAME;
            }
          }
          res[key] = [this.baseChartService.filterInputData(dataFlowObj, startTime, endTime, channelFiltering)];
        }
      }
    });

    return res;
  }

  resetChart(isRedraw?: boolean) {
    if (this.highChart) {
      this.chartOptions.xAxis = [];
      this.chartOptions.yAxis = [];
      this.chartOptions.series = [];
      // clear chart and reload data
      while (this.highChart.series.length > 0) {
        this.highChart.series[0].remove(false);
      }
      while (this.highChart.yAxis.length > 0) {
        this.highChart.yAxis[0].remove(false);
      }
      while (this.highChart.xAxis.length > 0) {
        this.highChart.xAxis[0].remove(false);
      }

      // remove annotations
      // this.clearAnnotations();
      if (isRedraw) {
        this.highChart.redraw();
      }
    }
  }

  setAxisMinMax(min: number, max: number, isYAxis?: boolean, isRedraw?: boolean) {
    const axisKey = isYAxis ? 'yAxis' : 'xAxis';
    if (this.highChart && !isEmpty(this.highChart[axisKey])) {
      const axisItems = this.highChart[axisKey];
      const axisLength = axisItems.length;
      for (let index = 0; index < axisLength; index++) {
        // do NOT set min/max to non-treatment YAxis
        if (isYAxis) {
          const axisLabel = this.highChart[axisKey][index]['userOptions'].title.text;
          if (!axisLabel || axisLabel === 'FDI' || axisLabel === 'Diagnostic' || axisLabel === 'Los') continue;
        }
        this.highChart[axisKey][index].update({ min, max }, isRedraw);
      }

      // forEach(axisItems, (value, index) => {
      //   this.highChart[axisKey][index].update({ min, max }, isRedraw);
      // });
    }
  }

  setChartMessage(message: string) {
    if (typeof message === 'string' && this.highChart) {
      const noDataEle = document.querySelector('.highcharts-no-data');
      if (noDataEle) {
        const noDataEleText = noDataEle.querySelector('text tspan');
        if (noDataEleText) {
          noDataEleText.textContent = message;
          this.messageNoData = message;
        }
      }
    }
  }

  zoomOut() {
    this.highChart.zoomOut();
  }

  reflow() {
    if (!this.highChart) return;
    this.highChart.reflow();
  }

  exportChart(fileName?: string, type?: string) {
    const chart = this.highChart;
    if (type === 'Save PNG') {
      chart.exportChartLocal({
        filename: `${fileName}`,
        sourceWidth: chart.chartWidth,
        sourceHeight: chart.chartHeight,
        scale: 4,
        type: 'image/png'
      }, {
        lang: {
          noData: `${this.messageNoData}`
        },
      });
    } else if (type === 'Save JPEG') {
      chart.exportChartLocal({
        filename: `${fileName}`,
        sourceWidth: chart.chartWidth,
        sourceHeight: chart.chartHeight,
        scale: 8,
        type: 'image/jpeg'
      }, {
        lang: {
          noData: `${this.messageNoData}`
        },
      });
    } else {
      chart.print();
    }
  }

  updateSeriesData(inputData: IInputDataMultiFlow, filterSettings: ITimeSetting, isRedraw?: boolean, offsetDataState?: IOffsetDataState) {
    if (!this.highChart || !this.channelSettings || !filterSettings || !filterSettings.type) return;

    // STEP 1: handle filter input data by time setting
    const filteredData = this.handleFilterInputData(inputData, filterSettings, this.channelSettings);
    const seriesData = [];
    for (const dataType in filteredData) {
      if (!filteredData[dataType] || !filteredData[dataType][0]) continue;
      const dataFlow = filteredData[dataType][0];
      const prefixChannel = this.channelSelectionService.getPrefixChannelByDataType(dataType);
      const channelSettingsByDataType = this.channelSelectionService.filterChannelsByPrefixStr(this.channelSettings, prefixChannel);
      const parsedData = this.baseChartService.parseSeriesData(channelSettingsByDataType, this.highChart.series, dataFlow, this.realtimeChannels, filterSettings.type, dataType, undefined, this.unitSetting);
      seriesData.push(...parsedData);
    }

    // STEP 2: parse data for offset data
    if (offsetDataState && offsetDataState.channelSettings && offsetDataState.channelData) {
      for (const offsetInputData of offsetDataState.channelData) {
        const offsetChannel = this.compareOffsetChannelsService.getOffsetChannelInfo(offsetDataState.channelSettings, offsetInputData);
        if (offsetChannel.channel) {
          if (!offsetChannel.channel.isRealtime) {
            const offsetFirstRealtimeData = offsetInputData.realtimeData && offsetInputData.realtimeData.length ? offsetInputData.realtimeData[0] : null;
            const offsetSeriesData = this.baseChartService.getDataSeriesFromChannels(offsetChannel.index, [offsetChannel.channel], this.highChart.series, offsetFirstRealtimeData, filterSettings.type, null, { isOffsetSeries: true }, this.unitSetting);
            seriesData.push(...offsetSeriesData);
          } else {
            const getFirstInputData = (realtimeData, fracproData) => {
              if (realtimeData && realtimeData[0]) return realtimeData[0];
              if (fracproData && fracproData[0]) return fracproData[0];
              return null;
            };
            const primaryInputData = getFirstInputData(inputData.realtimeData, inputData.fracproData);
            if (!primaryInputData || !offsetInputData.realtimeData || !offsetInputData.realtimeData.length) continue;
            const offsetSeriesData = this.compareOffsetChannelsService.getOffsetChannelDataSeries(
              offsetChannel.index,
              offsetChannel.channel,
              primaryInputData,
              offsetInputData.realtimeData[0],
              this.highChart.series,
              filterSettings.type,
              this.unitSetting
            );
            seriesData.push(...offsetSeriesData);
          }
        }
      }
    }

    // STEP 3: if there is seriesData: update highChart or add series
    if (!seriesData || !seriesData.length) return;
    if (!this.highChart.series || !this.highChart.series.length) {
      for (const series of seriesData) {
        this.highChart.addSeries(series, false, false);
      }
      isRedraw = true;
    }
    else {
      // for each series update their data in the highChart
      for (const series of this.highChart.series) {
        // check if there seriesItem has data to append or set
        const seriesItem = seriesData.find(x => x.id === series.options.id);
        if (!seriesItem || !seriesItem.data || !seriesItem.data.length) continue;
        // check if user is zoomIn: if DOES: userMin and userMax has data
        const extreme = series.chart.xAxis[0].getExtremes();
        if (extreme.userMin !== undefined || extreme.userMax !== undefined) continue;
        const lastSeriesData = !series.data.length ? null : series.data[series.data.length - 1];

        // CASE 1: if there is data: addPoint
        if (lastSeriesData) {
          const lastIndex = seriesItem.data.length - 1;
          let addIndex = 0;
          for (let i = lastIndex; i >= addIndex; i--) {
            if (seriesItem.data[i].x > lastSeriesData.x) continue;
            addIndex = i;
            break;
          }
          for (let i = addIndex; i <= lastIndex; i++) {
            series.addPoint(seriesItem.data[i], false);
            isRedraw = true;
          }
        }
        // CASE 2: if NULL: setData
        else if (seriesItem) {
          series.setData(seriesItem.data);
        }
      }
      // if there is a series that the highChart have not render: add it
      for (const series of seriesData) {
        const seriesOption = this.highChart.series.find(x => x.options.id === series.id);
        if (seriesOption) continue;
        this.highChart.addSeries(series, false, false);
        isRedraw = true;
      }
    }

    // reset min max
    if (filterSettings.autoScale && filterSettings.lastMin) {
      let min = this.highChart['xAxis'][0].min;
      let endIndex: number;
      const timeTypeLowerCase = filterSettings.type ? filterSettings.type.toLowerCase() : '';
      const { firstData, lastData } = this.getFirstLastData(this.channelSettings, inputData.realtimeData, inputData.fracproData);

      if (filterSettings.type && timeTypeLowerCase === TIME_SETTING_TYPE.EpochTimeLowerCase) {
        if (lastData) {
          endIndex = new Date(lastData[0]).getTime();
          min = endIndex - filterSettings.lastMin * 60_000;
        }
        if (firstData) {
          const startIndex = new Date(firstData[0]).getTime();
          if (min < startIndex) min = startIndex;
        }
        if (endIndex && endIndex - min > filterSettings.lastMin * 60000) {
          min = endIndex - filterSettings.lastMin * 60_000;
        }
      } else {
        if (lastData) {
          endIndex = lastData[1];
          min = endIndex - filterSettings.lastMin * 60;
        }
        if (firstData) {
          const startIndex = firstData[1];
          if (min < startIndex) min = startIndex;
        }
        if (endIndex && endIndex - min > filterSettings.lastMin * 60) {
          min = endIndex - filterSettings.lastMin * 60;
        }
      }
      this.setAxisMinMax(min, null, false, true);
    }
    // end

    // STEP 4: call highChart redraw if isRedraw
    if (isRedraw) {
      setTimeout(() => {
        this.highChart.redraw();
        this.highChart.reflow();
      }, 100);
    }
  }

  toggleTooltip(isDisplay) {
    if (this.highChart) {
      this.highChart.update({
        tooltip: {
          enabled: isDisplay
          // formatter: this.baseChartService.getTooltipFormatter(!isDisplay)
        }
      });
    }
  }

  updateChartCursorOptions(cursor) {
    if (cursor) {
      this.cursor = cursor;
      if (this.highChart) {
        const crosshair = this.baseChartService.crosshair(this.cursor.id);
        const handleAxisCross = (axisOpt, crossValue) => {
          if (axisOpt) {
            if (Array.isArray(axisOpt) && axisOpt.length) {
              axisOpt.forEach(item => {
                item.crosshair = crossValue;
              })
            } else {
              axisOpt.crosshair = crossValue;
            }
          }
          return axisOpt;
        };
        // set xAxis
        let xAxisOpt: any = this.highChart.options.xAxis;
        xAxisOpt = handleAxisCross(xAxisOpt, crosshair.x);
        // set yAxis
        let yAxisOpt: any = this.highChart.options.yAxis;
        yAxisOpt = handleAxisCross(yAxisOpt, crosshair.y);
        // update chart
        this.highChart.update({
          tooltip: {
            shared: this.cursor.id === ENUM_CURSOR_OPTS.Default
          },
          xAxis: xAxisOpt,
          yAxis: yAxisOpt,
          plotOptions: {
            series: {
              cursor: this.cursor.id
            }
          }
        });
      }
    }
  }

  toggleAnnotations(annotationsOption, isDisplay?: boolean) {
    if (this.highChart) {
      // get comment series by id
      let commentSeries: any = this.highChart.get(CHART_SERIES_NAME.comment);
      if (!isEmpty(annotationsOption) && !isEmpty(this.highChart.xAxis) && !isEmpty(this.highChart.yAxis)) {
        const seriesObj: any = {
          id: CHART_SERIES_NAME.comment,
          name: CHART_SERIES_NAME.comment,
          zIndex: 100,
          // type: 'scatter',
          type: 'line',
          color: '#000',
          lineWidth: 0,
          states: {
            hover: {
              lineWidthPlus: 0,
              lineWidth: 0
            }
          },
          marker: {
            enabled: true,
            radius: 5,
            symbol: 'circle',
            states: {
              hover: {
                lineWidth: 0
              }
            },
          },
          tooltip: {
            headerFormat: '<div>',
            pointFormat: `
              <p><b>{point.comment}</b></p>
            `,
            footerFormat: '</div>',
            style: {
              backgroundColor: 'black',
              color: 'white'
            },
            enabled: false,
            animation: false
          },
          showInLegend: false,
          data: [],
          findNearestPointBy: 'xy'
        };
        // add comment series if doesn't exists
        if (!commentSeries) {
          this.highChart.addSeries(seriesObj);
          commentSeries = this.highChart.get(CHART_SERIES_NAME.comment);
        }
        if (isDisplay) commentSeries.setData(annotationsOption);
        else commentSeries.setData([]);
      } else {
        // set data as empty in case update comments data is an empty array
        if (commentSeries) commentSeries.setData([]);
      }
      setTimeout(() => {
        this.highChart.reflow();
      });
    }
  }

  clearAnnotations() {
    // remove annotations
    const chart: any = this.highChart;
    if (chart) {
      const annotations = chart.annotations;
      if (!isEmpty(annotations)) {
        for (let index = annotations.length - 1; index >= 0; index--) {
          const annotationObj = annotations[index];
          chart.removeAnnotation(annotationObj.options.id);
        }
      }
    }
  }

  toggleDiagnosticPoints(points: IDiagnosticPoint | IDiagnosticPoint[], keyOpt?: string): void {
    if (!points) return;
    if (points instanceof Array) {
      let pointsChange: IDiagnosticPointDataChange[] = [];
      forEach(points, (point) => {
        const pointChange = this.baseChartService.makeDiagnosticPoint(this.highChart, point, keyOpt);
        if (pointChange) pointsChange.push(pointChange);
      });
      if (pointsChange.length) this.diagnosticPointsDisplay.emit(pointsChange);
    } else {
      const diagnosticPoint = this.baseChartService.makeDiagnosticPoint(this.highChart, points, keyOpt);
      if (diagnosticPoint) this.diagnosticPointsDisplay.emit(diagnosticPoint);
    }
  }

  getFilterByTimeObj(startTime: number, endTime: number): ITimeSetting {
    const filterSetting: ITimeSetting = {
      type: TIME_SETTING_TYPE.EpochTime,
      startIndex: new Date(startTime).getTime(),
      endIndex: new Date(endTime).getTime(),
    };
    return filterSetting;
  }

  /**
   * FDI handlers
   */
  addFDIHandler({ baseTreatmentDataTime, timeFormat, timezoneOffset, fdiInfo, isStaticFDI, treatmentInfo, startIndex, endIndex, treatmentStart, treatmentEnd }) {
    const yAxisFDI: Highcharts.AxisOptions & { userOptions?: { index: number } } = Object.assign(this.highChart.get(ID_YAXIS_FDI));
    const highChartInstance = this.highChart;
    let yAxisIndex = 0;
    if (yAxisFDI && yAxisFDI.userOptions) {
      yAxisIndex = yAxisFDI.userOptions.index;
    }
    const onDragHandler = (event) => {
      this.fdiDrag.emit(event.target);
    };
    const onDropHandler = (event) => {
      this.fdiDrop.emit(event.target);
    };
    const onTreatmentStartEndDropHandler = (event) => {
      this.treatmentStartEndDrop.emit(event.target);
    };
    const onMouseOverHandler = (event) => {
      this.fdiMouseOver.emit(event.target);
    };
    this.treatmentPlotFdiService.addFDIHandler({
      highChartInstance,
      baseTreatmentDataTime,
      timeFormat,
      timezoneOffset,
      fdiInfo,
      yAxisIndex,
      isStaticFDI,
      treatmentInfo,
      startIndex,
      endIndex,
      treatmentStart,
      treatmentEnd,
      onDropHandler,
      onTreatmentStartEndDropHandler,
      onMouseOverHandler,
      onDragHandler
    });
  }

  removeAllFDIHandler() {
    const highChartInstance = this.highChart;
    this.treatmentPlotFdiService.removeFDIHandler({ highChartInstance });
  }

  toggleShowFDIHandler(isShow) {
    const highChartInstance = this.highChart;
    this.treatmentPlotFdiService.toggleShowFDIHandler({ highChartInstance, isShow });
  }

  updateFDIHandler(fdi: TreatmentPlotFDI, fdiStart: number, fdiEnd: number) {
    const highChartInstance = this.highChart;
    this.treatmentPlotFdiService.updateFDIHandler(highChartInstance, fdi, fdiStart, fdiEnd);
  }

  updateFDIPoint(fdiId: string, fdiKeyValue: string, xValue: number, yValue: number) {
    const highChartInstance = this.highChart;
    this.treatmentPlotFdiService.updateFDIDataPoint(highChartInstance, fdiId, fdiKeyValue, xValue, yValue);
  }

  updateLOSPoint(timeOffset, keyValue: string) {
    // if (!this.highChart) return;
    // // const highChartInstance: any = this.highChart;
    // // const series = highChartInstance.series.find(x => x.userOptions.id.includes(`los_${keyValue}`));
    // const series = this.highChart.get(`los_${keyValue}`) as Highcharts.Series;
    // if (series && series.data) {
    //   console.log([timeOffset, series.data]);
    //   const updateData: any[] = [];
    //   //   seriesObj.setData([
    //   //     { x: offsetTimeSec, y: 0, groupId: `${point.id}`, name: `${point.name}` },
    //   //     { x: offsetTimeSec, y: 100, groupId: `${point.id}`, name: `${point.name}` }
    //   //   ]);
    //   series.data.forEach(c => {
    //     updateData.push({ x: timeOffset, y: c.y, groupId: `los_${keyValue}`, name: c.name });
    //   });
    //   series.setData(updateData);
    //   // series.graph.show();
    //   setTimeout(() => { this.highChart.redraw(); }, 500);
    // }
  }

  setTreatmentDragMax(baseData, lastData, timeSetting: ITimeSetting) {
    const highChartInstance = this.highChart;
    const userOptionNames = ['treatmentStart', 'treatmentEnd'];
    this.treatmentPlotFdiService.setTreatmentDragMax(highChartInstance, baseData, lastData, timeSetting, userOptionNames);
  }

  setFDIDragMax(baseData, lastData, timeSetting: ITimeSetting) {
    const highChartInstance = this.highChart;
    const userOptionNames = FDI_PARAM;
    this.treatmentPlotFdiService.setTreatmentDragMax(highChartInstance, baseData, lastData, timeSetting, userOptionNames);
  }

  updateDiagnosticPoint(point: IDiagnosticPoint, keyOpt?: string) {
    if (!keyOpt) keyOpt = 'diag';
    if (point && this.highChart) {
      const seriesObj = this.highChart.get(`${keyOpt}_${point.id}`) as Highcharts.Series;
      if (seriesObj) {
        const seriesDataPoint = seriesObj.data[0] as unknown as CustomDataPoint;
        // generate xAxis value
        const timeFormat = this.filterSettings.type;
        let offsetTimeSec: number;
        let timefloat = { start: null, end: null };
        let timestamp = { start: null, end: null };
        // set input chart data
        let inputData: IInputDataMultiFlow;
        // if (this.isFilterData && !this.filterSettings.autoScale) {
        //   inputData = this.handleFilterInputData(this.inputChartData, this.filterSettings, this.channelSettings);
        // } else {
        //   inputData = this.handleFilterInputData(this.inputChartData, this.filterSettings, this.channelSettings);
        // }
        inputData = this.handleFilterInputData(this.inputChartData, this.filterSettings, this.channelSettings);
        // set time range
        if (inputData.realtimeData && inputData.realtimeData.length) {
          const timeRange = this.baseChartService.getTimeRangeFilter(inputData.realtimeData[0]);
          timefloat = timeRange.timefloat;
          timestamp = timeRange.timestamp;
        } else if (inputData.fracproData && inputData.fracproData.length) {
          const timeRange = this.baseChartService.getTimeRangeFilter(inputData.fracproData[0]);
          timefloat = timeRange.timefloat;
          timestamp = timeRange.timestamp;
        }

        if (isNumber(point.timeOffSet)) {
          if (timeFormat !== TIME_SETTING_TYPE.EpochTime) {
            offsetTimeSec = point.timeOffSet; // in sec
            if (timefloat.start && point.timeOffSet < timefloat.start) offsetTimeSec = timefloat.start;
            if (timefloat.end && point.timeOffSet > timefloat.end) offsetTimeSec = timefloat.end;
          } else {
            if (this.baseTreatmentDataTime) {
              // convert offset value to epoch value
              offsetTimeSec = point.timeOffSet;
              if (timefloat.start && point.timeOffSet < timefloat.start) offsetTimeSec = timefloat.start;
              if (timefloat.end && point.timeOffSet > timefloat.end) offsetTimeSec = timefloat.end;
              offsetTimeSec = new Date(this.baseTreatmentDataTime).getTime() + offsetTimeSec * 1000;
              // offsetTimeSec = offsetTimeSec + this.timezoneOffset * 60 * 1000;
            }
          }
          // update diagnostic point
          seriesObj.setData([
            { x: offsetTimeSec, y: 0, groupId: `${point.id}`, name: `${point.name}` },
            { x: offsetTimeSec, y: 100, groupId: `${point.id}`, name: `${point.name}` }
          ]);
        }
        // end
      }
    }
  }

}

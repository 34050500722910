import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IInputDataChart } from '../../models';

export interface IInputDataTreatment {
  treatmentId: number;
  treatmentNumber: number;
  realtimeData: IInputDataChart[];
  fracproData: IInputDataChart[];
  channelSettings?: any
};

@Injectable()
export class SingleWellMultiTreatmentChartService {
  private zoomBufferDataSubject: BehaviorSubject<IInputDataTreatment[]> = new BehaviorSubject(null);

  constructor() { }

  setZoomBufferData(data: IInputDataTreatment[]) {
    this.zoomBufferDataSubject.next(data);
  }

  getZoomBufferData(): Observable<IInputDataTreatment[]> {
    return this.zoomBufferDataSubject.asObservable();
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompareWellsService } from '../../services/compare-wells.service';
import { isEmpty, minBy, isEqual, forEach } from 'lodash';
import { forkJoin, Subscription, Subject, of, Observable } from 'rxjs';
import { IPivotPointItem } from '../../services/pivot-point.service';
import { DiagnosticPointsService } from '../../services';
import { Helper } from '../../helpers/helper';
import { DIAGNOSTIC_DEFAULTS } from '../../helpers/app.constants';
import { takeUntil, map } from 'rxjs/operators';

export interface IResSelectPivotPoint { selected: IPivotPointItem; data: IPivotPointItem[] }

@Component({
  selector: 'app-pivot-point-select',
  templateUrl: './pivot-point-select.component.html',
  styleUrls: ['./pivot-point-select.component.scss']
})
export class PivotPointSelectComponent implements OnInit {
  destroy$: Subject<any> = new Subject();
  subscriptions: Subscription[] = [];
  optionsPivotOpened: boolean = false;
  pivotPointSetting: any = { id: 'JobTime', text: 'Job time' };
  pivotPointData: IPivotPointItem[] = [];
  initPivotList = [
    { id: 'JobTime', text: 'Job time' },
    { id: 'TimeStart', text: 'Start Stage1' }
  ];
  pivotList: any[] = [];

  private prevCompany: number | string;
  private _companyId: number | string;
  @Input() set companyId(id: number | string) {
    if (this.prevCompany === undefined && this._companyId === undefined) this.prevCompany = id;
    else this.prevCompany = this._companyId;
    this._companyId = id;
  }
  get companyId(): number | string {
    return this._companyId;
  }

  selectedTreatments: any[] = [];
  @Input()
  set treatments(treatments: any[]) {
    if (treatments && treatments.length) {
      this.selectedTreatments = treatments.filter(item => !!item.id);
      const treatmentIds = this.selectedTreatments.map(item => item.id);
      if (this.pivotPointSetting && treatmentIds.length) this.getPivotPoints(this.pivotPointSetting, treatmentIds);
    }
  }
  _wellId: number;
  @Input()
  set wellId(id: number) {
    if (this._wellId !== id || this.companyId !== this.prevCompany) {
      this._wellId = id;
      this.updatePivotList(this._wellId, this.companyId);
    }
  }
  @Input()
  set singleWell(well) {
    if (well && well.wellId) {
      if (this._wellId !== well.wellId || this.companyId !== this.prevCompany) {
        this._wellId = well.wellId;
        this.updatePivotList(this._wellId, this.companyId);
      }
    }
  }

  @Input() disabled: boolean = false;

  @Output()
  onSelectItem: EventEmitter<IResSelectPivotPoint> = new EventEmitter();
  @Output()
  onPivotDataChange: EventEmitter<IResSelectPivotPoint> = new EventEmitter();

  constructor(
    private compareWellsService: CompareWellsService,
    private diagnosticPointsService: DiagnosticPointsService,
  ) { }

  ngOnInit(): void {
    this.pivotList = this.initPivotList.concat(DIAGNOSTIC_DEFAULTS);
    this.updatePivotList(this._wellId, this.companyId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    Helper.unsubscribe(this.subscriptions);
  }

  private updatePivotList(wellId, companyId): void {
    if (wellId && companyId !== null) {
      this.subscriptions.push(
        this.diagnosticPointsService.getByCompanyAndWell(companyId, wellId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            res => {
              if (!isEmpty(res)) {
                const diagPoints = res.map(item => ({ id: item.name, text: item.name }));
                this.pivotList = [...this.initPivotList, ...DIAGNOSTIC_DEFAULTS, ...diagPoints];
              } else {
                this.pivotList = this.initPivotList.concat(DIAGNOSTIC_DEFAULTS);
              }
            }
          )
      );
    }
  }

  public isActivePivot(value): boolean {
    return this.pivotPointSetting.id === value.id;
  }

  public openButtonPivotDropdown(): void {
    this.optionsPivotOpened = !this.optionsPivotOpened;
  }

  public clickOutsidePivot(): void {
    this.optionsPivotOpened = false;
  }

  public selectPivotPoint(pivotPointOpt: any): void {
    this.clickOutsidePivot();
    if (!pivotPointOpt || !pivotPointOpt.id) return;
    if (this.pivotPointSetting && this.pivotPointSetting.id === pivotPointOpt.id) return;
    if (!this.selectedTreatments || !this.selectedTreatments.length) return;
    const treatmentIds = this.selectedTreatments.filter(item => !!item.id).map(item => item.id);
    if (!treatmentIds.length) return;

    this.pivotPointSetting = pivotPointOpt;
    this.getPivotPoints(pivotPointOpt, treatmentIds);
  }

  private getPivotPoints(pivotPointOpt: any, treatmentIds: number[]): void {
    this.subscriptions.push(
      this.handlePivotData(pivotPointOpt, treatmentIds)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: IPivotPointItem[]) => {
        this.pivotPointData = response ? response.map(data => {
            data.color = pivotPointOpt.color;
            data.paramNameDisplay = pivotPointOpt.text;
            return data;
          }) : response;
        this.onSelectItem.emit({
          selected: pivotPointOpt,
          data: this.pivotPointData
        });
      })
    );
  }

  private handlePivotData(pivotPointOpt: any, treatmentIds: number[]): Observable<IPivotPointItem[]> {
    if (!pivotPointOpt || !treatmentIds || !treatmentIds.length) return of([]);
    if (pivotPointOpt.id === 'JobTime') return of([]);

    const requestBody: { treatmentIds: number[], paramNames: string[] } = {
      treatmentIds: treatmentIds ? treatmentIds : [],
      paramNames: [pivotPointOpt.id]
    };
    // case Start Stage1
    if (pivotPointOpt.id === 'TimeStart') requestBody.paramNames = requestBody.paramNames.concat(['BaseTreatmentDateTime']);

    return this.compareWellsService.getDataForPivotPoints(requestBody)
      .pipe(map(res => {
        if (!res || !res.result.length) return [];        
        if (pivotPointOpt.id !== 'TimeStart') return res.result;

        const listTimeStartData: any[] = res.result.filter(data => data.paramName !== 'BaseTreatmentDateTime');
        const listBaseTreatmentDateTimeData: any[] = res.result.filter(data => data.paramName === 'BaseTreatmentDateTime');

        const baseTimeStart = minBy(listTimeStartData, (item: IPivotPointItem) => item.paramValue);
        const baseTreatmentDateTime = minBy(listTimeStartData, (item: IPivotPointItem) => item.paramValue);
        // conver time start to offset value
        return listTimeStartData.map(timeStartData => {
          const curBaseTreatmentDateTime = listBaseTreatmentDateTimeData.find(item => item.treatmentId === timeStartData.treatmentId);
          if (curBaseTreatmentDateTime) {
            timeStartData.paramValue = (timeStartData.paramValue - curBaseTreatmentDateTime.paramValue) - (baseTimeStart.paramValue - baseTreatmentDateTime.paramValue);
          }
          return timeStartData;
        });
      }));
  }

}

import { Injectable } from '@angular/core';
import { clone, cloneDeep, findKey, isEmpty, map as _map } from 'lodash';
import { IInputFDIParam, TreatmentPlotFDI, FDI_STATIC_MARKERS, FDI_DYNAMIC_MARKERS } from './treatment-plot-fdi.model';
import { CHART_SERIES_NAME, FDI_MARKER_RADIUS, FDI_PARAM_TENANT, FDI_PARAM_TENANTS, HexColor, TIME_SETTING_TYPE } from '../../helpers/app.constants';
import { TreatmentPlotTimeConverterService } from './treatment-plot-time-converter.service';
import { ChannelSelectionService } from '../../services';
import { ITimeSetting } from '../../models';
import { Helper, Vector } from '../../helpers/helper';

export const ID_YAXIS_FDI = 'yaxis-fdi';
export const PREFIX_FDI_SERIES = 'fdi';
const DEFAULT_LINE_WIDTH = 3;
const DEFAULT_TANGENT_LINE_STYLE = 'Dot';

@Injectable()
export class TreatmentPlotFdiService {
  private static FDI_MARKER_COLORS = [HexColor.YELLOW, HexColor.ORANGE, HexColor.GREEN];
  private static FDI_TREATMENT_COLOR = HexColor.RED;

  constructor(
    private channelSelectionService: ChannelSelectionService,
    private treatmentPlotTimeConverterService: TreatmentPlotTimeConverterService,
  ) { }

  public getFDIMarkerColor = (countAddFDI: number, offset: number) => {
    const colorIndex = Math.abs(countAddFDI - offset) % TreatmentPlotFdiService.FDI_MARKER_COLORS.length;
    return TreatmentPlotFdiService.FDI_MARKER_COLORS[colorIndex];
  }

  addFDIHandler({
    highChartInstance,
    baseTreatmentDataTime,
    timeFormat,
    fdiInfo,
    yAxisIndex,
    isStaticFDI,
    treatmentInfo,
    startIndex,
    endIndex,
    treatmentStart,
    treatmentEnd,
    onDropHandler,
    onTreatmentStartEndDropHandler,
    onMouseOverHandler,
    onDragHandler
  }: IInputFDIParam) {
    let series = [];
    // add FDI: fdiStart, fdiEnd, leakOffStart, leakOffEnd, etc...
    const fdiSeries = this.parseFDIChartSeries({
      baseTreatmentDataTime, timeFormat, fdiInfo, yAxisIndex,
      onDropHandler, onMouseOverHandler, onDragHandler
    });
    series = series.concat(fdiSeries);
    // add tangent FDI dot lines
    const tangentSeries = this.parseFDITangentToChartSeries(series, FDI_PARAM_TENANT);
    series = series.concat(tangentSeries);
    // add treatmentStart and treatmentEnd
    if (isStaticFDI) {
      const staticSeries = this.parseTreatmentChartSeries({
        baseTreatmentDataTime, timeFormat, treatmentInfo,
        treatmentStart, treatmentEnd, startIndex, endIndex,
        yAxisIndex, onDropHandler: onTreatmentStartEndDropHandler, onDragHandler
      });
      series = series.concat(staticSeries);
    }
    // render highChart
    if (highChartInstance) {
      series.forEach(opt => {
        highChartInstance.addSeries(opt, false);
      });
      highChartInstance.redraw();
    }
  }

  addStaticFDIHandler({
    highChartInstance,
    baseTreatmentDataTime, timeFormat, treatmentInfo, startIndex, endIndex, treatmentStart, treatmentEnd,
    yAxisIndex, onDropHandler, onDragHandler
  }: IInputFDIParam) {
    let series = [];
    const staticSeries = this.parseTreatmentChartSeries({
      baseTreatmentDataTime, timeFormat, treatmentInfo,
      treatmentStart, treatmentEnd, startIndex, endIndex,
      yAxisIndex, onDropHandler, onDragHandler
    });
    series = series.concat(staticSeries);
    if (highChartInstance) {
      series.forEach(opt => {
        highChartInstance.addSeries(opt, false);
      });
      highChartInstance.redraw();
    }
  }

  removeFDIHandler({ highChartInstance }) {
    if (highChartInstance) {
      const fdiSeries = highChartInstance.series.filter(obj => obj.userOptions.id.indexOf(PREFIX_FDI_SERIES) === 0);
      fdiSeries.forEach(opt => {
        const seriesObj = highChartInstance.get(opt.userOptions.id);
        if (seriesObj) {
          seriesObj.remove(false);
        }
      });
      // highChartInstance.redraw();
    }
  }

  toggleShowFDIHandler({ highChartInstance, isShow }) {
    if (highChartInstance) {
      const fdiSeries = highChartInstance.series.filter(obj => obj.userOptions.id.indexOf(PREFIX_FDI_SERIES) === 0);
      fdiSeries.forEach(opt => {
        const seriesObj = highChartInstance.get(opt.userOptions.id);
        if (seriesObj) {
          seriesObj.setVisible(isShow, false);
        }
      });
      // highChartInstance.redraw();
    }
  }

  updateFDIHandler(highChartInstance, fdi: TreatmentPlotFDI, fdiStart: number, fdiEnd: number) {
    const setDataTimestamp = (highChart, fdiName, index) => {
      const filter = (data, id, name) => data.includes(id) && data.includes(name);
      const series = highChart.series.find(x => filter(x.userOptions.id, fdi.id, fdiName));
      if (!series) return;

      const fdiFData = series.data[0];
      const fdiData = this.parseFDISeriesData(index, null, fdiFData.groupId, fdiFData.name, fdiFData.fdiId, fdiFData.fdiKeyValue);
      series.setData(fdiData);
    };

    if (fdiStart) setDataTimestamp(highChartInstance, 'fdiStart', fdiStart);
    if (fdiEnd) setDataTimestamp(highChartInstance, 'fdiEnd', fdiEnd);
    // highChartInstance.redraw();
  }

  updateFDIDataPoint(highChartInstance, id: string, keyValue: string, xValue: number, yValue: number) {
    const series = highChartInstance.series.find(x => x.userOptions.id.includes(id));
    if (!series) return;
    const fdiFData = series.data[0];
    const fdiFName = this.parseDynamicMarkerName(fdiFData.fdiKeyValue);
    const fdiData = this.parseFDISeriesData(xValue, yValue, fdiFData.groupId, fdiFName, fdiFData.fdiId, fdiFData.fdiKeyValue);
    series.setData(fdiData);
    if (series.graph) series.graph.show();
    series.show();
    // highChartInstance.redraw();

    if (FDI_PARAM_TENANTS.includes(keyValue)) {
      const tangentId = id.replace('Start', 'Tangent').replace('End', 'Tangent');
      const tangentSeries: IFDIChartSeries = highChartInstance.series.find(x => x.userOptions.id.includes(tangentId));
      if (!tangentSeries) return;
      const fdiStart: IFDIChartSeriesData = { x: tangentSeries.data[1].x, y: tangentSeries.data[1].y };
      const fdiEnd: IFDIChartSeriesData = { x: tangentSeries.data[2].x, y: tangentSeries.data[2].y };
      const offsetTime = xValue > 1_000_000_000_000 ? 2 * 60 * 1000 : 2 * 60; // 2 min

      if (keyValue.includes('Start')) {
        fdiStart.x = xValue;
        fdiStart.y = yValue;
      } else if (keyValue.includes('End')) {
        fdiEnd.x = xValue;
        fdiEnd.y = yValue;
      }

      const fdiStartVector: Vector = new Vector(fdiStart.x, fdiStart.y);
      const fdiEndVector: Vector = new Vector(fdiEnd.x, fdiEnd.y);
      const offsetFdiStartVector = Helper.calculateVector(fdiStartVector, fdiEndVector, fdiStart.x - offsetTime);
      const offsetFdiEndVector = Helper.calculateVector(fdiStartVector, fdiEndVector, fdiEnd.x + offsetTime);

      tangentSeries.setData([offsetFdiStartVector, fdiStart, fdiEnd, offsetFdiEndVector]);
      // highChartInstance.redraw();
    }
  }

  private parseDynamicMarkerName = name => {
    for (const i in FDI_DYNAMIC_MARKERS) {
      if (name !== i) continue;
      return FDI_DYNAMIC_MARKERS[i];
    }
  }

  private parseTreatmentSeriesData = (xValue: number, groupId: string, fdiName: string, fdiId: string, fdiKeyValue: string) => {
    return [
      { x: xValue, y: 0, groupId, name: fdiName, fdiId, fdiKeyValue },
      { x: xValue, y: 100, groupId, name: fdiName, fdiId, fdiKeyValue }
    ];
  }

  private parseFDISeriesData = (xValue: number, yValue: number | null, groupId: string, fdiName: string, fdiId: string, fdiKeyValue: string) => {
    if (yValue === null) {
      return [
        { x: xValue, y: -100, groupId, name: fdiName, fdiId, fdiKeyValue },
        { x: xValue, y: 200, groupId, name: fdiName, fdiId, fdiKeyValue }
      ];
    }

    return [
      { x: xValue, y: -100, groupId, name: fdiName, fdiId, fdiKeyValue },
      { x: xValue, y: yValue, groupId, name: fdiName, fdiId, fdiKeyValue },
      { x: xValue, y: 200, groupId, name: fdiName, fdiId, fdiKeyValue }
    ];
  }

  private parseSeriesDragDrop = (timeFormat, startIndex, endIndex) => {
    const dragDrop: any = {
      draggableX: true,
      groupBy: 'groupId',
      dragSensitivity: 0,
      dragPrecisionX: timeFormat === TIME_SETTING_TYPE.EpochTime ? 1000 : 1
    };

    if (startIndex && endIndex) {
      dragDrop.dragMinX = startIndex;
      dragDrop.dragMaxX = endIndex;
    }

    return dragDrop;
  }

  parseTreatmentInfoToChartSeries({
    yAxisIndex, seriesGroupId, fdiId, fdiName, fdiValue, fdiKeyValue, color,
    timeFormat, startIndex, endIndex,
    onDropHandler, onDragHandler
  }, onMouseOverHandler?) {
    const dragDrop = this.parseSeriesDragDrop(timeFormat, startIndex, endIndex);
    const data = this.parseTreatmentSeriesData(fdiValue, seriesGroupId, fdiName, fdiId, fdiKeyValue);

    return {
      id: `${PREFIX_FDI_SERIES}_${seriesGroupId}`,
      name: CHART_SERIES_NAME.fdi,
      type: 'line',
      tooltip: false,
      data,
      showInLegend: false,
      yAxis: yAxisIndex,
      color,
      lineWidth: DEFAULT_LINE_WIDTH,
      dragDrop,
      stickyTracking: false,
      point: {
        events: {
          drag(e) {
            if (typeof onDragHandler === 'function') {
              onDragHandler(e);
            }
          },
          drop(e) {
            if (typeof onDropHandler === 'function') {
              onDropHandler(e);
            }
            this.series.update({
              cursor: 'grab'
            }, true);
          },
          dragStart(e) {
            this.series.update({
              cursor: 'grabbing'
            }, true);
          },
          click(e) {
            this.series.update({
              cursor: 'grab'
            }, true);
          },
          mouseOver(e) {
            if (typeof onMouseOverHandler === 'function') {
              onMouseOverHandler(e);
            }
          },
        }
      },
      cursor: 'grab'
    };
  }

  parseFDIInfoToChartSeries({
    yAxisIndex, seriesGroupId, fdiId, fdiName, fdiValue, fdiKeyValue, color, invisibleLine,
    timeFormat, startIndex, endIndex,
    onDropHandler, onDragHandler
  }, onMouseOverHandler?) {
    const lineWidth = invisibleLine ? 0 : DEFAULT_LINE_WIDTH;
    const seriesData = this.parseFDISeriesData(fdiValue, null, seriesGroupId, fdiName, fdiId, fdiKeyValue);
    const dragDrop = this.parseSeriesDragDrop(timeFormat, startIndex, endIndex);

    return {
      id: `${PREFIX_FDI_SERIES}_${seriesGroupId}`,
      name: CHART_SERIES_NAME.fdi,
      type: 'line',
      tooltip: false,
      data: seriesData,
      showInLegend: false,
      yAxis: yAxisIndex,
      color,
      lineWidth,
      dragDrop,
      stickyTracking: false,
      point: {
        events: {
          drag(e) {
            if (typeof onDragHandler === 'function') {
              onDragHandler(e);
            }
            this.series.update({
              marker: {
                enabled: false
              },
              lineWidth: DEFAULT_LINE_WIDTH,
            });
          },
          drop(e) {
            if (typeof onDropHandler === 'function') {
              onDropHandler(e);
            }
            this.series.update({
              cursor: 'grab',
              marker: {
                enabled: true
              },
              lineWidth,
            });
          },
          dragStart(e) {
            this.series.update({
              cursor: 'grabbing'
            }, true);
          },
          click(e) {
            this.series.update({
              cursor: 'grab'
            }, true);
          },
          mouseOver(e) {
            if (typeof onMouseOverHandler === 'function') {
              onMouseOverHandler(e);
            }
          },
        }
      },
      marker: {
        radius: FDI_MARKER_RADIUS,
        enabled: true,
        symbol: 'circle'
      },
      cursor: 'grab'
    };
  }

  parseFDITangentToChartSeries(fdiSeries: any[], fdiNames: string[]): IFDIChartSeries[] {
    const res: IFDIChartSeries[] = [];
    const replaceText = (text: string, srcText: string = 'Start', desText: string = 'Tangent'): string => text.replace(srcText, desText);

    for (const fdiName of fdiNames) {
      const fdiStart: IFDIChartSeries = fdiSeries.find(x => x.id.includes(fdiName + 'Start'));
      const fdiEnd: IFDIChartSeries = fdiSeries.find(x => x.id.includes(fdiName + 'End'));
      if (!fdiStart || !fdiEnd) continue;

      // init dataStart
      const dataStart: IFDIChartSeriesData = {
        fdiId: fdiStart.data[0].fdiId,
        fdiKeyValue: replaceText(fdiStart.data[0].fdiKeyValue),
        groupId: replaceText(fdiStart.data[0].groupId),
        x: fdiStart.data[0].x,
        y: fdiStart.data[0].y
      };
      // init dataEnd
      const dataEnd = cloneDeep(dataStart);
      dataEnd.x = fdiEnd.data[0].x;
      dataEnd.y = fdiEnd.data[0].y;
      // init dataSeries
      const dataSeries: IFDIChartSeries = {
        color: fdiStart.color,
        data: [
          cloneDeep(dataStart),
          dataStart,
          dataEnd,
          cloneDeep(dataEnd)
        ],
        id: replaceText(fdiStart.id),
        lineWidth: 3,
        marker: { enabled: false },
        name: PREFIX_FDI_SERIES,
        type: 'line',
        tooltip: false,
        showInLegend: false,
        stickyTracking: false,
        enableMouseTracking: false,
        dashStyle: DEFAULT_TANGENT_LINE_STYLE,
        yAxis: fdiStart.yAxis
      };

      res.push(dataSeries);
    }
    return res;
  }

  parseFDIValueToTreatment(timeFormat, baseTreatmentDataTime, fdiValueInSec): number {
    if (timeFormat === TIME_SETTING_TYPE.OffsetTime) {
      return this.treatmentPlotTimeConverterService.convertOffsetToEpochSec({
        xValue: fdiValueInSec, // in sec
        baseTreatmentDataTime
      });
    } else {
      // have to remove the timezone before update it to the database
      return this.treatmentPlotTimeConverterService.toEpochSecWithTimezone({ xValue: fdiValueInSec });
    }
  }

  parseFDIValueToChartSeries(timeFormat, baseTreatmentDataTime, fdiValueInMilliSec): number {
    if (timeFormat === TIME_SETTING_TYPE.OffsetTime) {
      return this.treatmentPlotTimeConverterService.convertEpochToOffset({
        xValue: fdiValueInMilliSec, baseTreatmentDataTime
      }); // in seconds
    } else {
      return this.treatmentPlotTimeConverterService.toEpochMillisecWithTimezone({ xValue: fdiValueInMilliSec }); // in milliseconds
    }
  }

  parseFDIChartSeries({
    baseTreatmentDataTime, timeFormat, fdiInfo,
    yAxisIndex, onDropHandler, onMouseOverHandler, onDragHandler
  }) {
    const seriesData = [];
    const listFDIs = Object.keys(FDI_DYNAMIC_MARKERS);

    const parseColor = (fdiKeyValue: string) => {
      if (fdiKeyValue.includes('leak')) return HexColor.ORANGE;
      if (fdiKeyValue.includes('peak')) return HexColor.RED;
      return HexColor.YELLOW;
    };

    if (listFDIs && !isEmpty(listFDIs)) {
      listFDIs.forEach((fdiKeyValue) => {
        let fdiValue = fdiInfo[fdiKeyValue] * 1000; // sec to millisec
        let fdiName = '';
        // for some reason countAddFDI starting at 2
        const color = parseColor(fdiKeyValue);
        const seriesGroupId = `${fdiInfo.id}_${fdiKeyValue}_${new Date().getTime()}`;
        const fdiId = `${fdiInfo.id}`;
        fdiName = FDI_DYNAMIC_MARKERS[fdiKeyValue];
        fdiValue = this.parseFDIValueToChartSeries(timeFormat, baseTreatmentDataTime, fdiValue);

        const seriesItem = this.parseFDIInfoToChartSeries({
          yAxisIndex, color, seriesGroupId, fdiId, fdiName, invisibleLine: true,
          fdiValue, fdiKeyValue, timeFormat, startIndex: null, endIndex: null, onDropHandler, onDragHandler
        }, onMouseOverHandler);
        seriesData.push(seriesItem);
      });
    }
    return seriesData;
  }

  parseTreatmentChartSeries({
    baseTreatmentDataTime, timeFormat, treatmentInfo, treatmentStart, treatmentEnd, startIndex, endIndex,
    yAxisIndex, onDropHandler, onDragHandler
  }) {
    const seriesData = [];
    if (treatmentInfo) {
      const listFDIs = Object.keys(FDI_STATIC_MARKERS);
      const color = TreatmentPlotFdiService.FDI_TREATMENT_COLOR;
      const objValues = {
        treatmentStart: treatmentStart ? treatmentStart : treatmentInfo.timeStart,
        treatmentEnd: treatmentEnd ? treatmentEnd : (treatmentInfo.timeEnd || treatmentInfo.timeStart + (120 * 60)),
      };

      // limit the treatmentStart and treatmentEnd in treatment range
      // other FDI marker can be dragged outside treatment range as long as in the chart
      if (timeFormat === TIME_SETTING_TYPE.EpochTime) {
        const treatmentStartOffset = treatmentStart * 1000;
        const treatmentEndOffset = treatmentEnd * 1000;
        if (startIndex < treatmentStartOffset) startIndex = treatmentStartOffset;
        if (endIndex > treatmentEndOffset) endIndex = treatmentEndOffset;
      } else {
        const durationSecond = (baseTime: string, timestamp: number): number => Math.floor((timestamp - new Date(baseTime).getTime()) / 1000);
        const treatmentStartOffset = durationSecond(baseTreatmentDataTime, treatmentStart * 1000);
        const treatmentEndOffset = durationSecond(baseTreatmentDataTime, treatmentEnd * 1000);
        if (startIndex < treatmentStartOffset) startIndex = treatmentStartOffset;
        if (endIndex > treatmentEndOffset) endIndex = treatmentEndOffset;
      }

      if (listFDIs && !isEmpty(listFDIs)) {
        listFDIs.forEach((fdiKeyValue, index) => {
          let fdiValue = objValues[fdiKeyValue] * 1000; // sec to millisec
          let fdiName = '';
          const seriesGroupId = `${index}_${fdiKeyValue}_${new Date().getTime()}`;
          const fdiId = `${index}`;
          fdiName = FDI_STATIC_MARKERS[fdiKeyValue];
          fdiValue = this.parseFDIValueToChartSeries(timeFormat, baseTreatmentDataTime, fdiValue);

          const seriesItem = this.parseTreatmentInfoToChartSeries({ yAxisIndex, color, seriesGroupId, fdiId, fdiName, fdiValue, fdiKeyValue, timeFormat, startIndex, endIndex, onDropHandler, onDragHandler });
          seriesData.push(seriesItem);
        });
      }
    }
    return seriesData;
  }

  setTreatmentDragMax(highChart, baseData, lastData, timeSetting: ITimeSetting, userOptionNames: string[]) {
    let dragMaxX: number;
    if (timeSetting.type === TIME_SETTING_TYPE.EpochTime) {
      dragMaxX = new Date(lastData[0]).getTime();
    } else {
      const offset = new Date(lastData[0]).getTime() - new Date(baseData[0]).getTime();
      dragMaxX = baseData[1] + Math.round(offset / 1000);
    }

    for (const series of highChart.series) {
      for (const names of userOptionNames) {
        if (!series.userOptions.id.includes('_' + names + '_')) continue;
        series.userOptions.dragDrop.dragMaxX = dragMaxX;
      }
    }
  }

  checkIsExistStaticFDIs(curListFDIs: TreatmentPlotFDI[]) {
    if (!isEmpty(curListFDIs)) {
      const keys = Object.keys(FDI_STATIC_MARKERS);
    }
  }

  filterListFDIsByOffsetChannels(localOffsetChannelSettings, curListFDIs) {
    let result = [];
    if (localOffsetChannelSettings && !isEmpty(curListFDIs)) {
      // get offset channels only
      const offsetChannels = this.channelSelectionService.getListChannels(localOffsetChannelSettings, true);
      const availableTreatmentIds = _map(offsetChannels, 'treatmentId');
      // filter list fdi by current offset channels
      result = curListFDIs.filter(item => availableTreatmentIds.includes(item.treatmentId));
    }
    return result;
  }
}


export interface IFDIChartSeriesData {
  x: number;
  y: number;
  fdiId?: string;
  fdiKeyValue?: string;
  groupId?: string;
  name?: string;
  [x: string]: any;
}

export interface IFDIChartSeries {
  id: string;
  data: IFDIChartSeriesData[];
  name?: string;
  type?: string;
  tooltip?: boolean;
  yAxis?: number;
  showInLegend?: boolean;
  stickyTracking?: boolean;
  enableMouseTracking?: boolean;
  dashStyle?: string;
  [x: string]: any;
}

export interface IFDIInfoToChartSeries {
  fdiId: string;
  fdiName: string;
  fdiValue: number;
  fdiKeyValue: string;
  yAxisIndex: number;
  color;
  timeFormat;
  startIndex;
  endIndex;
  invisibleLine;
  onDropHandler;
  onDragHandler;
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isEmpty } from 'lodash';
import { ITreatmentLogTemplateResponse, ITreatmentLogTemplate } from '../../models/treatment-log-template.model';
import { NbDialogService } from '@nebular/theme';
import { TreatmentLogTemplateService } from '../../services/treatment-log-template.service';
import { IScatterPlotChartSetting, IAxisSetting } from '../../services/charts';
import { Subscription } from 'rxjs';
import { ConfirmTemplateModalComponent } from '../modals/confirm-template-modal/confirm-template-modal.component';

@Component({
  selector: 'app-treatment-log-template-dropdown',
  templateUrl: './treatment-log-template-dropdown.component.html',
  styleUrls: ['./treatment-log-template-dropdown.component.scss']
})
export class TreatmentLogTemplateDropdownComponent implements OnInit {
  listTemplates: ITreatmentLogTemplateResponse[];
  isOpen: boolean;
  @Input() selectedItem: any;
  plotTemplateToSave: ITreatmentLogTemplate = {
    xAxis: null,
    yAxis: [],
    designActualYAxis: [],
    curveYAxis: [],
    scale: []
  };
  subscriptions: Subscription[] = [];

  @Input() loading: boolean;

  @Input()
  set dataTemplates(templates: ITreatmentLogTemplateResponse[]) {
    if (!isEmpty(templates)) {
      this.listTemplates = templates;
    } else {
      this.listTemplates = [];
    }
  }
  @Input() companyId: number;
  @Input() wellId: number;
  @Input() hideSaveButton: boolean = false;
  @Input()
  set settings(settings: IScatterPlotChartSetting) {
    if (settings) {
      this.plotTemplateToSave = {
        xAxis: { ...settings.xAxis },
        yAxis: [...settings.yAxis],
        designActualYAxis: [...settings.designActualYAxis]
      };

      if (settings.curveYAxis) {
        this.plotTemplateToSave.curveYAxis = [...settings.curveYAxis];
      }

      if (settings.scale) {
        this.plotTemplateToSave.scale = [...settings.scale];
      }

      if (settings.fdiYAxis) {
        this.plotTemplateToSave.fdiYAxis = [...settings.fdiYAxis];
      }

    }
  }

  @Output() itemSelected: EventEmitter<ITreatmentLogTemplateResponse> = new EventEmitter();
  @Output() templatesChange: EventEmitter<ITreatmentLogTemplate[]> = new EventEmitter();

  constructor(
    private dialogService: NbDialogService,
    private treatmentLogTemplateService: TreatmentLogTemplateService,
  ) { }

  ngOnInit() {
  }

  openDropdown() {
    this.isOpen = !this.isOpen;
  }

  isSelected(item): boolean {
    if (this.selectedItem && this.selectedItem.id) {
      return item.id === this.selectedItem.id;
    }
    return false;
  }

  selectItem(event: Event, templateRes) {
    event.stopPropagation();
    if (!this.selectedItem || templateRes.id !== this.selectedItem.id) {
      const data = { ...templateRes };
      this.selectedItem = data;

      this.itemSelected.emit(data);
      this.isOpen = false;
    }
  }

  deleteItem(event: Event, templateRes) {
    event.stopPropagation();
    if (templateRes && templateRes.id) {
      const subs = this.treatmentLogTemplateService.deleteTemplate(templateRes.id).subscribe(item => {
        this.templatesChange.emit();
      });
      this.subscriptions.push(subs);
    }
  }

  clickOutside() {
    this.isOpen = false;
  }

  saveTemplate() {

    this.dialogService.open(
      ConfirmTemplateModalComponent,
      {
        context: {
          hideMessage: true,
          showAllWellsCheckbox: false,
          templates: this.listTemplates,
          isScatterPlot: true
        }
      }
    )
      .onClose
      .subscribe(res => {
        if (res) {
          const payload: any = {
            name: res.name,
            content: this.plotTemplateToSave
          };
          const templateRes = this.listTemplates.find(template => template.name === res.name);
          if (templateRes && templateRes.id) {
            payload.type = templateRes.type;
            this.updateTemplate(templateRes.id, payload);
          } else {
            this.createTemplate(payload);
          }
        }
      });
  }

  private createTemplate(payload): void {
    this.subscriptions.push(
      this.treatmentLogTemplateService
        .createTemplate(payload)
        .subscribe(
          item => {
            this.templatesChange.emit();
          }
        )
    );
  }

  private updateTemplate(plotTemplateId, payload): void {
    this.subscriptions.push(
      this.treatmentLogTemplateService
        .updateTemplate(plotTemplateId, payload)
        .subscribe(
          item => {
            this.templatesChange.emit();
          }
        )
    );
  }

}

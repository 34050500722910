import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-switch-column',
  template: `
    <div>
      <ui-switch [checked]="value" (change)="onChange($event)" size="small" color="#117FA1"></ui-switch>
    </div>`,
})

export class CustomSwitchColumnComponent implements OnInit {
  renderValue: any;

  @Input() value: any;
  @Input() rowData: any;
  @Output() onEdited: EventEmitter<any> = new EventEmitter();
  @Output() columnClick: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
  }

  onChange(eventData) {
    console.log(eventData)
    this.value = eventData;
    this.onEdited.emit({ rowData: this.rowData, cellValue: this.value });
  }

}

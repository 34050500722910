import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'app-custom-related-column',
  template: `
    <span style="cursor: pointer;"
      [ngStyle]="{
        'color': color ? color : '', 'background': background ? background : ''
      }"
      (click)="onClick()">{{renderValue}}</span>
  `,
})
export class CustomRelatedColumnComponent implements ViewCell, OnInit {
  renderValue: string;
  color: string;
  background: string;

  @Input() value: any;
  @Input() rowData: any;
  @Output() columnClick: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    if (this.value && this.value) {
      if (typeof this.value === 'object') {
        this.renderValue = this.value.text;
        this.color = this.value.color;
        this.background = this.value.background;
      } else {
        this.renderValue = this.value.toString();
      }
    }
  }

  onClick() {
    this.columnClick.emit({ rowData: this.rowData, cellValue: this.value });
  }

}

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'multiple-input',
  template: `<div class="ul-select-multiple">
              <span class="ul-select-match-item" *ngFor="let a of itemList">
                <span class="ul-select-match-text" [innerHtml]="a.text"></span>
                <i class="icon-close" (click)="removeClick(a)"></i>
              </span>
             </div>`,
  styleUrls: ['./multiple-input.component.scss']
})
export class MultipleInputComponent {

  itemList: {id: number, text: string}[] = [];

  @Input()
  set items(list: any) {
    this.itemList = list;
  }

  @Output() remove: EventEmitter<any> = new EventEmitter();

  removeClick(item: any) {
    this.remove.emit(item);
  }

}

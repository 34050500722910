import { Component, OnInit, Input } from '@angular/core';
import { DefaultColor, LIST_SHAPES } from '../../../helpers/app.constants';
import { NbDialogRef } from '@nebular/theme';
import { AbstractControl, FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { IScatterPlotChartSetting, IAxisSetting } from '../../../services/charts';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-treatment-log-chart-settings',
  templateUrl: './treatment-log-chart-settings.component.html',
  styleUrls: ['./treatment-log-chart-settings.component.scss']
})
export class TreatmentLogChartSettingsComponent implements OnInit {

  defaultColor: any[] = DefaultColor;

  listShapes: any[] = LIST_SHAPES.map(item => item.name);

  form: FormGroup;

  xAxisSetting: Partial<IAxisSetting> = {};
  yAxisSetting: Partial<IAxisSetting> = {};
  curveYAxisSetting: Partial<IAxisSetting> = {};

  axisSettings: IAxisSetting[] = [];

  @Input()
  set chartSettings(data: IScatterPlotChartSetting) {
    if (data) {
      console.log(data);
      if (data.xAxis) {
        this.axisSettings.push(Object.assign(data.xAxis, {dataKey: 'xAxis'}));
      }
      if (data.yAxis) {
        data.yAxis.forEach(setting => {
          this.axisSettings.push(Object.assign(setting, {dataKey: 'yAxis'}));
        })
      }
      if (data.curveYAxis) {
        data.curveYAxis.forEach(setting => {
          this.axisSettings.push(Object.assign(setting, {dataKey: 'curveYAxis'}));
        })
      }
    }
  }

  get formControls() { return this.form.controls; }
  get formSettings() { return this.formControls.settings as FormArray; }

  constructor(
    private dialogRef: NbDialogRef<any>,
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.addFormGroups(this.axisSettings);
  }

  createForm() {
    this.form = this.formBuilder.group({
      settings: new FormArray([])
    });
  }

  addFormGroups(settings: IAxisSetting[]) {
    settings.forEach(setting => {
      this.formSettings.push(this.formBuilder.group({
        name: [setting.name, Validators.required],
        shape: [setting.shape, Validators.required],
        color: [setting.color, [Validators.required]],
        min: [setting.min, []],
        max: [setting.max, []],
        dataKey: [setting.dataKey, []],
      }));
    })
  }

  onSelectShape(event, formControl) {
    formControl.setValue(event.text);
  }

  selectColor(event, formControl) {
    formControl.setValue(event);
  }

  close() {
    this.dialogRef.close();
  }

  submitForm() {
    console.log(this.form.value);
    const result: IScatterPlotChartSetting = {
      xAxis: null,
      yAxis: null,
      curveYAxis: null
    };
    if (this.form.value && !isEmpty(this.form.value.settings)) {
      this.form.value.settings.forEach(item => {
        result[item.dataKey] = Object.assign({}, item);
      });
    }
    this.dialogRef.close(result);
  }

}

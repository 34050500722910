import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbAuthModule, NbDummyAuthStrategy } from '@nebular/auth';
import { ApiService, CookieStorageService } from './services';
import { HttpTokenInterceptor } from './interceptors';
import { CookieService } from 'ngx-cookie-service';
import { HttpNotConnectedInterceptor } from './interceptors/http.not-connected.interceptor';
import { HttpErrorHandlerInterceptor } from './interceptors/http.error-handler.interceptor';
import { ToasterService } from '../shared/services';

const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({

    strategies: [
      NbDummyAuthStrategy.setup({
        name: 'email',
        delay: 3000,
      }),
    ]
  }).providers
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [
    NbAuthModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpNotConnectedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandlerInterceptor, multi: true },
    CookieService,
    ApiService,
    CookieStorageService,
    ToasterService
  ]
})
export class CoreModule {
  constructor() {}

  static forRoot(): ModuleWithProviders {
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    return <ModuleWithProviders> {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService, NavbarScaleChartService } from '../../services';
import { NbSidebarService, NbMenuService } from '@nebular/theme';
import { MsalService } from "@azure/msal-angular";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  active: boolean = true;
  userFullName = 'Anonymous';
  userMenu = [
    { title: 'User Profile' },
    { title: 'Request Support' },
    { title: 'Change Password' },
    { title: 'Log out' }
  ];
  isShowUserMenu = false;

  constructor(
    private router: Router,
    private userAuthService: UserAuthService,
    private sideBarService: NbSidebarService,
    private nbMenuService: NbMenuService,
    private navbarScaleChartService: NavbarScaleChartService,
    private authService: MsalService,
  ) { }

  ngOnInit() {
    this.userAuthService.currentUser.subscribe((user) => {
      if (user && user.firstName && user.lastName) {
        this.userFullName = `${user.firstName} ${user.lastName}`;
      }
    });

    this.nbMenuService.onSubmenuToggle().subscribe(item => {
      if (this.active === false) {
        this.active = true;
      }
    });
  }

  navigate(title) {
    if (title === 'User Profile') {
      this.router.navigate(['/pages/user']);
    } else if (title === 'Log out') {
      // this.router.navigate(['/pages/user/logout']);
      this.userAuthService.logout();
    } else if (title === 'Request Support') {
      this.router.navigate(['/pages/user/supportRequest']);
    } else if (title === 'Change Password') {
      this.authService.loginRedirect({
        authority: 'https://devlinqxfracpro.b2clogin.com/devlinqxfracpro.onmicrosoft.com/B2C_1_UAT_PASSWORD_RESET',
        scopes: ['  https://devlinqxfracpro.onmicrosoft.com/UAT_WebApi_Scope/uat_scp_rw',]
      })
      // this.router.navigate(['/pages/user/changePassword']);
    }
    this.isShowUserMenu = false;
  }

  isActive() {
    this.active = !this.active;
    if (this.active) {
      this.sideBarService.expand('menu-sidebar');
    } else {
      this.sideBarService.toggle(true, 'menu-sidebar');
    }
    this.navbarScaleChartService.setLoading(this.active);
  }

}

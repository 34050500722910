import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[noWhiteSpace]'
})

export class NoWhiteSpaceDirective {
  constructor(private control: NgControl) {}

  @HostListener('blur', ['$event.target.value'])
  onblur(updatedValue: string) {
    let value: any = this.control.control.value;
    if (value != null && typeof value === 'string') {
      value = value.trim();
      value = value.replace(/\s+/g, ' ');
      this.control.control.setValue(value);
    }
  }
}
import { Component, OnInit } from '@angular/core';
import { LoadingOverlayService } from '../../services/loading-overlay.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  isLoadingPage$: Observable<boolean> = this.loadingOverlayService.isLoading;

  constructor(
    private loadingOverlayService: LoadingOverlayService
  ) { }

  ngOnInit() {
  }

}

import { SearchWellListBase } from './search-well-list-base';


export class SearchWellListDropdown extends SearchWellListBase<string> {
  controlType = 'dropdown';
  options: {key: string, value: string}[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}

export enum TimerState {
  idle = 0,
  running = 1,
  paused = 2,
  resumed = 3
}

export class IntervalTimer {
  timerId: number;
  timeoutId: number;
  startTime: number;
  remaining: number = 0;
  state: number = TimerState.idle;
  callback: any;
  interval: number;
  pauseTime: number = 0;
  delayTime: number = 0;

  constructor(callback, interval) {
    this.callback = callback;
    this.interval = interval;
    this.startTime = new Date().getTime();
    this.timerId = window.setInterval(callback, interval);
    this.state = TimerState.running;
    this.pauseTime = 0;
    this.delayTime = 0;
  }

  pause() {
    if (this.state !== TimerState.running) {
      return;
    }

    this.pauseTime = new Date().getTime();
    this.remaining = this.interval - (this.pauseTime - this.startTime);
    window.clearInterval(this.timerId);
    this.state = TimerState.paused;
  }

  resume() {
    if (this.state !== TimerState.paused) return;

    this.state = TimerState.resumed;
    this.timeoutId = window.setTimeout(() => this.timeoutCallback(), this.remaining);
  }

  timeoutCallback() {
    if (this.state !== TimerState.resumed) return;
    // run callback
    this.callback();

    this.startTime = new Date().getTime();
    this.delayTime = this.startTime - this.pauseTime;
    this.timerId = window.setInterval(this.callback, this.interval);
    this.state = TimerState.running;
  }

  stop() {
    window.clearInterval(this.timerId);
    window.clearTimeout(this.timeoutId);
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class DiagnosticCompanyStorageService {

  constructor() {}

  setCompanyStorage(companyId, value) {
    const key = companyId ? `company_${companyId}` : `company`;
    const values = value ? (Array.isArray(value) ? value : [value]) : [];
    sessionStorage.setItem(`${key}`, JSON.stringify(values));
  }

  getCompanyStorage(companyId) {
    const key = companyId ? `company_${companyId}` : `company`;
    try {
      const stringData = sessionStorage.getItem(`${key}`);
      return JSON.parse(stringData);
    } catch (error) {
      return null;
    }
  }

  removeCompanyStorage(companyId) {
    const key = companyId ? `company_${companyId}` : `company`;
    sessionStorage.removeItem(`${key}`);
  }

  clearAll() {
    sessionStorage.clear();
  }
}
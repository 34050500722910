import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_boost from 'highcharts/modules/boost';
HC_boost(Highcharts);
import { IChannelSettingModel, ITimeSetting, ITreatmentComment, IInputDataChart, IChannelSettingItemModel, IInputDataMultiFlow, IMappedChannel as IMappedChannel, IChannelItem } from '../../models';
import { Helper } from '../../helpers/helper';
import { isEmpty, isObject, isNumber, isString, cloneDeep, minBy, maxBy, isNil, uniq } from 'lodash';
import { CHART_MESSAGES, CHART_SERIES_NAME, CHART_OPTION, TIME_SETTING_TYPE, YAXIS_KEYS, PREFIX_PREDICTIVE_CHANNEL } from '../../helpers/app.constants';
import { UnitSystemService } from '../unit-system.service';
import { CustomDataPoint, IDiagnosticPoint, IDiagnosticPointDataChange } from '../../models/diagnostic-points.model';

export interface ITimeRangeFilter {
  timefloat: { start: number, end: number }; // in sec
  timestamp: { start: number, end: number }; // in millisec epoch
}

export interface ISeriesDataExtraInfo {
  seriesColor?: string;
  seriesPrefix?: string;
  yAxisMin?: number;
  yAxisMax?: number;
  timezoneOffset?: number;
  isOffsetSeries?: boolean;
}

export const TIMEFLOAT_CHANNEL_NAME = 'C0';
export const FRACPRO_TIMEFLOAT_CNAME = 'F0';
export const EQUIPMENT_TIMEFLOAT_CNAME = 'E0';
export const DATETIME_CHANNEL_NAME = 'time';
export enum ENUM_CURSOR_OPTS {
  Default = 'default',
  LargeCross = 'crosshair',
  VertLine = 'n-resize'
};
export const CURSOR_OPT_DEFAULT = 'Vert Line';
export const COMPARE_CURSOR_OPT_DEFAULT = 'Large Cross';
export const CONST_CURSOR_OPTIONS = [
  { id: ENUM_CURSOR_OPTS.VertLine, text: 'Vert Line', typeCursor: ENUM_CURSOR_OPTS.VertLine, icon: 'icon-vertical-line' },
  { id: ENUM_CURSOR_OPTS.LargeCross, text: 'Large Cross', typeCursor: ENUM_CURSOR_OPTS.LargeCross, icon: 'icon-plus-line' }
];

export const TOOLTIP_STYLE = `padding: 5px; border: 1px solid #000; border-radius: 8px;`;

@Injectable()
export class BaseChartService {
  constructor(
    private unitSystemService: UnitSystemService
  ) { }

  private crosshairDefault: Highcharts.XAxisCrosshairOptions = { dashStyle: 'ShortDot', width: 2, zIndex: 100 };
  private crosshairDefaultNoSnap: Highcharts.XAxisCrosshairOptions = { dashStyle: 'ShortDot', width: 2, zIndex: 100, snap: false };

  public crosshair = (id: string) => {
    const result: {x: Highcharts.XAxisCrosshairOptions | boolean, y: Highcharts.XAxisCrosshairOptions | boolean} = {
      x: false,
      y: false
    };
    if (id === ENUM_CURSOR_OPTS.LargeCross) {
      result.x = this.crosshairDefault;
      result.y = this.crosshairDefault;
    }
    if (id === ENUM_CURSOR_OPTS.VertLine) {
      result.x = this.crosshairDefault;
    }
    return result;
  }

  getDefaultOptions(isBoost?: boolean, isTimeMin?: boolean, isDarkMode?: boolean, cursor?: {id: string, text: string, typeCursor: string}): Highcharts.Options {
    const symbol = '●';
    const tooltipPointMaker = `<span style="color:{series.color}">${symbol}</span>`;
    const tooltipPointFormat = `
      <tr>
        <td>${tooltipPointMaker} {series.name}: </td>
        <td style="text-align: left"><b>{point.y}</b></td>
      </tr>
    `;
    return {
      chart: {
        style: {
          fontFamily: '"Open Sans", Roboto, Arial, sans-serif'
        },
        backgroundColor: !isDarkMode ? '#ffffff' : '#181a1b',
        borderColor: !isDarkMode ? undefined : '#b1aba1',
        plotBackgroundColor: !isDarkMode ? '#ffffff' : '#181a1b',
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        },
        spacing: [5, 5, 0, 5],
        animation: false,
        events: {}
      },
      boost: {
        enabled: !!isBoost,
        useGPUTranslations: false,
        seriesThreshold: 1
      },
      lang: {
        noData: CHART_MESSAGES.en.pleaseSelectChannel
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
        printMaxWidth: 2000
      },
      credits: {
        enabled: false
      },
      xAxis: [],
      yAxis: [],
      tooltip: {
        shared: !cursor || cursor.id === ENUM_CURSOR_OPTS.Default,
        xDateFormat: '%n-%d-%Y %H:%M:%S',
        useHTML: true,
        headerFormat: '<small><b>Time:</b> {point.key}</small><table>',
        borderColor: '#000',
        pointFormat: tooltipPointFormat,
        footerFormat: '</table>',
        valueDecimals: 2,
        backgroundColor: null,
        borderWidth: 0,
        formatter: this.getTooltipFormatter(false, isTimeMin)
      },
      legend: {
        itemDistance: 50,
        maxHeight: 88,
        margin: 0,
        itemMarginBottom: 0,
        enabled: true,
        itemStyle: {color: !isDarkMode ? '' : '#e8e6e3'},
        itemHoverStyle: { color: !isDarkMode ? '' : '#f5f5f5' },
        itemHiddenStyle: { color: !isDarkMode ? '#333333b3' : '#a7a7a7' }
      },
      series: [],
      time: {
        useUTC: CHART_OPTION.useUTC
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          states: {
            inactive: {
              opacity: 1
            },
            hover: {
              lineWidth: 2
            }
          },
          turboThreshold: 0,
          showInNavigator: false,
          dataGrouping: {
            enabled: false
          },
          cursor: !cursor ? 'default' : cursor.id
        },
        line: {
          animation: false,
          findNearestPointBy: 'xy',
        }
      },
      navigator: {
        enabled: false
      },
      // rangeSelector: {
      //   selected: 1
      // },
    };
  }

  getTooltipFormatter(isOnlyShowComment?: boolean, isTimeMin?: boolean) {
    const formatter = function () {
      const symbol = '●';
      const tooltipStyle = TOOLTIP_STYLE;
      const customStyle = `background: #000; color: #fff; opacity: 0.75;`;
      const getTimeValueHeader = (activePoint, isShare?: boolean) => {
        let isDatetimeAxis = false;
        if (isShare) {
          if (activePoint.points.length > 0) {
            const firstPoint = activePoint.points[0];
            isDatetimeAxis = firstPoint.series.chart.xAxis[0].isDatetimeAxis;
          }
        } else {
          isDatetimeAxis = activePoint.series.chart.xAxis[0].isDatetimeAxis;
        }
        let timeValue = `${activePoint.x ? Helper.convertSecToMin(isTimeMin ? activePoint.x * 60 : activePoint.x) : 0} min`;
        if (isDatetimeAxis) {
          const utcTimestamp = new Date(activePoint.x).getTime();
          timeValue = Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', utcTimestamp);
        }
        return timeValue;
      };
      const getTooltipMarker = (activePoint) => {
        return `<span style="color:${activePoint.color}">${symbol}</span>`;
      };
      const getUnshareTooltip = (activePoint) => {
        const tooltipPointMaker = getTooltipMarker(activePoint);
        const timeValue = getTimeValueHeader(activePoint);
        const commentTootipContent = `
          <div style="${tooltipStyle}${customStyle}">
            <small><b>Time:</b> ${timeValue}</small>
            <div>${activePoint.point.comment}</div>
          </div>
        `;
        if (isOnlyShowComment) {
          if (activePoint.point.comment && activePoint.point.series.name === CHART_SERIES_NAME.comment) {
            return commentTootipContent;
          }
          return null;
        } else {
          if (activePoint.point.comment && activePoint.point.series.name === CHART_SERIES_NAME.comment) {
            return commentTootipContent;
          } else if (activePoint.series.name === CHART_SERIES_NAME.diagnostic || activePoint.series.name === CHART_SERIES_NAME.los) { // show diagnostic name
              const names = activePoint.series.options.data && activePoint.series.options.data.length > 1
                ? uniq(activePoint.series.options.data.map(item => item.name)) 
                : activePoint.point.name;
              let dataRow: string = '';
              if (Array.isArray(names)) {
                names.forEach(name => {
                  dataRow += `<tr><td colspan="2"><b>${name}</b></td></tr>`;
                });
              } else dataRow = `<tr><td colspan="2"><b>${names}</b></td></tr>`;
              return `
                <div style="${tooltipStyle} background-color:rgba(255, 255, 255, 0.75);">
                  <small><b>Time:</b> ${timeValue}</small>
                  <table>
                    ${dataRow}
                  </table>
                </div>
              `;
          } else {
            const seriesName = activePoint.series.userOptions.tooltipName || activePoint.series.userOptions.name || activePoint.series.name;
            return `
              <div style="${tooltipStyle} background-color:rgba(255, 255, 255, 0.75);">
                <small><b>Time:</b> ${timeValue}</small>
                <table>
                  <tr>
                    <td>${tooltipPointMaker} ${seriesName}: </td>
                    <td style="text-align: left"><b>${Highcharts.numberFormat(activePoint.y, 2)}</b></td>
                  </tr>
                </table>
              </div>
            `;
          }
        }
      };
      if (this.point && !this.points) {
        return getUnshareTooltip(this);
      } else if (this.points && this.points.length > 0) {
        const tbRows = [];
        const timeValue = getTimeValueHeader(this, true);
        this.points.forEach(activePoint => {
          const tooltipPointMaker = `<span style="color:${activePoint.color}">${symbol}</span>`;
          // not show tooltip for special group series
          const specialSeries = [CHART_SERIES_NAME.comment, CHART_SERIES_NAME.diagnostic, CHART_SERIES_NAME.los, CHART_SERIES_NAME.fdi];
          if (!specialSeries.includes(activePoint.series.name)) {
            const seriesName = activePoint.series.userOptions.tooltipName || activePoint.series.userOptions.name || activePoint.series.name;
            const dataRow = `
              <tr>
                <td>${tooltipPointMaker} ${seriesName}: </td>
                <td style="text-align: left"><b>${Highcharts.numberFormat(activePoint.y, 2)}</b></td>
              </tr>
            `;
            tbRows.push(dataRow);
          } else if (activePoint.series.name === CHART_SERIES_NAME.diagnostic || activePoint.series.name === CHART_SERIES_NAME.los) { // show diagnostic name
            const dataRow = `
              <tr>
                <td colspan="2"><b>${activePoint.point.name}</b></td>
              </tr>
            `;
            tbRows.push(dataRow);
          } else if (activePoint.series.name === CHART_SERIES_NAME.fdi) { // show diagnostic name
            const dataRow = `
              <tr>
                <td colspan="2"><b>${activePoint.point.name}</b></td>
              </tr>
            `;
            tbRows.push(dataRow);
          }
        });
        // find comment point
        const commentPoint = this.points.find(item => item.point.comment && item.point.series.name === CHART_SERIES_NAME.comment);
        if (!isOnlyShowComment) {
          // show all points and comments
          if (commentPoint && commentPoint.point && commentPoint.point.comment) {
            const commentRow = `
              <tr>
                <td colspan="2">${commentPoint.point.comment}</td>
              </tr>
            `;
            tbRows.unshift(commentRow);
          }
          return `
            <div style="${tooltipStyle} background-color:rgba(255, 255, 255, 0.75);">
              <small><b>Time:</b> ${timeValue}</small>
              <table>
                ${tbRows.join('')}
              </table>
            </div>
          `;
        } else {
          // only show points if there are comments on them
          if (commentPoint && commentPoint.point && commentPoint.point.comment) {
            const commentRow = `
              <tr>
                <td colspan="2">${commentPoint.point.comment}</td>
              </tr>
            `;
            tbRows.unshift(commentRow);
            return `
              <div style="${tooltipStyle} background-color:rgba(255, 255, 255, 0.75);">
                <small><b>Time:</b> ${timeValue}</small>
                <table>
                  ${tbRows.join('')}
                </table>
              </div>
            `;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    };
    return formatter;
  }

  getYAxisOption(channelSettings: IChannelSettingModel, offsetChannelSettings?: IChannelSettingModel, unitSetting?: number, isCrosshair?: boolean) {
    const result = [];
    const thisService = this;
    if (channelSettings) {
      let yAxisIndex = -1;
      const yAxisKeys = YAXIS_KEYS;
      for (let index = 0; index < yAxisKeys.length; index++) {
        const key = yAxisKeys[index];
        if (channelSettings.hasOwnProperty(key)) {
          yAxisIndex++;
          const setting = channelSettings[key];
          let listChannels = [...setting.channels];
          if (offsetChannelSettings && offsetChannelSettings[key]) {
            listChannels = [...setting.channels, ...offsetChannelSettings[key].channels];
          }
          let axisName = '';
          listChannels.forEach(item => {
            const unit = item.measureType && item.measureType.trim() ? this.unitSystemService.getUnit(item.measureType, unitSetting) : item.unit;
            const channelUnit = Helper.getValidUnit(unit, true);
            let channelName = item.channelName + ' ' + channelUnit;
            if (item.isBourdetDer) channelName = Helper.insertString(channelName, '/sec', -1);
            axisName = axisName + `${axisName ? ' - ' : ''}<span style="color: ${item.color}">${channelName}</span>`;
          });
          setting.min = (isString(setting.min) && setting.min) ? parseFloat(setting.min.replace(/,/g, '')) : setting.min;
          setting.max = (isString(setting.max) && setting.max) ? parseFloat(setting.max.replace(/,/g, '')) : setting.max;

          const minValue = setting.min > setting.max ? setting.max : setting.min;
          const maxValue = setting.min > setting.max ? setting.min : setting.max;
          const isReverted = setting.min > setting.max;
          const option: Highcharts.YAxisOptions = {
            labels: {
              style: {
                // color: item.color
              }
            },
            title: {
              text: axisName,
              useHTML: false,
              style: {
                wordWrap: 'break-word',
                width: 300,
                fontFamily: '"Open Sans", Roboto, Arial, sans-serif',
                fontWeight: 'bold'
              }
            },
            crosshair: !!isCrosshair ? this.crosshairDefault : false,
            opposite: setting.opposite || false,
            showEmpty: false,
            lineWidth: 1,
            tickWidth: 1,
            tickLength: 5,
            min: minValue || null,
            max: maxValue || null,
            reversed: !!isReverted,
            alignTicks: true,
            gridLineWidth: 1,
            tickPositioner() {
              const thisAxis: any = this;
              const min = thisAxis.dataMin;
              let max = thisAxis.dataMax;
              if (isNumber(min) && isNumber(max) && !isNaN(min) && !isNaN(max)) {
                if (min === max) {
                  max = max + 1;
                }
                // add padding max
                const padding = parseFloat((Math.abs(min + max) * 0.1).toFixed(4)); // padding 10%
                // round up max value
                let maxValue = max + padding; // round up max nearest 100
                if (maxValue < 0) {
                  maxValue = 0; // round up to 0
                } else if (maxValue > 0 && maxValue < 1) {
                  maxValue = 1; // round up to 1
                } else if (maxValue > 1 && maxValue < 99) {
                  maxValue = Math.ceil(maxValue / 10) * 10; // round up max nearest 10
                } else {
                  maxValue = Math.ceil(maxValue / 100) * 100; // round up max nearest 10
                }
                const tickPositions = thisService.getTickPositions(min, maxValue);
                return tickPositions;
              } else {
                return thisAxis.tickPositions;
              }
            }
          };

          if (channelSettings.autoScaleYAxis) {
            option.min = null;
            option.max = null;
          }
          if (!channelSettings.autoScaleYAxis && isNumber(minValue) && isNumber(maxValue) && !isNaN(minValue) && !isNaN(maxValue)) {
            option.tickPositions = this.getTickPositions(minValue, maxValue);
          }
          result.push(option);
        }
      }
    }
    return result;
  }

  getTickPositions(min, max) {
    const ticks = [];
    if (typeof min === 'number' && typeof max === 'number' && !isNaN(min) && !isNaN(max)) {
      max = parseFloat(max.toFixed(4));
      let tick = min;
      const step = (max - min) / 5;

      while (tick < max - step / 2) {
        if (tick > 1) {
          ticks.push(parseFloat(tick.toFixed(0)));
        } else if (tick > 0.1 && tick < 1) {
          ticks.push(parseFloat(tick.toFixed(1)));
        } else {
          ticks.push(parseFloat(tick.toFixed(2)));
        }
        tick += step;
      }
      ticks.push(max);
    }
    return ticks;
  }

  getXAxisOption(timeFormat, startIndex?, endIndex?, isDefaultLabel?: boolean, isCrosshair?: boolean): Highcharts.XAxisOptions {
    const result: Highcharts.XAxisOptions = {
      type: 'datetime',
      showEmpty: false,
      gridLineWidth: 1,
      title: {
        text: 'Date time',
      },
      minPadding: 0,
      maxPadding: 0.05,
      minRange: 1,
      startOnTick: false,
      tickLength: 5,
      min: startIndex || null,
      max: endIndex || null,
      crosshair: !!isCrosshair ? this.crosshairDefault : false
    };
    if (timeFormat && timeFormat.toLowerCase() === TIME_SETTING_TYPE.OffsetTimeLower) {
      result.type = 'linear';
      result.title.text = 'Job Time (min)';
      if (!isDefaultLabel) {
        result.labels = {
          formatter() {
            const xValue = Helper.convertSecToMin(this.value);
            return xValue;
          }
        };
      }
    } else {
      result.labels = {
        formatter() {
          const utcTimestamp = new Date(this.value).getTime();
          if (!this.isFirst && !this.isLast) {
            return Highcharts.dateFormat('%H:%M', utcTimestamp);
          } else {
            return Highcharts.dateFormat('%e. %b %Y %H:%M', utcTimestamp);
          }
        }
      };
    }
    return result;
  }

  getTooltipHeader(timeFormat) {
    const beginBody = '<table>';
    let result = `<small><b>Time:</b> {point.key}</small>${beginBody}`;
    if (timeFormat && timeFormat.toLowerCase() === TIME_SETTING_TYPE.OffsetTimeLower) {
      const unit = 'min';
      result = `<small><b>Time:</b> {point.key:.2f} ${unit}</small>${beginBody}`;
    }
    return result;
  }

  getDataSeriesFromChannels(yAxisIndex: number, channels: IChannelSettingItemModel[], highChartSeries: Highcharts.Series[], inputData?: IInputDataChart, timeFormat?: string, dataType?: string, extraInfo?: ISeriesDataExtraInfo, unitSetting?: number) {
    extraInfo = isObject(extraInfo) ? extraInfo : {};
    if (!channels || !inputData) return [];

    const columns = inputData.columns;
    let xAxisChannelName = DATETIME_CHANNEL_NAME;
    if (timeFormat && timeFormat.toLowerCase() === TIME_SETTING_TYPE.OffsetTimeLower) {
      xAxisChannelName = TIMEFLOAT_CHANNEL_NAME;
      // set fracpro time float channel name
      if (dataType === 'fracproData') {
        xAxisChannelName = FRACPRO_TIMEFLOAT_CNAME;
      } else if (dataType === 'equipmentData') {
        xAxisChannelName = EQUIPMENT_TIMEFLOAT_CNAME;
      }
    }
    // find index of time channel
    const timeIndex = columns.findIndex(value => value === xAxisChannelName);

    const result = channels.map((channelItem, index) => {
      const measureType = channelItem.measureType;
      const unitCoefficient = this.unitSystemService.getUnitCoefficient(measureType, unitSetting);
      let dataSeries = [];
      const dataIndex = typeof yAxisIndex === 'number' && !isNaN(yAxisIndex) ? yAxisIndex : index;
      const channelIndex = columns.findIndex(value => value === channelItem.cName);

      // parse to chart data point
      const seriesId = `${channelItem.channelName || channelItem.name}_${dataIndex}${extraInfo.isOffsetSeries ? '_offset' : ''}`;
      const isXValueNameChange = xAxisChannelName === DATETIME_CHANNEL_NAME;

      if (channelIndex >= 0 && channelIndex < inputData.columns.length) {
        const currentChartSeries = highChartSeries.find(x => x.options.id === seriesId);
        const lastChartSeriesData = !currentChartSeries || !currentChartSeries.data || !currentChartSeries.data.length ? null : currentChartSeries.data[currentChartSeries.data.length - 1];

        if (lastChartSeriesData) {
          let startIndex = 0;
          const lastIndex = inputData.values.length - 1;
          for (let i = lastIndex; i >= startIndex; i--) {
            const dataRow = inputData.values[i];
            const xValue = !isXValueNameChange ? dataRow[timeIndex] : new Date(dataRow[timeIndex]).getTime();
            if (xValue > lastChartSeriesData.x) continue;
            startIndex = i;
            break;
          }

          if (unitCoefficient) {
            for (let i = startIndex + 1; i < lastIndex; i++) {
              const dataRow = inputData.values[i];
              const xValue = !isXValueNameChange ? dataRow[timeIndex] : new Date(dataRow[timeIndex]).getTime();
              const yValue = this.unitSystemService.convertValue(dataRow[channelIndex], unitCoefficient);
              const value = { x: xValue, y: yValue, groupId: seriesId };
              dataSeries.push(value);
            }
          } else {
            for (let i = startIndex + 1; i < lastIndex; i++) {
              const dataRow = inputData.values[i];
              const xValue = !isXValueNameChange ? dataRow[timeIndex] : new Date(dataRow[timeIndex]).getTime();
              const yValue = dataRow[channelIndex] as number;
              const value = { x: xValue, y: yValue, groupId: seriesId };
              dataSeries.push(value);
            }
          }
        } else {
          if (unitCoefficient) {
            dataSeries = inputData.values.map(dataRow => {
              const xValue = !isXValueNameChange ? dataRow[timeIndex] : new Date(dataRow[timeIndex]).getTime();
              const yValue = this.unitSystemService.convertValue(dataRow[channelIndex], unitCoefficient);
              return { x: xValue, y: yValue, groupId: seriesId };
            });
          } else {
            dataSeries = inputData.values.map(dataRow => {
              const xValue = !isXValueNameChange ? dataRow[timeIndex] : new Date(dataRow[timeIndex]).getTime();
              const yValue = dataRow[channelIndex] as number;
              return { x: xValue, y: yValue, groupId: seriesId };
            });
          }
        }
      }

      const unit = (measureType && measureType.trim() && !!unitSetting) ? (unitCoefficient ? unitCoefficient.unit : undefined) : channelItem.unit;
      let channelUnit = Helper.getValidUnit(unit, true);
      if (channelItem.isBourdetDer) channelUnit = Helper.insertString(channelUnit, '/sec', -1);

      const channelName = channelItem.channelName || channelItem.name;
      const isDisplayOriginalName = (channelItem.originalName && channelName.toUpperCase() !== channelItem.originalName.toUpperCase());

      let isPridective: boolean;
      if (channelItem.originalName) {
        const originalNameLoweCase = channelItem.originalName.toLowerCase();
        isPridective = originalNameLoweCase.indexOf(PREFIX_PREDICTIVE_CHANNEL) === 0;
      }

      let seriesName = `${extraInfo.seriesPrefix || ''}${channelItem.channelName || channelItem.name}`;
      const tooltipName = !!isDisplayOriginalName ? `${seriesName}: ${channelItem.originalName} ${channelUnit}` : `${seriesName} ${channelUnit}`;
      seriesName = seriesName + ' ' + channelUnit;
      const seriesColor = extraInfo.seriesColor ? extraInfo.seriesColor : channelItem.color;

      return {
        id: seriesId,
        name: seriesName,
        type: 'line',
        data: dataSeries,
        dashStyle: !!isPridective ? 'shortdash' : 'solid',
        tooltipName,
        yAxis: dataIndex,
        color: seriesColor,
        boostThreshold: 10000,
        connectNulls: true
      };
    });
    return result;
  }

  updateTimestampByTimezoneOffset(inputData: IInputDataChart, timezoneOffset?: number) {
    // if (inputData && !isEmpty(inputData.values) && timezoneOffset) {
    //   const values = inputData.values;
    //   const columns = inputData.columns;
    //   const xAxisChannelName = DATETIME_CHANNEL_NAME;
    //   // find index of time channel
    //   const timeIndex = columns.findIndex(value => value === xAxisChannelName);
    //   values.forEach(dataRow => {
    //     const curTimestamp = dataRow[timeIndex];
    //     if (xAxisChannelName === DATETIME_CHANNEL_NAME) {
    //       dataRow[timeIndex] = new Date(curTimestamp).getTime();
    //       if (isNumber(timezoneOffset)) {
    //         dataRow[timeIndex] = dataRow[timeIndex] + (timezoneOffset * 60 * 1000);
    //       }
    //     }
    //   });
    // }
  }

  parseSeriesData(channelSettings: IChannelSettingModel, highChartSeries: Highcharts.Series[], inputData?: IInputDataChart, mappedChannels?: IChannelItem[], timeFormat?: string, dataType?: string, extraInfo?: ISeriesDataExtraInfo, unitSetting?: number) {
    extraInfo = isObject(extraInfo) ? extraInfo : {};
    const result = [];

    if (channelSettings && inputData) {
      let yAxisIndex = 0;
      for (const key in channelSettings) {
        if (channelSettings.hasOwnProperty(key) && key !== 'autoScaleYAxis') {
          const setting = channelSettings[key];
          if (setting.channels && setting.channels.length > 0) {
            // set y axis's min max for filtering data series
            if (isNumber(setting.min) && isNumber(setting.max) && !channelSettings.autoScaleYAxis) {
              extraInfo.yAxisMin = setting.min;
              extraInfo.yAxisMax = setting.max;
            }
            if (mappedChannels && mappedChannels.length) {
              for (const i of setting.channels) {
                const item = mappedChannels.find(x => x.name === i.channelName);
                if (!item) continue;
                i.originalName = item.originalName;
              }
            }

            const seriesData = this.getDataSeriesFromChannels(yAxisIndex, setting.channels, highChartSeries, inputData, timeFormat, dataType, extraInfo, unitSetting);
            result.push(...seriesData);
          }
          yAxisIndex++;
        }
      }
    }

    return result;
  }

  isAllSeriesDataEmpty(seriesData) {
    let result = false;
    if (seriesData && seriesData.length > 0) {
      const emptyData = seriesData.filter(item => item && item.data && item.data.length === 0);
      result = emptyData.length === seriesData.length;
    }
    return result;
  }

  getLastDataEachChannel(channelSettings: IChannelSettingModel, inputData): IChannelSettingItemModel[] {
    let result = [];
    if (channelSettings && inputData) {
      try {
        const selectedChannels = [
          ...channelSettings.firstLeft.channels,
          ...channelSettings.secondLeft.channels,
          ...channelSettings.firstRight.channels,
          ...channelSettings.secondRight.channels
        ];
        if (inputData.values && inputData.values.length > 0) {
          // find index of time channel
          result = selectedChannels.map(channelItem => {
            const mapValue = this.getLastValueByChannel(channelItem, inputData);
            return { ...channelItem, channelValue: mapValue.channelValue };
          });
        }
      } catch (err) {
        throw err;
      }
    }
    return result;
  }

  getLastValueByChannel(channelItem, inputData: IInputDataChart) {
    const columns = inputData.columns;
    let channelValue = null;
    let lastData = null;
    let firstData = null;

    if (inputData.values && inputData.values.length > 0) {
      const channelIndex = columns.findIndex(value => value === channelItem.cName);
      if (channelIndex >= 0) {
        const values = inputData.values.filter(dataRow => {
          const yValue = dataRow[channelIndex];
          return isNumber(yValue) && !isNaN(yValue);
        });
        if (!isEmpty(values)) {
          lastData = values[values.length - 1];
          firstData = values[0];
        }
        if (lastData && isNumber(lastData[channelIndex]) && !isNaN(lastData[channelIndex])) {
          channelValue = lastData[channelIndex];
        }
      }
    }
    return { lastData, firstData, channelValue };
  }

  getFirstLastData(inputData: IInputDataChart) {
    if (!inputData || !inputData.values.length) return { firstData: null, lastData: null };
    const n = inputData.values.length;
    return {
      firstData: inputData.values[0],
      lastData: inputData.values[n - 1]
    };
  }

  checkHasNewData(currentInputData: IInputDataChart, newInputDataSliced?: IInputDataChart, dataType?: string) {
    let result = false;
    let timeFloatCName = TIMEFLOAT_CHANNEL_NAME;
    if (dataType === 'fracproData') {
      timeFloatCName = FRACPRO_TIMEFLOAT_CNAME;
    } else if (dataType === 'equipmentData') {
      timeFloatCName = EQUIPMENT_TIMEFLOAT_CNAME;
    }
    if (currentInputData && newInputDataSliced) {
      const lastTimeFloatValue = this.getChannelValue(timeFloatCName, currentInputData);
      const newestTimeFloatValue = this.getChannelValue(timeFloatCName, newInputDataSliced, 0);
      if (Helper.isNumberic(lastTimeFloatValue) && Helper.isNumberic(newestTimeFloatValue)) {
        if (newestTimeFloatValue >= lastTimeFloatValue) {
          result = true;
        }
      } else if (lastTimeFloatValue === null && newestTimeFloatValue > lastTimeFloatValue) {
        result = true;
      }
    }
    return result;
  }

  sliceToGetNewInputData(currentInputData: IInputDataChart, newInputData: IInputDataChart, dataType?: string) {
    let result = null;
    if (currentInputData && newInputData &&
      newInputData.values && currentInputData.values &&
      newInputData.values.length > currentInputData.values.length
    ) {
      let timeFloatCName = TIMEFLOAT_CHANNEL_NAME;
      if (dataType === 'fracproData') {
        timeFloatCName = FRACPRO_TIMEFLOAT_CNAME;
      } else if (dataType === 'equipmentData') {
        timeFloatCName = EQUIPMENT_TIMEFLOAT_CNAME;
      }
      const lastIndexCurrentInputData = currentInputData.values.length - 1;
      const lastTimeFloatValue = this.getChannelValue(timeFloatCName, currentInputData);
      // find index that there are new data
      const timeIndex = newInputData.columns.findIndex(value => value === timeFloatCName);
      const indexNewData = newInputData.values.findIndex(dataRow => dataRow[timeIndex] > lastTimeFloatValue);

      // create result base on newInputData with new values
      result = {
        ...newInputData,
        values: []
      };

      if (indexNewData > -1) {
        result.values = newInputData.values.slice(indexNewData);
      } else {
        // slice data from next last index to get new values
        result.values = newInputData.values.slice(lastIndexCurrentInputData + 1);
      }
    }
    return result;
  }

  getChannelValue(channelId, inputData, inputIndexDataRow?: number) {
    let channelValue = null;
    if (channelId && inputData) {
      const values = inputData.values;
      const columns = inputData.columns;
      // find index value of channel
      const channelIndex = columns.findIndex(value => value === channelId);
      if (values && values.length > 0) {
        // default get last data row;
        let indexDataRow = values.length - 1;
        if (inputIndexDataRow >= 0) {
          indexDataRow = inputIndexDataRow;
        }
        const lastDataRow = values[indexDataRow];
        if (lastDataRow && lastDataRow.length > 0) {
          channelValue = lastDataRow[channelIndex];
        }
      }
    }
    return channelValue;
  }

  getTimeRangeFilter(inputData?: IInputDataChart): ITimeRangeFilter {
    const result = {
      timefloat: { start: null, end: null },
      timestamp: { start: null, end: null }
    };
    if (inputData && !isEmpty(inputData.columns) && !isEmpty(inputData.values)) {
      const values = inputData.values;
      const columns = inputData.columns;
      const timestampIndex = columns.findIndex(value => value === DATETIME_CHANNEL_NAME);
      const timefloatIndex = columns.findIndex(value => value === TIMEFLOAT_CHANNEL_NAME || value === FRACPRO_TIMEFLOAT_CNAME || value === EQUIPMENT_TIMEFLOAT_CNAME);
      const firstDataRow = values[0];
      const lastDataRow = values[values.length - 1];

      // covert time equipment chart
      if (columns.findIndex(value => value === EQUIPMENT_TIMEFLOAT_CNAME) !== -1) {
        let defaultTime = 0;
        values.forEach((valueData, valueIndex) => {
          if (valueIndex === 0) {
            defaultTime = valueData[timefloatIndex];
          }
          valueData[timefloatIndex] = this.convertTimeEquipment(defaultTime, valueData[timefloatIndex]);
        });
      }

      // convert time float values from second to min (time float value on database is second but show on UI is min)
      // result.timefloat.start = Helper.convertSecToMin(firstDataRow[timefloatIndex]);
      // result.timefloat.end = Helper.convertSecToMin(lastDataRow[timefloatIndex]);
      result.timefloat.start = firstDataRow[timefloatIndex];
      result.timefloat.end = lastDataRow[timefloatIndex];

      result.timestamp.start = new Date(firstDataRow[timestampIndex]).getTime();
      result.timestamp.end = new Date(lastDataRow[timestampIndex]).getTime();
    }
    return result;
  }

  mergeTimeRangeOffsetChannels(offsetChartInputData: IInputDataMultiFlow[], primaryTimeRange?: ITimeRangeFilter): ITimeRangeFilter {
    const timeRangeData = [];
    if (primaryTimeRange) {
      timeRangeData.push(primaryTimeRange);
    }
    if (!isEmpty(offsetChartInputData)) {
      offsetChartInputData.forEach(inputData => {
        if (!isEmpty(inputData.realtimeData) && inputData.realtimeData[0]) {
          const timeRangeRealtimeData = this.getTimeRangeFilter(inputData.realtimeData[0]);
          timeRangeData.push(timeRangeRealtimeData);
        }
      });
    }
    if (!isEmpty(timeRangeData)) {
      const startOffsetTimeItem = minBy(timeRangeData, item => item.timefloat.start);
      const endOffsetTimeItem = maxBy(timeRangeData, item => item.timefloat.end);
      const startDateTimeItem = minBy(timeRangeData, item => item.timestamp.start);
      const endDateTimeItem = maxBy(timeRangeData, item => item.timestamp.end);
      const result = {
        timefloat: { start: startOffsetTimeItem.timefloat.start, end: endOffsetTimeItem.timefloat.end },
        timestamp: { start: startDateTimeItem.timestamp.start, end: endDateTimeItem.timestamp.end },
      };
      return result;
    }
    return;
  }

  mergeTimeRangeFilter(realtimeDataRange: ITimeRangeFilter, fracproDataRange: ITimeRangeFilter): ITimeRangeFilter {
    const result = {
      timefloat: { start: null, end: null },
      timestamp: { start: null, end: null }
    };
    if (realtimeDataRange && fracproDataRange) {
      for (const key in result) {
        if (result.hasOwnProperty(key)) {
          if (realtimeDataRange[key].start === null && realtimeDataRange[key].end === null) {
            result[key].start = fracproDataRange[key].start;
            result[key].end = fracproDataRange[key].end;
          } else if (fracproDataRange[key].start === null && fracproDataRange[key].end === null) {
            result[key].start = realtimeDataRange[key].start;
            result[key].end = realtimeDataRange[key].end;
          } else {
            if (realtimeDataRange[key].start > fracproDataRange[key].start) {
              result[key].start = fracproDataRange[key].start;
            } else {
              result[key].start = realtimeDataRange[key].start;
            }
            if (realtimeDataRange[key].end < fracproDataRange[key].end) {
              result[key].end = fracproDataRange[key].end;
            } else {
              result[key].end = realtimeDataRange[key].end;
            }
          }
        }
      }
    }

    if (result.timestamp.start <= 0 && result.timestamp.end > 86400000) {
      // get last 24 hours as start time
      const durationInMillisec = (result.timefloat.end - result.timefloat.start) * 1000;
      result.timestamp.start = result.timestamp.end - durationInMillisec;
    }

    return result;
  }

  toFilterSettings(type, timeRangeFilterValues: ITimeRangeFilter, timezone?: string, startValue?, endValue?, autoScale?: boolean): ITimeSetting {
    const filterType = type ? type : '';
    const filterTypeLower = filterType.toLowerCase();
    // default startIndex and endIndex = 0 to let it autoScale
    let result: ITimeSetting = { type: filterType, startIndex: 0, endIndex: 0, autoScale };
    //
    const offsetTime = (result, startIndex?, endIndex?) => {
      if (!isNil(startIndex) && !isNil(endIndex)) {
        result.startIndex = startIndex * 60;  // do * 60 for min to second
        result.endIndex = endIndex * 60;  // do * 60 for min to second
      }
      return result;
    };
    const epochTime = (result, startIndex?, endIndex?) => {
      if (!isNil(startIndex) && !isNil(endIndex)) {
        result.startIndex = Helper.floorDataTimeString(startIndex, timezone) * 1000; // do * 1000 for milisecond
        result.endIndex = Helper.ceilDataTimeString(endIndex, timezone) * 1000; // do * 1000 for milisecond
      }
      return result;
    };
    if (!timeRangeFilterValues) {
      if (filterType && filterTypeLower === TIME_SETTING_TYPE.OffsetTimeLower) {
        return offsetTime(result, startValue, endValue);
      } else {
        return epochTime(result, startValue, endValue);
      }
    }

    // case 1: user select 'float'
    if (filterType && filterTypeLower === TIME_SETTING_TYPE.OffsetTimeLower) {
      if (!timeRangeFilterValues.timefloat) return result;
      result.startIndex = timeRangeFilterValues.timefloat.start;
      result.endIndex = timeRangeFilterValues.timefloat.end;
      return offsetTime(result, startValue, endValue);
      // result.startIndex = result.startIndex;
      // result.endIndex = result.endIndex;
    }
    // case 2: user select 'hh:mm'
    else if (filterType && filterTypeLower === TIME_SETTING_TYPE.EpochTimeLowerCase) {
      if (!timeRangeFilterValues.timestamp) return result;
      result.startIndex = timeRangeFilterValues.timestamp.start;
      result.endIndex = timeRangeFilterValues.timestamp.end;
      return epochTime(result, startValue, endValue);

      // if (timeRangeFilterValues && Helper.isNumberic(timeRangeFilterValues.timestamp.start)) {
      //   result.startIndex = Helper.setTimeOnDate(timeRangeFilterValues.timestamp.start, startTimeParsed.hours, startTimeParsed.minutes);
      // }
      // // case start value equal 0
      // if (timeRangeFilterValues && Helper.isNumberic(timeRangeFilterValues.timestamp.start)) {
      //   const durationSec = (timeRangeFilterValues.timefloat.end - timeRangeFilterValues.timefloat.start) || 0;
      //   const startTimestamp = timeRangeFilterValues.timestamp.start;
      //   const endTimestamp = moment(timeRangeFilterValues.timestamp.start).add('second', durationSec).valueOf();
      //   result.endIndex = Helper.setEndTimeOnDate(startTimestamp, endTimestamp, startValue, endValue);
      // }
    }
    return result;
  }

  filterInputData(inputData, startIndex, endIndex, channelMappingName) {
    const result = {
      columns: [],
      values: [],
    };
    if (inputData) {
      result.columns = [...inputData.columns];
      let channelIndex = null;
      if (channelMappingName === TIMEFLOAT_CHANNEL_NAME || channelMappingName === FRACPRO_TIMEFLOAT_CNAME || channelMappingName === EQUIPMENT_TIMEFLOAT_CNAME) {
        channelIndex = result.columns.findIndex(value => value === TIMEFLOAT_CHANNEL_NAME || value === FRACPRO_TIMEFLOAT_CNAME || value === EQUIPMENT_TIMEFLOAT_CNAME);
      } else if (channelMappingName === DATETIME_CHANNEL_NAME) {
        channelIndex = result.columns.findIndex(value => value === DATETIME_CHANNEL_NAME);
      }

      if (typeof channelIndex === 'number' && channelIndex >= 0) {
        if (typeof startIndex === 'number' && (typeof endIndex === 'number' || isNil(endIndex))) {
          // convert datetime to timestamp for filtering data
          if (channelMappingName === DATETIME_CHANNEL_NAME) {
            result.values = inputData.values.filter(dataRow => {
              const timestampValue = new Date(dataRow[channelIndex]).getTime();
              if (endIndex !== null && endIndex !== undefined) return timestampValue >= startIndex && timestampValue <= endIndex;
              else return timestampValue >= startIndex;
            });
          } else {
            result.values = inputData.values.filter(dataRow => {
                dataRow[channelIndex] >= startIndex && dataRow[channelIndex] <= endIndex
                if (endIndex !== null && endIndex !== undefined) return dataRow[channelIndex] >= startIndex && dataRow[channelIndex] <= endIndex;
                else return dataRow[channelIndex] >= startIndex;
              }
            );
          }
        } else {
          result.values = [...inputData.values];
        }
      }
    }
    return result;
  }

  convertTimeEquipment(defaultTime: number, value: number) {
    let result = null;
    defaultTime = defaultTime * 1;
    value = value * 1;
    if (typeof value === 'number' && !isNaN(value)) {
      result = value - defaultTime;
    }
    return result;
  }

  parseAnnotationsOption(inputComments: ITreatmentComment[], timeFormat: string, timeRangeFilterValues: ITimeRangeFilter, chart?: Highcharts.Chart) {
    const result = [];
    const clonedData = cloneDeep(inputComments);

    if (!isEmpty(clonedData) && timeRangeFilterValues && timeRangeFilterValues.timestamp) {
      if (timeFormat === TIME_SETTING_TYPE.OffsetTime) {
        clonedData.forEach(item => {
          item.jobtime = item.jobtime; // in sec
        });
      } else {
        const baseTime = Math.floor(timeRangeFilterValues.timestamp.start / 1000) || 0; // sec
        clonedData.forEach(item => {
          item.jobtime = (baseTime + item.jobtime) * 1000; // millisecond
        });
      }

      let minYAxis = 0;
      const padding = 0;
      if (chart && !isEmpty(chart.yAxis)) {
        minYAxis = chart.yAxis[0].getExtremes().min;
      }

      clonedData.forEach((item, index) => {
        const option = {
          x: item.jobtime,
          y: minYAxis + padding || 0,
          comment: item.comment
        };
        // only add point with comment is not an empty string
        if (typeof option.comment === 'string' && option.comment.trim() !== '') {
          result.push(option);
        }
      });
    }

    return result;
  }

  getDataScaleSeries(seriesData, queryString: string) {
    const result = { min: null, max: null };
    if (!isEmpty(seriesData) && queryString) {
      const minData: object = minBy(seriesData, queryString);
      const maxData: object = maxBy(seriesData, queryString);
      if (minData && minData.hasOwnProperty(queryString)) {
        result.min = minData[queryString];
      }
      if (maxData && maxData.hasOwnProperty(queryString)) {
        result.max = maxData[queryString];
      }
    }
    return result;
  }

  updateStartTimeOffset(dataValues, offsetTimeIndex = 1, timeOffset = 0) {
    if (!isEmpty(dataValues)) {
      for (let index = 0; index < dataValues.length; index++) {
        const dataRow = dataValues[index];
        dataRow[offsetTimeIndex] = dataRow[offsetTimeIndex] + timeOffset;
      }
    }
  }

  makeDiagnosticPoint(highChart: Highcharts.Chart, point: IDiagnosticPoint, keyOpt?: string): IDiagnosticPointDataChange {
    if (!keyOpt) keyOpt = 'diag';
    if (point && highChart) {
      const seriesObj = highChart.get(`${keyOpt}_${point.id}`) as Highcharts.Series;
      if (seriesObj) {
        seriesObj.setVisible(!!point.selected);
        if (point.selected) {
          const seriesDataPoint = seriesObj.data[0] as unknown as CustomDataPoint;
          const dataPoint = {
            groupId: seriesDataPoint.groupId,
            x: seriesObj.data[0].x
          };
          return dataPoint as IDiagnosticPointDataChange;
        }
      }
    }
    return null;
  }

}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services';
import { map } from 'rxjs/operators';

@Injectable()
export class TreatmentLogTemplateService {
  prefixPath = `/wells`;

  constructor(
    private apiService: ApiService
  ) { }

  getTemplates() {
    return this.apiService.get(`${this.prefixPath}/scatterPlotTemplates`).pipe(map(res => res.result));
  }

  createTemplate(body) {
    return this.apiService.post(`${this.prefixPath}/scatterPlotTemplates`, body);
  }

  updateTemplate(plotTemplateId, body) {
    let param = new HttpParams();
    param = param.set('plotTemplateId', plotTemplateId);

    return this.apiService.put(`${this.prefixPath}/scatterPlotTemplates/${plotTemplateId}`, body, null, null, param);
  }

  deleteTemplate(id) {
    return this.apiService.delete(`${this.prefixPath}/scatterPlotTemplates/${id}`);
  }

}

import { Helper } from "../../helpers/helper";
import { Chart } from "highcharts";
import { ITreatmentItem } from "../../models/treatment.model";

export class TreatmentPlotFDI {
  id: string;
  fdiStart: any;
  fdiEnd: any;
  peakFDIStart: any;
  peakFDIEnd: any;
  leakOffStart: any;
  leakOffEnd: any;
  wellId: number;
  treatmentId: number;
  color: string;
  cName: string;

  constructor({ fdiStart, fdiEnd, peakFDIStart, peakFDIEnd, leakOffStart, leakOffEnd, wellId, treatmentId, color, cName }) {
    this.id = `${wellId}_${treatmentId}`;
    this.fdiStart = fdiStart;
    this.fdiEnd = fdiEnd;
    this.peakFDIStart = peakFDIStart;
    this.peakFDIEnd = peakFDIEnd;
    this.leakOffStart = leakOffStart;
    this.leakOffEnd = leakOffEnd;
    this.wellId = wellId;
    this.treatmentId = treatmentId;
    this.color = color || Helper.randomHexColor();
    this.cName = cName;
  }
}

export interface TreatmentPlotFDIInfo {
  fdiEnd?: number;
  fdiStart?: number;
  netGainPress?: number;
  grossGainPress?: number;
  volToFrstResp?: number;
  volToFrstRespPercent?: number;
  avgSlope?: number;
  maxSlope?: number;
  avgFDIPressure?: number;
  maxFDIPressure?: number;
  preFDIPressure?: number;
}

export interface TreatmentPlotFDIParam {
  fdiEnd?: boolean;
  fdiStart?: boolean;
  netGainPress?: boolean;
  grossGainPress?: boolean;
  volToFrstResp?: boolean;
  volToFrstRespPercent?: boolean;
  avgSlope?: boolean;
  maxSlope?: boolean;
  avgFDIPressure?: boolean;
  maxFDIPressure?: boolean;
  preFDIPressure?: boolean;
}

export interface IInputFDIParam {
  highChartInstance: Chart;
  baseTreatmentDataTime; timeFormat; timezoneOffset;
  fdiInfo: TreatmentPlotFDI;
  yAxisIndex: number;
  isStaticFDI?: boolean;
  isAddStaticFDI?: boolean;
  treatmentInfo?: ITreatmentItem;
  startIndex: number;
  endIndex: number;
  treatmentStart: number;
  treatmentEnd: number;
  onDropHandler?: (event: any) => void;
  onTreatmentStartEndDropHandler?: (event: any) => void;
  onMouseOverHandler?: (event: any) => void;
  onDragHandler?: (event: any) => void;
}

export interface IFDIPayload {
  fdi: TreatmentPlotFDI[];
  treatmentEnd?: number;
  treatmentStart?: number;
}

export interface IFdiDropEventData {
  fdiId: string;
  fdiKeyValue: string;
  groupId: string;
  x: number;
}

export enum FDI_TYPES {
  treatmentStart = 'Treatment Start',
  treatmentEnd = 'Treatment End',
  fdiStart = 'FDI Start',
  fdiEnd = 'FDI End',
  peakFDIStart = 'Peak FDI Start',
  peakFDIEnd = 'Peak FDI End',
  leakOffStart = 'Leak Off Start',
  leakOffEnd = 'Leak Off End',
}

export const FDI_STATIC_MARKERS = {
  treatmentStart: 'Treatment Start',
  treatmentEnd: 'Treatment End'
};

export const FDI_DYNAMIC_MARKERS = {
  fdiStart: 'FDI Start',
  fdiEnd: 'FDI End',
  peakFDIStart: 'Peak FDI Start',
  peakFDIEnd: 'Peak FDI End',
  leakOffStart: 'Leak Off Start',
  leakOffEnd: 'Leak Off End'
};

import { Injectable } from '@angular/core';

export interface IComparePadViewData {
  padName: string;
  isPadWell: boolean;
}

@Injectable()
export class TreatmentSettingsService {
  inputData: IComparePadViewData;

  constructor() { }

  setInputData(inputData: IComparePadViewData): void {
    this.inputData = inputData;
  }

  getInputData(): IComparePadViewData {
    return this.inputData;
  }

  resetData() {
    this.inputData = null;
  }

}

import * as _ from 'lodash';
import {COUNTRIES} from '../helpers/countries.constants';

export interface ICountry {
  id: number;
  alpha2Code: string;
  englishName: string;
  localName: string;
  region: string;
  countryCallingCode: number;
  offset?: number;
}

export class CountryModel implements ICountry {
  id: number;
  alpha2Code: string;
  englishName: string;
  localName: string;
  region: string;
  countryCallingCode: number;
  offset?: number;

  constructor() {
    this.id = null;
    this.alpha2Code = null;
    this.englishName = null;
    this.localName = null;
    this.region = null;
    this.countryCallingCode = null;
    this.offset = null;
  }


  static init(): CountryModel[] {
    let countries: CountryModel[];
    countries = COUNTRIES.map(g => this.create(g));
    return countries;
  }

  static create(data): CountryModel {
    return (new CountryModel()).fill(data);
  }

  fill(data): this {
    const self = this;

    _.each(data, (val, key) => {
      if (self.hasOwnProperty(key)) {
        self[key] = val;
      }
    });

    return self;
  }

}


import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Helper } from '../../../helpers/helper';
import { IOffsetWellCompare, OffsetWellCompare } from '../../../models/offset-well-compare.model';
import { IWellItemPaging } from '../../../models/well.model';
import { TreatmentService } from '../../../services/treatment.service';

@Component({
  selector: 'app-offset-well-compare',
  templateUrl: './offset-well-compare.component.html',
  styleUrls: ['./offset-well-compare.component.scss']
})
export class OffsetWellCompareComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  form: FormGroup;
  selectedWell: AbstractControl;
  selectedTreatment: AbstractControl;
  selectedAxis: AbstractControl;
  compStartTime: AbstractControl;
  compEndTime: AbstractControl;
  selectedChannel: AbstractControl;
  isRealtime: AbstractControl;
  startTime: Date;
  endTime: Date;
  startTimeStr: any = '';
  endTimeStr: any = '';
  offsetTimeZone: number = new Date().getTimezoneOffset() * 60 * 1000; // in milliseconds
  isProducingWell: AbstractControl;

  listTreatments: any[] = [];
  realtimeChannels: any[] = [];

  axisOptions = [
    { id: 'Leftmost', text: 'Left 1' },
    { id: 'Left', text: 'Left 2' },
    { id: 'Right', text: 'Right 1' },
    { id: 'Rightmost', text: 'Right 2' },
  ];

  @Input()
  modelSetting: IOffsetWellCompare;
  @Input()
  curWellId: number;
  @Input()
  curTreatmentId: number;
  @Input()
  listWells: IWellItemPaging[] = [];

  constructor(
    private treatmentService: TreatmentService,
    protected dialogRef: NbDialogRef<any>,
  ) {
    this.createForm();
  }

  ngOnInit() {
    if (this.modelSetting) {
      const selectedWell = this.listWells.find(item => item.wellId === this.modelSetting.wellId);
      if (selectedWell) {
        this.selectedWell.setValue(selectedWell);
        this.onChangeWell(selectedWell);
      }
    } else {
      if (!isEmpty(this.listWells)) {
        // default select current well
        if (this.curWellId) {
          const selectedWell = this.listWells.find(item => item.wellId === this.curWellId * 1);
          if (selectedWell) {
            this.selectedWell.setValue(selectedWell);
            this.onChangeWell(selectedWell);
          }
        } else { // default set first item
          this.selectedWell.setValue(this.listWells[0]);
          this.onChangeWell(this.listWells[0]);
        }
      }
    }

    if (!isEmpty(this.axisOptions)) {
      this.selectedAxis.setValue(this.axisOptions[0]);
    }
  }

  private onChangeWell(selectedWell) {
    this.selectedWell.setValue(selectedWell);
    this.treatmentService.getListTreatment(selectedWell.wellId).subscribe(res => {
      this.listTreatments = res.result;
      const selectedTreatment = !this.modelSetting || !this.modelSetting.treatmentId ?
        this.listTreatments[0] :
        this.listTreatments.find(item => item.id === this.modelSetting.treatmentId);
      if (selectedTreatment) {
        this.selectedTreatment.setValue(selectedTreatment);
        this.onChangeTreatment();
      }
    });
  }

  private onChangeTreatment() {
    if (!this.selectedWell.value.wellId || !this.selectedTreatment.value.id) return;
    this.getTreatmentTimeRange(this.selectedWell.value.wellId, this.selectedTreatment.value.id);
    this.getChannels(this.selectedWell.value.wellId, this.selectedTreatment.value.id).subscribe(res => {
      this.realtimeChannels = res;
      this.selectedChannel.setValue('');
    });
  }

  ngOnDestroy() {
    Helper.unsubscribe(this.subscriptions);
  }

  close() {
    this.dialogRef.close();
  }

  createForm() {
    this.form = new FormGroup({
      selectedWell: new FormControl(null, [Validators.required]),
      selectedTreatment: new FormControl(null, [Validators.required]),
      selectedAxis: new FormControl('', [Validators.required]),
      compStartTime: new FormControl('', [Validators.required]),
      compEndTime: new FormControl('', [Validators.required]),
      selectedChannel: new FormControl([], [Validators.required]),
      isRealtime: new FormControl(false, [Validators.required]),
      isProducingWell: new FormControl(false, [Validators.required])
    });
    this.selectedWell = this.form.controls['selectedWell'];
    this.selectedTreatment = this.form.controls['selectedTreatment'];
    this.selectedAxis = this.form.controls['selectedAxis'];
    this.compStartTime = this.form.controls['compStartTime'];
    this.compEndTime = this.form.controls['compEndTime'];
    this.selectedChannel = this.form.controls['selectedChannel'];
    this.isRealtime = this.form.controls['isRealtime'];
    this.isProducingWell = this.form.controls['isProducingWell'];
  }

  submitForm() {
    // submit form
    if (this.form.valid) {

      const startTime = new Date(this.compStartTime.value).getTime() - this.offsetTimeZone;
      const endTime = new Date(this.compEndTime.value).getTime() - this.offsetTimeZone;
      const offwetWellCompare = new OffsetWellCompare(
        this.selectedWell.value.wellId,
        this.selectedWell.value.wellName,
        this.selectedTreatment.value.id,
        startTime,
        endTime,
        this.selectedAxis.value.id,
        this.selectedChannel.value,
        this.isRealtime.value,
        this.selectedTreatment.value.treatmentNumber,
        this.selectedTreatment.value.dataType,
        this.isProducingWell.value,
        this.selectedTreatment.value.treatmentName,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      );
      this.dialogRef.close(offwetWellCompare);
    }
  }

  onSelectWell(eventData) {
    if (!eventData || !eventData.id) return;
    const selectedWell = this.listWells.find(item => item.wellId === eventData.id);
    if (!selectedWell) return;
    this.onChangeWell(selectedWell);
  }

  onSelectTreatment(eventData) {
    const treatment = this.listTreatments.find(item => item.id === eventData.id);
    if (treatment) {
      this.selectedTreatment.setValue(treatment);
    }
    if (this.selectedWell.value.wellId && treatment.id) {
      // get list channels
      this.getTreatmentTimeRange(this.selectedWell.value.wellId, treatment.id);
      const subRes = this.getChannels(this.selectedWell.value.wellId, treatment.id).subscribe(res => {
        this.realtimeChannels = res;
      });
      this.subscriptions.push(subRes);
    }
  }

  onSelectAxis(eventData) {
    if (eventData.id) {
      this.selectedAxis.setValue(eventData);
    }
  }

  getTreatmentTimeRange(wellId, treatmentId) {
    if (wellId && treatmentId) {
      this.treatmentService.getTreatmentDetailWebApi(wellId, treatmentId).toPromise().then(res => {
        if (res && res.result) {
          this.startTimeStr = formatDate(res.result.timeStart * 1000, 'M/d/yyyy, h:mm aaa', 'en-US', 'UTC');
          this.endTimeStr = formatDate(res.result.timeEnd * 1000, 'M/d/yyyy, h:mm aaa', 'en-US', 'UTC');
          this.startTime = Helper.convertDateToUTC(new Date(res.result.timeStart * 1000));
          this.endTime = Helper.convertDateToUTC(new Date(res.result.timeEnd * 1000));
          // set time range model
          this.compStartTime.setValue(Helper.convertDateToUTC(new Date(res.result.timeStart * 1000)));
          if (res.result.timeEnd >= res.result.timeStart) {
            this.compEndTime.setValue(Helper.convertDateToUTC(new Date(res.result.timeEnd * 1000)));
          } else {
            this.compEndTime.setValue(Helper.convertDateToUTC(new Date(res.result.timeStart * 1000)));
          }
        }
      });
    }
  }

  getChannels(wellId, treatmentId) {
    return this.treatmentService.getRealtimeChannels(wellId, treatmentId).pipe(map(response => {
      return Helper.getList(response, 'result.items');
    }));
  }

  selectChannel(eventData: { id: string; text: string }) {
    if (eventData && eventData.text) {
      const channel = this.realtimeChannels.find(item => item.name === eventData.text);
      if (channel) {
        this.selectedChannel.setValue([channel]);
      }
    }
  }
}

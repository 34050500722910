import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from '../select/select-item';
import { compact, orderBy, findIndex, remove, uniq, isEmpty } from 'lodash';
import { ToasterService } from '../../services/toaster.service';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface IDesignActualItem {
  id: string;
  text: string;
  designValue: string;
  actualValue: string;
}

interface IDesignActualModel {
  groupOption: string;
  selected: IDesignActualItem[]
}

export interface IModelSelectParameters {
  selected: SelectItem[],
  designActual: IDesignActualModel
}

@Component({
  selector: 'app-select-parameters',
  templateUrl: './select-parameters.component.html',
  styleUrls: ['./select-parameters.component.scss']
})
export class SelectParametersComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  inputMode: boolean = false;
  optionsOpened: boolean = false;

  public options: Array<SelectItem> = [];
  public pressureOptions: Array<SelectItem> = [];
  public slurryRateOptions: Array<SelectItem> = [];
  public massOptions: Array<SelectItem> = [];
  public volumeOptions: Array<SelectItem> = [];
  public proppantConcOptions: Array<SelectItem> = [];
  public fdiOptions: Array<SelectItem> = [];
  public timeOptions: Array<SelectItem> = [];
  public treatmentOptions: Array<SelectItem> = [];

  public itemObjects: Array<SelectItem> = [];
  public pressureItemObjects: Array<SelectItem> = [];
  public slurryRateObjects: Array<SelectItem> = [];
  public massObjects: Array<SelectItem> = [];
  public volumeObjects: Array<SelectItem> = [];
  public proppantConcObjects: Array<SelectItem> = [];
  public fdiObjects: Array<SelectItem> = [];
  public timeObjects: Array<SelectItem> = [];
  public treatmentObjects: Array<SelectItem> = [];

  private _disabled: boolean = false;
  public activeOption: SelectItem[] = [];

  public itemEmpty: SelectItem = new SelectItem('');

  public designActualOptions: IDesignActualItem[] = [
    { designValue: 'desPumpTime', actualValue: 'totalPumpTime', text: 'Pump Time', id: 'Pump Time' },
    { designValue: 'desCleanVol', actualValue: 'baseFluidVol', text: 'Clean Vol', id: 'Clean Vol' },
    { designValue: 'desSlurryVol', actualValue: 'totalVolume', text: 'Slurry Vol', id: 'Slurry Vol' },
  ];
  designActualModel: IDesignActualModel = {
    groupOption: 'treatment',
    selected: []
  };
  groupOptionControl: FormControl = new FormControl('treatment');

  alwaysActiveGroupName = ['Groups'];
  searchVal: string = '';
  groupName: string = 'Pressure';
  isXAxis: boolean = false;

  @ViewChild('searchInput') searchElement: ElementRef;

  @Input() public idField: string = 'id';
  @Input() public textField: string = 'text';
  @Input() public childrenField: string = 'children';
  @Input() public allowEmpty: boolean = true;
  @Input() public placeholder: string = '';
  @Input() public multiple: boolean = false;
  @Input() public maxItems: number;
  @Input() public addNewItem: boolean = false;
  @Input() public isFullWidth: boolean = false;
  @Input() public sortSelect: boolean = true;
  @Input() public isMultiCompare: boolean = true;
  @Input()
  set isXAxisPar(value) {
    this.isXAxis = value;
    if (this.isXAxis) {
      this.groupName = 'Order'.concat(',', this.alwaysActiveGroupName.join(','));
    }
  }

  @Input()
  set activeDesignActual(value) {
    if (value) {
      this.designActualModel.selected = [...value];
    }
  }

  @Input()
  set treatmentItem(value: Array<any>) {
    if (!value) {
      this.treatmentObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.treatmentObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.treatmentObjects = orderBy(this.treatmentObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  set isipItem(value: Array<any>) {
    if (!value) {
      this.itemObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.itemObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.itemObjects = orderBy(this.itemObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  set pressureItem(value: Array<any>) {
    if (!value) {
      this.pressureItemObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.pressureItemObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.pressureItemObjects = orderBy(this.pressureItemObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  set slurryRateItem(value: Array<any>) {
    if (!value) {
      this.slurryRateObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.slurryRateObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.slurryRateObjects = orderBy(this.slurryRateObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  set massItem(value: Array<any>) {
    if (!value) {
      this.massObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.massObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.massObjects = orderBy(this.massObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  set volumeItem(value: Array<any>) {
    if (!value) {
      this.volumeObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.volumeObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.volumeObjects = orderBy(this.volumeObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  set timeItem(value: Array<any>) {
    if (!value) {
      this.timeObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.timeObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.timeObjects = orderBy(this.timeObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  set proppantConcItem(value: Array<any>) {
    if (!value) {
      this.proppantConcObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.proppantConcObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.proppantConcObjects = orderBy(this.proppantConcObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  set fdiItem(value: Array<any>) {
    if (!value) {
      this.fdiObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.fdiObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.fdiObjects = orderBy(this.fdiObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  public set active(selectedItems: Array<any>) {
    if (!isEmpty(selectedItems)) {
      selectedItems = compact(selectedItems);
      const areItemsStrings = typeof selectedItems[0] === 'string';
      this.activeOption = selectedItems.map((item: any) => {
        const data = areItemsStrings ? item : { id: item[this.idField], text: item[this.textField], prefix: item.prefix };
        return new SelectItem(data);
      });
    }
  }

  public get active(): Array<any> {
    return this.activeOption;
  }

  @Input()
  public set fakeOpen(open: boolean) {
    if (!!open) {
      this.open();
    } else {
      this.clickOutside();
    }
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
    if (this._disabled === true) {
      this.clickOutside();
    }
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Output() public selected: EventEmitter<any> = new EventEmitter();
  @Output() public removed: EventEmitter<any> = new EventEmitter();
  @Output() public onModelChange: EventEmitter<IModelSelectParameters> = new EventEmitter();
  @Output() public onGroupsChange: EventEmitter<string> = new EventEmitter();

  constructor(
    private toasterService: ToasterService,
    private ref: ChangeDetectorRef,
    private element: ElementRef,
    private accordionConfig: NgbAccordionConfig
  ) {
    this.accordionConfig.closeOthers = false;
    this.groupName = this.groupName.concat(',', this.alwaysActiveGroupName.join(','));
  }

  ngOnInit(): void {
    this.groupOptionControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.designActualModel.groupOption = val;
        this.onModelChange.emit({
          selected: this.activeOption,
          designActual: this.designActualModel
        })
        this.onGroupsChange.emit(val);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  isActive(value: SelectItem): boolean {
    const item = this.activeOption.find(i => i.id === value.id);
    return !!item;
  }

  matchClick(e: any) {
    if (this._disabled === true) {
      return;
    }
    this.inputMode = !this.inputMode;
    if (!!this.inputMode) {
      this.open();
    } else {
      this.clickOutside();
    }
  }

  open() {
    this.options = this.itemObjects.filter(item => item);
    this.pressureOptions = this.pressureItemObjects.filter(item => item);
    this.slurryRateOptions = this.slurryRateObjects.filter(item => item);
    this.massOptions = this.massObjects.filter(item => item);
    this.volumeOptions = this.volumeObjects.filter(item => item);
    this.proppantConcOptions = this.proppantConcObjects.filter(item => item);
    this.fdiOptions = this.fdiObjects.filter(item => item);
    this.timeOptions = this.timeObjects.filter(item => item);
    this.treatmentOptions = this.treatmentObjects.filter(item => item);

    this.optionsOpened = true;
    this.ref.detectChanges();
    if (!this.multiple) {
      const itemActive = this.element.nativeElement.querySelector('.ul-select-choice .ul-select-choice-row.active .dropdown-item');
      if (itemActive) {
        itemActive.focus();
      }
    }

    // find active accordion id
    this.groupName = this.findActiveGroups();

    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    });
  }

  public clickOutside(): void {
    this.inputMode = false;
    this.optionsOpened = false;
  }

  public inputEvent(e: any) {
    this.searchVal = e.target.value.trim();
    if (this.searchVal) {
      this.options = this.itemObjects.filter((item: any) => (!this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
      this.pressureOptions = this.pressureItemObjects.filter((item: any) => (!this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
      this.slurryRateOptions = this.slurryRateObjects.filter((item: any) => (!this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
      this.massOptions = this.massObjects.filter((item: any) => (!this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
      this.volumeOptions = this.proppantConcObjects.filter((item: any) => (!this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
      this.proppantConcOptions = this.proppantConcObjects.filter((item: any) => (!this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
      this.fdiOptions = this.fdiObjects.filter((item: any) => (!this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
      this.timeOptions = this.timeObjects.filter((item: any) => (!this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
      this.treatmentOptions = this.treatmentObjects.filter((item: any) => (!this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));

    } else {
      this.options = this.itemObjects.filter((item: any) => !this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
      this.pressureOptions = this.pressureItemObjects.filter((item: any) => !this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
      this.slurryRateOptions = this.slurryRateObjects.filter((item: any) => !this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
      this.volumeOptions = this.volumeObjects.filter((item: any) => !this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
      this.massOptions = this.massObjects.filter((item: any) => !this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
      this.proppantConcOptions = this.proppantConcObjects.filter((item: any) => !this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
      this.fdiOptions = this.fdiObjects.filter((item: any) => !this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
      this.timeOptions = this.timeObjects.filter((item: any) => !this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
      this.treatmentOptions = this.treatmentObjects.filter((item: any) => !this.multiple || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
    }
  }

  selectMatch(value, e: Event = void 0): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.multiple) {
      const isSelectedItem = this.activeOption.find(item => item.id === value.id);
      if (isSelectedItem) {
        remove(this.activeOption, (item => item.id === value.id));
      } else {
        if (this.maxItems) {
          if (this.activeOption.length < this.maxItems) {
            this.activeOption.push(value);
          } else {
            this.toasterService.showError(`The Items must be at less than or equal to ${this.maxItems}`);
          }
        } else {
          this.activeOption.push(value);
        }
      }
      // emit selected items
      this.selected.emit(this.activeOption);
    } else {
      this.activeOption = [value];
      this.selected.emit(value);
    }
    this.onModelChange.emit({
      selected: this.activeOption,
      designActual: this.designActualModel
    })
  }

  findActiveGroups() {
    let activeGroups = this.activeOption.map(item => {
      return this.checkGroupName(item.text);
    });
    activeGroups = uniq(activeGroups);
    activeGroups = activeGroups.concat(this.alwaysActiveGroupName);
    if (this.designActualModel && !isEmpty(this.designActualModel.selected)) {
      activeGroups = activeGroups.concat(['DesignActual']);
    }

    return activeGroups.join(',');
  }

  selectDesignActual(value, e: Event = void 0): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const isSelectedItem = this.designActualModel.selected.find(item => item.id === value.id);
    if (isSelectedItem) {
      this.designActualModel.selected = [];
    } else {
      this.designActualModel.selected = [value];
    }

    this.onModelChange.emit({
      selected: this.activeOption,
      designActual: this.designActualModel
    })
  }

  private checkGroupName(value): string {
    if (value === 'ISIP(final)' || value === 'ISIP Gradient (Final)' || value === 'ISIP (Early)' || value === 'ISIP Gradient (Early)' || value === 'Btm Pressure (ISIP)') {
      return 'ISIP';
    } else if (value === 'Slurry Rate (Maximum)' || value === 'Slurry Rate (Average)' || value === 'Slurry Rate (Ballseat)' || value === 'Slurry Rate (Breakdown)' || value === 'Slurry Rate (Pump Down)') {
      return 'SlurryRate';
    } else if (value === 'Mass (Total Proppant)') {
      return 'Mass';
    } else if (value === 'Volume (Slurry)' || value === 'Volume (Clean)' || value === 'Volume (Ballseat)' || value === 'Volume (Breakdown)' || value === 'Volume (Pumpdown)') {
      return 'Volume';
    } else if (value === 'Proppant Conc (Average)' || value === 'Proppant Conc (Maximum)') {
      return 'ProppantConc';
    } else if (value === 'Net Gain' || value === 'Gross Gain' || value === 'VFR' || value === 'Avg FDI Intensity' || value === '%VFR' || value === 'Max FDI Intensity') {
      return 'FDI';
    } else if (value == 'Time (Pumping)') {
      return 'Time';
    } else if (value === 'Pressure (Average)' || value === 'Pressure (Maximum)' || value === 'Pressure (Ballseat)' || value === 'Pressure (Breakdown)' || value === 'Pressure (Well Opening)' || value === 'Btm Pressure Max' || value === 'Btm Pressure Avg') {
      return 'Pressure';
    } else if (value && value.indexOf('Order') === 1) {
      return 'Order';
    } else {
      return '';
    }
  }

  elementFocus(realtime: boolean) {
    let element: any;
    if (realtime) {
      element = this.element.nativeElement.querySelector('.ul-select-realtime .ul-select-choice');
    } else {
      element = this.element.nativeElement.querySelector('.ul-select-fracpro .ul-select-choice');
    }
    this.ref.detectChanges();
    if (element) {
      element.focus();
    }
  }

}

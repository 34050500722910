import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forEach } from 'lodash';
import { ApiService } from '../../core/services/api.service';

@Injectable()
export class PadService {
  prefixPath = '/wells/pad';

  constructor(private apiService: ApiService) {

  }

  getListPads(paramObject: any, page: number, pageSize: number, sortField?: string, sortType: boolean = true) {
    let param = new HttpParams();
    if (paramObject) {
      forEach(paramObject, (item, key) => {
        if (item) {
          param = param.set(key.toString(), item);
        }
      });
    }
    if (page) {
      param = param.set('page', page.toString());
    }
    if (pageSize) {
      param = param.set('pageSize', pageSize.toString());
    }
    if (sortField) {
      param = param.set('sortField', sortField);
      param = param.set('sortType', sortType.toString());
    }
    return this.apiService.get(`${this.prefixPath}`, param);
  }

  getPadByName(padName: string) {
    let param = new HttpParams();
    if (padName) {
      param = param.set('padName', padName.toString());
    }

    return this.apiService.get(`${this.prefixPath}/detail`, param);
  }

  deletePads(paramObject: number[], finalize?: VoidFunction): Observable<any> {
    let param = new HttpParams();
    forEach(paramObject, (id, key) => {
      if (id) {
        param = param.append('padIds', id.toString());
      }
    });
    return this.apiService.deleteParam(`${this.prefixPath}`, param, finalize);
  }

  deletePostPads(param: {data: string[]}, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/deletePadName`, param, finalize, { requestTimeout: 30 * 60 * 1000 }); // 30 min request timeout
  }

}

import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';

export interface IWellsCompareInputData {
  compareType: string;
  parameterSelect: any[];
  channelSelect: any[];
  treatmentSelect: any[];
  wellSelect: any[];
};

@Injectable()
export class WellsCompareDataService {
  inputData: IWellsCompareInputData;
  channelList: any[];
  parameterList: any[];
  treatmentList: any[];
  treatmentSelectDetails: any = {};


  constructor() { }

  setInputData(input: IWellsCompareInputData): void {
    this.inputData = {...input};
  }

  getInputData() {
    return this.inputData;
  }

  resetInputData() {
    this.inputData = null;
  }

  resetData() {
    this.inputData = null;
    this.channelList = [];
    this.parameterList = [];
    this.treatmentList = [];
    this.treatmentSelectDetails = {};
  }

  setChannelList(data) {
    this.channelList = data;
  }

  getChannelList() {
    return this.channelList;
  }

  setParameterList(data) {
    this.parameterList = data;
  }

  getParameterList() {
    return this.parameterList;
  }

  setTreatmentList(data) {
    this.treatmentList = data;
  }

  getTreatmentList() {
    return this.treatmentList;
  }

  toGroupParametersList(listParameters) {
    const result = {
      listISIPOptions: [],
      listPressureOptions: [],
      listSlurryRateOptions: [],
      listMassOptions: [],
      listVolumeOptions: [],
      listProppantConcOptions: [],
      listFDIOptions: [],
      listTimeOptions: []
    }
    if (!isEmpty(listParameters)) {
      listParameters.splice(0, 0, '');
      // ISIP
      result.listISIPOptions.push({ id: listParameters[32].id, text: "ISIP (Final)" });
      result.listISIPOptions.push({ id: listParameters[31].id, text: "ISIP Gradient (Final)" });
      result.listISIPOptions.push({ id: listParameters[37].id, text: "ISIP (Early)" });
      result.listISIPOptions.push({ id: listParameters[45].id, text: "ISIP Gradient (Early)" });
      result.listISIPOptions.push({ id: listParameters[46].id, text: "Btm Pressure (ISIP)" });
      // Pressure
      result.listPressureOptions.push({ id: listParameters[25].id, text: "Pressure (Average)" });
      result.listPressureOptions.push({ id: listParameters[8].id, text: "Pressure (Maximum)" });
      result.listPressureOptions.push({ id: listParameters[21].id, text: "Pressure (Ballseat)" });
      result.listPressureOptions.push({ id: listParameters[17].id, text: "Pressure (Breakdown)" });
      result.listPressureOptions.push({ id: listParameters[4].id, text: "Pressure (Well Opening)" });
      result.listPressureOptions.push({ id: listParameters[49].id, text: "Btm Pressure (Maximum)" });
      result.listPressureOptions.push({ id: listParameters[50].id, text: "Btm Pressure (Average)" });
      // Slurry
      result.listSlurryRateOptions.push({ id: listParameters[9].id, text: "Slurry Rate (Maximum)" });
      result.listSlurryRateOptions.push({ id: listParameters[26].id, text: "Slurry Rate (Average)" });
      result.listSlurryRateOptions.push({ id: listParameters[22].id, text: "Slurry Rate (Ballseat)" });
      result.listSlurryRateOptions.push({ id: listParameters[18].id, text: "Slurry Rate (Breakdown)" });
      result.listSlurryRateOptions.push({ id: listParameters[44].id, text: "Slurry Rate (Pump Down)" });
      // Mass
      result.listMassOptions.push({ id: listParameters[29].id, text: "Mass (Total Proppant)" });
      // Volume
      result.listVolumeOptions.push({ id: listParameters[28].id, text: "Volume (Slurry)" });
      result.listVolumeOptions.push({ id: listParameters[27].id, text: "Volume (Clean)" });
      result.listVolumeOptions.push({ id: listParameters[23].id, text: "Volume (Ballseat)" });
      result.listVolumeOptions.push({ id: listParameters[19].id, text: "Volume (Breakdown)" });
      result.listVolumeOptions.push({ id: listParameters[24].id, text: "Volume (Pumpdown)" });
      result.listVolumeOptions.push({ id: listParameters[51].id, text: "Volume (Design Rate)" });
      // Proppant Conc
      result.listProppantConcOptions.push({ id: listParameters[11].id, text: "Proppant Conc (Average)" });
      result.listProppantConcOptions.push({ id: listParameters[10].id, text: "Proppant Conc (Maximum)" });
      // FDI
      result.listFDIOptions.push({ id: listParameters[55].id, text: "VFR"});
      result.listFDIOptions.push({ id: listParameters[56].id, text: "%VFR"});
      result.listFDIOptions.push({ id: listParameters[53].id, text: "Net Pressure Gain"});
      result.listFDIOptions.push({ id: listParameters[54].id, text: "Gross Pressure Gain"});
      result.listFDIOptions.push({ id: listParameters[59].id, text: "Pre FDI Pressure Trend"});
      result.listFDIOptions.push({ id: listParameters[60].id, text: "Avg FDI Pressure Trend"});
      result.listFDIOptions.push({ id: listParameters[61].id, text: "Max FDI Pressure Trend"});
      result.listFDIOptions.push({ id: listParameters[57].id, text: "Avg FDI Intensity"});
      result.listFDIOptions.push({ id: listParameters[58].id, text: "Max FDI Intensity"});
      // Time
      result.listTimeOptions.push({ id: listParameters[5].id, text: "Time (Pumping)" });
      result.listTimeOptions.push({ id: listParameters[52].id, text: "Time (Design Rate)" });
    }
    return result;
  }

  setTreatmentSelectedDetail(data) {
    this.treatmentSelectDetails = data;
  }

  getTreatmentSelectDetails() {
    return this.treatmentSelectDetails;
  }
}

export const OBJ_FDI_PARAMS = {
  NetGainPress: "NetGainPress",
  GrossGainPress: "GrossGainPress",
  VolToFrstResp: "VolToFrstResp",
  VolToFrstRespPercent: "VolToFrstRespPercent",
  AvgSlope: "AvgSlope",
  MaxSlope: "MaxSlope",
  PreFdiPressure: "PreFdiPressure",
  AvgFdiPressure: "AvgFdiPressure",
  MaxFdiPressure: "MaxFdiPressure"
}

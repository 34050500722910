import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

declare var window: any;

export interface IAppConfig {
  baseURL: string
}

@Injectable()
export class AppInitService {

  constructor(
    private http: HttpClient,
  ) { }

  init() {
    return this.http.get('../app-config.json').pipe(map(result => {
      // set app config in global variable window
      window.appConfig = result;
      return result;
    })).toPromise();
  }
}

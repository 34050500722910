import { Injectable } from '@angular/core';

const STORAGE_KEY = 'fracpro_channel';
const STORAGE_TENANT = 'company';

@Injectable()
export class FracproChannelStorageService {

  constructor() {}

  setFracproChannels(value) {
    if (!value) return;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
  }

  getFracproChannels() {
    try {
      const stringData = localStorage.getItem(STORAGE_KEY);
      return JSON.parse(stringData);
    } catch (error) {
      return null;
    }
  }

  removeFracproChannel() {
    localStorage.removeItem(STORAGE_KEY);
  }

  clearFracproChannels() {
    for (const obj in localStorage) {
      if (localStorage.hasOwnProperty(obj) && obj.includes(STORAGE_KEY)) {
        localStorage.removeItem(obj);
      }
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { IWellItemPaging } from '../models/well.model';

@Pipe({
  name: 'wellCompletionDate'
})
export class WellCompletionDatePipe implements PipeTransform {

  transform(completionDate: string, well: IWellItemPaging): any {
    const timeFormat = 'HH:mm, DD MMM YYYY';
    let formattedDate = moment(completionDate).utcOffset(0).format(timeFormat);
    if (well.timeZone) {
      const dayLight = well.dayLight || well.treatmentList&&well.treatmentList[0] ? well.treatmentList[0].dayLight : 0;
      const timezoneOffset = (well.timeZone + dayLight) / 60; // in minutes
      formattedDate = moment(completionDate).utcOffset(timezoneOffset).format(timeFormat);
    }
    return formattedDate;
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IGroupPlotTemplate, IPlotTemplate, IPlotTemplateResponse } from '../../models/plot-template.model';
import { NbDialogService } from '@nebular/theme';
import { PlotTemplateService } from '../../services';
import { IChannelSettingModel } from '../../models';
import { PLOT_TEMPLATE_TYPE, ChartTimeFormat } from '../../helpers/app.constants';
import { Subscription } from 'rxjs';
import { isEmpty, each } from 'lodash';
import { ConfirmTemplateModalComponent } from '../modals/confirm-template-modal/confirm-template-modal.component';

@Component({
  selector: 'app-plot-template-dropdown',
  templateUrl: './plot-template-dropdown.component.html',
  styleUrls: ['./plot-template-dropdown.component.scss']
})
export class PlotTemplateDropdownComponent implements OnInit {
  PLOT_TEMPLATE_TYPE = PLOT_TEMPLATE_TYPE;
  isOpen: boolean = false;
  isTemplateAction: boolean = false;
  plotTemplateToSave: IPlotTemplate;

  groupTemplates: IGroupPlotTemplate[] = [];
  templates: IPlotTemplateResponse[] = [];

  subscriptions: Subscription[] = [];

  @Input()
  set dataTemplates(templates: IPlotTemplateResponse[]) {
    if (!isEmpty(templates)) {
      this.templates = [];
      const groupsTpls = [];
      let flag = 0;
      let templateSelected: any;
      each(PLOT_TEMPLATE_TYPE, (value, key) => {
        // get templates by type
        const templatesByType = templates
          .filter(item => item.type === PLOT_TEMPLATE_TYPE[key])
          .map(item => {
            const result = { ...item };
            result.name = item.name ? item.name : `Template ${item.id}`;
            return result;
          });
        // check selected item
        if (this.selectedItem && flag === 0) {
          templateSelected = templatesByType.find(item => item.id === this.selectedItem.id);
          if (templateSelected) {
            flag++;
          }
        }
        // appends group template
        groupsTpls.push({
          name: key,
          templates: templatesByType
        });
        this.templates = this.templates.concat(templatesByType);
      });
      this.selectedItem = (flag !== 0) ? templateSelected : null;
      this.onEmitItemSelected(this.selectedItem);

      this.groupTemplates = groupsTpls;
      this.isTemplateAction = this.groupTemplates.filter(group => {
        return group.templates.findIndex(template => template.type === PLOT_TEMPLATE_TYPE.User || template.type === PLOT_TEMPLATE_TYPE.Company) !== -1;
      }).length > 0;
    } else {
      this.selectedItem = null;
      this.groupTemplates = [];
      this.isTemplateAction = false;
    }
  }

  @Input()
  set channelSettings(settings: IChannelSettingModel) {
    this.plotTemplateToSave = this.plotTemplateService.channelSettingsToPlotTemplate(settings);
  }
  @Input() companyId: number;
  @Input() wellId: number;
  @Input() timeFormat: ChartTimeFormat;
  @Input() commentType: string;
  @Input() isShowComments: boolean;
  @Input() isShowTooltip: boolean;

  @Input()
  onlySaveTemplateByCompany: boolean = false;

  @Input() loading: boolean;

  @Input()
  selectedItem: IPlotTemplateResponse;

  @Input()
  hideSaveButton: boolean = false;

  @Output() itemSelected: EventEmitter<IPlotTemplateResponse> = new EventEmitter();
  @Output() templatesChange: EventEmitter<IPlotTemplate[]> = new EventEmitter();

  constructor(
    private dialogService: NbDialogService,
    private plotTemplateService: PlotTemplateService
  ) { }

  ngOnInit() {
  }

  onEmitItemSelected(data): void {
    this.itemSelected.emit(data);
  }

  onEmitTemplatesChange(): void {
    this.templatesChange.emit();
  }

  openDropdown() {
    this.isOpen = !this.isOpen;
  }

  isSelected(item): boolean {
    if (this.selectedItem && this.selectedItem.id) {
      return item.id === this.selectedItem.id;
    }
    return false;
  }

  selectItem(event: Event, templateRes) {
    event.stopPropagation();
    if (!this.selectedItem || templateRes.id !== this.selectedItem.id) {
      const data = { ...templateRes };
      this.selectedItem = data;

      this.onEmitItemSelected(data);
      this.isOpen = false;
    }
  }

  deleteItem(event: Event, templateRes) {
    event.stopPropagation();
    if (templateRes && templateRes.id) {
      this.subscriptions.push(
        this.plotTemplateService
          .deleteByWell(this.wellId, templateRes.id)
          .subscribe(
            item => {
              this.onEmitTemplatesChange();
            }
          )
      );
    }
  }

  clickOutside() {
    this.isOpen = false;
  }

  saveTemplate() {

    this.dialogService.open(
      ConfirmTemplateModalComponent,
      {
        context: {
          hideMessage: this.onlySaveTemplateByCompany ? true : false,
          showAllWellsCheckbox: this.onlySaveTemplateByCompany ? false : true,
          defaultIsAllWells: this.onlySaveTemplateByCompany ? true : false,
          templates: this.templates,
          isScatterPlot: false
        }
      }
    )
      .onClose
      .subscribe(
        res => {
          if (res) {
            this.plotTemplateToSave.timeFormat = this.timeFormat;
            this.plotTemplateToSave.commentType = this.commentType;
            this.plotTemplateToSave.isShowComments = this.isShowComments;
            this.plotTemplateToSave.isShowTooltip = this.isShowTooltip;
            const payload: any = {
              name: res.name,
              type: PLOT_TEMPLATE_TYPE.User,
              isAll: res.isAllWells,
              content: this.plotTemplateToSave
            };
            if (res.isAllWells) {
              payload.type = PLOT_TEMPLATE_TYPE.Company;
            }
            const templateRes = this.templates.find(template => template.name === res.name);
            if (templateRes && templateRes.id) {
              this.updatePlotTempateByWeel(this.wellId, templateRes.id, payload);
            } else {
              this.createPlotTempateByWeel(this.wellId, payload);
            }
          }
        }
      );
  }

  private createPlotTempateByWeel(wellId, payload): void {
    this.subscriptions.push(
      this.plotTemplateService
        .createByWell(wellId, payload)
        .subscribe(
          item => {
            this.onEmitTemplatesChange();
          }
        )
    );
  }

  private updatePlotTempateByWeel(wellId, plotTemplateId, payload): void {
    this.subscriptions.push(
      this.plotTemplateService
        .updateByWell(wellId, plotTemplateId, payload)
        .subscribe(
          item => {
            this.onEmitTemplatesChange();
          }
        )
    );
  }

}

import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import { isEmpty, isObject, isNumber, isString, extend, forEach, groupBy, meanBy, uniqBy, lowerFirst, isNil } from 'lodash';
import { CHART_MESSAGES, EpochTimeParameters, DEFAULT_DESIGN_COLOR, DEFAULT_ACTUAL_COLOR, CHART_OPTION, TREATMENT_UNIT_TYPE } from '../../helpers/app.constants';
import { ILogDataState, ENUM_SCALE_TYPES } from './log-data-curve-parameter-chart.service';
import { ITreatmentItem } from '../../models/treatment.model';
import { Helper } from '../../helpers/helper';
import { SelectItem } from '../../components/select/select-item';
import { UnitSystemService } from '../unit-system.service';

type ScaleOption = ENUM_SCALE_TYPES.VerticalSection | ENUM_SCALE_TYPES.MeasuredDepth | ENUM_SCALE_TYPES.Treatment;

export interface IChartDataPoint {
  id?: string;
  name: string;
  treatmentName?: string;
  xValue: number;
  yValue: number;
  avgMDValue?: number;
  color?: string;
  unit?: string;
  shape?: string;
}

export interface IInputParamData {
  id: number;
  name: string;
  paramName: string;
  paramValue: number;
  paramUnit?: string;
  avgMDValue?: number;
}

export interface IAxisSetting {
  id: any;
  name: string;
  unit?: string;
  color?: string;
  shape?: string;
  min?: number;
  max?: number;
  dataKey?: string;
  type?: string;
  designValue?: string;
  actualValue?: string;
  designShape?: string;
  actualShape?: string;
  designColor?: string;
  actualColor?: string;
  timeStart?: number;
  wellId?: number;
  treatmentId?: number;
  treatmentNumber?: number;
  wellName?: string;
  measureType?: string;
}

export interface IScatterPlotChartSetting {
  xAxis: IAxisSetting;
  yAxis: IAxisSetting[];
  curveYAxis?: IAxisSetting[];
  designActualYAxis?: IAxisSetting[];
  fdiYAxis?: IAxisSetting[];
  scale?: ScaleOption[];
}

export interface IScatterChartInputState {
  xAxisMode?: string;
  itemsComparing: IAxisSetting[];
  xAxisSettings: IAxisSetting[];
  yAxisSettings: IAxisSetting[];
  chartSettings?: IScatterPlotChartSetting;
  inputData: IInputParamData[];
  logDataState?: ILogDataState;
  designActualData?: any;
  fdiOffsetWellsData?: IInputParamData[];
  groupOption?: string;
}

export interface IParsedChartOptions {
  xAxis: any[];
  yAxis: any[];
  seriesData: any[];
  tooltip?: any;
}

export enum ENUM_AXIS_TYPE {
  DesignActual = 'designActual'
}

@Injectable()
export class ScatterPlotParameterChartService {

  constructor(private unitSystemService: UnitSystemService) { }

  getDefaultOptions(xAxisMode?: string, isDarkMode?: boolean): Highcharts.Options {
    const options: Highcharts.Options = {
      chart: {
        style: {
          fontFamily: 'Open Sans'
        },
        backgroundColor: !isDarkMode ? '#ffffff' : '#181a1b',
        borderColor: !isDarkMode ? undefined : '#b1aba1',
        plotBackgroundColor: !isDarkMode ? '#ffffff' : '#181a1b',
        zoomType: 'x',
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        },
        spacing: [10, 5, 5, 5]
      },
      boost: {
        enabled: false
      },
      lang: {
        noData: CHART_MESSAGES.en.noData
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: [],
      yAxis: [],
      tooltip: {
        shared: true,
        shadow: false,
        useHTML: true,
        borderColor: '#000',
        borderRadius: 14,
        footerFormat: '</table>',
        valueDecimals: 2
      },
      legend: {
        itemDistance: 50,
        maxHeight: 88,
        margin: 5,
        itemMarginBottom: 0,
        itemMarginTop: 0,
        enabled: false,
        floating: false,
        verticalAlign: 'top',
        align: 'right',
        y: 5,
        itemStyle: {color: !isDarkMode ? '' : '#e8e6e3'},
        itemHoverStyle: { color: !isDarkMode ? '' : '#f5f5f5' },
        itemHiddenStyle: { color: !isDarkMode ? '#333333b3' : '#a7a7a7' }
      },
      series: [],
      time: {
        useUTC: CHART_OPTION.useUTC
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
            radius: 5
          },
          states: {
            inactive: {
              opacity: 1
            },
            hover: {
              lineWidth: 2
            }
          },
          turboThreshold: 0
        },
        line: {
          animation: false,
          findNearestPointBy: 'xy',
        }
      },
    };
    const tooltipOption = this.getTooltipOption(xAxisMode);
    options.tooltip = extend(options.tooltip, tooltipOption);

    return options;
  }

  getXAxisOptionDefault(axisTitle: string = '', type?: 'datetime', axisSetting?: Partial<IAxisSetting>, isReverted?: boolean, isDarkMode?: boolean) {
    if (!isReverted && axisSetting) {
      if (axisSetting.min > axisSetting.max) {
        isReverted = true;
      }
    }
    const option: Highcharts.XAxisOptions | any = {
      labels: {
        style: {
          color: !isDarkMode ? undefined : 'red'
        }
      },
      title: {
        text: axisTitle,
        useHTML: false,
        style: {
          wordWrap: 'break-word',
          width: 300,
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
          color: !isDarkMode ? '#333333' : '#a8a095'
        }
      },
      showEmpty: false,
      lineWidth: 1,
      tickWidth: 1,
      alignTicks: true,
      gridLineWidth: 1,
      showFirstLabel: true,
      showLastLabel: true,
      min: axisSetting ? (axisSetting.min > axisSetting.max ? axisSetting.max : axisSetting.min) : null,
      max: axisSetting ? (axisSetting.min > axisSetting.max ? axisSetting.min : axisSetting.max) : null,
      reversed: !!isReverted
    };
    if (type && type === 'datetime') {
      option.type = type;
      option.labels = {
        // tslint:disable-next-line:object-literal-shorthand
        formatter: function () {
          const utcTimestamp = new Date(this.value * 1000).getTime();
          return Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', utcTimestamp);
        }
      };
    }

    return option;
  }

  getXAxisOptionCategories(settings: IAxisSetting[], isHidden?: boolean, isDarkMode?: boolean) {
    const categories = [];
    if (settings && !isEmpty(settings)) {
      settings.forEach(item => {
        categories.push(item.name);
      });
    }
    const option: Highcharts.XAxisOptions = this.getXAxisOptionDefault('Treatment', undefined, undefined, undefined, isDarkMode);
    option.categories = categories;
    if (isHidden) {
      option.visible = false;
    }

    return option;
  }

  getYAxisOption(axisTitle: string = '', type?: 'datetime', isOpposite?: boolean, axisSetting?: Partial<IAxisSetting>, unsetColor = false) {
    const option: Highcharts.YAxisOptions = {
      showEmpty: false,
      gridLineWidth: 1,
      lineWidth: 1,
      tickWidth: 1,
      showFirstLabel: true,
      showLastLabel: true,
      title: {
        text: axisTitle,
        useHTML: false,
        style: {
          wordWrap: 'break-word',
          width: 300,
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
          color: unsetColor ? undefined : axisSetting.color
        }
      },
      opposite: !!isOpposite,
      min: axisSetting ? (axisSetting.min > axisSetting.max ? axisSetting.max : axisSetting.min) : null,
      max: axisSetting ? (axisSetting.min > axisSetting.max ? axisSetting.min : axisSetting.max) : null,
      reversed: (axisSetting && axisSetting.min > axisSetting.max),
      alignTicks: false,
      startOnTick: false,
      endOnTick: false,
      minPadding: 0,
    };
    if (type && type === 'datetime') {
      option.type = type;
      option.labels = {
        // tslint:disable-next-line:object-literal-shorthand
        formatter: function () {
          const utcTimestamp = new Date(this.value * 1000).getTime();
          return Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', utcTimestamp);
        }
      };
    }

    return option;
  }

  getSeriesData(yAxisIndex, dataSeries: number[] | any[], extraInfo?) {
    extraInfo = isObject(extraInfo) ? extraInfo : {};
    const seriesColor = extraInfo.seriesColor;
    const seriesName = extraInfo.seriesName;
    const seriesShape = isString(extraInfo.seriesShape) ? extraInfo.seriesShape.toLowerCase() : 'circle';
    const dataType = extraInfo.dataType ? extraInfo.dataType : 'scatter'
    const xAxisIndex = isNumber(extraInfo.xAxisIndex) ? extraInfo.xAxisIndex : 0
    let result = {
      id: seriesName,
      name: seriesName,
      type: dataType,
      data: dataSeries,
      color: seriesColor,
      marker: {
        symbol: seriesShape
      },
      yAxis: yAxisIndex,
      xAxis: xAxisIndex,
      zIndex: undefined
    };
    if (extraInfo.seriesIndex) {
      result.zIndex = extraInfo.seriesIndex
    }
    if (dataType === 'line') {
      result.zIndex = 1;
    }

    return result;
  }

  getTooltipOption(xAxisMode?: string, isEpochX?: boolean, isEpochY?: boolean, isMultiWells?: boolean) {
    const symbol = '●';
    let tooltipOptions = {};
    let prefixHeader = '';
    if (!isMultiWells) {
      prefixHeader = 'Treatment ';
    }
    let headerFormat = `<small>${prefixHeader}{series.name}</small><table>`;
    if (xAxisMode !== 'parameter') {
      tooltipOptions = {
        shared: true,
        useHTML: true,
        borderColor: '#000',
        formatter: function () {
          const tbRows = [];
          let treatmentName;
          this.points.forEach((activePoint, index) => {
            if (!treatmentName) {
              treatmentName = activePoint.point.treatmentName;
            }

            const tooltipPointMaker = `<span style="color:${activePoint.color}">${symbol}</span>`;
            const value = Highcharts.numberFormat(activePoint.y, 2)
            const dataRow = `
                <tr>
                  <td>${tooltipPointMaker} ${activePoint.series.name}: </td>
                  <td style="text-align: left"><b>${value}</b></td>
                </tr>
              `
            tbRows.push(dataRow);

            if (activePoint.point.isDesignActual) {
              const percentDev = (activePoint.point.actualValue - activePoint.point.designValue) / activePoint.point.designValue * 100;
              const devDataRow = `
                <tr>
                  <td>%Dev: </td>
                  <td style="text-align: left"><b>${Highcharts.numberFormat(percentDev, 2)}</b></td>
                </tr>
              `
              tbRows.push(devDataRow);
            }
          });

          return `
            <div>
            <small>${treatmentName ? treatmentName : ''}</small>
              <table>
                ${tbRows.join('')}
              </table>
            </div>
          `;
        }
      };
    } else {
      tooltipOptions = {
        headerFormat: headerFormat,
        pointFormatter: function () {
          const tooltipPointMaker = `<span style="color:${this.color}">${symbol}</span>`;
          const tooltipPointFormat = `
            <tr>
              <td>${tooltipPointMaker} ${this.xLabel}: </td>
              <td style="text-align: left"><b>${isEpochX ? Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x * 1000) : Highcharts.numberFormat(this.x, 2)}</b></td>
            </tr>
            <tr>
              <td>${tooltipPointMaker} ${this.yLabel}: </td>
              <td style="text-align: left"><b>${isEpochY ? Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.y * 1000) : Highcharts.numberFormat(this.y, 2)}</b></td>
            </tr>
          `;

          return tooltipPointFormat;
        }
      };
    }

    return tooltipOptions;
  }

  convertPos = (paramData, name?: string) => {
    const unitTypeKey = Object.keys(TREATMENT_UNIT_TYPE).find(key => {
      return paramData.paramName && key.toLowerCase() === paramData.paramName.toLowerCase()
    });
    const unitType = TREATMENT_UNIT_TYPE[unitTypeKey];
    let unit = paramData.paramUnit;
    if (!isNil(unitType)) {
      unit = this.unitSystemService.getUnit(unitType);
      if (!isNil(unit)) unit = `(${unit})`;
      else unit = paramData.paramUnit;
    }

    return {
      value: !isNil(unitType) ? this.unitSystemService.convert(paramData.paramValue, unitType) : (paramData.paramValue * 1),
      label: name ? `${name} ${unit || ''}` : `${paramData.paramName} ${unit || ''}`
    };
  }

  parseSeriesDataByCategories(itemsComparing: IAxisSetting[], yAxisSettings: IAxisSetting[], inputData: IInputParamData[], beginYAxisIndex = 0) {
    const seriesData = [];
    if (!isEmpty(itemsComparing) && !isEmpty(yAxisSettings) && !isEmpty(inputData)) {
      forEach(yAxisSettings, (ySetting, yAxisIndex) => {
        const seriesDataPoints = [];
        // create default data series
        let seriesNames = `${ySetting.name}`;
        forEach(itemsComparing, (itemComparing) => {
          let dataPoint = null;
          const paramDataByItemComparing = inputData.find(item => {
            return item.id === itemComparing.id && item.paramName === ySetting.id;
          });
          if (paramDataByItemComparing) {
            const dataPointConverted = this.convertPos(paramDataByItemComparing, ySetting.name);
            seriesNames = dataPointConverted.label;
            if (isNumber(dataPointConverted.value)) {
              const seriesShape = isString(ySetting.shape) ? ySetting.shape.toLowerCase() : 'circle';
              dataPoint = {
                id: itemComparing.id,
                y: dataPointConverted.value,
                name: dataPointConverted.label,
                groupName: itemComparing.name,
                treatmentName: paramDataByItemComparing.name,
                avgMD: paramDataByItemComparing.avgMDValue,
                color: ySetting.color,
                marker: {
                  symbol: seriesShape
                }
              };
              // replace correct data point
              seriesDataPoints.push(dataPoint);
            }
          }

        });
        // create series by items comparing
        const seriesInfo = {
          seriesName: seriesNames,
          seriesColor: ySetting.color,
          seriesShape: ySetting.shape,
        };
        const seriesObj = this.getLineFakeScatterSeriesData(seriesDataPoints, seriesInfo, beginYAxisIndex + yAxisIndex);
        seriesData.push(seriesObj);
      });
    }

    return seriesData;
  }

  getLineFakeScatterSeriesData(dataSeries: number[] | any[], extraInfo?, yAxisIndex?: number, fdiTreatmentId?: number) {
    extraInfo = isObject(extraInfo) ? extraInfo : {};
    const seriesColor = extraInfo.seriesColor;
    const seriesName = extraInfo.seriesName;
    const seriesShape = isString(extraInfo.seriesShape) ? extraInfo.seriesShape.toLowerCase() : 'circle';
    const result = {
      id: seriesName,
      name: seriesName,
      type: 'line',
      data: dataSeries,
      color: seriesColor,
      marker: {
        symbol: seriesShape
      },
      lineWidth: 0,
      zIndex: 2,
      states: {
        inactive: {
          opacity: 1
        },
        hover: {
          lineWidth: 0,
          lineWidthPlus: 0
        }
      },
      yAxis: yAxisIndex
    };
    return result;
  }

  parseSeriesDataByParameter(itemsComparing, xAxisSettings, yAxisSettings, inputData) {
    let seriesData = [];

    try {
      if (!isEmpty(itemsComparing) && !isEmpty(xAxisSettings) && !isEmpty(yAxisSettings) && !isEmpty(inputData)) {
        forEach(yAxisSettings, (ySetting, yAxisIndex) => {
          forEach(itemsComparing, (itemComparing) => {
            const dataValues = [];
            forEach(xAxisSettings, (xSetting) => {
              let dataPoint = null;
              const paramDataX = inputData.find(item => {
                return item.id === itemComparing.id && item.paramName === xSetting.id;
              });
              if (paramDataX) {
                const pointX = this.convertPos(paramDataX);
                if (isNumber(pointX.value)) {
                  dataPoint = { x: pointX.value, y: null, xLabel: pointX.label };
                }
              }

              if (dataPoint) {
                dataValues.push(dataPoint);
              }
              const paramDataY = inputData.find(item => {
                return item.id === itemComparing.id && item.paramName === ySetting.id;
              });
              if (paramDataY) {
                const pointY = this.convertPos(paramDataY);
                dataPoint.yLabel = pointY.label;
                if (isNumber(pointY.value)) {
                  dataPoint.y = pointY.value;
                }
              }
            });
            const seriesInfo = {
              seriesName: itemComparing.name,
              seriesColor: ySetting.color,
              seriesShape: ySetting.shape,
            };
            const seriesObj = this.getSeriesData(yAxisIndex, dataValues, seriesInfo);
            seriesData.push(seriesObj);
          });
        });
      }
    } catch (err) {
      return seriesData;
    }

    return seriesData;
  }

  parseDesignActualSeriesDataByWellMode(wellItems, yAxisSetting, yAxisIndex, designActualData) {
    let seriesData = [];

    try {
      if (yAxisSetting && !isEmpty(wellItems) && !isEmpty(designActualData)) {
        // create default data series
        const designSeriesData = [];
        const actualSeriesData = [];
        forEach(wellItems, (well, index) => {
          const dataByWell = designActualData.filter(data => data.wellId === well.wellId);
          if (!isEmpty(dataByWell)) {
            const designValue = meanBy(dataByWell, `${yAxisSetting.designValue}`);
            const actualValue = meanBy(dataByWell, `${yAxisSetting.actualValue}`);
            const seriesShape = isString(yAxisSetting.shape) ? yAxisSetting.shape.toLowerCase() : 'triangle';
            designSeriesData.push({
              y: designValue,
              marker: {
                symbol: seriesShape
              },
              name: `${yAxisSetting.name} (Design)`,
              color: yAxisSetting.designColor
            });
            actualSeriesData.push({
              y: actualValue,
              marker: {
                symbol: seriesShape
              },
              name: `${yAxisSetting.name} (Actual)`,
              color: yAxisSetting.actualColor,
              designValue,
              actualValue,
              isDesignActual: true
            });
          }
        });
        const designDataType = yAxisSetting.designShape === 'Line' ? 'line' : 'column';
        const actualDataType = yAxisSetting.actualShape === 'Line' ? 'line' : 'column';
        // create series by items comparing
        const seriesInfo = {
          seriesShape: yAxisSetting.shape,
          xAxisIndex: 1,
          seriesIndex: -1
        };
        const designSeries = this.getSeriesData(yAxisIndex, designSeriesData, { ...seriesInfo, seriesName: `${yAxisSetting.name} (Design)`, seriesColor: DEFAULT_DESIGN_COLOR, dataType: designDataType });
        const actualSeries = this.getSeriesData(yAxisIndex, actualSeriesData, { ...seriesInfo, seriesName: `${yAxisSetting.name} (Actual)`, seriesColor: DEFAULT_ACTUAL_COLOR, dataType: actualDataType });
        seriesData.push(designSeries);
        seriesData.push(actualSeries);
      }
    } catch (err) {
      return seriesData;
    }

    return seriesData;
  }

  parseDesignActualSeriesDataByTreatmentMode(treatmentComparingItems, yAxisSetting, yAxisIndex, designActualData) {
    let seriesData = [];

    try {
      if (yAxisSetting && !isEmpty(treatmentComparingItems) && !isEmpty(designActualData)) {
        // create default data series
        const designSeriesData = [];
        const actualSeriesData = [];
        forEach(treatmentComparingItems, (treatment) => {
          const treatmentDetailData = designActualData.find(daTreatmentItem => {
            return treatment.treatmentId === daTreatmentItem.id;
          });
          if (treatmentDetailData) {
            const designValue = treatmentDetailData[yAxisSetting.designValue];
            const actualValue = treatmentDetailData[yAxisSetting.actualValue];

            const seriesShape = isString(yAxisSetting.shape) ? yAxisSetting.shape.toLowerCase() : 'circle';
            designSeriesData.push({
              y: designValue,
              marker: {
                symbol: seriesShape
              },
              name: `${yAxisSetting.name} (Design)`,
              color: yAxisSetting.designColor
            });
            actualSeriesData.push({
              y: actualValue,
              marker: {
                symbol: seriesShape
              },
              name: `${yAxisSetting.name} (Actual)`,
              color: yAxisSetting.actualColor,
              designValue,
              actualValue,
              isDesignActual: true
            });
          }
        });
        const designDataType = yAxisSetting.designShape === 'Line' ? 'line' : 'column';
        const actualDataType = yAxisSetting.actualShape === 'Line' ? 'line' : 'column';
        // create series by items comparing
        const seriesInfo = {
          seriesShape: yAxisSetting.shape,
          dataType: 'column',
          seriesIndex: -1
        };
        const designSeries = this.getSeriesData(yAxisIndex, designSeriesData, { ...seriesInfo, seriesName: `${yAxisSetting.name} (Design)`, seriesColor: DEFAULT_DESIGN_COLOR, dataType: designDataType });
        const actualSeries = this.getSeriesData(yAxisIndex, actualSeriesData, { ...seriesInfo, seriesName: `${yAxisSetting.name} (Actual)`, seriesColor: DEFAULT_ACTUAL_COLOR, dataType: actualDataType });
        seriesData.push(designSeries);
        seriesData.push(actualSeries);
      }
    } catch (err) {
      return seriesData;
    }

    return seriesData;
  }

  getLeftYAxisOption(yAxisSettings: IAxisSetting[], isDesignActual = false) {
    const yAxisOpts = yAxisSettings.map(setting => {
      let yAxisType;
      if (EpochTimeParameters.indexOf(setting.name) > -1) {
        yAxisType = 'datetime';
      }
      const isUnsetColor = !!isDesignActual;
      return this.getYAxisOption(setting.name, yAxisType, false, setting, isUnsetColor);
    });

    return yAxisOpts;
  }

  parseChartOptions(
    itemsComparing: IAxisSetting[],
    chartSettings: IScatterPlotChartSetting,
    inputData: IInputParamData[],
    xAxisMode?: string,
    isMultiWells?: boolean,
    designActualData?,
    groupOption?: string,
    fdiOffsetData?: IInputParamData[],
    isDarkMode?: boolean
  ): IParsedChartOptions {
    let xAxisType;
    let xAxisOpts = [];
    let seriesData = [];
    const xAxisSettings = chartSettings.xAxis;
    const yAxisSettings = chartSettings.yAxis;
    const designActualYAxis = chartSettings.designActualYAxis;
    const fdiYAxisSettings = chartSettings.fdiYAxis;
    if (xAxisMode === 'parameter') {
      const xAxisTitle = xAxisSettings.name;
      if (EpochTimeParameters.indexOf(xAxisSettings.name) > -1) {
        xAxisType = 'datetime';
      }
      const reversed = (xAxisSettings && xAxisSettings.min > xAxisSettings.max);
      let xAxisOpt = this.getXAxisOptionDefault(xAxisTitle, xAxisType, xAxisSettings, reversed, isDarkMode);
      xAxisOpts.push(xAxisOpt);
      seriesData = this.parseSeriesDataByParameter(itemsComparing, [xAxisSettings], yAxisSettings, inputData);
    } else {
      let xAxisOpt = this.getXAxisOptionCategories(itemsComparing, undefined, isDarkMode);
      xAxisOpts.push(xAxisOpt);
      seriesData = this.parseSeriesDataByCategories(itemsComparing, yAxisSettings, inputData);
      if (!isEmpty(designActualYAxis)) {
        if (groupOption === 'treatment') {
          designActualYAxis.forEach((setting, index) => {
            const designActualSeries = this.parseDesignActualSeriesDataByTreatmentMode(itemsComparing, setting, index + yAxisSettings.length, designActualData);
            seriesData = seriesData.concat(designActualSeries);
          });
        } else {
          const itemsGroupByWell = groupBy(itemsComparing, 'wellId');
          const wellItems = [];
          Object.values(itemsGroupByWell).forEach(items => {
            if (!isEmpty(items)) {
              wellItems.push(items[0]);
            }
          });
          const categories = [];
          wellItems.forEach(item => {
            categories.push(item.wellName);
          });
          const designActualXAxis: Highcharts.XAxisOptions = this.getXAxisOptionDefault('Well', undefined, undefined, undefined, isDarkMode);
          designActualXAxis.categories = categories;
          xAxisOpts.push(designActualXAxis);

          designActualYAxis.forEach((setting, index) => {
            const designActualSeries = this.parseDesignActualSeriesDataByWellMode(wellItems, setting, index + yAxisSettings.length, designActualData);
            seriesData = seriesData.concat(designActualSeries);
          });
        }
      }
      // parse data for FDI
      if (!isEmpty(fdiYAxisSettings)) {
        const curYAxisIndex = yAxisSettings.length + designActualYAxis.length;
        const fdiSeries = this.parseFDISeriesDataByCategories(itemsComparing, fdiYAxisSettings, fdiOffsetData, curYAxisIndex);
        seriesData = seriesData.concat(fdiSeries);
      }

    }
    // get left y axis
    let yAxisOpts = this.getLeftYAxisOption(yAxisSettings);
    if (!isEmpty(designActualYAxis)) {
      yAxisOpts = yAxisOpts.concat(this.getLeftYAxisOption(designActualYAxis, true));
    }
    // make fdi y axis
    if (!isEmpty(fdiYAxisSettings)) {
      yAxisOpts = yAxisOpts.concat(this.getLeftYAxisOption(fdiYAxisSettings));
    }

    // get tooltip options
    const tooltipOption = this.getTooltipOption(xAxisMode, xAxisType === 'datetime', false, isMultiWells);

    return {
      xAxis: xAxisOpts,
      yAxis: yAxisOpts,
      seriesData: seriesData,
      tooltip: tooltipOption
    };
  }

  parseMultiDataToStringText(
    inputChartData: any[],
    selectedXAxisOption: SelectItem[],
    selectedYAxisOption: SelectItem[],
    selectedWells: any[]
  ): string {
    const separateSelectedWell = (wells: any[]) => {
      const mainWells: any[] = [];
      const offsetWells: any[] = [];
      for (const well of wells) {
        if (!well.treatmentList || !well.treatmentList.length) continue;
        let isOffset = false;
        for (const treatment of well.treatmentList) {
          if (treatment.treatmentNumber === -10) {
            isOffset = true;
            break;
          }
        }
        if (isOffset) offsetWells.push(well);
        else mainWells.push(well);
      }
      return { mainWells, offsetWells };
    };

    const getInputValue = (inputData: any[], treatmentId: number, paramName: string, fdiOffsetId?: number) => {
      const findData = !fdiOffsetId ?
        inputData.find(x => x.treatmentId === treatmentId && x.paramName === paramName) :
        inputData.find(x => x.treatmentId === treatmentId && x.fdiTreatmentId === fdiOffsetId && x.paramName === paramName)
      if (!findData) return null;
      return { paramValue: findData.paramValue, paramUnit: findData.paramUnit };
    };

    const getWellFromTreatmentId = (wells: any[], treatmentId: number) => {
      for (const well of wells) {
        if (!well.treatmentList || !well.treatmentList.length) continue;
        for (const treatment of well.treatmentList) {
          if (treatment.id === treatmentId) return well;
        }
      }
      return null;
    }

    const { mainWells: selectedMainWells, offsetWells: selectedOffsetWells } = separateSelectedWell(selectedWells);
    const rowValues: any[] = [];
    for (const well of selectedMainWells) {
      for (const treatment of well.selectedTreatmentList) {
        const filterOffsetData = inputChartData.filter(x => x.wellId === well.wellId);
        const rowValue: any[] = [];
        let fdiTreatmentId = 0;

        for (const axisOption of selectedYAxisOption) {
          const findOffsetData = filterOffsetData.find(x => x.treatmentId === treatment.id && x.paramName === axisOption.id);
          if (findOffsetData) fdiTreatmentId = findOffsetData.fdiTreatmentId;
          const getValue = findOffsetData ?
            getInputValue(inputChartData, treatment.id, axisOption.id, findOffsetData.fdiTreatmentId) :
            getInputValue(inputChartData, treatment.id, axisOption.id);
          rowValue.push(getValue ? getValue.paramValue : '');
        }

        rowValue.unshift(treatment.treatmentName);
        if (fdiTreatmentId) {
          const findFdiWell = getWellFromTreatmentId(selectedOffsetWells, fdiTreatmentId);
          if (findFdiWell) rowValue.unshift(findFdiWell.wellName);
          else rowValue.unshift('');
        } else {
          rowValue.unshift('');
        }
        rowValue.unshift(well.wellName);

        const parseRowValue = Helper.concatString(rowValue, ',');
        rowValues.push(parseRowValue);
      }
    }
    const parseRowValues = Helper.concatString(rowValues, '\r\n');

    const headerValue: any[] = [];
    headerValue.push('WellName');
    headerValue.push('OffsetWellName');
    headerValue.push('Treatment');
    for (const axisOption of selectedYAxisOption) {
      headerValue.push(axisOption.id);
    }
    const parseHeaderValue = Helper.concatString(headerValue, ',');
    return parseHeaderValue + '\r\n' + parseRowValues;
  }

  getRawStringByParamOptions(
    inputChartData: IInputParamData[], selectedAxisOptions: { id: any; text: string }[], selectedTreatments: ITreatmentItem[],
    designActualYAxisOption?: IAxisSetting, designActualData?
  ) {
    let headersNames = ['Treatment', ...selectedAxisOptions.map(item => item.text)];
    const paramsUnitData = selectedAxisOptions.map(opt => {
      const paramItem = inputChartData.find(dbt => dbt.paramName === opt.id);
      if (paramItem) {
        return paramItem.paramUnit.replace('(', '').replace(')', '');
      }
      return;
    });
    let headersUnits = ['', ...paramsUnitData];
    if (!isEmpty(designActualYAxisOption) && designActualData) {
      headersNames = headersNames.concat([`${designActualYAxisOption.name} (Design)`, `${designActualYAxisOption.name} (Actual)`]);
      headersUnits = headersUnits.concat(['', '']);
    }

    const channelData = [];
    selectedTreatments.forEach(trm => {
      const dataByTreatment = inputChartData.filter(dataItem => dataItem.id === trm.id);
      const paramsDataRow = selectedAxisOptions.map(opt => {
        const paramItem = dataByTreatment.find(dbt => dbt.paramName === opt.id);
        if (paramItem) {
          return paramItem.paramValue;
        }
        return;
      });
      const dataRow = [trm.treatmentNumber, ...paramsDataRow];
      if (!isEmpty(designActualYAxisOption) && designActualData) {
        const dvaDataByTreatment = designActualData.find(dataItem => dataItem.id === trm.id);
        if (dvaDataByTreatment) {
          const designValue = dvaDataByTreatment[designActualYAxisOption.designValue];
          const actualValue = dvaDataByTreatment[designActualYAxisOption.actualValue];
          dataRow.push(designValue);
          dataRow.push(actualValue);
        }
      }

      channelData.push(dataRow);
    })
    const result = [
      headersNames,
      headersUnits,
      channelData.join('\n')
    ]
    const stringData = result.join('\n');

    return stringData;
  }

  getRawStringNonScale(
    inputChartData: IInputParamData[], selectedXAxisOption, selectedYAxisOption,
    selectedTreatments: ITreatmentItem[],
    designActualYAxisOption?, designActualData?
  ) {
    if (!isEmpty(inputChartData)) {
      inputChartData.reverse();
      let stringData;
      if (!isEmpty(selectedXAxisOption) && selectedXAxisOption[0].id === 'treatment') {
        stringData = this.getRawStringByParamOptions(inputChartData, selectedYAxisOption, selectedTreatments, designActualYAxisOption, designActualData);
      } else if (!isEmpty(selectedXAxisOption) && selectedXAxisOption[0].id !== 'treatment') {
        const axisParamOptions = [...selectedXAxisOption, ...selectedYAxisOption];
        stringData = this.getRawStringByParamOptions(inputChartData, axisParamOptions, selectedTreatments);
      }
      return stringData;
    }
  }

  parseFDISeriesDataByCategories(itemsComparing: IAxisSetting[], yAxisSettings: IAxisSetting[], inputData: IInputParamData[], beginYAxisIndex = 0) {
    if (isEmpty(itemsComparing) || isEmpty(yAxisSettings) || isEmpty(inputData)) return [];

    const seriesData = [];
    forEach(yAxisSettings, (ySetting, yAxisIndex) => {
      const seriesDataPoints = [];
      let seriesNames = `${ySetting.name}`;

      forEach(itemsComparing, (itemComparing) => {
        let dataPoint = null;
        const paramDataByItemComparing = inputData.find(item => {
          return item.id === itemComparing.id && item.paramName === ySetting.id;
        });
        if (paramDataByItemComparing) {
          const dataPointConverted = this.convertPos(paramDataByItemComparing, ySetting.name);
          seriesNames = dataPointConverted.label;

          if (isNumber(dataPointConverted.value)) {
            const seriesShape = isString(ySetting.shape) ? ySetting.shape.toLowerCase() : 'circle';
            dataPoint = {
              id: itemComparing.id,
              y: dataPointConverted.value,
              name: dataPointConverted.label,
              groupName: itemComparing.name,
              treatmentName: paramDataByItemComparing.name,
              avgMD: paramDataByItemComparing.avgMDValue,
              color: ySetting.color,
              marker: {
                symbol: seriesShape
              }
            };
            // replace correct data point
            seriesDataPoints.push(dataPoint);
          }
        }

      });
      // create series by items comparing
      const seriesInfo = {
        seriesName: seriesNames,
        seriesColor: ySetting.color,
        seriesShape: ySetting.shape,
      };
      const seriesObj = this.getLineFakeScatterSeriesData(seriesDataPoints, seriesInfo, beginYAxisIndex + yAxisIndex);
      seriesData.push(seriesObj);
    });

    for (const series of seriesData) {
      const newData = Helper.createListOf(null, itemsComparing.length);
      for (const data of series.data) {
        const iter = itemsComparing.findIndex(x => x.name === data.groupName);
        if (iter < 0) continue;
        newData[iter] = data;
      }
      series.data = newData;
    }

    return seriesData;
  }
}

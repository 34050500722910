import { Injectable } from '@angular/core';

const STORAGE_KEY = 'channel_mapping';
const STORAGE_TENANT = 'company';

@Injectable()
export class ChannelMappingStorageService {

  constructor() {}

  private getTenantItem(tenantId) {
    return tenantId ? [STORAGE_KEY, STORAGE_TENANT, tenantId].join('_') : STORAGE_KEY;
  }

  setChannelMapped(tenantId, value) {
    const item = this.getTenantItem(tenantId);
    if (!value) return;
    localStorage.setItem(item, JSON.stringify(value));
  }

  getChannelMapped(tenantId) {
    const item = this.getTenantItem(tenantId);
    try {
      const stringData = localStorage.getItem(item);
      return JSON.parse(stringData);
    } catch (error) {
      return null;
    }
  }

  removeChannelMapped(tenantId) {
    const item = this.getTenantItem(tenantId);
    localStorage.removeItem(item);
  }

  clearChannelMapped() {
    for (const obj in localStorage) {
      if (localStorage.hasOwnProperty(obj) && obj.includes(STORAGE_KEY)) {
        localStorage.removeItem(obj);
      }
    }
  }
}

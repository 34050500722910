import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {CPATTERN} from '../../helpers/app.constants';

@Component({
  selector: 'error-message',
  template: `<div style="position: relative" *ngIf="formControl && formControl.touched && !formControl.valid">
               <div class="form-text text-danger" *ngIf="formControl.errors">{{getErrorMessage()}}</div>
             </div>`,
})
export class ErrorMessageComponent {

  cvalidate = CPATTERN;
  formControl: any;
  @Input()
  set fc(control: AbstractControl) {
    if (control) {
      this.formControl = control;
    }
  }
  @Input() txtName: string = '';
  @Input() txtRequired: string = '';
  @Input() txtMinMax: string = '';
  @Input() txtLength: string = '';
  @Input() emailPattern: boolean = false;
  @Input() passwordPattern: boolean = false;
  @Input() textPattern: boolean = false;
  @Input() textNumberPattern: boolean = false;
  @Input() pattern: string = '';
  @Input() errorPattern: string = '';

  getErrorMessage() {
    let errorText = '';
    if (this.formControl.errors) {
      if (this.formControl.errors.required || this.formControl.errors.requiredTrue) {
        if (this.txtRequired) {
          errorText = this.txtRequired;
        } else if (this.formControl.errors.required) {
          errorText = `${this.txtName} is required.`;
        } else {
          errorText = `${this.txtName} is required true.`;
        }
      } else if (this.formControl.errors.min || this.formControl.errors.max) {
        if (this.txtMinMax) {
          errorText = this.txtMinMax;
        } else if (this.formControl.errors.min) {
          errorText = `${this.txtName} must be at least than ${this.formControl.errors.min.min}.`;
        } else {
          errorText = `${this.txtName} must be at less than or equal to ${this.formControl.errors.max.max}.`;
        }
      } else if (this.formControl.errors.minlength || this.formControl.errors.maxlength) {
        if (this.txtLength) {
          errorText = this.txtLength;
        } else if (this.formControl.errors.minlength) {
          errorText = `${this.txtName} must be at least than ${this.formControl.errors.minlength.requiredLength} characters long.`;
        } else {
          errorText = `${this.txtName} must be at less than or equal to ${this.formControl.errors.maxlength.requiredLength} characters long.`;
        }
      } else if (this.formControl.errors.pattern) {
        const requiredParent = this.formControl.errors.pattern.requiredPattern;
        if (!!this.emailPattern) {
          const regex = this.cvalidate.EMAIL.toString();
          if (requiredParent === regex) {
            errorText = `${this.txtName} has to be a valid email address.`;
          }
        } else if (!!this.passwordPattern) {
          const regex = this.cvalidate.PASSWORD.toString();
          if (requiredParent === regex) {
            errorText = `Please choose a stronger password. Try a mix of upper/lowercase letters, numbers and symbols.`;
          }
        } else if (!!this.textPattern) {
          const regex = this.cvalidate.TEXT.toString();
          if (requiredParent === regex) {
            errorText = `${this.txtName} does not contain special chars, numbers and decimal digits (except for space).`;
          }
        } else if (!!this.textNumberPattern) {
          const regex = this.cvalidate.TEXTNUMBER.toString();
          if (requiredParent === regex) {
            errorText = `${this.txtName} does not contain special characters, decimal digits (except for space).`;
          }
        } else {
          const regex = this.pattern.toString();
          if (requiredParent === regex) {
            errorText = this.errorPattern;
          }
        }
      } else if (this.formControl.errors.minNumber) {
        errorText = `${this.txtName} must be later than Start time.`;
      } else if (this.formControl.errors.invalidMinimum) {
        errorText = `${this.txtName} must be larger than or equal ${this.formControl.errors.invalidMinimum.minimumValue}.`;
      }
    }
    return errorText;
  }

}

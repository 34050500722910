import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services';
import { map } from 'rxjs/operators';
import { WELL_SEARCH_OPTIONS } from '../helpers/app.constants';
import { forkJoin } from 'rxjs';

export interface ISettingItem {
  name: string;
  order: number;
}

@Injectable()
export class WellSearchSettingsService {
  prefixPath = `/filterSettings`;

  constructor(
    private apiService: ApiService
  ) { }

  getSettings() {
    return this.apiService.get(`${this.prefixPath}`).pipe(map(res => res.result));
  }

  createSetting(body: ISettingItem) {
    return this.apiService.post(`${this.prefixPath}`, body);
  }

  updateSettings(id, body: ISettingItem) {
    return this.apiService.put(`${this.prefixPath}?filterSettingId=${id}`, body);
  }

  deleteSetting(id) {
    return this.apiService.delete(`${this.prefixPath}?filterSettingId=${id}`);
  }

  getDefaultSettings() {
    return WELL_SEARCH_OPTIONS.filter(item => item.default);
  }

  createDefaultSettings() {
    const defaultItems = this.getDefaultSettings();
    const req$ = defaultItems.map((item, index) => {
      return this.createSetting({
        name: item.name,
        order: index
      });
    });

    return forkJoin(req$);
  }
}

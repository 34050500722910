import {FormGroup, ValidatorFn} from '@angular/forms';

export class NumberValidator {
  public static nonZero(min: number): ValidatorFn {
    return (control: FormGroup): {[key: string]: any} => {
      const input = control.value;
      if (input === '') {
        return null;
      }
      const isValid = input <= min;
      if (isValid) {
        return {minValue: {min}};
      } else {
        return null;
      }
    };
  }
  public static compareNumber(startNumber, endNumber): ValidatorFn {
    return (control: FormGroup) => {
/*      if (!control.controls[startNumber].value || !control.controls[endNumber].value) {
        return null;
      }*/
      if (control.controls[startNumber].value <= control.controls[endNumber].value) {
        control.controls[endNumber].setErrors(null);
        return null;
      } else {
        control.controls[endNumber].setErrors({
          minNumber: control.controls[startNumber].value,
        });
        return {
          validateNewer: {
            valid: false,
          }
        };
      }
    };
  }
}

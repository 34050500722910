import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { cloneDeep } from 'lodash';
import { TreatmentPlotFDIParam } from '../../treatment-plot/treatment-plot-fdi.model';


@Component({
  selector: 'app-fdi-parameter-select',
  templateUrl: './fdi-parameter-select.component.html',
  styleUrls: ['./fdi-parameter-select.component.scss']
})
export class FDIParameterSelectComponent implements OnInit {
  form: FormGroup;
  inputParam: TreatmentPlotFDIParam;

  @Input()
  set selectedParam(param: TreatmentPlotFDIParam) {
    this.inputParam = cloneDeep(param);
    if (!param) return;
    if (!param.fdiStart) this.fdiStart.setValue(false);
    if (!param.fdiEnd) this.fdiEnd.setValue(false);
    if (!param.netGainPress) this.netGainPress.setValue(false);
    if (!param.grossGainPress) this.grossGainPress.setValue(false);
    if (!param.volToFrstResp) this.volToFrstResp.setValue(false);
    if (!param.volToFrstRespPercent) this.volToFrstRespPercent.setValue(false);
    if (!param.avgSlope) this.avgSlope.setValue(false);
    if (!param.maxSlope) this.maxSlope.setValue(false);
    if (!param.preFDIPressure) this.preFDIPressure.setValue(false);
    if (!param.avgFDIPressure) this.avgFDIPressure.setValue(false);
    if (!param.maxFDIPressure) this.maxFDIPressure.setValue(false);
  }

  constructor(
    protected dialogRef: NbDialogRef<any>,
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  fdiStart: AbstractControl;
  fdiEnd: AbstractControl;
  netGainPress: AbstractControl;
  grossGainPress: AbstractControl;
  volToFrstResp: AbstractControl;
  volToFrstRespPercent: AbstractControl;
  avgSlope: AbstractControl;
  maxSlope: AbstractControl;
  preFDIPressure: AbstractControl;
  avgFDIPressure: AbstractControl;
  maxFDIPressure: AbstractControl;

  createForm() {
    this.form = new FormGroup({
      fdiStart: new FormControl(true),
      fdiEnd: new FormControl(true),
      netGainPress: new FormControl(true),
      grossGainPress: new FormControl(true),
      volToFrstResp: new FormControl(true),
      volToFrstRespPercent: new FormControl(true),
      avgSlope: new FormControl(true),
      maxSlope: new FormControl(true),
      preFDIPressure: new FormControl(true),
      avgFDIPressure: new FormControl(true),
      maxFDIPressure: new FormControl(true),
    }, []);

    this.fdiStart = this.form.controls['fdiStart'];
    this.fdiEnd = this.form.controls['fdiEnd'];
    this.netGainPress = this.form.controls['netGainPress'];
    this.grossGainPress = this.form.controls['grossGainPress'];
    this.volToFrstResp = this.form.controls['volToFrstResp'];
    this.volToFrstRespPercent = this.form.controls['volToFrstRespPercent'];
    this.avgSlope = this.form.controls['avgSlope'];
    this.maxSlope = this.form.controls['maxSlope'];
    this.preFDIPressure = this.form.controls['preFDIPressure'];
    this.avgFDIPressure = this.form.controls['avgFDIPressure'];
    this.maxFDIPressure = this.form.controls['maxFDIPressure'];
  }

  submitForm() {
    if (!this.inputParam) this.inputParam = {};
    this.inputParam.fdiStart = this.fdiStart.value;
    this.inputParam.fdiEnd = this.fdiEnd.value;
    this.inputParam.netGainPress = this.netGainPress.value;
    this.inputParam.grossGainPress = this.grossGainPress.value;
    this.inputParam.volToFrstResp = this.volToFrstResp.value;
    this.inputParam.volToFrstRespPercent = this.volToFrstRespPercent.value;
    this.inputParam.avgSlope = this.avgSlope.value;
    this.inputParam.maxSlope = this.maxSlope.value;
    this.inputParam.preFDIPressure = this.preFDIPressure.value;
    this.inputParam.avgFDIPressure = this.avgFDIPressure.value;
    this.inputParam.maxFDIPressure = this.maxFDIPressure.value;
    this.dialogRef.close(this.inputParam);
  }

  close() {
    this.dialogRef.close(this.inputParam);
  }
}

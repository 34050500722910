import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services';

@Injectable()
export class SessionService {
  prefixSessionsPath = `/sessions`;
  prefixTokenPath = `/tokens`;

  constructor(
    private apiService: ApiService
  ) { }

  getCurrentUser() {
    return this.apiService.get(`${this.prefixSessionsPath}/currentUser`);
  }

  getWellsToken(wellId: number) {
    return this.apiService.get(`${this.prefixTokenPath}/auth/wells/${wellId}`);
  }

  getWellTreatmentsToken(wellId: number, treatmentId: number) {
    return this.apiService.get(`${this.prefixTokenPath}/auth/wells/${wellId}/treatments/${treatmentId}`);
  }

}

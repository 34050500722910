import { SearchPadListBase } from './search-pad-list-base';


export class SearchPadListTextBox extends SearchPadListBase<string> {
  controlType = 'textbox';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}

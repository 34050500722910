import { ITreatmentLogTemplate, ITreatmentLogTemplateResponse } from './../../../models/treatment-log-template.model';
import { IPlotTemplate, IPlotTemplateResponse } from './../../../models/plot-template.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from '../../../services/user-auth.service';

interface IFormData { isAllWells: boolean; name: string; }

@Component({
  selector: 'app-confirm-template-modal',
  templateUrl: './confirm-template-modal.component.html',
  styleUrls: ['./confirm-template-modal.component.scss']
})
export class ConfirmTemplateModalComponent implements OnInit {

  @Output()
  confirm: EventEmitter<any> = new EventEmitter();
  @Input()
  className: string = 'pop-delete';
  @Input()
  title: string = 'Confirm';
  @Input()
  message: string = 'Plot Template should be available to';
  @Input()
  hideMessage: boolean = false;
  @Input()
  showAllWellsCheckbox: boolean = true;
  @Input()
  defaultIsAllWells: boolean = false;
  @Input()
  templateRes?: IPlotTemplate | ITreatmentLogTemplateResponse;
  @Input()
  templates: any[];
  @Input()
  isScatterPlot: boolean = false;

  optionsOpened: boolean = false;
  submitLabel: string = 'OK';
  canSubmit: boolean = true;
  form: FormGroup;
  search: string = '';
  isAllWellsDisabled = false;
  userId = 0;

  constructor(
    private dialogRef: NbDialogRef<any>,
    private userAuthService: UserAuthService
  ) {
    const user = this.userAuthService.getUserInfo();
    this.userId = user.userId;
  }

  get name() {
    return this.form.controls.name;
  }

  setName(value) {
    this.form.controls.name.setValue(value);
  }

  get isAllWells() {
    return this.form.controls.isAllWells;
  }

  setIsAllWells(value) {
    this.form.controls.isAllWells.setValue(value);
  }

  get filterTemplates() {
    if (!this.templates || !this.templates.length) return [];
    if (this.isScatterPlot) return this.templates.filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()));
    return this.templates.filter(x => x.type !== 0).filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()));
  }

  ngOnInit() {
    const name = this.templateRes && this.templateRes.id ? this.templateRes.name : '';
    this.form = new FormGroup(
      {
        name: new FormControl(name, [Validators.required]),
        isAllWells: new FormControl(this.defaultIsAllWells)
      }
    );
  }

  submit() {
    if (!this.canSubmit) return;
    if (this.form.valid) {
      const formData = this.form.value;
      this.confirm.emit(formData);
      this.dialogRef.close(formData);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  allWellsChange(val) {
    // this.allWells = val;
  }

  selectMatch(template, event) {
    this.setName(template.name);
    this.setIsAllWells(template.isAll);
    this.search = template.name;
    this.optionsOpened = false;
    this.setButtonLabel();
  }

  inputEvent($e) {
    this.search = $e.target.value;
    this.setButtonLabel();
  }

  private setButtonLabel = () => {
    if (!this.templates || !this.templates.length) return;
    this.canSubmit = true;
    const template = this.templates.find(x => x.name === this.search);
    if (template) {
      this.setName(template.name);
      this.setIsAllWells(template.isAll);
      if (this.isScatterPlot || template.type !== 0) {
        this.submitLabel = 'Overwrite';
      } else {
        this.canSubmit = false;
      }
      this.isAllWellsDisabled = template.creatorUserId && template.creatorUserId !== this.userId;
    }
    else {
      this.submitLabel = 'OK';
      this.isAllWellsDisabled = false;
    }
  }

  focusOut() {
    setTimeout(e => {
      this.optionsOpened = false;
    }, 200);
  }

}

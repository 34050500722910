import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import {MomentZoneOffset} from 'moment';
import {CountryModel} from '../../models/country.model';

@Injectable()
export class CountriesAndTimezonesService {
  constructor() {
  }

  getCountries(): CountryModel[] {
    return CountryModel.init();
  }

  getCountriesCode(): any {
    return moment.tz.countries();
  }

  parseCountryOffsetToTz(country: string, offsetTime?: number): string {
    const countries = this.getCountries();

    const existCountry = countries.find(x => {
      return x.englishName === country || x.localName === country;
    });
    if (!existCountry) {
      return null;
    }

    return this.parseCountryCodeOffsetToTz(existCountry.alpha2Code, offsetTime);
  }
  // get timezone from country name & offsetTime
  parseCountryCodeOffsetToTz(countryCode: string, offsetTime?: number): string {
    let result: any;
    let timezones: MomentZoneOffset[];

    if (offsetTime !== null && offsetTime !== undefined) {
      timezones = moment.tz.zonesForCountry(countryCode, true);

      result = timezones
        .filter(tz => tz.offset === offsetTime)
        .map(x => x.name);
    } else {
      result = moment.tz.zonesForCountry(countryCode);
    }

    return result && result.length > 0 ? JSON.stringify(result) : null;
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, IAPIServiceOptions } from '../../core/services';
import { HttpParams } from '@angular/common/http';

export interface ITreatmentChartRequestBody {
  wellId: string;
  treatmentId: number;
  items: {name: string; min?: number; max?: number}[];
  filter?: {startTime: string; endTime: string; type: number};
}

export interface ITreatmentChartRequestOption {
  downSamplingNum?: any;
  startDate?: string;
  endDate?: string;
  startDateC0?: number;
  endDateC0?: number;
}

@Injectable()
export class ChartsService {
  prefixTreatmentPath = `/wells`;

  constructor(private apiService: ApiService) { }

  getTreatmentChartData(param, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixTreatmentPath}/treatments/getChart`, param, finalize);
  }

  getDataChart(reqBody?: any, finalize?: VoidFunction, authToken?: string): Observable<any> {
    const url = `${this.prefixTreatmentPath}/${reqBody.wellId}/treatments/${reqBody.treatmentId}/flowPaths/${reqBody.flowPathType}/getDataChart`;
    let param = new HttpParams();
    if (authToken && authToken.trim()) {
      param = param.set('authToken', authToken);
    }
    return this.apiService.post(url, reqBody, finalize, undefined, param);
  }

  getBourdetDataChart(reqBody, finalize?: VoidFunction, authToken?: string): Observable<any> {
    const url = `${this.prefixTreatmentPath}/calculateBourdetDerivative`;
    let param = new HttpParams();
    if (authToken && authToken.trim()) {
      param = param.set('authToken', authToken);
    }
    return this.apiService.post(url, reqBody, finalize, undefined, param);
  }

  getCompareChart(param, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixTreatmentPath}/compareByChart`, param, finalize);
  }

  getEquipmentChartData(param, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`/companies/equipments/getChart`, param, finalize);
  }

  getSingleWellMultiTreatmeantChartData(body, option?: ITreatmentChartRequestOption, finalize?: VoidFunction) {
    option = option ? option : {};
    let params: HttpParams = new HttpParams();
    if (option.downSamplingNum) {
      params = params.set('downSamplingNum', option.downSamplingNum);
    }
    if (option.startDate && option.endDate) {
      body.startDate = option.startDate;
      body.endDate = option.endDate;
    } else if (option.startDateC0 && option.endDateC0) {
      body.startDateC0 = option.startDateC0;
      body.endDateC0 = option.endDateC0;
    }
    return this.apiService.post(
      `${this.prefixTreatmentPath}/${body.wellId}/treatments/flowPaths/${body.flowPathType}/compareSingleByChart`,
      body,
      finalize,
      null,
      params
    );
  }

  getCompareMultiWellChartData(body, option?: ITreatmentChartRequestOption, finalize?: VoidFunction) {
    // return this.apiService.post(`${this.prefixTreatmentPath}/compareMultipleByChart`, param, finalize);
    option = option ? option : {};
    let params: HttpParams = new HttpParams();
    if (option.downSamplingNum) {
      params = params.set('downSamplingNum', option.downSamplingNum.toString());
    }
    if (option.startDate && option.endDate) {
      body.startDate = option.startDate;
      body.endDate = option.endDate;
    } else if (option.startDateC0 && option.endDateC0) {
      body.startDateC0 = option.startDateC0;
      body.endDateC0 = option.endDateC0;
    }
    return this.apiService.post(
      `${this.prefixTreatmentPath}/compareMultipleByDSChart`,
      body,
      finalize,
      null,
      params
    );
  }

  getPressureTrend(treatmentId: number, cName: string, startTime: number, endTime: number) {
    const url = '/treatments/' + treatmentId + '/pressureTrend';
    const q = '?startTime=' + startTime + '&endTime=' + endTime + '&cName=' + cName;
    return this.apiService.get(url + q);
  }

}

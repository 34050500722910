import { TIME_SETTING_TYPE, ChartTimeFormat, ENUM_THEMES } from './../../../helpers/app.constants';
import { Component, OnInit, Input, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
HC_stock(Highcharts);
import HC_exporting from 'highcharts/modules/exporting';
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
HC_exporting(Highcharts);
HC_offline_exporting(Highcharts);
import { isEmpty, cloneDeep, forEach, isObject, isNumber } from 'lodash';
const noData = require('highcharts/modules/no-data-to-display');
noData(Highcharts);
import HC_boost from 'highcharts/modules/boost';
HC_boost(Highcharts);
const randomColors = require('randomcolor');
import * as tinygradient from 'tinygradient';
import * as tinycolor from 'tinycolor2';
import { IChannelSettingModel, IChannelSettingItemModel } from '../../../../shared/models/channel.model';
import {
  BaseChartService, DATETIME_CHANNEL_NAME, TIMEFLOAT_CHANNEL_NAME, FRACPRO_TIMEFLOAT_CNAME, ISeriesDataExtraInfo,
} from '../../../../shared/services/charts/base-chart.service';
import { ChannelSelectionService } from '../../../services';
import { PivotPointService, IPivotPointItem } from '../../../../shared/services/pivot-point.service';
import { of, from, forkJoin, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CHART_MESSAGES } from '../../../helpers/app.constants';
import { IInputDataChart } from '../../../models';
import { Helper } from '../../../helpers/helper';
import { IInputDataTreatment, SingleWellMultiTreatmentChartService } from '../../../services/charts/single-well-multi-treatment-chart.service';
import { ThemesSettingsService } from '../../../services/themes-settings.service';
import { UnitSystemService } from '../../../services/unit-system.service';

interface IInputState {
  channelSettings: IChannelSettingModel;
  filterSettings?: {
    type: ChartTimeFormat;
    startIndex: number;
    endIndex: number;
  };
  inputData: IInputDataTreatment[];
  navigatorInputData?: IInputDataTreatment[];
  pivotPointData?: IPivotPointItem[];
}
@Component({
  selector: 'app-single-well-multi-treatment-chart',
  templateUrl: './single-well-multi-treatment-chart.component.html',
  styleUrls: ['./single-well-multi-treatment-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleWellMultiTreatmentChartComponent implements OnInit {
  subscriptions: Subscription[] = [];

  @Input()
  set inputState(state: IInputState) {
    if (state) {
      this.channelSettings = state.channelSettings;
      this.filterSettings = state.filterSettings;
      this.inputChartData = state.inputData;
      this.navigatorInputData = state.navigatorInputData;
      this.pivotPointData = state.pivotPointData;
    }
  }

  @Input()
  set inputReflow(isReflow: any) {
    if (this.highChart) {
      this.highChart.reflow();
    }
  }

  @Input() maxTreatmentList: number = 0;

  @Input() defaultTextData: string = '';

  @Output() onChangeXMinMax: EventEmitter<any> = new EventEmitter();
  @Output() onChangeNavigatorMinMax: EventEmitter<any> = new EventEmitter();

  inputChartData: IInputDataTreatment[];
  navigatorInputData: IInputDataTreatment[];
  pivotPointData: IPivotPointItem[];

  channelSettings: IChannelSettingModel;
  filterSettings: {
    type: ChartTimeFormat,
    startIndex: number,
    endIndex: number
  } = {
      type: TIME_SETTING_TYPE.OffsetTime,
      startIndex: 0,
      endIndex: 0
    };

  highChart: any;
  Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  runOutsideAngular: boolean = false;

  treatmentColors = [];

  isLoadingChart = false;
  messageNoData: string = CHART_MESSAGES.en.pleaseSelectChannel;
  chartConstructorType = 'chart';
  isDarkTheme: boolean;

  constructor(
    private baseChartService: BaseChartService,
    private channelSelectionService: ChannelSelectionService,
    private pivotPointService: PivotPointService,
    private singleWellMultiTreatmentChartService: SingleWellMultiTreatmentChartService,
    private ref: ChangeDetectorRef,
    private themesSettingsService: ThemesSettingsService,
    private unitSystemService: UnitSystemService
  ) {
    const localData = this.themesSettingsService.getLocalStorage();
    if (localData && localData.themes === ENUM_THEMES.Dark) {
      this.isDarkTheme = true;
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });
  }

  ngOnInit() {
    this.setupChart();
    this.treatmentColors = this.generateRandomTreatmentColors();

    // update chart series data by zoom buffer
    const subs = this.singleWellMultiTreatmentChartService.getZoomBufferData().subscribe((dataByTreatments: IInputDataTreatment[]) => {
      if (dataByTreatments) {
        // parse all treatment data
        const seriesData = this.parseMultiTreatmentSeriesData(this.channelSettings, dataByTreatments, this.filterSettings.type, this.pivotPointData);
        this.updateChartSeriesData(seriesData);
      }
    });
    this.subscriptions.push(subs);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputState && !changes.inputState.isFirstChange()) {
      this.updateChart(this.inputChartData, this.channelSettings, this.filterSettings, this.pivotPointData);
    }
  }

  ngOnDestroy(): void {
    Helper.unsubscribe(this.subscriptions);
    // unset zoom buffer data
    this.singleWellMultiTreatmentChartService.setZoomBufferData(null);
  }

  setupChart() {
    let options = this.baseChartService.getDefaultOptions(true, undefined, this.isDarkTheme);
    const xAxisOpt = this.baseChartService.getXAxisOption(this.filterSettings.type, null, null, true);
    const self = this;
    // bind set extreme event
    xAxisOpt.events = {
      setExtremes: function (e) {
        if (e && e.trigger === 'navigator') {
          self.onChangeNavigatorMinMax.emit(e);
        }
      }
    };
    options.xAxis = [xAxisOpt];
    options.yAxis = this.baseChartService.getYAxisOption(this.channelSettings);
    options.tooltip = Object.assign(options.tooltip, {
      headerFormat: this.baseChartService.getTooltipHeader(this.filterSettings.type),
      shared: false
    });
    options.legend.labelFormatter = function () {
      const arr = this.name.split('-');
      return arr[arr.length - 1].trim();
    }
    this.chartOptions = options;
    if (this.defaultTextData) {
      this.chartOptions.lang.noData = this.defaultTextData;
    }
    this.chartOptions.chart.events = {
      selection: function (event) {
        const result = { minX: null, maxX: null, isReset: false };
        if (event.xAxis) {
          result.minX = event.xAxis[0].min;
          result.maxX = event.xAxis[0].max;
        } else {
          const xAxisData = this.xAxis[0].getExtremes();
          result.minX = xAxisData.dataMin;
          result.maxX = xAxisData.dataMax;
          result.isReset = true;
        }
        self.onChangeXMinMax.emit(result);

        return true;
      }
    };

    setTimeout(() => {
      const timeTypeLowerCase = this.filterSettings.type ? this.filterSettings.type.toLowerCase() : '';
      if (timeTypeLowerCase === TIME_SETTING_TYPE.EpochTimeLowerCase) {
        // parse all treatment data
        const seriesData = this.parseMultiTreatmentSeriesData(this.channelSettings, this.inputChartData, this.filterSettings.type, this.pivotPointData);
        // set empty first
        seriesData.forEach(item => {
          item.data = [];
        });
        const navigatorSeriesData = this.parseNavigatorSeriesData(this.channelSettings, this.navigatorInputData, this.filterSettings.type, this.pivotPointData);

        options = Object.assign(options, {
          navigator: {
            adaptToUpdatedData: false,
            enabled: true,
            handles: {
              enabled: true
            },
            series: navigatorSeriesData,
            margin: 5,
            maskFill: !this.isDarkTheme ? 'rgba(102,133,194,0.3)' : 'rgba(51, 77, 128, 0.3)',
            outlineColor: !this.isDarkTheme ? '#cccccc' : '#e8e6e3',
            xAxis: {
              labels: {
                style: { color: !this.isDarkTheme ? '#000' : '#e8e6e3' }
              }
            }
          },
          scrollbar: {
            liveRedraw: false
          },
          series: seriesData
        });

        options.rangeSelector = {
          enabled: false,
          selected: 0,
          buttons: [{
            type: 'day',
            count: 1,
            text: '1d'
          }, {
            type: 'week',
            count: 1,
            text: '1w'
          }, {
            type: 'month',
            text: '1m'
          }, {
            type: 'all',
            text: 'All'
          }],
          inputEnabled: false
        };
        options.tooltip.split = false;
        this.highChart = Highcharts.stockChart('highstock-chart', options);
      } else {
        this.highChart = Highcharts.chart('highstock-chart', this.chartOptions);
      }
    }, 200);

  }

  loadChartData(channelSettings: IChannelSettingModel, inputData: IInputDataTreatment[], timeFormat, pivotPointData?: IPivotPointItem[], navigatorInputData?) {
    const observables = [of(null).pipe(delay(200))];
    if (this.highChart && channelSettings) {
      const promiseRenderChart = new Promise<void>((resolve) => {
        setTimeout(() => {
          console.log('begin parse series: ', new Date());
          // parse all treatment data
          let seriesData = this.parseMultiTreatmentSeriesData(channelSettings, inputData, timeFormat, pivotPointData);
          // check if all series is empty data then return empty array (prevent show series legend on chart)
          if (this.baseChartService.isAllSeriesDataEmpty(seriesData)) {
            seriesData = [];
          }
          if (isEmpty(seriesData)) {
            this.setChartMessage(CHART_MESSAGES.en.noData);
          }
          this.updateChartSeriesData(seriesData);
          console.log('add series done: ', new Date());
          resolve();
        }, 1000);
      });
      observables.push(from(promiseRenderChart));
    }

    return forkJoin(observables);
  }

  updateChartSeriesData(seriesData) {
    if (!isEmpty(seriesData)) {
      if (!isEmpty(this.highChart.series)) {
        this.highChart.series.forEach(series => {
          const seriesItem = seriesData.find(item => {
            return item.id === series.options.id;
          });
          if (seriesItem) {
            series.setData(seriesItem.data, false);
          }
        });
      } else {
        seriesData.forEach(series => {
          this.highChart.addSeries(series, false, false);
        });
      }
      this.highChart.redraw();
    }
  }

  parseSeriesDataByChannels(dataInfo: IInputDataTreatment, channelSettings: IChannelSettingModel, inputData?: IInputDataChart, timeFormat?: ChartTimeFormat, dataType?: string, extraInfo?: ISeriesDataExtraInfo, masterDataInfo?: IInputDataTreatment) {
    extraInfo = isObject(extraInfo) ? extraInfo : {};
    let result = [];
    if (channelSettings && inputData) {
      let yAxisIndex = 0;
      for (const key in channelSettings) {
        if (channelSettings.hasOwnProperty(key) && key !== 'autoScaleYAxis') {
          const setting = channelSettings[key];
          if (setting.channels && setting.channels.length > 0) {
            // set y axis's min max for filtering data series
            if (isNumber(setting.min) && isNumber(setting.max) && !channelSettings.autoScaleYAxis) {
              extraInfo.yAxisMin = setting.min;
              extraInfo.yAxisMax = setting.max;
            }
            const seriesData = this.getDataSeriesFromChannels(dataInfo, yAxisIndex, setting.channels, inputData, timeFormat, dataType, extraInfo, masterDataInfo);

            result = result.concat(seriesData);
          }
          yAxisIndex++;
        }
      }
    }

    return result;
  }

  getDataSeriesFromChannels(
    dataInfo: IInputDataTreatment,
    yAxisIndex: number,
    channels: IChannelSettingItemModel[], inputData?: IInputDataChart, timeFormat?: ChartTimeFormat, dataType?: string, extraInfo?: ISeriesDataExtraInfo,
    masterDataInfo?: IInputDataTreatment
  ) {
    extraInfo = isObject(extraInfo) ? extraInfo : {};
    let result = [];
    if (channels && inputData) {
      let values = [];
      const columns = inputData.columns;
      let xAxisChannelName = DATETIME_CHANNEL_NAME;
      if (timeFormat && timeFormat.toLowerCase() === TIME_SETTING_TYPE.OffsetTimeLower) {
        xAxisChannelName = TIMEFLOAT_CHANNEL_NAME;
        // set fracpro time float channel name
        if (dataType === 'fracproData') {
          xAxisChannelName = FRACPRO_TIMEFLOAT_CNAME;
        }
      }
      // find index of time channel
      const timeIndex = columns.findIndex(value => value === xAxisChannelName);

      result = channels.map((channelItem, index) => {
        const measureType = channelItem.measureType;
        let dataSeries = [];
        const dataIndex = typeof yAxisIndex === 'number' && !isNaN(yAxisIndex) ? yAxisIndex : index;
        // find index of channel setting
        const channelIndex = columns.findIndex(value => value === channelItem.cName);

        // filter valid value
        values = cloneDeep(inputData.values);
        values = values.filter(dataRow => {
          const yValue = dataRow[channelIndex];

          return isNumber(yValue) && !isNaN(yValue);
        });
        if (isNumber(extraInfo.yAxisMin) && isNumber(extraInfo.yAxisMax) && extraInfo.yAxisMin <= extraInfo.yAxisMax) {
          values = values.map(dataRow => {
            const yValue = dataRow[channelIndex];
            // y_plot = min (y_max, max (y_min, y))
            dataRow[channelIndex] = Math.min(extraInfo.yAxisMax, Math.max(extraInfo.yAxisMin, yValue));

            return dataRow;
          });
        }

        // parse to chart data point
        const seriesId = `${dataInfo.treatmentId}_${channelItem.channelName || channelItem.name}`;
        let masterSeriesId;
        if (masterDataInfo) {
          masterSeriesId = `${masterDataInfo.treatmentId}_${channelItem.channelName || channelItem.name}`;
        }
        values.forEach(dataRow => {
          let xValue = dataRow[timeIndex];
          if (xAxisChannelName === DATETIME_CHANNEL_NAME) {
            xValue = new Date(xValue).getTime();
          }
          // covert x value from second to min (time float value response from api is in second)
          if (xAxisChannelName === TIMEFLOAT_CHANNEL_NAME || xAxisChannelName === FRACPRO_TIMEFLOAT_CNAME) {
            xValue = Helper.convertSecToMin(xValue);
          }

          // const yValue = dataRow[channelIndex] * 1;
          const yValue = measureType && measureType.trim() ? this.unitSystemService.convert(dataRow[channelIndex], measureType) : (dataRow[channelIndex] as number);
          const point = { x: xValue, y: yValue, groupId: seriesId };
          // const point = [xValue, yValue];
          dataSeries.push(point);
        });

        // pre-sort data series
        if (dataSeries.length > 1) {
          dataSeries = dataSeries.sort((a, b) => {
            // return a[0] - b[0];
            return a.x - b.x;
          });
        }

        const unit = measureType && measureType.trim() ? this.unitSystemService.getUnit(measureType) : channelItem.unit;
        const channelUnit = Helper.getValidUnit(unit, true);
        // const seriesName = `${channelItem.channelName || channelItem.name} ${channelUnit}`;
        const seriesName = `${extraInfo.seriesPrefix || ''}${channelItem.channelName || channelItem.name} ${channelUnit}`;
        const seriesColor = extraInfo.seriesColor ? extraInfo.seriesColor : channelItem.color;

        return {
          id: seriesId,
          name: seriesName,
          type: 'line',
          data: dataSeries,
          yAxis: dataIndex,
          color: seriesColor,
          boostThreshold: 10000,
          connectNulls: true,
          linkedTo: masterSeriesId
        };
      });
    }
    return result;
  }

  parseSingleTreatmentSeriesData(channelSettings, singleTreatmentInputData: IInputDataTreatment, timeFormat, color?: string, masterDataInfo?: IInputDataTreatment) {
    // parse all flow input data to chart's series data
    let seriesData = [];
    if (singleTreatmentInputData) {
      // generate series data name
      let extraSpace = ' - ';
      if (singleTreatmentInputData.treatmentNumber < 10) {
        extraSpace = ' --- ';
      }
      const treatmentName = `Treatment ${singleTreatmentInputData.treatmentNumber}${extraSpace}`;
      const extraInfo = {
        seriesPrefix: treatmentName,
        seriesColor: color,
      };
      const dataCollection = {
        realtimeData: singleTreatmentInputData.realtimeData,
        fracproData: singleTreatmentInputData.fracproData
      };
      for (const dataType in dataCollection) {
        if (dataCollection.hasOwnProperty(dataType)) {
          if (!isEmpty(dataCollection[dataType])) {
            const dataFlow = dataCollection[dataType][0];
            const prefixChannel = this.channelSelectionService.getPrefixChannelByDataType(dataType);
            const channelSettingsByDataType = this.channelSelectionService.filterChannelsByPrefixStr(channelSettings, prefixChannel);

            const parsedData = this.parseSeriesDataByChannels(singleTreatmentInputData, channelSettingsByDataType, dataFlow, timeFormat, dataType, extraInfo, masterDataInfo);
            seriesData = seriesData.concat(parsedData);
          }
        }
      }
    }
    return seriesData;
  }

  parseMultiTreatmentSeriesData(channelSettings, multiTreatmentInputData: IInputDataTreatment[], timeFormat, pivotPointData?) {
    let seriesData = [];
    if (!isEmpty(multiTreatmentInputData)) {
      let firstTreatmentAsMaster = null;
      multiTreatmentInputData.forEach((treatmentData, index) => {
        if (index !== 0) {
          firstTreatmentAsMaster = multiTreatmentInputData[0];
        }
        if (treatmentData && channelSettings) {
          let parsedData = [];
          // update time value by pivot point data
          if (!isEmpty(pivotPointData)) {
            const treatmentShiftedData = this.pivotPointService.handleUpdateTimeByPivotData(treatmentData, pivotPointData);
            // parse chart series data for each treatment
            parsedData = this.parseSingleTreatmentSeriesData(channelSettings, treatmentShiftedData, timeFormat, this.treatmentColors[index], firstTreatmentAsMaster);
          } else {
            // parse chart series data for each treatment
            parsedData = this.parseSingleTreatmentSeriesData(channelSettings, treatmentData, timeFormat, this.treatmentColors[index], firstTreatmentAsMaster);
          }
          seriesData = seriesData.concat(parsedData);
        }
      });
    }

    seriesData = this.changeColorFollowColorMode(channelSettings, seriesData);

    return seriesData;
  }

  changeColorFollowColorMode(channelSettings: IChannelSettingModel, seriesData) {
    const settings = cloneDeep(channelSettings);
    for (const key in settings) {
      if (settings.hasOwnProperty(key)) {
        const setting = settings[key];
        if (setting && !isEmpty(setting.channels)) {
          forEach(setting.channels, item => {
            let treatmentData = seriesData.filter(i => i.id.includes(item.channelName));
            if (item.colorMode === 'Random') {
              treatmentData = treatmentData.map((data, index) => {
                data.color = this.treatmentColors[index];
                return data;
              });
            } else if (item.colorMode === 'Flat') {
              treatmentData = treatmentData.map((data, index) => {
                data.color = item.color;
                return data;
              });
            } else {
              let colorDarken;
              let colorLighten;
              const countTreatmentData = treatmentData.length;
              const colorChannelDark = new tinycolor(item.color);
              const colorChannelLight = new tinycolor(item.color);
              if (colorChannelDark.isDark()) {
                colorDarken = colorChannelDark.darken(20).toHexString();
                if (colorChannelDark.getBrightness() < 25) {
                  colorDarken = colorChannelDark.lighten(10).toHexString();
                }
                colorLighten = colorChannelLight.lighten(40).toHexString();
              } else if (colorChannelLight.isLight()) {
                colorLighten = colorChannelLight.lighten(20).toHexString();
                if (colorChannelLight.getBrightness() > 240) {
                  colorLighten = colorChannelLight.darken(10).toHexString();
                }
                colorDarken = colorChannelDark.darken(40).toHexString();
              }
              const listColor = new tinygradient([
                colorDarken,
                colorLighten
              ]);
              if (countTreatmentData >= 2) {
                const listColorPicker = listColor.rgb(this.maxTreatmentList).map((value) => {
                  return value.toString();
                });
                treatmentData = treatmentData.map((data, index) => {
                  data.color = listColorPicker[index];
                  return data;
                });
              } else {
                const listColorDefault = [colorDarken, colorLighten];
                treatmentData = treatmentData.map((data, index) => {
                  data.color = listColorDefault[index];
                  return data;
                });
              }
            }
          });
        }
      }
    }
    return seriesData;
  }

  updateChart(inputData, channelSettings, filterSettings, pivotPointData?: IPivotPointItem[], navigatorInputData?) {
    if (inputData && channelSettings && filterSettings) {
      this.setupChart();
      this.isLoadingChart = true;
      this.loadChartData(channelSettings, inputData, filterSettings.type, pivotPointData, navigatorInputData).subscribe(() => {
        this.isLoadingChart = false;
        this.highChart.redraw();
        this.highChart.setSize(this.highChart.chartWidth, this.highChart.chartHeight);
        this.highChart.options.chart.width = null;
        this.highChart.options.chart.height = null;
        this.ref.detectChanges();
      });
    }
  }

  resetChart(isRedraw?: boolean) {
    if (this.highChart) {
      this.chartOptions.xAxis = [];
      this.chartOptions.yAxis = [];
      this.chartOptions.series = [];
      // clear chart and reload data
      while (this.highChart.series.length > 0) {
        this.highChart.series[0].remove(false);
      }
      while (this.highChart.yAxis.length > 0) {
        this.highChart.yAxis[0].remove(false);
      }
      while (this.highChart.xAxis.length > 0) {
        this.highChart.xAxis[0].remove(false);
      }

      if (isRedraw) {
        this.highChart.redraw();
      }
    }
  }

  setChartMessage(message: string) {
    if (typeof message === 'string' && this.highChart) {
      const noDataEle = document.querySelector('.highcharts-no-data');
      if (noDataEle && noDataEle.children[0]) {
        noDataEle.children[0].innerHTML = message;
        this.messageNoData = message;
      }
    }
  }

  zoomOut() {
    this.highChart.zoomOut();
  }

  reflow() {
    this.highChart.reflow();
  }

  toggleTooltip(isDisplay) {
    if (this.highChart) {
      this.highChart.update({
        tooltip: {
          enabled: isDisplay
        }
      });
    }
  }

  toggleDragDropSeries() {
    if (this.highChart) {
      this.highChart.update({
        plotOptions: {
          series: {
            dragDrop: {
              draggableX: true,
              groupBy: 'groupId'
            },
            stickyTracking: false
          }
        },
        tooltip: {
          shared: false
        }
      }, false);
    }
  }

  generateRandomTreatmentColors() {
    const colors = randomColors({
      luminosity: 'bright',
      count: 200
    });
    return colors;
  }

  exportChart(fileName?: string, type?: string) {
    const chart = this.highChart;
    if (type === 'Save PNG') {
      chart.exportChartLocal({
        filename: `${fileName}`,
        sourceWidth: chart.chartWidth,
        sourceHeight: chart.chartHeight,
        scale: 4,
        type: 'image/png'
      }, {
        lang: {
          noData: `${this.messageNoData}`
        },
      });
    } else if (type === 'Save JPEG') {
      chart.exportChartLocal({
        filename: `${fileName}`,
        sourceWidth: chart.chartWidth,
        sourceHeight: chart.chartHeight,
        scale: 8,
        type: 'image/jpeg'
      }, {
        lang: {
          noData: `${this.messageNoData}`
        },
      });
    } else {
      chart.print();
    }
  }

  parseNavigatorSeriesData(channelSettings, navigatorInputData, timeFormat?, pivotPointData?) {
    let seriesData = [];
    seriesData = this.parseMultiTreatmentSeriesData(channelSettings, navigatorInputData, timeFormat, pivotPointData);
    seriesData = seriesData.map(series => {
      let firstDataPoint;
      let verticalLineData = [];
      if (!isEmpty(series.data)) {
        firstDataPoint = series.data[0];
        verticalLineData = [{ x: firstDataPoint.x, y: 0 }, { x: firstDataPoint.x, y: 10 }];
      }

      return {
        type: 'line',
        fillOpacity: 0.5,
        dataGrouping: {
          groupPixelWidth: 10
        },
        data: verticalLineData,
        color: 'red'
      };
    });

    return seriesData;
  }

}

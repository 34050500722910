import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ICompareWellsTimeControl } from './compare-wells-time-control.model';

@Injectable()
export class CompareWellsTimeControlService {
  private dataState: BehaviorSubject<ICompareWellsTimeControl> = new BehaviorSubject(this.getInitialState());

  public dataRangeDateTime: Subject<{startTime: string; endTime: string}> = new Subject();

  constructor() { }

  getInitialState(): ICompareWellsTimeControl {
    return {
      startTime: '',
      endTime: '',
      isAutoLoad: false,
      isWellColor: true,
      lastDays: 'None'
    }
  }

  setDataState(data: ICompareWellsTimeControl) {
    this.dataState.next(data);
  }

  getDataState(): Observable<ICompareWellsTimeControl> {
    return this.dataState.asObservable();
  }

  restDataState() {
    this.dataState.next(this.getInitialState());
  }
}

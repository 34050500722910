import { Injectable } from '@angular/core';
import { forEach, some, isEmpty } from 'lodash';
import { IChannelItem, IChannelSettingModel, IInputDataChart, IInputDataMultiFlow } from '../../../../shared/models';
import { IOffsetWellCompare } from '../../../../shared/models/offset-well-compare.model';
import { ChartsService, ChannelSelectionService } from '../../../../shared/services';

@Injectable()
export class RealtimeTreatmentService {

  constructor(
    private chartsService: ChartsService,
    private channelSelectionService: ChannelSelectionService,
  ) { }

  getChartData(
    wellId,
    treatmentId,
    channelsMap: string[],
    flowPathType?: number
  ): Promise<IInputDataMultiFlow> {
    if (wellId) {
      flowPathType = typeof flowPathType === 'number' && !isNaN(flowPathType) ? flowPathType : 0;
      const requestBody = {
        wellId,
        treatmentId,
        items: channelsMap,
        flowPathType
      };
      return this.chartsService.getDataChart(requestBody).toPromise().then(res => {
        if (res && res.result && res.result) {
          return Promise.resolve(res.result);
        }
        return Promise.resolve(res);
      });
    } else {
      return Promise.reject(new Error('Invalid wellId'));
    }
  }

  getOffsetWellCompareData(channelSettings: IChannelSettingModel, offsetWellCompareSettings: IOffsetWellCompare, flowPathType?: number): Promise<IInputDataMultiFlow> {
    if (channelSettings && offsetWellCompareSettings) {
      const wellId = offsetWellCompareSettings.wellId;
      const treatmentId = offsetWellCompareSettings.treatmentId;
      const offsetWellChannelSettings = this.channelSelectionService.filterChannelsByAxisType(channelSettings, offsetWellCompareSettings.axisType);

      const channelsMap = this.channelSelectionService.toRequestChannelMap(offsetWellChannelSettings);
      return this.getChartData(wellId, treatmentId, channelsMap, flowPathType);
    } else {
      return Promise.resolve(null);
    }
  }

  sameChannelData = (currentData: IInputDataMultiFlow, inputData: IInputDataMultiFlow): boolean => {
    if (!currentData && !inputData) return true;
    if (!currentData && inputData) return false;
    if (currentData && !inputData) return false;

    const compare = (data: IInputDataChart[], inData: IInputDataChart[]): boolean => {
      if (!data && !inData) return true;
      if (!data[0] && !inData[0]) return true;
      if (!data[0] && inData[0]) return false;
      if (data[0] && !inData[0]) return false;

      if (!data[0].values.length && !inData[0].values.length) return true;
      if (data[0].columns.length !== inData[0].columns.length) return false;
      if (data[0].values.length !== inData[0].values.length) return false;
      return true;
    };

    const sameRealtime = compare(currentData.realtimeData, inputData.realtimeData);
    const sameFracpro = compare(currentData.fracproData, inputData.fracproData);
    return sameRealtime && sameFracpro;
  }

  concatRealtimeData = (currentData: IInputDataMultiFlow, inputData: IInputDataMultiFlow, baseDateTime: string, requestDateTime: string): IInputDataMultiFlow => {
    const concat = (currData: IInputDataChart[], inData: IInputDataChart[]): IInputDataChart[] => {
      if (!currData || !currData.length) {
        currData = [];
        if (!inData[0].values || !inData[0].values.length) return currData;
        currData[0] = inData[0];
      } else {
        if (!inData[0].values || !inData[0].values.length) return currData;
        const n = currData[0].values.length;
        const m = inData[0].values.length;
        let idx = n - 1;
        const firstInData = inData[0].values[0];
        for (let i = idx; i >= 0; i--) {
          const lastCurrData = currData[0].values[i];
          if (firstInData[0] <= lastCurrData[0]) continue;
          idx = i;
          break;
        }
        for (let i = 0; i < m; i++) {
          currData[0].values[idx + i + 1] = inData[0].values[i];
        }
      }
      return currData;
    };

    if (!currentData) return inputData;
    if (!inputData) return currentData;
    // if there requestTreatmentDateTime IS baseTreatmentDateTime: return inputData
    if (baseDateTime === requestDateTime) return inputData;
    // if there is NO inputDate: return currentData
    const noInputRealtimeData = !inputData.realtimeData || !inputData.realtimeData[0];
    const noInputFracproData = !inputData.fracproData || !inputData.fracproData[0];
    if (noInputRealtimeData && noInputFracproData) return currentData;

    // else begin to concat data
    if (inputData.realtimeData && inputData.realtimeData[0]) {
      currentData.realtimeData = concat(currentData.realtimeData, inputData.realtimeData);
    }
    if (inputData.fracproData && inputData.fracproData[0]) {
      currentData.fracproData = concat(currentData.fracproData, inputData.fracproData);
    }

    const res: IInputDataMultiFlow = {
      realtimeData: [],
      fracproData: [],
      equipmentData: []
    };
    if (currentData.realtimeData && currentData.realtimeData[0]) res.realtimeData[0] = currentData.realtimeData[0];
    if (currentData.fracproData && currentData.fracproData[0]) res.fracproData[0] = currentData.fracproData[0];
    if (currentData.equipmentData && currentData.equipmentData[0]) res.equipmentData[0] = currentData.equipmentData[0];
    return res;
  }

}

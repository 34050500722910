import { Injectable } from '@angular/core';
import { of, from, Observable } from 'rxjs';
import { delay, map, mergeMap, filter } from 'rxjs/operators';
import { TreatmentService } from '../../shared/services/treatment.service';
import { DataFilesService } from '../../shared/services/data-files.service';

export interface ITreatmentAvgMDItem {
  id: number;
  name: string;
  avgMD: number;
}

@Injectable()
export class WellsCompareLogDataService {

  constructor(
    private treatmentService: TreatmentService,
    private dataFilesService: DataFilesService
  ) { }

  getLogDataFiles(wellId) {
    return this.dataFilesService.getByWell(wellId).pipe(map(res => {
      return res.result.filter(file => {
        return file.dataFile && file.dataFile.indexOf('.las') > -1;
      });
    }))
      .pipe(map(res => {
        return res.map(item => ({
          id: item.dataFile,
          text: item.dataFile
        }));
      }));
  }

  getListCurves(fileName, wellId) {
    return this.dataFilesService.getCurvesDasFile(fileName, wellId).pipe(map(res => {
      return res.result;
    })).pipe(map(res => {
      return res.map(item => ({
        id: item.name,
        unit: item.unit,
        text: item.name
      }));
    }));
  }

  getLogDataCurve(fileName, wellId, curve) {
    return this.dataFilesService.getLogDataCurveDasFile(fileName, wellId, curve.toLowerCase()).pipe(map(res => {
      return {
        columns: ['DEPTH', `${curve}`],
        values: res.result.map(item => {
          return [item.depth, item.column];
        })
      };
    }));
  }

  getTreatmentAvgMD(wellId, treatmentId, treatmentName): Observable<ITreatmentAvgMDItem> {
    return this.treatmentService.getTreatmentDetail(wellId, treatmentId).pipe(
      map(res => {
        let avgMD = null;
        if (res.result) {
          avgMD = (res.result.topPerfMD + res.result.bottomPerfMD) / 2;
        }
        return {
          id: treatmentId,
          name: treatmentName,
          avgMD: avgMD
        };
      })
    );
  }

  getTreatmentDetails(wellId, treatmentId, treatmentName): Observable<ITreatmentAvgMDItem> {
    return this.treatmentService.getTreatmentDetail(wellId, treatmentId).pipe(
      map(response => {
        if (!response.result) return null;
        const treatmentDetail = response.result;
        const avgMD = (treatmentDetail.topPerfMD + treatmentDetail.bottomPerfMD) / 2;
        return {
          id: treatmentId,
          name: treatmentName,
          wellId: wellId,
          avgMD: avgMD,
          desPumpTime: treatmentDetail.desPumpTime / 60, // sec to min
          desCleanVol: treatmentDetail.desCleanVol,
          desSlurryVol: treatmentDetail.desSlurryVol,
          totalPumpTime: treatmentDetail.totalPumpTime / 60, // sec to min
          baseFluidVol: treatmentDetail.baseFluidVol,
          totalVolume: treatmentDetail.totalVolume
        };
      })
    );
  }

  getTreatmentDataForCompareWell(wellId, treatmentId: number, treatmentName: string): Observable<ITreatmentAvgMDItem> {
    return this.treatmentService.getTreatmentData(wellId, treatmentId)
      .pipe(map(treatmentDetail => {
        if (!treatmentDetail) return null;
        const avgMD = (treatmentDetail.topPerfMD + treatmentDetail.bottomPerfMD) / 2;
        return {
          id: treatmentId,
          name: treatmentName,
          wellId: wellId,
          avgMD: avgMD,
          desPumpTime: treatmentDetail.desPumpTime / 60, // sec to min
          desCleanVol: treatmentDetail.desCleanVol,
          desSlurryVol: treatmentDetail.desSlurryVol,
          totalPumpTime: treatmentDetail.totalPumpTime / 60, // sec to min
          baseFluidVol: treatmentDetail.baseFluidVol,
          totalVolume: treatmentDetail.totalVolume
        };
      }));
  }

  getTreatmentsDataForCompareWell(wellId, treatmentsInput?: { treatmentId: number, treatmentNumber: number, treatmentName?: string }[]): Observable<ITreatmentAvgMDItem[]> {
    const treatmentIds = treatmentsInput.map(item => item.treatmentId);
    return this.treatmentService.getTreatmentsData(wellId, treatmentIds).pipe(
      map(response => {
        return response.map(treatment => {
          const topPerfMD = !treatment.topPerfMD ? 0 : treatment.topPerfMD;
          const bottomPerfMD = !treatment.bottomPerfMD ? 0 : treatment.bottomPerfMD;
          const treatmentExist = treatmentsInput.find(item => item.treatmentNumber === treatment.number);
          const treatmentId = treatmentExist ? treatmentExist.treatmentId : 0;
          const treatmentName = treatmentExist.treatmentName ? treatmentExist.treatmentName : treatmentExist.treatmentNumber.toString();

          return {
            id: treatmentId,
            name: treatmentName,
            wellId: wellId,
            avgMD: (topPerfMD + bottomPerfMD) / 2,
            desPumpTime: treatment.desPumpTime / 60, // sec to min
            desCleanVol: treatment.desCleanVol,
            desSlurryVol: treatment.desSlurryVol,
            totalPumpTime: treatment.totalPumpTime / 60, // sec to min
            baseFluidVol: treatment.baseFluidVol,
            totalVolume: treatment.totalVolume
          };
        });
      })
    );
  }
}

import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_offline_exporting from 'highcharts/modules/offline-exporting';

import HC_annotations from 'highcharts/modules/annotations';
import { isEmpty, cloneDeep, forEach, isObject, isNumber, maxBy, capitalize } from 'lodash';
import HC_boost from 'highcharts/modules/boost';
HC_annotations(Highcharts);
HC_exporting(Highcharts);
HC_offline_exporting(Highcharts);
HC_boost(Highcharts);
const noData = require('highcharts/modules/no-data-to-display');
noData(Highcharts);

const randomColors = require('randomcolor');
import * as tinygradient from 'tinygradient';
import * as tinycolor from 'tinycolor2';

import { IChannelSettingModel, IChannelSettingItemModel } from '../../../../shared/models/channel.model';
import { BaseChartService, ISeriesDataExtraInfo, DATETIME_CHANNEL_NAME, TIMEFLOAT_CHANNEL_NAME, FRACPRO_TIMEFLOAT_CNAME, ENUM_CURSOR_OPTS } from '../../../../shared/services/charts/base-chart.service';
import { ChannelSelectionService } from '../../../services';
import { PivotPointService, IPivotPointItem } from '../../../../shared/services/pivot-point.service';
import { of, Subscription } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';
import { CHART_MESSAGES, CHART_SERIES_NAME, ENUM_THEMES, PREFIX_PREDICTIVE_CHANNEL, TIME_SETTING_TYPE } from '../../../helpers/app.constants';
import { IInputDataChart } from '../../../models';
import { Helper } from '../../../helpers/helper';
import { IInputDataWell, MultiWellChartService, IWellOptions } from '../../../services/charts/multi-well-chart.service';
import { CompareWellsTimeControlService } from '../../compare-wells-time-control/compare-wells-time-control.service';

import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { ThemesSettingsService } from '../../../services/themes-settings.service';
import { UnitSystemService } from '../../../services/unit-system.service';
import { IDiagnosticPoint } from '../../../models/diagnostic-points.model';
(window as any).moment = moment;
(window as any).momentTz = momentTz;

interface IInputState {
  channelSettings: IChannelSettingModel;
  inputData: IInputDataWell[];
  filterSettings?: {
    type: string;
    startIndex: number;
    endIndex: number;
    multiWellScrollbar?: boolean,
    scrollbarSamplingRate?: string
  };
  pivotPointData: IPivotPointItem[];
  navigatorInputData?: IInputDataWell[];
  wellOptions: IWellOptions[];
}

@Component({
  selector: 'app-multi-well-chart',
  templateUrl: './multi-well-chart.component.html',
  styleUrls: ['./multi-well-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiWellChartComponent implements OnInit, OnChanges, OnDestroy {
  subscriptions: Subscription[] = [];
  isWellColorMode: boolean = true;
  parsedSeriesData: any = [];

  cursor: { id: string, text: string, typeCursor: string };

  @Input()
  set inputState(state: IInputState) {
    if (state) {
      this.channelSettings = state.channelSettings;
      this.filterSettings = state.filterSettings;
      this.inputChartData = state.inputData;
      this.pivotPointData = state.pivotPointData;
      this.navigatorInputData = state.navigatorInputData;
      this.wellOptions = state.wellOptions;
    }
  }

  @Input()
  set inputReflow(isReflow: any) {
    if (this.highChart) {
      this.highChart.reflow();
    }
  }

  @Input() defaultTextData: string = '';

  @Output() onChangeXMinMax: EventEmitter<any> = new EventEmitter();
  @Output() onChangeNavigatorMinMax: EventEmitter<any> = new EventEmitter();

  inputChartData: IInputDataWell[];
  pivotPointData: IPivotPointItem[];
  navigatorInputData: IInputDataWell[];
  wellOptions: IWellOptions[];
  channelSettings: IChannelSettingModel;
  filterSettings: any = {
    type: TIME_SETTING_TYPE.OffsetTime,
    startIndex: 0,
    endIndex: 0,
    multiWellScrollbar: true,
    scrollbarSamplingRate: ''
  };

  highChart: any;
  Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  updateFlagChart: boolean = false;
  isLoadingChart = false;

  messageNoData: string = CHART_MESSAGES.en.pleaseSelectChannel;

  randomColorsList = [];
  annotationBuffer: any = {};
  isDarkTheme: boolean;

  unitSetting: number;

  constructor(
    private baseChartService: BaseChartService,
    private channelSelectionService: ChannelSelectionService,
    private pivotPointService: PivotPointService,
    private multiWellChartService: MultiWellChartService,
    private compareWellsTimeControlService: CompareWellsTimeControlService,
    private ref: ChangeDetectorRef,
    private themesSettingsService: ThemesSettingsService,
    private unitSystemService: UnitSystemService
  ) {
    const localData = this.themesSettingsService.getLocalStorage();
    if (localData && localData.themes === ENUM_THEMES.Dark) {
      this.isDarkTheme = true;
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    this.unitSetting = this.unitSystemService.loadUnitSettings();
  }

  ngOnInit() {
    this.setupChart();
    this.randomColorsList = this.generateRandomColors();

    // update chart series data by zoom buffer
    const subs = this.multiWellChartService.getZoomBufferData().subscribe((dataByTreatments: IInputDataWell[]) => {
      if (dataByTreatments) {

        // parse all treatment data
        const seriesData = this.parseMultiWellSeriesData(dataByTreatments, this.filterSettings.type, this.pivotPointData, this.unitSetting);
        this.updateChartSeriesData(seriesData);
        this.parsedSeriesData = seriesData;
      }
    });
    const subsAliasName = this.multiWellChartService.onWellAliasNameChangeSubject.asObservable().subscribe((wellOption: IWellOptions) => {
      if (wellOption && !isEmpty(wellOption)) {
        if (!isEmpty(this.annotationBuffer) && !isEmpty(wellOption.selectedTreatmentList)) {
          wellOption.selectedTreatmentList.forEach(treatment => {
            const annotationId = `${wellOption.wellId}_${treatment.id}`;
            if (this.annotationBuffer[annotationId]) {
              this.annotationBuffer[annotationId].update({
                labelOptions: {
                  text: wellOption.aliasName ? `(${wellOption.aliasName})-${treatment.text}` : treatment.text
                }
              });
            }
          });
        }
      }
    });
    const subsAliasColor = this.multiWellChartService.onWellAliasColorChangeSubject.asObservable().subscribe((wellOption: IWellOptions) => {
      if (wellOption && !isEmpty(wellOption)) {
        if (this.highChart && !isEmpty(this.highChart.series)) {
          const seriesByWellId = this.highChart.series.filter(item => item.userOptions.id && item.userOptions.id.indexOf(`${wellOption.wellId}`) === 0);
          seriesByWellId.forEach(series => {
            series.update({
              color: wellOption.wellColor
            }, false);
          });
          this.highChart.redraw();
        }
      }
    });
    const subsWellTimeControl = this.compareWellsTimeControlService.getDataState().subscribe(settings => {
      if (settings) {
        // update well color mode
        this.isWellColorMode = settings.isWellColor;
      }
    });
    this.subscriptions.push(subs);
    this.subscriptions.push(subsAliasName);
    this.subscriptions.push(subsAliasColor);
    this.subscriptions.push(subsWellTimeControl);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputState && !changes.inputState.isFirstChange()) {
      const timezone = this.wellOptions[0].tzTimeZone;
      Highcharts.setOptions({
        time: {
          getTimezoneOffset(timestamp) {
            return -momentTz.tz(timestamp, timezone).utcOffset();
          },
        }
      });

      this.updateChart(this.inputChartData, this.filterSettings, this.pivotPointData, this.unitSetting);
    }
  }

  ngOnDestroy(): void {
    Helper.unsubscribe(this.subscriptions);
    // unset zoom buffer data
    this.multiWellChartService.setZoomBufferData(null);
    this.annotationBuffer = {};
  }

  setupChart() {
    let options = this.baseChartService.getDefaultOptions(true, true, this.isDarkTheme, this.cursor);

    const cursorOpt = this.cursor ? this.cursor.id : ENUM_CURSOR_OPTS.Default;
    const crosshair = this.baseChartService.crosshair(cursorOpt);
    const isXAxisCrosshair = !!crosshair.x;
    const isYAxisCrosshair = !!crosshair.y;

    const xAxisOpt = this.baseChartService.getXAxisOption(this.filterSettings.type, null, null, true, isXAxisCrosshair);
    const self = this;
    // bind set extreme event
    xAxisOpt.events = {
      setExtremes(e) {
        if (e && e.trigger === 'navigator') {
          self.onChangeNavigatorMinMax.emit(e);
        }
      }
    };
    options.xAxis = [xAxisOpt];
    options.yAxis = this.baseChartService.getYAxisOption(this.channelSettings, undefined, this.unitSetting, isYAxisCrosshair);
    // const tooltipShared = !this.cursor ? false : (this.cursor.typeCursor === ENUM_CURSOR_OPTS.VertLine);
    // const tooltipSplit = !this.cursor ? false : (this.cursor.typeCursor === ENUM_CURSOR_OPTS.VertLine);

    // add diagnostic Axis
    if (this.filterSettings.type !== TIME_SETTING_TYPE.EpochTime) {
      // add a virsual yAxis for vertical line series
      const diagnoctisAxisOpt: Highcharts.YAxisOptions = {
        title: {
          text: capitalize(CHART_SERIES_NAME.diagnostic),
          useHTML: false,
        },
        opposite: false,
        showEmpty: false,
        min: 0,
        max: 100,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: false,
        visible: false
      };
      options.yAxis.push(diagnoctisAxisOpt);
    }
    options.tooltip = Object.assign(options.tooltip, {
      headerFormat: this.baseChartService.getTooltipHeader(this.filterSettings.type),
      shared: false,
      split: false
    });

    options.legend.labelFormatter = function () {
      // case 1: if this is monitor
      if (this.userOptions.treatmentNumber === 'Monitor') {
        const arr = this.name.split(' - Monitor - ');
        return arr[arr.length - 1].trim();
      }
      // case 2: if this is treatment
      else {
        const arr = this.name.split('-');
        return arr[arr.length - 1].trim();
      }
    };

    this.chartOptions = options;
    if (this.defaultTextData) {
      this.chartOptions.lang.noData = this.defaultTextData;
    }
    this.chartOptions.chart.events = {
      selection(event) {
        const result = { minX: null, maxX: null, isReset: false };
        if (event.xAxis) {
          result.minX = event.xAxis[0].min;
          result.maxX = event.xAxis[0].max;
        } else {
          const xAxisData = this.xAxis[0].getExtremes();
          result.minX = xAxisData.dataMin;
          result.maxX = xAxisData.dataMax;
          result.isReset = true;
        }
        self.onChangeXMinMax.emit(result);

        return true;
      }
    };

    setTimeout(() => {
      if (this.filterSettings.type === TIME_SETTING_TYPE.EpochTime) {
        // parse all treatment data
        const seriesData = this.parseMultiWellSeriesData(this.inputChartData, this.filterSettings.type, this.pivotPointData, this.unitSetting);
        this.parsedSeriesData = seriesData;
        // set empty first
        seriesData.forEach(item => {
          item.data = [];
        });
        const navigatorSeriesData = this.parseNavigatorSeriesData(this.navigatorInputData, this.filterSettings.type, this.pivotPointData, this.unitSetting);

        options = Object.assign(options, {
          navigator: {
            adaptToUpdatedData: false,
            enabled: !!this.filterSettings.multiWellScrollbar,
            handles: {
              enabled: true
            },
            series: navigatorSeriesData,
            margin: 5,
            maskFill: !this.isDarkTheme ? 'rgba(102,133,194,0.3)' : 'rgba(51, 77, 128, 0.3)',
            outlineColor: !this.isDarkTheme ? '#cccccc' : '#e8e6e3',
            xAxis: {
              labels: {
                formatter() {
                  const utcTimestamp = new Date(this.value).getTime();
                  return Highcharts.dateFormat('%m/%d %H:%M', utcTimestamp);
                },
                style: { color: !this.isDarkTheme ? '#000' : '#e8e6e3' }
              }
            }
          },
          scrollbar: {
            liveRedraw: false,
            enabled: !!this.filterSettings.multiWellScrollbar,
          },
          series: seriesData
        });

        options.rangeSelector = {
          enabled: false,
          selected: 0,
          buttons: [{
            type: 'day',
            count: 1,
            text: '1d'
          }, {
            type: 'week',
            count: 1,
            text: '1w'
          }, {
            type: 'month',
            text: '1m'
          }, {
            type: 'all',
            text: 'All'
          }],
          inputEnabled: false
        };
        // options.tooltip.split = false;
        this.chartOptions = options;
        this.highChart = Highcharts.stockChart('multi-wells-highstock-chart', options);
      } else {
        this.highChart = Highcharts.chart('multi-wells-highstock-chart', this.chartOptions);
      }
    }, 200);

  }

  private getPivotSeriesData(diagPoint: IDiagnosticPoint, index, groupId: string, timeFormat: string, inputData): { x: number, y: number, groupId: string, name: string }[] {
    if (timeFormat === TIME_SETTING_TYPE.EpochTime || !inputData || !diagPoint) return null;
    let offsetTimeSec = (index + 1) * 2; // default when unset
    let timefloat: { start: number, end: number } = { start: null, end: null }; // in second

    if (inputData.realtimeData && inputData.realtimeData.length) {
      const timeRange = this.baseChartService.getTimeRangeFilter(inputData.realtimeData[0]);
      timefloat = timeRange.timefloat;
    } else if (inputData.fracproData && inputData.fracproData.length) {
      const timeRange = this.baseChartService.getTimeRangeFilter(inputData.fracproData[0]);
      timefloat = timeRange.timefloat;
    }

    if (isNumber(diagPoint.timeOffSet)) {
      offsetTimeSec = diagPoint.timeOffSet; // in sec
      if (timefloat.start && diagPoint.timeOffSet < timefloat.start) offsetTimeSec = timefloat.start;
      if (timefloat.end && diagPoint.timeOffSet > timefloat.end) offsetTimeSec = timefloat.end;
    }
    const formatOffsetTime = (value: number): number => {
      if (!value) return value;
      const result = (value / 60).toFixed(2);
      return Number(result);
    };

    const res: { x: number, y: number, groupId: string, name: string }[] = [];
    const xValue = formatOffsetTime(offsetTimeSec);
    const name = diagPoint.name;
    for (let i = 0; i <= 100; i++) {
      res.push({ x: xValue, y: i, groupId, name });
    }
    return res;
  }

  private loadPivotPoints(diagPoint: IDiagnosticPoint, index, keyOpt: string, timeFormat: string, inputData, yAxisData) {
    if (timeFormat === TIME_SETTING_TYPE.EpochTime || !inputData || !diagPoint) return null;
    const name = CHART_SERIES_NAME.diagnostic;
    const groupId = `${keyOpt}_${diagPoint.id}`;
    const data = this.getPivotSeriesData(diagPoint, index, groupId, timeFormat, inputData);

    return {
      id: groupId,
      name,
      type: 'line',
      tooltip: true,
      data,
      showInLegend: false,
      yAxis: yAxisData.length - 1,
      color: diagPoint.color,
      lineWidth: 5,
      dragDrop: {
        draggableX: false,
        groupBy: 'groupId',
        dragSensitivity: 0,
        dragPrecisionX: 1
      },
      boostThreshold: 20000,
      stickyTracking: false,
      visible: !!diagPoint.selected,
    };
  }

  parsePivotPointData(pivotPointData: IPivotPointItem[], inputDataList: IInputDataWell[], timeFormat: string) {
    const seriesData = [];
    if (!pivotPointData || !pivotPointData.length) return seriesData;
    const maxPivotPoint = this.pivotPointService.getMaxPivotPoint(pivotPointData);

    const diagPoint: IDiagnosticPoint = {
      id: `${maxPivotPoint.paramName}_${maxPivotPoint.paramValue}`,
      name: maxPivotPoint.paramNameDisplay || maxPivotPoint.paramName,
      color: maxPivotPoint.color,
      timeOffSet: maxPivotPoint.paramValue,
      selected: true,
      isDefault: true
    };

    const index = pivotPointData.findIndex(x => x.treatmentId === maxPivotPoint.treatmentId);
    const inputData: IInputDataWell = inputDataList.find(data => data.treatmentId === maxPivotPoint.treatmentId);
    const series = this.loadPivotPoints(diagPoint, index, 'diag', timeFormat, inputData, this.highChart.yAxis);
    seriesData.push(series);

    return seriesData;
  }

  loadChartData(inputData: IInputDataWell[], timeFormat, pivotPointData?: IPivotPointItem[], unitSetting?: number) {
    const renderChart$ = of(null)
      .pipe(delay(1000))
      .pipe(switchMap(() => {
        // console.log('begin parse series: ', new Date());
        // parse all treatment data
        let seriesData = this.parseMultiWellSeriesData(inputData, timeFormat, pivotPointData, unitSetting);
        let pivotSeries = this.parsePivotPointData(pivotPointData, inputData, timeFormat);
        // check if all series is empty data then return empty array (prevent show series legend on chart)
        if (this.baseChartService.isAllSeriesDataEmpty(seriesData)) {
          seriesData = pivotSeries;
        } else seriesData = seriesData.concat(pivotSeries);
        return of(seriesData);
      }))
      .pipe(tap(seriesData => {
        this.parsedSeriesData = seriesData;
        if (this.highChart) {
          if (isEmpty(seriesData)) {
            this.setChartMessage(CHART_MESSAGES.en.noData);
          }
          this.updateChartSeriesData(seriesData, true);
          // console.log(seriesData);
          // console.log('add series done: ', new Date());
          // add annotations
          if (timeFormat === TIME_SETTING_TYPE.EpochTime) {
            this.toggleAliasNameAnnotation(false, false, this.filterSettings.showWellAliasInTag);
          }
          this.highChart.redraw();
        }
      }));

    return renderChart$;
  }

  toggleAliasNameAnnotation(isRedraw: boolean = true, isRemove: boolean = false, isAlias?: boolean) {
    if (this.parsedSeriesData) {
      const aliasNameAnnotations = this.parseAliasNameAnnotation(this.wellOptions, this.parsedSeriesData, isAlias);
      if (!isEmpty(aliasNameAnnotations)) {
        Object.keys(aliasNameAnnotations).forEach(id => {
          if (aliasNameAnnotations[id]) {
            if (isRemove) {
              this.highChart.removeAnnotation(id, false);
            }
            const annotation = this.highChart.addAnnotation(aliasNameAnnotations[id], false);
            this.annotationBuffer[id] = annotation;
          }
        });
      }
      if (isRedraw) {
        this.highChart.redraw();
      }
    }
  }

  updateChartSeriesData(seriesData: any[], noRedraw?: boolean) {
    if (!isEmpty(seriesData)) {
      if (!isEmpty(this.highChart.series)) {
        let pivotSeries = seriesData.filter(series => series.name === CHART_SERIES_NAME.diagnostic);
        this.highChart.series.forEach(series => {
          const seriesItem = seriesData.find(item => {
            return item.name === series.options.name;
          });
          if (seriesItem) {
            pivotSeries = pivotSeries.filter(series => series.name !== seriesItem.name);
            if (seriesItem.name === CHART_SERIES_NAME.diagnostic) series.setVisible(!!seriesItem.selected);
            series.setData(seriesItem.data, false);
          }
        });
        // check pivot item
        pivotSeries.forEach(series => {
          this.highChart.addSeries(series, false, false);
        });
      } else {
        // name
        seriesData.forEach(series => {
          this.highChart.addSeries(series, false, false);
        });
      }

      if (!noRedraw) {
        this.highChart.redraw();
      }
    }
  }

  parseSeriesDataByChannels(dataInfo: IInputDataWell, channelSettings: IChannelSettingModel, inputData?: IInputDataChart, timeFormat?: string, dataType?: string, extraInfo?: ISeriesDataExtraInfo, masterDataInfo?: IInputDataWell, unitSetting?: number) {
    extraInfo = isObject(extraInfo) ? extraInfo : {};

    let result = [];
    if (channelSettings && inputData) {
      let yAxisIndex = 0;
      for (const key in channelSettings) {
        if (channelSettings.hasOwnProperty(key) && key !== 'autoScaleYAxis') {
          const setting = channelSettings[key];
          if (setting.channels && setting.channels.length > 0) {
            // set y axis's min max for filtering data series
            if (isNumber(setting.min) && isNumber(setting.max) && !channelSettings.autoScaleYAxis) {
              extraInfo.yAxisMin = setting.min;
              extraInfo.yAxisMax = setting.max;
            }
            const seriesData = this.getDataSeriesFromChannels(dataInfo, yAxisIndex, setting.channels, inputData, timeFormat, dataType, extraInfo, masterDataInfo, unitSetting);
            result = result.concat(seriesData);
          }
          yAxisIndex++;
        }
      }
    }

    return result;
  }

  getDataSeriesFromChannels(dataInfo: IInputDataWell, yAxisIndex: number, channels: IChannelSettingItemModel[], inputData?: IInputDataChart, timeFormat?: string, dataType?: string, extraInfo?: ISeriesDataExtraInfo, masterDataInfo?: IInputDataWell, unitSetting?: number) {
    extraInfo = isObject(extraInfo) ? extraInfo : {};
    let result = [];
    if (channels && inputData) {
      let values = [];
      const columns = inputData.columns;
      let xAxisChannelName = DATETIME_CHANNEL_NAME;
      if (timeFormat && timeFormat.toLowerCase() === TIME_SETTING_TYPE.OffsetTimeLower) {
        xAxisChannelName = TIMEFLOAT_CHANNEL_NAME;
        // set fracpro time float channel name
        if (dataType === 'fracproData') {
          xAxisChannelName = FRACPRO_TIMEFLOAT_CNAME;
        }
      }
      // find index of time channel
      const timeIndex = columns.findIndex(value => value === xAxisChannelName);
      result = channels.map((channelItem, index) => {
        if (channelItem.channelMappedGroup && channelItem.channelMappedGroup.length) {
          const selectedItem = channelItem.channelMappedGroup.find(x => x.wellId === dataInfo.wellId && x.treatmentId === dataInfo.treatmentId);
          if (selectedItem) {
            channelItem.cName = channelItem.channel.cName = selectedItem.cName;
            // channelItem.color = channelItem.channel.color = selectedItem.color;
            channelItem.originalName = channelItem.channel.originalName = selectedItem.originalName;
            channelItem.channelName = selectedItem.name;
            channelItem.name = channelItem.channel.name = selectedItem.name;
            // channelItem.unit = channelItem.channel.unit = selectedItem.unit;
            // channelItem.measureType = channelItem.channel.measureType = selectedItem.measureType;
          }
        } else if (dataInfo.mappedChannels && dataInfo.mappedChannels.length) {
          const mappedChannel = dataInfo.mappedChannels.find(x => x.mappedName === channelItem.name || x.mappedName === channelItem.channelName);
          if (mappedChannel) {
            channelItem.channelName = mappedChannel.mappedName;
            channelItem.name = mappedChannel.mappedName;
            channelItem.originalName = mappedChannel.originalName;
          }
        }

        const measureType = channelItem.measureType;
        const unitCoefficient = this.unitSystemService.getUnitCoefficient(measureType, unitSetting);
        let dataSeries = [];
        const dataIndex = typeof yAxisIndex === 'number' && !isNaN(yAxisIndex) ? yAxisIndex : index;
        // find index of channel setting
        const channelIndex = columns.findIndex(value => value === channelItem.cName);

        // filter valid value
        values = cloneDeep(inputData.values);
        values = values.filter(dataRow => {
          const yValue = dataRow[channelIndex];
          return isNumber(yValue) && !isNaN(yValue);
        });

        if (!values || !values.length) return null;

        if (isNumber(extraInfo.yAxisMin) && isNumber(extraInfo.yAxisMax) && extraInfo.yAxisMin <= extraInfo.yAxisMax) {
          if (unitCoefficient) {
            values = values.map(dataRow => {
              const yValue = dataRow[channelIndex];
              // y_plot = min (y_max, max (y_min, y))
              const yAxisMin = this.unitSystemService.revertValue(extraInfo.yAxisMin, unitCoefficient);
              const yAxisMax = this.unitSystemService.revertValue(extraInfo.yAxisMax, unitCoefficient);
              dataRow[channelIndex] = Math.min(yAxisMax, Math.max(yAxisMin, yValue));
              return dataRow;
            });
          } else {
            values = values.map(dataRow => {
              const yValue = dataRow[channelIndex];
              const yAxisMin = extraInfo.yAxisMin;
              const yAxisMax = extraInfo.yAxisMax;
              dataRow[channelIndex] = Math.min(yAxisMax, Math.max(yAxisMin, yValue));
              return dataRow;
            });
          }
        }

        // parse to chart data point
        const seriesId = `${dataInfo.wellId}-${dataInfo.treatmentId}_${channelItem.channelName || channelItem.name}`;
        let masterSeriesId;
        if (masterDataInfo) {
          masterSeriesId = `${masterDataInfo.wellId}-${masterDataInfo.treatmentId}_${channelItem.channelName || channelItem.name}`;
        }
        if (unitCoefficient) {
          values.forEach(dataRow => {
            let xValue = dataRow[timeIndex];
            if (xAxisChannelName === DATETIME_CHANNEL_NAME) {
              xValue = new Date(xValue).getTime();
            }
            // covert x value from second to min (time float value response from api is in second)
            if (xAxisChannelName === TIMEFLOAT_CHANNEL_NAME || xAxisChannelName === FRACPRO_TIMEFLOAT_CNAME) {
              xValue = Helper.convertSecToMin(xValue);
            }

            const yValue = this.unitSystemService.convertValue(dataRow[channelIndex], unitCoefficient);
            const point = { x: xValue, y: yValue };
            dataSeries.push(point);
          });
        } else {
          values.forEach(dataRow => {
            let xValue = dataRow[timeIndex];
            if (xAxisChannelName === DATETIME_CHANNEL_NAME) {
              xValue = new Date(xValue).getTime();
            }
            // covert x value from second to min (time float value response from api is in second)
            if (xAxisChannelName === TIMEFLOAT_CHANNEL_NAME || xAxisChannelName === FRACPRO_TIMEFLOAT_CNAME) {
              xValue = Helper.convertSecToMin(xValue);
            }

            const yValue = dataRow[channelIndex] as number;
            const point = { x: xValue, y: yValue };
            dataSeries.push(point);
          });
        }

        // pre-sort data series
        // if (dataSeries.length > 1) {
        //   dataSeries = dataSeries.sort((a, b) => {
        //     return a.x - b.x;
        //   });
        // }

        let isMonitor = false;
        let treatmentNumber: string | number = dataInfo.treatmentNumber;
        if (treatmentNumber < 0) {
          treatmentNumber = 'Monitor';
          isMonitor = true;
        }

        // const channelUnit = Helper.getValidUnit(channelItem.unit, true);
        // const seriesChannel = channelItem.channelName ? channelItem.channelName : channelItem.name;
        // const seriesChannelName = isMonitor ? `${dataInfo.wellName}.${seriesChannel}` : seriesChannel;
        // const seriesName = `${extraInfo.seriesPrefix || ''}${seriesChannelName} ${channelUnit}`;

        const unit = (measureType && measureType.trim() && !!unitSetting) ? (unitCoefficient ? unitCoefficient.unit : undefined) : channelItem.unit;
        const channelUnit = Helper.getValidUnit(unit, true);
        const channelName = channelItem.channelName || channelItem.name;
        const isDisplayOriginalName = (channelItem.originalName && channelName.toUpperCase() !== channelItem.originalName.toUpperCase());

        let isPridective: boolean;
        if (channelItem.originalName) {
          const originalNameLoweCase = channelItem.originalName.toLowerCase();
          isPridective = originalNameLoweCase.indexOf(PREFIX_PREDICTIVE_CHANNEL) === 0;
        }

        let seriesName = `${extraInfo.seriesPrefix || ''}${channelItem.channelName || channelItem.name}`;
        const tooltipName = !!isDisplayOriginalName ? `${seriesName}: ${channelItem.originalName} ${channelUnit}` : `${seriesName} ${channelUnit}`;
        seriesName = seriesName + ' ' + channelUnit;
        const seriesColor = channelItem.color ? channelItem.color : extraInfo.seriesColor;

        return {
          id: seriesId,
          name: seriesName,
          tooltipName,
          type: 'line',
          data: dataSeries,
          // lineWidth: !!isPridective ? 3 : 2,
          dashStyle: !!isPridective ? 'shortdash' : 'Solid',
          yAxis: dataIndex,
          color: seriesColor,
          boostThreshold: 10000,
          connectNulls: true,
          linkedTo: isMonitor ? undefined : masterSeriesId,
          wellId: dataInfo.wellId,
          treatmentId: dataInfo.treatmentId,
          treatmentNumber
        };
      });
    }

    return result.filter(x => x);
  }

  parseSingleWellSeriesData(channelSettings, wellInputData: IInputDataWell, timeFormat, color?: string, masterDataInfo?: IInputDataWell, unitSetting?: number) {
    // parse all flow input data to chart's series data
    let seriesData = [];
    if (wellInputData) {
      // generate series data name
      let treatmentName = `Treatment ${wellInputData.treatmentNumber}`;
      if (wellInputData.treatmentNumber < 0) {
        treatmentName = 'Monitor';
      }

      const prefixName = `${wellInputData.wellName} - ${treatmentName} - `;
      const extraInfo = {
        seriesPrefix: prefixName,
        seriesColor: color
      };
      const dataCollection = {
        realtimeData: wellInputData.realtimeData,
        fracproData: wellInputData.fracproData
      };
      for (const dataType in dataCollection) {
        if (dataCollection.hasOwnProperty(dataType)) {
          if (!isEmpty(dataCollection[dataType])) {
            const dataFlow = dataCollection[dataType][0];
            const prefixChannel = this.channelSelectionService.getPrefixChannelByDataType(dataType);
            const channelSettingsByDataType = this.channelSelectionService.filterChannelsByPrefixStr(channelSettings, prefixChannel);

            const parsedData = this.parseSeriesDataByChannels(wellInputData, channelSettingsByDataType, dataFlow, timeFormat, dataType, extraInfo, masterDataInfo, unitSetting);
            if (!parsedData.length) continue;
            seriesData = seriesData.concat(parsedData);
          }
        }
      }
    }
    return seriesData;
  }

  parseMultiWellSeriesData(multiWellInputData: IInputDataWell[], timeFormat, pivotPointData?: IPivotPointItem[], unitSetting?: number) {
    let seriesData = [];
    if (!isEmpty(multiWellInputData)) {
      // find well master that is not monitor well
      const normalWellAsMaster = multiWellInputData.find(dataWell => dataWell.treatmentNumber > 0);
      const indexNormalWellAsMaster = multiWellInputData.findIndex(dataWell => dataWell.treatmentNumber > 0);

      multiWellInputData.forEach((wellData, index) => {
        let masterDataInfo;
        if (normalWellAsMaster && index !== indexNormalWellAsMaster) {
          masterDataInfo = normalWellAsMaster;
        }
        if (wellData && wellData.channelSettings) {
          let parsedData = [];

          // update time value by pivot point data
          if (!isEmpty(pivotPointData)) {
            const shiftedData = this.pivotPointService.handleUpdateTimeByPivotData(wellData, pivotPointData);
            // parse chart series data for each treatment
            parsedData = this.parseSingleWellSeriesData(wellData.channelSettings, shiftedData, timeFormat, this.randomColorsList[index], masterDataInfo, unitSetting);
          } else {
            parsedData = this.parseSingleWellSeriesData(wellData.channelSettings, wellData, timeFormat, this.randomColorsList[index], masterDataInfo, unitSetting);
          }
          seriesData = seriesData.concat(parsedData);
        }
      });
    }

    if (this.isWellColorMode && !isEmpty(this.wellOptions)) {
      this.wellOptions.forEach(opt => {
        seriesData.forEach(series => {
          if (series.wellId === opt.wellId) {
            series.color = opt.wellColor;
          }
        });
      });
    } else {
      seriesData = this.changeColorFollowColorMode(this.channelSettings, seriesData);
    }

    return seriesData;
  }

  changeColorFollowColorMode(channelSettings: IChannelSettingModel, seriesData) {
    const settings = cloneDeep(channelSettings);
    for (const key in settings) {
      if (settings.hasOwnProperty(key)) {
        const setting = settings[key];
        if (setting && !isEmpty(setting.channels)) {
          let dataRandomColor: any[] = [];
          forEach(setting.channels, item => {
            let wellData = seriesData.filter(i => i.id.includes(item.channelName));
            if (item.colorMode === 'Random') {
              dataRandomColor = dataRandomColor.concat(wellData);
            } else if (item.colorMode === 'Flat') {
              wellData = wellData.map((data, index) => {
                data.color = item.color;
                return data;
              });
            } else {
              let colorDarken;
              let colorLighten;
              const countTreatmentData = wellData.length;
              const colorChannelDark = new tinycolor(item.color);
              const colorChannelLight = new tinycolor(item.color);
              if (colorChannelDark.isDark()) {
                colorDarken = colorChannelDark.darken(20).toHexString();
                if (colorChannelDark.getBrightness() < 25) {
                  colorDarken = colorChannelDark.lighten(10).toHexString();
                }
                colorLighten = colorChannelLight.lighten(40).toHexString();
              } else if (colorChannelLight.isLight()) {
                colorLighten = colorChannelLight.lighten(20).toHexString();
                if (colorChannelLight.getBrightness() > 240) {
                  colorLighten = colorChannelLight.darken(10).toHexString();
                }
                colorDarken = colorChannelDark.darken(40).toHexString();
              }
              const listColor = new tinygradient([
                colorDarken,
                colorLighten
              ]);
              if (countTreatmentData > 2) {
                const listColorPicker = listColor.rgb(countTreatmentData).map((value) => {
                  return value.toString();
                });
                wellData = wellData.map((data, index) => {
                  data.color = listColorPicker[index];
                  return data;
                });
              } else {
                const listColorDefault = [colorDarken, colorLighten];
                wellData = wellData.map((data, index) => {
                  data.color = listColorDefault[index];
                  return data;
                });
              }
            }
          });
          if (dataRandomColor && dataRandomColor.length > 0) {
            dataRandomColor = dataRandomColor.map((data, index) => {
              data.color = this.randomColorsList[index];
              return data;
            });
          }
        }
      }
    }
    return seriesData;
  }

  updateChart(inputData, filterSettings, pivotPointData?, unitSetting?: number) {
    if (inputData && filterSettings) {
      this.setupChart();
      this.isLoadingChart = true;
      this.loadChartData(inputData, filterSettings.type, pivotPointData, unitSetting).subscribe(() => {
        this.isLoadingChart = false;
        this.highChart.redraw();
        this.highChart.setSize(this.highChart.chartWidth, this.highChart.chartHeight);
        this.highChart.options.chart.width = null;
        this.highChart.options.chart.height = null;
        this.ref.detectChanges();
      });
    }
  }

  resetChart(isRedraw?: boolean) {
    if (this.highChart) {
      this.chartOptions.xAxis = [];
      this.chartOptions.yAxis = [];
      this.chartOptions.series = [];
      // clear chart and reload data
      while (this.highChart.series.length > 0) {
        this.highChart.series[0].remove(false);
      }
      while (this.highChart.yAxis.length > 0) {
        this.highChart.yAxis[0].remove(false);
      }
      while (this.highChart.xAxis.length > 0) {
        this.highChart.xAxis[0].remove(false);
      }

      if (isRedraw) {
        this.highChart.redraw();
      }
    }
  }

  setChartMessage(message: string) {
    if (typeof message === 'string' && this.highChart) {
      const noDataEle = document.querySelector('.highcharts-no-data');
      if (noDataEle && noDataEle.children[0]) {
        noDataEle.children[0].innerHTML = message;
        this.messageNoData = message;
      }
    }
  }

  zoomOut() {
    this.highChart.zoomOut();
  }

  reflow() {
    this.highChart.reflow();
  }

  toggleTooltip(isDisplay) {
    if (this.highChart) {
      this.highChart.update({
        tooltip: {
          enabled: isDisplay
        }
      });
    }
  }

  updateChartCursorOptions(cursor) {
    if (cursor) {
      this.cursor = cursor;

      if (this.highChart) {
        const crosshair = this.baseChartService.crosshair(this.cursor.id);
        const handleAxisCross = (axisOpt, crossValue) => {
          if (axisOpt) {
            if (Array.isArray(axisOpt) && axisOpt.length) {
              axisOpt.forEach(item => {
                item.crosshair = crossValue;
              })
            } else {
              axisOpt.crosshair = crossValue;
            }
          }
          return axisOpt;
        };
        // set xAxis
        let xAxisOpt: any = this.highChart.options.xAxis;
        xAxisOpt = handleAxisCross(xAxisOpt, crosshair.x);
        // set yAxis
        let yAxisOpt: any = this.highChart.options.yAxis;
        yAxisOpt = handleAxisCross(yAxisOpt, crosshair.y);
        // update chartOptions
        this.chartOptions.xAxis = xAxisOpt;
        this.chartOptions.yAxis = yAxisOpt;
        this.chartOptions.plotOptions.series.cursor = this.cursor.id;
        this.chartOptions.tooltip.shared = false;
        this.chartOptions.tooltip.split = false;
        // update chart
        this.highChart.update({
          plotOptions: {
            series: {
              cursor: this.cursor.id
            }
          },
          xAxis: xAxisOpt,
          yAxis: yAxisOpt,
          tooltip: {
            shared: false,
            split: false
          }
        }, true);
      }
    }
  }

  toggleScrollbar(isDisplay) {
    if (this.highChart) {
      this.highChart.update({
        navigator: {
          enabled: isDisplay
        },
        scrollbar: {
          enabled: isDisplay
        }
      });
    }
  }

  toggleDragDropSeries() {
    if (this.highChart) {
      this.highChart.update({
        plotOptions: {
          series: {
            dragDrop: {
              draggableX: true,
              groupBy: 'groupId'
            },
            stickyTracking: false
          }
        },
        // tooltip: {
        //   shared: false
        // }
      }, false);
    }
  }

  generateRandomColors() {
    const colors = randomColors({
      luminosity: 'bright',
      count: 200
    });
    return colors;
  }

  exportChart(fileName?: string, type?: string) {
    const chart = this.highChart;
    if (type === 'Save PNG') {
      chart.exportChartLocal({
        filename: `${fileName}`,
        sourceWidth: chart.chartWidth,
        sourceHeight: chart.chartHeight,
        scale: 4,
        type: 'image/png'
      }, {
        lang: {
          noData: `${this.messageNoData}`
        },
      });
    } else if (type === 'Save JPEG') {
      chart.exportChartLocal({
        filename: `${fileName}`,
        sourceWidth: chart.chartWidth,
        sourceHeight: chart.chartHeight,
        scale: 8,
        type: 'image/jpeg'
      }, {
        lang: {
          noData: `${this.messageNoData}`
        },
      });
    } else {
      chart.print();
    }
  }

  parseNavigatorSeriesData(navigatorInputData, timeFormat?, pivotPointData?, unitSetting?: number) {
    let seriesData = [];
    seriesData = this.parseMultiWellSeriesData(navigatorInputData, timeFormat, pivotPointData, unitSetting);
    seriesData = seriesData.map(series => {
      let firstDataPoint;
      if (!isEmpty(series.data)) {
        firstDataPoint = series.data[0];
        firstDataPoint.y = 0;
      }

      return {
        type: 'line',
        fillOpacity: 0.5,
        dataGrouping: {
          groupPixelWidth: 10
        },
        data: firstDataPoint ? [{ x: firstDataPoint.x, y: 0 }, { x: firstDataPoint.x, y: 10 }] : [],
        color: 'red'
      };
    });

    return seriesData;
  }

  parseAliasNameAnnotation(wellOptions: IWellOptions[], parsedSeries, isAlias: boolean = true) {
    const annotationsByWellTreatment = {};
    if (wellOptions && !isEmpty(wellOptions)) {
      wellOptions.forEach(wellOpt => {
        if (!isEmpty(wellOpt.selectedTreatmentList)) {
          wellOpt.selectedTreatmentList.forEach(treatment => {
            const labels = [];
            const seriesByTreatmentList = parsedSeries.filter(series => series.treatmentId === treatment.id);
            if (!isEmpty(seriesByTreatmentList)) {
              // find data max y
              seriesByTreatmentList.forEach(series => {
                const maxPoint: any = maxBy(series.data, 'y');
                if (maxPoint) {
                  series.dataMaxY = maxPoint.y;
                }
              });
              const maxByDataMaxSeries: any = maxBy(seriesByTreatmentList, 'dataMaxY');
              // const seriesByTreatment = parsedSeries.find(series => series.treatmentId === treatment.id);
              const seriesByTreatment = maxByDataMaxSeries;
              if (seriesByTreatment && seriesByTreatment && !isEmpty(seriesByTreatment.data)) {
                const avgX = (seriesByTreatment.data[0].x + seriesByTreatment.data[seriesByTreatment.data.length - 1].x) / 2;
                const maxY: any = maxBy(seriesByTreatment.data, 'y');
                if (avgX && maxY.y) {
                  labels.push({
                    borderColor: seriesByTreatment.color,
                    point: {
                      x: avgX,
                      y: maxY.y,
                      xAxis: 0,
                      yAxis: seriesByTreatment.yAxis
                    },
                    allowOverlap: true
                  });
                }
                let labelText = wellOpt.aliasName ? `(${wellOpt.aliasName})-${seriesByTreatment.treatmentNumber}` : `${seriesByTreatment.treatmentNumber}`;
                if (!isAlias) {
                  labelText = `${seriesByTreatment.treatmentNumber}`;
                }
                const annotationId = `${wellOpt.wellId}_${treatment.id}`;
                const annotationOpt = {
                  id: annotationId,
                  labels,
                  labelOptions: {
                    text: labelText
                  }
                };
                annotationsByWellTreatment[annotationId] = annotationOpt;
              }
            }
          });
        }
      });
    }
    return annotationsByWellTreatment;
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { USER_STORAGE } from '../../helpers/app.constants';
import { LocalDataStorageService } from '../../services/local-data-storage.service';
import { FDIParameterSelectComponent } from '../modals/fdi-parameter-select/fdi-parameter-select.component';
import { TreatmentPlotFDIParam } from '../treatment-plot/treatment-plot-fdi.model';

@Component({
  selector: 'app-treatment-plot-fdi-dropdown',
  templateUrl: './treatment-plot-fdi-dropdown.component.html',
  styleUrls: ['./treatment-plot-fdi-dropdown.component.scss']
})
export class TreatmentPlotFdiDropdownComponent implements OnInit {
  listActions = [];
  isOpen = false;
  isShow = true;

  @Input() disabled = false;
  @Input() selectedParam: TreatmentPlotFDIParam;

  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Output() onRemoveAll: EventEmitter<any> = new EventEmitter();
  @Output() onToggleShow: EventEmitter<any> = new EventEmitter();
  @Output() onSelectParam: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialogService: NbDialogService,
    private localDataStorageService: LocalDataStorageService
  ) { }

  ngOnInit() {
    this.listActions = [
      { text: 'Add FDI', action: () => this.add() },
      { text: 'Remove All', action: () => this.removeAll() },
      { text: 'Show/Hide', action: () => this.toggleShow() },
      { text: 'Parameter', action: () => this.openParam() }
    ];
  }

  clickOutside() {
    this.isOpen = false;
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  add() {
    this.onAdd.emit();
    this.clickOutside();
  }

  removeAll() {
    this.onRemoveAll.emit();
    this.clickOutside();
  }

  toggleShow() {
    this.isShow = !this.isShow;
    this.onToggleShow.emit(this.isShow);
    this.clickOutside();
  }

  openParam() {
    this.isOpen = false;
    this.dialogService.open(FDIParameterSelectComponent, {
      context: {
        selectedParam: this.selectedParam
      }
    }).onClose.subscribe(res => {
      this.selectedParam = res;
      this.localDataStorageService.setOption(USER_STORAGE.fdiParam, this.selectedParam);
      this.onSelectParam.emit(this.selectedParam);
    });
  }

}

import { Injectable } from '@angular/core';
import { TIME_SETTING_TYPE } from '../../helpers/app.constants';

export interface IParamConvertXValueByTimeFormat {
  xValue: number;
  timeFormat: string;
  baseTreatmentDataTime: string;
  wellTimeZoneOffset: number;
}

@Injectable()
export class TreatmentPlotTimeConverterService {

  constructor() { }

  convertXValueByTimeFormat({ xValue, timeFormat, baseTreatmentDataTime, wellTimeZoneOffset }: IParamConvertXValueByTimeFormat) {
    let timeOffset;
    if (xValue < 0) {
      xValue = 10 * 60;
    }
    if (timeFormat === TIME_SETTING_TYPE.EpochTime) {
      timeOffset = xValue - new Date(baseTreatmentDataTime).getTime(); // - wellTimeZoneOffset * 60 * 1000; // milliseconds
      timeOffset = timeOffset / 1000; // in seconds
    } else {
      if (xValue > 60000) {
        xValue = 200 * 60;
      }
      timeOffset = xValue; // in seconds
    }
    return timeOffset;
  }

  convertOffsetToEpochMillisec({ xValue, baseTreatmentDataTime }) {
    let epochInMillisec;
    // convert offset value to epoch value
    epochInMillisec = new Date(baseTreatmentDataTime).getTime() + xValue * 1000;
    epochInMillisec = epochInMillisec;
    return epochInMillisec;
  }

  convertOffsetToEpochSec({ xValue, baseTreatmentDataTime }) {
    const epochInMillisec = this.convertOffsetToEpochMillisec({ xValue, baseTreatmentDataTime });
    return Math.round(epochInMillisec / 1000);
  }

  convertEpochToOffset({ xValue, baseTreatmentDataTime }) {
    let offsetInSec;
    let epochInMillisec = xValue - (new Date(baseTreatmentDataTime).getTime()); // milliseconds
    if (epochInMillisec < 0) {
      epochInMillisec = 5000;
    }
    offsetInSec = Math.round(epochInMillisec / 1000); // in seconds
    return offsetInSec;
  }

  convertEpochToMin({ xValue, baseTreatmentDataTime }) {
    const offsetInSec = this.convertEpochToOffset({ xValue, baseTreatmentDataTime });
    return offsetInSec / 60;
  }

  convertMsToSec(value: number | string, timezoneOffset = 0) {
    const time = typeof value === 'string' ? new Date(value).getTime() : value;
    return Math.floor(time / 1000) - (timezoneOffset * 60);
  }

  toEpochMillisecWithTimezone({ xValue }) {
    const epochInMillisec = new Date(xValue).getTime(); // + wellTimeZoneOffset * 60 * 1000;
    return epochInMillisec;
  }

  toEpochSecWithTimezone({ xValue }) {
    const epochInSec = this.toEpochMillisecWithTimezone({ xValue }) / 1000;
    return Math.round(epochInSec);
  }
}

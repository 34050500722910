import { IAppConfig } from "../app/app-init.service";

declare var window: any;

export class DynamicEnvironment {
	constructor() {
	}

	getAppConfig(): IAppConfig {
		if (window && window.appConfig) {
			return window.appConfig;
		}
		return { 
			baseURL: 'http://localhost' 
		};
	}
}
import { Injectable } from '@angular/core';
import {
  ScatterPlotParameterChartService, IAxisSetting,
  IInputParamData, IChartDataPoint, IScatterPlotChartSetting, IParsedChartOptions
} from './scatter-plot-parameter-chart.service';
import { isEmpty, isString, isNumber } from 'lodash';
import * as Highcharts from 'highcharts';
import { ENUM_TREATMENT_COLUMNS } from '../../helpers/app.constants';

export enum ENUM_SCALE_TYPES {
  VerticalSection = 'Vertical Section',
  Treatment = 'Treatment',
  MeasuredDepth = 'Measured Depth'
}

export interface IAvgMDData {
  id: number;
  avgMD: number;
  yValue?: number;
}

export interface ILogDataState {
  curveItem: {
    name: string;
    unit?: string;
  };
  scaleMode: string;
  curveData?: IChartDataPoint[];
  parameterData?: IChartDataPoint[];
  logCurvesData?: IChartDataPoint[];
  selectedCurves?: {
    name: string;
    unit?: string;
  }[],
  shape?: string;
  color?: string;
  min?: number;
  max?: number;
  wellKickOfMD?: number;
}

@Injectable()
export class LogDataCurveParameterChartService {

  constructor(
    private scatterPlotParameterChartService: ScatterPlotParameterChartService
  ) { }

  parseChartOptions(
    itemsComparing: IAxisSetting[],
    chartSettings: IScatterPlotChartSetting,
    inputData: IInputParamData[],
    logDataState?: ILogDataState,
    designActualData?,
    groupOption?: string,
    isDarkMode?: boolean
  ): IParsedChartOptions {
    let xAxisOpts = [];
    let yAxisOpts = [];
    let seriesData = [];
    let xAxisOpt;
    let designActualXAxis;
    let yAxisLeftOpt = [];
    let yAxisRightOpt = [];
    let designActualOpt = [];
    let tooltipOption = null;
    const yAxisSettings = chartSettings.yAxis;
    const curveAxisSettings = chartSettings.curveYAxis;
    const designActualYAxis = chartSettings.designActualYAxis;

    // get series data of log curve data
    if (logDataState && !isEmpty(curveAxisSettings) && !isEmpty(logDataState.logCurvesData)) {
      yAxisLeftOpt = this.scatterPlotParameterChartService.getLeftYAxisOption(yAxisSettings);
      curveAxisSettings.forEach(setting => {
        const yAxisRightTitle = `${setting.name} ` + (setting.unit ? `(${setting.unit})` : '');
        const opts = this.scatterPlotParameterChartService.getYAxisOption(yAxisRightTitle, null, true, setting);
        yAxisRightOpt.push(opts);
      });
      if (logDataState.scaleMode === ENUM_SCALE_TYPES.Treatment) {
        xAxisOpt = this.scatterPlotParameterChartService.getXAxisOptionCategories(itemsComparing, undefined, isDarkMode);
        const parameterSeriesData = this.scatterPlotParameterChartService.parseSeriesDataByCategories(itemsComparing, yAxisSettings, inputData);

        const logDataSeriesIndex = yAxisSettings.length + designActualYAxis.length;
        const logDataSeries = this.parseCurveSeriesDataByAvgMD(logDataState.logCurvesData, chartSettings.curveYAxis, logDataSeriesIndex);
        seriesData = parameterSeriesData.concat(logDataSeries);
        if (!isEmpty(designActualYAxis)) {
          designActualOpt = this.scatterPlotParameterChartService.getLeftYAxisOption(designActualYAxis);
          if (groupOption === 'treatment') {
            designActualYAxis.forEach((setting, index) => {
              const designActualSeries = this.scatterPlotParameterChartService.parseDesignActualSeriesDataByTreatmentMode(itemsComparing, setting, index + yAxisSettings.length, designActualData);
              seriesData = seriesData.concat(designActualSeries);
            });
          } else {
            const wellItems = [itemsComparing[0]];
            designActualXAxis = this.scatterPlotParameterChartService.getXAxisOptionCategories(wellItems, true, isDarkMode);

            designActualYAxis.forEach((setting, index) => {
              const designActualSeries = this.scatterPlotParameterChartService.parseDesignActualSeriesDataByWellMode(wellItems, setting, index + yAxisSettings.length, designActualData);
              seriesData = seriesData.concat(designActualSeries);
            });
          }
        }
      } else {
        let isRevertedXAxis = false;
        if (!isEmpty(logDataState.logCurvesData)) {
          const firstDataRow = logDataState.logCurvesData[0];
          const lastDataRow = logDataState.logCurvesData[logDataState.logCurvesData.length - 1];
          if (firstDataRow && lastDataRow) {
            if (firstDataRow.xValue > lastDataRow.xValue) {
              isRevertedXAxis = true;
            }
          }
        }
        if (logDataState.scaleMode === ENUM_SCALE_TYPES.VerticalSection) {
          xAxisOpt = this.scatterPlotParameterChartService.getXAxisOptionDefault(ENUM_SCALE_TYPES.VerticalSection, null, chartSettings.xAxis, isRevertedXAxis, isDarkMode);
        } else if (logDataState.scaleMode === ENUM_SCALE_TYPES.MeasuredDepth) {
          xAxisOpt = this.scatterPlotParameterChartService.getXAxisOptionDefault(ENUM_SCALE_TYPES.MeasuredDepth, null, chartSettings.xAxis, isRevertedXAxis, isDarkMode);
          // set kick of MD as min
          if (logDataState.wellKickOfMD) {
            xAxisOpt.min = logDataState.wellKickOfMD;
          }
        }
        const parameterSeriesData = this.parseParameterSeriesData(yAxisSettings, logDataState.parameterData);
        const logDataSeriesIndex = parameterSeriesData.length;
        const logDataSeries = this.parseCurveSeriesDataByCurveData(curveAxisSettings, logDataState.logCurvesData, logDataState.parameterData, logDataSeriesIndex);
        seriesData = [...parameterSeriesData, ...logDataSeries];
      }
      if (xAxisOpt) {
        xAxisOpts = [xAxisOpt];
      }
      if (designActualXAxis) {
        xAxisOpts.push(designActualXAxis);
      }
      yAxisOpts = [...yAxisLeftOpt, ...designActualOpt, ...yAxisRightOpt];
      // get tooltip options
      tooltipOption = this.getTooltipOption(logDataState.scaleMode);
    }

    return {
      xAxis: xAxisOpts,
      yAxis: yAxisOpts,
      seriesData: seriesData,
      tooltip: tooltipOption
    };
  }

  getTooltipOption(scaleMode?: string) {
    let tooltipOptions = {};
    const symbol = '●';
    const self = this;
    if (scaleMode === ENUM_SCALE_TYPES.Treatment) {
      tooltipOptions = {
        shared: true,
        useHTML: true,
        borderColor: '#000',
        formatter: function() {
          const tbRows = [];
          let treatmentName;
          let isCurvePoint = false;
          this.points.forEach((activePoint, index) => {
            if (!treatmentName) {
              treatmentName = activePoint.point.treatmentName;
            }
            isCurvePoint = activePoint.point.isCurve;

            const tooltipPointMaker = `<span style="color:${activePoint.color}">${symbol}</span>`;
            const value = self.isEpochName(activePoint.series.name) ? Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', activePoint.y * 1000) : Highcharts.numberFormat(activePoint.y, 2)
            let avgMDRow = '';
            if (index === 0) {
              avgMDRow = isNumber(activePoint.point.avgMD) ? `
              <tr>
                <td>${tooltipPointMaker} AverageMD (ft): </td>
                <td style="text-align: left"><b>${activePoint.point.avgMD}</b></td>
              </tr>
            ` : '';
            }
            const avgStrOnLastPoint = isCurvePoint ? `Average ` : '';
            const dataRow = `
                ${avgMDRow}
                <tr>
                  <td>${tooltipPointMaker} ${avgStrOnLastPoint}${activePoint.series.name}: </td>
                  <td style="text-align: left"><b>${value}</b></td>
                </tr>
              `
            tbRows.push(dataRow);

            if (activePoint.point.isDesignActual) {
              const percentDev = (activePoint.point.actualValue - activePoint.point.designValue) / activePoint.point.designValue * 100;
              const devDataRow = `
                <tr>
                  <td>%Dev: </td>
                  <td style="text-align: left"><b>${Highcharts.numberFormat(percentDev, 2)}</b></td>
                </tr>
              `
              tbRows.push(devDataRow);
            }
          });

          return `
            <div>
            <small>${treatmentName ? treatmentName : ''}</small>
              <table>
                ${tbRows.join('')}
              </table>
            </div>
          `;
        },
      };
    } else {
      tooltipOptions = {
        shared: true,
        headerFormat: '<small>{series.name}</small><table>',
        formatter: function() {
          const tbRows = [];
          let treatmentName;
          let isCurvePoint = false;
          this.points.forEach((activePoint, index) => {
            if (!treatmentName) {
              treatmentName = activePoint.point.treatmentName;
            }
            isCurvePoint = activePoint.point.isCurve;
            const tooltipPointMaker = `<span style="color:${activePoint.color}">${symbol}</span>`;
            const value = self.isEpochName(activePoint.series.name) ? Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', activePoint.y * 1000) : Highcharts.numberFormat(activePoint.y, 2)
            let avgMDRow = '';
            if (index === 0) {
              avgMDRow = isNumber(activePoint.point.avgMD) ? `
                <tr>
                  <td>${tooltipPointMaker} AverageMD (ft): </td>
                  <td style="text-align: left"><b>${activePoint.point.avgMD}</b></td>
                </tr>
              ` : '';
            }
            const avgStrOnLastPoint = isCurvePoint ? `Average ` : '';
            const dataRow = `
                ${avgMDRow}
                <tr>
                  <td>${tooltipPointMaker} ${avgStrOnLastPoint}${activePoint.series.name}: </td>
                  <td style="text-align: left"><b>${value}</b></td>
                </tr>
              `
              tbRows.push(dataRow);
          });
          const verticalSectionRow = `
            <tr>
              <td>- ${ENUM_SCALE_TYPES.VerticalSection} (ft): </td>
              <td style="text-align: left"><b>${Highcharts.numberFormat(this.x, 2)}</b></td>
            </tr>
          `;

          return `
            <div>
              <small>${treatmentName ? treatmentName : ''}</small>
              <table>
                ${scaleMode === ENUM_SCALE_TYPES.VerticalSection ? verticalSectionRow : ''}
                ${tbRows.join('')}
              </table>
            </div>
          `;
        },
      };
    }

    return tooltipOptions;
  }

  parseCurveSeriesDataByAvgMD(logCurvesData: IChartDataPoint[], curveAxisSettings: IAxisSetting[], beginYAxisIndex?: number) {
    const result = [];
    if (!isEmpty(curveAxisSettings)) {
      curveAxisSettings.forEach((setting, index) => {
        const chartDataPoints = logCurvesData.filter(item => item.id === setting.id);
        const seriesShape = isString(setting.shape) ? setting.shape.toLowerCase() : 'circle';
        const data = chartDataPoints.map(item => {
          return {
            y: item.yValue,
            name: `${item.name}`,
            color: setting.color,
            marker: {
              symbol: seriesShape
            },
            isCurve: true
          };
        });
        const series = this.getLineSeriesOption(setting.name, data, setting, beginYAxisIndex + index);
        series.marker.enabled = true;
        result.push(series);
      });
    }
    return result;
  }

  parseCurveSeriesDataByCurveData(curveAxisSettings, logCurvesData: IChartDataPoint[], parameterDataPoints: IChartDataPoint[], beginYAxisIndex?: number) {
    const result = [];
    curveAxisSettings.forEach((setting, index) => {
      const chartDataPoints = logCurvesData.filter(item => item.id === setting.id);
      const seriesShape = isString(setting.shape) ? setting.shape.toLowerCase() : 'circle';
      const data = chartDataPoints.map(item => {
        const isSelected = parameterDataPoints.find(i => i.xValue === item.xValue);
        return {
          x: item.xValue,
          y: item.yValue,
          name: `${item.name}`,
          color: setting.color,
          marker: {
            enabled: !!isSelected,
            symbol: seriesShape,
            lineWidth: 2
          },
          isCurve: true
        };
      }).sort((a, b) => a.x - b.x);
      const series = this.getLineSeriesOption(setting.name, data, setting, beginYAxisIndex + index);
      result.push(series);
    });
    return result;
  }

  parseParameterSeriesData(yAxisSettings: IAxisSetting[], parameterData: IChartDataPoint[]) {
    const series = yAxisSettings.map((setting, yAxisIndex) => {
      const seriesColor = setting.color;
      const seriesShape = isString(setting.shape) ? setting.shape.toLowerCase() : 'circle';
      const paramDataByYAxis = parameterData.filter(param => param.name === setting.id);
      let result = {
        id: `parameter-${setting.id}`,
        name: setting.name,
        type: 'line',
        data: paramDataByYAxis.map(item => {
          return {
            x: item.xValue,
            y: item.yValue,
            name: `${item.name}`,
            treatmentName: `${item.treatmentName}`,
            avgMD: item.avgMDValue
          };
        }).sort((a, b) => a.x - b.x),
        color: seriesColor,
        marker: {
          symbol: seriesShape
        },
        lineWidth: 0,
        zIndex: 2,
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            lineWidth: 0,
            lineWidthPlus: 0
          }
        },
        yAxis: yAxisIndex
      };

      return result;
    });
    return series;
  }

  getLineSeriesOption(id, data = [], settings: IAxisSetting, yAxisIndex?: number) {
    let result = {
      id: id,
      name: id,
      type: 'line',
      data: data,
      yAxis: yAxisIndex,
      zIndex: 1,
      marker: {
        enabled: false
      },
      color: settings.color
    };

    return result;
  }

  isEpochName(name) {
    return name.indexOf(ENUM_TREATMENT_COLUMNS.TimeStart) > -1 || name.indexOf(ENUM_TREATMENT_COLUMNS.TimeEnd) > -1;
  }

  getRawStringScale(logDataState: ILogDataState, inputChartData : IInputParamData[]){

    let curveDatas = logDataState.curveData;
    let parameterDatas = logDataState.parameterData;
    if(logDataState.scaleMode === ENUM_SCALE_TYPES.VerticalSection || logDataState.scaleMode === ENUM_SCALE_TYPES.MeasuredDepth){

      if(!isEmpty(curveDatas) && !isEmpty(parameterDatas)){
        let headersNames = ['Treatment', 'Vertical Section'].concat(parameterDatas[0].name).concat(logDataState.curveItem.name);
        if(logDataState.scaleMode === ENUM_SCALE_TYPES.MeasuredDepth){
          headersNames = ['Treatment', 'Measured Depth'].concat(parameterDatas[0].name).concat(logDataState.curveItem.name);
        }
        let headersUnits = [',ft'].concat(parameterDatas[0].unit.replace('(', '').replace(')', '')).concat(logDataState.curveItem.unit ? logDataState.curveItem.unit : '');

        parameterDatas.reverse();
        const channelData = [];
        parameterDatas.forEach(parameterData => {
          let channelDataRow = [];
          let treatmentName = parameterData.treatmentName.split(' ');
          if(treatmentName.length > 1){
            channelDataRow.push(treatmentName[1]);
          }
          //channelDataRow.push(parameterData.treatmentName);
          channelDataRow.push(parameterData.xValue);
          channelDataRow.push(parameterData.yValue);

          let logChan = curveDatas.filter(dataRow => {
            const xValue = dataRow.xValue;
            return xValue == parameterData.xValue;
          });
          if(logChan.length > 0){
            channelDataRow.push(logChan[0].yValue ? logChan[0].yValue : '');
          }else{
            channelDataRow.push('');
          }
          channelData.push(channelDataRow);
        });
        const result = [
          headersNames,
          headersUnits,
          channelData.join('\n')
        ]
        const stringData = result.join('\n');

        return stringData;
      }
    }else{
      if(!isEmpty(inputChartData) && !isEmpty(curveDatas)){
        let headersNames = ['Treatment'].concat(inputChartData[0].paramName).concat(logDataState.curveItem.name);
        let headersUnits = [''].concat(inputChartData[0].paramUnit.replace('(', '').replace(')', '')).concat(logDataState.curveItem.unit ? logDataState.curveItem.unit.replace('(', '').replace(')', '') : '');

        inputChartData.reverse();
        const channelData = [];

        inputChartData.forEach(data => {
          let channelDataRow = [];

          let treatmentName = data.name.split(' ');
          if(treatmentName.length > 1){
            channelDataRow.push(treatmentName[1]);
          }
          //channelDataRow.push(data.name);
          channelDataRow.push(data.paramValue);

          let logChan = curveDatas.filter(dataRow => {
            const xValue = dataRow.xValue;
            return xValue == data.avgMDValue;
          });
          if(logChan.length > 0){
            channelDataRow.push(logChan[0].yValue ? logChan[0].yValue : '');
          }else{
            channelDataRow.push('');
          }
          channelData.push(channelDataRow);
        });

        const result = [
          headersNames,
          headersUnits,
          channelData.join('\n')
        ]
        const stringData = result.join('\n');

        return stringData;
      }
    }
  }
}

import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { cloneDeep, forEach, isEmpty } from 'lodash';
import { forkJoin } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { LIMIT_CHANNELS_SELECTION, YAXIS_KEYS } from '../../../helpers/app.constants';
import { IChannelItem, IChannelSettingModel } from '../../../models/channel.model';
import { OffsetWellCompare } from '../../../models/offset-well-compare.model';
import { IWellItemPaging } from '../../../models/well.model';
import { ChannelSelectionService, DIGITS_AFTER_DECIMAL_DEFAULT } from '../../../services';
import { CompareOffsetChannelsService } from '../../../services/compare-offset-channels.service';
import { TreatmentService } from '../../../services/treatment.service';
import { BourdetDerivativeComponent } from '../bourdet-derivative/bourdet-derivative.component';
import { OffsetWellCompareComponent } from '../offset-well-compare/offset-well-compare.component';

@Component({
  selector: 'app-channel-selection',
  templateUrl: './channel-selection.component.html',
  styleUrls: ['./channel-selection.component.scss']
})
export class ChannelSelectionComponent implements OnInit, OnDestroy {

  // realtime channels
  realtimeChannelMapping: IChannelItem[] = [];
  // fracpro channels
  fracProChannelsMapping: IChannelItem[] = [];

  titleHeader: string = 'Channel Selection';
  isAutoScaleYAxis: boolean;

  settingsModel: IChannelSettingModel = {
    autoScaleYAxis: true,
    firstLeft: {
      min: 0,
      max: 1000,
      channels: []
    },
    secondLeft: {
      min: 0,
      max: 1000,
      channels: []
    },
    firstRight: {
      min: 0,
      max: 1000,
      channels: []
    },
    secondRight: {
      min: 0,
      max: 1000,
      channels: []
    }
  };

  firstLoadedSettingsModel: IChannelSettingModel;
  resultChannelsWithMinMax: {cName: string; channelName: string; min: number; max: number}[];
  compareChannelsSelected: { cName: string, name: string, min: number, max: number }[];
  disabledEditColorMode: boolean = false;

  @Input()
  set title(value) {
    if (value) {
      this.titleHeader = value;
    }
  }

  digitsAfterDecimaDefault: string = DIGITS_AFTER_DECIMAL_DEFAULT;
  @Input()
  set realtimeChannels(channelMapping) {
    if (channelMapping && channelMapping.length > 0) {
      this.realtimeChannelMapping = channelMapping.map(item => {
        if (item) item.digits !== undefined && item.digits !== null ? item.digits : this.digitsAfterDecimaDefault;
        return item;
      });
    }
  }

  @Input()
  set fracProChannels(channelMapping) {
    if (channelMapping && channelMapping.length > 0) {
      this.fracProChannelsMapping = channelMapping.map(item => {
        if (item) item.digits !== undefined && item.digits !== null ? item.digits : this.digitsAfterDecimaDefault;
        return item;
      });
    }
  }

  @Input()
  set timeMode(mode: string) {
    if (mode === 'hh:mm' && this.compareChannel) {
      this.disabledEditColorMode = true;
    }
  }

  @Input() compareChannel: boolean = false;
  @Input() compareWellCount: number = 1;
  @Input() equipmentChart: boolean = false;
  @Input() showAutoScale: boolean = false;
  @Input() showChannelMinMax: boolean = true;
  @Input() wellId: number;
  @Input() treatmentId: number;
  @Input() flowPathType: number;
  @Input() equipmentId: number;
  @Input() uniqueKey: string = 'name';
  @Input() hideCompareBtn: boolean = false;
  @Input() maxChannelSelect: number = 12; // set 0 for unlimited

  @Input() compareWellsInfo: { wellId: number; treatmentId: number; flowPathType: number }[] = [];
  @Input() channelsSelected: { cName: string; name: string; prefix?: string }[];
  @Input()
  set autoScaleYAxis(value: boolean) {
    this.isAutoScaleYAxis = value;
  }

  set settings(data) {
    if (data) {
      this.settingsModel = data;
    } else {
      this.settingsModel = this.channelSelectionService.getDefaultSettings(this.realtimeChannelMapping);
    }
    setTimeout(() => {
      this.checkMaxItem();
    });
  }

  @Input()
  offsetWellCompareSetting: OffsetWellCompare[] = [];

  @Input()
  bourdetSetting: OffsetWellCompare[] = [];

  @Input()
  listWells: IWellItemPaging[];

  @Input()
  simpleMode: boolean = false;

  constructor(
    protected dialogRef: NbDialogRef<any>,
    private dialogService: NbDialogService,
    private channelSelectionService: ChannelSelectionService,
    private compareOffsetChannelsService: CompareOffsetChannelsService,
    private treatmentService: TreatmentService,
    private element: ElementRef,
    private renderer: Renderer2,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    // get min max of selected channels
    const listChannels = this.channelSelectionService.getListChannels(this.settingsModel);
    if (!!this.simpleMode) {
      YAXIS_KEYS.forEach(key => {
        if (this.settingsModel.hasOwnProperty(key)) {
          const setting = this.settingsModel[key];
          if (setting && !isEmpty(setting.channels)) {
            // update channels
            setting.channels = setting.channels.map(curChannel => {
              curChannel.digits = curChannel.digits !== undefined && curChannel.digits !== null ? curChannel.digits : this.digitsAfterDecimaDefault;
              curChannel.channel.digits = curChannel.digits;
              curChannel.min = curChannel.channel.min = null;
              curChannel.max = curChannel.channel.max = null;
              return curChannel;
            });
          }
        }
      });
      this.settings = this.settingsModel;
    } else {
      if (!this.compareChannel) {
        const cNames = listChannels.map(item => item.cName);
        if (this.wellId && this.treatmentId && !isEmpty(cNames)) {
          this.treatmentService.getChannelsMinMax(this.wellId, this.treatmentId, this.flowPathType, cNames).subscribe(response => {
            if (response && !isEmpty(response.result)) {
              this.settings = this.channelSelectionService.updateMinMaxChannels(this.settingsModel, response.result);
            }
          }, err => err);
        } else if (this.equipmentChart && this.equipmentId && !isEmpty(cNames)) {
          this.treatmentService.getChannelsMinMaxEquipment(this.equipmentId, cNames).subscribe(response => {
            if (response && !isEmpty(response.result)) {
              this.settings = this.channelSelectionService.updateMinMaxChannels(this.settingsModel, response.result);
            }
          }, err => err);
        }
        // get min max offset channels
        const listOffsetChannels = this.channelSelectionService.getListChannels(this.settingsModel, true);
        if (!isEmpty(listOffsetChannels)) {
          const requests = listOffsetChannels.filter(x => !x.isBourdetDer).map(channel => {
            return this.treatmentService.getChannelsMinMax(channel.wellId, channel.treatmentId, this.flowPathType, [channel.cName])
              .pipe(map(res => {
                const offsetInfo = {
                  wellId: channel.wellId,
                  treatmentId: channel.treatmentId,
                  cName: channel.cName
                };
                if (!isEmpty(res.result)) {
                  return Object.assign(res.result[0], offsetInfo);
                }
                return offsetInfo;
              }));
          });
          forkJoin(requests).subscribe(result => {
            this.settings = this.channelSelectionService.updateMinMaxChannels(this.settingsModel, result, true);
          });
        }
      } else {
        if (!isEmpty(this.compareWellsInfo)) {
          const channelNames = this.channelsSelected.map(item => item.name);
          const wellsItems = this.compareWellsInfo.map(item => {
            return {
              treatmentId: item.treatmentId,
              wellId: item.wellId,
              flowPathType: 0
            };
          });
          if (!isEmpty(channelNames) && !isEmpty(wellsItems.filter(item => !!item.treatmentId))) {
            this.treatmentService.getCompareChannelsMinMax(channelNames, wellsItems).subscribe(response => {
              if (response && !isEmpty(response.result)) {
                this.resultChannelsWithMinMax = response.result;
                this.compareChannelsSelected = this.channelSelectionService.setMinMaxChannels(this.channelsSelected, this.resultChannelsWithMinMax, true);
                this.firstLoadedSettingsModel = this.channelSelectionService.updateMinMaxChannelsByName(this.settingsModel, response.result);
                const newSettings = cloneDeep(this.firstLoadedSettingsModel);
                this.settings = this.channelSelectionService.updateAxisScaleByMinMaxChannels(newSettings, true);
              }
            }, err => err);
          }
        }
      }

    }
  }

  ngOnDestroy() {
    this.ref.detach();
  }

  close() {
    this.dialogRef.close();
  }

  submitForm() {
    this.dialogRef.close(this.settingsModel);
  }

  selectColor(item, event) {
    item.color = event;
  }

  checkMaxItem() {
    if (!this.maxChannelSelect) return;
    const countData = this.countAllChannels();
    const listButton = this.element.nativeElement.querySelectorAll('.btn-action-create');
    if (countData >= this.maxChannelSelect) {
      forEach(listButton, item => {
        this.renderer.setAttribute(item, 'disabled', 'true');
      });
    } else {
      forEach(listButton, item => {
        this.renderer.removeAttribute(item, 'disabled');
      });
    }
  }

  countAllChannels() {
    const selectedChannels = [
      ...this.settingsModel.firstLeft.channels,
      ...this.settingsModel.secondLeft.channels,
      ...this.settingsModel.firstRight.channels,
      ...this.settingsModel.secondRight.channels
    ];
    const countData = selectedChannels.length;
    return countData;
  }

  onCreateChannel(channel) {
    this.checkMaxItem();
  }

  onDeleteChannel(channel) {
    this.checkMaxItem();
  }

  onChangeChannel(channel) {
    // in case something else
    // console.log(channel);
  }

  onChangeAutoScaleYAxis(value) {
    this.settingsModel.autoScaleYAxis = value;
  }

  compareOffsetWell() {
    this.dialogService.open(OffsetWellCompareComponent, {
      context: {
        // modelSetting: this.offsetWellCompareSetting,
        curWellId: this.wellId,
        curTreatmentId: this.treatmentId,
        listWells: this.listWells
      }
    }).onClose.subscribe(offsetWellSetting => {
      if (offsetWellSetting) {
        this.offsetWellCompareSetting = [...this.offsetWellCompareSetting, offsetWellSetting];
        // get offset channel min max
        if (!isEmpty(offsetWellSetting.realtimeChannels)) {
          const selectedChannel = offsetWellSetting.realtimeChannels[0];
          this.treatmentService.getChannelsMinMax(offsetWellSetting.wellId, offsetWellSetting.treatmentId, this.flowPathType, [selectedChannel.cName])
            .pipe(tap(response => {
              if (response && !isEmpty(response.result)) {
                selectedChannel.min = response.result[0].min;
                selectedChannel.max = response.result[0].max;
              }
            }))
            .pipe(finalize(() => {
              this.realtimeChannelMapping = this.compareOffsetChannelsService.mergeOffsetChannels(this.realtimeChannelMapping, this.offsetWellCompareSetting);
              this.settings = this.compareOffsetChannelsService.mergeSettingsOffsetChannels(this.settingsModel, offsetWellSetting, this.realtimeChannelMapping);
            })).subscribe();
        }
      }
    });
  }

  bourdetDerivative() {
    this.dialogService.open(BourdetDerivativeComponent, {
      context: {
        curWellId: this.wellId,
        curTreatmentId: this.treatmentId,
        listWells: this.listWells
      }
    }).onClose.subscribe(bourdetSetting => {
      if (bourdetSetting) {
        this.bourdetSetting = [...this.bourdetSetting, bourdetSetting];
        // get offset channel min max
        if (!isEmpty(bourdetSetting.realtimeChannels)) {
          const selectedChannel = bourdetSetting.realtimeChannels[0];
          this.treatmentService.getChannelsMinMax(bourdetSetting.wellId, bourdetSetting.treatmentId, this.flowPathType, [selectedChannel.cName])
            .pipe(tap(response => {
              if (response && !isEmpty(response.result)) {
                selectedChannel.min = response.result[0].min;
                selectedChannel.max = response.result[0].max;
              }
            }))
            .pipe(finalize(() => {
              this.realtimeChannelMapping = this.compareOffsetChannelsService.mergeOffsetChannels(this.realtimeChannelMapping, this.bourdetSetting);
              this.settings = this.compareOffsetChannelsService.mergeSettingsOffsetChannels(this.settingsModel, bourdetSetting, this.realtimeChannelMapping);
            })).subscribe();
        }
      }
    });
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ENUM_THEMES } from '../helpers/app.constants';

@Injectable()
export class ThemesSettingsService {
  private dataState: BehaviorSubject<any>;

  constructor() {
    this.dataState = new BehaviorSubject({
      themes: ENUM_THEMES.Light,
    });
  }

  setDataState(data: any) {
    this.dataState.next(data);
  }

  getDataState(): Observable<any> {
    return this.dataState.asObservable();
  }

  setLocalStorage(value, optionKey?: string) {
    const key = optionKey ? `themes_settings_${optionKey}` : `themes_settings`;
    localStorage.setItem(key, JSON.stringify(value));
  }

  getLocalStorage(optionKey?: string) {
    const key = optionKey ? `themes_settings_${optionKey}` : `themes_settings`;
    try {
      const stringData = localStorage.getItem(key);
      return JSON.parse(stringData);
    } catch (error) {
      return null;
    }
  }

}

export const COUNTRIES = [
  {
    id: 1,
    englishName: 'Afghanistan',
    localName: 'Afghanestan',
    region: 'South-Central Asia',
    alpha2Code: 'AF',
    countryCallingCode: 93
  },
  {
    id: 2,
    englishName: 'Albania',
    localName: 'Shqiperia',
    region: 'Southern Europe',
    alpha2Code: 'AL',
    countryCallingCode: 355
  },
  {
    id: 3,
    englishName: 'Algeria',
    localName: 'Al Jaza\'\'ir',
    region: 'Northern Africa',
    alpha2Code: 'DZ',
    countryCallingCode: 213
  },
  {
    id: 4,
    englishName: 'American Samoa',
    localName: 'American Samoa',
    region: 'Polynesia, Oceania',
    alpha2Code: 'AS',
    countryCallingCode: 1684
  },
  {
    id: 5,
    englishName: 'Andorra',
    localName: 'Andorra',
    region: 'Southern Europe',
    alpha2Code: 'AD',
    countryCallingCode: 376
  },
  {
    id: 6,
    englishName: 'Angola',
    localName: 'Angola',
    region: 'Central Africa',
    alpha2Code: 'AO',
    countryCallingCode: 244
  },
  {
    id: 7,
    englishName: 'Anguilla',
    localName: 'Anguilla',
    region: 'Leeward Islands, Caribbean',
    alpha2Code: 'AI',
    countryCallingCode: 1264
  },
  {
    id: 8,
    englishName: 'Antarctica',
    localName: 'Antarctica',
    region: 'Antarctica',
    alpha2Code: 'AQ',
    countryCallingCode: 672
  },
  {
    id: 9,
    englishName: 'Antigua and Barbuda',
    localName: 'Antigua and Barbuda',
    region: 'Leeward Islands, Caribbean',
    alpha2Code: 'AG',
    countryCallingCode: 1268
  },
  {
    id: 10,
    englishName: 'Argentina',
    localName: 'Argentina',
    region: 'Southern South America',
    alpha2Code: 'AR',
    countryCallingCode: 54
  },
  {
    id: 11,
    englishName: 'Armenia',
    localName: 'Hayastan',
    region: 'Western Asia',
    alpha2Code: 'AM',
    countryCallingCode: 374
  },
  {
    id: 12,
    englishName: 'Aruba',
    localName: 'Aruba',
    region: 'Leeward Islands, Caribbean',
    alpha2Code: 'AW',
    countryCallingCode: 297
  },
  {
    id: 13,
    englishName: 'Australia',
    localName: 'Australia',
    region: 'Australia/Oceania',
    alpha2Code: 'AU',
    countryCallingCode: 61
  },
  {
    id: 14,
    englishName: 'Austria',
    localName: 'Österreich',
    region: 'Western Europe',
    alpha2Code: 'AT',
    countryCallingCode: 43
  },
  {
    id: 15,
    englishName: 'Azerbaijan',
    localName: 'Azarbaycan',
    region: 'Caucasus, Western Asia',
    alpha2Code: 'AZ',
    countryCallingCode: 994
  },
  {
    id: 16,
    englishName: 'Bahamas',
    localName: 'Bahamas',
    region: 'Caribbean',
    alpha2Code: 'BS',
    countryCallingCode: 1242
  },
  {
    id: 17,
    englishName: 'Bahrain',
    localName: 'Al Bahrayn',
    region: 'Arabian Peninsula, Middle East',
    alpha2Code: 'BH',
    countryCallingCode: 973
  },
  {
    id: 18,
    englishName: 'Bangladesh',
    localName: 'Bangladesh',
    region: 'South-Central Asia',
    alpha2Code: 'BD',
    countryCallingCode: 880
  },
  {
    id: 19,
    englishName: 'Barbados',
    localName: 'Barbados',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'BB',
    countryCallingCode: 1246
  },
  {
    id: 20,
    englishName: 'Belarus',
    localName: 'Byelarus',
    region: 'Eastern Europe',
    alpha2Code: 'BY',
    countryCallingCode: 375
  },
  {
    id: 21,
    englishName: 'Belgium',
    localName: 'Belgique/Belgie',
    region: 'Western Europe',
    alpha2Code: 'BE',
    countryCallingCode: 32
  },
  {
    id: 22,
    englishName: 'Belize',
    localName: 'Belize',
    region: 'Central America',
    alpha2Code: 'BZ',
    countryCallingCode: 501
  },
  {
    id: 23,
    englishName: 'Benin',
    localName: 'Benin',
    region: 'West Africa',
    alpha2Code: 'BJ',
    countryCallingCode: 229
  },
  {
    id: 24,
    englishName: 'Bermuda',
    localName: 'Bermuda',
    region: 'North America',
    alpha2Code: 'BM',
    countryCallingCode: 1441
  },
  {
    id: 25,
    englishName: 'Bhutan',
    localName: 'Bhutan',
    region: 'South-Central Asia',
    alpha2Code: 'BT',
    countryCallingCode: 975
  },
  {
    id: 26,
    englishName: 'Bolivia',
    localName: 'Bolivia',
    region: 'Central South America',
    alpha2Code: 'BO',
    countryCallingCode: 591
  },
  {
    id: 27,
    englishName: 'Bosnia and Herzegovina',
    localName: 'Bosna i Hercegovina',
    region: 'Southern Europe',
    alpha2Code: 'BA',
    countryCallingCode: 387
  },
  {
    id: 28,
    englishName: 'Botswana',
    localName: 'Botswana',
    region: 'Southern Africa',
    alpha2Code: 'BW',
    countryCallingCode: 267
  },
  {
    id: 29,
    englishName: 'Brazil',
    localName: 'Brasil',
    region: 'Central Eastern South America',
    alpha2Code: 'BR',
    countryCallingCode: 55
  },
  {
    id: 30,
    englishName: 'Brunei Darussalam',
    localName: 'Negara Brunei Darussalam',
    region: 'Southeast Asia',
    alpha2Code: 'BN',
    countryCallingCode: 673
  },
  {
    id: 31,
    englishName: 'Bulgaria',
    localName: 'Bulgaria',
    region: 'Balkan, Eastern Europe',
    alpha2Code: 'BG',
    countryCallingCode: 359
  },
  {
    id: 32,
    englishName: 'Burkina Faso',
    localName: 'Burkina Faso',
    region: 'West Africa',
    alpha2Code: 'BF',
    countryCallingCode: 226
  },
  {
    id: 33,
    englishName: 'Burundi',
    localName: 'Burundi',
    region: 'Eastern Africa, African Great Lakes',
    alpha2Code: 'BI',
    countryCallingCode: 257
  },
  {
    id: 34,
    englishName: 'Cambodia',
    localName: 'Kampuchea',
    region: 'South-East Asia',
    alpha2Code: 'KH',
    countryCallingCode: 855
  },
  {
    id: 35,
    englishName: 'Cameroon',
    localName: 'Cameroon',
    region: 'Central Africa',
    alpha2Code: 'CM',
    countryCallingCode: 237
  },
  {
    id: 36,
    englishName: 'Canada',
    localName: 'Canada',
    region: 'North North America',
    alpha2Code: 'CA',
    countryCallingCode: 1
  },
  {
    id: 37,
    englishName: 'Cape Verde',
    localName: 'Cabo Verde',
    region: 'West Africa',
    alpha2Code: 'CV',
    countryCallingCode: 238
  },
  {
    id: 38,
    englishName: 'Cayman Islands',
    localName: 'Cayman Islands',
    region: 'Greater Antilles, Caribbean',
    alpha2Code: 'KY',
    countryCallingCode: 1345
  },
  {
    id: 39,
    englishName: 'Central African Republic',
    localName: 'Republique Centrafricaine',
    region: 'Central Africa',
    alpha2Code: 'CF',
    countryCallingCode: 236
  },
  {
    id: 40,
    englishName: 'Chad',
    localName: 'Tchad',
    region: 'Central Africa',
    alpha2Code: 'TD',
    countryCallingCode: 235
  },
  {
    id: 41,
    englishName: 'Chile',
    localName: 'Chile',
    region: 'Western South America',
    alpha2Code: 'CL',
    countryCallingCode: 56
  },
  {
    id: 42,
    englishName: 'China',
    localName: 'Zhong Guo',
    region: 'Eastern Asia',
    alpha2Code: 'CN',
    countryCallingCode: 86
  },
  {
    id: 43,
    englishName: 'Christmas Island',
    localName: 'Christmas Island',
    region: 'Southeast Asia',
    alpha2Code: 'CX',
    countryCallingCode: 618
  },
  {
    id: 44,
    englishName: 'Cocos [Keeling] Islands',
    localName: 'Cocos [Keeling] Islands',
    region: 'South-East Asia, Australia',
    alpha2Code: 'CC',
    countryCallingCode: 618
  },
  {
    id: 45,
    englishName: 'Colombia',
    localName: 'Colombia',
    region: 'North West South America',
    alpha2Code: 'CO',
    countryCallingCode: 57
  },
  {
    id: 46,
    englishName: 'Comoros',
    localName: 'Comores',
    region: 'Eastern Africa',
    alpha2Code: 'KM',
    countryCallingCode: 269
  },
  {
    id: 47,
    englishName: 'Democratic Republic of the Congo [Kinshasa]',
    localName: 'République Démocratique du Congo',
    region: 'Central Africa',
    alpha2Code: 'CD',
    countryCallingCode: 243
  },
  {
    id: 48,
    englishName: 'Congo, Republic of [Brazzaville]',
    localName: 'République du Congo',
    region: 'Central Africa',
    alpha2Code: 'CG',
    countryCallingCode: 242
  },
  {
    id: 49,
    englishName: 'Cook Islands',
    localName: 'Cook Islands',
    region: 'Polynesia, Oceania',
    alpha2Code: 'CK',
    countryCallingCode: 682
  },
  {
    id: 50,
    englishName: 'Costa Rica',
    localName: 'Costa Rica',
    region: 'Central America',
    alpha2Code: 'CR',
    countryCallingCode: 506
  },
  {
    id: 51,
    englishName: 'Ivory Coast',
    localName: 'Cote d\'\'Ivoire',
    region: 'West Africa',
    alpha2Code: 'CI',
    countryCallingCode: 225
  },
  {
    id: 52,
    englishName: 'Croatia',
    localName: 'Hrvatska',
    region: 'Southern Europe',
    alpha2Code: 'HR',
    countryCallingCode: 385
  },
  {
    id: 53,
    englishName: 'Cuba',
    localName: 'Cuba',
    region: 'Greater Antilles, Caribbean',
    alpha2Code: 'CU',
    countryCallingCode: 53
  },
  {
    id: 54,
    englishName: 'Cyprus',
    localName: 'Kibris, Kypros',
    region: 'Mediterranean, Western Asia',
    alpha2Code: 'CY',
    countryCallingCode: 357
  },
  {
    id: 55,
    englishName: 'Czech Republic',
    localName: 'Ceska Republika',
    region: 'Eastern Europe',
    alpha2Code: 'CZ',
    countryCallingCode: 420
  },
  {
    id: 56,
    englishName: 'Denmark',
    localName: 'Danmark',
    region: 'Northern Europe',
    alpha2Code: 'DK',
    countryCallingCode: 45
  },
  {
    id: 57,
    englishName: 'Djibouti',
    localName: 'Djibouti',
    region: 'Eastern Africa',
    alpha2Code: 'DJ',
    countryCallingCode: 253
  },
  {
    id: 58,
    englishName: 'Dominica',
    localName: 'Dominica',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'DM',
    countryCallingCode: 1767
  },
  {
    id: 59,
    englishName: 'Dominican Republic',
    localName: 'Dominicana, Republica',
    region: 'Greater Antilles, Caribbean',
    alpha2Code: 'DO',
    countryCallingCode: 1809
  },
  {
    id: 60,
    englishName: 'East Timor [Timor-Leste]',
    localName: 'Timor',
    region: 'South-East Asia',
    alpha2Code: 'TL',
    countryCallingCode: 670
  },
  {
    id: 61,
    englishName: 'Ecuador',
    localName: 'Ecuador',
    region: 'North West South America',
    alpha2Code: 'EC',
    countryCallingCode: 593
  },
  {
    id: 62,
    englishName: 'Egypt',
    localName: 'Misr',
    region: 'Africa, Middle East',
    alpha2Code: 'EG',
    countryCallingCode: 20
  },
  {
    id: 63,
    englishName: 'El Salvador',
    localName: 'El Salvador',
    region: 'Central America',
    alpha2Code: 'SV',
    countryCallingCode: 503
  },
  {
    id: 64,
    englishName: 'Equatorial Guinea',
    localName: 'Guinea Ecuatorial',
    region: 'Central Africa',
    alpha2Code: 'GQ',
    countryCallingCode: 240
  },
  {
    id: 65,
    englishName: 'Eritrea',
    localName: 'Hagere Ertra',
    region: 'Eastern Africa',
    alpha2Code: 'ER',
    countryCallingCode: 291
  },
  {
    id: 66,
    englishName: 'Estonia',
    localName: 'Eesti Vabariik',
    region: 'Northern Europe',
    alpha2Code: 'EE',
    countryCallingCode: 372
  },
  {
    id: 67,
    englishName: 'Ethiopia',
    localName: 'Ityop\'\'iya',
    region: 'Eastern Africa',
    alpha2Code: 'ET',
    countryCallingCode: 251
  },
  {
    id: 68,
    englishName: 'Falkland Islands',
    localName: 'Islas Malvinas',
    region: 'Southern South America',
    alpha2Code: 'FK',
    countryCallingCode: 500
  },
  {
    id: 69,
    englishName: 'Faroe Islands',
    localName: 'Foroyar',
    region: 'Northern Europe',
    alpha2Code: 'FO',
    countryCallingCode: 298
  },
  {
    id: 70,
    englishName: 'Fiji',
    localName: 'Fiji',
    region: 'Melanesia, Oceania',
    alpha2Code: 'FJ',
    countryCallingCode: 679
  },
  {
    id: 71,
    englishName: 'Finland',
    localName: 'Suomen Tasavalta',
    region: 'Northern Europe',
    alpha2Code: 'FI',
    countryCallingCode: 358
  },
  {
    id: 72,
    englishName: 'France',
    localName: 'France',
    region: 'Western Europe',
    alpha2Code: 'FR',
    countryCallingCode: 33
  },
  {
    id: 73,
    englishName: 'French Guiana',
    localName: 'Guyane',
    region: 'Northern South America',
    alpha2Code: 'GF',
    countryCallingCode: 594
  },
  {
    id: 74,
    englishName: 'French Polynesia',
    localName: 'Polynésie Française',
    region: 'Polynesia, Oceania',
    alpha2Code: 'PF',
    countryCallingCode: 689
  },
  {
    id: 75,
    englishName: 'French Southern Territories',
    localName: 'Terres Australes et Antarctiques Françaises',
    region: 'Southern South America, Antarctic',
    alpha2Code: 'TF',
    countryCallingCode: 262
  },
  {
    id: 76,
    englishName: 'Gabon',
    localName: 'Gabon',
    region: 'Central Africa',
    alpha2Code: 'GA',
    countryCallingCode: 241
  },
  {
    id: 77,
    englishName: 'Gambia',
    localName: 'The Gambia',
    region: 'West Africa',
    alpha2Code: 'GM',
    countryCallingCode: 220
  },
  {
    id: 78,
    englishName: 'Georgia',
    localName: 'Sak\'\'art\'\'velo',
    region: 'Western Asia',
    alpha2Code: 'GE',
    countryCallingCode: 995
  },
  {
    id: 79,
    englishName: 'Germany',
    localName: 'Deutschland',
    region: 'Western Europe',
    alpha2Code: 'DE',
    countryCallingCode: 49
  },
  {
    id: 80,
    englishName: 'Ghana',
    localName: 'Ghana',
    region: 'West Africa',
    alpha2Code: 'GH',
    countryCallingCode: 233
  },
  {
    id: 81,
    englishName: 'Gibraltar',
    localName: 'Gibraltar',
    region: 'Southern Europe',
    alpha2Code: 'GI',
    countryCallingCode: 350
  },
  {
    id: 82,
    englishName: 'Great Britain',
    localName: 'Great Britain',
    region: 'Northern Europe',
    alpha2Code: 'GB',
    countryCallingCode: 44
  },
  {
    id: 83,
    englishName: 'Greece',
    localName: 'Ellas or Ellada',
    region: 'Southern Europe',
    alpha2Code: 'GR',
    countryCallingCode: 30
  },
  {
    id: 84,
    englishName: 'Greenland',
    localName: 'Kalaallit Nunaat',
    region: 'North America',
    alpha2Code: 'GL',
    countryCallingCode: 299
  },
  {
    id: 85,
    englishName: 'Grenada',
    localName: 'Grenada',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'GD',
    countryCallingCode: 1473
  },
  {
    id: 86,
    englishName: 'Guadeloupe',
    localName: 'Guadeloupe',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'GP',
    countryCallingCode: 590
  },
  {
    id: 87,
    englishName: 'Guam',
    localName: 'Guam',
    region: 'Micronesia, Oceania',
    alpha2Code: 'GU',
    countryCallingCode: 1671
  },
  {
    id: 88,
    englishName: 'Guatemala',
    localName: 'Guatemala',
    region: 'Central America',
    alpha2Code: 'GT',
    countryCallingCode: 502
  },
  {
    id: 89,
    englishName: 'Guinea',
    localName: 'Guinee',
    region: 'West Africa',
    alpha2Code: 'GN',
    countryCallingCode: 224
  },
  {
    id: 90,
    englishName: 'Guinea-Bissau',
    localName: 'Guine-Bissau',
    region: 'West Africa',
    alpha2Code: 'GW',
    countryCallingCode: 245
  },
  {
    id: 91,
    englishName: 'Guyana',
    localName: 'Guyana',
    region: 'North Eastern South America',
    alpha2Code: 'GY',
    countryCallingCode: 592
  },
  {
    id: 92,
    englishName: 'Haiti',
    localName: 'Haiti',
    region: 'Greater Antilles, Caribbean',
    alpha2Code: 'HT',
    countryCallingCode: 509
  },
  {
    id: 93,
    englishName: 'Holy See',
    localName: 'Status Civitatis Vaticanæ',
    region: 'Southern Europe within Italy',
    alpha2Code: 'VA',
    countryCallingCode: 379
  },
  {
    id: 94,
    englishName: 'Honduras',
    localName: 'Honduras',
    region: 'Central America',
    alpha2Code: 'HN',
    countryCallingCode: 504
  },
  {
    id: 95,
    englishName: 'Hong Kong',
    localName: 'Xianggang',
    region: 'Eastern Asia',
    alpha2Code: 'HK',
    countryCallingCode: 852
  },
  {
    id: 96,
    englishName: 'Hungary',
    localName: 'Magyarorszag',
    region: 'Eastern Europe',
    alpha2Code: 'HU',
    countryCallingCode: 36
  },
  {
    id: 97,
    englishName: 'Iceland',
    localName: 'Lyoveldio Island',
    region: 'Northern Europe',
    alpha2Code: 'IS',
    countryCallingCode: 354
  },
  {
    id: 98,
    englishName: 'India',
    localName: 'Bharat',
    region: 'South-Central Asia',
    alpha2Code: 'IN',
    countryCallingCode: 91
  },
  {
    id: 99,
    englishName: 'Indonesia',
    localName: 'Indonesia',
    region: 'Maritime South-East Asia',
    alpha2Code: 'ID',
    countryCallingCode: 62
  },
  {
    id: 100,
    englishName: 'Iran [Islamic Republic of)',
    localName: 'Iran',
    region: 'South-Central Asia',
    alpha2Code: 'IR',
    countryCallingCode: 98
  },
  {
    id: 101,
    englishName: 'Iraq',
    localName: 'Iraq',
    region: 'Middle East, Western Asia',
    alpha2Code: 'IQ',
    countryCallingCode: 964
  },
  {
    id: 102,
    englishName: 'Ireland',
    localName: 'Éire',
    region: 'Northern Europe',
    alpha2Code: 'IE',
    countryCallingCode: 353
  },
  {
    id: 103,
    englishName: 'Israel',
    localName: 'Yisra\'\'el',
    region: 'Middle East, Western Asia',
    alpha2Code: 'IL',
    countryCallingCode: 972
  },
  {
    id: 104,
    englishName: 'Italy',
    localName: 'Italia',
    region: 'Southern Europe',
    alpha2Code: 'IT',
    countryCallingCode: 39
  },
  {
    id: 105,
    englishName: 'Jamaica',
    localName: 'Jamaica',
    region: 'Greater Antilles, Caribbean',
    alpha2Code: 'JM',
    countryCallingCode: 1876
  },
  {
    id: 106,
    englishName: 'Japan',
    localName: 'Nippon',
    region: 'Eastern Asia',
    alpha2Code: 'JP',
    countryCallingCode: 81
  },
  {
    id: 107,
    englishName: 'Jordan',
    localName: 'Al Urdun',
    region: 'Middle East, Western Asia',
    alpha2Code: 'JO',
    countryCallingCode: 962
  },
  {
    id: 108,
    englishName: 'Kazakhstan',
    localName: 'Qazaqstan',
    region: 'Central Asia',
    alpha2Code: 'KZ',
    countryCallingCode: 7
  },
  {
    id: 109,
    englishName: 'Kenya',
    localName: 'Kenya',
    region: 'Eastern Africa',
    alpha2Code: 'KE',
    countryCallingCode: 254
  },
  {
    id: 110,
    englishName: 'Kiribati',
    localName: 'Kiribati, Kiribas',
    region: 'Micronesia, Oceania',
    alpha2Code: 'KI',
    countryCallingCode: 686
  },
  {
    id: 111,
    englishName: 'Korea, Democratic People\'\'s Rep. [North Korea]',
    localName: 'Choson',
    region: 'Eastern Asia',
    alpha2Code: 'KP',
    countryCallingCode: 850
  },
  {
    id: 112,
    englishName: 'Korea, Republic of [South Korea]',
    localName: 'Han-guk',
    region: 'Eastern Asia',
    alpha2Code: 'KR',
    countryCallingCode: 82
  },
  {
    id: 113,
    englishName: 'Kuwait',
    localName: 'Al Kuwayt',
    region: 'Middle East, Western Asia',
    alpha2Code: 'KW',
    countryCallingCode: 965
  },
  {
    id: 114,
    englishName: 'Kyrgyzstan',
    localName: 'Kyrgyz Respublikasy',
    region: 'Central Asia',
    alpha2Code: 'KG',
    countryCallingCode: 996
  },
  {
    id: 115,
    englishName: 'Lao, People\'\'s Democratic Republic',
    localName: 'Lao',
    region: 'South-East Asia',
    alpha2Code: 'LA',
    countryCallingCode: 856
  },
  {
    id: 116,
    englishName: 'Latvia',
    localName: 'Latvija',
    region: 'Northern Europe',
    alpha2Code: 'LV',
    countryCallingCode: 371
  },
  {
    id: 117,
    englishName: 'Lebanon',
    localName: 'Lubnan',
    region: 'Middle East, Western Asia',
    alpha2Code: 'LB',
    countryCallingCode: 961
  },
  {
    id: 118,
    englishName: 'Lesotho',
    localName: 'Lesotho',
    region: 'Southern Africa',
    alpha2Code: 'LS',
    countryCallingCode: 266
  },
  {
    id: 119,
    englishName: 'Liberia',
    localName: 'Liberia',
    region: 'West Africa',
    alpha2Code: 'LR',
    countryCallingCode: 231
  },
  {
    id: 120,
    englishName: 'Libya',
    localName: 'Libiyah',
    region: 'Northern Africa',
    alpha2Code: 'LY',
    countryCallingCode: 218
  },
  {
    id: 121,
    englishName: 'Liechtenstein',
    localName: 'Liechtenstein',
    region: 'Western Europe',
    alpha2Code: 'LI',
    countryCallingCode: 423
  },
  {
    id: 122,
    englishName: 'Lithuania',
    localName: 'Lietuva',
    region: 'Northern Europe',
    alpha2Code: 'LT',
    countryCallingCode: 370
  },
  {
    id: 123,
    englishName: 'Luxembourg',
    localName: 'Luxembourg, Letzebuerg',
    region: 'Western Europe',
    alpha2Code: 'LU',
    countryCallingCode: 352
  },
  {
    id: 124,
    englishName: 'Macau',
    localName: 'Aomen',
    region: 'Eastern Asia',
    alpha2Code: 'MO',
    countryCallingCode: 853
  },
  {
    id: 125,
    englishName: 'Macedonia, Rep. of',
    localName: 'Makedonija',
    region: 'Southern Europe',
    alpha2Code: 'MK',
    countryCallingCode: 389
  },
  {
    id: 126,
    englishName: 'Madagascar',
    localName: 'Madagascar',
    region: 'Eastern Africa',
    alpha2Code: 'MG',
    countryCallingCode: 261
  },
  {
    id: 127,
    englishName: 'Malawi',
    localName: 'Malawi',
    region: 'Eastern Africa',
    alpha2Code: 'MW',
    countryCallingCode: 265
  },
  {
    id: 128,
    englishName: 'Malaysia',
    localName: 'Malaysia',
    region: 'Southeast Asia',
    alpha2Code: 'MY',
    countryCallingCode: 60
  },
  {
    id: 129,
    englishName: 'Maldives',
    localName: 'Dhivehi Raajje',
    region: 'South-Central Asia',
    alpha2Code: 'MV',
    countryCallingCode: 960
  },
  {
    id: 130,
    englishName: 'Mali',
    localName: 'Mali',
    region: 'West Africa',
    alpha2Code: 'ML',
    countryCallingCode: 223
  },
  {
    id: 131,
    englishName: 'Malta',
    localName: 'Malta',
    region: 'Southern Europe',
    alpha2Code: 'MT',
    countryCallingCode: 356
  },
  {
    id: 132,
    englishName: 'Marshall Islands',
    localName: 'Marshall Islands',
    region: 'Micronesia, Oceania',
    alpha2Code: 'MH',
    countryCallingCode: 692
  },
  {
    id: 133,
    englishName: 'Martinique',
    localName: 'Martinique',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'MQ',
    countryCallingCode: 596
  },
  {
    id: 134,
    englishName: 'Mauritania',
    localName: 'Muritaniyah',
    region: 'West Africa',
    alpha2Code: 'MR',
    countryCallingCode: 222
  },
  {
    id: 135,
    englishName: 'Mauritius',
    localName: 'Mauritius',
    region: 'Eastern Africa',
    alpha2Code: 'MU',
    countryCallingCode: 230
  },
  {
    id: 136,
    englishName: 'Mayotte',
    localName: 'Mayotte',
    region: 'Eastern Africa',
    alpha2Code: 'YT',
    countryCallingCode: 262
  },
  {
    id: 137,
    englishName: 'Mexico',
    localName: 'Estados Unidos Mexicanos',
    region: 'North America',
    alpha2Code: 'MX',
    countryCallingCode: 52
  },
  {
    id: 138,
    englishName: 'Micronesia, Federal States of',
    localName: 'Micronesia',
    region: 'Micronesia, Oceania',
    alpha2Code: 'FM',
    countryCallingCode: 691
  },
  {
    id: 139,
    englishName: 'Moldova, Republic of',
    localName: 'Moldova',
    region: 'Eastern Europe',
    alpha2Code: 'MD',
    countryCallingCode: 373
  },
  {
    id: 140,
    englishName: 'Monaco',
    localName: 'Monaco',
    region: 'Southern Europe',
    alpha2Code: 'MC',
    countryCallingCode: 377
  },
  {
    id: 141,
    englishName: 'Mongolia',
    localName: 'Mongol Uls',
    region: 'Eastern Asia',
    alpha2Code: 'MN',
    countryCallingCode: 976
  },
  {
    id: 142,
    englishName: 'Montenegro',
    localName: 'Crna Gora',
    region: 'Southern Europe',
    alpha2Code: 'ME',
    countryCallingCode: 382
  },
  {
    id: 143,
    englishName: 'Montserrat',
    localName: 'Montserrat',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'MS',
    countryCallingCode: 1664
  },
  {
    id: 144,
    englishName: 'Morocco',
    localName: 'Al Maghrib',
    region: 'Northern Africa',
    alpha2Code: 'MA',
    countryCallingCode: 212
  },
  {
    id: 145,
    englishName: 'Mozambique',
    localName: 'Mocambique',
    region: 'Eastern Africa',
    alpha2Code: 'MZ',
    countryCallingCode: 258
  },
  {
    id: 146,
    englishName: 'Myanmar, Burma',
    localName: 'Myanma Naingngandaw',
    region: 'Southeast Asia',
    alpha2Code: 'MM',
    countryCallingCode: 95
  },
  {
    id: 147,
    englishName: 'Namibia',
    localName: 'Namibia',
    region: 'Southern Africa',
    alpha2Code: 'NA',
    countryCallingCode: 264
  },
  {
    id: 148,
    englishName: 'Nauru',
    localName: 'Nauru',
    region: 'Micronesia, Oceania',
    alpha2Code: 'NR',
    countryCallingCode: 674
  },
  {
    id: 149,
    englishName: 'Nepal',
    localName: 'Nepal',
    region: 'South-Central Asia',
    alpha2Code: 'NP',
    countryCallingCode: 977
  },
  {
    id: 150,
    englishName: 'Netherlands',
    localName: 'Nederland/Holland',
    region: 'Western Europe',
    alpha2Code: 'NL',
    countryCallingCode: 31
  },
  {
    id: 151,
    englishName: 'Netherlands Antilles',
    localName: 'Nederlandse Antillen',
    region: 'Caribbean',
    alpha2Code: 'AN',
    countryCallingCode: 599
  },
  {
    id: 152,
    englishName: 'New Caledonia',
    localName: 'Nouvelle-Calédonie',
    region: 'Melanesia, Oceania',
    alpha2Code: 'NC',
    countryCallingCode: 687
  },
  {
    id: 153,
    englishName: 'New Zealand',
    localName: 'Aotearoa',
    region: 'Oceania; Australia',
    alpha2Code: 'NZ',
    countryCallingCode: 64
  },
  {
    id: 154,
    englishName: 'Nicaragua',
    localName: 'Nicaragua',
    region: 'Central America',
    alpha2Code: 'NI',
    countryCallingCode: 505
  },
  {
    id: 155,
    englishName: 'Niger',
    localName: 'Niger',
    region: 'West Africa',
    alpha2Code: 'NE',
    countryCallingCode: 227
  },
  {
    id: 156,
    englishName: 'Nigeria',
    localName: 'Nigeria',
    region: 'West Africa',
    alpha2Code: 'NG',
    countryCallingCode: 234
  },
  {
    id: 157,
    englishName: 'Niue',
    localName: 'Niue',
    region: 'Polynesia, Oceania',
    alpha2Code: 'NU',
    countryCallingCode: 683
  },
  {
    id: 158,
    englishName: 'Northern Mariana Islands',
    localName: 'Northern Mariana Islands',
    region: 'Micronesia, Oceania',
    alpha2Code: 'MP',
    countryCallingCode: 1670
  },
  {
    id: 159,
    englishName: 'Norway',
    localName: 'Norge',
    region: 'Northern Europe',
    alpha2Code: 'NO',
    countryCallingCode: 47
  },
  {
    id: 160,
    englishName: 'Oman',
    localName: 'Saltanat Uman',
    region: 'Middle East',
    alpha2Code: 'OM',
    countryCallingCode: 968
  },
  {
    id: 161,
    englishName: 'Pakistan',
    localName: 'Pakistan',
    region: 'South-Central Asia',
    alpha2Code: 'PK',
    countryCallingCode: 92
  },
  {
    id: 162,
    englishName: 'Palau',
    localName: 'Belau',
    region: 'Micronesia, Oceania',
    alpha2Code: 'PW',
    countryCallingCode: 680
  },
  {
    id: 163,
    englishName: 'Palestinian territories',
    localName: 'Filastin',
    region: 'Middle East, Western Asia',
    alpha2Code: 'PS',
    countryCallingCode: 970
  },
  {
    id: 164,
    englishName: 'Panama',
    localName: 'Panama',
    region: 'Central America',
    alpha2Code: 'PA',
    countryCallingCode: 507
  },
  {
    id: 165,
    englishName: 'Papua New Guinea',
    localName: 'Papua Niu Gini',
    region: 'Maritime Southeast Asia, Melanesia, Oceania',
    alpha2Code: 'PG',
    countryCallingCode: 675
  },
  {
    id: 166,
    englishName: 'Paraguay',
    localName: 'Paraguay',
    region: 'Central South America',
    alpha2Code: 'PY',
    countryCallingCode: 595
  },
  {
    id: 167,
    englishName: 'Peru',
    localName: 'Peru',
    region: 'Western South America',
    alpha2Code: 'PE',
    countryCallingCode: 51
  },
  {
    id: 168,
    englishName: 'Philippines',
    localName: 'Pilipinas',
    region: 'Southeast Asia',
    alpha2Code: 'PH',
    countryCallingCode: 63
  },
  {
    id: 169,
    englishName: 'Pitcairn Island',
    localName: 'Pitcairn Island',
    region: 'Polynesia, Oceania',
    alpha2Code: 'PN',
    countryCallingCode: 870
  },
  {
    id: 170,
    englishName: 'Poland',
    localName: 'Polska',
    region: 'Eastern Europe',
    alpha2Code: 'PL',
    countryCallingCode: 48
  },
  {
    id: 171,
    englishName: 'Portugal',
    localName: 'Portugal',
    region: 'Southern Europe',
    alpha2Code: 'PT',
    countryCallingCode: 351
  },
  {
    id: 172,
    englishName: 'Puerto Rico',
    localName: 'Puerto Rico',
    region: 'Greater Antilles, Caribbean',
    alpha2Code: 'PR',
    countryCallingCode: 1787
  },
  {
    id: 173,
    englishName: 'Qatar',
    localName: 'Dawlat Qatar',
    region: 'Arabian Peninsula, Middle East',
    alpha2Code: 'QA',
    countryCallingCode: 974
  },
  {
    id: 174,
    englishName: 'Reunion Island',
    localName: 'Ile de la Réunion',
    region: 'Eastern Africa',
    alpha2Code: 'RE',
    countryCallingCode: 262
  },
  {
    id: 175,
    englishName: 'Romania',
    localName: 'Romania',
    region: 'Eastern Europe',
    alpha2Code: 'RO',
    countryCallingCode: 40
  },
  {
    id: 176,
    englishName: 'Russian Federation',
    localName: 'Rossiya',
    region: 'Eastern Europe - Northern Asia',
    alpha2Code: 'RU',
    countryCallingCode: 7
  },
  {
    id: 177,
    englishName: 'Rwanda',
    localName: 'Rwanda',
    region: 'Eastern Africa, African Great Lakes',
    alpha2Code: 'RW',
    countryCallingCode: 250
  },
  {
    id: 178,
    englishName: 'Saint Kitts and Nevis',
    localName: '',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'KN',
    countryCallingCode: 1869
  },
  {
    id: 179,
    englishName: 'Saint Lucia',
    localName: 'Saint Lucia',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'LC',
    countryCallingCode: 1758
  },
  {
    id: 180,
    englishName: 'Saint Vincent and the Grenadines',
    localName: '',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'VC',
    countryCallingCode: 1784
  },
  {
    id: 181,
    englishName: 'Samoa',
    localName: 'Samoa',
    region: 'Polynesia, Oceania',
    alpha2Code: 'WS',
    countryCallingCode: 685
  },
  {
    id: 182,
    englishName: 'San Marino',
    localName: 'San Marino',
    region: 'Southern Europe within Italy',
    alpha2Code: 'SM',
    countryCallingCode: 378
  },
  {
    id: 183,
    englishName: 'Sao Tome and Principe',
    localName: 'Sao Tome e Principe',
    region: 'Central Africa',
    alpha2Code: 'ST',
    countryCallingCode: 239
  },
  {
    id: 184,
    englishName: 'Saudi Arabia',
    localName: 'Al Arabiyah as Suudiyah',
    region: 'Arabian Peninsula, Middle East',
    alpha2Code: 'SA',
    countryCallingCode: 966
  },
  {
    id: 185,
    englishName: 'Senegal',
    localName: 'Senegal',
    region: 'West Africa',
    alpha2Code: 'SN',
    countryCallingCode: 221
  },
  {
    id: 186,
    englishName: 'Serbia',
    localName: 'Srbija',
    region: 'Southern Europe',
    alpha2Code: 'RS',
    countryCallingCode: 381
  },
  {
    id: 187,
    englishName: 'Seychelles',
    localName: 'Seychelles',
    region: 'Eastern Africa',
    alpha2Code: 'SC',
    countryCallingCode: 248
  },
  {
    id: 188,
    englishName: 'Sierra Leone',
    localName: 'Sierra Leone',
    region: 'West Africa',
    alpha2Code: 'SL',
    countryCallingCode: 232
  },
  {
    id: 189,
    englishName: 'Singapore',
    localName: 'Singapore',
    region: 'Southeast Asia',
    alpha2Code: 'SG',
    countryCallingCode: 65
  },
  {
    id: 190,
    englishName: 'Slovakia [Slovak Republic]',
    localName: 'Slovensko',
    region: 'Eastern Europe',
    alpha2Code: 'SK',
    countryCallingCode: 421
  },
  {
    id: 191,
    englishName: 'Slovenia',
    localName: 'Slovenija',
    region: 'Southern Europe',
    alpha2Code: 'SI',
    countryCallingCode: 386
  },
  {
    id: 192,
    englishName: 'Solomon Islands',
    localName: 'Solomon Islands',
    region: 'Melanesia, Oceania',
    alpha2Code: 'SB',
    countryCallingCode: 677
  },
  {
    id: 193,
    englishName: 'Somalia',
    localName: 'Somalia',
    region: 'Eastern Africa',
    alpha2Code: 'SO',
    countryCallingCode: 252
  },
  {
    id: 194,
    englishName: 'South Africa',
    localName: 'South Africa',
    region: 'Southern Africa',
    alpha2Code: 'ZA',
    countryCallingCode: 27
  },
  {
    id: 195,
    englishName: 'South Sudan',
    localName: 'South Sudan',
    region: 'East-Central Africa',
    alpha2Code: 'SS',
    countryCallingCode: 211
  },
  {
    id: 196,
    englishName: 'Spain',
    localName: 'España',
    region: 'Southern Europe',
    alpha2Code: 'ES',
    countryCallingCode: 34
  },
  {
    id: 197,
    englishName: 'Sri Lanka',
    localName: 'Sri Lanka',
    region: 'South-Central Asia',
    alpha2Code: 'LK',
    countryCallingCode: 94
  },
  {
    id: 198,
    englishName: 'Sudan',
    localName: 'As-Sudan',
    region: 'Northern Africa',
    alpha2Code: 'SD',
    countryCallingCode: 249
  },
  {
    id: 199,
    englishName: 'Suriname',
    localName: 'Suriname',
    region: 'North-Eastern South America',
    alpha2Code: 'SR',
    countryCallingCode: 597
  },
  {
    id: 200,
    englishName: 'Swaziland',
    localName: 'Swaziland',
    region: 'Southern Africa',
    alpha2Code: 'SZ',
    countryCallingCode: 47
  },
  {
    id: 201,
    englishName: 'Sweden',
    localName: 'Sverige',
    region: 'Northern Europe',
    alpha2Code: 'SE',
    countryCallingCode: 46
  },
  {
    id: 202,
    englishName: 'Switzerland',
    localName: 'Schweiz [German], Suisse [French], Svizzera [Italian]',
    region: 'Western Europe',
    alpha2Code: 'CH',
    countryCallingCode: 41
  },
  {
    id: 203,
    englishName: 'Syria, Syrian Arab Republic',
    localName: 'Suriyah',
    region: 'Middle East, Western Asia',
    alpha2Code: 'SY',
    countryCallingCode: 963
  },
  {
    id: 204,
    englishName: 'Taiwan [Republic of China]',
    localName: 'T\'\'ai-wan',
    region: 'Eastern Asia',
    alpha2Code: 'TW',
    countryCallingCode: 886
  },
  {
    id: 205,
    englishName: 'Tajikistan',
    localName: 'Jumhurii Tojikiston',
    region: 'Central Asia',
    alpha2Code: 'TJ',
    countryCallingCode: 992
  },
  {
    id: 206,
    englishName: 'Tanzania; officially the United Republic of Tanzania',
    localName: 'Jamhuri ya Muungano wa Tanzania',
    region: 'Eastern Africa',
    alpha2Code: 'TZ',
    countryCallingCode: 255
  },
  {
    id: 207,
    englishName: 'Thailand',
    localName: 'Prathet Thai',
    region: 'South-East Asia',
    alpha2Code: 'TH',
    countryCallingCode: 66
  },
  {
    id: 208,
    englishName: 'Tibet',
    localName: 'Bod',
    region: 'South-Central Asia',
    alpha2Code: 'XZ',
    countryCallingCode: 895
  },
  {
    id: 209,
    englishName: 'Timor-Leste [East Timor]',
    localName: 'Timor',
    region: 'Maritime South-East Asia',
    alpha2Code: 'TL',
    countryCallingCode: 670
  },
  {
    id: 210,
    englishName: 'Togo',
    localName: 'Republique Togolaise',
    region: 'West Africa',
    alpha2Code: 'TG',
    countryCallingCode: 228
  },
  {
    id: 211,
    englishName: 'Tokelau',
    localName: 'Tokelau',
    region: 'Oceania/Australia',
    alpha2Code: 'TK',
    countryCallingCode: 690
  },
  {
    id: 212,
    englishName: 'Tonga',
    localName: 'Tonga',
    region: 'Polynesia, Oceania',
    alpha2Code: 'TO',
    countryCallingCode: 676
  },
  {
    id: 213,
    englishName: 'Trinidad and Tobago',
    localName: 'Trinidad, Tobago',
    region: 'Northern South America, Caribbean',
    alpha2Code: 'TT',
    countryCallingCode: 1868
  },
  {
    id: 214,
    englishName: 'Tunisia',
    localName: 'Tunis',
    region: 'Northern Africa',
    alpha2Code: 'TN',
    countryCallingCode: 216
  },
  {
    id: 215,
    englishName: 'Turkey',
    localName: 'Turkiye',
    region: 'Southeastern Europe, Western Asia',
    alpha2Code: 'TR',
    countryCallingCode: 90
  },
  {
    id: 216,
    englishName: 'Turkmenistan',
    localName: 'Turkmenistan',
    region: 'Central Asia',
    alpha2Code: 'TM',
    countryCallingCode: 993
  },
  {
    id: 217,
    englishName: 'Turks and Caicos Islands',
    localName: 'Turks and Caicos Islands',
    region: 'Caribbean, parts of the Bahamas island chain.',
    alpha2Code: 'TC',
    countryCallingCode: 1649
  },
  {
    id: 218,
    englishName: 'Tuvalu',
    localName: 'Tuvalu',
    region: 'Polynesia, Oceania',
    alpha2Code: 'TV',
    countryCallingCode: 688
  },
  {
    id: 219,
    englishName: 'Ugandax',
    localName: 'Uganda',
    region: 'Eastern Africa',
    alpha2Code: 'UG',
    countryCallingCode: 256
  },
  {
    id: 220,
    englishName: 'Ukraine',
    localName: 'Ukrayina',
    region: 'Eastern Europe',
    alpha2Code: 'UA',
    countryCallingCode: 380
  },
  {
    id: 221,
    englishName: 'United Arab Emirates',
    localName: 'Al Imarat al Arabiyah al Muttahidah',
    region: 'Arabian Peninsula, Middle East',
    alpha2Code: 'AE',
    countryCallingCode: 971
  },
  {
    id: 222,
    englishName: 'United Kingdom',
    localName: 'United Kingdom',
    region: 'Northern Europe',
    alpha2Code: 'GB',
    countryCallingCode: 44
  },
  {
    id: 223,
    englishName: 'United States',
    localName: 'United States',
    region: 'North America',
    alpha2Code: 'US',
    countryCallingCode: 1
  },
  {
    id: 224,
    englishName: 'Uruguay',
    localName: 'Republica Oriental del Uruguay',
    region: 'Central East South America',
    alpha2Code: 'UY',
    countryCallingCode: 598
  },
  {
    id: 225,
    englishName: 'Uzbekistan',
    localName: 'Uzbekiston Respublikasi',
    region: 'Central Asia',
    alpha2Code: 'UZ',
    countryCallingCode: 998
  },
  {
    id: 226,
    englishName: 'Vanuatu',
    localName: 'Vanuatu',
    region: 'Melanesia, Oceania',
    alpha2Code: 'VU',
    countryCallingCode: 678
  },
  {
    id: 227,
    englishName: 'Vatican City State [Holy See]',
    localName: 'Status Civitatis Vaticanæ',
    region: 'Southern Europe within Italy',
    alpha2Code: 'VA',
    countryCallingCode: 379
  },
  {
    id: 228,
    englishName: 'Venezuela',
    localName: 'Venezuela',
    region: 'Northern South America',
    alpha2Code: 'VE',
    countryCallingCode: 58
  },
  {
    id: 229,
    englishName: 'Vietnam',
    localName: 'Viet Nam',
    region: 'South-East Asia',
    alpha2Code: 'VN',
    countryCallingCode: 84
  },
  {
    id: 230,
    englishName: 'Virgin Islands [British]',
    localName: '',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'VG',
    countryCallingCode: 1284
  },
  {
    id: 231,
    englishName: 'Virgin Islands [U.S.]',
    localName: 'Virgin Islands',
    region: 'Lesser Antilles, Caribbean',
    alpha2Code: 'VI',
    countryCallingCode: 1340
  },
  {
    id: 232,
    englishName: 'Wallis and Futuna Islands',
    localName: 'Wallis et Futuna',
    region: 'Polynesia, Oceania',
    alpha2Code: 'WF',
    countryCallingCode: 681
  },
  {
    id: 233,
    englishName: 'Western Sahara',
    localName: 'Aṣ-Ṣaḥrā’ al-Gharbīyah',
    region: 'Northern Africa',
    alpha2Code: 'EH',
    countryCallingCode: 967
  },
  {
    id: 234,
    englishName: 'Yemen',
    localName: 'Al Yaman',
    region: 'Arabian Peninsula, Middle East',
    alpha2Code: 'YE',
    countryCallingCode: 260
  },
  {
    id: 235,
    englishName: 'Zambia',
    localName: 'Zambia',
    region: 'Eastern Africa',
    alpha2Code: 'ZM',
    countryCallingCode: 263
  },
  {
    id: 236,
    englishName: 'Europe',
    localName: 'Europe',
    region: 'Europe',
    alpha2Code: 'EU',
    countryCallingCode: 0
  }
];


import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { ApiService } from '../../core/services';
import { UserAuthService } from './user-auth.service';

export class UnitsCoefficient {
  name: string;
  units: string[];
  m: number[];
  c: number[];
}

@Injectable()
export class UnitSystemService {

  private coefficientData: UnitsCoefficient[] = [];

  constructor(private apiService: ApiService,
    private userAuthService: UserAuthService) {
    this.ensureCoefficient();
  }

  ensureCoefficient() {
    if (this.coefficientData.length) return;
    const localData = this.loadUnitSystem();
    if (localData && localData.length) this.coefficientData = localData;
    else {
      this.apiService.get('/Setting/unitConverter').subscribe(res => {
        this.coefficientData = res.result;
        this.setUnitSystem(res.result);
      }, err => {
        //
      });
    }
  }

  convert(value: number, measurementName: string, unit?: number): number {
    const convertUnit = unit === null || unit === undefined ? this.loadUnitSettings() : unit;
    if (!convertUnit) return value;
    const coefficient = this.getCoefficient(measurementName);
    if (coefficient === null || coefficient === undefined) return value;
    return value * coefficient.m[convertUnit] + coefficient.c[convertUnit];
  }

  convertValue(value: number, coefficient: {m: number, c: number}): number {
    return value * coefficient.m + coefficient.c;
  }

  revert(value: number, measurementName: string, unit?: number): number {
    const convertUnit = unit === null || unit === undefined ? this.loadUnitSettings() : unit;
    if (!convertUnit) return value;
    const coefficient = this.getCoefficient(measurementName);
    if (coefficient === null || coefficient === undefined) return value;
    return (value - coefficient.c[convertUnit]) / coefficient.m[convertUnit];
  }

  revertValue(value: number, coefficient: {m: number, c: number}): number {
    return (value - coefficient.c) / coefficient.m;
  }

  getUnit(measurementName: string, unitTarget?: number, unitSource?: string): string {
    const convertUnit = unitTarget === null || unitTarget === undefined ? this.loadUnitSettings() : unitTarget;
    if (convertUnit === null || convertUnit === undefined) return unitSource;
    if (convertUnit === 0 && unitSource) return unitSource;
    const coefficient = this.getCoefficient(measurementName);
    if (coefficient === null || coefficient === undefined) return undefined;
    return coefficient.units[convertUnit];
  }

  getDefaultOptions() {
    return [
      { id: 0, text: 'Oil field'},
      { id: 1, text: 'Metric bar'},
      { id: 2, text: 'Metric kpa'},
      { id: 3, text: 'Metric mpa'},
      { id: 4, text: 'Metric atm'},
    ];
  }

  private getCoefficient(measurement: string): UnitsCoefficient | null {
    if (!this.coefficientData.length) return null;
    for (const c of this.coefficientData) {
      if (c.name === measurement) return c;
    }
    return null;
  }

  getUnitCoefficient(measureType: string, unitSetting: number): {m: number, c: number, unit: string} {
    if (measureType && measureType.trim() && !!unitSetting) {
      const coefficient = this.getCoefficient(measureType);
      if (coefficient !== null && coefficient !== undefined) return { m: coefficient.m[unitSetting], c: coefficient.c[unitSetting], unit: coefficient.units[unitSetting] };
      else return undefined;
    }
    return undefined;
  }

  setUnitSettings(value: number) {
    localStorage.setItem('unit-settings', value.toString());
  }

  loadUnitSettings(): number {
    try {
      const stringData = localStorage.getItem('unit-settings');
      if (!isNil(stringData)) return parseInt(stringData, 10);
      else {
        const userInfo = this.userAuthService.getUserInfo();
        if (userInfo && !isNil(userInfo.unitSystem)) {
          this.setUnitSettings(userInfo.unitSystem);
          return userInfo.unitSystem;
        }
        else return 0;
      }
    } catch (error) {
      return 0;
    }
  }

  setUnitSystem(value) {
    localStorage.setItem('unit-system', JSON.stringify(value));
  }

  loadUnitSystem(): UnitsCoefficient[] {
    try {
      const stringData = localStorage.getItem('unit-system');
      return JSON.parse(stringData);
    } catch (error) {
      return [];
    }
  }

  getFractionDigits(value, decimal?: number): number {
    if (!value) return isNil(decimal) ? 0 : decimal;
    else {
      if (!isNil(decimal)) return decimal;
      else {
        const arr: string[] = value.toString().split('.');
        if (!arr.length || arr.length < 2) return 0;
        else return arr[1].length;
      }
    }
  }

  getValueConvert(measureType: string, value, decimal?: number): number | string {
    const fractionDigits = this.getFractionDigits(value, decimal);

    const parseValue = (val: number) => {
      if (Number.isInteger(val)) return val;
      else return formatNumber(Number(val), 'en-US', `1.0-${fractionDigits}`);
    }

    if ((typeof value === 'string' && isNaN(Number(value))) || isNaN(value)) return value;
    else if (isNil(measureType) || !measureType.length) return parseValue(value);
    else return parseValue(this.convert(value, measureType));
  }

}

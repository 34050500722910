import { Component, OnInit, Input } from '@angular/core';
import { DefaultColor, LIST_SHAPES } from '../../../helpers/app.constants';
import { NbDialogRef } from '@nebular/theme';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep, isEmpty } from 'lodash';
import { IScatterPlotChartSetting, ENUM_AXIS_TYPE } from '../../../services/charts';

@Component({
  selector: 'app-scatter-plot-parameter-settings',
  templateUrl: './scatter-plot-parameter-settings.component.html',
  styleUrls: ['./scatter-plot-parameter-settings.component.scss']
})
export class ScatterPlotParameterSettingsComponent implements OnInit {

  defaultColor: any[] = DefaultColor;

  listShapes: any[] = LIST_SHAPES;
  listDesignActualShapes: any[] = [
    { id: 'Bar', name: 'Bar' },
    { id: 'Line', name: 'Line' }
  ];
  settings = [];

  form: FormGroup;
  shape: AbstractControl;
  color: AbstractControl;

  isXAxisParam: boolean = false;
  cloneChartSetting: IScatterPlotChartSetting;
  xYAxis = [];
  designActualYAxis = [];

  @Input()
  set chartSettings(data: IScatterPlotChartSetting) {
    if (data) {
      this.cloneChartSetting = cloneDeep(data);
      if (data.xAxis) {
        if (data.xAxis.id !== 'time' && data.xAxis.id !== 'well') {
          this.xYAxis.push(data.xAxis);
          this.isXAxisParam = true;
        }
      }
      this.xYAxis = this.xYAxis.concat(data.yAxis.map(item => {
        return Object.assign(item, { isYAxis: true });
      }));
      if (!isEmpty(data.curveYAxis)) {
        this.xYAxis = this.xYAxis.concat(data.curveYAxis.map(item => {
          return Object.assign(item, { isCurve: true });
        }));
      }
      if (!isEmpty(data.fdiYAxis)) {
        this.xYAxis = this.xYAxis.concat(data.fdiYAxis.map(item => {
          return Object.assign(item, { isFDIYAxis: true });
        }));
      }
      if (!isEmpty(data.designActualYAxis)) {
        this.designActualYAxis = data.designActualYAxis;
      }
      this.xYAxis.map(item => item.shapeTemp = [{ id: item.shape, name: item.shape }]);
    }
  }

  get designShapeActive() {
    if (!isEmpty(this.designActualYAxis)) {
      return [{ id: this.designActualYAxis[0].designShape, name: this.designActualYAxis[0].designShape }]
    }
    return;
  }

  get actualShapeActive() {
    if (!isEmpty(this.designActualYAxis)) {
      return [{ id: this.designActualYAxis[0].actualShape, name: this.designActualYAxis[0].actualShape }]
    }
    return;
  }

  constructor(
    private dialogRef: NbDialogRef<any>,
  ) {

  }

  ngOnInit() {
  }

  onSelectShape(item, event) {
    item.shape = event.id;
    item.shapeTemp = [{ id: event.id, name: event.text }];
  }

  onSelectDesignShape(item, event) {
    item.designShape = event.id;
  }
  onSelectActualShape(item, event) {
    item.actualShape = event.id;
  }

  selectColor(item, event) {
    item.color = event;
  }

  selectDesignColor(item, event) {
    item.designColor = event;
  }

  selectActualColor(item, event) {
    item.actualColor = event;
  }

  close() {
    this.dialogRef.close();
  }

  submitForm() {
    if (this.isXAxisParam) {
      this.cloneChartSetting.xAxis = this.xYAxis[0];
    }
    this.cloneChartSetting.yAxis = this.xYAxis.filter(item => item.isYAxis);
    this.cloneChartSetting.curveYAxis = this.xYAxis.filter(item => item.isCurve);
    this.cloneChartSetting.fdiYAxis = this.xYAxis.filter(item => item.isFDIYAxis);
    this.cloneChartSetting.designActualYAxis = [...this.designActualYAxis];

    this.dialogRef.close(this.cloneChartSetting);
  }

}

import { Injectable } from '@angular/core';

export const PAD_COMPARE_WELL = 'padCompareWell';
export const PAD_COMPARE_PARAM_MULTI_WELL = 'padCompareParamMultiWell';
export const PAD_COMPARE_PARAM_SINGLE_WELL = 'padCompareParamSingleWell';
export const KEY_TEMPLATE = 'template';
export const KEY_TEMPLATE_ID = 'templateId';
export const KEY_OFFSET_CHANNEL_SETTINGS = 'offsetChannelSettings';
export const KEY_LAST_MIN_FILTER = 'lastMinFilter';
export const KEY_TIME_FORMAT = 'timeFormat';

@Injectable()
export class RealtimeTreatmentStorageService {
  constructor() { }

  setOption(id, option, value, key: string = 'well') {
    if (option && id !== undefined) {
      localStorage.setItem(`${key}_${id}_${option}`, JSON.stringify(value));
    }
  }

  getOption(id, optionKey, keyType: string = 'well') {
    if (optionKey && id !== undefined) {
      try {
        const stringData = localStorage.getItem(`${keyType}_${id}_${optionKey}`);
        return JSON.parse(stringData);
      } catch (error) {
        return null;
      }
    }
    return null;
  }

  removeOption(id, optionKey, keyType: string = 'well') {
    if (optionKey) {
      localStorage.removeItem(`${keyType}_${id}_${optionKey}`);
    }
  }

  popOption(id, optionKey, keyType = 'well') {
    if (optionKey && id !== undefined) {
      try {
        const stringData = localStorage.getItem(`${keyType}_${id}_${optionKey}`);
        const arr = JSON.parse(stringData);
        arr.pop();
        this.setOption(id, optionKey, arr, keyType);
      } catch (error) {
        return;
      }
    }
    return;
  }

  setRealtimeTreatmentsData(values) {
    if (values) {
      sessionStorage.setItem(`treatment_realtime_data`, JSON.stringify(values));
    }
  }

  getRealtimeTreatmentsData() {
    try {
      const stringData = sessionStorage.getItem(`treatment_realtime_data`);
      return JSON.parse(stringData);
    } catch (error) {
      return null;
    }
  }

  removeRealtimeTreatmentsData() {
    sessionStorage.removeItem(`treatment_realtime_data`);
  }

  clearAll() {
    localStorage.clear();
  }
}

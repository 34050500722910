import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { CPATTERN } from '../../helpers/app.constants';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-input-tags',
  templateUrl: './input-tags.component.html',
  styleUrls: ['./input-tags.component.scss']
})
export class InputTagsComponent implements OnInit {
  form: FormGroup;
  tagName: AbstractControl;
  listTags = [];

  @Input() isExternalCheck: boolean;
  @Input()
  set items(values) {
    this.listTags = [...values];
  }

  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      tagName: new FormControl('', [
        Validators.minLength(2),
        Validators.maxLength(50),
        Validators.pattern(CPATTERN.CHANNEL_MAPPING_NAME),
        this.duplicateNameValidator()
      ])
    });
    this.tagName = this.form.controls['tagName'];
  }

  duplicateNameValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const existedName = this.listTags.find(item => item.name === control.value.trim());
      return existedName ? {'tagName': {value: control.value}} : null;
    };
  }

  addTag(event, tagName) {
    if (this.form.valid && tagName) {
      if (this.isExternalCheck) {
        this.onAdd.emit({ items: [...this.listTags], tagName: tagName });
      } else {
        this.listTags.push({
          name: tagName
        });
        this.onAdd.emit([...this.listTags]);
        this.tagName.setValue('');
      }
    } else {
      if (this.tagName.errors && this.tagName.errors.tagName) {
        this.toasterService.showError('The mapped channel name has already been added. Please enter another name');
      } else if (this.tagName.errors) {
        this.toasterService.showError('The mapped channel name has to be between 2 and 50 characters long and not contain special characters (except for dot, space, underscore, -, %,(),[]).');
      }
    }
  }

  removeTag(event, item, index) {
    if (item) {
      this.listTags.splice(index, 1);
      this.onDelete.emit([...this.listTags]);
    }
  }

  disableInput() {
    if (this.tagName) {
      this.tagName.disable();
    }
  }

  enableInput() {
    if (this.tagName) {
      this.tagName.enable();
    }
  }

  unsetInput() {
    if (this.tagName) {
      this.tagName.setValue('');
    }
  }
}

import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { SearchWellListControlService } from './search-well-list-control.service';
import { SearchWellListService } from './search-well-list.service';
import { SearchWellListBase } from './search-well-list-base';
import { uniqBy, isEmpty, each } from 'lodash';
import { WellSearchSettingsService } from '../../services/well-search-settings.service';
import { takeUntil, finalize, tap } from 'rxjs/operators';
import { WELL_SEARCH_OPTIONS } from '../../helpers/app.constants';
import { SearchWellOptionsStorageService, UserAuthService } from '../../services';

@Component({
  selector: 'app-search-well-list',
  templateUrl: './search-well-list.component.html',
  styleUrls: ['./search-well-list.component.scss']
})
export class SearchWellListComponent implements OnInit, OnDestroy {
  destroy$: Subject<any> = new Subject();
  form: FormGroup;
  country: AbstractControl;
  state: AbstractControl;
  county: AbstractControl;
  serviceCompanyId: AbstractControl;
  operatorId: AbstractControl;
  prospect: AbstractControl;
  pad: AbstractControl;
  formation: AbstractControl;

  @Input() isService: boolean = false;
  @Input() isOperator: boolean = false;
  @Output()
  onSearch: EventEmitter<any> = new EventEmitter();

  listContries$: Observable<any[]>;
  listStates$: Observable<any[]>;
  listConties$: Observable<any[]>;
  listPads$: Observable<any[]>;
  listFormations$: Observable<any[]>;
  listServiceCompanies$: Observable<any[]>;
  listOperatorCompanies$: Observable<any[]>;
  defaultOptions: any[] = [
    {
      id: 'state',
      name: 'State',
    },
    {
      id: 'county',
      name: 'County',
    },
    {
      id: 'pad',
      name: 'Pad',
    },
    {
      id: 'formation',
      name: 'Formation',
    },
    {
      id: 'serviceCompanyId',
      name: 'Service Company',
    },
    {
      id: 'operatorId',
      name: 'Operator',
    }
  ];
  selectedOptions: any[] = [];
  listOptions: SearchWellListBase<any>[] = [];
  listOptionsStorage: object = {};

  userId: number;

  constructor(
    private userAuthService: UserAuthService,
    private searchWellListControlService: SearchWellListControlService,
    private searchWellListService: SearchWellListService,
    private wellSearchSettingsService: WellSearchSettingsService,
    private searchWellOptionsStorageService: SearchWellOptionsStorageService
  ) {
    const user = this.userAuthService.getUserInfo();
    if (user) this.userId = user.userId;
    this.getListOptionsStorage();
  }

  private getListOptionsStorage(): void {
    this.listOptionsStorage = this.searchWellOptionsStorageService.getListOptions(this.userId);
  }

  private setListOptionsStorage(): void {
    this.searchWellOptionsStorageService.setListOptions(this.listOptionsStorage, this.userId);
  }

  private removeListOptionsStorage(): void {
    this.listOptionsStorage = {};
    this.searchWellOptionsStorageService.removeListOptions();
  }

  ngOnInit() {
    this.wellSearchSettingsService
      .getSettings()
      .pipe(takeUntil(this.destroy$))
      .subscribe(items => {
        if (!isEmpty(items)) {
          // map selected options
          this.selectedOptions = items.map(item => {
            return WELL_SEARCH_OPTIONS.find(opt => opt.name === item.name);
          });
          this.selectedOptions = uniqBy(this.selectedOptions, 'id');
        } else {
          this.selectedOptions = this.defaultOptions;
        }
        this.getSearchWellListOptions();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  getSearchWellListOptions() {
    let paramOptions: { id: any; name: string }[];
    if (!this.listOptionsStorage) {
      this.listOptionsStorage = {};
      paramOptions = this.selectedOptions;
    } else {
      paramOptions = this.selectedOptions.filter(item => {
        return this.listOptionsStorage[item.id] === null || this.listOptionsStorage[item.id] === undefined;
      });
    }
    if (paramOptions && paramOptions.length > 0) {
      this.searchWellListService.getSearchWellListOptions(paramOptions)
        .subscribe(
          options => {
            paramOptions.forEach(opt => {
              const option = options ? options.find(item => item.key === opt.id) : null;
              if (option) {
                this.listOptionsStorage[option.key] = option;
              }
            });
            this.setListOptionsStorage();
            this.createForm();
          }
        )
    } else {
      this.createForm();
    }

  }

  private createForm(): void {
    const options: any[] = [];
    this.selectedOptions.forEach(item => {
      if (this.listOptionsStorage[item.id]) {
        options.push(this.listOptionsStorage[item.id]);
      }
    });
    this.listOptions = options;
    if (!this.form) {
      this.form = this.searchWellListControlService.toFormGroup(options);
    }
  }

  search() {
    const formData = this.form.value;
    if (formData && !isEmpty(formData.state)) {
      formData.state = formData.state.map(item => item.id).join(',');
    }
    if (formData && !isEmpty(formData.county)) {
      formData.county = formData.county.map(item => item.id).join(',');
    }
    if (formData && !isEmpty(formData.pad)) {
      formData.pad = formData.pad.map(item => item.id).join(',');
    }
    if (formData && !isEmpty(formData.formation)) {
      formData.formation = formData.formation.map(item => item.id).join(',');
    }
    if (formData && !isEmpty(formData.operatorId)) {
      formData.operatorId = formData.operatorId.map(item => item.id).join(',');
    }
    if (formData && !isEmpty(formData.serviceCompanyId)) {
      formData.serviceCompanyId = formData.serviceCompanyId.map(item => item.id).join(',');
    }
    this.onSearch.emit(formData);
  }

  reset() {
    this.form.reset();
    this.removeListOptionsStorage();
    this.onSearch.emit(this.form.value);
    this.getSearchWellListOptions();
  }

}

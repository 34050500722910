import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormGroup, FormControl } from '@angular/forms';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-fdi-well-selection-modal',
  templateUrl: './fdi-well-selection-modal.component.html',
  styleUrls: ['./fdi-well-selection-modal.component.scss']
})
export class FdiWellSelectionModalComponent implements OnInit {
  form: FormGroup;

  @Input() listWells: any[] = [];
  @Input() activeWellIds: number[] = [];
  @Input() title: string = 'Observation Well';

  get selectedWell() {
    return this.form.controls.selectedWell;
  }

  constructor(
    private dialogRef: NbDialogRef<any>,
  ) { }

  ngOnInit() {
    this.listWells = this.listWells.filter(well => this.activeWellIds.includes(well.wellId));
    this.form = new FormGroup({
      selectedWell: !isEmpty(this.listWells) ? new FormControl(this.listWells[0]) : undefined
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    this.dialogRef.close(this.selectedWell.value);
  }

  onSelectWell(eventData) {
    if (eventData && eventData.id) {
      const well = this.listWells.find(item => item.wellId === eventData.id);
      this.selectedWell.setValue(well);
    }
  }

}

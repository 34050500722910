import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/services';
import { IEditChannelMappedDto, IRenameChannel } from '../models';

@Injectable()
export class ChannelMappingService {
  prefixPath = `/companies`;

  constructor(private apiService: ApiService) { }

  getAll(companyId): Observable<any> {
    const queryString = companyId ? '?companyId=' + companyId : '';
    return this.apiService.get(`${this.prefixPath}/channelMapped` + queryString);
  }
  create(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/channelMapped`, param, finalize);
  }
  update(param: IEditChannelMappedDto, finalize?: VoidFunction): Observable<any> {
    return this.apiService.put(`${this.prefixPath}/channelMapped`, param, finalize);
  }
  delete(mappedName, companyId): Observable<any> {
    return this.apiService.delete(`${this.prefixPath}/channelMapped?ChannelMappedName=${mappedName}&CompanyId=${companyId}`);
  }
  getOriginalName(wellId: number, treatmentId: number): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/wells/${wellId}/treatments/${treatmentId}/getOriginalName`);
  }
  getAllOriginalName(wellId: number): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/wells/${wellId}/getOriginalName`);
  }
  getChannelName(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/getChannelName`);
  }

  renameChannel(treatmentId: number, reqBody: IRenameChannel[] , finalize?: VoidFunction, params?: any): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/treatments/${treatmentId}/renameChannel`, reqBody, finalize, undefined, params);
  }
  renameChannelLater(treatmentId: number, reqBody: IRenameChannel[], finalize?: VoidFunction, params?: any): Observable<any> {
    return this.apiService.post(`${this.prefixPath}/treatments/${treatmentId}/renameChannelLater`, reqBody, finalize, undefined, params);
  }

}

import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-well-grid-view',
  templateUrl: './well-grid-view.component.html',
  styleUrls: ['./well-grid-view.component.scss']
})
export class WellGridViewComponent implements OnInit, OnDestroy {
  editData: any;

  @Input() classNames: string;
  @Input() listWells: any[] = [];

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  editProblemWell(item: any) {
    this.editData = item;
    if (item.treatmentId) {
      this.router.navigate(['pages/treatments/realtime', item.treatmentId], {
        queryParams: { wellId: item.wellId }
      });
    }
  }

}

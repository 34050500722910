import { Directive, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appInputNumberInRange]'
})
export class InputNumberInRangeDirective {
  constructor(private el: ElementRef) { }
  minValue = 0;
  maxValue = 1000;

  @Input() 
  set rangeMin(value) {
    this.minValue = value * 1;
  }
  @Input() 
  set rangeMax(value) {
    this.maxValue = value * 1;
  }
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  @HostListener('keyup', ['$event.target.value', '$event']) 
  onkeyup(value, event) {
    this.resetValue(value);
  }

  @HostListener('keydown', ['$event.target.value', '$event']) 
  onkeydown(value, event) {
    this.resetValue(value);
  }

  @HostListener('blur', ['$event.target.value', '$event']) 
  onblur(value, event) {
    this.resetValue(value);
  }

  resetValue(value) {
    if (value < this.minValue) {
      this.el.nativeElement.value = this.minValue;
      this.ngModelChange.emit(this.minValue);
    }
    if (value > this.maxValue) {
      this.el.nativeElement.value = this.maxValue;
      this.ngModelChange.emit(this.maxValue);
    }
  }
}

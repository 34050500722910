import { Injectable } from '@angular/core';
import { SearchWellListBase } from './search-well-list-base';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class SearchWellListControlService {

  constructor() { }

  toFormGroup(searchOptions: SearchWellListBase<string>[] ) {
    let group: any = {};

    searchOptions.forEach(opt => {
      group[opt.key] = opt.key === 'pad' ? new FormControl(opt.value || []) : new FormControl(opt.value || '');
    });
    return new FormGroup(group);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserAuthService, LoadingOverlayService } from '../services';

@Injectable()
export class AuthGuardService {

  constructor(
    private router: Router,
    private userAuthService: UserAuthService,
    private loadingOverlayService: LoadingOverlayService
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    if (!this.userAuthService.isAuthenticated()) {
      this.userAuthService.purgeAuth();
      this.router.navigate(['auth', 'login']);
      return Promise.resolve(false);
    } else {
      this.loadingOverlayService.show();
      return this.userAuthService.populateUser().then(user => {
        this.loadingOverlayService.hide();
        // if (user && user.isChangePassword === false) {
        //   this.router.navigate(['auth', 'changePasswordDefault']);
        // }
        return !!user;
      }).catch(err => {
        this.loadingOverlayService.hide();
        this.userAuthService.purgeAuth();
        this.router.navigate(['auth', 'login']);
        return false;
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IInputDataChart } from '../../models';

export interface IInputDataWell {
  wellId: number;
  wellName: string;
  treatmentId: number;
  treatmentNumber: number;
  realtimeData: IInputDataChart[];
  fracproData: IInputDataChart[];
  flowPathType?: number;
  channelSettings?: any;
  mappedChannels?: any[];
  aliasName?: string;
  color?: string;
}

export interface IWellOptions {
  wellId: number;
  wellName: string;
  selectedTreatmentList?: any[];
  aliasName?: string;
  wellColor?: string;
  longitude?: number;
  latitude?: number;
  tzTimeZone?: string;
  stTimeZone?: string;
}


@Injectable()
export class MultiWellChartService {

  private zoomBufferDataSubject: BehaviorSubject<IInputDataWell[]> = new BehaviorSubject(null);
  private wellOptionsDataSubject: BehaviorSubject<IWellOptions[]> = new BehaviorSubject(null);
  public onWellAliasNameChangeSubject: Subject<IWellOptions> = new Subject();
  public onWellAliasColorChangeSubject: Subject<IWellOptions> = new Subject();

  constructor() { }

  setZoomBufferData(data: IInputDataWell[]) {
    this.zoomBufferDataSubject.next(data);
  }

  getZoomBufferData(): Observable<IInputDataWell[]> {
    return this.zoomBufferDataSubject.asObservable();
  }

  setWellOptionsData(data: IWellOptions[]) {
    this.wellOptionsDataSubject.next(data);
  }

  getWellOptionsData() {
    return this.wellOptionsDataSubject.asObservable();
  }

}

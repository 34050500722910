import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import { cloneDeep, forEach, isEmpty, some, uniq, uniqBy } from 'lodash';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { IChannelItem, IChannelSettingItemModel, IChannelSettingModel, IInputDataChart, IInputDataMultiFlow, ITimeSetting } from '../../../shared/models';
import { ChannelSelectionService, ChartsService } from '../../../shared/services';
import { Helper } from '../../helpers/helper';
import { ChartTimeFormat, PREFIX_PREDICTIVE_CHANNEL, TIME_SETTING_TYPE } from '../../helpers/app.constants';
import { IBourdetOption } from '../../models/offset-well-compare.model';
import { IResultGetChartDataFunc, IResultIntervalGetChartDataFunc } from '../../models/treatment-plot.model';
import { ITreatmentItem } from '../../models/treatment.model';
import { BaseChartService, DATETIME_CHANNEL_NAME, TIMEFLOAT_CHANNEL_NAME } from '../../services/charts';
import { TreatmentDetailsStorageService } from '../../services/storage/treatment-details-storage.service';
import { TreatmentService } from '../../services/treatment.service';

@Injectable()
export class TreatmentPlotService {

  constructor(
    private treatmentService: TreatmentService,
    private treatmentDetailsStorageService: TreatmentDetailsStorageService,
    private chartsService: ChartsService,
    private channelSelectionService: ChannelSelectionService,
    private baseChartService: BaseChartService
  ) { }

  // private reCalcOffsetData(data, baseDataTime) {
  //   if (!data.values) return data;
  //   if (!data.values.length) return data;

  //   const baseTreatmentDataTimestamp = Date.parse(baseDataTime);
  //   const firstTreatmentDataTimestamp = Date.parse(data.values[0][0]);
  //   const offsetInTimestamp = Math.floor((firstTreatmentDataTimestamp - baseTreatmentDataTimestamp) / 1000);
  //   const mismatchedTimestamp = data.values[0][1] - offsetInTimestamp;
  //   if (mismatchedTimestamp) for (const i of data.values) i[1] = i[1] - mismatchedTimestamp;
  //   return data;
  // }

  private latestDataTime(base, realtime, fracpro) {
    if (!realtime && !fracpro) return base;
    if (realtime && !fracpro) return realtime;
    if (realtime && fracpro) {
      if (realtime > fracpro) return realtime;
      return fracpro;
    }
  }

  requestBourdetDerData(
    wellId: number,
    treatmentId: number,
    channelsMap: string[],
    channelsMapName: string[],
    bourdetOption: IBourdetOption,
    flowPathType?: number,
    baseTreatmentDataTime?: string,
    baseLatestDataTime?: string,
    authToken?: string
  ): Observable<IInputDataMultiFlow> {
    const mapChannelName = data => {
      if (!data) return null;
      const t = data.replace('-BD', '');
      const i = t.lastIndexOf('-');
      if (i >= 0) return t.substring(i + 1).replace(/ /g, '');
      return t.replace(/ /g, '');
    };

    let requestBody: any = { wellId, treatmentId, items: channelsMap, flowPathType, baseTreatmentDataTime };
    requestBody = Object.assign(requestBody, bourdetOption);

    const mapStartDate = (baseDataTime, startDataTime, currentStartDate, bourdetDerRange) => {
      const bourdetTime = bourdetDerRange ? Math.round(-bourdetDerRange) * 1000 : 0;
      const roundDataTime = Helper.addTimestampToString(startDataTime, bourdetTime);
      if (currentStartDate > baseDataTime && currentStartDate > roundDataTime) return currentStartDate;
      if (roundDataTime > baseDataTime && roundDataTime > currentStartDate) return roundDataTime;
      return baseDataTime;
    };

    const mapResult = res => {
      if (!res || !res.result) return res;
      const realtimeData = [{ name: 'REALTIME_DATA', columns: res.result.columns, values: res.result.values }];
      let result = { wellId, treatmentId, cName: channelsMap[0], realtimeData, channelName: requestBody.channelName, fracproData: [] };
      // if (result.realtimeData.length) result.realtimeData[0] = this.reCalcOffsetData(result.realtimeData[0], requestBody.baseTreatmentDataTime);

      result = Object.assign(result, bourdetOption);
      delete result['startDate'];
      delete result['endDate'];
      return result;
    };

    requestBody.cName = channelsMap[0];
    requestBody.channelName = mapChannelName(channelsMapName[0]);
    requestBody.isShowBourdetDer = bourdetOption.isBourdetDer;
    requestBody.startDate = mapStartDate(baseTreatmentDataTime, baseLatestDataTime, bourdetOption.startDate, bourdetOption.bourdetDerRange);

    return this.chartsService.getBourdetDataChart(requestBody, undefined, authToken).pipe(map(res => mapResult(res)));
  }

  requestChartData(wellId: number, treatmentId: number, channelsMap: string[], bourdetOption: IBourdetOption, flowPathType = 0, isSeparateRealTimeData = true, baseTreatmentDataTime?: string, baseLatestDataTime?: string, authToken?: string) {
    if (!channelsMap || !channelsMap.length) return of({
      equipmentData: [],
      realtimeData: [],
      fracproData: [],
      wellId: wellId,
      treatmentId: treatmentId,
      cName: '',
      isBourdetDer: bourdetOption && bourdetOption.isBourdetDer,
      isSmoothData: bourdetOption && bourdetOption.isSmoothData,
      bourdetDerRange: bourdetOption && bourdetOption.bourdetDerRange,
      smoothRange: bourdetOption && bourdetOption.smoothRange,
      channelName: '',
    });
    const mapResult = res => {
      if (!res || !res.result) return res;
      res.result.wellId = wellId;
      res.result.treatmentId = treatmentId;
      if (channelsMap.length === 1) {
        res.result.cName = channelsMap[0];
      }
      // if (res.result.realtimeData.length) res.result.realtimeData[0] = this.reCalcOffsetData(res.result.realtimeData[0], baseTreatmentDataTime);
      return res.result;
    };

    const requestBody: any = { wellId, treatmentId, items: channelsMap, flowPathType, isSeparateRealTimeData, baseTreatmentDataTime: baseLatestDataTime };
    if (bourdetOption && bourdetOption.startDate) requestBody.startDataTime = bourdetOption.startDate;
    if (bourdetOption && bourdetOption.endDate) requestBody.endDataTime = bourdetOption.endDate;
    return this.chartsService.getDataChart(requestBody, undefined, authToken).pipe(map(res => mapResult(res)));
  }

  getChartData(
    wellId: number,
    treatmentId: number,
    channelsMap: string[],
    channelsMapName: string[],
    bourdetOption: IBourdetOption,
    flowPathType?: number,
    isSeparateRealTimeData?: boolean,
    baseTreatmentDataTime?: string,
    baseRealtimeDataTime?: string,
    baseFracproDataTime?: string,
    authToken?: string
  ): Observable<IInputDataMultiFlow> {
    flowPathType = typeof flowPathType === 'number' && !isNaN(flowPathType) ? flowPathType : 0;
    const lastestDataTime = this.latestDataTime(baseTreatmentDataTime, baseRealtimeDataTime, baseFracproDataTime);
    
    if (!channelsMap || !channelsMap.length) return of({
      equipmentData: [],
      realtimeData: [],
      fracproData: [],
      wellId: wellId,
      treatmentId: treatmentId,
      cName: '',
      isBourdetDer: bourdetOption && bourdetOption.isBourdetDer,
      isSmoothData: bourdetOption && bourdetOption.isSmoothData,
      bourdetDerRange: bourdetOption && bourdetOption.bourdetDerRange,
      smoothRange: bourdetOption && bourdetOption.smoothRange,
      channelName: '',
    });

    if (bourdetOption && bourdetOption.isBourdetDer) {
      return this.requestBourdetDerData(wellId, treatmentId, channelsMap, channelsMapName, bourdetOption, flowPathType, baseTreatmentDataTime, lastestDataTime, authToken);
    } else {
      return this.requestChartData(wellId, treatmentId, channelsMap, bourdetOption, flowPathType, isSeparateRealTimeData, baseTreatmentDataTime, lastestDataTime, authToken);
    }
  }

  getWellTreatmentsData(offsetChannels: IChannelSettingItemModel[]): Observable<ITreatmentItem[]> {
    if (!offsetChannels) return of(null);
    if (!offsetChannels.length) return of(null);

    const settings = uniqBy(offsetChannels, ['wellId', 'treatmentId']);
    const requests$ = settings.map(setting => {
      const wellId = setting.wellId;
      const treatmentId = setting.treatmentId;

      const treatmentLocalData = this.treatmentDetailsStorageService.getTreatmentData(treatmentId);
      if (treatmentLocalData) {
        return of(treatmentLocalData);
      } else {
        return this.treatmentService.getTreatmentDetailWebApi(wellId, treatmentId)
          .pipe(map(res => res.result))
          .pipe(tap(treatmentData => {
            this.treatmentDetailsStorageService.setTreatmentData(treatmentId, treatmentData);
          }));
      }
    });

    return forkJoin(requests$);
  }
  // getOffsetWellCompareData
  getOffsetChartInputData(
    offsetChannels: IChannelSettingItemModel[],
    treatmentItem: ITreatmentItem,
    flowPathType?: number,
    currentOffsetInputData?: IInputDataMultiFlow[],
    timeSetting?: ITimeSetting,
    baseTreatmentDateTime?: string | number,
    isRealtime?: boolean,
    isRealtimeBourdet?: boolean,
    treatmentsToken?: { wellId: number, treatmentId: number, authToken: string, startTime: number }[]
  ): Observable<IInputDataMultiFlow[]> {
    if (!offsetChannels) return of(null);
    if (!offsetChannels.length) return of(null);

    const requests$ = offsetChannels.map(setting => {
      const wellId = setting.wellId;
      const treatmentId = setting.treatmentId;
      let authToken: string;
      if (treatmentsToken && treatmentsToken.length) {
        const tokenExist = treatmentsToken.find(item => item.wellId === wellId && item.treatmentId === treatmentId);
        if (tokenExist) authToken = tokenExist.authToken;
      }
      const channelsMap = [setting.cName];
      const channelsMapName = [setting.channelName];
      let treatmentDetailData$: Observable<ITreatmentItem>;
      const treatmentLocalData = this.treatmentDetailsStorageService.getTreatmentData(treatmentId);
      if (treatmentLocalData) {
        treatmentDetailData$ = of(treatmentLocalData);
      } else {
        treatmentDetailData$ = this.treatmentService.getTreatmentDetailWebApi(wellId, treatmentId)
          .pipe(map(res => res.result))
          .pipe(tap(treatmentData => {
            this.treatmentDetailsStorageService.setTreatmentData(treatmentId, treatmentData);
          }));
      }

      const calcBourdetFilterTime = (channelSetting: IChannelSettingItemModel, treatmentBaseTime: number): any => {
        let startDate = null;
        let endDate = null;

        if (!channelSetting.isRealtime) {
          if (timeSetting.type === TIME_SETTING_TYPE.EpochTime) {
            const offset = timeSetting.endIndex - timeSetting.startIndex;
            startDate = Helper.toDateTimeString(treatmentBaseTime * 1000);
            endDate = Helper.toDateTimeString(treatmentBaseTime * 1000 + offset);
          } else {
            const offset = timeSetting.endIndex - timeSetting.startIndex;
            startDate = Helper.toDateTimeString(treatmentBaseTime * 1000);
            endDate = Helper.toDateTimeString((treatmentBaseTime + offset) * 1000);
          }
        } else {
          if (timeSetting.type === TIME_SETTING_TYPE.EpochTime) {
            startDate = Helper.toDateTimeString(timeSetting.startIndex);
            endDate = Helper.toDateTimeString(timeSetting.endIndex);
          } else {
            startDate = Helper.toDateTimeString((timeSetting.startIndex + treatmentBaseTime) * 1000);
            endDate = Helper.toDateTimeString((timeSetting.endIndex + treatmentBaseTime) * 1000);
          }
        }

        return { startDate, endDate };
      };

      const calcTreatmentBaseTime = (channelSetting: IChannelSettingItemModel, treatmentInfo: ITreatmentItem): number => {
        // if this offset channel is NOT real time: return it origin baseTreatmentDateTime
        if (!channelSetting.isRealtime) return treatmentInfo.baseTreatmentDateTime;
        // else return the main treatment baseTreatmentDateTime
        if (!baseTreatmentDateTime) return treatmentInfo.baseTreatmentDateTime;
        if (typeof baseTreatmentDateTime === 'number') return baseTreatmentDateTime;
        return Math.round(Date.parse(baseTreatmentDateTime) / 1000);
      };

      const realtimeBourdet = isRealtimeBourdet === false ? isRealtimeBourdet : true;

      return treatmentDetailData$.pipe(switchMap(res => {
        const treatmentInfo = res;
        // const dayLight = treatmentItem.dayLight ? treatmentItem.dayLight : 0;
        // const timeZoneOffset = (treatmentItem.timeZone + dayLight) / 60; // in minutes

        const treatmentTimestamp = calcTreatmentBaseTime(setting, treatmentInfo);
        const treatmentDateTime = new Date(treatmentTimestamp * 1000).toISOString();

        const bourdetOption: IBourdetOption = calcBourdetFilterTime(setting, treatmentTimestamp);
        if (setting.isBourdetDer) {
          bourdetOption.isSmoothData = setting.isSmoothData;
          bourdetOption.smoothRange = setting.smoothRange;
          bourdetOption.isBourdetDer = setting.isBourdetDer;
          bourdetOption.bourdetDerRange = setting.bourdetDerRange;
        }

        if (isRealtime) {
          bourdetOption.endDate = null;
          bourdetOption.startDate = null;
        }

        // if there are currentOffsetInputData: find currentOffsetData
        // perform fetch data with currentTreatmentDateTime and concat data to currentOffsetData
        const currentOffsetData = (!currentOffsetInputData || !currentOffsetInputData.length) ? null : currentOffsetInputData.find(item => Helper.findMultiProp(item, setting, TreatmentService.COMPARE_CHANNEL_PROP));

        const existCurrentoffsetData = (currentData: IInputDataMultiFlow) => {
          if (!currentData) return false;
          if (!currentData.realtimeData || !currentData.realtimeData.length) return false;
          if (!currentData.realtimeData[0].values) return false;
          if (!currentData.realtimeData[0].values.length) return false;
          return true;
        };

        if (existCurrentoffsetData(currentOffsetData)) {
          // CASE 1: if NO realtime bourdet on bourdet channel
          if (bourdetOption.isBourdetDer && !realtimeBourdet) {
            currentOffsetData.isBourdetDer = bourdetOption.isBourdetDer;
            currentOffsetData.bourdetDerRange = bourdetOption.bourdetDerRange;
            currentOffsetData.isSmoothData = bourdetOption.isSmoothData;
            currentOffsetData.smoothRange = bourdetOption.smoothRange;
            return of(currentOffsetData);
          }

          const firstCurrentOffsetData = currentOffsetData.realtimeData[0].values[0][0];
          const lastCurrentOffsetData = currentOffsetData.realtimeData[0].values[currentOffsetData.realtimeData[0].values.length - 1][0];
          const isStartTimeWithin = timeSetting.startIndex >= Helper.mapDateTimeTimestamp(firstCurrentOffsetData);
          const isEndTimeWithin = timeSetting.endIndex <= Helper.mapDateTimeTimestamp(lastCurrentOffsetData);

          // CASE 2: if both startDate and endDate in current time: filter them and return: do not need to call api
          if (isStartTimeWithin && isEndTimeWithin) {
            const filter = x => Helper.filterInRange(Helper.mapDateTimeTimestamp(x[0]), timeSetting.startIndex, timeSetting.endIndex);
            currentOffsetData.realtimeData[0].values = currentOffsetData.realtimeData[0].values.filter(x => filter(x));
            currentOffsetData.isBourdetDer = bourdetOption.isBourdetDer;
            currentOffsetData.bourdetDerRange = bourdetOption.bourdetDerRange;
            currentOffsetData.isSmoothData = bourdetOption.isSmoothData;
            currentOffsetData.smoothRange = bourdetOption.smoothRange;
            return of(currentOffsetData);
          }

          let predictChannelsMap;
          if (setting.originalName) {
            const originalNameLoweCase = setting.originalName.toLowerCase();
            if (originalNameLoweCase.indexOf(PREFIX_PREDICTIVE_CHANNEL) === 0) predictChannelsMap = channelsMap;
          }
          const currentRealtimeData = currentOffsetData.realtimeData[0];
          const currentFracproData = currentOffsetData.fracproData[0];
          const lastRealtimeDateTime = currentRealtimeData ? this.getLastTimeFromDataPoint(currentRealtimeData, undefined, predictChannelsMap) : null;
          const lastFracproDateTime = currentFracproData ? this.getLastTimeFromDataPoint(currentFracproData, undefined, predictChannelsMap) : null;

          // DEFAULT: return data get from server and append to current
          return this.getChartData(wellId, treatmentId, channelsMap, channelsMapName, bourdetOption, flowPathType, treatmentItem.isSeparateRealTimeData, lastRealtimeDateTime, lastFracproDateTime, undefined, authToken).pipe(map(responseInputData => {
            const realtimeData = responseInputData.realtimeData[0];
            const fracproData = responseInputData.fracproData[0];
            // update timestamp data by timezone offset
            // this.baseChartService.updateTimestampByTimezoneOffset(realtimeData, timeZoneOffset);
            // this.baseChartService.updateTimestampByTimezoneOffset(fracproData, timeZoneOffset);
            // concat updated data to current data
            const concatUpdateData = (data, updateData) => {
              if (!data) return [];
              if (!data.values) return [];
              if (!updateData) return data.values;
              if (!updateData.values) return data.values;
              // return [...data.values, ...updateData.values];
              return data.values.concat(updateData.values);
            };

            if (currentRealtimeData) currentRealtimeData.values = concatUpdateData(currentRealtimeData, realtimeData);
            if (currentFracproData) currentFracproData.values = concatUpdateData(currentFracproData, fracproData);
            // set other stat to currentOffsetData
            currentOffsetData.isBourdetDer = bourdetOption.isBourdetDer;
            currentOffsetData.bourdetDerRange = bourdetOption.bourdetDerRange;
            currentOffsetData.isSmoothData = bourdetOption.isSmoothData;
            currentOffsetData.smoothRange = bourdetOption.smoothRange;
            return currentOffsetData;
          }));
        }
        // if there is NO currentOffsetInputData: perform fresh fetch
        // other stat for channel is exec within the fetch service itself
        else {
          // CASE 1: if NO realtime bourdet on bourdet channel
          if (bourdetOption.isBourdetDer && !realtimeBourdet) {
            if (currentOffsetData) {
              currentOffsetData.fracproData = [];
              currentOffsetData.realtimeData = [];
              currentOffsetData.isBourdetDer = bourdetOption.isBourdetDer;
              currentOffsetData.bourdetDerRange = bourdetOption.bourdetDerRange;
              currentOffsetData.isSmoothData = bourdetOption.isSmoothData;
              currentOffsetData.smoothRange = bourdetOption.smoothRange;
            }
            return of(currentOffsetData);
          }

          // DEFAULT: return data get from server
          return this.getChartData(wellId, treatmentId, channelsMap, channelsMapName, bourdetOption, flowPathType, treatmentItem.isSeparateRealTimeData, treatmentDateTime, undefined, undefined, authToken).pipe(map(resData => {
            // if (resData.realtimeData && resData.realtimeData.length) this.baseChartService.updateTimestampByTimezoneOffset(resData.realtimeData[0], timeZoneOffset);
            // if (resData.fracproData && resData.fracproData.length) this.baseChartService.updateTimestampByTimezoneOffset(resData.fracproData[0], timeZoneOffset);
            return resData;
          }));
        }
      }));
    });
    return forkJoin(requests$);
  }

  checkChannelAppear(setting, listRealtimeChannels, listFracProChannels) {
    if (!isEmpty(listRealtimeChannels) || !isEmpty(listFracProChannels)) {
      // First Left Channel
      const firstLeftChannel = (setting && setting.firstLeft.channels) ? setting.firstLeft.channels : [];
      // Second Left Channel
      const secondLeftChannel = (setting && setting.secondLeft.channels) ? setting.secondLeft.channels : [];
      // First Right Channel
      const firstRightChannel = (setting && setting.firstRight.channels) ? setting.firstRight.channels : [];
      // Second Right Channel
      const secondRightChannel = (setting && setting.secondRight.channels) ? setting.secondRight.channels : [];
      // Search same value on Channel List
      const handleChannel = (channels: any[]) => {
        let result = [];
        forEach(channels, (item) => {
          if ((some(listRealtimeChannels, { cName: item.cName, name: item.channelName }) || some(listFracProChannels, { cName: item.cName, name: item.channelName }))) {
            result.push(item);
          }
        });
        return result;
      };
      // Set value on Channel
      setting.firstLeft.channels = handleChannel(firstLeftChannel);
      setting.secondLeft.channels = handleChannel(secondLeftChannel);
      setting.firstRight.channels = handleChannel(firstRightChannel);
      setting.secondRight.channels = handleChannel(secondRightChannel);
    } else {
      setting.firstLeft.channels = [];
      setting.secondLeft.channels = [];
      setting.firstRight.channels = [];
      setting.secondRight.channels = [];
    }
  }

  getLastTimeFromData = (inputData: IInputDataMultiFlow, predictedChannels: IChannelItem[]): string => {
    if (!inputData) return '';

    const predictChannelsMap = predictedChannels.map(x => x.cName);
    const curRealtimeData = inputData.realtimeData ? inputData.realtimeData[0] : null;
    const curFracproData = inputData.fracproData ? inputData.fracproData[0] : null;
    let baseRealtimeDataTime = '';
    let baseFracproDataTime = '';

    // find base realtime data time
    if (curRealtimeData && !isEmpty(curRealtimeData.values)) {
      baseRealtimeDataTime = this.getLastTimeFromDataPoint(curRealtimeData, undefined, predictChannelsMap);
      // baseRealtimeDataTime = Helper.addSecondTimeString(baseRealtimeDataTime, 1);
    }
    // find base fracpro data time
    if (curFracproData && !isEmpty(curFracproData.values)) {
      baseFracproDataTime = this.getLastTimeFromDataPoint(curFracproData, undefined, predictChannelsMap);
      // baseFracproDataTime = Helper.addSecondTimeString(baseFracproDataTime, 1);
    }

    if (!baseRealtimeDataTime && !baseFracproDataTime) return '';
    if (!baseRealtimeDataTime) return baseFracproDataTime;
    if (!baseFracproDataTime) return baseRealtimeDataTime;
    return baseRealtimeDataTime < baseFracproDataTime ? baseRealtimeDataTime : baseFracproDataTime;
  }

  mergeRealtimeData(curDataValues: any[], newDataValues: any[], predictChannels: any[]) {
    if (!predictChannels || !predictChannels.length) return [...curDataValues, ...newDataValues];

    const firstNewDataDateTime = newDataValues[0][0];
    const n = curDataValues.length;
    let index = n - 1;
    for (let i = index; i >= 0; i--) {
      if (curDataValues[i][0] >= firstNewDataDateTime) continue;
      index = i;
      break;
    }
    for (let i = 0; i < newDataValues.length; i++) {
      curDataValues[index + i + 1] = newDataValues[i];
    }
    return curDataValues;
  }

  getNewDataChartHandler(
    wellId: number,
    treatmentId: number,
    channelSettings,
    inputCurChartInputData: IInputDataMultiFlow,
    fracproGridCName?: string[],
    flowPathType?: number,
    isSeparateRealTimeData?: boolean,
    baseTreatmentDataTime?: string,
    predictedChannels?: IChannelItem[]
  ): Observable<IResultIntervalGetChartDataFunc> {
    const curChartInputData = cloneDeep(inputCurChartInputData);
    const result: IResultIntervalGetChartDataFunc = {
      isHasNewData: false,
      isHasNewRealtimeData: false,
      isHasNewFracproData: false,
      isValidResponseData: undefined,
      isValidCurrentInputData: undefined,
      newTimeRangeFilter: null,
      responseInputData: curChartInputData
    };
    let channelsMap = this.channelSelectionService.toRequestChannelMap(channelSettings);
    const channelsMapName = this.channelSelectionService.toRequestChannelMap(channelSettings, 'channelName');
    if (!isEmpty(fracproGridCName)) {
      channelsMap = uniq(channelsMap.concat(fracproGridCName));
    }
    if (!isEmpty(channelsMap)) {
      const predictChannelsMap = predictedChannels.map(x => x.cName);
      const curRealtimeData = curChartInputData && curChartInputData.realtimeData ? curChartInputData.realtimeData[0] : null;
      const curFracproData = curChartInputData && curChartInputData.fracproData ? curChartInputData.fracproData[0] : null;
      let baseRealtimeDataTime = baseTreatmentDataTime;
      let baseFracproDataTime = baseTreatmentDataTime;
      // find base realtime data time
      if (curRealtimeData && !isEmpty(curRealtimeData.values)) {
        baseRealtimeDataTime = this.getLastTimeFromDataPoint(curRealtimeData, undefined, predictChannelsMap);
        baseRealtimeDataTime = Helper.addSecondTimeString(baseRealtimeDataTime, 1);
      }
      // find base fracpro data time
      if (curFracproData && !isEmpty(curFracproData.values)) {
        baseFracproDataTime = this.getLastTimeFromDataPoint(curFracproData, undefined, predictChannelsMap);
        baseFracproDataTime = Helper.addSecondTimeString(baseFracproDataTime, 1);
      }
      return this.getChartData(wellId, treatmentId * 1, channelsMap, channelsMapName, null, flowPathType, isSeparateRealTimeData, baseTreatmentDataTime, baseRealtimeDataTime, baseFracproDataTime
      ).pipe(switchMap(response => {
        try {
          const responseInputData = response;
          const isValidResponseData = !!(responseInputData && (responseInputData.realtimeData || responseInputData.fracproData));
          const isValidCurrentInputData = !!(curChartInputData && (curChartInputData.realtimeData || curChartInputData.fracproData));
          // set result values
          result.isValidResponseData = isValidResponseData;
          result.isValidCurrentInputData = isValidCurrentInputData;

          if (isValidResponseData) {
            let isHasNewRealtimeData = false;
            if (responseInputData.realtimeData) {
              const newRealtimeData = responseInputData.realtimeData[0];
              // update current realtime data
              if (curRealtimeData && newRealtimeData && !isEmpty(newRealtimeData.values)) {
                isHasNewRealtimeData = true;
                curRealtimeData.values = this.mergeRealtimeData(curRealtimeData.values, newRealtimeData.values, predictChannelsMap);
              }
            }

            let isHasNewFracproData = false;
            if (responseInputData.fracproData) {
              const newFracproData = responseInputData.fracproData[0];
              // update current fracpro data
              if (curFracproData && newFracproData && !isEmpty(newFracproData.values)) {
                isHasNewFracproData = true;
                curFracproData.values = this.mergeRealtimeData(curFracproData.values, newFracproData.values, predictChannelsMap);
              }
            }

            // update chart series data if has new data
            if (isHasNewRealtimeData || isHasNewFracproData) {
              result.responseInputData = curChartInputData;
              const timeRangeRealtimeData = this.baseChartService.getTimeRangeFilter(curRealtimeData);
              const timeRangeFracproData = this.baseChartService.getTimeRangeFilter(curFracproData);
              const newTimeRangeFilter = this.baseChartService.mergeTimeRangeFilter(timeRangeRealtimeData, timeRangeFracproData);

              // set result values
              result.isHasNewData = true;
              result.isHasNewRealtimeData = isHasNewRealtimeData;
              result.isHasNewFracproData = isHasNewFracproData;
              result.newTimeRangeFilter = newTimeRangeFilter;
            }
          }

          return of(result);
        } catch (err) {
          return throwError(err);
        }
      }));
    } else {
      return of(result);
    }
  }

  getChartDataHandler(
    wellId: number,
    treatmentId: number,
    treatmentInfo: ITreatmentItem,
    channelSettings: IChannelSettingModel,
    offsetChannels?: IChannelSettingItemModel[],
    fracproGridCName?: string[],
    flowPathType?: number,
    baseTreatmentDataTime?: string,
    timeSetting?: ITimeSetting,
    isRealtime?: boolean,
    bourdetOption?: IBourdetOption
  ): Observable<IResultGetChartDataFunc> {
    const result: IResultGetChartDataFunc = {
      chartInputData: null,
      offsetChartInputData: [],
      timeRangeFilter: null
    };
    let channelsMap = this.channelSelectionService.toRequestChannelMap(channelSettings);
    const channelsMapName = this.channelSelectionService.toRequestChannelMap(channelSettings, 'channelName');

    if (!isEmpty(fracproGridCName)) {
      channelsMap = uniq(channelsMap.concat(fracproGridCName));
    }

    return this.getChartData(wellId, treatmentId, channelsMap, channelsMapName, bourdetOption, flowPathType, treatmentInfo.isSeparateRealTimeData, baseTreatmentDataTime)
      .pipe(switchMap(response => {
        const responseInputData = response;
        if (responseInputData && (responseInputData.realtimeData || responseInputData.fracproData)) {
          const realtimeData = responseInputData.realtimeData ? responseInputData.realtimeData[0] : null;
          const fracproData = responseInputData.fracproData ? responseInputData.fracproData[0] : null;
          // update timestamp data by timezone offset
          // this.baseChartService.updateTimestampByTimezoneOffset(realtimeData, wellTimeZoneOffset);
          // this.baseChartService.updateTimestampByTimezoneOffset(fracproData, wellTimeZoneOffset);
          // default chart always in auto scale time mode, in this mode filter time setting always be updated by chart data
          const timeRangeRealtimeData = this.baseChartService.getTimeRangeFilter(realtimeData);
          const timeRangeFracproData = this.baseChartService.getTimeRangeFilter(fracproData);
          // set time range filter
          result.timeRangeFilter = this.baseChartService.mergeTimeRangeFilter(timeRangeRealtimeData, timeRangeFracproData);
          // set chart input data
          result.chartInputData = responseInputData;
        }
        let requestTimeSetting;
        if (timeSetting) requestTimeSetting = timeSetting;
        else if (result.timeRangeFilter && result.timeRangeFilter.timestamp) {
          requestTimeSetting = {
            type: TIME_SETTING_TYPE.EpochTime,
            startIndex: result.timeRangeFilter.timestamp.start,
            endIndex: result.timeRangeFilter.timestamp.end
          } as ITimeSetting;
        } else {
          requestTimeSetting = {
            type: TIME_SETTING_TYPE.EpochTime,
            startIndex: 0,
            endIndex: 0
          } as ITimeSetting;
        }

        // const timeRangeFilterRequest = timeRangeFilter ? timeRangeFilter : result.timeRangeFilter;
        return this.getOffsetChartInputData(offsetChannels, treatmentInfo, flowPathType, null, requestTimeSetting, baseTreatmentDataTime, isRealtime);
      }))
      .pipe(switchMap(response => {
        // set offset chart input data
        result.offsetChartInputData = response;
        return of(result);
      }));
  }

  getStringRawData(
    uniThreeAxisChannel: IChannelSettingItemModel[],
    realtimeData: IInputDataChart, fracproData: IInputDataChart, minTime: number, maxTime: number,
    timeFormat: string,
    offsetChannels: IChannelSettingItemModel[], offsetChartInputData: IInputDataMultiFlow[]
  ) {
    const channelNames = uniThreeAxisChannel.map(channel => channel.name);
    const channelUnits = uniThreeAxisChannel.map(channel => channel.unit ? channel.unit : '');
    let headersNames = ['Time'].concat(channelNames);
    let headersUnits = ['yyyy-MM-dd hh:mm:ss'].concat(channelUnits);
    let realtimeDataFiltered = realtimeData && realtimeData.values ? realtimeData.values : [];
    let fracproDataFiltered = fracproData && fracproData.values ? fracproData.values : [];
    if (minTime && maxTime) {
      if (timeFormat === 'hh:mm') {
        if (realtimeData && !isEmpty(realtimeData.values)) {
          realtimeDataFiltered = realtimeData.values.filter(dataRow => {
            const yValue = dataRow[0];
            return yValue >= minTime && yValue <= maxTime;
          });
        }
        if (fracproData && !isEmpty(fracproData.values)) {
          fracproDataFiltered = fracproData.values.filter(dataRow => {
            const yValue = dataRow[0];
            return yValue >= minTime && yValue <= maxTime;
          });
        }
      } else {
        const minTimeSec = minTime * 60;
        const maxTimeSec = maxTime * 60;
        if (realtimeData && !isEmpty(realtimeData.values)) {
          realtimeDataFiltered = realtimeData.values.filter(dataRow => {
            const yValue = dataRow[1];
            return yValue >= minTimeSec && yValue <= maxTimeSec;
          });
        }
        if (fracproData && !isEmpty(fracproData.values)) {
          fracproDataFiltered = fracproData.values.filter(dataRow => {
            const yValue = dataRow[1];
            return yValue >= minTimeSec && yValue <= maxTimeSec;
          });
        }
      }
    }
    // set base data for loop, will follow by realtime or fracpro data
    let baseDataFiltered = realtimeDataFiltered;
    if (isEmpty(realtimeDataFiltered) && !isEmpty(fracproDataFiltered)) {
      baseDataFiltered = fracproDataFiltered;
    }

    // handle offset channels
    if (!isEmpty(offsetChannels)) {
      let offsetChannelNames = [];
      let offsetChannelUnits = [];
      offsetChannels.forEach(offsetChannel => {
        offsetChannelNames = offsetChannelNames.concat([`${offsetChannel.name}-Time`, offsetChannel.originName]);
        offsetChannelUnits = offsetChannelUnits.concat(['yyyy-MM-dd hh:mm:ss', offsetChannel.unit]);
      });
      headersNames = headersNames.concat(offsetChannelNames);
      headersUnits = headersUnits.concat(offsetChannelUnits);
    }

    const channelData = [];
    baseDataFiltered.forEach((dataRow, index) => {
      let channelDataRow = [];
      const realtimeTimeFloat = dataRow[1];
      let timeData = Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', new Date(dataRow[0]).getTime());
      channelDataRow.push(timeData);
      uniThreeAxisChannel.forEach((channel) => {
        if (channel.cName.indexOf('C') > -1) {
          if (realtimeData && !isEmpty(realtimeData.columns) && !isEmpty(realtimeData.values)) {
            const channelIndex = realtimeData.columns.findIndex(t => t === channel.cName);
            const channelValue = dataRow[channelIndex];
            channelDataRow.push(channelValue);
          }
        } else if (channel.cName.indexOf('F') > -1) {
          if (fracproData && !isEmpty(fracproData.columns) && !isEmpty(fracproData.values)) {
            const channelIndex = fracproData.columns.findIndex(t => t === channel.cName);
            const fracproDataByRealtimeTimeFloat = fracproDataFiltered.find(rowItem => rowItem[1] === realtimeTimeFloat);
            if (fracproDataByRealtimeTimeFloat) {
              const channelValue = fracproDataByRealtimeTimeFloat[channelIndex];
              channelDataRow.push(channelValue);
            } else {
              channelDataRow.push('');
            }
          }
        }

        if (!isEmpty(offsetChannels) && !isEmpty(offsetChartInputData)) {
          const filterInputData = (item, channelItem) => {
            if (item.treatmentId !== channelItem.treatmentId) return false;
            if (item.isSmoothData !== channelItem.isSmoothData) return false;
            if (item.isBourdetDer !== channelItem.isBourdetDer) return false;
            return true;
          };

          offsetChannels.forEach(offsetChannel => {
            const wellTreatmentData = offsetChartInputData.find(item => filterInputData(item, offsetChannel));
            if (wellTreatmentData) {
              const offsetRealtimeData = wellTreatmentData.realtimeData[0];
              let offsetRealtimeDataFiltered = offsetRealtimeData.values;
              if (minTime && maxTime) {
                if (timeFormat === 'hh:mm') {
                  offsetRealtimeDataFiltered = offsetRealtimeDataFiltered.filter(realtimeDataRow => {
                    const yValue = realtimeDataRow[0];
                    return yValue >= minTime && yValue <= maxTime;
                  });
                } else {
                  const minTimeSec = minTime * 60;
                  const maxTimeSec = maxTime * 60;
                  offsetRealtimeDataFiltered = offsetRealtimeDataFiltered.filter(realtimeDataRow => {
                    const yValue = realtimeDataRow[1];
                    return yValue >= minTimeSec && yValue <= maxTimeSec;
                  });
                }
              }
              if (!isEmpty(offsetRealtimeDataFiltered)) {
                const realtimeDataRow = offsetRealtimeDataFiltered[index];
                timeData = Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', new Date(realtimeDataRow[0]).getTime());
                const channelIndex = offsetRealtimeData.columns.findIndex(t => t === offsetChannel.cName);
                const channelValue = realtimeDataRow[channelIndex];
                channelDataRow = channelDataRow.concat([timeData, channelValue]);
              }
            }
          });
        }
      });
      channelData.push(channelDataRow);
    });
    const result = [headersNames, headersUnits, channelData.join('\n')];
    const stringData = result.join('\n');

    return stringData;
  }


  // don't use
  convertOffsetChannelTime(offsetInputChart: IInputDataChart, primaryInputData: IInputDataChart) {
    const timeFLoatIndex = offsetInputChart.columns.findIndex(value => value === TIMEFLOAT_CHANNEL_NAME);
    const dateTimeIndex = offsetInputChart.columns.findIndex(value => value === DATETIME_CHANNEL_NAME);
    // find primary base time
    let basePrimaryTimestamp = 0;
    let curPrimaryTimestamp = 0;
    let baseOffsetTimestamp = 0;
    if (primaryInputData.values[0]) {
      basePrimaryTimestamp = new Date(primaryInputData.values[0][dateTimeIndex]).getTime();
    }
    if (primaryInputData.values[primaryInputData.values.length - 1]) {
      curPrimaryTimestamp = new Date(primaryInputData.values[primaryInputData.values.length - 1][dateTimeIndex]).getTime();
    }
    if (offsetInputChart.values[0]) {
      baseOffsetTimestamp = new Date(offsetInputChart.values[0][dateTimeIndex]).getTime();
    }
    // in case offset channel starts earlier than primary channel, filter data which outside primary timestamp
    if (baseOffsetTimestamp < basePrimaryTimestamp) {
      if (basePrimaryTimestamp < curPrimaryTimestamp) {
        offsetInputChart.values = offsetInputChart.values.filter(dataRow => {
          const timestamp = new Date(dataRow[dateTimeIndex]).getTime();

          return timestamp >= basePrimaryTimestamp && timestamp <= curPrimaryTimestamp;
        });
      }
    }
    // move offset time value in offset channel data to primary channel offset time
    const diffTime = (baseOffsetTimestamp - basePrimaryTimestamp) / 1000; // in seconds
    if (diffTime) {
      offsetInputChart.values.forEach(dataRow => {
        dataRow[timeFLoatIndex] = dataRow[timeFLoatIndex] + diffTime;
      });
    }

    return offsetInputChart;
  }


  // don't use
  getOffsetStringRawData(
    offsetChannelSettings: IChannelSettingItemModel[], offsetChartInputData: IInputDataMultiFlow[],
    minTime: number, maxTime: number, timeFormat: ChartTimeFormat, realtimeData: IInputDataChart
  ) {
    let channelName = '';
    let channelUnit = '';
    for (let i = 0; i < offsetChannelSettings.length; i++) {
      if (i === offsetChannelSettings.length - 1) {
        channelName += offsetChannelSettings[i].name;
        channelUnit += offsetChannelSettings[i].unit;
      } else {
        channelName += offsetChannelSettings[i].name + ', ';
        channelUnit += offsetChannelSettings[i].unit + ', ';
      }
    }

    let textContent = 'Date, Time, ' + channelName + '\n';
    textContent += 'MM/dd/yyyy, hh:mm:ss, ' + channelUnit + '\n';
    forEach(realtimeData.values, value => {
      textContent += new Date(value[0]).toLocaleDateString() + ', ' + new Date(value[0]).toLocaleTimeString() + ', ';
      for (let j = 0; j < offsetChannelSettings.length; j++) {
        const offsetRealtimeData = offsetChartInputData
          .filter(
            t => t.treatmentId === offsetChannelSettings[j].treatmentId && t.wellId === offsetChannelSettings[j].wellId && t.cName === offsetChannelSettings[j].cName
          );

        // let temp = this.convertOffsetChannelTime(offsetRealtimeData[0].realtimeData[0], realtimeData);
        const textValue = offsetRealtimeData[0].realtimeData[0].values
          .filter(dataRow => {
            const yValue = dataRow[1];
            if (yValue === value[1]) {
              return dataRow;
            }
          });
        if (textValue.length > 0) {
          if (j === offsetChannelSettings.length - 1) {
            textContent += textValue[0][2].toString();
          } else {
            textContent += textValue[0][2].toString() + ', ';
          }
        } else {
          if (j === offsetChannelSettings.length - 1) {
            textContent += '';
          } else {
            textContent += ' , ';
          }
        }
      }
      textContent += '\n';
    });
    return textContent;
  }

  validTimeOffsetByTimeFormat({ xValue, timeFormat, baseTreatmentDataTime, wellTimeZoneOffset }) {
    let timeOffset;
    if (xValue < 0) {
      xValue = 10;
    }
    if (timeFormat === 'hh:mm') {
      timeOffset = xValue - (new Date(baseTreatmentDataTime).getTime() + wellTimeZoneOffset * 60 * 1000); // milliseconds
      timeOffset = timeOffset / 1000; // in seconds
    } else {
      if (xValue > 60000) {
        xValue = 200;
      }
      timeOffset = xValue * 60; // in seconds
    }
    return timeOffset;
  }

  getLastTimeFromDataPoint(inputData: IInputDataChart, wellTimeZoneOffset?: number, predictChannelsMap?: string[], getTimeEllapsed?: boolean) {
    let baseTreatmentDataTime;
    if (inputData && !isEmpty(inputData.values)) {
      const filterIndex: number[] = [];
      if (!isEmpty(inputData.columns)) {
        for (let i = 0; i < inputData.columns.length; i++) {
          if (inputData.columns[i] === 'time') continue;
          if (inputData.columns[i].includes('C0')) continue;
          if (!isEmpty(predictChannelsMap) && predictChannelsMap.includes(inputData.columns[i])) continue;
          filterIndex.push(i);
        }
      }

      const getLastDataValue = (data: any[]) => {
        const n = data.length;
        for (let i = n - 1; i >= 0; i--) {
          for (const index of filterIndex) {
            if (data[i][index] === null) continue;
            return data[i];
          }
        }
      };

      // const currentValues = (data) => {
      //   if (!data) return [];
      //   if (!predictChannelsMap || isEmpty(predictChannelsMap)) return data.values;
      //   const result = data.values.filter(val => {
      //     let flag = 0;
      //     for (let index = 1; index < data.columns.length; index++) {
      //       const isPredict = predictChannelsMap.includes(data.columns[index]);
      //       if (!isPredict && val[index] !== null && val[index] !== undefined) {
      //         flag++;
      //         break;
      //       }
      //     }
      //     return flag > 0;
      //   });

      //   return result;
      // }

      const lastDataPoint = getLastDataValue(inputData.values);

      if (getTimeEllapsed) {
        if (!lastDataPoint) return 0;
        const index = inputData.columns.indexOf('C0');
        if (index < 0) return 0;
        return lastDataPoint[index];
      }

      if (lastDataPoint) {
        if (!wellTimeZoneOffset) {
          baseTreatmentDataTime = lastDataPoint[0];
        } else {
          const baseEpochValueForNewData = new Date(lastDataPoint[0]).getTime() - (wellTimeZoneOffset * 60 * 1000); // have to revert timezone offset after changed
          baseTreatmentDataTime = new Date(baseEpochValueForNewData).toISOString();
        }
      }
    }
    return baseTreatmentDataTime;
  }

}

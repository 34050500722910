import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { countBy } from 'lodash';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-table-wells-selection',
  templateUrl: './table-wells-selection.component.html',
  styleUrls: ['./table-wells-selection.component.scss']
})
export class TableWellsSelectionComponent implements OnInit, OnChanges {

  @Input() listWells: any[] = [];
  @Input() selectedWell: any[] = [];
  @Input() maxWells: number;
  @Input() countItemWells: number;
  @Input() isService: boolean = false;
  @Input() isOperator: boolean = false;
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  tableState = {
    selectedAll: false
  };
  constructor(private toasterService: ToasterService) { }

  ngOnInit() {
    if (this.tableState.selectedAll) {
      this.setSelectedWells(this.tableState.selectedAll);
      this.selectedChange.emit({ selected: [...this.listWells] });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listWells && !changes.listWells.isFirstChange()) {
      this.listWells = changes.listWells.currentValue;
      // reset selected all state
      this.setSelectAllState();
    }
  }

  toggleSelectWell(event, well) {
    event.preventDefault();
    if (well) {
      well.selected = !well.selected;
      this.setSelectAllState();

      this.selectedChange.emit({ selected: [...this.listWells] });
    }
  }

  toggleClickWell(event, check: boolean) {
    if (!check) {
      const countSelected = countBy(this.listWells, 'selected').true || 0;
      if (this.maxWells <= (this.countItemWells + countSelected)) {
        event.preventDefault();
        event.stopPropagation();
        this.toasterService.showError('Max Well have to be greater than the number of Well records');
      }
    }
  }

  setSelectAllState() {
    const countSelected = countBy(this.listWells, 'selected').true || 0;
    if (countSelected !== this.listWells.length) {
      this.tableState.selectedAll = false;
    } else {
      this.tableState.selectedAll = true;
    }
  }

  toggleSelectAll(event) {
    event.preventDefault();
    this.tableState.selectedAll = !this.tableState.selectedAll;
    this.setSelectedWells(this.tableState.selectedAll);
    this.selectedChange.emit({ selected: [...this.listWells] });
  }

  toggleClickAll(event , check: boolean) {
    if (!check) {
      const count = this.listWells.length;
      if (this.maxWells <= (this.countItemWells + count)) {
        event.preventDefault();
        event.stopPropagation();
        this.toasterService.showError('Max Well have to be greater than the number of Well records');
      }
    }
  }

  setSelectedWells(value) {
    if (this.listWells && this.listWells.length > 0) {
      this.listWells.forEach(well => {
        well.selected = value;
      });
    }
  }

  removeAllSelected() {
    this.tableState.selectedAll = false;
    this.setSelectedWells(false);
  }

}

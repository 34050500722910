import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { isEmpty } from 'lodash';
import { Options } from 'ng5-slider';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Helper } from '../../../helpers/helper';
import { OffsetWellCompare } from '../../../models/offset-well-compare.model';
import { IWellItemPaging } from '../../../models/well.model';
import { TreatmentService } from '../../../services/treatment.service';

@Component({
  selector: 'app-bourdet-derivative',
  templateUrl: './bourdet-derivative.component.html',
  styleUrls: ['./bourdet-derivative.component.scss']
})
export class BourdetDerivativeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  form: FormGroup;
  isRealtime: AbstractControl;
  isProducingWell: AbstractControl;
  selectedWell: AbstractControl;
  selectedTreatment: AbstractControl;
  selectedChannel: AbstractControl;
  selectedProducingWell: AbstractControl;

  listTreatments: any[] = [];
  realtimeChannels: any[] = [];
  startTime: Date;
  endTime: Date;
  startTimeStr: any = '';
  endTimeStr: any = '';
  compStartTime: AbstractControl;
  compEndTime: AbstractControl;

  smoothRange: AbstractControl;
  optionsSmoothRange: Options = {
    floor: 30,
    ceil: 300,
    showSelectionBar: true,
    selectionBarGradient: {
      from: 'white',
      to: '#0DB9F0'
    }
  };

  bourdetDerRange: AbstractControl;
  optionsBourdetDerRange: Options = {
    floor: 30,
    ceil: 600,
    showSelectionBar: true,
    selectionBarGradient: {
      from: 'white',
      to: '#0DB9F0'
    }
  };

  listProducingWell: any = [
    {
      id: 1,
      name: 'Parent Well',
      value: true
    },
    {
      id: 2,
      name: 'Child Well',
      value: false
    }
  ];

  offsetTimeZone: number = new Date().getTimezoneOffset() * 60 * 1000; // in milliseconds
  isEnableSmoothData = true;
  isShowBourdetDer = true;
  isEnabledFeature = true;

  @Input() modelSetting: OffsetWellCompare;
  @Input() curWellId: number;
  @Input() curTreatmentId: number;
  @Input() listWells: IWellItemPaging[] = [];

  constructor(
    private treatmentService: TreatmentService,
    protected dialogRef: NbDialogRef<any>
  ) {
    this.createForm();
  }

  ngOnInit() {
    if (this.modelSetting) {
      const selectedWell = this.listWells.find(item => item.wellId === this.modelSetting.wellId);
      if (selectedWell) {
        this.selectedWell.setValue(selectedWell);
        this.onChangeWell(selectedWell);
      }
    } else {
      if (!isEmpty(this.listWells)) {
        // default select current well
        if (this.curWellId) {
          const selectedWell = this.listWells.find(item => item.wellId === this.curWellId * 1);
          if (selectedWell) {
            this.selectedWell.setValue(selectedWell);
            this.onChangeWell(selectedWell);
          }
        } else { // default set first item
          this.selectedWell.setValue(this.listWells[0]);
          this.onChangeWell(this.listWells[0]);
        }
      }
    }

    if (!isEmpty(this.selectedProducingWell)) {
      this.selectedProducingWell.setValue(this.listProducingWell[0]);
    }
  }

  private onChangeWell(selectedWell) {
    this.selectedWell.setValue(selectedWell);
    this.treatmentService.getListTreatment(selectedWell.wellId).subscribe(res => {
      this.listTreatments = res.result;
      const selectedTreatment = !this.modelSetting || !this.modelSetting.treatmentId ?
        this.listTreatments[0] :
        this.listTreatments.find(item => item.id === this.modelSetting.treatmentId);
      if (selectedTreatment) {
        this.selectedTreatment.setValue(selectedTreatment);
        this.onChangeTreatment();
      }
    });
  }

  private onChangeTreatment() {
    if (!this.selectedWell.value.wellId || !this.selectedTreatment.value.id) return;
    this.getTreatmentTimeRange(this.selectedWell.value.wellId, this.selectedTreatment.value.id);
    this.getChannels(this.selectedWell.value.wellId, this.selectedTreatment.value.id).subscribe(res => {
      this.setRealtimeChannels(res);
      this.selectedChannel.setValue('');
    });
  }

  private setRealtimeChannels(list: any[]): void {
    this.realtimeChannels = list.filter(item => {
      // cName format: C + <number>
      if (!item || !item.cName || !item.cName.length) return false;
      const temp = item.cName.slice(1);
      return Number.isInteger(parseInt(temp)) && parseInt(temp).toString() === temp;
    });
  }

  close() {
    this.dialogRef.close();
  }

  getTreatmentTimeRange(wellId, treatmentId) {
    if (wellId && treatmentId) {
      this.treatmentService.getTreatmentDetailWebApi(wellId, treatmentId).toPromise().then(res => {
        if (res && res.result) {
          this.startTimeStr = formatDate(res.result.timeStart * 1000, 'M/d/yyyy, h:mm aaa', 'en-US', 'UTC');
          this.endTimeStr = formatDate(res.result.timeEnd * 1000, 'M/d/yyyy, h:mm aaa', 'en-US', 'UTC');
          this.startTime = Helper.convertDateToUTC(new Date(res.result.timeStart * 1000));
          this.endTime = Helper.convertDateToUTC(new Date(res.result.timeEnd * 1000));
          // set time range model
          this.compStartTime.setValue(Helper.convertDateToUTC(new Date(res.result.timeStart * 1000)));
          if (res.result.timeEnd >= res.result.timeStart) {
            this.compEndTime.setValue(Helper.convertDateToUTC(new Date(res.result.timeEnd * 1000)));
          } else {
            this.compEndTime.setValue(Helper.convertDateToUTC(new Date(res.result.timeStart * 1000)));
          }
        }
      });
    }
  }

  getChannels(wellId, treatmentId) {
    return this.treatmentService.getRealtimeChannels(wellId, treatmentId).pipe(map(response => {
      return Helper.getList(response, 'result.items');
    }));
  }

  onSelectProducingWell(eventData) {
    if (eventData && eventData.id) {
      const selectedProducing = this.listProducingWell.find(item => item.id === eventData.id);
      if (selectedProducing) {
        this.selectedProducingWell.setValue(selectedProducing);
      }
    }
  }

  onSelectWell(eventData) {
    if (!eventData || !eventData.id) return;
    const selectedWell = this.listWells.find(item => item.wellId === eventData.id);
    if (!selectedWell) return;
    this.onChangeWell(selectedWell);
  }

  onSelectTreatment(eventData) {
    const treatment = this.listTreatments.find(item => item.id === eventData.id);
    if (treatment) {
      this.selectedTreatment.setValue(treatment);
    }
    if (this.selectedWell.value.wellId && treatment.id) {
      // get list channels
      this.getTreatmentTimeRange(this.selectedWell.value.wellId, treatment.id);
      const subRes = this.getChannels(this.selectedWell.value.wellId, treatment.id).subscribe(res => {
        this.setRealtimeChannels(res);
        this.selectedChannel.setValue('');
      });
      this.subscriptions.push(subRes);
    }
  }

  selectChannel(eventData: { id: string; text: string }) {
    if (eventData && eventData.text) {
      const channel = this.realtimeChannels.find(item => item.name === eventData.text);
      if (channel) {
        this.selectedChannel.setValue([channel]);
      }
    }
  }

  createForm() {
    this.form = new FormGroup({
      selectedWell: new FormControl(null, [Validators.required]),
      selectedTreatment: new FormControl(null, [Validators.required]),
      compStartTime: new FormControl('', [Validators.required]),
      compEndTime: new FormControl('', [Validators.required]),
      axisType: new FormControl('Left', [Validators.required]),
      selectedChannel: new FormControl([], [Validators.required]),
      isRealtime: new FormControl(true, [Validators.required]),
      selectedProducingWell: new FormControl(null, [Validators.required]),
      select: new FormControl(false, [Validators.required]),
      smoothRange: new FormControl(60),
      bourdetDerRange: new FormControl(300)
    });

    this.selectedWell = this.form.controls['selectedWell'];
    this.selectedTreatment = this.form.controls['selectedTreatment'];
    this.compStartTime = this.form.controls['compStartTime'];
    this.compEndTime = this.form.controls['compEndTime'];
    this.selectedChannel = this.form.controls['selectedChannel'];
    this.isRealtime = this.form.controls['isRealtime'];
    this.selectedProducingWell = this.form.controls['selectedProducingWell'];
    this.smoothRange = this.form.controls['smoothRange'];
    this.bourdetDerRange = this.form.controls['bourdetDerRange'];
  }

  submitForm() {
    if (this.form.valid) {
      const startTime = new Date(this.compStartTime.value).getTime() - this.offsetTimeZone;
      const endTime = new Date(this.compEndTime.value).getTime() - this.offsetTimeZone;
      const bourdetDerivative = new OffsetWellCompare(
        this.selectedWell.value.wellId,
        this.selectedWell.value.wellName,
        this.selectedTreatment.value.id,
        startTime,
        endTime,
        'Leftmost',
        this.selectedChannel.value,
        this.isRealtime.value,
        this.selectedTreatment.value.treatmentNumber,
        this.selectedTreatment.value.dataType,
        this.selectedProducingWell.value.value,
        this.selectedTreatment.value.treatmentName,
        this.isEnableSmoothData,
        this.smoothRange.value,
        this.isShowBourdetDer,
        this.bourdetDerRange.value
      );

      this.dialogRef.close(bourdetDerivative);
    }
  }

  smoothChanged() {
    this.isEnableSmoothData = !this.isEnableSmoothData;
    if (this.isEnableSmoothData === false) {
      this.smoothRange = new FormControl(60);
    }
  }

  changedShowBourdetDer() {
    this.isShowBourdetDer = !this.isShowBourdetDer;
    if (this.isShowBourdetDer === false) {
      this.bourdetDerRange = new FormControl(300);
    }
  }

  ngOnDestroy() {
    Helper.unsubscribe(this.subscriptions);
  }

}

import { IOffsetWellCompare } from "./offset-well-compare.model";

export interface IReatlimeChannelSettingModel {
  wellId: number;
  treatmentId: number;
  flowPathType: number;
  cNames: string[];
  lastDataTime: string;
}

export interface IChannelSettingYAxisModel {
  min: number;
  max: number;
  channels: IChannelSettingItemModel[];
  opposite?: boolean;
}

export interface IChannelSettingModel {
  autoScaleYAxis?: boolean;
  firstLeft: IChannelSettingYAxisModel;
  secondLeft: IChannelSettingYAxisModel;
  firstRight: IChannelSettingYAxisModel;
  secondRight: IChannelSettingYAxisModel;
  timezoneOffset?: number;
  offsetWellSettings?: IOffsetWellCompare[];
}

export interface IChannelSettingItemModel {
  channelName: string;
  name: string;
  channel: IChannelItem;
  cName: string;
  unit?: string;
  color?: string;
  colorMode?: string;
  min?: number;
  max?: number;
  opposite?: boolean;
  channelValue?: number;
  // offset channel properties
  isOffset?: boolean;
  isRealtime?: boolean;
  wellId?: number;
  treatmentId?: number;
  firstData?: number;
  lastData?: number;
  originName?: string;
  originalName?: string;
  isSmoothData?: boolean;
  smoothRange?: number;
  isBourdetDer?: boolean;
  bourdetDerRange?: number;
  isCompare?: boolean;
  digits?: string;
  digitsFormat?: string;
  measureType?: string;
  channelMappedGroup?: any[];
}

export interface IChannelItem {
  cName: string;
  name: string;
  unit: string;
  cnameObjects?: ICNameObject[];
  color?: string;
  colorMode?: string;
  min?: number;
  max?: number;
  isOffset?: boolean;
  isRealtime?: boolean;
  wellId?: number;
  treatmentId?: number;
  originName?: string;
  originalName?: string;
  isSmoothData?: boolean;
  smoothRange?: number;
  isBourdetDer?: boolean;
  bourdetDerRange?: number;
  isCompare?: boolean;
  digits?: string;
  measureType?: string;
  channelMappedGroup?: any[];
}

export interface IEditChannelMappedDto {
  channelMappedName: string,
  color: string,
  companyId: number
}

export interface IRenameChannel { originalName: string, mappedName: string }

export interface IChannelMappingResponse {
  id: number;
  name: string;
  color?: string;
  isEdit?: boolean;
  isApplyToAllWell?: boolean;
  mappedChannel: string[];
}

export const isChannelSettingModel = (object: any) => {
  return object && object.firstLeft && object.secondLeft && object.firstRight && object.secondRight;
};

interface ICNameObject {
  cName: string;
  wellId: number;
  treatmentId: number;
}

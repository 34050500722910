import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {ApiService} from '../../../core/services/api.service';

@Injectable()
export class HistoricalWellsService {
  prefixPath = '/companies/historicalWells';

  constructor(private apiService: ApiService) {

  }
  updateByCompany(param: any, finalize?: VoidFunction) {
    return this.apiService.put(`${this.prefixPath}/updateVisible`, param, finalize);
  }
  delete(id: number, finalize?: VoidFunction): Observable<any> {
    return this.apiService.delete(`${this.prefixPath}/${id}`, finalize);
  }
  getAll(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}`);
  }
  getCountries(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/getCountries`);
  }
  getStates(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/getStates`);
  }
  getCounties(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/getCounties`);
  }
  getPads(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/getPadNames`);
  }
  getFormations(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/getFormations`);
  }
}

import { Injectable } from '@angular/core';
import { TREATMENT_MONITOR_NUMBER } from '../../shared/helpers/app.constants';

export interface IPadRealtimeInputData {
  padInfo: any;
  listWells: any[];
  wellInProgress: any;
  treatmentInProgress: any;
  currentTreatmentId: number;
}

export interface IPadDetailWell {
  wellId: number;
  tzTimeZone: string;
  stTimeZone: string;
  wellUid: string;
  wellName: string;
  formation: string;
  formationName: string;
  operator: string;
  operatorName: string;
  serviceName: string;
  serviceCompany: string;
  lastTreatmentId: number;
  lastTreatmentInProgress: boolean;
  completionDate: string | null;
  completionDateDisplay: string | null;
  padName: string;
  treatmentList: IPadDetailTreatment[];
  lastTreatment;
  lastTreatmentComplete;
}

export interface IPadDetailTreatment {
  id: number;
  wellId: number;
  wellName: string;
  treatmentName: string;
  treatmentNumber: number;
  inProgress: boolean;
  treatmentComplete: boolean;
  timeEnd: number;
  timeStart: number;
  timeZone: number;
  tzTimeZone: string;
  stTimeZone: string;
  dayLight: number;
}


@Injectable()
export class PadRealtimeDataService {
  inputData: IPadRealtimeInputData;
  parameterList: any[];
  excludeTreatmentIds: number[];


  constructor() { }

  setInputData(input: IPadRealtimeInputData): void {
    this.inputData = {...input};
  }

  getInputData() {
    return this.inputData;
  }

  resetInputData() {
    this.inputData = null;
  }

  resetData() {
    this.inputData = null;
    this.parameterList = [];
    this.excludeTreatmentIds = [];
  }

  setParameterList(data) {
    this.parameterList = data;
  }

  getParameterList() {
    return this.parameterList;
  }

  hasCurrentInProgress(listWells: any[], currentTreatment): boolean {
    if (!currentTreatment) return false;
    for (const well of listWells) {
      for (const treatment of well.treatmentList) {
        if (treatment.id === currentTreatment.id && treatment.inProgress) return true;
      }
    }
    return false;
  }

  getLatestInProgress(listWells: any[], padInfo, currentTreatmentId?: number): IPadRealtimeInputData | null {
    let treatmentInProgress = null;
    let wellInProgress = null;

    for (const well of listWells) {
      for (const treatment of well.treatmentList) {
        if (!treatment.inProgress || treatment.treatmentNumber === TREATMENT_MONITOR_NUMBER) continue;
        if (currentTreatmentId && treatment.id === currentTreatmentId) continue;
        if (!treatmentInProgress || treatment.timeStart > treatmentInProgress.timeStart) {
          treatmentInProgress = treatment;
          wellInProgress = well;
        }
      }
    }

    if (!treatmentInProgress || !wellInProgress) {
      return { listWells, padInfo, wellInProgress: null, treatmentInProgress: null, currentTreatmentId: 0 }
    }

    return { listWells, padInfo, wellInProgress, treatmentInProgress, currentTreatmentId: treatmentInProgress.id }
  }

}

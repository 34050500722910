import { Component, OnInit } from '@angular/core';
import { VersionBuildCheckService } from './shared/services';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <!--<ngx-loading [show]="isLoadingPage" [config]="{ fullScreenBackdrop: true }"></ngx-loading>-->
  `
})
export class AppComponent implements OnInit {
  title = 'ng-dashboard';
  // isLoadingPage = false;

  constructor(
    private versionBuildCheckService: VersionBuildCheckService
  ) { }

  ngOnInit(): void {
    if (environment.production) {
      this.versionBuildCheckService.initVersionCheck('../version.json', () => {
        console.log('New version updated');
        // reload the page
        location.reload(true);
      });
    }
  }

}

import { formatNumber } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { isNaN } from 'lodash';

@Component({
  template: `
    <span>{{ value }}</span>
  `,
})
export class CustomNumberDecimal3Component implements OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  constructor() { }

  ngOnInit() {
    if (this.value && this.value) {
      if (!this.value) {
        this.renderValue = '0';
      } else if (typeof this.value === 'string' && isNaN(Number(this.value))) {
        this.renderValue = this.value;
      } else {
        this.renderValue = formatNumber(Number(this.value), 'en-US', `1.3-3`);
      }
    }
  }
}

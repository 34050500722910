import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

interface IModalConfirmResponse {
  confirm: boolean;
  data: any;
}

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  @Output() confirm: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() openChange: EventEmitter<any> = new EventEmitter();
  title: string = 'Delete';
  message: string = 'Are you sure you want to delete?';
  className: string = 'pop-delete';
  btnTxtConfirm: string = 'Yes';
  btnTxtCancel: string = 'No';
  btnTxtClose: string = null;
  image: string = 'assets/img/img-delete.png';
  noAction: boolean = false;
  showNameInput: boolean = false;
  nameData: string = '';

  data: any = null;

  @Input()
  set config(config: any) {
    // Config Dialog
    this.noAction = false;
    if (config.noAction) {
      this.noAction = true;
    }
    this.title = '';
    if (config.title) {
      this.title = config.title;
    }
    this.message = '';
    if (config.message) {
      this.message = config.message;
    }
    this.className = '';
    if (config.className) {
      this.className = config.className;
    }
    this.btnTxtConfirm = 'Yes';
    if (config.btnTxtConfirm) {
      this.btnTxtConfirm = config.btnTxtConfirm;
    }
    this.btnTxtCancel = 'No';
    if (config.btnTxtCancel) {
      this.btnTxtCancel = config.btnTxtCancel;
    }
    this.image = 'assets/img/img-delete.png';
    if (config.image || config.image === '') {
      this.image = config.image;
    }
    if (config.showNameInput) {
      this.showNameInput = !!config.showNameInput;
    }
    if (config.btnTxtClose) {
      this.btnTxtClose = config.btnTxtClose;
    }

    // Data return
    this.data = null;
    if (config.data) {
      this.data = config.data;
    } else {
      this.data = null;
    }
  }


  constructor(private dialogRef: NbDialogRef<any>) { }

  ngOnInit() {
  }

  hide(response: IModalConfirmResponse): void {
    if (this.showNameInput) {
      response.data = response.data || {};
      response.data.name = this.nameData;
    }
    this.dialogRef.close(response);
    this.openChange.emit(false);
  }

  onConfirm() {
    this.confirm.emit(this.data);
    this.hide({ confirm: true, data: this.data });
  }

  onCancel() {
    this.cancel.emit(this.data);
    this.hide({ confirm: false, data: this.data });
  }

  close() {
    this.dialogRef.close();
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {
  @Input() selected = '';
  @Input() companies = [];
  @Output() changed = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onChange(id) {
    let result = null;
    if (id) {
      result = this.companies.find(item => item.id * 1 === id * 1);
    }
    this.changed.emit(result);
  }

}

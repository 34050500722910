import { SearchWellListBase } from "./search-well-list-base";


export class SearchWellListTextBox extends SearchWellListBase<string> {
  controlType = 'textbox';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}

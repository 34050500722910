import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, OnInit } from '@angular/core';
import { SelectItem } from './select-item';
import { compact, orderBy, cloneDeep, isEmpty, remove } from 'lodash';
import { ToasterService } from '../../services/toaster.service';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-items',
  templateUrl: './select-items.component.html',
  styleUrls: ['./select-items.component.scss']
})
export class SelectItemsComponent implements OnInit, OnDestroy {
  destroy$: Subject<any> = new Subject();
  inputMode: boolean = false;
  optionsOpened: boolean = false;

  public options: Array<SelectItem> = [];
  public itemObjects: Array<SelectItem> = [];
  private _disabled: boolean = false;
  public activeOption: SelectItem[] = [];

  public itemEmpty: SelectItem = new SelectItem('');

  searchVal: string = '';
  maxValue: number = 50;

  @ViewChild('searchInput') searchElement: ElementRef;

  @Input() public maxShowedItem: boolean = false;
  @Input() public idField: string = 'id';
  @Input() public textField: string = 'text';
  @Input() public allowEmpty: boolean = true;
  @Input() public searchField: boolean = true;
  @Input() public placeholder: string = '';
  @Input() public multiple: boolean = false;
  @Input() public maxItems: number;
  @Input() public isFullWidth: boolean = false;
  @Input() public sortSelect: boolean = true;
  @Input()
  set item(value: Array<any>) {
    if (!value || isEmpty(value)) {
      this.itemObjects = [];
      this.activeOption = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.itemObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
      })));
      if (this.sortSelect) {
        this.itemObjects = orderBy(this.itemObjects, item => item.text.toLowerCase());
      }
      if (isEmpty(this.itemObjects)) {
        this.activeOption = [];
      }
    }
  }

  @Input()
  public set active(selectedItems: Array<any>) {
    // if (isEmpty(this.activeOption) && !isEmpty(selectedItems)) {
      selectedItems = compact(selectedItems);
      const areItemsStrings = typeof selectedItems[0] === 'string';
      this.activeOption = selectedItems.map((item: any) => {
        const data = areItemsStrings ? item : { id: item[this.idField], text: item[this.textField], prefix: item.prefix };
        return new SelectItem(data);
      });
    // }
  }

  public get active(): Array<any> {
    return this.activeOption;
  }

  @Input()
  public set fakeOpen(open: boolean) {
    if (!!open) {
      this.open();
    } else {
      this.clickOutside();
    }
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
    this.ref.detectChanges();
    if (this._disabled === true) {
      this.clickOutside();
    }
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  fcActive: FormControl = new FormControl([]);

  @Output() public selected: EventEmitter<any> = new EventEmitter();
  @Output() public selectedAll: EventEmitter<any> = new EventEmitter();
  @Output() public removed: EventEmitter<any> = new EventEmitter();
  @Output() public removedAll: EventEmitter<any> = new EventEmitter();
  @Output() public addNew: EventEmitter<any> = new EventEmitter();
  @Output() public resetChannelEmit: EventEmitter<any> = new EventEmitter();
  constructor(private toasterService: ToasterService,
    private element: ElementRef,
    private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.fcActive.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      // unset active item
      console.log(value);
      if (!value) {
        this.activeOption = [];
      }
    });
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    this.ref.detach();
  }

  isActive(value: SelectItem): boolean {
    if (!value || !this.activeOption) {
      return false;
    }
    const item = this.activeOption.find(i => i.id === value.id);
    return !!item;
  }

  matchClick(e: any) {
    if (this._disabled === true) {
      return;
    }
    this.inputMode = !this.inputMode;
    if (!!this.inputMode) {
      this.open();
    } else {
      this.clickOutside();
    }
  }

  open() {
    this.options = this.itemObjects.filter(item => item);

    this.optionsOpened = true;
    this.ref.detectChanges();
    if (!this.multiple) {
      const itemActive = this.element.nativeElement.querySelector('.ul-select-choice .ul-select-choice-row.active .dropdown-item');
      if (itemActive) {
        itemActive.focus();
      }
    }

    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    });
  }

  public clickOutside(): void {
    this.inputMode = false;
    this.optionsOpened = false;
    this.maxValue = 50;
    this.ref.detectChanges();
  }

  public inputEvent(e: any) {
    this.searchVal = e.target.value.trim();
    if (this.searchVal) {
      this.options = this.itemObjects.filter(item => item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1);
    } else {
      this.options = this.itemObjects.filter(item => item);
    }
  }

  selectMatch(value, e: Event = void 0): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.multiple) {
      const isSelectedItem = this.activeOption.find(item => item.id === value.id);
      if (isSelectedItem) {
        remove(this.activeOption, (item => item.id === value.id));
      } else {
        if (this.maxItems) {
          if (this.activeOption.length < this.maxItems) {
            this.activeOption.push(value);
          } else {
            this.toasterService.showError(`The Items must be at less than or equal to ${this.maxItems}`);
          }
        } else {
          this.activeOption.push(value);
        }
      }
      // emit selected items
      this.selected.emit(this.activeOption);
    } else {
      this.activeOption = [value];
      this.selected.emit(value);
    }
  }

}

import { Injectable } from '@angular/core';

@Injectable()
export class SearchPadPadOptionsStorageService {
  constructor() { }

  setListOptions(data: any, userId: number) {
    if (userId) data['userId'] = userId;
    const value = data ? JSON.stringify(data) : undefined;
    localStorage.setItem(`search_pad_list_opt`, value);
  }

  getListOptions(userId: number) {
    try {
      const stringData = localStorage.getItem(`search_pad_list_opt`);
      const data = JSON.parse(stringData);
      if (userId) {
        if (data['userId'] === userId) return data;
        else null;
      } else return data;
    } catch (error) {
      return null;
    }
  }

  removeListOptions() {
    localStorage.removeItem(`search_pad_list_opt`);
  }

  clearAll() {
    localStorage.clear();
  }
}

import {AfterViewInit, Directive, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core';

@Directive({
  selector: '[ellipsisLines]'
})

export class EllipsisLinesDirective implements AfterViewInit {
  textDefault: any = '';
  @ViewChild(ElementRef) wrapper: ElementRef;
  constructor(private el: ElementRef,
              private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    const container = this.el.nativeElement;
    this.textDefault = container.innerHTML;
    const wordArray = container.innerHTML.split(' ');
    this.processContent(wordArray);
  }

  processContent(wordArray) {
    const container = this.el.nativeElement;
    if (wordArray.length === 1) {
      let wordLength = wordArray[0].length;
      while (container.scrollHeight > container.offsetHeight) {
        wordLength = wordLength - 5;
        container.innerHTML = wordArray[0].slice(0, wordLength) + '...';
        const height = `${container.offsetHeight}px`;
        this.renderer.setStyle(container, 'height', height);
      }
    } else {
      while (container.scrollHeight > container.offsetHeight) {
        wordArray.pop();
        container.innerHTML = wordArray.join(' ') + '...';
        const height = `${container.offsetHeight}px`;
        this.renderer.setStyle(container, 'height', height);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const container = this.el.nativeElement;
    container.innerHTML = this.textDefault;
    const wordArray = this.textDefault.split(' ');
    this.processContent(wordArray);
  }
}
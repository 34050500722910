import { Injectable } from '@angular/core';
import { IPlotTemplate } from '../models/plot-template.model';
import { IChannelSettingModel } from '../models';
import { ApiService } from '../../core/services';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { YAxisMap } from '../helpers/app.constants';
import { Observable } from 'rxjs';

@Injectable()
export class PlotTemplateService {
  prefixPath = `/wells`;

  constructor(
    private apiService: ApiService
  ) { }

  channelSettingsToPlotTemplate(channelSettings: IChannelSettingModel): IPlotTemplate {
    if (channelSettings) {
      const plotTemplate: IPlotTemplate = {};
      const yAxisMap = {
        yAxisLeft: YAxisMap.firstLeft,
        yAxisLeftMost: YAxisMap.secondLeft,
        yAxisRight: YAxisMap.firstRight,
        yAxisRightMost: YAxisMap.secondRight,
      };

      _.each(yAxisMap, (value, key) => {
        const yAxisChannelSettingKey = yAxisMap[key];

        plotTemplate[key] = {
          min: channelSettings[yAxisChannelSettingKey].min,
          max: channelSettings[yAxisChannelSettingKey].max,
          channels: channelSettings[yAxisChannelSettingKey].channels.map(item => {
            return {
              name: item.name,
              color: item.color
            };
          })
        };
      });

      return plotTemplate;
    }
    return;
  }

  getByWell(wellId): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/${wellId}/plotTemplates`);
  }

  createByWell(wellId, body) {
    let param = new HttpParams();
    param = param.set('wellId', wellId);

    return this.apiService.post(`${this.prefixPath}/${wellId}/plotTemplates`, body, null, null, param);
  }

  updateByWell(wellId, plotTemplateId , body) {
    let param = new HttpParams();
    param = param.set('wellId', wellId);
    param = param.set('plotTemplateId', plotTemplateId);

    return this.apiService.put(`${this.prefixPath}/${wellId}/plotTemplates/${plotTemplateId}`, body, null, null, param);
  }

  deleteByWell(wellId, id) {
    return this.apiService.delete(`${this.prefixPath}/${wellId}/plotTemplates/${id}`);
  }
}

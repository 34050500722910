import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { WellsCompareDataService, IWellsCompareInputData } from './wells-compare-data.service';
import { of } from 'rxjs';


@Injectable()
export class WellsCompareResolver implements Resolve<IWellsCompareInputData> {
  constructor(
    private router: Router,
    private wellsCompareDataService: WellsCompareDataService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const inputData = this.wellsCompareDataService.getInputData();
    if (inputData) {
      return of(inputData);
    } else {
      this.router.navigate(['pages/wells']);
      return of(null);
    }
  }
}

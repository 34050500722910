import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, from  } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { ToasterService, UserAuthService } from '../../shared/services';
import { CookieStorageService } from '../services';
import { CookieService } from 'ngx-cookie-service';
import { InteractionRequiredAuthError } from 'msal';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import Swal from 'sweetalert2';

@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {
  private accessTokenKey = 'jwtToken';
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private toasterService: ToasterService,
    private userAuthService: UserAuthService,
    private cookieStorageService: CookieStorageService,
    private cookieService: CookieService,
    private authService: MsalService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentToken = this.cookieStorageService.getAccessToken();
      if (currentToken) {
        request = this.addToken(request, currentToken);
      }
    
    return next.handle(request).pipe(
      tap(evt => {
      }),
      catchError((err: any) => {
        try {
          if (err instanceof HttpErrorResponse && err.error) {
            // check to see if there's internet
            if (!window.navigator.onLine) {
              this.toasterService.showError('No internet connection');
            } else {
              if (err.status !== 401 && err.status !== 403  && err.status > 0) {
                this.toasterService.handleError(err.error);
              }
            }
 
            if (err.status === 401) {
              setTimeout(() => {
                this.userAuthService.logout();
              }, 500);
                // this.toasterService.showError('Token has expired. Trying to refresh token.'); 
                // this.handle401Error(request, next);   
            }
            else if(err.status === 403){
              setTimeout(()=>{
                Swal.fire({
                  text: "You do not have a role assigned right now. Kindly contact your organization's administrator.",
                  icon: 'info',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  confirmButtonText: 'Ok',
                  cancelButtonColor: 'rgb(48,133,214)',
                  reverseButtons: true
                }).then((result) =>{
                  console.log("resss===>", result);
                  if(result.value === true){
                    this.userAuthService.logout();
                  }
                })
                // this.toasterService.handleError(err.error);
              },300)
            }
          }
          return throwError(err);
        } catch (error) {
          return throwError(err);
        }
      }));
  }
   handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
         console.log("sss==>>")
     this.userAuthService.refreshToken().then(response=>{
          console.log("res===>", response)
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.accessToken);
          return next.handle(this.addToken(request, response.accessToken));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          return throwError(error);
        })
    
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          this.refreshTokenSubject.next(jwt);
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  
   addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}

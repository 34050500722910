import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/services/api.service';

@Injectable()
export class CompaniesService {
  prefixPath = '/companies';

  constructor(private apiService: ApiService) {

  }
  create(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixPath}`, param, finalize);
  }
  update(id: number, param: any, finalize?: VoidFunction) {
    return this.apiService.put(`${this.prefixPath}/${id}`, param, finalize);
  }
  delete(id: number, finalize?: VoidFunction): Observable<any> {
    return this.apiService.delete(`${this.prefixPath}/${id}`, finalize);
  }
  getListCompany(filter?: string): Observable<any> {
    if (filter) {
      return this.apiService.get(`${this.prefixPath}/companies?filter=${filter}`);
    } else {
      return this.apiService.get(`${this.prefixPath}`);
    }
  }
  getCompanyId(id?: number): Observable<any> {
    if (id) {
      return this.apiService.get(`${this.prefixPath}/${id}`);
    } else {
      return this.apiService.get(`${this.prefixPath}/`);
    }
  }
  getWellByCompanyId(id?: number): Observable<any> {
    if (id) {
      return this.apiService.get(`${this.prefixPath}/getWells?companyId=${id}`);
    } else {
      return this.apiService.get(`${this.prefixPath}/getWells`);
    }
  }
  getEquipmentChannels(companyId: number, equipmentId: string): Observable<any> {
    return this.apiService.get(`${this.prefixPath}/${companyId}/equipments/${equipmentId}/getEquipmentChannel`);
  }
  UpdatePolicyInWell(companyId: number,months:number): Observable<any> {
    return this.apiService.put(`${this.prefixPath}/applyPolicy?companyId=${companyId}&months=${months}`,"sddf");
  }
}

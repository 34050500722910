import { Injectable } from '@angular/core';
import { User } from '../models';
import { isArray } from 'util';
import { UserRoleType } from '../../shared/helpers/app.constants';

@Injectable()
export class UserRolesService {

  constructor() { }

  toUserRole(user: User) {
    if (user && isArray(user.roles)) {
      return user.roles[0];
    }
    return '';
  }

  isCarboAdmin(role: string) {
    return role.toLowerCase() === UserRoleType.carboAdmin;
  }

  isClientAdmin(role: string) {
    return role.toLowerCase() === UserRoleType.clientAdmin;
  }

  isAdmin(role: string) {
    return this.isCarboAdmin(role) || this.isClientAdmin(role);
  }

  isFieldEngineer(role: string) {
    return role.toLowerCase() === UserRoleType.fieldEngineer;
  }

  isDataEngineer(role: string) {
    return role.toLowerCase() === UserRoleType.dataEngineer;
  }

  isAnalyst(role: string) {
    return role.toLowerCase() === UserRoleType.analyst;
  }

  getRoles() {
    return [
      { name: 'Analyst', value: 'Analyst' },
      { name: 'Data Engineer', value: 'Data Engineer' },
      { name: 'Field Engineer', value: 'Field Engineer' },
      { name: 'Admin', value: 'Carbo Admin' },
      { name: 'Admin', value: 'Admin' },
    ];
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Helper } from '../../helpers/helper';
import { ChannelMappingService } from '../../services/channel-mapping.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { remove, findIndex, forEach, isNil } from 'lodash';
import { LoadingOverlayService } from '../../services/loading-overlay.service';
import { UserAuthService } from '../../services/user-auth.service';
import { UserRolesService } from '../../services/user-roles.service';
import { ToasterService } from '../../services/toaster.service';
import { IRenameChannel } from '../../models';

@Component({
  selector: 'app-rename-channel',
  templateUrl: './rename-channel.component.html',
  styleUrls: ['./rename-channel.component.scss']
})
export class RenameChannelComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  submitted: boolean = false;
  loading: boolean = false;
  pattern: any = /^[_A-Za-z0-9\s\-[\]()%]*$/;

  wellId: number;
  treatmentId: number;

  companyId: number;
  isCarboAdmin: boolean = false;

  // Original Channel
  originalNameList: string[] = [];
  // Existing Channel
  existingChannelList: string[] = [];
  // New Channel
  channelList: any[] = [];
  newChannelList: any[] = [];

  paramChannel: IRenameChannel[] = [];

  constructor(private location: Location,
    private route: ActivatedRoute,
    private channelMappingService: ChannelMappingService,
    private loadingOverlayService: LoadingOverlayService,
    private userAuthService: UserAuthService,
    private userRolesService: UserRolesService,
    private toasterService: ToasterService) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(filter(params => params.wellId))
      .subscribe((param: any) => {
        if (param) {
          this.wellId = param.wellId;
          this.treatmentId = param.treatmentId;
          this.getAllChannel(param.wellId, param.treatmentId);
          this.getChannelNameList();
        }
      });
    const userInfo = this.userAuthService.getUserInfo();
    const userRole = this.userRolesService.toUserRole(userInfo);
    this.isCarboAdmin = this.userRolesService.isCarboAdmin(userRole);
    this.companyId = userInfo.companyId;
  }

  ngOnDestroy(): void {
    Helper.unsubscribe(this.subscriptions);
  }

  backClicked() {
    this.location.back();
  }

  getAllChannel(wellId: any, treatmentId: any) {
    this.newChannelList = [];
    this.subscriptions.push(
      this.channelMappingService.getOriginalName(wellId, treatmentId)
        .subscribe((res: {result: {listExistName: string[], listOriginalName: string[]}}) => {
          if (res && res.result) {
            this.originalNameList = res.result.listOriginalName;
            if (!res.result.listExistName || !res.result.listExistName.length) this.existingChannelList = this.originalNameList;
            else {
              this.existingChannelList = res.result.listExistName.map((value: string, index: number) => {
                if (!value || !value.trim()) return this.originalNameList[index];
                else return value;
              });
            }
            forEach(this.originalNameList, (item, index) => {
              this.newChannelList.push({
                id: index,
                channelName: null,
                isEditable: false
              });
            });
          }
        })
    );
  }

  getChannelNameList() {
    this.subscriptions.push(
      this.channelMappingService.getChannelName()
        .subscribe((res) => {
          if (res) {
            this.channelList = res.result;
          }
        })
    );
  }

  channelsSelector(index: number): any[] {
    const curNewItem = this.newChannelList[index];
    const newListExisting = this.newChannelList.filter(item => item.channelName !== null && item.channelName !== undefined && curNewItem.channelName !== item.channelName);
    if (!newListExisting.length) return this.channelList;
    return this.channelList.filter(channelName => {
      return newListExisting.findIndex(item => item.channelName === channelName) === -1;
    });
  }

  selectChannel(event: any, index: number) {
    if (event) {
      const indexParam = findIndex(this.paramChannel, { originalName: this.originalNameList[index] });
      if (indexParam !== -1) {
        this.paramChannel[indexParam].mappedName = event;
      } else {
        const param = {
          originalName: this.originalNameList[index],
          mappedName: event
        };
        this.paramChannel.push(param);
      }
      this.newChannelList[index].channelName = event;
      this.newChannelList[index].isEditable = false;
    } else {
      remove(this.paramChannel, { originalName: this.originalNameList[index] });
      this.newChannelList[index].channelName = null;
      this.newChannelList[index].isEditable = false;
    }
  }

  onSubmit(param: IRenameChannel[], renameLater?: boolean) {
    this.loadingOverlayService.show();
    this.submitted = true;
    this.loading = true;

    const finalizeFunc = () => {
      forEach(this.newChannelList, i => {
        i.channelName = null;
        i.isEditable = false;
      });
      this.paramChannel = [];
      this.getChannelNameList();
      this.getAllChannel(this.wellId, this.treatmentId);
      this.submitted = false;
      this.loading = false;
      this.loadingOverlayService.hide();
    };
    const observable$ = !renameLater
      ? this.channelMappingService.renameChannel(this.treatmentId, param, finalizeFunc)
      : this.channelMappingService.renameChannelLater(this.treatmentId, param, finalizeFunc);
    this.subscriptions.push(observable$.subscribe());
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  addNewChannel(event: any, index: number) {
    if (event) {
      event = event.replace(/\s+/g, ' ');
      const name = event.trim();
      if (name.length >= 2 && name.length <= 50 && this.pattern.test(name)) {
        const param: any = {
          companyId: this.isCarboAdmin ? null : this.companyId,
          channelMappedName: name
        };
        this.subscriptions.push(
          this.channelMappingService.create(param, () => {
          })
            .subscribe((res) => {
              this.toasterService.showSuccess('Created Channel Success');
              this.selectChannel(name, index);
              this.getChannelNameList();
            }, (error) => {
            })
        );
      } else {
        this.toasterService.showError('The channel name has to be between 2 and 50 characters long and not contain decimal digits and special characters (except for: space, -, %, underscore, (), []).');
      }
    }
  }

  findIndexParam(index: number) {
    const indexParam = findIndex(this.paramChannel, { originalName: this.originalNameList[index] });
    return indexParam;
  }

  onChangeSelect(item: any) {
    forEach(this.newChannelList, i => {
      i.isEditable = false;
    });
    setTimeout(() => {
      item.isEditable = true;
    });
  }
  resetChannel(index: number) {
    if (index > -1 && !isNil(this.originalNameList[index])) {
      this.selectChannel(this.originalNameList[index], index);
    }
  }
  isNotResetChannel(index: number): boolean {
    if (!this.originalNameList || !this.existingChannelList) return true;
    else if (isNil(this.existingChannelList[index])) return false;
    return this.originalNameList[index] === this.existingChannelList[index];
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services';
import { Observable } from 'rxjs';

@Injectable()
export class UserService {
  userProfilePath = `/userProfile`;

  constructor(private apiService: ApiService) { }

  getCurrentUser(): Observable<any> {
    return this.apiService.get(`${this.userProfilePath}`);
  }
  updateCurrentUser(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.put(`${this.userProfilePath}`, param, finalize);
  }
  sendSupportRequest(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.userProfilePath}/support`, param, finalize);
  }
}

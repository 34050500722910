import { Injectable } from '@angular/core';
import { isEmpty, isObject } from 'lodash';
import { TreatmentPlotService } from '../../components/treatment-plot/treatment-plot.service';
import { IInputDataChart } from '../../models';

@Injectable()
export class RealtimeChartService {
  private inputDataCache = null;

  constructor(
  ) { }

  setInputDataCache(inputData) {
    this.inputDataCache = inputData;
  }

  // realtime chart grid data
  getFracproGridDataDefault() {
    const gridModel = [
      {
        heading: 'Realtime',
        dataGrid: [
          [
            { name: 'Elapsed Time', mnemonic: 'Time', unit: 'min', value: null, cName: 'C0', decimal: 1, dataFlow: 'realtime', measureType: undefined },
            { name: 'Model Time', mnemonic: 'Time', unit: 'min', value: null, cName: 'F0', decimal: 1, measureType: undefined },
            { name: 'Surf Pressure', mnemonic: 'Surf Pressure', unit: 'psi', value: null, cName: null, decimal: 0, measureType: undefined },
            { name: 'Slurry Rate', mnemonic: 'Slurry Rate', unit: 'bpm', value: null, cName: null, decimal: 1, measureType: undefined },
          ],
          [
            { name: 'Stage Number', mnemonic: 'Surf Stage Number', unit: null, value: null, cName: null, measureType: undefined },
            { name: 'Stage Name', mnemonic: 'Stage Name', unit: null, value: null, cName: null, measureType: undefined },
            { name: 'Proppant Name', mnemonic: 'Proppant Name', unit: null, value: null, cName: null, measureType: undefined },
            { name: 'Fluid Name', mnemonic: 'Fluid Name', unit: null, value: null, cName: null, measureType: undefined },
          ],
          [
            { name: 'Prop Conc', mnemonic: 'Prop Conc', unit: 'ppg', value: null, cName: null, decimal: 2, measureType: undefined },
            { name: 'Btm Prop Conc', mnemonic: 'Btm Prop Conc', unit: 'ppg', value: null, cName: null, decimal: 2, measureType: undefined },
            { name: 'Btm Pressure', mnemonic: 'Btmh Pressure', unit: 'psi', value: null, cName: null, decimal: 0, measureType: undefined },
          ]
        ]
      },
      {
        heading: 'Totals',
        dataGrid: [
          [
            { name: 'Prop Total', mnemonic: 'Surf Prop Tot', unit: null, value: null, cName: null, decimal: 2, measureType: undefined },
            { name: 'Stage Prop Total', mnemonic: 'Prop Stage Tot', unit: null, value: null, cName: null, decimal: 2, measureType: undefined },
          ],
          [
            { name: 'Clean Total', mnemonic: 'Surf Clean Tot', unit: 'bbls', value: null, cName: null, decimal: 2 , measureType: undefined},
            { name: 'Stage Clean Total', mnemonic: 'Clean Stage Tot', unit: null, value: null, cName: null, decimal: 2, measureType: undefined },
          ],
          [
            { name: 'Slurry Total', mnemonic: 'Surf Slry Tot', unit: null, value: null, cName: null, decimal: 2, measureType: undefined },
            { name: 'Stage Slurry Total', mnemonic: 'Slurry Stage Tot', unit: 'bbls', value: null, cName: null, decimal: 2, measureType: undefined },
          ]
        ]
      }
    ];
    return gridModel;
  }

  getFracproGridData(channelMapping) {
    const gridData = this.getFracproGridDataDefault();

    if (!isEmpty(channelMapping)) {
      // update cName and unit
      gridData.forEach(model => {
        model.dataGrid.forEach(grid => {
          grid.forEach(channel => {
            const mappedItem = channelMapping.find(item => channel.mnemonic === item.name);
            if (mappedItem) {
              channel.cName = mappedItem.cName;
              channel.unit = mappedItem.unit;
              channel.measureType = mappedItem.measureType;
            }
          });
        });
      });
    }

    return gridData;
  }

  getCNameGridData(gridData) {
    const result = [];
    if (!isEmpty(gridData)) {
      // update cName and unit
      gridData.forEach(model => {
        model.dataGrid.forEach(grid => {
          grid.forEach(channel => {
            if (channel.cName) {
              result.push(channel.cName);
            }
          });
        });
      });
    }
    return result;
  }

  getFracproCNameGridData(channelMapping) {
    return this.getCNameGridData(this.getFracproGridData(channelMapping));
  }

  setChannelValueGridData(gridData, fracproInputData: IInputDataChart, realtimeInputData: IInputDataChart, predictedCNames?: string[]) {
    fracproInputData = isObject(fracproInputData) && fracproInputData ? fracproInputData : { columns: [], values: [] };
    realtimeInputData = isObject(realtimeInputData) && realtimeInputData ? realtimeInputData : { columns: [], values: [] };
    if (!isEmpty(gridData)) {
      const fracproValues = fracproInputData.values;
      const fracproColumns = fracproInputData.columns;
      const realtimeValues = realtimeInputData.values;
      const realtimeColumns = realtimeInputData.columns;
      // update cName and unit
      gridData.forEach(model => {
        model.dataGrid.forEach(grid => {
          grid.forEach(channel => {
            if (channel && channel.cName) {
              // update realtime data
              if (channel.dataFlow === 'realtime') {
                if (!isEmpty(realtimeColumns) && !isEmpty(realtimeValues)) {
                  // if there is predictCName
                  if (predictedCNames && predictedCNames.length) {
                    const lastTime = this.getBaseTimeByLastDataPoint(realtimeInputData, predictedCNames);
                    if (lastTime) {
                      channel.value = lastTime / 60;
                    }
                  } else {
                    const indexDataRow = realtimeValues.length - 1;
                    const lastDataRow = realtimeValues[indexDataRow];
                    // set last value as channel value
                    if (!isEmpty(lastDataRow)) {
                      const channelIndex = realtimeColumns.findIndex(value => value === channel.cName);
                      // convert time from seconds to min
                      if (channel.cName === 'C0') {
                        channel.value = lastDataRow[channelIndex] / 60;
                      } else {
                        channel.value = lastDataRow[channelIndex];
                      }
                    }
                  }

                }
              } else { // udpate fracpro data
                if (!isEmpty(fracproColumns) && !isEmpty(fracproValues)) {
                  // get last data row
                  const indexDataRow = fracproValues.length - 1;
                  const lastDataRow = fracproValues[indexDataRow];
                  // set last value as channel value
                  if (!isEmpty(lastDataRow)) {
                    const channelIndex = fracproColumns.findIndex(value => value === channel.cName);
                    // convert time from seconds to min
                    if (channel.cName === 'F0') {
                      channel.value = lastDataRow[channelIndex] / 60;
                    } else {
                      channel.value = lastDataRow[channelIndex];
                    }
                  }
                }
              }
              // fix decimal
              if (typeof channel.decimal === 'number' && typeof channel.value === 'number' && !isNaN(channel.value)) {
                channel.value = channel.value.toFixed(channel.decimal) * 1;
              }
            }
          });
        });
      });
    }
  }

  getBaseTimeByLastDataPoint(inputData: IInputDataChart, predictChannelsMap?: string[]) {
    if (inputData && !isEmpty(inputData.values)) {
      const filterIndex: number[] = [];
      for (let i = 0; i < inputData.columns.length; i++) {
        if (inputData.columns[i] === 'time') continue;
        if (inputData.columns[i].includes('C0')) continue;
        if (predictChannelsMap.includes(inputData.columns[i])) continue;
        filterIndex.push(i);
      }

      const getLastDataValue = (data: any[]) => {
        const n = data.length;
        for (let i = n - 1; i >= 0; i--) {
          for (const index of filterIndex) {
            if (data[i][index] === null) continue;
            return data[i];
          }
        }
      };

      const lastDataPoint = getLastDataValue(inputData.values);
      if (!lastDataPoint) return 0;
      const index = inputData.columns.indexOf('C0');
      if (index < 0) return 0;
      return lastDataPoint[index];
    }
  }
}

import { groupBy, isEmpty, maxBy, orderBy } from 'lodash';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import * as tzLookup from 'tz-lookup';
import { Subscription } from 'rxjs/Subscription';
import { DefaultColor } from './app.constants';
import { MomentZoneOffset } from 'moment';
import { CountryModel } from '../models/country.model';
import * as _ from 'lodash';

const randomColor = require('randomcolor');
(window as any).moment = moment;
(window as any).momentTz = momentTz;

export class Vector {
  x: number | null;
  y: number | null;

  constructor(x: number | null, y: number | null) {
    this.x = x;
    this.y = y;
  }
}

export class Helper {

  /**
   * Unsubscribe
   * @param {Subscription[]} subs
   */
  public static unsubscribe(subs: Subscription[]) {
    subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  // public static lookupTimeZone(latitude: number, longitude: number): any {
  //   return tzLookup(latitude, longitude);
  // }

  public static calculateVector(point1: Vector, point2: Vector, x: number): Vector {
    const res = new Vector(x, null);
    const dx = point2.x - point1.x;
    if (dx === 0) return res;
    const m = (point2.y - point1.y) / dx;
    const b = point1.y - (m * point1.x);
    res.y = m * x + b;
    return res;
  }

  public static findOffsetDataLinear(data: any[], targetTime: string): number {
    let left = null;
    let right = null;
    let target = null;
    const n = data.length;

    for (let i = 0; i < n; i++) {
      if (targetTime === data[i][0]) {
        target = data[i];
        break;
      }
      if (data[i][0] < targetTime) left = data[i];
      if (data[i][0] > targetTime && right === null) right = data[i];
      if (left && right) break;
    }

    if (target) return target[2];
    if (left && right) {
      const time1 = new Date(left[0]).getTime();
      const time2 = new Date(right[0]).getTime();
      const time = new Date(targetTime).getTime();
      const mid = Helper.calcLinear(left[2], right[2], time1, time2, time);
      return mid;
    }
    return null;
  }

  public static splitNumber(n: number, group: number, index?: boolean): number[] {
    if (group <= 1) return index ? [0] : [n];
    const d = Math.floor(n / group);
    const f = n % group;
    const res: number[] = [];

    for (let i = 0; i < group; i++) {
      if (index) {
        if (!i) res.push(0);
        else if (i <= f) res.push(res[i - 1] + d + 1);
        else res.push(res[i - 1] + d);
      } else {
        if (i < f) res.push(d + 1);
        else res.push(d);
      }
    }
    return res;
  }

  public static findOffsetData(data: any[], targetTime: string): number {
    // let leftData = null;
    // let rightData = null;
    let leftIdx = null;
    let rightIdx = null;

    const binaryOffsetData = (left: number, right: number) => {
      if (left > right) {
        leftIdx = left;
        rightIdx = right;
        return null;
      }

      const mid = Math.floor(left + (right - left) / 2);
      if (data[mid][0] === targetTime) return data[mid];
      if (data[mid][0] > targetTime) return binaryOffsetData(left, mid - 1);
      if (data[mid][0] < targetTime) return binaryOffsetData(mid + 1, right);
    };

    const target = binaryOffsetData(0, data.length - 1);
    if (target) return target[2];

    if (leftIdx !== null && rightIdx !== null) {
      const time = new Date(targetTime).getTime();
      let time1 = new Date(data[leftIdx][0]).getTime();
      let time2 = new Date(data[rightIdx][0]).getTime();

      // make sure time1 always < time and time2 always > time
      while (time1 > time) {
        time1 = new Date(data[--leftIdx][0]).getTime();
      }
      while (time2 < time) {
        time2 = new Date(data[++rightIdx][0]).getTime();
      }
      const linearTarget = Helper.calcLinear(data[leftIdx][2], data[rightIdx][2], time1, time2, time);
      return linearTarget;
    }
    return null;
  }

  static parseInt(value: string | number, defaultValue = 0, radix = 10): number {
    if (typeof value === 'number') return defaultValue;
    try {
      return parseInt(value, radix);
    } catch (e) {
      return defaultValue;
    }
  }

  public static lookupTimeZone(country: string, offsetTime?: number): string {
    if (!country) return 'Etc/UTC';
    const countries = CountryModel.init();
    const existCountry = countries.find(x => {
      return x.englishName === country || x.localName === country;
    });
    if (!existCountry) {
      return 'Etc/UTC';
    }

    return this.parseCountryCodeOffsetToTz(existCountry.alpha2Code, -Math.round(offsetTime / 60));
  }
  // get timezone from country name & offsetTime
  public static parseCountryCodeOffsetToTz(countryCode: string, offsetTime?: number): string {
    let result: any;
    let timezones: MomentZoneOffset[];

    if (offsetTime !== null && offsetTime !== undefined) {
      timezones = moment.tz.zonesForCountry(countryCode, true);

      result = timezones
        .filter(tz => tz.offset === offsetTime)
        .map(x => x.name);
    } else {
      result = moment.tz.zonesForCountry(countryCode);
    }

    if (result && result.length) return result[0];
    return 'Etc/UTC';
  }

  public static parseTimeString(time: string): { hours: number, minutes: number } {
    const result = { hours: null, minutes: null };
    if (time) {
      const arr: any = time.split(':');
      if (arr && arr.length > 1) {
        const hours = Math.floor(arr[0]);
        const minutes = Math.floor(arr[1]);
        if (!isNaN(hours) && !isNaN(minutes)) {
          result.hours = hours;
          result.minutes = minutes;
        }
      }
    }
    return result;
  }

  public static addSecondTimeString(time: string, sec: number): string {
    return moment.utc(time).add({ seconds: sec }).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  }

  public static getTimezoneOffset(currentTime: string | number, timezone: string, isUTC = false): number {
    if (typeof currentTime === 'string') {
      currentTime = currentTime.substring(0, 19);
      return moment.tz(currentTime, timezone).utcOffset();
    }
    if (currentTime < 1_000_000_000) currentTime = currentTime * 1000;
    if (!isUTC) return moment.tz(currentTime, timezone).utcOffset();

    const parseCurrentTime = moment.tz(currentTime, timezone).toISOString();
    return this.getTimezoneOffset(parseCurrentTime, timezone);
  }

  public static removeTimezoneTime(time: number, timezone: string): number {
    const offset = Helper.getTimezoneOffset(time, timezone, true) * 60 * 1000;
    return time - offset;
  }

  public static addTimezoneTime(currentTime: number, time: number, timezone: string): number {
    const offset = Helper.getTimezoneOffset(currentTime + time, timezone) * 60 * 1000;
    return time + offset;
  }

  public static parseDateTimeString(time: string, setSecond?: number): number {
    if (setSecond !== null) return moment(time).set('seconds', setSecond).unix();
    return moment(time).unix();
  }

  public static floorDataTimeString(time: string, timezone: string): number {
    return moment.tz(time, timezone).set('seconds', 0).unix();
  }

  public static ceilDataTimeString(time: string, timezone: string): number {
    if (moment(time).second() === 0) return moment.tz(time, timezone).unix();
    return moment.tz(time, timezone).set('seconds', 60).unix();
  }

  public static toTimeString(time: number) {
    return this.toMomentString(time, 'HH:mm');
  }

  public static isSameSet<T>(model1: T, model2: T): boolean {
    if (!model1 && !model2) return true;
    if (model1 && !model2) return false;
    if (!model1 && model2) return false;
    return true;
  }

  public static isSameSetData(data1, data2): boolean {
    if (!Helper.isSameSet(data1, data2)) return false;
    return data1 === data2;
  }

  public static toDateTimeString(time: number, setSecond?: number): string {
    if (!isNaN(setSecond)) {
      return moment(time).set('second', setSecond).utc().toISOString();
    }
    return moment(time).utc().toISOString();
  }

  public static concatTimeString(datetime: string, second: number): string {
    const time = new Date(datetime).getTime() + second * 1000;
    return this.toDateTimeString(time);
  }

  public static toDateTimeStringRemoveTimezone(time: number, timezone: number): string {
    const getTime = new Date(time).getTime() - (timezone * 60 * 1000);
    return moment(getTime).utc().toISOString();
  }

  public static toMomentString(time: number, format: string) {
    if (!time || isNaN(time)) return '';
    return moment(time).utc().format(format);
  }

  public static addTimestampToString(datetime, timestamp) {
    const getTime = new Date(datetime).getTime();
    return moment(getTime + timestamp).utc().toISOString();
  }

  public static insertString(text: string, insertText: string, index: number): string {
    const n = index >= 0 ? index : text.length - Math.abs(index);
    return text.substr(0, n) + insertText + text.substr(n);
  }

  public static mapDateTimeString(timestamp: string | any, setSecond?: number): string {
    if (typeof timestamp === 'string') {
      if (!isNaN(setSecond)) return moment(timestamp).set('second', setSecond).utc().toISOString();
      return moment(timestamp).utc().toISOString();
    } else {
      if (!isNaN(setSecond)) return timestamp.set('second', setSecond).utc().toISOString();
      return timestamp.utc().toISOString();
    }
  }

  public static mapDateTimeUTCString(timestamp: string | any, timezone: string, setHour?: number): string {
    if (typeof timestamp === 'string') {
      timestamp = timestamp.substring(0, timestamp.length - 1);
      if (setHour != null || setHour !== undefined) return moment.tz(timestamp, timezone).set('hours', setHour).set('minutes', 0).set('seconds', 0).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      return moment.tz(timestamp, timezone).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    } else {
      const timestampString = timestamp.format('YYYY-MM-DDTHH:mm:ss');
      if (setHour != null || setHour !== undefined) return moment.tz(timestampString, timezone).set('hours', setHour).set('minutes', 0).set('seconds', 0).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      return moment.tz(timestampString, timezone).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }
  }

  public static floorTimestamp(timestamp: number) {
    return Math.floor(timestamp / 60_000) * 60_000;
  }

  public static floorTimestampToString(timestamp: number, timeZone?: string) {
    if (!timeZone) {
      return moment.unix(timestamp).utc().set('second', 0).toISOString();
    }
    else {
      return moment.unix(timestamp).utc().set('second', 0).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }
  }

  public static ceilTimestamp(timestamp: number) {
    const d = timestamp / 60_000;
    const f = Math.floor(timestamp / 60_000);
    if (d > 0.01) return (f + 1) * 60_000;
    return f * 60_000;
  }

  public static ceilTimestampToString(timestamp: number, timeZone?: string): string {
    if (!timeZone) {
      if (moment.unix(timestamp).second() === 0) return moment.unix(timestamp).utc().toISOString();
      return moment.unix(timestamp).utc().set('second', 60).toISOString();
    }
    else {
      if (moment.unix(timestamp).seconds() === 0) return moment.unix(timestamp).utc().tz(timeZone).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      return moment.unix(timestamp).utc().set('second', 60).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }
  }

  public static mapDateTimeTimestamp(timestamp: string | number | any, setSecond?: number): number {
    if (typeof timestamp === 'string') return moment(timestamp).utc().valueOf();
    if (typeof timestamp === 'number') return timestamp;
    else {
      if (!isNaN(setSecond)) {
        return timestamp.set('second', 0).utc().valueOf();
      }
      return timestamp.utc().valueOf();
    }
  }

  public static setTimeOnDate(timestamp: number, hours, minutes, isDST?: boolean, offsetHour?: number) {
    let result = timestamp;
    const seconds = moment(timestamp).second();
    const dayTime = moment(timestamp).utc().startOf('day');
    if (isDST) {
      // forward or backward offset hour
      dayTime.hour(hours + offsetHour);
    } else {
      dayTime.hour(hours);
    }
    dayTime.minute(minutes);
    dayTime.second(seconds);
    result = dayTime.valueOf();
    return result;
  }

  public static setEndTimeOnDate(startTimestamp: number, endTimestamp: number, startTime: string, endTime: string) {
    let result = startTimestamp;
    // ideally case is calculated with startTimestamp and endTimestamp are in the same day
    const startMoment = moment(startTime, 'HH:mm');
    const endMoment = moment(endTime, 'HH:mm');
    const startDayTime = moment(startTimestamp).utc().startOf('day');
    const endDayTime = moment(endTimestamp).utc().startOf('day');
    const startMomentHour = startMoment.get('hour');
    const endMomentHour = endMoment.get('hour');
    startDayTime.set('hour', startMomentHour);
    startDayTime.set('minute', startMoment.get('minute'));
    endDayTime.set('hour', endMomentHour);
    endDayTime.set('minute', endMoment.get('minute'));
    let durationMinutes = endDayTime.diff(startDayTime, 'minutes');
    // in case startTimestamp and endTimestamp are NOT in the same day
    // and the endTimestamp moved to new day but the end time set value should be applied for the previous day (23h->24h)
    if (endMomentHour >= startMomentHour && endMomentHour < 24) {
      const endDayTimeByStartTime = moment(startTimestamp).utc().startOf('day');
      endDayTimeByStartTime.set('hour', endMomentHour);
      endDayTimeByStartTime.set('minute', endMoment.get('minute'));
      durationMinutes = endDayTimeByStartTime.diff(startDayTime, 'minutes');
    }
    // case DST happened
    if (durationMinutes < 0) {
      durationMinutes = durationMinutes + 60;
    }
    // if still less than 0 then invalid input
    if (durationMinutes > 0) {
      startDayTime.add(durationMinutes, 'minute');
      result = startDayTime.valueOf();
    }

    return result;
  }

  public static concatString(arr: (string | undefined | null)[], joiner: string): string {
    let res = '';
    const n = arr.length;
    for (let i = 0; i < n; i++) {
      if (arr[i] === undefined || arr[i] === null) continue;
      if (i !== 0) res += joiner;
      res += arr[i];
    }
    return res.trim();
  }

  public static randomHexColor() {
    const color = randomColor({
      luminosity: 'bright'
    });
    return color;
  }

  public static saveFileText(content, name: string, ext: string = 'txt', appendTime?: boolean) {
    const file = new Blob([content], { type: 'text/plain;charset=utf-8' });
    const fileName = appendTime ? (name + '_' + new Date().getTime() + '.' + ext) : name + '.' + ext;
    saveAs(file, fileName);
  }

  public static isNumberic(value) {
    if (typeof value === 'string') {
      value = value.trim();
    }
    return value !== null && value !== '' && typeof Math.floor(value) === 'number' && !isNaN(Math.floor(value));
  }

  public static getValidUnit(unit: string, isWrap?: boolean): string {
    const trash = ['(', ')'];
    let result = '';
    if (unit && typeof unit === 'string') {
      unit = unit.trim();
      let arrStr = unit.split('');
      arrStr = arrStr.filter(str => {
        return !trash.includes(str);
      });
      result = arrStr.join('');
      if (isWrap) {
        result = `(${result})`;
      }
    }
    return result;
  }

  public static isDST(datetime) {
    const january = new Date(datetime.getFullYear(), 0, 1).getTimezoneOffset();
    const july = new Date(datetime.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(january, july) !== datetime.getTimezoneOffset();
  }

  public static convertDateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  public static getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  public static getRandomColor() {
    const colors = DefaultColor;
    const index = this.getRandomInt(0, colors.length);
    return colors[index];
  }

  public static convertSecToMin(value: number) {
    let result = null;
    // convert to number incase input is string number
    value = value * 1;
    if (typeof value === 'number' && !isNaN(value)) {
      value = value / 60;
      result = parseFloat(value.toFixed(3));
    }
    return result;
  }

  public static convertMinToSec(value: number | string) {
    if (!value) return 0;
    if (typeof value === 'string') {
      try {
        const cast = parseFloat(value);
        return cast * 60;
      } catch (e) {
        return 0;
      }
    }
    return value * 60;
  }


  public static handleShowHighestByTreatmentId(listItems) {
    if (!isEmpty(listItems)) {
      const gbw = groupBy(listItems, 'wellId');
      const trtMaxById = [];
      Object.values(gbw).map(treatments => {
        const maxItem = maxBy(treatments, 'treatmentId');
        if (maxItem) {
          trtMaxById.push(maxItem);
        }
      });
      return orderBy(trtMaxById, 'treatmentId', 'desc');
    }
    return [];
  }

  private static getProp(data, props) {
    return props.length === 1 ? data[props[0]] : this.getProp(data[props[0]], props.slice(1));
  }

  private static readProp(data, prop: string) {
    const split = prop.split('.');
    return this.getProp(data, split);
  }

  public static getList(data, prop: string, defaultList = []) {
    if (!data) return defaultList;
    const read = this.readProp(data, prop);
    if (!read || !read.length) return defaultList;
    return read;
  }

  public static findInMultiList(listOfList, prop: string, value) {
    for (const list of listOfList) {
      for (const i of list) {
        const split = prop.split('.');
        const read = this.getProp(i, split);
        if (read === value) return i;
      }
    }
    return null;
  }

  private static getListData(data, defaultData, targetProp, toProps) {
    const read = this.readProp(data, targetProp);
    if (!read) return defaultData;
    const res: any = {};
    for (const p of toProps) {
      res[p] = read[p] ? read[p] : defaultData[p];
    }
    return res;
  }

  public static findMultiProp(data1, data2, props: string[]) {
    if (!data1 || !data2) return false;
    for (const i of props) {
      if (!data1[i] && !data2[i]) continue;
      if (data1[i] !== data2[i]) return false;
    }
    return true;
  }

  public static filterMulti(data1, data2, props: string[]): boolean {
    if (!data1 || !data2) {
      return false;
    }

    for (const i of props) {
      if (!data1[i] && !data2[i]) continue;
      if (data1[i] !== data2[i]) return false;
    }
    return true;
  }

  public static calcLinear(X1, X2, Y1, Y2, Y): number {
    return (Y - Y1) / (Y2 - Y1) * (X2 - X1) + X1;
  }

  public static filterInRange(data: number, start: number, end: number, includeEqual = true): boolean {
    if (includeEqual) return data >= start && data <= end;
    return data > start && data < end;
  }

  public static convertMinToTimestamp(minValue: number, baseTimeStampSec: number): number {
    // minValue is minute value - bastTimeStamp is up to second
    return (minValue * 60 + baseTimeStampSec) * 1000;
  }

  public static createListOf(value, amount: number = 0) {
    if (!amount) return [];
    const res: any = [];
    for (let i = 0; i < amount; i++) {
      res.push(value);
    }
    return res;
  }

  public static isArrayEqual(x, y) {
    return isEmpty(_.difference(x, y)) && isEmpty(_.difference(y, x));
  }

  public static compareList<T>(arr: T[], brr: T[], compareIndex?: boolean): boolean {
    if (!arr.length && brr.length) return false;
    if (arr.length && !brr.length) return false;

    if (!compareIndex) {
      for (const a of arr) {
        const find = brr.includes(a);
        if (!find) return false;
      }
      for (const b of brr) {
        const find = arr.includes(b);
        if (!find) return false;
      }
      return true;
    } else {
      if (arr.length !== brr.length) return false;
      const length = arr.length || brr.length;
      for (let i = 0; i < length; i++) {
        if (arr[i] !== brr[i]) return false;
      }
      return true;
    }
  }
}

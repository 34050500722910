import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { ToasterService, UserAuthService } from '../../shared/services';
import { CookieStorageService } from '../services';
import { Router } from '@angular/router';
import { IsIEOrEdge } from '../../shared/helpers/app.constants';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  isRefreshing: boolean = false;
  private readonly JWT_TOKEN = 'jwtToken';
  refreshTokenSubject:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private cookieStoreService: CookieStorageService,
    private userAuthService: UserAuthService,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      Accept: 'application/json',
    };

    // const isIEOrEdge = IsIEOrEdge.test(window.navigator.userAgent);

    const token = this.cookieStoreService.getAccessToken();

    if (token) {
      req=this.addToken(req, token);
      // headersConfig['Authorization'] = `Bearer ${token}`;
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) { // try to refresh token but still has error, give up and logout.
          // this.userAuthService.logout1();
          // this.
          // this.router.navigate(['/login']);
          // return this.handle401Error(req, next);
        } else {
          
            return throwError(error);
          
        }
      })
    );
    // if (req.method === 'GET') {
    //   const customRequest = req.clone({
    //     headers: req.headers.set('Cache-Control', 'no-cache')
    //       .set('Pragma', 'no-cache')
    //       .set('Authorization', `Bearer ${token}`)
    //       .set('Accept', 'application/json')
    //   });
    //   return next.handle(customRequest);
    // } else {
    //   const request = req.clone({ setHeaders: headersConfig });
    //   return next.handle(request);
    // }

  }
   handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = false;
      this.refreshTokenSubject.next(null);
       this.userAuthService.refreshToken().then(token =>{
          this.isRefreshing = false;
          this.userAuthService.storeJwtToken(token.accessToken);
          this.userAuthService.setAuth(token);
          // this.router.navigate(['/']);
          this.cookieStoreService.setItem(this.JWT_TOKEN, token.accessToken);
          this.refreshTokenSubject.next(token.accessToken);
          return next.handle(this.addToken(request, token.accessToken));
        }),
        catchError((error: HttpErrorResponse) => {
          this.isRefreshing = true;
          this.userAuthService.logout();
          return throwError(error);
        })
        
    
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

   addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('Authorization', `Bearer ${token}`)
      .set('Accept', 'application/json')
    });
  }
}

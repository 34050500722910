import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services';

@Injectable()
export class DataFilesService {
  prefixWellsPath = `/wells`;

  constructor(
    private apiService: ApiService
  ) { }

  private getWellPath(wellId) {
    return `${this.prefixWellsPath}/${wellId}/dataFiles`;
  }
  private getTreatmentPath(wellId, treatmentId) {
    return `${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}/dataFiles`;
  }

  uploadByWell(wellId, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.postFile(`${this.getWellPath(wellId)}/${file.name}`, formData);
  }

  getByWell(wellId: number) {
    return this.apiService.get(`${this.getWellPath(wellId)}/details`);
  }

  deleteByWell(wellId, fileName) {
    return this.apiService.delete(`${this.getWellPath(wellId)}/${fileName}`);
  }

  uploadByTreatment(wellId, treatmentId, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.postFile(`${this.getTreatmentPath(wellId, treatmentId)}/${file.name}`, formData);
  }

  getByTreatment(wellId: number, treatmentId: number) {
    return this.apiService.get(`${this.getTreatmentPath(wellId, treatmentId)}/details`);
  }

  deleteByTreatment(wellId, treatmentId, fileName) {
    return this.apiService.delete(`${this.getTreatmentPath(wellId, treatmentId)}/${fileName}`);
  }

  uploadDataFiles(file: File, wellId, treatmentId?) {
    if (treatmentId) {
      return this.uploadByTreatment(wellId, treatmentId, file);
    }
    return this.uploadByWell(wellId, file);
  }

  getDataFiles(wellId: number, treatmentId?: number) {
    if (treatmentId) {
      return this.getByTreatment(wellId, treatmentId);
    }
    return this.getByWell(wellId);
  }

  deleteDataFiles(fileName, wellId, treatmentId?) {
    if (treatmentId) {
      return this.deleteByTreatment(wellId, treatmentId, fileName);
    }
    return this.deleteByWell(wellId, fileName);
  }

  getCurvesDasFile(fileName, wellId) {
    return this.apiService.get(`${this.getWellPath(wellId)}/las/${fileName}/depths`);
  }

  getLogDataCurveDasFile(fileName, wellId, curveName) {
    if (curveName) {
      curveName = curveName.trim();
    }
    return this.apiService.get(`${this.getWellPath(wellId)}/las/${fileName}/depths/values?columnName=${curveName}`);
  }
}

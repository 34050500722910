import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToasterService {
  constructor(
    private toastrService: ToastrService
  ) { }

  showSuccess(message: string) {
    this.toastrService.success(message, '');
  }

  showError(message: string) {
    this.toastrService.error(message, '');
  }

  handleError(resData) {
    let message = 'Oops! Something went wrong. Please contact us for support.';
    if (resData && resData.error && resData.error.message) {
      message = resData.error.message;
    } else if (resData && !!resData.notInternet) {
      message = resData.message;
    }
    this.toastrService.error(message, 'Error');
  }
}

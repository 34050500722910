import { Injectable } from '@angular/core';

export const KEY_DESIGN_ACTUAL_SETTING = 'designActualSetting';

@Injectable()
export class LocalDataStorageService {

  constructor() { }

  setOption(optionKey, value) {
    if (optionKey) {
      localStorage.setItem(`${optionKey}`, JSON.stringify(value));
    }
  }

  getOption(optionKey) {
    if (optionKey) {
      try {
        const stringData = localStorage.getItem(`${optionKey}`);
        return JSON.parse(stringData);
      } catch (error) {
        return null;
      }
    }
    return;
  }

  removeOption(optionKey) {
    if (optionKey) {
      localStorage.removeItem(`${optionKey}`);
    }
  }
}

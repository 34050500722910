import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2} from '@angular/core';
import {NgControl} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[decimalFormat]'
})

export class DecimalFormatDirective implements AfterViewInit, OnChanges, OnDestroy {
  private decimalDigits: number = 4;
  private input: any;
  private decimalPipe = new DecimalPipe('en-US');
  private ob: Subscription;

  @Input()
  set attrDisabled(tmp: boolean) {
    if (this.input) {
      if (!!tmp) {
        this.renderer.setAttribute(this.input, 'disabled', '');
      } else {
        this.renderer.removeAttribute(this.input, 'disabled');
      }
    }
  }

  @Input()
  set decimalFormat(d: any) {
    const digit = parseInt(d, 10);
    if (digit >= 0) {
      this.decimalDigits = digit;
    } else {
      this.decimalDigits = 4;
    }
  }

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private control: NgControl) {
  }
  ngAfterViewInit(): void {
    this.input = this.renderer.createElement('input');
    // this.renderer.addClass(this.input, 'form-control');
    this.setInputValue(this.el.nativeElement.value);

    const className = this.el.nativeElement.getAttribute('class');
    if (className) {
      this.renderer.setAttribute(this.input, 'class', className);
    }

    const style = this.el.nativeElement.getAttribute('style');
    if (style) {
      this.renderer.setAttribute(this.input, 'style', style);
    }

    // Check only view
    const disabled = this.el.nativeElement.getAttribute('disabled');
    if (disabled !== null) {
      this.renderer.setAttribute(this.input, 'disabled', '');
    }

    this.renderer.insertBefore(this.el.nativeElement.parentNode, this.input, this.el.nativeElement);
    this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    if (this.control.control) {
      this.ob = this.control.control.valueChanges.subscribe((value) => this.setInputValue(value));
    }
    this.renderer.listen(this.input, 'keypress', (e: any) => {
      const event = e || window.event;
      if (event) {
        return this.onKeyDown(e);
      }
    });
    this.renderer.listen(this.input, 'input', (e: any) => {
      if (e.target.value !== null) {
        if (e.target.value !== '-') {
          e.target.value = this.format(e.target.value);
        }
      }
      if (this.control.control) {
        this.control.control.markAsTouched();
      }
      return true;
    });
  }

  private clearDelimitersAndLeadingZeros(value) {
    if (value === '0') {
      return '0';
    }
    const cleanValue = value.toString().replace(/,/g, '').replace(/^0*/, '');
    const abc = cleanValue.replace(/[^0-9.]/g, '');
    return cleanValue.replace(/[^0-9.]/g, '');
  }

  private setInputValue(value): void {
    const viewValue = (value != null && value !== '' && typeof value === 'number') ? this.decimalPipe.transform(value, `1.0-${this.decimalDigits}`) : '';
    this.renderer.setAttribute(
      this.input,
      'value',
      viewValue
    );
    if (this.input.value !== viewValue) {
      this.input.value = viewValue;
    }
  }

  onKeyDown(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    const arr = event.target.value.split('.');
    // Check negative number
    if (
      charCode === 45 && (event.target.value.includes('-')
      )) {
      return false;
    }
    // Check point
    if (charCode === 46 && (this.decimalDigits === 0 || event.target.value.includes('.') || event.target.value === '')) {
      return false;
    }
    if ((48 <= charCode && charCode <= 57)
      || charCode === 46
      || charCode === 45) {
      return true;
    } else {
      return false;
    }
  }

  private format(value) {
    if (value === '') {
      if (this.control.control) {
        this.control.control.setValue('', {emitViewToModelChange: true});
        this.control.control.markAsTouched();
      }
      return '';
    } else if (value.slice(-1) === '.') {
      return value;
    } else if (value.includes('-')) {
      const minus = value.split('-');
      if (minus[0]) {
        const valueChange = value.toString().replace(/-/g, '');
        return valueChange;
      } else {
        if (minus[1] === '0') {
          return 0;
        }
        const arr = minus[1].split('.');
        const decimal = this.decimalPipe.transform(this.prepareNumberToFormatter(arr[0]), `1.0-0`);
        let result;
        if (this.decimalDigits > 0 && arr.length > 1) {
          result = `-${decimal}.${arr[1].substr(0, this.decimalDigits)}`;
        } else {
          result = `-${decimal}`;
        }
        let realValue = result === null ? null : this.prepareNumberToFormatter(result);
        realValue = `-${realValue}`;
        if (this.control.control) {
          this.control.control.setValue(realValue, {emitViewToModelChange: true});
        }
        return result;
      }
    } else {
      const arr = value.split('.');
      const decimal = this.decimalPipe.transform(this.prepareNumberToFormatter(arr[0]), `1.0-0`);
      let result;
      if (this.decimalDigits > 0 && arr.length > 1) {
        result = `${decimal}.${arr[1].substr(0, this.decimalDigits)}`;
      } else {
        result = decimal;
      }
      const realValue = result === null ? null : this.prepareNumberToFormatter(result);
      if (this.control.control) {
        this.control.control.setValue(realValue, {emitViewToModelChange: true});
      }

      return result;
    }
  }

  private prepareNumberToFormatter(value) {
    const newVal = this.clearDelimitersAndLeadingZeros(value);
    if (newVal === '-') {
      return newVal;
    } else {
      return parseFloat(newVal);
    }
  }

  ngOnChanges(): void {
    setTimeout(() => {
      if (this.control.control) {
        this.setInputValue(this.control.control.value);
      }
    });
  }

  ngOnDestroy(): void {
    this.input.parentNode.removeChild(this.input);
    if (this.ob) {
      this.ob.unsubscribe();
    }
  }
}
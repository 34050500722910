import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { cloneDeep } from 'lodash';
import {
  TIME_SETTINGS_TIME_OPTIONS, TIME_SETTING_TYPE, DEFAULT_LAST_MIN_FILTER, TREATMENT_MONITOR_NUMBER, DEFAULT_MONITOR_LAST_MIN_FILTER
} from '../../../helpers/app.constants';
import { Helper } from '../../../helpers/helper';
import { ITimeSetting } from '../../../models/time-setting.model';
import { ITreatmentItem } from '../../../models/treatment.model';
import { ITimeRangeFilter } from '../../../services/charts/base-chart.service';


@Component({
  selector: 'app-time-settings',
  templateUrl: './time-settings.component.html',
  styleUrls: ['./time-settings.component.scss']
})
export class TimeSettingsComponent implements OnInit {
  public mask = [/\d/, /\d/, ':', /\d/, /\d/];

  TIME_SETTING_TYPE = TIME_SETTING_TYPE;
  originTimeRange: ITimeRangeFilter;
  timeRangeFilterValues: ITimeRangeFilter;
  listType: any[] = [TIME_SETTING_TYPE.OffsetTime, TIME_SETTING_TYPE.EpochTime];
  form: FormGroup;
  type: AbstractControl;
  autoScaleTime: AbstractControl;
  startTime: AbstractControl;
  endTime: AbstractControl;
  commentType: AbstractControl;
  lastMin: AbstractControl;
  startTimeFloat: AbstractControl;
  endTimeFloat: AbstractControl;

  modelData: { type: string; startIndex: number; endIndex: number };

  @Input() compareChannel: boolean = false;
  @Input()
  set timeRangeFilter(time: ITimeRangeFilter) {
    this.originTimeRange = cloneDeep(time);
    if (time) {
      this.originTimeRange.timestamp.start = Helper.floorTimestamp(time.timestamp.start);
      this.originTimeRange.timestamp.end = Helper.ceilTimestamp(time.timestamp.end);
      if (this.modelData) {
        if (this.modelData.type && this.modelData.type.toLowerCase() === TIME_SETTING_TYPE.OffsetTimeLower) {
          time.timefloat.start = this.modelData.startIndex;
          time.timefloat.end = this.modelData.endIndex;
        } else {
          time.timestamp.start = this.modelData.startIndex;
          time.timestamp.end = this.modelData.endIndex;
        }
      }
    }
    this.timeRangeFilterValues = time;
  }

  @Input()
  set model(param: ITimeSetting) {
    if (param) {
      this.modelData = param;
      this.type.setValue(param.type);
      const isFloatFormat = param.type && param.type.toLowerCase() === TIME_SETTING_TYPE.OffsetTimeLower;
      if (!isFloatFormat) {
        let startValue = null;
        if (!this.wellItem || !this.wellItem.tzTimeZone) {
          startValue = Helper.floorTimestampToString(param.startIndex);
        } else {
          const startIndexSec = Math.round(param.startIndex / 1000);
          startValue = Helper.floorTimestampToString(startIndexSec, this.wellItem.tzTimeZone);
        }

        let endValue = null;
        if (!this.wellItem || !this.wellItem.tzTimeZone) {
          endValue = Helper.ceilTimestampToString(param.endIndex);
        } else {
          const endIndexSec = Math.round(param.endIndex / 1000);
          endValue = Helper.ceilTimestampToString(endIndexSec, this.wellItem.tzTimeZone);
        }

        this.startTime.setValue(startValue);
        this.endTime.setValue(endValue);
      } else {
        const startTime = Helper.convertSecToMin(param.startIndex);
        const endTime = Helper.convertSecToMin(param.endIndex);
        this.startTimeFloat.setValue(Math.floor(startTime));
        this.endTimeFloat.setValue(Math.ceil(endTime));
      }
    }
  }

  @Input() treatmentItem: ITreatmentItem = null;
  @Input() wellItem = null;
  @Input() realTime: boolean = false;

  @Input()
  set lastMinFilter(lastMin: number) {
    if (lastMin === 0) this.lastMin.setValue('All');
    else if (!lastMin) this.lastMin.setValue(this.lastMinOptionsDefault);
    else {
      const optSelected = (value: number, values: number[], options: string[], defaultLabel: string, isRealtime: boolean) => {
        let i = values.findIndex(x => x === value);
        if (i > -1) return options[i];

        if (!!this.realTime) {
          i = TIME_SETTINGS_TIME_OPTIONS.realtimeExtends.findIndex(x => x === value);
          if (i > -1) return TIME_SETTINGS_TIME_OPTIONS.realtimeExtendsLabel[i];
        }
        return defaultLabel;
      }
      let result: string;
      if (this.treatmentItem && this.treatmentItem.number === TREATMENT_MONITOR_NUMBER) {
        result = optSelected(lastMin, TIME_SETTINGS_TIME_OPTIONS.monitor, TIME_SETTINGS_TIME_OPTIONS.monitorLabel, TIME_SETTINGS_TIME_OPTIONS.defaultMonitor, this.realTime);
      } else {
        result = optSelected(lastMin, TIME_SETTINGS_TIME_OPTIONS.treatment, TIME_SETTINGS_TIME_OPTIONS.treatmentLabel, TIME_SETTINGS_TIME_OPTIONS.defaultTreatment, this.realTime);
      }
      this.lastMin.setValue(result);
    }
  }

  @Input()
  set autoScale(auto: boolean) {
    this.autoScaleTime.setValue(auto);
  }

  @Input() showCommentOption: boolean = false;
  @Input() disableCommentOption: boolean = false;
  @Input() listCommentsOptions: boolean = false;
  @Input()
  set selectedCommentType(value: string) {
    if (value) {
      this.commentType.setValue(value);
    }
  }

  constructor(
    protected dialogRef: NbDialogRef<any>,
  ) {
    this.createForm();
  }

  ngOnInit() {
    if (this.compareChannel) {
      // Set Validators
      this.startTime.setValidators([]);
      this.endTime.setValidators([]);
      // Reset Form
      this.startTime.reset();
      this.endTime.reset();
    }
    if (this.realTime && this.autoScaleTime.value) {
      this.autoTimeChange();
    }
  }

  close() {
    this.dialogRef.close();
  }

  createForm() {
    this.form = new FormGroup({
      type: new FormControl(TIME_SETTING_TYPE.OffsetTime, [Validators.required]),
      commentType: new FormControl('List', [Validators.required]),
      autoScaleTime: new FormControl(true),
      startTime: new FormControl('', []),
      endTime: new FormControl('', []),
      startTimeFloat: new FormControl(null, []),
      endTimeFloat: new FormControl(null, []),
      lastMin: new FormControl('', [])
    }, [/*NumberValidator.compareNumber('startTime', 'endTime')*/]);
    this.type = this.form.controls['type'];
    this.autoScaleTime = this.form.controls['autoScaleTime'];
    this.startTime = this.form.controls['startTime'];
    this.endTime = this.form.controls['endTime'];
    this.startTimeFloat = this.form.controls['startTimeFloat'];
    this.endTimeFloat = this.form.controls['endTimeFloat'];
    this.commentType = this.form.controls['commentType'];
    this.lastMin = this.form.controls['lastMin'];
  }

  submitForm() {
    const values = cloneDeep(this.form.value);
    if (!this.compareChannel) {
      // map lastMin
      values.lastMin = this.mapSelectedLastMin(values.lastMin);
      // map startTime and endTime
      if (this.type.value === TIME_SETTING_TYPE.EpochTime || this.type.value === TIME_SETTING_TYPE.EpochTimeLowerCase) {
        // get timestamp default
        let timestampStart: number;
        let timestampEnd: number;
        if (this.originTimeRange && this.originTimeRange.timestamp) {
          timestampStart = this.originTimeRange.timestamp.start;
          timestampEnd = this.originTimeRange.timestamp.end;
        }
        // set value
        if (!this.wellItem || !this.wellItem.tzTimeZone) {
          values.startTime = values.startTime ? Helper.mapDateTimeString(values.startTime, 0) :
            Helper.toTimeString(timestampStart);
          values.endTime = values.endTime ? Helper.mapDateTimeString(values.endTime, 0) :
            Helper.toTimeString(timestampEnd);
        } else {
          values.startTime = values.startTime ?
            Helper.mapDateTimeUTCString(values.startTime, this.wellItem.tzTimeZone) :
            Helper.toTimeString(timestampStart);
          values.endTime = values.endTime ?
            Helper.mapDateTimeUTCString(values.endTime, this.wellItem.tzTimeZone) :
            Helper.toTimeString(timestampEnd);
        }
      }
      // map startTimeFloat and endTimeFloat
      if (this.type.value === TIME_SETTING_TYPE.OffsetTime) {
        // get timefloat default
        let timefloatStart: number;
        let timefloatEnd: number;
        if (this.originTimeRange && this.originTimeRange.timefloat) {
          timefloatStart = this.originTimeRange.timefloat.start;
          timefloatEnd = this.originTimeRange.timefloat.end;
        }
        // set value
        if (values.startTimeFloat === '' || values.startTimeFloat === null) values.startTimeFloat = timefloatStart;
        if (values.endTimeFloat === '' || values.startTimeFloat === null) values.endTimeFloat = timefloatEnd;
      }
    }
    this.dialogRef.close(values);
  }

  get lastMinOptionsDefault(): string {
    if (this.treatmentItem && this.treatmentItem.number === TREATMENT_MONITOR_NUMBER) {
      return TIME_SETTINGS_TIME_OPTIONS.defaultMonitor;
    } else {
      return TIME_SETTINGS_TIME_OPTIONS.defaultTreatment;
    }
  }

  get lastMinOptions(): string[] {
    if (this.lastMin.value === '') {
      this.lastMin.setValue(this.lastMinOptionsDefault);
    }
    const handleListOptions = (list, realtime) => {
      if (!list || !realtime) return list;
      return [list[0]].concat(TIME_SETTINGS_TIME_OPTIONS.realtimeExtendsLabel).concat(list.filter(item => item !== 'All'));
    };
    if (this.treatmentItem && this.treatmentItem.number === TREATMENT_MONITOR_NUMBER) {
      return handleListOptions(TIME_SETTINGS_TIME_OPTIONS.monitorLabel, this.realTime);
    } else {
      return handleListOptions(TIME_SETTINGS_TIME_OPTIONS.treatmentLabel, this.realTime);
    }
  }

  private lastMinMapped = (value: string, labels: string[], values: number[], defaultVal: number, isRealtime: boolean) => {
    let index = labels.findIndex(x => x === value);
    if (index > -1) return values[index];
    if (!!isRealtime) {
      index = TIME_SETTINGS_TIME_OPTIONS.realtimeExtendsLabel.findIndex(x => x === value);
      if (index > -1) return TIME_SETTINGS_TIME_OPTIONS.realtimeExtends[index];
    }
    return defaultVal;
  }

  mapSelectedLastMin(value): number {
    if (value === 'All') return 0;
    if (this.treatmentItem && this.treatmentItem.number === TREATMENT_MONITOR_NUMBER) {
      return this.lastMinMapped(value, TIME_SETTINGS_TIME_OPTIONS.monitorLabel, TIME_SETTINGS_TIME_OPTIONS.monitor, DEFAULT_MONITOR_LAST_MIN_FILTER, this.realTime);
    } else {
      return this.lastMinMapped(value, TIME_SETTINGS_TIME_OPTIONS.treatmentLabel, TIME_SETTINGS_TIME_OPTIONS.treatment, DEFAULT_LAST_MIN_FILTER, this.realTime);
    }
  }

  selectedType(e: any) {
    if (this.type.value === e) return;
    this.type.setValue(e);

    if (this.compareChannel) return;
    // if set to EpochTime: get the OffsetTime startTimeFloat and endTimeFloat value
    if (this.type.value === TIME_SETTING_TYPE.EpochTime) {
      // if startTime or endTime is blank (exclude 0): use the originTimeRange.timefloat
      const mapTimeFloat = (data: string | number, originData: number): number => data === '' ? originData : Helper.convertMinToSec(data);
      if (this.timeRangeFilterValues && this.timeRangeFilterValues.timefloat && this.originTimeRange && this.originTimeRange.timefloat) {
        this.timeRangeFilterValues.timefloat.start = mapTimeFloat(this.startTimeFloat.value, this.originTimeRange.timefloat.start);
        this.timeRangeFilterValues.timefloat.end = mapTimeFloat(this.endTimeFloat.value, this.originTimeRange.timefloat.end);
      }
    }
    // else: get the EpochTime startTime and endTime value
    else {
      // if startTime or endTime is blank (exclude 0): use the originTimeRange.timestamp
      const mapTimestamp = (data: string, originData: number) => data === '' ? originData : Helper.mapDateTimeTimestamp(data);
      if (this.timeRangeFilterValues && this.timeRangeFilterValues.timestamp && this.originTimeRange && this.originTimeRange.timestamp) {
        this.timeRangeFilterValues.timestamp.start = mapTimestamp(this.startTime.value, this.originTimeRange.timestamp.start);
        this.timeRangeFilterValues.timestamp.end = mapTimestamp(this.endTime.value, this.originTimeRange.timestamp.end);
      }
    }
    this.setTimeRangeFilter(this.type.value, this.timeRangeFilterValues);

  }

  setTimeRangeFilter(timeFormat: string, timeRangeFilterValues: ITimeRangeFilter) {
    if (!timeRangeFilterValues) return;

    const getMismatchTimefloat = (timefloat, originTimestamp) => {
      if (!this.wellItem || !this.wellItem.tzTimeZone) return 0;
      const a = Helper.getTimezoneOffset(timefloat + originTimestamp, this.wellItem.tzTimeZone);
      const b = Helper.getTimezoneOffset(originTimestamp, this.wellItem.tzTimeZone);
      return a - b;
    };

    if (timeFormat === TIME_SETTING_TYPE.EpochTime) {
      const mapTimestamp = (data: number, originData: number): string => {
        if (this.wellItem && this.wellItem.tzTimeZone) data = Helper.addTimezoneTime(originData, data, this.wellItem.tzTimeZone);
        return Helper.toDateTimeString(data + originData);
      };

      const startTime = mapTimestamp(timeRangeFilterValues.timefloat.start * 1000, this.originTimeRange.timestamp.start);
      const endTime = mapTimestamp(timeRangeFilterValues.timefloat.end * 1000, this.originTimeRange.timestamp.start);
      this.startTime.setValue(startTime);
      this.endTime.setValue(endTime);
    } else {
      const mapTimeFloat = (data: number, originData: number): number => {
        if (this.wellItem && this.wellItem.tzTimeZone) data = Helper.removeTimezoneTime(data, this.wellItem.tzTimeZone);
        const time = Helper.convertSecToMin((data - originData) / 1000);
        const mismatch = getMismatchTimefloat(time * 1000, originData);
        return time - mismatch;
      };

      const startTime = mapTimeFloat(timeRangeFilterValues.timestamp.start, this.originTimeRange.timestamp.start);
      const endTime = mapTimeFloat(timeRangeFilterValues.timestamp.end, this.originTimeRange.timestamp.start);

      this.startTimeFloat.setValue(Math.floor(startTime));
      this.endTimeFloat.setValue(Math.ceil(endTime));
    }
  }

  autoTimeChange(e?: any) {
    // if (this.autoScaleTime.value) {
    //   // this.startTime.setValue(null);
    //   // this.endTime.setValue(null);
    //   this.setTimeRangeFilter(this.type.value, this.originTimeRange);
    // } else {
    //   this.setTimeRangeFilter(this.type.value, this.timeRangeFilterValues);
    // }
  }

  selectedCommentOption(eventData: string) {
    if (eventData) {
      this.commentType.setValue(eventData);
    }
  }

  onChangeLastMin(eventData) {
    if (!eventData || !eventData.id) return;
    this.lastMin.setValue(eventData.id);
  }
}

import { User } from './../../models/user.model';
import { UserAuthService } from './../../services/user-auth.service';
import { SearchPadPadOptionsStorageService } from './../../services/storage/search-pad-options-storage.service';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { SearchPadListControlService } from './shared/services/search-pad-list-control.service';
import { SearchPadListService } from './shared/services/search-pad-list.service';
import { SearchPadListBase } from './shared/models/search-pad-list-base';
import { isEmpty } from 'lodash';
import { PAD_SEARCH_OPTIONS } from '../../helpers/app.constants';

@Component({
  selector: 'app-search-pad-list',
  templateUrl: './search-pad-list.component.html',
  styleUrls: ['./search-pad-list.component.scss']
})
export class SearchPadListComponent implements OnInit, OnDestroy {
  destroy$: Subject<any> = new Subject();
  form: FormGroup;
  country: AbstractControl;
  state: AbstractControl;
  county: AbstractControl;
  serviceCompanyId: AbstractControl;
  operatorId: AbstractControl;
  prospect: AbstractControl;
  pad: AbstractControl;
  formation: AbstractControl;

  @Output() searchChange: EventEmitter<any> = new EventEmitter();

  listContries$: Observable<any[]>;
  listStates$: Observable<any[]>;
  listConties$: Observable<any[]>;
  listPads$: Observable<any[]>;
  listFormations$: Observable<any[]>;
  listServiceCompanies$: Observable<any[]>;
  listOperatorCompanies$: Observable<any[]>;
  defaultOptions: any[] = PAD_SEARCH_OPTIONS;
  selectedOptions: any[] = [];
  listOptions: SearchPadListBase<any>[] = [];
  listOptionsStorage: object = {};

  user: User;
  userId: number;
  isCarbo: boolean = false;
  isService: boolean = false;
  isOperator: boolean = false;

  @Input() selectedSearchParams;

  constructor(
    private userAuthService: UserAuthService,
    private searchPadListControlService: SearchPadListControlService,
    private searchPadListService: SearchPadListService,
    private searchPadPadOptionsStorageService: SearchPadPadOptionsStorageService
  ) {
    this.user = this.userAuthService.getUserInfo();
    if (this.user) {
      if (this.user.companyType === 'Carbo') {
        this.isCarbo = true;
        this.isService = false;
        this.isOperator = false;
      } else if (this.user.companyType === 'Service') {
        this.isCarbo = false;
        this.isService = true;
        this.isOperator = false;
      } else if (this.user.companyType === 'Operator') {
        this.isCarbo = false;
        this.isService = false;
        this.isOperator = true;
      }
      this.userId = this.user.userId;
    }
    this.getListOptionsStorage();
  }

  get filterlistOptions() {
    const filterOptions = (list: any[], selected: any[], isNumber?: boolean) => {
      const res: any[] = [];
      for (const item of list) {
        if (!item) res.push(null);
        const find = isNumber ? selected.find(x => x === item.id) : selected.find(x => x === item);
        if (!find) continue;
        res.push(item);
      }
      return res;
    }

    const mapOptions = (list: any[], selected: any[], label: string, isNumber?: boolean) => {
      if (selected && selected.length) {
        const findIndex = list.findIndex(x => x.label === label)
        if (findIndex >= 0) list[findIndex].options = filterOptions(list[findIndex].options, selected, isNumber);
      }
      return list;
    }

    let list = this.listOptions;
    list = mapOptions(list, this.selectedSearchParams.countries, 'Country', false);
    list = mapOptions(list, this.selectedSearchParams.counties, 'County', false);
    list = mapOptions(list, this.selectedSearchParams.states, 'State', false);
    list = mapOptions(list, this.selectedSearchParams.formations, 'Formation', false);
    list = mapOptions(list, this.selectedSearchParams.services, 'Service', true);
    list = mapOptions(list, this.selectedSearchParams.operators, 'Operator', true);
    return list;
  }

  private getListOptionsStorage(): void {
    this.listOptionsStorage = this.searchPadPadOptionsStorageService.getListOptions(this.userId);
  }

  private setListOptionsStorage(): void {
    this.searchPadPadOptionsStorageService.setListOptions(this.listOptionsStorage, this.userId);
  }

  private removeListOptionsStorage(): void {
    this.listOptionsStorage = {};
    this.searchPadPadOptionsStorageService.removeListOptions();
  }

  ngOnInit(): void {
    this.selectedOptions = this.defaultOptions;
    this.getSearchPadListOptions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private getSearchPadListOptions(): void {
    let paramOptions: { id: any; name: string }[];
    if (!this.listOptionsStorage) {
      this.listOptionsStorage = {};
      paramOptions = this.selectedOptions;
    } else {
      paramOptions = this.selectedOptions.filter(item => {
        return this.listOptionsStorage[item.id] === null || this.listOptionsStorage[item.id] === undefined;
      });
    }
    if (paramOptions && paramOptions.length > 0) {
      this.searchPadListService.getSearchPadListOptions(paramOptions)
        .subscribe(
          options => {
            paramOptions.forEach(opt => {
              const option = options ? options.find(item => item.key === opt.id) : null;
              if (option) {
                this.listOptionsStorage[option.key] = option;
              }
            });
            this.setListOptionsStorage();
            this.createForm();
          }
        );
    } else {
      this.createForm();
    }
  }

  private createForm(): void {
    const options: any[] = [];
    this.selectedOptions.forEach(item => {
      if (this.listOptionsStorage[item.id]) {
        if (this.listOptionsStorage[item.id].key === 'serviceCompanyId') {
          if (this.isCarbo || this.isOperator) {
            options.push(this.listOptionsStorage[item.id]);
          }
        } else if (this.listOptionsStorage[item.id].key === 'operatorId') {
          if (this.isCarbo || this.isService) {
            options.push(this.listOptionsStorage[item.id]);
          }
        } else {
          options.push(this.listOptionsStorage[item.id]);
        }
      }
    });
    this.listOptions = options;
    if (!this.form) {
      this.form = this.searchPadListControlService.toFormGroup(options);
    }
  }

  onSearch(): void {
    const formData = this.form.value;
    if (formData && !isEmpty(formData.state)) {
      formData.state = formData.state.map(item => item.id).join(',');
      if (formData.state === "") formData.state = ",";
    }
    if (formData && !isEmpty(formData.county)) {
      formData.county = formData.county.map(item => item.id).join(',');
      if (formData.county === "") formData.county = ",";
    }
    if (formData && !isEmpty(formData.pad)) {
      formData.pad = formData.pad.map(item => item.id).join(',');
      if (formData.pad === "") formData.pad = ",";
    }
    if (formData && !isEmpty(formData.formation)) {
      formData.formation = formData.formation.map(item => item.id).join(',');
      if (formData.formation === "") formData.formation = ",";
    }
    if (formData && !isEmpty(formData.operatorId)) {
      formData.operatorId = formData.operatorId.map(item => item.id).join(',');
      if (formData.operatorId === "") formData.operatorId = ",";
    }
    if (formData && !isEmpty(formData.serviceId)) {
      formData.serviceId = formData.serviceId.map(item => item.id).join(',');
      if (formData.serviceId === "") formData.serviceId = ",";
    }
    this.searchChange.emit(formData);
  }

  reset(): void {
    this.form.reset();
    this.removeListOptionsStorage();
    this.searchChange.emit(this.form.value);
    this.getSearchPadListOptions();
  }

}

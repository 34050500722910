import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { AppInitService } from './app-init.service';
// import {  MsalInterceptor } from '@azure/msal-angular';
import { b2cPolicies, msalConfig } from './auth.config';
// import { IPublicClientApplication, PublicClientApplication, InteractionType } from 'msal';
import { MsalGuard, BroadcastService, MsalService, MsalAngularConfiguration, MsalModule } from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export const protectedResourceMap: [string, string[]][] = [
  ['"https://devlinqxfracpro.onmicrosoft.com/api_26m/scope_rw_26m"', ['user.read']]
];
export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  }
}
// export function MSALInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication(msalConfig);
// }
// export function MSALGuardConfigFactory(): MsalAngularConfiguration {
//   return {
//     authRequest: loginRequest
//   };
// }

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MsalModule.forRoot({
      auth: {
        clientId: msalConfig.auth.clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        validateAuthority: false,
        redirectUri: msalConfig.auth.redirectUri,
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true, // set to true for IE 11
      },
    },
    {
      popUp: !isIE,
      consentScopes: [
        "user.read",
        "openid",
        "profile",
        "api://a88bb933-319c-41b5-9f04-eff36d985612/access_as_user"
      ],
      unprotectedResources: ["https://www.microsoft.com/en-us/"],
      protectedResourceMap,
      extraQueryParameters: {}
    }
  ),
    CoreModule.forRoot(),
    SharedModule.forRoot(),
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { DefaultEditor } from 'ng2-smart-table';

@Component({
  selector: 'app-edit-custom-channels-select',
  templateUrl: './edit-custom-channels-select.component.html',
  styleUrls: []
})
export class EditCustomChannelsSelectComponent extends DefaultEditor implements OnInit {
  selected: any = null;
  items = [];

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.cell.getColumn().getConfig()) {
      this.items = this.cell.getColumn().getConfig().list;
    }
    if (this.cell.newValue) {
      this.selected = this.cell.newValue.cName;
    }
  }

  onChange(selected) {
    this.cell.newValue = this.items.find(item => item.cName === selected);
    this.items = this.items.filter(item => item.cName !== selected);
  }

}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError, finalize, timeout } from 'rxjs/operators';

export interface IAPIServiceOptions {
  overrideBaseUrl?: boolean;
  requestTimeout?: number;
}

const REQUEST_TIMEOUT = 90000; // 1m30s

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
  ) {}

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  private getRequestUrl(options: IAPIServiceOptions, path: string) {
    const opts = typeof options === 'object' && options !== null ? options : {};
    let requestUrl = `${environment.api_url}${path}`;
    if (opts.overrideBaseUrl) {
      requestUrl = path;
    }
    return requestUrl;
  }

  get(path: string, params: HttpParams = new HttpParams(), options?: IAPIServiceOptions): Observable<any> {
    const requestUrl = this.getRequestUrl(options, path);
    const requestTimeout = options && options.requestTimeout ? options.requestTimeout : REQUEST_TIMEOUT;

    return this.http.get(requestUrl, { params })
      .pipe(timeout(requestTimeout))
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, reqBody: any, finalizeHandler?: VoidFunction, options?: IAPIServiceOptions, params?: HttpParams): Observable<any> {
    const requestUrl = this.getRequestUrl(options, path);

    return this.http.put(
      requestUrl,
      reqBody,
      { params }
    ).pipe(catchError(this.formatErrors))
    .pipe(finalize(() => {
      if (typeof finalizeHandler === 'function') {
        finalizeHandler();
      }
    }));
  }

  post(path: string, reqBody?: any, finalizeHandler?: VoidFunction, options?: IAPIServiceOptions, params?: HttpParams): Observable<any> {
    const requestUrl = this.getRequestUrl(options, path);
    const requestTimeout = options && options.requestTimeout ? options.requestTimeout : REQUEST_TIMEOUT;

    return this.http.post(
      requestUrl,
      reqBody,
      { params }
    )
    .pipe(timeout(requestTimeout))
    .pipe(catchError(this.formatErrors))
    .pipe(finalize(() => {
      if (typeof finalizeHandler === 'function') {
        finalizeHandler();
      }
    }));
  }

  patch(path: string, params: HttpParams = new HttpParams(), finalizeHandler?: VoidFunction, options?: IAPIServiceOptions): Observable<any> {
    const requestUrl = this.getRequestUrl(options, path);

    return this.http.patch(
      requestUrl,
      params
    ).pipe(catchError(this.formatErrors))
    .pipe(finalize(() => {
      if (typeof finalizeHandler === 'function') {
        finalizeHandler();
      }
    }));
  }

  delete(path: string, finalizeHandler?: VoidFunction, options?: IAPIServiceOptions): Observable<any> {
    const requestUrl = this.getRequestUrl(options, path);

    return this.http.delete(
      requestUrl,
    ).pipe(catchError(this.formatErrors))
    .pipe(finalize(() => {
      if (typeof finalizeHandler === 'function') {
        finalizeHandler();
      }
    }));
  }

  deleteParam(path: string, params: HttpParams = new HttpParams(), finalizeHandler?: VoidFunction, options?: IAPIServiceOptions): Observable<any> {
    const requestUrl = this.getRequestUrl(options, path);

    return this.http.delete(
      requestUrl, { params }
    ).pipe(catchError(this.formatErrors))
      .pipe(finalize(() => {
        if (typeof finalizeHandler === 'function') {
          finalizeHandler();
        }
      }));
  }

  postFile(path: string, params: any, httpOptions?, finalizeHandler?: VoidFunction, options?: IAPIServiceOptions): Observable<any> {
    const requestUrl = this.getRequestUrl(options, path);

    return this.http.post(
      requestUrl,
      params,
      httpOptions
    ).pipe(catchError(this.formatErrors))
    .pipe(finalize(() => {
      if (typeof finalizeHandler === 'function') {
        finalizeHandler();
      }
    }));
  }
}

import { ToasterService } from './../../../shared/services/toaster.service';
import { SortEvent, SortableTableHeaderDirective } from './../../../shared/directives/sortable-table-header.directive';
import { cloneDeep, remove, countBy, some, isEmpty } from 'lodash';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChildren, QueryList, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pad-list-table',
  templateUrl: './pad-list-table.component.html',
  styleUrls: ['./pad-list-table.component.scss']
})
export class PadListTableComponent implements OnInit, OnChanges {
  listPads: any[] = [];
  listWellFull: any[] = [];
  tableState = {
    selectedAll: false
  };
  @Input() maxItemList: number;
  @Input() isCarboAdmin: boolean = false;
  @Input() isService: boolean = false;
  @Input() isOperator: boolean = false;
  @Input() isHideCheck: boolean = false;
  @Input()
  set itemList(listPads) {
    this.listPads = listPads;
    if (!this.isCarboAdmin) {
      const listPadsFake = cloneDeep(this.listPads);
      remove(listPadsFake, (item: any) => {
        return (item.treatmentList && item.treatmentList.length === 0);
      });
      this.checkAllSelect(listPadsFake);
    } else {
      this.checkAllSelect(this.listPads);
    }
  }

  @Output() rowSelect: EventEmitter<any> = new EventEmitter();
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();
  @Output() sortChange: EventEmitter<SortEvent> = new EventEmitter();

  @ViewChildren(SortableTableHeaderDirective) headers: QueryList<SortableTableHeaderDirective>;

  constructor(private toasterService: ToasterService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listPads && !changes.listPads.isFirstChange()) {
      this.listPads = changes.listPads.currentValue;
    }
  }

  toggleClickPad(event, check: boolean) {
    if (!check) {
      const countSelected = countBy(this.listWellFull, 'selected').true || 0;
      if (countSelected > 100) {
        event.preventDefault();
        event.stopPropagation();
        this.toasterService.showError('Wells must be choose at less than or equal to 100 with compare multiple well');
      }
    }
  }

  toggleSelectPad(event, well) {
    event.preventDefault();
    if (well) {
      well.selected = !well.selected;
      if (!this.isCarboAdmin) {
        const listPadsFake = cloneDeep(this.listPads);
        remove(listPadsFake, (item: any) => {
          return (item.treatmentList && item.treatmentList.length === 0);
        });
        this.checkAllSelect(listPadsFake);
      } else {
        this.checkAllSelect(this.listPads);
      }
      if (some(this.listWellFull, {wellId: well.wellId})) {
        this.removeWellOnList(well);
      } else {
        this.listWellFull.push(well);
      }
      const selectedWells = this.listWellFull;
      this.selectedChange.emit({ selected: [...selectedWells] });
    }
  }

  toggleClickAll(event , check: boolean) {
    if (!check) {
      const count = this.listWellFull.length;
      if (count > 100) {
        event.preventDefault();
        event.stopPropagation();
        this.toasterService.showError('Wells must be choose at less than or equal to 100 with compare multiple well');
      }
    }
  }

  toggleSelectAll(event) {
    event.preventDefault();
    this.tableState.selectedAll = !this.tableState.selectedAll;
    setTimeout(() => {
      this.setSelectedWells(this.tableState.selectedAll);
      this.selectedChange.emit({ selected: [...this.listWellFull] });
    });
  }

  setSelectedWells(value) {
    if (this.listPads && this.listPads.length > 0) {
      this.listPads.forEach(well => {
        if (well.treatmentList && well.treatmentList.length > 0 || this.isCarboAdmin) {
          well.selected = value;
          if (value) {
            if (!some(this.listWellFull, well)) {
              this.listWellFull.push(well);
            }
          } else {
            this.removeWellOnList(well);
          }
        } else {
          well.selected = false;
        }
      });
    }
  }

  removeWellOnList(well) {
    remove(this.listWellFull, item => {
      return item.wellId === well.wellId;
    });
  }

  clickRowSelect(e: any) {
    this.rowSelect.emit(e);
  }

  removeAllSelected() {
    this.tableState.selectedAll = false;
    this.listWellFull = [];
  }

  checkAllSelect(wellList: any[]) {
    const countSelected = countBy(wellList, 'selected').true || 0;
    if (isEmpty(wellList) || countSelected !== wellList.length) {
      this.tableState.selectedAll = false;
    } else {
      this.tableState.selectedAll = true;
    }
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.sortChange.emit({column, direction});
  }

}

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, OnInit } from '@angular/core';
import { SelectItem } from './select-item';
import { compact, forEach, orderBy, findIndex, cloneDeep } from 'lodash';
import { ToasterService } from '../../services/toaster.service';
import { fromEvent, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-ng-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnDestroy {
  destroy$: Subject<any> = new Subject();
  inputMode: boolean = false;
  optionsOpened: boolean = false;

  public options: Array<SelectItem> = [];
  public itemObjects: Array<SelectItem> = [];
  private _active: Array<SelectItem> = [];
  private _disabled: boolean = false;
  public activeOption: SelectItem = new SelectItem('object');

  public itemEmpty: SelectItem = new SelectItem('');

  searchVal: string = '';
  maxValue: number = 50;
  lazyOptions: any;

  @ViewChild('searchInput') searchElement: ElementRef;

  @Input() public title: string;
  @Input() public maxShowedItem: boolean = false;
  @Input() public idField: string = 'id';
  @Input() public textField: string = 'text';
  @Input() public childrenField: string = 'children';
  @Input() public allowEmpty: boolean = true;
  @Input() public searchField: boolean = true;
  @Input() public placeholder: string = '';
  @Input() public multiple: boolean = false;
  @Input() public autoClose: boolean = true;
  @Input() public maxItems: number;
  @Input() public itemName: string = 'Channel Name';
  @Input() public chooseAll: boolean = false;
  @Input() public addNewItem: boolean = false;
  @Input() public isFullWidth: boolean = false;
  @Input() public sortSelect: boolean = true;
  @Input() public nestedTag: boolean;
  @Input() public resetBtnLabel: string = 'Reset Channel';
  @Input() public isResetBtn: boolean = false;
  @Input() public disabledResetBtn: boolean = false;
  @Input() public onlyIcon: boolean = false;
  @Input() public isIcon: boolean = false;
  @Input() public iconField: string = 'icon';
  @Input()
  set item(value: Array<any>) {
    if (!value) {
      this.itemObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.itemObjects = items.map((item: any) => {
        if (typeof item === 'string') return new SelectItem(item);
        else return new SelectItem({
          id: item[this.idField],
          text: item[this.textField],
          children: item[this.childrenField],
          icon: item[this.iconField]
        });
      });
      if (this.sortSelect) {
        this.itemObjects = orderBy(this.itemObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  public set active(selectedItems: Array<any>) {
    if (!selectedItems || selectedItems.length === 0) {
      this._active = [];
      this.activeOption = new SelectItem('');
    } else {
      selectedItems = compact(selectedItems);

      const areItemsStrings = typeof selectedItems[0] === 'string';
      this._active = selectedItems.map((item: any) => {
        const data = areItemsStrings ? item : {id: item[this.idField], text: item[this.textField], icon: item[this.iconField]};
        this.activeOption = new SelectItem(data);
        return new SelectItem(data);
      });
    }
  }
  public get active(): Array<any> {
    return this._active;
  }

  @Input()
  fcActive: FormControl = new FormControl([]);

  @Input()
  public set fakeOpen(open: boolean) {
    if (!!open) {
      this.open();
    } else {
      this.clickOutside();
    }
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
    this.ref.detectChanges();
    if (this._disabled === true) {
      this.clickOutside();
    }
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Output() public selected: EventEmitter<any> = new EventEmitter();
  @Output() public selectedAll: EventEmitter<any> = new EventEmitter();
  @Output() public removed: EventEmitter<any> = new EventEmitter();
  @Output() public removedAll: EventEmitter<any> = new EventEmitter();
  @Output() public addNew: EventEmitter<any> = new EventEmitter();
  @Output() public resetChannelEmit: EventEmitter<any> = new EventEmitter();
  constructor(private toasterService: ToasterService,
              private element: ElementRef,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.fcActive.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      // unset active item
      if (!value) {
        this.active = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    this.ref.detach();
  }

  protected isActive(value: SelectItem): boolean {
    if (!value) {
      return false;
    }
    let isActive = this.activeOption && this.activeOption.id === value.id;
    if (!!this.multiple) isActive = isActive || this.active.findIndex(item => item.id === value.id) !== -1;
    return isActive;
  }
  protected matchClick(e: any) {
    if (this._disabled === true) {
      return;
    }
    this.inputMode = !this.inputMode;
    if (!!this.inputMode) {
      this.open();
    } else {
      this.clickOutside();
    }
  }
  open() {
    this.options = this.itemObjects.filter((item: any) => !this.multiple || !this.autoClose || (!!this.multiple && this.active.findIndex((o: SelectItem) => item.id === o.id) === -1));
    this.optionsOpened = true;
    this.lazyOptions = this.options.slice(0, 50);
    this.ref.detectChanges();
    if (this.options && this.options.length > 0) {
      const content = document.querySelector('.ul-select-choice');
      const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));
      const lazyContent = this.element.nativeElement.querySelector('.ul-select-choice');
      if (!this.multiple) {
        const indexItem = this.options.findIndex(item => {
          return item.id === this.activeOption.id;
        });
        if (indexItem > this.maxValue) {
          this.maxValue = this.roundMathMaxItem(indexItem);
          this.lazyOptions = this.options.slice(0, this.maxValue);
          this.ref.detectChanges();
        }
        const itemActive = this.element.nativeElement.querySelector('.ul-select-choice .ul-select-choice-row.active .dropdown-item');
        if (itemActive) {
          itemActive.focus();
        }
      }
      scroll$.subscribe(element => {
        if (lazyContent.offsetHeight + lazyContent.scrollTop > (30 * this.maxValue) - 10) {
          this.maxValue = this.maxValue + 50;
          this.lazyOptions = this.options.slice(0, this.maxValue);
          this.ref.detectChanges();
        }
      });
    }

    if (this.searchElement) {
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      });
    }
  }

  mainClickKeyDown(event: any) {
    // Up
    if (event.keyCode === 38) {
      event.preventDefault();
      const index = findIndex(this.lazyOptions, {id: this.activeOption.id});
      this.activeOption = index > 0 ? this.lazyOptions[index - 1] : this.lazyOptions[0];
      this.ensureActiveValue(index > 0 ? (index - 1) : -1);
      return;
    }
    // Down
    if (event.keyCode === 40) {
      event.preventDefault();
      const index = findIndex(this.lazyOptions, {id: this.activeOption.id});
      if (index < (this.options.length - 1)) {
        this.activeOption = index >= 0 ? this.lazyOptions[index + 1] : this.lazyOptions[0];
        this.ensureActiveValue(index + 1);
      }
      return;
    }
    // End
    if (event.keyCode === 35) {
      this.lazyOptions = this.options;
      this.ref.detectChanges();
      if (this.lazyOptions.length > 0) {
        this.activeOption = this.lazyOptions[this.lazyOptions.length - 1];
      }
      return;
    }
    // Home
    if (event.keyCode === 36) {
      if (this.lazyOptions.length > 0) {
        this.activeOption = this.lazyOptions[0];
      }
      return;
    }
    if (event.keyCode === 13) {
      if (this.activeOption && this.active.indexOf(this.activeOption) === -1) {
        this.selectMatch(this.activeOption, event);
      }
      return;
    }
  }


  public clickOutside(): void {
    this.inputMode = false;
    this.optionsOpened = false;
    this.maxValue = 50;
    this.ref.detectChanges();
  }

  public inputEvent(e: any) {
    this.searchVal = e.target.value.trim();
    if (this.searchVal) {
      this.options = this.itemObjects.filter((item: any) => (!this.multiple || !this.autoClose || (!!this.multiple && this.active.findIndex((o: SelectItem) => item.text === o.text) === -1)) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
      this.lazyOptions = this.options.slice(0, this.maxValue);
      if (this.multiple && this.searchVal) {
        if (this.lazyOptions && this.lazyOptions.length === 1) {
          this.activeOption = cloneDeep(this.lazyOptions[0]);
        }
      }
    } else {
      this.options = this.itemObjects.filter((item: any) => !this.multiple || !this.autoClose || (!!this.multiple && this.active.findIndex((o: SelectItem) => item.text === o.text) === -1));
      this.lazyOptions = this.options.slice(0, this.maxValue);
      if (this.multiple) {
        this.activeOption = null;
      }
    }
  }

  private selectMatch(value: SelectItem, e: Event = void 0): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!this.maxItems || this.active.length < this.maxItems) {
      if (this.multiple === true) {
        this.active.push(value);
      } else {
        this.active[0] = value;
        this.activeOption = value;
      }
      this.selected.emit(value);
    } else {
      this.toasterService.showError(`The Items must be at less than or equal to ${this.maxItems}`);
    }
    if (!!this.autoClose) {
      this.clickOutside();
    }
  }

  private selectAll(items: SelectItem[], e: Event = void 0): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!this.maxItems || (this.active.length + items.length) <= this.maxItems) {
      forEach(items, value => {
        if (this.multiple === true) {
          this.active.push(value);
        }
      });
      this.selectedAll.emit(this.active);
    } else {
      this.toasterService.showError(`The Items must be at less than or equal to ${this.maxItems}`);
    }
    this.clickOutside();
  }

  public removeAll() {
    this.active = [];
    this.ref.detectChanges();
    this.removedAll.emit();
  }
  public addNewItemList() {
    if (this.searchVal && this.searchVal.trim()) {
      this.addNew.emit(this.searchVal);
      this.clickOutside();
    } else {
      if (this.searchElement) this.searchElement.nativeElement.focus();
      this.toasterService.showError(`Please enter New ${this.itemName}!`);
    }
  }

  public roundMathMaxItem(num: number) {
    return (Math.ceil(num / 50)) * 50;
  }

  public ensureActiveValue(indexActive) {
    const container = this.element.nativeElement.querySelector('.ul-select-choice');
    let posY: number;
    if (!container) {
      return;
    }
    const choices = container.querySelectorAll('.ul-select-choice-row');
    if (choices.length < 1) {
      return;
    }
    if (this.allowEmpty || this.chooseAll) {
      indexActive = indexActive + 1;
    }
    const active = choices[indexActive];
    if (!active) {
      return;
    }
    if (this.multiple) {
      posY = active.offsetTop + active.clientHeight - container.scrollTop;
    } else {
      posY = active.offsetTop - container.scrollTop;
    }
    const height: number = container.offsetHeight;
    if (posY > height) {
      container.scrollTop += posY - height;
    } else if (posY <= active.clientHeight) {
      container.scrollTop -= active.clientHeight - posY;
    }
  }

  public resetChannel(){
    if (!this.disabledResetBtn) this.resetChannelEmit.emit();
  }
}

import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { isEmpty } from 'lodash';
const noData = require('highcharts/modules/no-data-to-display');
noData(Highcharts);

import {
  ScatterPlotParameterChartService,
  IInputParamData, IAxisSetting, IScatterChartInputState, IScatterPlotChartSetting, IParsedChartOptions
} from '../../../../shared/services/charts/scatter-plot-parameter-chart.service';

import {
  BaseChartService,
} from '../../../../shared/services/charts/base-chart.service';

import { CHART_MESSAGES, ENUM_THEMES } from '../../../helpers/app.constants';
import { ILogDataState, LogDataCurveParameterChartService } from '../../../services/charts/log-data-curve-parameter-chart.service';
import { ThemesSettingsService } from '../../../services/themes-settings.service';

@Component({
  selector: 'app-scatter-plot-parameter-chart',
  templateUrl: './scatter-plot-parameter-chart.component.html',
  styleUrls: ['./scatter-plot-parameter-chart.component.scss']
})
export class ScatterPlotParameterChartComponent implements OnInit {

  @Input()
  set inputState(state: IScatterChartInputState) {
    if (state) {
      this.xAxisMode = state.xAxisMode;
      this.itemsComparing = state.itemsComparing;
      this.chartSettings = state.chartSettings;
      this.inputChartData = state.inputData;
      this.fdiOffsetWellsData = state.fdiOffsetWellsData;
      this.logDataState = state.logDataState;
      this.designActualData = state.designActualData;
      this.groupOption = state.groupOption;
    }
  }

  @Input()
  set inputReflow(isReflow: any) {
    if (this.highChart) {
      this.highChart.reflow();
    }
  }

  @Input() defaultTextData: string = '';
  @Input() isMultiWells: boolean = false;

  xAxisMode: string;
  inputChartData: IInputParamData[];
  fdiOffsetWellsData: IInputParamData[];
  itemsComparing: IAxisSetting[];
  logDataState: ILogDataState;
  designActualData: any;
  groupOption: string = 'treatment';

  chartSettings: IScatterPlotChartSetting;

  highChart: any;
  Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  updateFlagChart: boolean = false;
  isDarkTheme: boolean;

  constructor(
    private scatterPlotParameterChartService: ScatterPlotParameterChartService,
    private logDataCurveParameterChartService: LogDataCurveParameterChartService,
    private baseChartService: BaseChartService,
    private themesSettingsService: ThemesSettingsService
  ) {
    const localData = this.themesSettingsService.getLocalStorage();
    if (localData && localData.themes === ENUM_THEMES.Dark) {
      this.isDarkTheme = true;
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
  }

  ngOnInit() {
    this.setupChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputState) {
      this.loadChartData(
        this.itemsComparing, this.chartSettings, this.inputChartData,
        this.xAxisMode, this.isMultiWells, this.logDataState, this.designActualData, this.groupOption, this.fdiOffsetWellsData
      );
    }
  }

  chartInstanceHandler(chart) {
    this.highChart = chart;
  }

  setupChart() {
    this.chartOptions = this.scatterPlotParameterChartService.getDefaultOptions(this.xAxisMode, this.isDarkTheme);
    this.chartOptions.yAxis = [];
    if (this.defaultTextData) {
      this.chartOptions.lang.noData = this.defaultTextData;
    }
    this.updateFlagChart = true;
  }

  loadChartData(
    itemsComparing: IAxisSetting[],
    chartSettings: IScatterPlotChartSetting,
    inputData: IInputParamData[],
    xAxisMode?: string,
    isMultiWells?: boolean,
    logDataState?: ILogDataState,
    designActualData?: any,
    groupOption?: string,
    fdiOffsetWellsData?: IInputParamData[]
  ) {
    try {
      itemsComparing = itemsComparing.filter(item => item.treatmentNumber  !== -10);
      this.resetChart();
      let chartOptions: IParsedChartOptions;
      if (!logDataState || isEmpty(logDataState.selectedCurves)) {
        chartOptions = this.scatterPlotParameterChartService.parseChartOptions(
          itemsComparing, chartSettings, inputData, xAxisMode, isMultiWells, designActualData, groupOption, fdiOffsetWellsData, this.isDarkTheme
        );
      } else {
        chartOptions = this.logDataCurveParameterChartService.parseChartOptions(
          itemsComparing, chartSettings, inputData, logDataState, designActualData, groupOption, this.isDarkTheme
        );
      }

      // add x axis
      chartOptions.xAxis.forEach(xAxisOpt => {
        this.highChart.addAxis(xAxisOpt, true);
      });
      // add y axis
      chartOptions.yAxis.forEach(yAxisOpt => {
        this.highChart.addAxis(yAxisOpt, false);
      });
      // set chart series data
      let seriesData = [...chartOptions.seriesData];

      // update tooltip option by xAxisMode
      this.highChart.update({
        tooltip: chartOptions.tooltip
      }, false);

      // check if all series is empty data then return empty array (prevent show series legend on chart)
      if (this.baseChartService.isAllSeriesDataEmpty(seriesData)) {
        seriesData = [];
      }
      if (isEmpty(seriesData)) {
        this.setChartMessage(CHART_MESSAGES.en.noData);
      }

      seriesData.forEach(series => {
        this.highChart.addSeries(series, false, false);
      });
      this.highChart.redraw();

      return;
    } catch (err) {
      return err;
    }
  }

  resetChart(isRedraw?: boolean) {
    if (this.highChart) {
      this.chartOptions.xAxis = [];
      this.chartOptions.yAxis = [];
      this.chartOptions.series = [];
      // clear chart and reload data
      while (this.highChart.series.length > 0) {
        this.highChart.series[0].remove(false);
      }
      while (this.highChart.yAxis.length > 0) {
        this.highChart.yAxis[0].remove(false);
      }
      while (this.highChart.xAxis.length > 0) {
        this.highChart.xAxis[0].remove(false);
      }
      // reset tooltip
      this.highChart.update({
        tooltip: {
          formatter: undefined
        }
      }, false);

      if (isRedraw) {
        this.highChart.redraw();
      }
    }
  }

  setChartMessage(message: string) {
    if (typeof message === 'string' && this.highChart) {
      const noDataEle = document.querySelector('.highcharts-no-data');
      if (noDataEle && noDataEle.children[0]) {
        noDataEle.children[0].innerHTML = message;
      }
    }
  }

  zoomOut() {
    this.highChart.zoomOut();
  }

  reflow() {
    this.highChart.reflow();
  }

  toggleTooltip(isDisplay) {
    if (this.highChart) {
      this.highChart.update({
        tooltip: {
          enabled: isDisplay
        }
      });
    }
  }

  exportChart(fileName?: string, type?: string) {
    const chart = this.highChart;
    if (type === 'Save PNG') {
      chart.exportChartLocal({
        filename: `${fileName}`,
        sourceWidth: chart.chartWidth,
        sourceHeight: chart.chartHeight,
        scale: 4,
        type: 'image/png'
      });
    } else if (type === 'Save JPEG') {
      chart.exportChartLocal({
        filename: `${fileName}`,
        sourceWidth: chart.chartWidth,
        sourceHeight: chart.chartHeight,
        scale: 8,
        type: 'image/jpeg'
      });
    } else {
      chart.print();
    }
  }


}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDiagnosticPoint } from '../../models/diagnostic-points.model';

@Component({
  selector: 'app-diagnostic-points-select',
  templateUrl: './diagnostic-points-select.component.html',
  styleUrls: ['./diagnostic-points-select.component.scss']
})
export class DiagnosticPointsSelectComponent implements OnInit {
  isOpen: boolean = false;
  listDiagnosticPoints: IDiagnosticPoint[] = [];

  @Input()
  set listItems(value: IDiagnosticPoint[]) {
    this.listDiagnosticPoints = value;
  }

  @Output()
  onSelectPoint: EventEmitter<IDiagnosticPoint> = new EventEmitter();

  @Output()
  onlistItemsChange: EventEmitter<IDiagnosticPoint[]> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  openDropdown() {
    this.isOpen = !this.isOpen;
  }

  isSelected(item): boolean {
    return item && item.selected;
  }

  selectPoint(point) {
    if (point) {
      point.selected = !point.selected;

      this.onSelectPoint.emit({...point});
    }
  }

  clickOutside() {
    this.isOpen = false;
  }

}

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CookieStorageService {
  private accessTokenKey = 'jwtToken';
  private userIdKey = 'userId';
  constructor(private cookieService: CookieService) { }

  getAccessToken(): string {
    return this.cookieService.get(this.accessTokenKey);
  }

  saveAccessToken(token: string, expireTimestamp?: number) {
    console.log("token1111==>", token);
    console.log("exp===>", expireTimestamp)
    this.cookieService.set(this.accessTokenKey, token, expireTimestamp, '/', null, null, null);
  }

  destroyAccessToken() {
    this.cookieService.delete(this.accessTokenKey);
    this.cookieService.delete('expireInSeconds');
  }

  getUserId(): string {
    return this.cookieService.get(this.userIdKey);
  }

  saveUserId(id: string, expireTimestamp?: number) {
    this.cookieService.set(this.userIdKey, id, expireTimestamp);
  }

  destroyUserId() {
    this.cookieService.delete(this.userIdKey);
  }

  destroyAll() {
    this.cookieService.deleteAll('/');
  }

  // Set Cookies
  public setCookies(key: string, value: any, expireTimestamp?: number) {
    const expire = expireTimestamp ? expireTimestamp : 1;
    this.cookieService.set(key, value, expire, '/', null, null, null);
  }

  // Get Cookies
  public getCookies(key: string): string {
    return this.cookieService.get(key);
  }
  
  public setItem(key: string, value: string) {
    this.cookieService.set(key, value);
  }
}

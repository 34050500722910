import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ChangeDetectionStrategy} from '@angular/core';
import {SelectItem} from '../select/select-item';
import {compact, orderBy, findIndex} from 'lodash';
import {ToasterService} from '../../services/toaster.service';

@Component({
  selector: 'app-select-channels',
  templateUrl: './select-channels.component.html',
  styleUrls: ['./select-channels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectChannelsComponent {
  inputMode: boolean = false;
  optionsOpened: boolean = false;

  public options: Array<SelectItem> = [];
  public fracProOptions: Array<SelectItem> = [];
  public itemObjects: Array<SelectItem> = [];
  public fracProItemObjects: Array<SelectItem> = [];
  private _active: Array<SelectItem> = [];
  private _disabled: boolean = false;
  public activeOption: SelectItem = new SelectItem('object');

  public itemEmpty: SelectItem = new SelectItem('');

  searchVal: string = '';

  @ViewChild('searchInput') searchElement: ElementRef;

  @Input() public idField: string = 'id';
  @Input() public textField: string = 'text';
  @Input() public childrenField: string = 'children';
  @Input() public allowEmpty: boolean = true;
  @Input() public placeholder: string = '';
  @Input() public multiple: boolean = false;
  @Input() public maxItems: number;
  @Input() public addNewItem: boolean = false;
  @Input() public isFullWidth: boolean = false;
  @Input() public sortSelect: boolean = true;
  @Input() public autoClose: boolean = true;

  @Input()
  set item(value: Array<any>) {
    if (!value) {
      this.itemObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.itemObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.itemObjects = orderBy(this.itemObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  set fracproItem(value: Array<any>) {
    if (!value) {
      this.fracProItemObjects = [];
    } else {
      const items = value.filter((item: any) => {
        if ((typeof item === 'string') || (typeof item === 'object' && item && item[this.textField] && (item[this.idField] || item[this.idField] === 0))) {
          return item;
        }
      });
      this.fracProItemObjects = items.map((item: any) => (typeof item === 'string' ? new SelectItem(item) : new SelectItem({
        id: item[this.idField],
        text: item[this.textField],
        children: item[this.childrenField]
      })));
      if (this.sortSelect) {
        this.fracProItemObjects = orderBy(this.fracProItemObjects, item => item.text.toLowerCase());
      }
    }
  }

  @Input()
  public set active(selectedItems: Array<any>) {
    if (!selectedItems || selectedItems.length === 0) {
      this._active = [];
      this.activeOption = new SelectItem('');
    } else {
      selectedItems = compact(selectedItems);

      const areItemsStrings = typeof selectedItems[0] === 'string';
      this._active = selectedItems.map((item: any) => {
        const data = areItemsStrings ? item : { id: item[this.idField], text: item[this.textField], prefix: item.prefix };
        this.activeOption = new SelectItem(data);
        return new SelectItem(data);
      });
    }
  }

  public get active(): Array<any> {
    return this._active;
  }

  @Input() curActiveItemIndex: number;

  @Input()
  public set fakeOpen(open: boolean) {
    if (!!open) {
      this.open();
    } else {
      this.clickOutside();
    }
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
    if (this._disabled === true) {
      this.clickOutside();
    }
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Output() public selected: EventEmitter<any> = new EventEmitter();
  @Output() public removed: EventEmitter<any> = new EventEmitter();

  constructor(private toasterService: ToasterService,
              private ref: ChangeDetectorRef,
              private element: ElementRef) {
  }

  protected isActive(value: SelectItem): boolean {
    if (!value) {
      return false;
    }
    let isActive = this.activeOption && this.activeOption.id === value.id;
    if (!!this.multiple) isActive = isActive || this.active.findIndex(item => item.id === value.id) !== -1;
    return isActive;
  }

  protected matchClick(e: any) {
    if (this._disabled === true) {
      return;
    }
    this.inputMode = !this.inputMode;
    if (!!this.inputMode) {
      this.open();
    } else {
      this.clickOutside();
    }
  }

  open() {
    this.options = this.itemObjects.filter((item: any) => !this.multiple || !this.autoClose || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
    this.fracProOptions = this.fracProItemObjects.filter((item: any) => !this.multiple || !this.autoClose || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
    this.optionsOpened = true;
    this.ref.detectChanges();
    if (!this.multiple) {
      const itemActive = this.element.nativeElement.querySelector('.ul-select-choice .ul-select-choice-row.active .dropdown-item');
      if (itemActive) {
        itemActive.focus();
      }
    }

    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    });
  }

  public clickOutside(): void {
    this.inputMode = false;
    this.optionsOpened = false;
  }

  public inputEvent(e: any) {
    this.searchVal = e.target.value.trim();
    if (this.searchVal) {
      this.options = this.itemObjects.filter((item: any) => (!this.multiple || !this.autoClose || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
      this.fracProOptions = this.fracProItemObjects.filter((item: any) => (!this.multiple || !this.autoClose || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text))) && (item.text.toString().toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1));
    } else {
      this.options = this.itemObjects.filter((item: any) => !this.multiple || !this.autoClose || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
      this.fracProOptions = this.fracProItemObjects.filter((item: any) => !this.multiple || !this.autoClose || (!!this.multiple && !this.active.find((o: SelectItem) => item.text === o.text)));
    }
  }

  private selectMatch(value: SelectItem, e: Event = void 0): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.multiple) {
      if (this.maxItems) {
        if (this.active.length < this.maxItems) {
          this.active.push(value);
          this.selected.emit(value);
        } else {
          this.toasterService.showError(`The Items must be at less than or equal to ${this.maxItems}`);
        }
      } else {
        this.active.push(value);
        this.selected.emit(value);
      }
    } else {
      this.active[0] = value;
      this.activeOption = value;
      this.selected.emit(value);
    }
    if (!!this.autoClose) {
      this.clickOutside();
    }

  }

  elementFocus(realtime: boolean) {
    let element: any;
    if (realtime) {
      element = this.element.nativeElement.querySelector('.ul-select-realtime .ul-select-choice');
    } else {
      element = this.element.nativeElement.querySelector('.ul-select-fracpro .ul-select-choice');
    }
    this.ref.detectChanges();
    if (element) {
      element.focus();
    }
  }

  mainClickKeyDown(event: any, realtime: boolean) {
    let index: number;
    // Up
    if (event.keyCode === 38) {
      event.preventDefault();
      if (realtime) {
        index = findIndex(this.options, {id: this.activeOption.id, text: this.activeOption.text});
        this.activeOption = index > 0 ? this.options[index - 1] : this.options[0];
      } else {
        index = findIndex(this.fracProOptions, {id: this.activeOption.id, text: this.activeOption.text});
        this.activeOption = index > 0 ? this.fracProOptions[index - 1] : this.fracProOptions[0];
      }
      this.ensureActiveValue((index > 0 ? (index - 1) : -1), realtime);
      return;
    }
    // Down
    if (event.keyCode === 40) {
      event.preventDefault();
      if (realtime) {
        index = findIndex(this.options, {id: this.activeOption.id, text: this.activeOption.text});
        if (index < (this.options.length - 1)) {
          this.activeOption = index >= 0 ? this.options[index + 1] : this.options[0];
        }
      } else {
        index = findIndex(this.fracProOptions, {id: this.activeOption.id, text: this.activeOption.text});
        if (index < (this.fracProOptions.length - 1)) {
          this.activeOption = index >= 0 ? this.fracProOptions[index + 1] : this.fracProOptions[0];
        }
      }
      this.ensureActiveValue((index + 1), realtime);
      return;
    }
    // End
    if (event.keyCode === 35) {
      if (realtime) {
        if (this.options.length > 0) {
          this.activeOption = this.options[this.options.length - 1];
        }
      } else {
        if (this.fracProOptions.length > 0) {
          this.activeOption = this.fracProOptions[this.fracProOptions.length - 1];
        }
      }
      return;
    }
    // Home
    if (event.keyCode === 36) {
      if (realtime) {
        if (this.options.length > 0) {
          this.activeOption = this.options[0];
        }
      } else {
        if (this.fracProOptions.length > 0) {
          this.activeOption = this.fracProOptions[0];
        }
      }
      return;
    }
    if (event.keyCode === 13) {
      if (this.active.indexOf(this.activeOption) === -1) {
        this.selectMatch(this.activeOption, event);
      }
      return;
    }
  }

  public ensureActiveValue(indexActive, realtime) {
    let container: any;
    if (realtime) {
      container = this.element.nativeElement.querySelector('.ul-select-realtime .ul-select-choice');
    } else {
      container = this.element.nativeElement.querySelector('.ul-select-fracpro .ul-select-choice');
    }
    let posY: number;
    if (!container) {
      return;
    }
    const choices = container.querySelectorAll('.ul-select-choice-row');
    if (choices.length < 1) {
      return;
    }
    const active = choices[indexActive];
    if (!active) {
      return;
    }
    // 19px is group title height
    posY = active.offsetTop + active.clientHeight - container.scrollTop - 19;
    const height: number = container.offsetHeight;
    if (posY > height) {
      container.scrollTop += posY - height;
    } else if (posY <= active.clientHeight) {
      container.scrollTop -= active.clientHeight - posY;
    }
  }
}

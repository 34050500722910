import { Injectable } from '@angular/core';
import { SearchPadListBase } from '../models/search-pad-list-base';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class SearchPadListControlService {

  constructor() { }

  toFormGroup(searchOptions: SearchPadListBase<string>[] ) {
    const group: any = {};

    searchOptions.forEach(opt => {
      group[opt.key] = opt.key === 'pad' ? new FormControl(opt.value || []) : new FormControl(opt.value || '');
    });
    return new FormGroup(group);
  }
}

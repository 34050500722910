import {Component, Input, OnDestroy, OnInit, ViewChild, SimpleChanges, OnChanges} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {Subscription} from 'rxjs';
import {Helper} from '../../../../shared/helpers/helper';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {TreatmentService} from '../../../../shared/services/treatment.service';
import {UserAuthService} from '../../../../shared/services/user-auth.service';
import {User} from '../../../../shared/models/user.model';
import {CPATTERN} from '../../../../shared/helpers/app.constants';
import { TableWellsSelectionComponent } from '../../table-wells-selection/table-wells-selection.component';
import { HistoricalWellsService } from '../../../../pages/manage/historical-wells/historical-wells.service';
import { CompareWellsService } from '../../../services/compare-wells.service';
import { forEach, orderBy, some, cloneDeep, isEmpty, remove } from 'lodash';
import { map, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-wells-selection-modal',
  templateUrl: './wells-selection-modal.component.html',
  styleUrls: ['./wells-selection-modal.component.scss']
})
export class WellsSelectionModalComponent implements OnInit {

  @ViewChild(TableWellsSelectionComponent) wellTable: TableWellsSelectionComponent;
  subscriptions: Subscription[] = [];
  cvalidate = CPATTERN;
  countWells: number = 0;
  wellItems: any[] = [];

  user: User;
  listSelectWells: any[] = [];
  listStates: any[] = [];
  listConties: any[] = [];
  listServiceCompanies: any[] = [];
  listOperatorCompanies: any[] = [];
  listPads: any[] = [];
  listFormations: any[] = [];
  // Form
  form: FormGroup;
  state: AbstractControl;
  county: AbstractControl;
  serviceCompanyId: AbstractControl;
  operatorId: AbstractControl;
  pad: AbstractControl;
  formation: AbstractControl;

  // Paging
  pagingState = {
    page: 1,
    pageSize: 10,
    collectionSize: 10
  };

  isLoading: boolean = false;

  @Input()
  set listWells(list: any) {
    if (list) {
      this.listSelectWells = list;
      forEach(this.listSelectWells, item => {
        if (some(this.selectedListWells, {wellId: item.wellId})) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
    }
  }

  @Input() selectedListWells: any[] = [];

  @Input() maxWells: number;
  @Input() countItemWells: number;

  constructor(
    private dialogRef: NbDialogRef<any>,
    private userAuthService: UserAuthService,
    private historicalWellsService: HistoricalWellsService,
    private compareWellsService: CompareWellsService,
    private treatmentService: TreatmentService
  ) {
    this.createForm();
    this.user = this.userAuthService.getUserInfo();
  }

  ngOnInit() {
    this.getPads();
    this.getFormations();
    this.getStates();
    this.getCounties();
    if (this.user && this.user.companyType !== 'Service') {
      this.getServiceCompanies();
    }
    if (this.user && this.user.companyType !== 'Operator') {
      this.getOperatorCompanies();
    }
    this.isLoading = true;
    const subs = this.getListWells(this.form.value, this.pagingState.page, this.pagingState.pageSize)
    .pipe(finalize(() => {
      this.isLoading = false;
    }))
    .subscribe(result => {
      this.listWells = result.items;
      this.pagingState.collectionSize = result.totalCount;
    });
    this.subscriptions.push(subs);
    this.countWells = this.selectedListWells.length;
  }

  ngOnDestroy(): void {
    Helper.unsubscribe(this.subscriptions);
  }

  createForm() {
    this.form = new FormGroup({
      state: new FormControl(''),
      county: new FormControl(''),
      pad: new FormControl(''),
      formation: new FormControl(''),
      serviceCompanyId: new FormControl(''),
      operatorId: new FormControl(''),
    });
    this.state = this.form.controls['state'];
    this.county = this.form.controls['county'];
    this.pad = this.form.controls['pad'];
    this.formation = this.form.controls['formation'];
    this.serviceCompanyId = this.form.controls['serviceCompanyId'];
    this.operatorId = this.form.controls['operatorId'];
  }

  getListWells(params, page, pageSize) {
    return this.compareWellsService.getListWell(params, page, pageSize)
      .pipe(map(res => {
        if (res && res.result) {
          forEach(res.result.items,  item => {
            // Sort treatment by treatment number
            item.treatmentList = orderBy(item.treatmentList, i => i.treatmentNumber);
            item.treatmentIdDefault = item.treatmentList.length > 0 ? item.treatmentList[0].id : null;
          });
          return res.result;
        }
        return res;
      }));
  }

  loadWells() {
    this.isLoading = true;
    const subs = this.getListWells(this.form.value, this.pagingState.page, this.pagingState.pageSize)
    .pipe(finalize(() => {
      this.isLoading = false;
    }))
    .subscribe(result => {
      this.listWells = result.items;
      this.pagingState.collectionSize = result.totalCount;
    });
    this.subscriptions.push(subs);
  }

  onPageChange(page) {
    this.pagingState.page = page;
    this.loadWells();
  }

  hide(data?: any): void {
    this.dialogRef.close(data);
  }

  submit(): void {
    this.hide(this.selectedListWells);
  }

  close() {
    this.dialogRef.close();
  }

  selectedWellsChange(event) {
    if (event && !isEmpty(event.selected)) {
      // add selected wells
      forEach(event.selected, item => {
        const selectedItem = this.selectedListWells.find(selected => selected.wellId === item.wellId);
        if (selectedItem) {
          if (item.selected === false) {
            remove(this.selectedListWells, (selected => selected.wellId === item.wellId));
          }
        } else {
          if (item.selected) {
            this.selectedListWells.push(item);
          }
        }
      });
      this.countWells = this.selectedListWells.length;
    }
  }

  getPads() {
    this.subscriptions.push(
      this.historicalWellsService.getPads().subscribe(
        (res) => {
          this.listPads = res.result;
        }
      )
    );
  }

  getFormations() {
    this.subscriptions.push(
      this.historicalWellsService.getFormations().subscribe(
        (res) => {
          this.listFormations = res.result;
        }
      )
    );
  }

  getStates() {
    this.subscriptions.push(
      this.historicalWellsService.getStates().subscribe(
        (res) => {
          this.listStates = res.result;
        }
      )
    );
  }

  getCounties() {
    this.subscriptions.push(
      this.historicalWellsService.getCounties().subscribe(
        (res) => {
          this.listConties = res.result;
        }
      )
    );
  }

  getServiceCompanies() {
    this.subscriptions.push(
      this.treatmentService.getListCompanyByType(1).subscribe(
        (res) => {
          this.listServiceCompanies = res.result.items;
        }
      )
    );
  }

  getOperatorCompanies() {
    this.subscriptions.push(
      this.treatmentService.getListCompanyByType(0).subscribe(
        (res) => {
          this.listOperatorCompanies = res.result.items;
        }
      )
    );
  }

  searchWells() {
    if (this.form.valid) {
      this.loadWells();
    }
  }

}

import { Directive, HostListener, ElementRef, Input, ViewChild, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appInputNoUnset]',
})
export class InputNoUnsetDirective {
  @ViewChild(ElementRef) eleContainer: ElementRef;
  @Input() appInputNoUnset: any;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  @HostListener('change') onInputChange() {
    const value = this.el.nativeElement.value.trim();
    if (!value) {
      this.ngModelChange.emit(this.appInputNoUnset);
    }
  }

  constructor(private el: ElementRef) { }
  
}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { IPadRealtimeInputData, PadRealtimeDataService } from './pad-realtime-data.service';
import { of } from 'rxjs';


@Injectable()
export class PadRealtimeResolver implements Resolve<IPadRealtimeInputData> {
  constructor(
    private router: Router,
    private padRealtimeDataService: PadRealtimeDataService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const inputData = this.padRealtimeDataService.getInputData();
    return of(inputData);
  }
}

import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Helper } from '../../helpers/helper';
import { ICompareWellsTimeControl } from './compare-wells-time-control.model';
import { CompareWellsTimeControlService } from './compare-wells-time-control.service';

@Component({
  selector: 'app-compare-wells-time-control',
  templateUrl: './compare-wells-time-control.component.html',
  styleUrls: ['./compare-wells-time-control.component.scss']
})
export class CompareWellsTimeControlComponent implements OnInit, OnDestroy {
  listDaysOptions: string[] = ['None', 'All', '1', '2', '3', '4', '7', '15', '30'];
  form: FormGroup;
  dateRange: { startTime: string; endTime: string } = null;
  minDate;
  maxDate;
  isShowAlias: boolean = true;
  isCompareMultiWells: boolean = false;

  @Input()
  set compareMultiple(value: boolean) {
    this.isCompareMultiWells = value;
    // disable well color mode in compare single well
    if (this.isCompareMultiWells === false && this.form) {
      this.isWellColor.setValue(false);
      const formValue = this.form.getRawValue();
      this.compareWellsTimeControlService.setDataState(formValue);
    }
  }

  @Output() refreshWellTreatments: EventEmitter<any> = new EventEmitter();
  @Output() stateChange: EventEmitter<any> = new EventEmitter();
  @Output() loadForm: EventEmitter<any> = new EventEmitter();
  @Output() toggleAlias: EventEmitter<any> = new EventEmitter();
  @Output() stateReset: EventEmitter<ICompareWellsTimeControl> = new EventEmitter();
  subscriptions: Subscription[] = [];

  constructor(
    private compareWellsTimeControlService: CompareWellsTimeControlService
  ) { }

  get startTime() {
    return this.form.controls.startTime;
  }

  get endTime() {
    return this.form.controls.endTime;
  }

  get isAutoLoad() {
    return this.form.controls.isAutoLoad;
  }

  get isWellColor() {
    return this.form.controls.isWellColor;
  }

  get lastDays() {
    return this.form.controls.lastDays;
  }

  ngOnInit(): void {
    this.createForm();
    // listen wells'data end time
    const subs = this.compareWellsTimeControlService.dataRangeDateTime.asObservable().subscribe(dateRange => {
      if (dateRange.startTime) dateRange.startTime = dateRange.startTime.substring(0, 10) + 'T00:00:00.000Z';
      if (dateRange.endTime) dateRange.endTime = dateRange.endTime.substring(0, 10) + 'T00:00:00.000Z';
      this.dateRange = dateRange;
    });
    this.subscriptions.push(subs);
  }

  ngOnDestroy(): void {
    this.compareWellsTimeControlService.restDataState();
    Helper.unsubscribe(this.subscriptions);
  }

  private createForm(): void {
    const initState = this.compareWellsTimeControlService.getInitialState();
    this.form = new FormGroup({
      lastDays: new FormControl(initState.lastDays),
      startTime: new FormControl({ value: initState.startTime, disabled: false }),
      endTime: new FormControl({ value: initState.endTime, disabled: false }),
      isAutoLoad: new FormControl({ value: initState.isAutoLoad, disabled: false }),
      isWellColor: new FormControl({ value: initState.isWellColor, disabled: false }),
    });
  }

  public onChangeDayOption(eventData): void {
    if (eventData && eventData.id) {
      this.lastDays.setValue(eventData.id);
      if (eventData.id === 'None') {
        this.resetTime();
      } else {
        if (this.dateRange) {
          this.minDate = this.dateRange.startTime;
          this.maxDate = this.dateRange.endTime;
          this.endTime.setValue(this.dateRange.endTime);
          if (eventData.id === 'All') {
            this.startTime.setValue(this.dateRange.startTime);
          } else {
            const lastDaysVal = eventData.id * 1;
            const startTimeStr = new Date(new Date(this.dateRange.endTime).getTime() - lastDaysVal * 8.64e+7).toISOString();
            this.startTime.setValue(startTimeStr);
          }
        }
      }
    }
    this.emitState();
  }

  private resetTime(): void {
    this.startTime.setValue('');
    this.endTime.setValue('');
    if (this.form) {
      const formValue = this.form.getRawValue();
      this.stateReset.emit(formValue);
    }
  }

  public toggleAutoLoad(event): void {
    this.emitState();
  }

  public toggleWellColor(event): void {
    const formValue = this.form.getRawValue();
    this.compareWellsTimeControlService.setDataState(formValue);
  }

  public changeDate(event): void {
    this.lastDays.setValue('None');
    if (event.isAutoLoad === false) return;
    this.emitState();
  }

  private emitState(): void {
    if (this.form) {
      const formValue = this.form.getRawValue();
      this.stateChange.emit(formValue);
    }
  }

  public load(): void {
    const formValue = this.form.getRawValue();
    this.loadForm.emit(formValue);
  }

  public onChangeAlias(value): void {
    this.isShowAlias = value;
    this.toggleAlias.emit(value);
  }

  public onRefreshWellTreatments(): void {
    this.refreshWellTreatments.emit();
  }

}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty, isNil } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../../core/services/api.service';
import { Helper } from '../helpers/helper';

@Injectable()
export class TreatmentService {

  constructor(
    private apiService: ApiService
  ) { }

  public static COMPARE_CHANNEL_PROP = ['wellId', 'treatmentId', 'cName', 'isShowBourdetDer', 'isRealTime', 'isSmoothData'];
  prefixPath = `/wells/treatments`;
  prefixWellsPath = `/wells`;

  private toUrlQueryString(queryParams) {
    let queryString = '';
    if (typeof queryParams === 'object' && queryParams !== null) {
      queryString = '?';
      const keysData = Object.keys(queryParams);
      keysData.forEach((key, index) => {
        queryString = queryString + key + '=' + queryParams[key];
        if (index !== keysData.length - 1) {
          queryString = queryString + '&';
        }
      });
    }
    return queryString;
  }

  handleToggleLiveButton() {
    // handle show live icon
    return this.getRealTimeTreatmentInProgress()
      .pipe(tap((res) => {
        this.handleMenuRealtime(res.result);
      }));
  }

  public handleMenuRealtime(treatmentInProgress: any[]): void {
    const menuRealtime = document.querySelector('a[title="Realtime"]');
    if (menuRealtime) {
      if (treatmentInProgress && treatmentInProgress.length > 0) {
        if ((' ' + menuRealtime.className + ' ').indexOf(' menu-realtime ') === -1) {
          menuRealtime.classList.add('menu-realtime');
        }
      } else {
        menuRealtime.classList.remove('menu-realtime');
      }
    }
  }

  createWellManual(param: any, finalize?: VoidFunction) {
    return this.apiService.post(`${this.prefixWellsPath}/createManual`, param, finalize);
  }

  getWellDetail(wellId: number) {
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}`);
  }

  suggestWellApi(companyId, queryText) {
    let param = new HttpParams();
    param = param.set('input', queryText.toString());
    if (companyId) {
      param = param.set('companyId', companyId);
    }
    return this.apiService.get(`${this.prefixPath}/suggestWellAPI`, param);
  }

  getCurrentWellInfo(wellId: string) {
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/getCurrentInfo`);
  }

  getListCompanyByType(type: number) {
    let param = new HttpParams();
    param = param.set('type', type.toString());
    return this.apiService.get(`/companies/getByType`, param);
  }

  uploadXMLCreateWell(file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.apiService.postFile(`${this.prefixWellsPath}/createByXML`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  uploadTreatmentFile(file, queryParams?) {
    const formData = new FormData();
    formData.append('file', file);

    return this.apiService.postFile(`${this.prefixWellsPath}/${queryParams.wellId}/treatments/${queryParams.treatmentId}/csv`, formData);
  }

  deleteTreatmentFile(wellId: number, treatmentId: number, csvFileName: string) {
    let param = new HttpParams();
    if (csvFileName) {
      param = param.set('fileName', csvFileName);
    }
    return this.apiService.deleteParam(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}/csv`, param);
  }

  getListTreatments(wellId) {
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/treatments`);
  }

  getTreatment(wellId, treatmentId, isAll = 'false') {
    let param = new HttpParams();
    param = param.set('isAll', isAll);
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}/realtime`, param);
  }

  createTreatmentManual(wellId: number, param: any, finalize?: VoidFunction) {
    return this.apiService.post(`${this.prefixWellsPath}/${wellId}/treatments/createTreatmentManual`, param, finalize);
  }

  updateTreatment(queryParams, dataParam: any, finalize?: VoidFunction) {
    return this.apiService.patch(`${this.prefixWellsPath}/${queryParams.wellId}/treatments/${queryParams.treatmentId}`, dataParam, finalize);
  }

  getAllRealTimeTreatment(page: number, pageSize: number) {
    let param = new HttpParams();
    param = param.set('page', page.toString());
    param = param.set('pageSize', pageSize.toString());
    return this.apiService.get(`${this.prefixPath}/getAllRealTimeTreatmentPaging`, param);
  }

  getRecentRealTimeTreatment(isFieldEngineer: boolean) {
    if (isFieldEngineer) {
      return this.apiService.get(`${this.prefixPath}/getRecentRealTimeTreatment?role=Field%20Engineer`);
    } else {
      return this.apiService.get(`${this.prefixPath}/getRecentRealTimeTreatment`);
    }
  }

  getRealTimeTreatmentInProgress() {
    return this.apiService.get(`${this.prefixPath}/inProgress`);
  }

  getRealTimeTreatmentInProgressPaging(page: number, pageSize: number) {
    let param = new HttpParams();
    param = param.set('page', page.toString());
    param = param.set('pageSize', pageSize.toString());
    return this.apiService.get(`${this.prefixPath}/inProgressPaging`, param);
  }

  public getTreatmentInProgressData(): Observable<any[]> {
    return this.apiService.get(`${this.prefixPath}/inProgressData`)
      .pipe(switchMap(response => {
        if (response && response.result) return of(response.result);
        else return of([]);
      }));
  }

  public getTreatmentInProgressDataPaging(page: number, pageSize: number): Observable<{ totalCount: number, items: any[] }> {
    let param = new HttpParams();
    param = param.set('page', page.toString());
    param = param.set('pageSize', pageSize.toString());

    return this.apiService.get(`${this.prefixPath}/inProgressDataPaging`, param)
      .pipe(switchMap(response => {
        if (response && response.result) return of(response.result);
        else return of({ totalCount: 0, items: [] });
      }));
  }

  getRealtimeChannels(wellId, treatmentId) {
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}/channels`).map(res => this.filterRealtimeChannel(res));
  }

  getFracProChannels(wellId, treatmentId, flowPathType?: number) {
    flowPathType = typeof flowPathType === 'number' && !isNaN(flowPathType) ? flowPathType : 0;
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}/flowpaths/${flowPathType}/channels`);
  }

  private filterRealtimeChannel(resData) {
    const filterChannel = channel => {
      if (channel.name.includes('_BD')) return false;
      if (channel.name.includes('_Smth')) return false;
      if (!channel.cName) return false;

      return true;
    };
    resData.result.items = resData.result.items.filter(x => filterChannel(x));
    return resData;
  }

  getChannelsForSettings(wellId: number, treatmentId: number, flowPathType: number = 0, isMonitor?: boolean) {
    const fracProChannels$ = !isMonitor ? this.getFracProChannels(wellId, treatmentId, flowPathType).pipe(catchError(err => of({}))) : of([]);
    return forkJoin(
      this.getRealtimeChannels(wellId, treatmentId).pipe(catchError(err => of({}))),
      fracProChannels$
    ).pipe(map(response => {
      const isNull = !response || !response.length;
      return {
        realtimeChannels: isNull ? [] : Helper.getList(response[0], 'result.items'),
        fracProChannels: isNull ? [] : Helper.getList(response[1], 'result')
      };
    }));
  }

  getListTreatment(wellId: number) {
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/treatments/getListTreatment`);
  }

  getTreatmentData(wellId: number, treatmentId: number): Observable<any> {
    if (!wellId || !treatmentId) return of(null)

    return this.apiService.get(`${this.prefixWellsPath}/${wellId}//treatments/${treatmentId}/data`)
      .pipe(map(response => { return !response || !response.result ? null : response.result; }));
  }

  getTreatmentsData(wellId: number, treatmentIds: number[]): Observable<any[]> {
    if (!treatmentIds || !treatmentIds.length) return of([])

    const body = { treatments: treatmentIds };
    return this.apiService.post(`${this.prefixWellsPath}/${wellId}/treatments/data`, body)
      .pipe(map(response => { return !response || !response.result ? [] : response.result; }));
  }

  getTreatmentDetail(wellId: number, treatmentId: number) {
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}/details`);
  }

  getTreatmentDetailWebApi(wellId: number, treatmentId: number) {
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}`);
  }

  updateChannel(params, queryParams?, finalize?: VoidFunction) {
    return this.apiService.put(`${this.prefixWellsPath}/${queryParams.wellId}/treatments/${queryParams.treatmentId}/channels`, params, finalize);
  }

  checkDuplicateUpdateChannel(params, queryParams?, finalize?: VoidFunction) {
    return this.apiService.put(`${this.prefixWellsPath}/${queryParams.wellId}/treatments/${queryParams.treatmentId}/checkDuplicateUpdateChannel`, params, finalize);
  }

  updateInProgress(wellId, treatmentId, params, finalize?: VoidFunction) {
    return this.apiService.put(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}/updateInProgress`, params, finalize);
  }

  checkDataStatus(url) {
    return this.apiService.get(url, null, { overrideBaseUrl: true });
  }

  getListFlowPaths(wellId: number, treatmentId: number) {
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}/flowPaths/getList`);
  }

  getChannelsMinMax(wellId: number, treatmentId: number, flowpathType: number, cNames: string[]) {
    return this.apiService.post(`/wells/${wellId}/treatments/${treatmentId}/getMinMax?flowpathType=${flowpathType}`, { items: cNames });
  }
  getChannelsMinMaxEquipment(equipmentId: number, cNames: string[]) {
    const param: any = {
      items: cNames
    };
    return this.apiService.post(`/companies/equipments/${equipmentId}/getMinMax`, param);
  }
  getCompareChannelsMinMax(channels: string[], wells: { wellId: number; treatmentId: number; flowPathType: number }[]) {
    const param: any = {
      channels,
      wells
    };
    return this.apiService.post(`${this.prefixPath}/getMinMax`, param);
  }

  uploadDataFiles(file: any, queryParams) {
    let path = `${this.prefixPath}/uploadFile`;
    if (typeof queryParams === 'object' && queryParams !== null) {
      const queryString = this.toUrlQueryString(queryParams);
      path = path + queryString;
    }
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.postFile(`${path}`, formData);
  }

  getDataFiles(wellId: number, treatmentId: number) {
    let param = new HttpParams();
    if (wellId) {
      param = param.set('wellId', wellId.toString());
    }
    if (treatmentId) {
      param = param.set('treatmentId', treatmentId.toString());
    }
    return this.apiService.get(`${this.prefixPath}/getDataFile`, param);
  }

  deleteDataFiles(paramObject: any, finalize?: VoidFunction) {
    let param = new HttpParams();
    if (paramObject.wellId) {
      param = param.set('wellId', paramObject.wellId.toString());
    }
    param = param.set('id', paramObject.id.toString());
    if (paramObject.treatmentId) {
      param = param.set('treatmentId', paramObject.treatmentId.toString());
    }
    return this.apiService.deleteParam(`${this.prefixPath}/deleteFile`, param, finalize);
  }

  checkIsViewDataFile(serviceCompany: string, operatorCompany: string) {
    let param = new HttpParams();
    if (serviceCompany) {
      param = param.set('serviceName', serviceCompany);
    }
    if (operatorCompany) {
      param = param.set('operatorName', operatorCompany);
    }
    return this.apiService.get(`${this.prefixPath}/checkIsViewDataFile`, param);
  }

  deleteTreatment(wellId, treatmentId) {
    return this.apiService.delete(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}`);
  }

  getListComments(wellId: number, treatmentId: number) {
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}/comments`);
  }

  checkSeperateRealTimeData(wellId: number, treatmentId: number) {
    return this.apiService.get(`${this.prefixWellsPath}/${wellId}/treatments/${treatmentId}/checkSeperateRealTimeData`);
  }

  updateWellAliasName(wellId: number, aliasName: string) {
    let param = new HttpParams();
    param = param.set('wellId', wellId.toString());
    param = param.set('aliasName', aliasName);
    return this.apiService.put(`${this.prefixWellsPath}/updateAliasWellName`, {}, null, null, param);
  }

}

import { formatNumber } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isEmpty, forEach, isNil } from 'lodash';
import { DefaultColor, ENUM_COLOR_MODE } from '../../helpers/app.constants';
import { Helper } from '../../helpers/helper';
import { IChannelSettingModel } from '../../models/channel.model';
import { ChannelSelectionService, DIGITS_AFTER_DECIMAL_DEFAULT, ToasterService } from '../../services';
import { TreatmentService } from '../../services/treatment.service';
import { UnitSystemService } from '../../services/unit-system.service';

export interface IChannelSettingYAxis {
  min: number;
  max: number;
  channels: any[];
}

@Component({
  selector: 'app-channel-setting-yaxis',
  templateUrl: './channel-setting-yaxis.component.html',
  styleUrls: ['./channel-setting-yaxis.component.scss']
})
export class ChannelSettingYaxisComponent implements OnInit {
  defaultColor: any[] = DefaultColor;
  numeralOption = {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
  };

  settings: IChannelSettingYAxis = {
    min: 0,
    max: 150,
    channels: []
  };

  @Input() isDisplayDigits: boolean;
  @Input() multipleSelection: boolean;
  @Input() simpleMode: boolean = false;

  @Input() wellId: number;
  @Input() treatmentId: number;
  @Input() flowPathType: number;
  @Input() titleHeader: string;
  @Input() compareChannel: boolean = false;
  @Input() equipmentId: number;
  @Input() uniqueKey: string = 'cName';
  @Input() autoScaleYAxis: boolean = true;
  @Input() ngSelectUp: boolean = false;
  @Input() realtimeChannels: any[] = [];
  @Input() fracproChannels: any[] = [];
  @Input() settingsModel: IChannelSettingModel;
  @Input() showChannelMinMax: boolean = true;
  @Input() disabledEditColorMode: boolean = false;
  @Input() compareChannelsSelected: { cName: string, name: string, min: number, max: number }[];

  channelsActived: any[] = [];

  @Input()
  set yAxisModel(data: IChannelSettingYAxis) {
    if (data) {
      data.channels = data.channels.map(item => {
        if (item && item.channel) {
          item.channel.digits = item.channel.digits !== undefined && item.channel.digits !== null ? item.channel.digits : this.digitsAfterDecimalValue;
        }
        return item;
      });
      this.settings = data;
      this.channelsActived = this.settings.channels.map(item => item && item.channel);
      if (!!this.simpleMode) {
        this.settings.max = null;
        this.settings.min = null;
      }
    }
  }
  @Input()
  set compareWellCount(count: number) {
    this.colorModeDefault = count > 1 ? [ENUM_COLOR_MODE.Flat] : [ENUM_COLOR_MODE.Gradient];
  }

  @Output() onCreateChannel: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteChannel: EventEmitter<any> = new EventEmitter();
  @Output() onChangeChannel: EventEmitter<any> = new EventEmitter();

  colorPickerValue: string = Helper.randomHexColor();
  colorModeList: string[] = [ENUM_COLOR_MODE.Random, ENUM_COLOR_MODE.Flat, ENUM_COLOR_MODE.Gradient];
  colorModeDefault: string[] = [ENUM_COLOR_MODE.Gradient];
  isShowAddChannel: boolean = false;
  isDisableAddChannel: boolean = false;

  digitsAfterDecimalOptions: string[] = ['0', '1', '2', '3', '4'];
  digitsAfterDecimalValue: string = DIGITS_AFTER_DECIMAL_DEFAULT;

  constructor(
    private toasterService: ToasterService,
    private treatmentService: TreatmentService,
    private channelSelectionService: ChannelSelectionService,
    private unitSystemService: UnitSystemService
  ) { }

  ngOnInit() {
  }

  onRemoveChannel(item, index) {
    this.settings.channels.splice(index, 1);
    this.channelsActived = this.settings.channels.map(item => item && item.channel);
    this.onDeleteChannel.emit(item);
  }

  isDuplicate(channel): boolean {
    try {
      const selectedChannels = [
        ...this.settingsModel.firstLeft.channels,
        ...this.settingsModel.secondLeft.channels,
        ...this.settingsModel.firstRight.channels,
        ...this.settingsModel.secondRight.channels,
      ];
      const existed = selectedChannels.find(item => item.channel[this.uniqueKey] === channel[this.uniqueKey]);
      return !!existed;
    } catch (err) {
      return false;
    }
  }

  selectChannels(channels, event) {
    const channel = Helper.findInMultiList([this.realtimeChannels, this.fracproChannels], 'name', event.id);
    if (!channel) return;

    if (!channels) return;

    const isDuplicated = this.isDuplicate(channel);
    if (isDuplicated) {
      const removeIndex = channels.findIndex(item => item.name === channel.name);
      if (removeIndex > -1) this.onRemoveChannel(channels[removeIndex], removeIndex);
    } else {
      this.addChannel(channels);

      channels[channels.length - 1] = Object.assign(channels[channels.length - 1], channel);
      channels[channels.length - 1].channel = { ...channel };
      if (!channels[channels.length - 1].color) channels[channels.length - 1].color = Helper.getRandomColor();

      // emit on change event and map minmax if need
      this.onChangeChannel.emit(channels[channels.length - 1]);
      this.mapChannelMinMax(channels[channels.length - 1], channels.length - 1);
    }

    this.channelsActived = this.settings.channels.map(item => item && item.channel);
  }

  selectChannel(item, event, itemIndex) {
    const channel = Helper.findInMultiList([this.realtimeChannels, this.fracproChannels], 'name', event.id);
    if (!channel) return;
    const isDuplicated = this.isDuplicate(channel);
    if (isDuplicated) this.toasterService.showError('This channel is already added');

    item = Object.assign(item, channel);
    item.channel = { ...channel };
    if (!item.color) item.color = Helper.getRandomColor();

    // emit on change event and map minmax if need
    this.onChangeChannel.emit(item);
    this.mapChannelMinMax(item, itemIndex);
    if (!isDuplicated) {
    this.channelsActived = this.settings.channels.map(item => item && item.channel);
    }
  }

  private getChannelIdData = item => {
    const isDefault = !item.channel || !item.channel.isOffset;
    return {
      wellId: isDefault ? this.wellId : item.channel.wellId,
      treatmentId: isDefault ? this.treatmentId : item.channel.treatmentId,
    };
  }

  private updateScale(item, itemIndex) {
    // in edit mode, only update axis scale if select on first item
    // case add new channel, the list channel must empty
    if ((itemIndex === 0 && this.settings.channels.length === 1) || (this.isShowAddChannel && isEmpty(this.settings.channels))) {
      this.autoUpdateScale(item.min, item.max);
    }
  }

  private mapChannelMinMax(item, itemIndex) {
    if (!!this.simpleMode) {
      item.min = null;
      item.max = null;
    } else {
      if (!item.channel || !item.channel.cName) return;
      const cNames = [item.channel.cName];
      if (!this.compareChannel) {
        const { wellId, treatmentId } = this.getChannelIdData(item);
        if (wellId && treatmentId && !isEmpty(cNames)) {
          this.treatmentService.getChannelsMinMax(wellId, treatmentId, this.flowPathType, cNames).subscribe(response => {
            if (!response || !response.result.length) return;
            const channel = response.result[0];
            item.min = channel.min;
            item.max = channel.max;
            this.updateScale(channel, itemIndex);
          }, err => err);
        } else if (this.equipmentId) {
          this.treatmentService.getChannelsMinMaxEquipment(this.equipmentId, cNames).subscribe(response => {
            if (!response || !response.result.length) return;
            const channel = response.result[0];
            item.min = channel.min;
            item.max = channel.max;
            this.updateScale(channel, itemIndex);
          }, err => err);
        }
      } else {
        if (!isEmpty(this.compareChannelsSelected)) {
          const selectingChannel = this.compareChannelsSelected.find(channel => channel.name === item.channel.name);
          if (selectingChannel) {
            item.min = selectingChannel.min;
            item.max = selectingChannel.max;
          }
        }
        this.updateScale(item, itemIndex);
      }
    }
  }

  selectColor(item, event) {
    item.color = event;
  }

  selectColorMode(item, event) {
    item.colorMode = event.text;
  }

  autoUpdateScale(min, max) {
    this.settings.min = min;
    this.settings.max = this.channelSelectionService.roundUpMaxValue(max);
    // if (this.settings && min <= max) {
    //   this.settings.min = min || 0;
    //   this.settings.max = this.channelSelectionService.roundUpMaxValue(max);
    //   if (this.settings.min === 0 && this.settings.max === 0) {
    //     this.settings.max = 150;
    //   }
    // }
  }

  addChannel(channels: any[]) {
    if (channels) {
      const isAllowNew = !isEmpty(channels) && channels[channels.length - 1].channel;
      if (channels.length === 0 || isAllowNew) {
        const newChannel = {
          color: this.colorPickerValue,
          colorMode: this.colorModeDefault[0],
          digits: this.digitsAfterDecimalValue
        };
        if (this.disabledEditColorMode) {
          // this.colorModeDefault = this.compareWellCount > 1 ? [ENUM_COLOR_MODE.Flat] : [ENUM_COLOR_MODE.Gradient];
          newChannel.colorMode = this.colorModeDefault[0];
        }
        channels.push(newChannel);
        this.onCreateChannel.emit(newChannel);
      }
    }
  }

  onChangeDigitsAfterDecimalOptions(item, digits) {
    if (digits && digits.id) {
      item.digits = digits.id;
      this.onChangeChannel.emit(item);
    }
  }

  convertUnit(measureType: string, value, decimal?: number): number | string {
    return this.unitSystemService.getValueConvert(measureType, value, decimal);
  }

}

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { IFDIPayload, TreatmentPlotFDI } from './treatment-plot-fdi.model';
import { isEmpty } from 'lodash';
import { map, share } from 'rxjs/operators';

@Injectable()
export class TreatmentPlotFdiStoreService {
  private dataPayloadSubject: BehaviorSubject<IFDIPayload> = new BehaviorSubject(this.getInitialDataPayload());

  constructor() { }

  getInitialDataPayload() {
    return {
      fdi: []
    };
  }

  setDataPayload(payload: IFDIPayload) {
    this.dataPayloadSubject.next(payload);
  }

  setListFDIs(listFDIs: TreatmentPlotFDI[]) {
    const currentPayload = this.dataPayloadSubject.value;
    if (currentPayload && listFDIs) {
      const newPayload = {...currentPayload};
      newPayload.fdi = [...listFDIs];
      this.dataPayloadSubject.next(newPayload);
    }
  }

  addItemToDataPayload(item: TreatmentPlotFDI) {
    const currentPayload = this.dataPayloadSubject.value;
    if (currentPayload && !isEmpty(currentPayload.fdi)) {
      const newPayload = {...currentPayload};
      newPayload.fdi.push(item);
      this.dataPayloadSubject.next(newPayload);
    } else {
      this.dataPayloadSubject.next({
        fdi: [item]
      });
    }
  }

  getDataPayload(): Observable<IFDIPayload> {
    return this.dataPayloadSubject.asObservable()
      .pipe(share())
      .pipe(map(res => {
        return Object.assign({}, res);
      }));
  }

  unsetDataPayload() {
    this.dataPayloadSubject.next(null);
  }

  resetDataPayload() {
    this.dataPayloadSubject.next(this.getInitialDataPayload());
  }
}

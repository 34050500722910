export const YAXIS_KEYS = ['firstLeft', 'secondLeft', 'firstRight', 'secondRight'];
export const TEMPLATE_YAXIS_KEYS = ['yAxisLeft', 'yAxisLeftMost', 'yAxisRight', 'yAxisRightMost'];

export const YAxisMap = {
  firstLeft: 'firstLeft',
  secondLeft: 'secondLeft',
  firstRight: 'firstRight',
  secondRight: 'secondRight',
};

export const CPATTERN = {
  EMAIL: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!$%^&@*()_+|~=`{}\[\]:";'<>?,.\s\/])[A-Za-z\d-!$%^&@*()_+|~=`{}\[\]:";'<>?,.\s\/]{8,}$/,
  TEXTNUMBER: /^[_A-Za-z0-9\s]*((-\s)*[_A-Za-z0-9\s])*$/,
  TEXT: /^[_A-Za-z\s]*((-\s)*[_A-Za-z\s])*$/,
  CHANNEL_MAPPING_NAME: /^[A-Za-z0-9\s\-%()[\]._]+$/,
  COMPANY_NAME: /^[A-Za-z0-9&\s,]+$/,
  MATCH_STAGE_STR: /Stage(?=[0-9]*)|Stg(?=[0-9]*)|S(?=[0-9]*)/
};

export const FDI_PARAM = ['fdiStart', 'fdiEnd', 'peakFDIStart', 'peakFDIEnd', 'leakOffStart', 'leakOffEnd'];
export const FDI_PARAM_TENANT = ['peakFDI', 'leakOff'];
export const FDI_PARAM_TENANTS = ['peakFDIStart', 'peakFDIEnd', 'leakOffStart', 'leakOffEnd'];

export const TenantType = {
  OperatorCompany: 0,
  ServiceCompany: 1,
  DefaultCompany: 2,
};

export const UserRoleType = {
  carboAdmin: 'carbo admin',
  clientAdmin: 'admin',
  dataEngineer: 'data engineer',
  fieldEngineer: 'field engineer',
  analyst: 'analyst',
};

export const DefaultColor = [
  'rgb(255, 0, 0)', 'rgb(0, 0, 255)', 'rgb(0, 255, 0)', 'rgb(255, 128, 0)', 'rgb(128, 0, 255)', 'rgb(255, 0, 128)', 'rgb(0, 0, 0)', 'rgb(255, 255, 0)',
  'rgb(255, 128, 128)', 'rgb(255, 255, 128)', 'rgb(128, 255, 128)', 'rgb(0, 255, 128)', 'rgb(128, 255, 255)', 'rgb(0, 128, 255)', 'rgb(255, 128, 192)', 'rgb(255, 128, 255)',
  'rgb(255, 0, 0)', 'rgb(255, 255, 0)', 'rgb(128, 255, 0)', 'rgb(0, 255, 64)', 'rgb(0, 255, 255)', 'rgb(0, 128, 192)', 'rgb(128, 128, 192)', 'rgb(255, 0, 255)',
  'rgb(128, 64, 64)', 'rgb(255, 128, 64)', 'rgb(0, 255, 0)', 'rgb(0, 128, 128)', 'rgb(0, 64, 128)', 'rgb(128, 128, 255)', 'rgb(128, 0, 64)', 'rgb(255, 0, 128)',
  'rgb(128, 0, 0)', 'rgb(255, 128, 0)', 'rgb(0, 128, 0)', 'rgb(0, 128, 64)', 'rgb(0, 0, 255)', 'rgb(0, 0, 160)', 'rgb(128, 0, 128)', 'rgb(128, 0, 255)',
  'rgb(64, 0, 0)', 'rgb(128, 64, 0)', 'rgb(0, 64, 0)', 'rgb(0, 64, 64)', 'rgb(0, 0, 128)', 'rgb(0, 0, 64)', 'rgb(64, 0, 64)', 'rgb(64, 0, 128)',
];




export const HexColor = {
  RED: '#f60002',
  ORANGE: '#f06c34',
  YELLOW: '#FFD600',
  GREEN: '#75BC54',
  DARK: '#36404b'
};

export const DefaultSize = {
  wellPage: 100
}

export const DefaultShapes = ['Circle', 'Square', 'Triangle', 'Diamond'];

export const IsIEOrEdge = /msie\s|trident\/|edge\//i;

export const EpochTimeParameters = [
  'TimeStart', 'TimeEnd'
];

export const CHART_MESSAGES = {
  en: {
    noData: 'No Data to render',
    pleaseSelectChannel: 'Please select Channel',
    pleaseSelectTreatment: 'Please select Treatment(s)',
    rendering: 'Rendering Data...',
    loading: 'Loading Data...'
  }
};

export const CHART_SERIES_NAME = {
  comment: 'chart-comment-series',
  diagnostic: 'diagnostic',
  fdi: 'fdi',
  los: 'los'
};

export const LIST_SHAPES = [
  { id: 'Circle', name: 'Circle' },
  { id: 'Square', name: 'Square' },
  { id: 'Triangle', name: 'Triangle' },
  { id: 'Diamond', name: 'Diamond' },
];
export const DEFUALT_DESIGN_ACUTAL_SHAPES = [
  { id: 'Bar', name: 'Bar' },
  { id: 'Line', name: 'Line' },
];
export const DEFAULT_DESIGN_COLOR = '#28a745';
export const DEFAULT_ACTUAL_COLOR = '#0000a0';

export const LOS_DEFAULTS = [
  { id: 'MinMaxStart', text: 'MinMaxStart', color: 'red' },
  { id: 'MinMaxEnd', text: 'MinMaxEnd', color: 'red' },
];

export const DEFAULT_PIVOTPOINTS_GROUP_ID = ['MinMax'];

export const DIAGNOSTIC_DEFAULTS = [
  { id: 'Equalize', text: 'Equalize', color: 'limegreen' },
  { id: 'WellOpenTime', text: 'Well Open', color: 'red' },
  { id: 'StartStage', text: 'Stage Start', color: 'aqua' },
  { id: 'BallSeatTime', text: 'BallSeat', color: 'lime' },
  { id: 'BreakDownTime', text: 'Breakdown', color: 'blue' },
  { id: 'AcidStart', text: 'Acid Start', color: 'purple' },
  { id: 'AcidEnd', text: 'Acid End', color: 'purple' },
  { id: 'DesignRate', text: 'Design Rate', color: 'brown' },
  { id: 'DiverterStart', text: 'Diverter Start', color: 'olive' },
  { id: 'DiverterEnd', text: 'Diverter End', color: 'olive' },
  { id: 'EndStage', text: 'Stage End', color: 'aqua' },
  { id: 'InitialShutinPresTime', text: 'ISIP', color: 'orange' },
  { id: 'WellClose', text: 'Well Close', color: 'red' }
];

export enum ENUM_DIAGNOSTIC_DEFAULTS {
  BreakDownTime = 'BreakDownTime',
  InitialShutinPresTime = 'InitialShutinPresTime',
  WellOpenTime = 'WellOpenTime',
  WellClose = 'WellClose',
  BallSeatTime = 'BallSeatTime',
  Equalize = 'Equalize',
  StartStage = 'StartStage',
  AcidStart = 'AcidStart',
  AcidEnd = 'AcidEnd',
  DesignRate = 'DesignRate',
  DiverterStart = 'DiverterStart',
  DiverterEnd = 'DiverterEnd',
  EndStage = 'EndStage',
}

export enum ENUM_LOS_DEFAULTS {
  MinMaxStart = 'MinMaxStart',
  MinMaxEnd = 'MinMaxEnd',
}

export enum ENUM_TREATMENT_COLUMNS {
  AveragePres = 'AveragePres',
  TimeStart = 'TimeStart',
  TimeEnd = 'TimeEnd'
}

export const PLOT_TEMPLATE_TYPE = {
  FracproAI: 0,
  FracproXch: 1,
  User: 2,
  Company: 3
};

export const TREATMENT_MONITOR_NUMBER = -10;

export const TREATMENT_TYPE = {
  0: 'Treatment',
  1: 'Monitoring',
  2: 'DFIT',
  3: 'Minifrac'
};

export enum ENUM_TREATMENT_TYPE {
  Treatment = 'Treatment',
  Monitoring = 'Monitoring',
  DFIT = 'DFIT',
  Minifrac = 'Minifrac'
}

export const USER_STORAGE = {
  fdiParam: 'user_fdi_param'
}

export const DATA_FILES_EXT = '.inp,.csv,.pdf,.txt,.doc,.xls,.xlsx,.xml,.las';

export enum INPUT_DATA_KEY {
  realtimeData = 'realtimeData',
  fracproData = 'fracproData',
  equipmentData = 'equipmentData'
}

export enum ENUM_COLOR_MODE {
  Random = 'Random',
  Flat = 'Flat',
  Gradient = 'Gradient'
}

export const WELL_SEARCH_OPTIONS = [
  { id: 'state', name: 'State', default: true },
  { id: 'county', name: 'County', default: true },
  { id: 'pad', name: 'Pad', default: true },
  { id: 'formation', name: 'Formation', default: true },
  { id: 'serviceCompanyId', name: 'Service Company', default: true },
  { id: 'operatorId', name: 'Operator', default: true },
  { id: 'wellName', name: 'WellName' },
  { id: 'zipperGroup', name: 'ZipperGroup' },
  { id: 'landingPoint', name: 'LandingPoint' },
  { id: 'fleet', name: 'Fleet' },
  { id: 'country', name: 'Country' },
  { id: 'prospect', name: 'Prospect' },
  { id: 'tag', name: 'Tag' }
];

export const PAD_SEARCH_OPTIONS = [
  { id: 'padName', name: 'Pad Name', default: true },
  { id: 'state', name: 'State', default: true },
  { id: 'county', name: 'County', default: true },
  { id: 'formation', name: 'Formation', default: true },
  { id: 'serviceId', name: 'Service', default: true },
  { id: 'operatorId', name: 'Operator', default: true },
];

export const DEFAULT_INIT_TEMPLATE_NAME = 'Surf PRC';
export const DEFAULT_LAST_MIN_FILTER = 120;
export const DEFAULT_MONITOR_LAST_MIN_FILTER = 60; // min
export const DEFAULT_MONITOR_LAST_HR_FILTER = 1; // hr
export const LIMIT_CHANNELS_SELECTION = 12;
export const FDI_MARKER_RADIUS = 7;

export enum TIME_SETTING_TYPE {
  OffsetTime = 'Float',
  OffsetTimeLower = 'float',
  EpochTime = 'hh:mm',
  EpochTimeLowerCase = 'hh:mm'
}
export type ChartTimeFormat = 'Float' | 'hh:mm' | '';
export const TIME_FORMAT_LIST = [
  {id: TIME_SETTING_TYPE.OffsetTimeLower, text: 'Job Time'},
  {id: TIME_SETTING_TYPE.EpochTimeLowerCase, text: 'Date Time'}
];

export const INTERVAL_CHART_REALTIME = 5000;
export const INTERVAL_CHART_CHANNEL_REALTIME = 30000;
export const INTERVAL_CHART_COMMENT_REALTIME = 30000;
export const INTERVAL_CHECK_WELL_INPROGRESS = 10000;
export const INTERVAL_CHECK_TREATMENT_DATA = 10000;
export const INTERVAL_CHECK_PAD_DATA = INTERVAL_CHART_REALTIME;
export const INTERVAL_CHECK_PAD_INPROGRESS = 30000;
export const INTERVAL_CHECK_UPLOAD_DATA = 5000;
export const EXPIRED_TIME_CHECK_UPLOAD_DATA = 120000;
export const WELL_TOKEN_EXPIRED = 3600000; // milisecond

export const TIME_SETTINGS_TIME_OPTIONS = {
  realtimeExtends: [1, 2, 5, 10, 15],
  realtimeExtendsLabel: ['1 min', '2 min', '5 min', '10 min', '15 min'],
  treatment: [0, 30, 60, 90, 120, 180, 240, 300],
  treatmentLabel: ['All', '30 min', '60 min', '90 min', '120 min', '180 min', '240 min', '300 min'],
  monitor: [0, 60, 360, 720, 1440, 2880, 10080, 20160],
  monitorLabel: ['All', '1 hour', '6 hours', '12 hours', '1 day', '2 days', '1 week', '2 weeks'],
  defaultTreatment: '120 min',
  defaultMonitor: '1 hour'
};

export const CHANNEL_FILTER_PROPS = ['wellId', 'treatmentId', 'cName', 'isBourdetDer', 'isSmoothData'];

export const isMonitorTreatmentNumber = (treatmentNumber) => treatmentNumber === -10;

export const CHART_OPTION = {
  useUTC: false
};

export const PREFIX_PREDICTIVE_CHANNEL = 'predicted';

// Dark mode
export enum ENUM_THEMES {
  Dark = 'Dark',
  Light = 'Light',
}

export const THEMES = [
  { id: ENUM_THEMES.Dark, text: ENUM_THEMES.Dark, background: '#232323', color: 'white' },
  { id: ENUM_THEMES.Light, text: ENUM_THEMES.Light, background: 'white' },
];

export const TREATMENT_UNIT_TYPE = {
  mdFormationTop: 'LENGTH_BIG_UNIT',
  mdFormationBottom: 'LENGTH_BIG_UNIT',
  tvdFormationTop: 'LENGTH_BIG_UNIT',
  tvdFormationBottom: 'LENGTH_BIG_UNIT',
  clusterLength: 'LENGTH_BIG_UNIT',
  avgClusterSpacing: 'LENGTH_BIG_UNIT',
  avgPerfTVD: 'LENGTH_BIG_UNIT',
  plugDepth: 'LENGTH_BIG_UNIT',
  perfSize: 'LENGTH_SMALL_UNIT',
  WellheadOpeningPressure: 'PRESSURE_UNIT',
  minPres: 'PRESSURE_UNIT',
  minFluidRate: 'PUMPRATE_BIG_UNIT',
  minRate: 'PUMPRATE_BIG_UNIT',
  maxPres: 'PRESSURE_UNIT',
  maxFluidRate: 'PUMPRATE_BIG_UNIT',
  maxRate: 'PUMPRATE_BIG_UNIT',
  maxProppantConcSurface: 'PUMPCONC_UNIT',
  avgProppantConcSurface: 'PUMPCONC_UNIT',
  totalN2StdVolume: 'HCVOL_1_UNIT',
  totalCO2Mass: 'MASS_SMALL_UNIT',
  hhpUsedFluid: 'POWER_UNIT',
  avgFractureWidth: 'LENGTH_SMALL_UNIT',
  BreakdownPres: 'PRESSURE_UNIT',
  BreakdownRate: 'PUMPRATE_BIG_UNIT',
  BreakdownVolume: 'VOLUME_1_UNIT',
  BallseatPressure: 'PRESSURE_UNIT',
  BallseatRate: 'PUMPRATE_BIG_UNIT',
  BallseatVolume: 'VOLUME_1_UNIT',
  pumpdownVolume: 'VOLUME_1_UNIT',
  averagePres: 'PRESSURE_UNIT',
  avgPres: 'PRESSURE_UNIT',
  avgRate: 'PUMPRATE_BIG_UNIT',
  avgSlurryReturnRate: 'PUMPRATE_BIG_UNIT',
  baseFluidVol: 'VOLUME_1_UNIT',
  totalVolume: 'VOLUME_1_UNIT',
  totalProppantMass: 'MASS_SMALL_UNIT',
  formationProppantMass: 'MASS_SMALL_UNIT',
  fractureGradient: 'PRESSGRAD_UNIT',
  isip: 'PRESSURE_UNIT',
  isiP5min: 'PRESSURE_UNIT',
  isiP10min: 'PRESSURE_UNIT',
  isiP15min: 'PRESSURE_UNIT',
  initialShutinPres: 'PRESSURE_UNIT',
  initialShutinPres1: 'PRESSURE_UNIT',
  initialShutinPres5: 'PRESSURE_UNIT',
  initialShutinPres10: 'PRESSURE_UNIT',
  initialShutinPres15: 'PRESSURE_UNIT',
  diagnosticISIP: 'PRESSURE_UNIT',
  diagnosticISIP5: 'PRESSURE_UNIT',
  diagnosticISIP10: 'PRESSURE_UNIT',
  diagnosticISIP15: 'PRESSURE_UNIT',
  closurePres: 'PRESSURE_UNIT',
  pumpDownMaxPress: 'PRESSURE_UNIT',
  pumpDownMaxRate: 'PUMPRATE_BIG_UNIT',
  diagFractureGradient: 'PRESSGRAD_UNIT',
  initialShutinPresBtm: 'PRESSURE_UNIT',
  btmMinPres: 'PRESSURE_UNIT',
  btmMaxPres: 'PRESSURE_UNIT',
  btmAveragePres: 'PRESSURE_UNIT',
  minProppantConcSurface: 'PUMPCONC_UNIT',
  desAverageRate: 'PUMPRATE_BIG_UNIT',
  desAveragePressure: 'PRESSURE_UNIT',
  volToDesRate: 'VOLUME_1_UNIT',
  desCleanVol: 'VOLUME_1_UNIT',
  desSlurryVol: 'VOLUME_1_UNIT',
  flushVol: 'VOLUME_1_UNIT',
  flushVolCalcTopPerf: 'VOLUME_1_UNIT',
  flushVolCalcBotPerf: 'VOLUME_1_UNIT',
  AnnulusPres: 'PRESSURE_UNIT',
  Annulus2Pres: 'PRESSURE_UNIT',
  presAtDesRate: 'PRESSURE_UNIT',
  proppantLadenVol: 'VOLUME_1_UNIT',
  producedWater: 'VOLUME_1_UNIT',
  fieldGas: 'HCVOL_2_UNIT',
  CNG: 'HCVOL_2_UNIT',
  plugType: 'PRESSURE_UNIT',
  OperatorMxPressure: 'PRESSURE_UNIT',
  estimatedPorePressure: 'PRESSURE_UNIT',
  dfitVolume: 'VOLUME_1_UNIT',
  closurePress: 'PRESSURE_UNIT',
  transmissibilitykh: 'COND_UNIT',
  estPermeability: 'PERM_SMALL_UNIT',
  pumpVol: 'VOLUME_1_UNIT',
  perfPress: 'PRESSURE_UNIT',
  NWBPress: 'PRESSURE_UNIT',
  BHPropConcatScreenOut: 'PUMPCONC_UNIT',
  topMD: 'LENGTH_BIG_UNIT',
  bottomMD: 'LENGTH_BIG_UNIT',
  topPerfMD: 'LENGTH_BIG_UNIT',
  bottomPerfMD: 'LENGTH_BIG_UNIT',
  topTVD: 'LENGTH_BIG_UNIT',
  bottomTVD: 'LENGTH_BIG_UNIT',
  size: 'LENGTH_SMALL_UNIT',
  density: 'PERFDENS_UNIT',
  mass: 'MASS_SMALL_UNIT',
  desMass: 'MASS_SMALL_UNIT',
  stMass: 'MASS_SMALL_UNIT',
  maxConcSurface: 'PUMPCONC_UNIT',
}

export const FLUID_UNIT_TYPE = {
  volume: 'VOLUME_3_UNIT',
  desVol: 'VOLUME_3_UNIT'
}

export const ADDITIVE_UNIT_TYPE = {
  stMass: 'MASS_SMALL_UNIT',
  mass: 'MASS_SMALL_UNIT',
  desMass: 'MASS_SMALL_UNIT',
  stVol: 'VOLUME_3_UNIT',
  desVol: 'VOLUME_3_UNIT',
  volume: 'VOLUME_3_UNIT'
}

export const WELL_UNIT_TYPE = {
  kellyBushing: 'LENGTH_BIG_UNIT',
  measuredDepthMD: 'LENGTH_BIG_UNIT',
  measuredDepthTVD: 'LENGTH_BIG_UNIT',
  openHoleD: 'LENGTH_SMALL_UNIT',
  openHoleTopTVD: 'LENGTH_BIG_UNIT',
  openHoleTopMD: 'LENGTH_BIG_UNIT',
  openHoleBotTVD: 'LENGTH_BIG_UNIT',
  openHoleBotMD: 'LENGTH_BIG_UNIT',
  elevation: 'LENGTH_BIG_UNIT',
  avgPerfTVD: 'LENGTH_BIG_UNIT',
  maxTreatPres: 'PRESSURE_UNIT',
  totalN2StdVolume: 'STAGE_N2_VOLUME_VAR',
  totalCO2Mass: 'TotalCO2Mass',
  maxFluidRate: 'PUMPRATE_BIG_UNIT',
  avgTreatPres: 'PRESSURE_UNIT',
  baseFluidVol: 'VOLUME_1_UNIT',
  totalJobVolume: 'VOLUME_1_UNIT',
  totalProppantWt: 'MASS_SMALL_UNIT',
  hhpUsed: 'POWER_UNIT',
  treatingBottomholeTemperature : 'TEMP_UNIT',
  minPerfMD: 'LENGTH_BIG_UNIT',
  minPerfTVD: 'LENGTH_BIG_UNIT',
  maxPerfMD: 'LENGTH_BIG_UNIT',
  maxPerfTVD: 'LENGTH_BIG_UNIT',
  lateralLength: 'LENGTH_BIG_UNIT',
  topMD: 'LENGTH_BIG_UNIT',
  topTVD: 'LENGTH_BIG_UNIT',
  bottomMD: 'LENGTH_BIG_UNIT',
  bottomTVD: 'LENGTH_BIG_UNIT',
  topPerfMD: 'LENGTH_BIG_UNIT',
  bottomPerfMD: 'LENGTH_BIG_UNIT',
  ID: 'LENGTH_SMALL_UNIT',
  OD: 'LENGTH_SMALL_UNIT'
}

export const DEFAULT_DIGIT_AFTER_DECIMAL = {
  avgPerfTVD: 1,
  lateralLength: 1,
  ID: 3, OD: 3, id: 3, od: 3,
  minPres: 0, maxPres: 0, avgPres: 0,
  minRate: 1, maxRate: 1, avgRate: 1,
  FracGradient: 3,
  isip: 0,
  isiP5min: 0,
  isiP10min: 0,
  isiP15min: 0,
  initialShutinPres: 0,
  initialShutinPres1: 0,
  initialShutinPres5: 0,
  initialShutinPres10: 0,
  initialShutinPres15: 0,
  diagnosticISIP: 0,
  diagnosticISIP5: 0,
  diagnosticISIP10: 0,
  diagnosticISIP15: 0,
  topPerfMD: 1, bottomPerfMD: 1,
  mass: 1, stMass: 1, topMD: 1, topTVD: 1, bottomMD: 1, bottomTVD: 1,
  desVol: 1, stVol: 1, volume: 1, fluidVol: 1,
  desMass: 1, maxConcSurface: 1,
  totalWeight: 1, totalStMass: 1,
  totalProppant: 1, totalProppantWt: 1
}
import { Injectable } from '@angular/core';

@Injectable()
export class CompareWellStorageService {

  constructor() {}

  setColor(wellId: number, value: string) {
    const item = 'well_' + wellId + '_color';
    if (!value) return;
    localStorage.setItem(item, value);
  }

  getColor(wellId: number) {
    const item = 'well_' + wellId + '_color';
    try {
      const stringData = localStorage.getItem(item);
      if (stringData) return stringData;
    } catch (error) {
      return null;
    }
  }

  removeColor(wellId: number) {
    const item = 'well_' + wellId + '_color';
    localStorage.removeItem(item);
  }
}

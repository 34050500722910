import { DiagnosticCompanyStorageService } from './storage/diagnostic-company-storage.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services';
import { HttpParams } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { delay, flatMap, map } from 'rxjs/operators';
import { includes, isEmpty, filter } from 'lodash';
import { CompareWellsService } from './compare-wells.service';
import { DIAGNOSTIC_DEFAULTS, LOS_DEFAULTS, DEFAULT_PIVOTPOINTS_GROUP_ID } from '../helpers/app.constants';
import { IDiagnosticPoint } from '../models/diagnostic-points.model';
import { Helper } from '../helpers/helper';
import { each } from 'highcharts';

@Injectable()
export class DiagnosticPointsService {
  prefixCompany = `/companies`;
  prefixWell = `/wells`;

  constructor(
    private apiService: ApiService,
    private compareWellsService: CompareWellsService,
    private diagnosticCompanyStorageService: DiagnosticCompanyStorageService
    ) { }

  getByCompany(companyId): Observable<any> {
    let param = new HttpParams();
    param = param.set('companyId', companyId);

    return this.apiService.get(`${this.prefixCompany}/diagnostics`, param);
  }
  createByCompany(companyId, body: any, finalize?: VoidFunction): Observable<any> {
    companyId = companyId ? companyId : '';
    return this.apiService.post(`${this.prefixCompany}/diagnostics?companyId=${companyId}`, body, finalize);
  }
  updateByCompany(companyId, diagnosticId, body: any, finalize?: VoidFunction): Observable<any> {
    companyId = companyId ? companyId : '';
    let param = new HttpParams();
    param = param.set('companyId', companyId);
    param = param.set('diagnosticId', diagnosticId);
    return this.apiService.put(`${this.prefixCompany}/diagnostics`, body, finalize, null, param);
  }
  deleteByCompany(companyId, diagnosticId): Observable<any> {
    companyId = companyId ? companyId : '';
    diagnosticId = diagnosticId ? diagnosticId : '';
    return this.apiService.delete(`${this.prefixCompany}/diagnostics?companyId=${companyId}&diagnosticId=${diagnosticId}`);
  }
  getByWell(wellId): Observable<any> {
    let param = new HttpParams();
    param = param.set('wellId', wellId);

    return this.apiService.get(`${this.prefixWell}/diagnostics`, param);
  }
  addByWell(wellId, param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.post(`${this.prefixWell}/diagnostic?wellId=${wellId}`, param, finalize);
  }
  deleteByWell(wellId, diagnosticId): Observable<any> {
    return this.apiService.delete(`${this.prefixWell}/diagnostics?wellId=${wellId}&diagnosticId=${diagnosticId}`);
  }
  getByCompanyAndWell(companyId, wellId) {
    let requests$: Observable<any>;
    companyId = companyId ? companyId : '';
    const companyStorage = this.diagnosticCompanyStorageService.getCompanyStorage(companyId);
    if (companyStorage) {
      requests$ = of(Object.values(companyStorage));
    } else {
      requests$ = this.getByCompany(companyId)
        .pipe(flatMap(res => {
          const result = res.result || [];
          this.diagnosticCompanyStorageService.setCompanyStorage(companyId, result);
          return of(result);
        }));
    }

    return requests$
      .pipe(flatMap((diagByCompany: any[]) => {
        return this.getByWell(wellId)
          .pipe(map(res => {
            const result = filter(diagByCompany, item => {
              return includes(res.result, item.id);
            });
            return result;
          }));
      }));
  }
  getByWellTreatment(wellId, treatmentId) {
    return this.apiService.get(`${this.prefixWell}/${wellId}/treatments/${treatmentId}/diagnostics`);
  }
  updateByWellTreatment(wellId, treatmentId, param: any, finalize?: VoidFunction) {
    return this.apiService.put(`${this.prefixWell}/${wellId}/treatments/${treatmentId}/diagnostics`, param, finalize);
  }
  getByCompanyWellTreatment(companyId, wellId, treatmentId) {
    return this.getByCompanyAndWell(companyId, wellId).pipe(
      flatMap(listDiags => {
        return this.getByWellTreatment(wellId, treatmentId)
          .pipe(flatMap(res => {
            if (res && !isEmpty(res.result)) {
              // update time offset
              listDiags.forEach(item => {
                const diagPoint = res.result.find(dp => dp.id === item.id);
                if (diagPoint) {
                  item.timeOffSet = diagPoint.timeOffSet;
                }
              });
            }
            return of(listDiags);
          }));
      })
    );
  }
  getDataForPivotPoints(treatmentId, paramNames?: string[], companyId?): Observable<IDiagnosticPoint[]> {
    const requestBody: { treatmentIds: number[], paramNames: string[] } = {
      treatmentIds: !treatmentId ? [] : [treatmentId],
      paramNames: DIAGNOSTIC_DEFAULTS.map(item => item.id).concat(DEFAULT_PIVOTPOINTS_GROUP_ID)
    };
    if (paramNames && paramNames.length) {
      each(paramNames, (value, key) => {
        if (!requestBody.paramNames.includes(value)) requestBody.paramNames.push(value.toString());
      });
    }
    return this.compareWellsService.getDataForPivotPoints(requestBody)
      .pipe(
        map(res => res.result),
        map((data: any[]) => {
          let companiesDiagnostics: any[] = [];
          if (companyId !== undefined) {
            const companiesStorage = this.diagnosticCompanyStorageService.getCompanyStorage(companyId);
            if (companiesStorage) companiesDiagnostics = companiesStorage;
          }
          const result: IDiagnosticPoint[] = data.map(point => {
            const defaultPoint = DIAGNOSTIC_DEFAULTS.concat(LOS_DEFAULTS).find(item => point.paramName === item.id);

            if (defaultPoint) {
              return Object.assign({
                id: defaultPoint.id,
                name: defaultPoint.text,
                timeOffSet: point.paramValue,
                color: defaultPoint.color || Helper.randomHexColor(),
                isDefault: true
              }) as IDiagnosticPoint;
            }
            const companyPoint = companiesDiagnostics.find(item => item.name === point.paramName);
            return Object.assign({
              id: companyPoint ? `${companyPoint.id}` : point.paramName,
              name: point.paramName,
              timeOffSet: point.paramValue,
              color: point.color || Helper.randomHexColor(),
              isDefault: false
            }) as IDiagnosticPoint;
          });

          return result;
        })
      );
  }
}

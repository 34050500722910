import { Injectable } from '@angular/core';
import { cloneDeep, isEmpty } from 'lodash';
import { IInputDataMultiFlow } from '../../models/chart-data.model';
import { FRACPRO_TIMEFLOAT_CNAME, TIMEFLOAT_CHANNEL_NAME } from '../../services/charts/base-chart.service';

@Injectable()
export class TreatmentPlotDataStoreService {
  private chartInputData: IInputDataMultiFlow = null;

  constructor() { }

  setChartInputData(value) {
    this.chartInputData = cloneDeep(value);
  }

  getChartInputData() {
    return this.chartInputData;
  }

  getLastDataPointByType(chartData: IInputDataMultiFlow, dataType: string = 'realtimeData') {
    if (chartData) {
      if (!chartData[dataType]) return null;
      const dataByType = chartData[dataType][0];
      let lastDataPoint;
      if (dataByType && !isEmpty(dataByType.values)) {
        lastDataPoint = dataByType.values[dataByType.values.length - 1];
      }
      return lastDataPoint;
    }
    return;
  }

  getChartInputDataByLastMin(chartInputData, lastMin: number) {
    if (!lastMin) return chartInputData;
    const lastSecVal = lastMin * 60; // to sec
    const chartData = cloneDeep(chartInputData);
    if (!chartData) return chartInputData;

    const dataTypes = ['realtimeData', 'fracproData'];
    dataTypes.forEach(dataKey => {
      const lastPointData = this.getLastDataPointByType(chartData, dataKey);
      if (!isEmpty(chartData[dataKey])) {
        const curData = chartData[dataKey][0];
        const timeFloatIndex = curData.columns.findIndex(value => value === TIMEFLOAT_CHANNEL_NAME || value === FRACPRO_TIMEFLOAT_CNAME);
        curData.values = curData.values.filter(dataRow => {
          return dataRow[timeFloatIndex] >= (lastPointData[timeFloatIndex] - lastSecVal);
        });
      }
    });
    return chartData;
  }

  getChartInputDataInRange(start: string, end: string) {
    const chartData = cloneDeep(this.chartInputData);
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-well-api',
  templateUrl: './search-well-api.component.html',
  styleUrls: ['./search-well-api.component.scss']
})
export class SearchWellApiComponent implements OnInit {
  @Input() listItems: any[];
  @Input() isOpen: boolean = false;
  @Output() selectItem: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  toggleSelect(event, item, itemIndex) {
    event.preventDefault();
    if (item) {
      this.isOpen = true;
      item.selected = !item.selected;
      this.deselectItems(itemIndex);

      this.selectItem.emit(item);
    }
  }

  deselectItems(itemIndex) {
    this.listItems.forEach((value, index) => {
      if (index !== itemIndex) {
        value.selected = false;
      }
    });
  }

}

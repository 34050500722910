import { LogLevel, Configuration } from 'msal';



const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_UAT',
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://devlinqxfracpro.b2clogin.com/devlinqxfracpro.onmicrosoft.com/B2C_1_UAT',
        },
    },
    authorityDomain: 'devlinqxfracpro.b2clogin.com',
};
export const msalConfig: Configuration = {
  auth: {
    // clientId: "284fda61-4b29-4532-813c-494f0066c148", // Local
    clientId: "56a72d0f-1c59-4c2c-a72b-4a0cb11152c6",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    // redirectUri: "http://localhost:4200/pages/dashboard", //Local
    redirectUri: "https://uat.fracpro.ai/pages/dashboard",
    // postLogoutRedirectUri: "http://localhost:4200/pages/user/logout", //Local
    postLogoutRedirectUri: "https://uat.fracpro.ai/pages/user/logout",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
export const loginRequest = {
    scopes: []
}

export const silentRequest = {
    scopes: [],
    loginHint: "example@domain.net"
};
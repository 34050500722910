import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NavbarScaleChartService {
  private isLoadingSubject = new Subject<boolean>();
  public isLoading = this.isLoadingSubject.asObservable();

  constructor() { }

  setLoading(value: boolean) {
    this.isLoadingSubject.next(value);
  }

  getLoading() {
    return this.isLoadingSubject.asObservable();
  }
}

import { Injectable } from '@angular/core';
import { IChannelItem, IChannelSettingModel, IChannelSettingItemModel, IInputDataChart, IInputDataMultiFlow } from '../models';
import { IOffsetWellCompare } from '../models/offset-well-compare.model';
import { cloneDeep, isEmpty, isNumber } from 'lodash';
import { ChannelSelectionService, DIGITS_AFTER_DECIMAL_DEFAULT } from './channel-selection.service';
import { DATETIME_CHANNEL_NAME, TIMEFLOAT_CHANNEL_NAME } from './charts/base-chart.service';
import { Helper } from '../helpers/helper';
import { CHANNEL_FILTER_PROPS, TIME_SETTING_TYPE, YAxisMap, YAXIS_KEYS } from '../helpers/app.constants';
import { UnitSystemService } from './unit-system.service';

@Injectable()
export class CompareOffsetChannelsService {

  constructor(
    private channelSelectionService: ChannelSelectionService,
    private unitSystemService: UnitSystemService
  ) { }

  mergeOffsetChannels(curChannels: IChannelItem[], offsetWellSettings: IOffsetWellCompare[]) {
    const offsetChannels = [];
    if (!isEmpty(offsetWellSettings)) {
      offsetWellSettings.forEach(item => {
        if (!isEmpty(item.realtimeChannels)) {
          const channel = { ...item.realtimeChannels[0] };
          channel.isOffset = true;
          channel.isRealtime = item.isRealtime;
          channel.wellId = item.wellId;
          channel.treatmentId = item.treatmentId;
          channel.originName = channel.name;
          channel.name = this.createChannelName(item.wellName, item.treatmentName, channel.name);
          channel.digits = channel.digits !== undefined && channel.digits !== null ? channel.digits : DIGITS_AFTER_DECIMAL_DEFAULT;

          // if (item.isSmoothData) {
          //   channel.isSmoothData = item.isSmoothData;
          //   channel.smoothRange = item.smoothRange;
          // }

          const isExisted = curChannels.find(channelName => channelName.name === channel.name);
          if (!isExisted) {
            offsetChannels.push(channel);
          }
          // else {
          //   isExisted.isSmoothData = item.isSmoothData;
          //   isExisted.smoothRange = item.smoothRange;
          // }
        }

        // this add addtional bourdet der to channel selection
        if (!isEmpty(item.realtimeChannels) && item.hasBourdetDer) {
          const channel = { ...item.realtimeChannels[0] };
          channel.isOffset = true;
          channel.isRealtime = item.isRealtime;
          channel.wellId = item.wellId;
          channel.treatmentId = item.treatmentId;
          channel.originName = channel.name;
          channel.name = this.createChannelName(item.wellName, item.treatmentName, channel.name, true);
          channel.isSmoothData = item.isSmoothData;
          channel.smoothRange = item.smoothRange;
          channel.isBourdetDer = item.hasBourdetDer;
          channel.bourdetDerRange = item.bourdetDerRange;
          channel.digits = channel.digits !== undefined && channel.digits !== null ? channel.digits : DIGITS_AFTER_DECIMAL_DEFAULT;

          const isExisted = curChannels.find(channelName => channelName.name === channel.name);
          if (!isExisted) {
            offsetChannels.push(channel);
          } else {
            isExisted.isSmoothData = item.isSmoothData;
            isExisted.smoothRange = item.smoothRange;
            isExisted.isBourdetDer = item.hasBourdetDer;
            isExisted.bourdetDerRange = item.bourdetDerRange;
          }
        }
      });
    }

    return [...curChannels, ...offsetChannels];
  }


  setSettingChannel(settings, key, offsetWellSetting, isBourdetDer, curChannels, mapChannelMinMax) {
    const setting = settings[key];
    const selectedChannel = { ...offsetWellSetting.realtimeChannels[0] };
    const offsetChannelName = this.createChannelName(offsetWellSetting.wellName, offsetWellSetting.treatmentName, selectedChannel.name, isBourdetDer);
    const offsetChannel = curChannels.find(item => item.name === offsetChannelName);
    const isExistedIndex = setting.channels.findIndex(item => item.name === offsetChannelName);
    offsetChannel.isBourdetDer = isBourdetDer;
    offsetChannel.isRealtime = offsetWellSetting.isRealtime;
    const channel = this.channelSelectionService.formatChannelModel(offsetChannel, true, true);
    const isCompare = offsetWellSetting.hasOwnProperty('isCompare') ? offsetWellSetting.isCompare : false;

    if (isExistedIndex < 0 && offsetChannel) {
      setting.channels.push(mapChannelMinMax(channel, isBourdetDer, isCompare));
    }
    else {
      setting.channels[isExistedIndex] = Object.assign(setting.channels[isExistedIndex], mapChannelMinMax(channel, isBourdetDer, isCompare));
    }
  }


  mergeSettingsOffsetChannels(channelSettings: IChannelSettingModel, offsetWellSetting: IOffsetWellCompare, curChannels: IChannelItem[]) {
    const settings = cloneDeep(channelSettings);
    const yAxisMap = {
      Left: YAxisMap.firstLeft,
      Leftmost: YAxisMap.secondLeft,
      Right: YAxisMap.firstRight,
      Rightmost: YAxisMap.secondRight,
    };

    const mapChannelMinMax = (channel, bourdetDer = false, isCompare) => {
      const min = offsetWellSetting.realtimeChannels[0].min;
      const max = offsetWellSetting.realtimeChannels[0].max;
      channel.min = min;
      channel.max = max;
      channel.channel.min = min;
      channel.channel.max = max;
      channel.isCompare = isCompare;

      if (bourdetDer === true) {
        delete channel.min;
        delete channel.max;
        delete channel.channel.min;
        delete channel.channel.max;
      }

      return channel;
    };

    for (const key in settings) {
      if (yAxisMap[offsetWellSetting.axisType] === key) {
        this.setSettingChannel(settings, key, offsetWellSetting, false, curChannels, mapChannelMinMax);
      }
      else if (yAxisMap['Right'] === key) {
        // add additional bourdet der
        if (offsetWellSetting.hasBourdetDer) {
          this.setSettingChannel(settings, key, offsetWellSetting, true, curChannels, mapChannelMinMax);
        }
      }
    }
    return settings;
  }

  createChannelName(wellName, treatmentName, channelName, bourdet?) {
    // if (bourdet) return `${wellName}-${treatmentName}-${channelName}-BD`;
    // return `${wellName}-${treatmentName}-${channelName}`;
    if (!isNaN(treatmentName)) treatmentName = '#' + treatmentName;
    if (treatmentName === 'Monitor' || treatmentName === 'Monitoring') treatmentName = '';
    if (treatmentName) treatmentName = '.' + treatmentName;
    if (channelName) channelName = '.' + channelName;
    const name = wellName + treatmentName + channelName;
    return bourdet ? name + '-BD' : name;
  }

  checkIsExistOffsetChannel(channelSettings: IChannelSettingModel) {
    if (!channelSettings) return false;
    if (!isEmpty(channelSettings.firstLeft.channels)) return true;
    if (!isEmpty(channelSettings.secondLeft.channels)) return true;
    if (!isEmpty(channelSettings.firstRight.channels)) return true;
    if (!isEmpty(channelSettings.secondRight.channels)) return true;
    return false;
  }

  getOffsetChannelInfo(channelSettings: IChannelSettingModel, offsetInputData): {
    index: number;
    channel: IChannelSettingItemModel
  } {
    const result = {
      index: 0,
      channel: null
    };
    if (!offsetInputData) return result;

    const yAxisKeys = YAXIS_KEYS;
    yAxisKeys.forEach((key, index) => {
      if (channelSettings.hasOwnProperty(key)) {
        const setting = channelSettings[key];
        const offsetChannel = setting.channels.find(channel => Helper.filterMulti(channel, offsetInputData, CHANNEL_FILTER_PROPS));
        if (offsetChannel) {
          result.channel = offsetChannel;
          result.index = index;
        }
      }
    });
    return result;
  }

  mergeChannelSettings(channelSettings: IChannelSettingModel, offsetChannelSettings: IChannelSettingModel): IChannelSettingModel {
    let result;
    if (channelSettings && offsetChannelSettings) {
      result = cloneDeep(channelSettings);
      const yAxisKeys = YAXIS_KEYS;
      yAxisKeys.forEach((key, index) => {
        if (channelSettings[key] && offsetChannelSettings[key]) {
          if (!isEmpty(offsetChannelSettings[key].channels)) {
            result[key].channels = [...result[key].channels, ...offsetChannelSettings[key].channels]
          }
        }
      });
    }
    return result;
  }

  populateOffsetData = (data, defaultOffsetTime: number) => {
    const mapTime = d => {
      if (typeof d === 'number') return d;
      return new Date(d).getTime();
    };

    const calcTime = (d: number, offset: number, returnT: string) => {
      if (returnT === 'number') return d + offset;
      return new Date(d + offset).toISOString().substring(0, 19) + 'Z';
    };

    const generateLinearData = (data, startIndex: number, endIndex: number) => {
      const dataColumn = data[startIndex].length;
      const startTime = mapTime(data[startIndex][0]);
      const endTime = mapTime(data[endIndex][0]);
      const dataPopulate = Math.floor((endTime - startTime) / defaultOffsetTime);
      const returnType = typeof data[startIndex][0];

      const a = [];
      for (let i = 0; i < dataPopulate; i++) {
        const d = [];
        d[0] = calcTime(startTime, defaultOffsetTime * (i + 1), returnType);
        d[1] = data[startIndex][1] + (defaultOffsetTime / 1000) * (i + 1);
        for (let j = 2; j < dataColumn; j++) {
          d[j] = Helper.calcLinear(data[startIndex][j], data[endIndex][j], data[startIndex][1], data[endIndex][1], d[1]);
        }
        a.push(d);
      }
      return a;
    };

    if (data.length < 2) return data;
    const res = [data[0]];
    const n = data.length;
    for (let i = 1; i < n; i++) {
      const nextTime = mapTime(data[i][0]);
      const initTime = mapTime(data[i - 1][0]);
      const offsetTime = nextTime - initTime;
      if (offsetTime === defaultOffsetTime) res.push(data[i]);
      else {
        const generateData = generateLinearData(data, i - 1, i);
        res.push(...generateData);
      }
    }

    res.push(data[n - 1]);
    return res;
  }

  getOffsetChannelDataSeries(yAxisIndex: number, channelItem: IChannelSettingItemModel, primaryInputData: IInputDataChart, offsetInputData: IInputDataChart, highChartSeries: Highcharts.Series[], timeFormat?: string, unitSetting?: number) {
    // const offsetInputValues = offsetInputData.values.length <= 1 ? offsetInputData : this.populateOffsetData(offsetInputData.values, 1000);
    const offsetInputValues = offsetInputData.values;
    if (!channelItem || !primaryInputData || !offsetInputValues) return [];
    if (!offsetInputValues || !offsetInputValues.length) return [];

    let values = offsetInputValues;
    const columns = offsetInputData.columns;
    let xAxisChannelName = DATETIME_CHANNEL_NAME;
    if (timeFormat && timeFormat === TIME_SETTING_TYPE.OffsetTime) {
      xAxisChannelName = TIMEFLOAT_CHANNEL_NAME;
    }

    // find index of time channel
    const timeIndex = columns.findIndex(value => value === xAxisChannelName);
    let dataSeries = [];
    const dataIndex = typeof yAxisIndex === 'number' && !isNaN(yAxisIndex) ? yAxisIndex : 0;
    const channelIndex = columns.findIndex(value => value === channelItem.cName);

    // convert offset channel base time
    let startIndex = 0;
    let offsetMismatch = 0;
    const lastIndex = values.length - 1;
    if (channelItem.isRealtime) {
      // find index of time channel
      const timeFLoatIndex = columns.findIndex(value => value === TIMEFLOAT_CHANNEL_NAME);
      const dateTimeIndex = columns.findIndex(value => value === DATETIME_CHANNEL_NAME);
      // find primary base time
      let basePrimaryTimestamp = 0;
      let basePrimaryTime0 = 1;
      let curPrimaryTimestamp = 0;
      let baseOffsetTimestamp = 0;
      let baseOffsetTime0 = 1;

      if (primaryInputData.values[0]) {
        basePrimaryTimestamp = new Date(primaryInputData.values[0][dateTimeIndex]).getTime();
        basePrimaryTime0 = primaryInputData.values[0][timeFLoatIndex];
      }
      if (primaryInputData.values[primaryInputData.values.length - 1]) {
        curPrimaryTimestamp = new Date(primaryInputData.values[primaryInputData.values.length - 1][dateTimeIndex]).getTime();
      }
      if (offsetInputValues[0]) {
        baseOffsetTimestamp = new Date(offsetInputValues[0][dateTimeIndex]).getTime();
        baseOffsetTime0 = offsetInputValues[0][timeFLoatIndex];
      }

      // in case offset channel starts earlier than primary channel, filter data which outside primary timestamp
      if (baseOffsetTimestamp < basePrimaryTimestamp && basePrimaryTimestamp < curPrimaryTimestamp) {
        for (let i = startIndex; i <= lastIndex; i++) {
          const timestamp = new Date(values[i][dateTimeIndex]).getTime();
          if (timestamp >= basePrimaryTimestamp) break;
          startIndex++;
        }
      }

      // calculate offset time mismatched
      offsetMismatch = (Math.round(baseOffsetTimestamp / 1000) - baseOffsetTime0) - (Math.round(basePrimaryTimestamp / 1000) - basePrimaryTime0);
      if (!values || !values.length) return [];
    }

    // parse to chart data point
    const seriesId = `${channelItem.channelName || channelItem.name}_${dataIndex}_offset`;
    const isXValueNameChange = xAxisChannelName === DATETIME_CHANNEL_NAME;

    const currentChartSeries = highChartSeries.find(x => x.options.id === seriesId);
    const lastChartSeriesData = !currentChartSeries || !currentChartSeries.data || !currentChartSeries.data.length ? null : currentChartSeries.data[currentChartSeries.data.length - 1];

    const measureType = channelItem.measureType;
    const unitCoefficient = this.unitSystemService.getUnitCoefficient(measureType, unitSetting);

    if (lastChartSeriesData) {
      for (let i = lastIndex; i >= startIndex; i--) {
        const dataRow = values[i];
        const xValue = !isXValueNameChange ? dataRow[timeIndex] + offsetMismatch : new Date(dataRow[timeIndex]).getTime();
        if (xValue > lastChartSeriesData.x) continue;
        startIndex = i;
        break;
      }

      if (unitCoefficient) {
        for (let i = startIndex; i <= lastIndex; i++) {
          const dataRow = values[i];
          const xValue = !isXValueNameChange ? dataRow[timeIndex] + offsetMismatch : new Date(dataRow[timeIndex]).getTime();
          const yValue = this.unitSystemService.convertValue(dataRow[channelIndex], unitCoefficient);
          const value = { x: xValue, y: yValue, groupId: seriesId };
          dataSeries.push(value);
        }
      } else {
        for (let i = startIndex; i <= lastIndex; i++) {
          const dataRow = values[i];
          const xValue = !isXValueNameChange ? dataRow[timeIndex] + offsetMismatch : new Date(dataRow[timeIndex]).getTime();
          const yValue = dataRow[channelIndex] as number;
          const value = { x: xValue, y: yValue, groupId: seriesId };
          dataSeries.push(value);
        }
      }
    } else {
      if (unitCoefficient) {
        dataSeries = values.map(dataRow => {
          const xValue = !isXValueNameChange ? dataRow[timeIndex] + offsetMismatch : new Date(dataRow[timeIndex]).getTime();
          const yValue = this.unitSystemService.convertValue(dataRow[channelIndex], unitCoefficient);
          return { x: xValue, y: yValue, groupId: seriesId };
        });
      } else {
        dataSeries = values.map(dataRow => {
          const xValue = !isXValueNameChange ? dataRow[timeIndex] + offsetMismatch : new Date(dataRow[timeIndex]).getTime();
          const yValue = dataRow[channelIndex] as number;
          return { x: xValue, y: yValue, groupId: seriesId };
        });
      }
    }

    const unit = (measureType && measureType.trim() && !!unitSetting) ? (unitCoefficient ? unitCoefficient.unit : undefined) : channelItem.unit;
    const channelUnit = Helper.getValidUnit(unit, true);
    let seriesName = `${channelItem.channelName || channelItem.name} ${channelUnit}`;
    if (channelItem.isBourdetDer) seriesName = Helper.insertString(seriesName, '/sec', -1);
    const seriesColor = channelItem.color;

    return [{
      id: seriesId,
      name: seriesName,
      type: 'line',
      data: dataSeries,
      yAxis: dataIndex,
      color: seriesColor,
      boostThreshold: 10000,
      connectNulls: true
    }];
  }
}

import { Injectable } from '@angular/core';
import { UserAuthService, UserRolesService } from '../services';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ServiceCompanyGuardService {

  constructor(private userAuthService: UserAuthService, private userRolesService: UserRolesService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
    return this.userAuthService.currentUser.pipe(map(user => {
      const typeCompany = user.companyType;

      return typeCompany === 'Carbo' || typeCompany === 'Service' ;
    }));
  }
}

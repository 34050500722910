import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UserAuthService } from '../services';

@Injectable()
export class NoAuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private userAuthService: UserAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isNoAuthenticated = !this.userAuthService.isAuthenticated();
    if (!isNoAuthenticated) {
      this.router.navigate(['pages']);
    }

    return isNoAuthenticated;
  }
}

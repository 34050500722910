import { Injectable } from '@angular/core';
import { ITreatmentItem } from '../../models/treatment.model';

@Injectable()
export class TreatmentDetailsStorageService {
  private treatmentsDetailsData: {[id: number]: ITreatmentItem} = {};
  private commentType: {[id: number]: string} = {};
  private filterSettings: {[id: number]: string} = {};

  constructor() { }

  setCommentType(treatmentId: number, commentType: string) {
    if (treatmentId) {
      this.getCommentType(treatmentId);
      this.commentType[treatmentId] = commentType;
      localStorage.setItem(`treatment_details_comment_type`, JSON.stringify(this.commentType));
    }
  }

  getCommentType(treatmentId?: number): any | string {
    const stringData = localStorage.getItem(`treatment_details_comment_type`);
    if (stringData) {
      this.commentType = JSON.parse(stringData);
    }
    if (!this.commentType) {
      this.commentType = {};
    }
    if (!treatmentId) {
      return this.commentType;
    } else {
      return this.commentType[treatmentId];
    }
  }

  setFilterSettings(treatmentId: number, filterSettings) {
    if (treatmentId) {
      this.getFilterSettings(treatmentId);
      this.filterSettings[treatmentId] = filterSettings;
      sessionStorage.setItem(`treatment_details_filterSettings`, JSON.stringify(this.filterSettings));
    }
  }

  getFilterSettings(treatmentId?: number): any {
    const stringData = sessionStorage.getItem(`treatment_details_filterSettings`);
    if (stringData) {
      this.filterSettings = JSON.parse(stringData);
    }
    if (!this.filterSettings) {
      this.filterSettings = {};
    }
    if (!treatmentId) {
      return this.filterSettings;
    } else {
      return this.filterSettings[treatmentId];
    }
  }

  setTreatmentData(treatmentId: number, treatmentItem: ITreatmentItem) {
    this.getTreatmentData();
    if (treatmentId) {
      this.treatmentsDetailsData[treatmentId] = treatmentItem;
      sessionStorage.setItem(`treatment_details`, JSON.stringify(this.treatmentsDetailsData));
    }
  }

  getTreatmentData(treatmentId?: number): any | ITreatmentItem {
    const stringData = sessionStorage.getItem(`treatment_details`);
    if (stringData) {
      this.treatmentsDetailsData = JSON.parse(stringData);
    }
    if (!this.treatmentsDetailsData) {
      this.treatmentsDetailsData = {};
    }
    if (!treatmentId) {
      return this.treatmentsDetailsData;
    } else {
      return this.treatmentsDetailsData[treatmentId];
    }
  }

  removeTreatmentData() {
    sessionStorage.removeItem(`treatment_details`);
  }

  clearAll() {
    sessionStorage.clear();
  }
}

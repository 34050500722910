import { Injectable } from '@angular/core';
import { isEmpty, isNumber, forEach, cloneDeep } from 'lodash';
import { TIMEFLOAT_CHANNEL_NAME, FRACPRO_TIMEFLOAT_CNAME } from './charts/base-chart.service';

export interface IPivotPointItem {
  treatmentId: number;
  treatmentNumber: number;
  paramName: string;
  paramValue: number;
  color?: string;
  paramNameDisplay?: string;
}

@Injectable()
export class PivotPointService {

  constructor() { }

  public getMaxPivotPoint(pivotPointData: IPivotPointItem[]): IPivotPointItem {
    if (!pivotPointData || !pivotPointData.length) return null;
    if (pivotPointData.length === 1) return pivotPointData[0];
    let max = pivotPointData[0];
    for (let i = 1; i < pivotPointData.length; i++) {
      if (pivotPointData[i].paramValue <= max.paramValue) continue;
      max = pivotPointData[i];
    }
    return max;
  }

  public handleUpdateTimeByPivotData(treatmentData, pivotPointData: IPivotPointItem[]) {
    const cloneData = cloneDeep(treatmentData);
    const maxPoint = this.getMaxPivotPoint(pivotPointData);
    if (!maxPoint) return cloneData;
    const maxValue = maxPoint.paramValue;
    if (!isNumber(maxValue)) return cloneData;
    const treatmentPivotData = pivotPointData.find(item => item.treatmentId === cloneData.treatmentId);
    if (!treatmentPivotData) return cloneData;
    const diffValue = maxValue - treatmentPivotData.paramValue; // in seconds
    if (!isNumber(diffValue) || isNaN(diffValue)) return cloneData;

    const dataFlowsKeys = ['realtimeData', 'fracproData'];
    forEach(dataFlowsKeys, key => {
      if (!isEmpty(cloneData[key])) {
        const dataFlow = cloneData[key][0];
        const values = dataFlow.values;
        const columns = dataFlow.columns;
        const offsetTimeCName = key === 'fracproData' ? FRACPRO_TIMEFLOAT_CNAME : TIMEFLOAT_CHANNEL_NAME;
        // find index of time channel
        const offsetTimeIndex = columns.findIndex(value => value === offsetTimeCName);
        if (offsetTimeIndex > -1) {
          forEach(values, (dataRow) => {
            const dataTime = dataRow[offsetTimeIndex];
            const shiftedValue = dataTime + diffValue;
            // update new time valule
            dataRow[offsetTimeIndex] = shiftedValue;
          });
        }
      }
    });

    return cloneData;
  }
}

import { Injectable } from '@angular/core';
import { SearchPadListDropdown } from '../models/search-pad-list-dropdown';
import { SearchPadListTextBox } from '../models/search-pad-list-textbox';
import { of, forkJoin } from 'rxjs';
import { HistoricalWellsService } from '../../../../../pages/manage/historical-wells/historical-wells.service';
import { map } from 'rxjs/operators';
import { TreatmentService } from '../../../../services/treatment.service';
import { isArray } from 'lodash';

@Injectable()
export class SearchPadListService {

  constructor(
    private historicalWellsService: HistoricalWellsService,
    private treatmentService: TreatmentService,
  ) { }

  getSearchPadListOptions(paramOptions: {id: any; name: string}[]) {
    const req$ = paramOptions.map(opt => {
      return this.getDataByKey(opt.id).pipe(map(res => {
        if (res && isArray(res)) {
          return new SearchPadListDropdown({
            key: opt.id,
            label: opt.name,
            options: res,
          });
        } else {
          return new SearchPadListTextBox({
            key: opt.id,
            label: opt.name,
          });
        }
      }));
    });

    return forkJoin(req$);
  }

  getDataByKey(key) {
    if (key === 'country') {
      return this.getCountries$();
    } else if (key === 'state') {
      return this.getStates$();
    } else if (key === 'county') {
      return this.getCounties$();
    } else if (key === 'formation') {
      return this.getFormations$();
    } else if (key === 'serviceId') {
      return this.getServiceCompanies$();
    } else if (key === 'operatorId') {
      return this.getOperatorCompanies$();
    } else {
      return of(null);
    }
  }

  getCountries$() {
    return this.historicalWellsService.getCountries().pipe(map(res => res.result));
  }

  getStates$() {
    return this.historicalWellsService.getStates().pipe(map(res => res.result));
  }

  getCounties$() {
    return this.historicalWellsService.getCounties().pipe(map(res => res.result));
  }

  getServiceCompanies$() {
    return this.treatmentService.getListCompanyByType(1).pipe(map(res => res.result.items));
  }

  getOperatorCompanies$() {
    return this.treatmentService.getListCompanyByType(0).pipe(map(res => res.result.items));
  }

  getFormations$() {
    return this.historicalWellsService.getFormations().pipe(map(res => res.result));
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IFDIPayload } from './treatment-plot-fdi.model';

@Injectable()
export class TreatmentPlotFdiApiService {
  prefixPath = '/treatments';

  constructor(
    private apiService: ApiService,
  ) { }

  getByTreatment(treatmentId): Observable<IFDIPayload> {
    return this.apiService.get(`${this.prefixPath}/${treatmentId}/FDI`)
      .pipe(map(res => res.result));
  }

  createByTreatment(treatmentId, body, isRealtime: boolean) {
    const q = isRealtime ? '?isRealtime=true' : '';
    return this.apiService.post(`${this.prefixPath}/${treatmentId}/FDI` + q, body)
      .pipe(map(res => res.result[0]));
  }

  updateByTreatment(treatmentId, body, isRealtime: boolean) {
    const q = isRealtime ? '?isRealtime=true' : '';
    return this.apiService.put(`${this.prefixPath}/${treatmentId}/FDI` + q, body)
      .pipe(map(res => res.result));
  }

  updateFDIByTreatment(treatmentId: number, fdiId: string, fdiName: string, fdiValue: number, isRealtime: boolean) {
    const fdi = {id: fdiId, [fdiName]: fdiValue};
    const data = {fdi: [fdi]};
    return this.updateByTreatment(treatmentId, data, isRealtime);
  }

  updateMutiFDIByTreatment(treatmentId: number, fdiId: string, fdiNames: string[], fdiValues: number[], isRealtime: boolean) {
    const fdiList = [];
    const n = Math.min(fdiNames.length, fdiValues.length);
    for (let i = 0; i < n; i++) {
      const fdi = {id: fdiId, [fdiNames[i]]: fdiValues[i]};
      fdiList.push(fdi);
    }
    const data = {fdi: fdiList};
    return this.updateByTreatment(treatmentId, data, isRealtime);
  }

  deleteByTreatment(treatmentId) {
    return this.apiService.delete(`${this.prefixPath}/${treatmentId}/FDI`);
  }
}

import { Injectable } from '@angular/core';
import { find, findLast, isEmpty, findLastIndex } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { ITreatmentLogTemplateResponse } from '../models/treatment-log-template.model';

interface IDataPoint {
  id: string;
  name: string;
  xValue: number;
  yValue: number;
  treatmentName?: string;
  unit?: string;
  avgMDValue?: number;
}
export interface ILogCurvesParam {id: string; name: string; values: any}
export interface IResTreatmentLogParser {
  curveData: IDataPoint[];
  parameterData?: IDataPoint[]
}

@Injectable()
export class TreatmentLogParserService {
  private readonly plotTemplatesDataSubject: Subject<ITreatmentLogTemplateResponse[]> = new Subject();
  public scatterPlotTemplatesData$ = this.plotTemplatesDataSubject.asObservable();

  constructor() { }

  public setPlotTemplates(data: ITreatmentLogTemplateResponse[]): void {
    this.plotTemplatesDataSubject.next(data);
  }

  public unsetPlotTemplates(): void {
    this.plotTemplatesDataSubject.next(null);
  }

  parseCurveDataByTreatment(logCurves: ILogCurvesParam[], treatmentAvgMDItems) {
    const curveData = [];
    if (!isEmpty(logCurves) && !isEmpty(treatmentAvgMDItems)) {
      logCurves.forEach(log => {
        // interpolate and find the value corresponding avgMD
        // (y2-y1)/(x2-x1) = (y-y1)/(x-x1)
        const logCurveData = log.values;
        treatmentAvgMDItems.forEach(avgData => {
          // find equal depth
          let y = null;
          const equalDepthDataRow = find(logCurveData, (dataRow) => {
            return dataRow[0] === avgData.avgMD;
          });
          if (equalDepthDataRow) {
            y = equalDepthDataRow[1];
          } else {
            // find smaller depth
            const smallerDepthDataRow = findLast(logCurveData, (dataRow) => {
              return dataRow[0] < avgData.avgMD;
            });
            // find greater depth
            const greaterDepthDataRow = find(logCurveData, (dataRow) => {
              return dataRow[0] > avgData.avgMD;
            });
            if (smallerDepthDataRow && greaterDepthDataRow) {
              const x1 = smallerDepthDataRow[0];
              const y1 = smallerDepthDataRow[1];
              const x2 = greaterDepthDataRow[0];
              const y2 = greaterDepthDataRow[1];
              const x = avgData.avgMD;
              y = (((y2-y1)/(x2-x1))*x - ((y2-y1)/(x2-x1))*x1) + y1;
            }
          }
          curveData.push({
            id: log.name,
            name: log.name,
            xValue: avgData.avgMD,
            yValue: y,
          });
        });
      });
    }

    return curveData;
  }

  parseDataByVerticalSection(
    logCurves: ILogCurvesParam[], treatmentAvgMDItems: any[],
    trajectoryData: any[], parameterInputData: any[]
  ): IResTreatmentLogParser {
    // parse log data curve
    // interpolate and find the value corresponding VS
    // (y2-y1)/(x2-x1) = (y-y1)/(x-x1)
    // find available depth range by trajectoryData
    const indexItemVSEqual0 = findLastIndex(trajectoryData, (item) => {
      return item.VS === 0;
    });
    const firstItem = trajectoryData[indexItemVSEqual0];
    const lastItem = trajectoryData[trajectoryData.length - 1];
    const minDepth = firstItem.MD;
    const maxDepth = lastItem.MD;
    let allCurveData: IDataPoint[] = [];
    let allParameterData: IDataPoint[] = [];
    logCurves.forEach(log => {
      const logCurveData = log.values.filter(dataRow => {
        return dataRow[0] >= minDepth && dataRow[0] <= maxDepth;
      });
      // convert log data depth to VS
      let convertedLogData = [];
      logCurveData.forEach(dataRow => {
        const logCurveMD = dataRow[0];
        // find equal depth
        let y = null;
        const equalDepthTrajectoryItem = find(trajectoryData, (item) => {
          return item.MD === logCurveMD;
        });
        if (equalDepthTrajectoryItem) {
          y = equalDepthTrajectoryItem.VS;
        } else {
          // find smaller depth
          const smallerDepthTrajectoryItem = findLast(trajectoryData, (item) => {
            return item.MD < logCurveMD;
          });
          // find greater depth
          const greaterDepthTrajectoryItem = find(trajectoryData, (item) => {
            return item.MD > logCurveMD;
          });
          if (smallerDepthTrajectoryItem && greaterDepthTrajectoryItem) {
            const x1 = smallerDepthTrajectoryItem.MD;
            const y1 = smallerDepthTrajectoryItem.VS;
            const x2 = greaterDepthTrajectoryItem.MD;
            const y2 = greaterDepthTrajectoryItem.VS;
            const x = logCurveMD;
            y = (((y2-y1)/(x2-x1))*x - ((y2-y1)/(x2-x1))*x1) + y1;
          }
        }
        convertedLogData.push({
          VS: y,
          MD: logCurveMD,
          yValue: dataRow[1]
        });
      });

      // parse parameter data
      const convertedParameterData = [];
      treatmentAvgMDItems.forEach(avgData => {
        // find equal depth
        let y = null;
        const equalDepthDataRow = find(trajectoryData, (item) => {
          return item.MD === avgData.avgMD;
        });
        if (equalDepthDataRow) {
          y = equalDepthDataRow.VS;
        } else {
          // find smaller depth
          const smallerDepthDataRow = findLast(trajectoryData, (item) => {
            return item.MD < avgData.avgMD;
          });
          // find greater depth
          const greaterDepthDataRow = find(trajectoryData, (item) => {
            return item.MD > avgData.avgMD;
          });
          if (smallerDepthDataRow && greaterDepthDataRow) {
            const x1 = smallerDepthDataRow.MD;
            const y1 = smallerDepthDataRow.VS;
            const x2 = greaterDepthDataRow.MD;
            const y2 = greaterDepthDataRow.VS;
            const x = avgData.avgMD;
            y = (((y2-y1)/(x2-x1))*x - ((y2-y1)/(x2-x1))*x1) + y1;
          }
        }
        if (parameterInputData) {
          const parametersDataByTreatment = parameterInputData.filter(data => {
            return data.id === avgData.id;
          });
          parametersDataByTreatment.forEach(inputDataItem => {
            if (inputDataItem) {
              convertedParameterData.push({
                VS: y,
                MD: avgData.avgMD,
                yValue: inputDataItem.paramValue,
                paramName: inputDataItem.paramName,
                treatmentName: inputDataItem.name,
                unit: inputDataItem.paramUnit
              });
            }
          })
        }
      });
      // set parameter data
      const parameterData = convertedParameterData.map(item => ({
        id: log.id,
        name: item.paramName,
        treatmentName: item.treatmentName,
        unit: item.unit,
        xValue: item.VS,
        yValue: item.yValue,
        avgMDValue: item.MD
      }));
      allParameterData = allParameterData.concat(parameterData);

      const noEqualVSItems = []
      convertedParameterData.forEach(item => {
        const equalXValueItem = convertedLogData.find(paramItem => paramItem.VS === item.VS);
        if (!equalXValueItem) {
          let yVal = null;
          // find smaller depth
          const smallerVSItem = findLast(convertedLogData, (paramItem) => {
            return paramItem.VS < item.VS;
          });
          // find greater depth
          const greaterVSItem = find(convertedLogData, (paramItem) => {
            return paramItem.VS > item.VS;
          });
          if (smallerVSItem && greaterVSItem) {
            const x1 = smallerVSItem.VS;
            const y1 = smallerVSItem.yValue;
            const x2 = greaterVSItem.VS;
            const y2 = greaterVSItem.yValue;
            const x = item.VS;
            yVal = (((y2-y1)/(x2-x1))*x - ((y2-y1)/(x2-x1))*x1) + y1;
            noEqualVSItems.push({
              VS: item.VS,
              MD: item.MD,
              yValue: yVal
            });
          }
        }
      });
      console.log('not equal = ', noEqualVSItems);
      if (!isEmpty(noEqualVSItems)) {
        convertedLogData = convertedLogData.concat(noEqualVSItems);
      }
      // set log curve data
      const curveData = convertedLogData.map(item => ({
        id: log.id,
        name: log.name,
        xValue: item.VS,
        yValue: item.yValue
      }));
      allCurveData = allCurveData.concat(curveData)
    });

    return {
      curveData: allCurveData,
      parameterData: allParameterData
    };
  }

  parseDataByMeasuredDepth(
    logCurves: ILogCurvesParam[], treatmentAvgMDItems: any[],
    trajectoryData: any[], parameterInputData: any[]
  ): IResTreatmentLogParser {
    // parse log data curve
    // interpolate and find the value corresponding VS
    // (y2-y1)/(x2-x1) = (y-y1)/(x-x1)
    // find available depth range by trajectoryData
    const indexItemVSEqual0 = findLastIndex(trajectoryData, (item) => {
      return item.VS === 0;
    });
    const firstItem = trajectoryData[indexItemVSEqual0];
    const lastItem = trajectoryData[trajectoryData.length - 1];
    let allCurveData: IDataPoint[] = [];
    let allParameterData: IDataPoint[] = [];
    logCurves.forEach(log => {
      const logCurveData = log.values;
      let convertedLogData = [];
      logCurveData.forEach(dataRow => {
        const logCurveMD = dataRow[0];

        convertedLogData.push({
          MD: logCurveMD,
          yValue: dataRow[1]
        });
      });

      logCurveData.forEach(dataRow => {
        const logCurveMD = dataRow[0];
        // find equal depth
        let y = null;
        const equalDepthTrajectoryItem = find(treatmentAvgMDItems, (item) => {
          return item.avgMD === logCurveMD && item.avgMD >= logCurveData[0].MD && item.avgMD <= logCurveData[logCurveData.length - 1].MD;
        });
        if (equalDepthTrajectoryItem) {
          y = equalDepthTrajectoryItem.avgMD;
        } else {
          // find smaller depth
          const smallerDepthTrajectoryItem = findLast(treatmentAvgMDItems, (item) => {
            return item.avgMD < logCurveMD && item.avgMD >= logCurveData[0].MD && item.avgMD <= logCurveData[logCurveData.length - 1].MD;
          });
          // find greater depth
          const greaterDepthTrajectoryItem = find(treatmentAvgMDItems, (item) => {
            return item.avgMD > logCurveMD && item.avgMD >= logCurveData[0].MD && item.avgMD <= logCurveData[logCurveData.length - 1].MD;
          });
          if (smallerDepthTrajectoryItem && greaterDepthTrajectoryItem) {
            const x1 = smallerDepthTrajectoryItem.avgMD;
            const y1 = dataRow[1];
            const x2 = greaterDepthTrajectoryItem.avgMD;
            const y2 = dataRow[1];
            const x = logCurveMD;
            y = (((y2-y1)/(x2-x1))*x - ((y2-y1)/(x2-x1))*x1) + y1;
          }
        }
        convertedLogData.push({
          MD: y,
          yValue: dataRow[1]
        });
      });

      // parse parameter data
      const convertedParameterData = [];
      treatmentAvgMDItems.forEach(avgData => {

        if (parameterInputData) {
          const parametersDataByTreatment = parameterInputData.filter(data => {
            return data.id === avgData.id;
          });
          parametersDataByTreatment.forEach(inputDataItem => {
            if (inputDataItem) {
              convertedParameterData.push({
                MD: avgData.avgMD,
                yValue: inputDataItem.paramValue,
                paramName: inputDataItem.paramName,
                treatmentName: inputDataItem.name,
                unit: inputDataItem.paramUnit
              });
            }
          })
        }
      });
      // set parameter data
      const parameterData = convertedParameterData.map(item => ({
        id: log.id,
        name: item.paramName,
        treatmentName: item.treatmentName,
        unit: item.unit,
        xValue: item.MD,
        yValue: item.yValue,
        avgMDValue: item.MD
      }));
      allParameterData = allParameterData.concat(parameterData);
      // set log curve data
      const curveData = convertedLogData.map(item => ({
        id: log.id,
        name: log.name,
        xValue: item.MD,
        yValue: item.yValue
      })).reverse();
      allCurveData = allCurveData.concat(curveData);
    });

    return {
      curveData: allCurveData,
      parameterData: allParameterData
    };
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  template: `
    <span class="color-pick-view" [ngStyle]="{'background-color': value}"></span>
  `,
})
export class CustomColorComponent implements OnInit {
  @Input() value: string | number;
  @Input() rowData: any;

  constructor() { }

  ngOnInit() {
  }
}

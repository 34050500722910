export class SelectItem {
  public id: string;
  public text: string;
  public children: Array<SelectItem>;
  public parent: SelectItem;
  public prefix: string;
  public icon: string;

  public constructor(source: any) {
    if (typeof source === 'string') {
      this.id = this.text = source;
    }
    if (typeof source === 'object') {
      if (source.id || source.id === 0) {
        this.id = source.id;
      } else {
        this.id = source.text;
      }
      this.text = source.text;
      if (source.children && source.text) {
        this.children = source.children.map((c: any) => {
          const r: SelectItem = new SelectItem(c);
          r.parent = this;
          return r;
        });
        this.text = source.text;
      }
      if (source.prefix) {
        this.prefix = source.prefix;
      }
      if (source.icon) {
        this.icon = source.icon;
      }
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {DefaultEditor} from 'ng2-smart-table';
import { Helper } from '../../helpers/helper';

@Component({
  template: `
    <input style="border-radius:50%;width:20px;height:20px;color: transparent;cursor:pointer;padding: 10px;margin-left: 10px;" type="text"
           class="form-control"
           [(colorPicker)] = "colorPicker"
           [style.background]="colorPicker"
           (colorPickerSelect)="updateValue($event)"/>
  `,
})
export class EditCustomColorComponent extends DefaultEditor implements OnInit {

  colorPicker: string = Helper.randomHexColor();

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.cell.newValue !== '') {
      this.colorPicker = this.cell.getValue();
    }
    if (!this.colorPicker) {
      this.colorPicker = Helper.randomHexColor();
    }
    this.cell.setValue(this.colorPicker);
  }

  updateValue(e: any) {
    this.cell.newValue = e;
  }
}

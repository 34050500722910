import { Injectable } from '@angular/core';

import { CookieStorageService } from '../../core/services';
import { Router } from '@angular/router';
import { BehaviorSubject, throwError, Observable, from } from 'rxjs';
import { distinctUntilChanged, catchError, map, switchMap, tap } from 'rxjs/operators';
import { User } from '../models';
import { UserService } from './user.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, ServerError } from 'msal';
import { CookieService } from 'ngx-cookie-service';
import {Driver, NgForage, NgForageCache} from 'ngforage';


@Injectable()
export class UserAuthService {
  private currentUserSubject = new BehaviorSubject<User>(null as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  public currentTokenSubject =  new BehaviorSubject<string>('');
  private readonly JWT_TOKEN = 'jwtToken';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  constructor(
    private cookieStorageService: CookieStorageService,
    private router: Router,
    private userService: UserService,
    private authService: MsalService,
    private cookieService: CookieService,
    private readonly ngf: NgForage,
    private readonly cache: NgForageCache,
  ) { 
    this.currentTokenSubject = new BehaviorSubject<string>(localStorage.getItem(this.JWT_TOKEN));
    this.ngf.name = 'FracProAI';
    this.cache.driver = Driver.INDEXED_DB;
  }

  async populateUser() {
    try {
      const response = await this.userService.getCurrentUser().toPromise();
      this.currentUserSubject.next(response.result);

      return response.result;
    } catch (err) {
      throw err;
    }
  }
  public get currentTokenValue(): string {
    return this.currentTokenSubject.value;
  }
  setAuth(user) {
    // Save JWT sent from server in cookies
    if (user) {
     
      let expireTime = 60;
      if (user.idTokenClaims.exp) {
        expireTime = user.idTokenClaims.exp / 86400; // in day
      }
      this.setExpireInSeconds(expireTime);
      this.cache.setItem(this.JWT_TOKEN, user.accessToken);
      this.cookieStorageService.saveAccessToken(user.accessToken, expireTime);
      // this.populateUser();
    }
  }

  setExpireInSeconds(expireInSeconds: number) {
    this.cookieStorageService.setCookies('expireInSeconds', JSON.stringify(expireInSeconds), expireInSeconds);
  }

  purgeAuth() {
    // Remove JWT from cookies
    this.cookieStorageService.destroyAccessToken();
    // Set current user to an empty object
    this.currentUserSubject.next(null as User);
    // localStorage.clear();
  }

  isAuthenticated() {
    const accessToken = this.cookieStorageService.getAccessToken();

    return !!(accessToken);
  }

  getUserInfo() {
    return this.currentUserSubject.value;
  }

  isCarboUser() {
    return this.currentUserSubject.value &&
    this.currentUserSubject.value.companyType &&
    this.currentUserSubject.value.companyType.toLowerCase() === 'carbo';
  }
  resetSession(){
    
    
    this.currentTokenSubject.next(null);
    this.currentUserSubject.next(null);
    localStorage.clear();
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem('user');
    // this.cache.removeCached(this.JWT_TOKEN);
    this.cookieStorageService.destroyAccessToken();
    this.cookieStorageService.destroyUserId();
  }
  clearCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
  logout() {
    // const accessToken = this.cookieStorageService.getAccessToken();
    // this.authService.clearCacheForScope(accessToken);
    
  //  console.log("loggg==>", accessToken)
   this.clearCookies();
    this.cookieStorageService.destroyAll();
    localStorage.clear();
    // console.log("loggg111==>", accessToken)
    sessionStorage.clear();
    this.resetSession();
   
    this.purgeAuth();
    // console.log("loggg12221==>", accessToken)
    this.authService.logout();
    
   
  //   const accounts = this.authService.getAllAccounts()[0];
  //   debugger;
  //   this.authService.ssoSilent({loginHint: accounts.idTokenClaims.emails[0]})
  // .catch((err)=>{
  //   if(accounts && err instanceof InteractionRequiredAuthError){
  //     //this is a particularly dirty hack but doing this does give single sign out behavior that closes all sessions.
  //     document.cookie = 'msal.interaction.status=; Max-Age=0';
  //     this.authService.logout();
  //     this.cookieStorageService.destroyAll();
  //     sessionStorage.clear();
  //     this.purgeAuth();
  //   }else{
  //     this.authService.logout();
  //     this.cookieStorageService.destroyAll();
  //     sessionStorage.clear();
  //     this.purgeAuth();
  //   }
  // });
 
  // const  postLogoutRedirectUri = 'http://localhost:4200/pages/dashboard'; // Replace with your post logout redirect URI
  
  // this.authService.logout(postLogoutRedirectUri);
  //     this.cookieStorageService.destroyAll();
  //     sessionStorage.clear();
  //     this.purgeAuth();
 
    // this.authService.logout();
    // this.cookieStorageService.destroyAll();
    // sessionStorage.clear();
//     this.authService.getPostLogoutRedirectUri();
//     const account = this.authService.getAllAccounts()[0];
// console.log("account===>", account.idTokenClaims.emails[0])
//     if (account) {
//         const request = {
//          // Try to get the session ID
//             loginHint: account.idTokenClaims.emails[0] // Fallback to login hint if sid is not available
//         };

//         // Attempt to silently log out the user
//         this.authService.ssoSilent(request)
//             .catch((err) => {
//                 if (account && err instanceof InteractionRequiredAuthError) {
//                     // Clear the MSAL interaction status cookie
//                     document.cookie = 'msal.interaction.status=; Max-Age=0';
//                     // Log out using MSAL
//                     this.authService.logout();
//                 }
//             });
//     } else {
//         // Log out directly if no account is found
//         this.authService.logout();
//     }

//     // Retrieve the access token from cookies
//     const accessToken = this.cookieStorageService.getAccessToken();
//     console.log("accessToken44444=>", accessToken);
 
//     // Clear the cache for the retrieved access token
//     this.authService.clearCacheForScope(accessToken);
    
//     // Log out using MSAL (to ensure proper session termination)
//     this.authService.logout();
    
//     // Destroy all cookies related to authentication
//     this.cookieStorageService.destroyAll();
    
//     // Clear session storage
//     sessionStorage.clear();
    
//     // Purge any additional authentication-related information
//     this.purgeAuth();
    
    // this.router.navigate(['/auth/login']);
  }
  logout1(){
    
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      // this.router.navigateByUrl(this.router.url);
    // });
    location.reload(); 
    // this.router.navigate(['pages', '/']);
    // this.router.navigate(['/']);
  }
// getToken(token){
//   console.log("too==>", token)
//   const accountVal = token;
//   if (accountVal) {
//     this.authService.acquireTokenSilent({
//       scopes: ['https://devlinqxfracpro.onmicrosoft.com/api_26m/scope_rw_26m.read'],
//       account: accountVal,
//     }).then((token) => {
//       console.log("tokkkkeeen====>", token.accessToken)
//       this.storeJwtToken(token.accessToken)
//       // this.setCurUser();
//     });
//   }
// }
getToken() {
  const accountVal = this.authService.getAccount();
  if (accountVal) {
  return this.authService.acquireTokenSilent({
      scopes: ['https://devlinqxfracpro.onmicrosoft.com/UAT_WebApi_Scope/uat_scp_rw'],
      account: accountVal,
     });
  } else {
    console.error("No account value provided.");
    // Handle the case where no account value is provided
  }
}
refreshToken() {
  return this.authService.acquireTokenSilent({
    scopes: ['https://devlinqxfracpro.onmicrosoft.com/UAT_WebApi_Scope/uat_scp_rw'],
    account: this.authService.getAccount(),
  })
  //   catchError(error => {
  //     console.error('Error refreshing token:', error);
  //     this.logout();
  //     return throwError(error);
  //   })
  // );
}

// public refreshToken(){
//   console.log("refresh===>", this.authService.getAllAccounts)
//   return this.authService.acquireTokenSilent({
//     scopes: [' https://devlinqxfracpro.onmicrosoft.com/DevAI_scp/DevAI_scp_rw'],
//       account: this.authService.getAccount(),
//   }).then((response)=>{
//     const accessToken = response.accessToken;
//     if (accessToken) {
//       this.storeJwtToken(accessToken);
//     }
//   });
  //   this.authService.acquireTokenSilent({
  //     scopes: ['https://devlinqxfracpro.onmicrosoft.com/api_26m/scope_rw_26m'],
  //     account: this.authService.getAllAccounts[0],
  //   }).then((response) => {
  //     console.log("Token1 Response:", response);
  //     const result = response;
  //     const accessToken = response.accessToken;
  //     if (accessToken) {
  //       console.log("Refresh Token:", accessToken);
  //       this.storeJwtToken(accessToken);
  //       // this.setAuth(result);
  //       // Call any additional functions or handle the token as needed
  //     } else {
  //       console.error("No access token found in token response.");
  //     }
  //   }).catch((error) => {
  //     console.error("Error acquiring token silently:", error);
  //     console.log("err===>", error.InteractionRequiredAuthError)
  //     if (error instanceof InteractionRequiredAuthError || ServerError) {
  //       console.log("User interaction is required. Prompting user to sign in.");
  //       // Prompt the user to sign in interactively
  //       // this.authService.loginPopup();
       
  //     } else {
  //       // Handle other errors appropriately
  //       console.error("Unhandled error:", error);
  //     }
  //   });
  // } else {
  //   console.error("No account value provided.");
  //   // Handle the case where no account value is provided
  // }


public storeJwtToken(jwt: string) {
  localStorage.setItem(this.JWT_TOKEN, jwt);

  this.currentTokenSubject.next(jwt);
  // return this.cache.setItem(this.JWT_TOKEN, jwt);
  this.cookieStorageService.setItem(this.JWT_TOKEN, jwt);
}
}

import { Component, OnInit, Input } from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {FormGroup, Validators, AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: 'app-pivot-point',
  templateUrl: './pivot-point.component.html',
  styleUrls: ['./pivot-point.component.scss']
})
export class PivotPointComponent implements OnInit {

  pivotPointOptions = [
    { id: "JobTime", text: "Job time" },
    { id: "TimeStart", text: "Start Stage1" },
    { id: "BreakDownTime", text: "Breakdown" },
    { id: "InitialShutinPresTime", text: "ISIP" },
    { id: "WellOpenTime", text: "Well Open" },
    { id: "WellClose", text: "Well Close" },
    { id: "BallSeatTime", text: "BallSeat" },
  ];

  form: FormGroup;
  pivotPoint: AbstractControl;

  @Input()
  set selectedOption(opt) {
    if (opt && this.pivotPoint) {
      this.pivotPoint.setValue(opt);
    }
  }

  constructor(
    protected dialogRef: NbDialogRef<any>,
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  createForm() {
    this.form = new FormGroup({
      pivotPoint: new FormControl('Start Stage1', [Validators.required]),
    });
    this.pivotPoint = this.form.controls['pivotPoint'];
    this.pivotPoint.setValue(this.pivotPointOptions[0]);
  }

  submitForm() {
    // submit form
    if (this.pivotPoint && this.pivotPoint.value) {
      this.dialogRef.close(this.pivotPoint.value);
    }
  }

  onSelectPivotPoint(e: any) {
    this.pivotPoint.setValue(e);
  }
}

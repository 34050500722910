import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export const EXPORT_CHART_TYPE = {
  PNG: 'Save PNG',
  JPEG: 'Save JPEG',
  PRINT: 'Print Chart',
  RAW: 'Raw Data',
};

@Component({
  selector: 'app-export-chart-dropdown',
  templateUrl: './export-chart-dropdown.component.html',
  styleUrls: ['./export-chart-dropdown.component.scss']
})
export class ExportChartDropdownComponent implements OnInit {
  @Input() hideRawData = false;
  @Output() exportEvent = new EventEmitter<string>();

  optionsExportOpened: boolean = false;
  exportList: string[] = [EXPORT_CHART_TYPE.PNG, EXPORT_CHART_TYPE.JPEG, EXPORT_CHART_TYPE.PRINT, EXPORT_CHART_TYPE.RAW];

  constructor() { }

  ngOnInit() {
    if (this.hideRawData) this.exportList = this.exportList.filter(x => x !== EXPORT_CHART_TYPE.RAW);
  }

  clickOutsideExport() {
    this.optionsExportOpened = false;
  }

  openButtonExportDropdown() {
    this.optionsExportOpened = !this.optionsExportOpened;
  }

  exportChart(option) {
    this.clickOutsideExport();
    this.exportEvent.emit(option);
  }

}

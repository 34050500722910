import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services';
import { Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class FracproChannelsService {
  prefixPath = `/Setting/version/channels`;

  constructor(private apiService: ApiService) { }

  getLatestVersion(): Observable<any> {
    return this.apiService.get(`${this.prefixPath}`);
  }

  update(param: any, finalize?: VoidFunction): Observable<any> {
    return this.apiService.put(`${this.prefixPath}`, param, finalize);
  }
}
